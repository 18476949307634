import React, { ReactNode } from 'react';
import { Label, Link } from './styles';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    href: string;
    label: string;
    leftIcon?: ReactNode;
	rightIcon?: ReactNode;
	target?: string;
	title?: string;
}

export default function PillLink({ href, label, leftIcon, rightIcon, target, title }: Props) {
	const useTitle = title || "Opens search for '" + label + "' in a new window";
	const targetWindow = target || "searchWindow";
    return (
		<Link href={href} target={targetWindow} title={useTitle}>
            {leftIcon}
			{label &&
				<>
					<Label>
						<small>{label}</small>
					</Label>
				</>
			}
            {rightIcon}
        </Link>
    );
}
