import React, { useState } from 'react';
import styled from 'styled-components/macro';

import {
    Hero,
    TextField,
    Button,
    ContentDelimiter,
} from '../../components/common';
import Dropdown from '../../components/Dropdown';
import Pill from '../../components/Pill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt, faClock } from '@fortawesome/free-regular-svg-icons';
import {
    faAngleDown,
    faAngleRight,
    faPlus,
    faTimes,
    faLocationArrow,
} from '@fortawesome/free-solid-svg-icons';
import Banner from '../../components/Banner';
import PageSection from '../../components/PageSection';
import credIcon from '../../assets/images/icons/icon-credentials-white-green-01.svg';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import assessmentIcon from '../../assets/images/icons/icon-assessments-green-blue-01.svg';
import Stats from '../../components/Stats';
import ActivityIndicator from '../../components/ActivityIndicator';
import { useGet } from '../../hooks';
import LoadingOrError from '../../components/LoadingOrError';
import LoadingButton from '../../components/LoadingButton';
import ErrorButton from '../../components/ErrorButton';
import { apiUrl } from '../../shared/globals';
import { Organization } from '../../types/external';

const Grid = styled.div`
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(4, 1fr);

    .cell {
        align-items: center;
        background-color: #f7f7f7;
        display: flex;
        justify-content: center;
        min-height: 200px;
        min-width: 200px;
        padding: 20px;
    }
`;

const searchTeaserFooter = [
    {
        content: '1 instructional Program',
        leftIcon: faListAlt,
    },
    {
        content: '2 Learning Delivery Types',
        leftIcon: faLocationArrow,
    },
    {
        content: 'Time Estimate',
        leftIcon: faClock,
    },
];

function TestPage() {
    const [modalDataIsLoading, modalDataHasError, getModalData] = useGet();
    const [modal, setModal] = useState<Organization>();
    const [modalUrl, setModalUrl] = useState<string>();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const modalErrorUrl = `${apiUrl}/organization/error-url`;
    const modalValidUrl = `${apiUrl}/organization/2`;

    function onOpenModalClick(url: string) {
        return () => {
            setModalUrl(url);
            if (url === modalUrl) {
                setModalIsOpen(true);
            } else {
                getModalData(url, (data: any) => {
					setModal(data);
                    setModalIsOpen(true);
                });
            }
        };
    }

    function closeModal() {
        setModalIsOpen(false);
    }

    function modalIsLoadingFor(url: string) {
        return url === modalUrl && modalDataIsLoading;
    }

    function modalHasErrorFor(url: string) {
        return url === modalUrl && modalDataHasError;
    }

    return (
        <div>
            <ContentDelimiter>
                <h1>Test Page</h1>
                <hr />
                <hr />
                <br />

                <br />
                <h2>Banner</h2>
                <br />
            </ContentDelimiter>

            <Banner
                icon={credIcon}
                type="Type goes here"
                title="Title goes here"
                subTitle="Subtitle goes here"
                content={<div>Text goes here</div>}
                otherContent={
                    <Button buttonType="secondary">Other stuff here</Button>
                }
            />

            <ContentDelimiter>
                <br />
                <h2>Typography</h2>
                <br />
                <div className="typography">
                    <label>Menu</label>
                    <div className="menu">
                        <span>Show mangled quartz flip vibe exactly</span>
                    </div>
                    <hr />
                    <label>Hero</label>
                    <div className="hero">
                        <Hero>Pack my box with five dozen quality jugs</Hero>
                    </div>
                    <hr />
                    <label>H1</label>
                    <div className="h1">
                        <h1>Pack my box with five dozen quality jugs</h1>
                    </div>
                    <hr />
                    <label>H2</label>
                    <div className="h2">
                        <h2>
                            The wizard quickly jinxed the gnomes before they
                            vaporized
                        </h2>
                    </div>
                    <hr />
                    <label>H3</label>
                    <div className="h3">
                        <h3>Show mangled quartz flip vibe exactly</h3>
                    </div>
                    <hr />
                    <label>Label</label>
                    <div className="label">
                        <label>The five boxing wizard jump quickly</label>
                    </div>
                    <hr />
                    <label>Button</label>
                    <div className="button">
                        <Button>
                            Grumpy wizards make a toxic brew for the jovial
                            queen
                        </Button>
                    </div>
                    <hr />
                    <label>Body</label>
                    <div className="body">
                        <span>
                            Grumpy wizards make a toxic brew for the jovial
                            queen
                        </span>
                    </div>
                    <hr />
                    <label>Body Bold</label>
                    <div className="body-bold">
                        <strong>
                            Fix problem quickly with galvanized jets
                        </strong>
                    </div>
                    <hr />
                    <label>Link</label>
                    <div className="link">
                        <a href="/test">Quick fox jumps nightly above wizard</a>
                    </div>
                    <hr />
                    <label>Pills</label>
                    <div className="pills">
                        <span>
                            The wizard quickly jinxed the gnomes before they
                            vaporized
                        </span>
                        <div style={{ color: 'red' }}>
                            This style will get implemented once the "Pill"
                            component is built
                        </div>
                    </div>
                    <hr />
                    <label>Small</label>
                    <div className="small">
                        <small>Quick fox jumps nightly above wizard</small>
                    </div>
                </div>

                <br />
                <h2>Fields / Dropdowns</h2>
                <br />
                <Grid>
                    <div>
                        <label>Dropdown</label>
                        <div className="cell">
							<Dropdown
								ariaLabel="Test"
                                options={[
                                    'Credentials',
                                    'Organizations',
                                    'Assessments',
                                    'Learning Opportunities',
                                    'Competency Frameworks',
                                ]}
                            />
                        </div>
                    </div>
                    <div>
                        <label>Text Field</label>
                        <div className="cell">
                            <TextField placeholder="topics, keywords or occupations..." />
                        </div>
                    </div>
                </Grid>

                <br />
                <h2>Buttons</h2>
                <br />
                <Grid>
                    <div>
                        <label>Default</label>
                        <div className="cell">
                            <Button>Button</Button>
                        </div>
                    </div>
                    <div>
                        <label>Primary</label>
                        <div className="cell">
                            <Button buttonType="primary">Button</Button>
                        </div>
                    </div>
                    <div>
                        <label>Secondary</label>
                        <div className="cell">
                            <Button buttonType="secondary">Button</Button>
                        </div>
                    </div>
                    <div>
                        <label>Tertiary</label>
                        <div className="cell">
                            <Button buttonType="tertiary">Button</Button>
                        </div>
                    </div>
                </Grid>

                <br />
                <h2>Pills</h2>
                <br />
                <Grid>
                    <div>
                        <label>Filter Pill</label>
                        <div className="cell">
                            <Pill
                                content={
                                    <span>1 Organization Sector Type</span>
                                }
                                leftIcon={
                                    <FontAwesomeIcon
                                        icon={faListAlt}
                                        size="lg"
                                    />
                                }
                                rightIcon={
                                    <FontAwesomeIcon icon={faPlus} size="lg" />
                                }
                                rightIconOnActive={
                                    <FontAwesomeIcon icon={faTimes} size="lg" />
                                }
                                pillType="filter"
                            />
                        </div>
                    </div>
                    <div>
                        <label>Filter Button</label>
                        <div className="cell">
                            <Pill
                                content={
                                    <span>1 Organization Sector Type</span>
                                }
                                leftIcon={
                                    <FontAwesomeIcon
                                        icon={faListAlt}
                                        size="lg"
                                    />
                                }
                                rightIcon={
                                    <FontAwesomeIcon
                                        icon={faAngleRight}
                                        size="lg"
                                    />
                                }
                                rightIconOnActive={
                                    <FontAwesomeIcon
                                        icon={faAngleDown}
                                        size="lg"
                                    />
                                }
                                pillType="filter-button"
                            />
                        </div>
                    </div>
                </Grid>

                <br />
                <h2>Page Section</h2>
                <br />
                <div>
                    <PageSection
                        icon={aboutIcon}
                        title="Title goes here"
                        variant="Highlight">
                        <div>Content goes here</div>
                    </PageSection>
                    <PageSection
                        icon={aboutIcon}
                        title="Title goes here"
                        variant="Shadow">
                        <div>Content goes here</div>
                    </PageSection>
                </div>

                <br />
                <h2>Stats</h2>
                <br />
                <Stats
                    id="organizations"
                    title="Organizations"
                    titleCounter={450}
                    headerTitle="Total Published Organizations"
                    headerContent="All published organizations in the system. Reference organanizations are not included"
                    footerCounter={299}
                    footerTitle="Additional Reference Organizations"
                    footerContent="An organization that is referenced by another document (for example accredited by) but not actually published."
                    filters={[
                        { label: 'General', action: 'General' },
                        { label: 'Type', action: 'Type' },
                        { label: 'Sector', action: 'Sector' },
                        { label: 'Service', action: 'Service' },
                        { label: 'Claim Type', action: 'Claim Type' },
                        { label: 'Identifier Type', action: 'Identifier Type' },
                        {
                            label: 'Organization Relationships',
                            action: 'Organization Relationships',
                        },
                        { label: 'Industry Groups', action: 'Industry Groups' },
                        {
                            label: 'Organization History',
                            action: 'Organization History',
                        },
                    ]}
                />

                <br />
                <h2>Activity Indicator</h2>
                <ActivityIndicator />

                <br />
                <br />
                <h2>Search Teaser</h2>
                {/* <SearchTeaser
                    icon={assessmentIcon}
                    preTitle={'Apprenticeship Certificate'}
                    title={'Scholl Computer Technician II'}
                    subTitle={
                        'Washington Public School Classified Employees Apprenticeship Committee'
                    }
                    body={
                        'The apprentice shall receive on the job instruction and experience as in necessary to become a quialified journey-level worker versed in the theory and practice of the occupation covered by these Standards. The following is a condensed schedule of work experinece, wihic every apprencitce shall follow as closely as conditions will permit. Data Management. Customers'
                    }
                    footer={searchTeaserFooter.map((link, index) => (
                        <Pill
                            key={index}
                            content={<span>{link.content}</span>}
                            leftIcon={
                                <FontAwesomeIcon
                                    icon={link.leftIcon}
                                    size="lg"
                                />
                            }
                            rightIcon={
                                <FontAwesomeIcon
                                    icon={faAngleRight}
                                    size="lg"
                                />
                            }
                            rightIconOnActive={
                                <FontAwesomeIcon icon={faAngleDown} size="lg" />
                            }
                            pillType="filter-button"
                        />
                    ))}
                /> */}

                <br />
                <br />
                <h2>Modal</h2>
            </ContentDelimiter>
            <br />
            <br />
        </div>
    );
}

export default TestPage;
