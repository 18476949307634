import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useMediaQuery } from '../../hooks';
import { theme } from '../../shared/theme';
import { HStack } from '../Stack';

const StyledTabBar = styled(HStack)`
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;
const TabItem = styled.button<{ active?: boolean }>`
    padding: 10px 0;
    background: transparent;
    border: none;
    border-bottom: ${(props) => (props.active ? '2px solid black' : undefined)};
    cursor: pointer;
`;

interface Props {
    items: string[];
    onTabClick?: (value: string, index: number) => void;
    activeIndex?: number;
}
export default function TabBar(props: Props) {
    const [activeIndex, setActiveIndex] = useState(props.activeIndex);
    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });

    function onTabClick(value: any, index: number) {
        setActiveIndex(index);
        props.onTabClick && props.onTabClick(value, index);
    }

    useEffect(() => {
        setActiveIndex(props.activeIndex || 0);
    }, [props.activeIndex]);

    if (isMobile) {
        // TODO: decide mobile tabbar
        return null;
    }
    return (
        <StyledTabBar spacing="20px">
            {props.items.map((item, index) => (
                <TabItem
                    active={index === activeIndex}
                    key={'tab-bar-item-' + index}
                    onClick={() => onTabClick(item, index)}>
                    {item}
                </TabItem>
            ))}
        </StyledTabBar>
    );
}
