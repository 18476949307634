import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

    * { box-sizing: border-box; }

    body {
        color: ${(props) => props.theme.color.blueDark};
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: ${(props) => props.theme.fontSize.md};
        line-height: 1.6;
        margin: 0;
        padding: 0;
    }

    button {
        cursor: pointer;
        font-family: inherit;
        font-size: ${(props) => props.theme.fontSize.md};
        line-height: 1.6;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    img { max-width: 100%; }

    hr {
        background-color: ${(props) => props.theme.color.greyLight};
        border: 0 none;
        height: 1px;
    }

    a {
        color: ${(props) => props.theme.color.blueDark};

        &:hover { text-decoration: none; }
    }

    label {
        font-size: ${(props) => props.theme.fontSize.lg};
        line-height: 1.125;
        font-weight: 700;
    }

    small {
        font-size: ${(props) => props.theme.fontSize.xs};
        line-height: 1.1538;
    }

    h1 {
        color: ${(props) => props.theme.color.aquaDark};
        font-size: ${(props) => props.theme.fontSize.xxxl};
        line-height: 1.1428;
    }

    h2 {
        font-size: ${(props) => props.theme.fontSize.xxl};
        line-height: 1.1667;
    }

    h3 {
        font-size: ${(props) => props.theme.fontSize.xl};
        line-height: 1.1428;
    }
`;

export default GlobalStyle;
