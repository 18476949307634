import React from 'react';
import styled from 'styled-components/macro';
import { Link, QuantitativeValue, MonetaryAmount } from '../../types/external';
import DisplayPills from '../../components/PillDisplay';
import { Section, SectionLabel, SectionContentItem } from './Shared';

export interface QuantitativeValueSingleProps {
	label?: string;
	item: QuantitativeValue;
}
export function QuantitativeValueSingle(props: QuantitativeValueSingleProps) {
	return (
		props.item && (props.item.Description) &&
		<QuantitativeValueWrapper>
			{props.label && <QVLabel>{props.label}</QVLabel>}
			{props.item.Description && <QVProperty>{props.item.Description}</QVProperty>}
			<SectionContentItem label="" data={typeof (props.item.UnitText) == "string" ? [{ Label: (props.item.UnitText as any).toString() }] as Link[] : props.item.UnitText} RenderWith={(data) => <DisplayPills links={data} />} />
			<QVNumbers>
				{props.item.Value || props.item.Percentage ||0 && <>{props.item.Percentage && <QVNumber>{props.item.Percentage + "%"}</QVNumber>}{props.item.Value && <QVNumber>{props.item.Value}</QVNumber>}</>}
				{props.item.MinValue && props.item.MaxValue && <QVNumber>{props.item.MinValue} to {props.item.MaxValue}</QVNumber>}
				{props.item.MinValue && !props.item.MaxValue && <QVNumber>At least {props.item.MinValue}</QVNumber>}
				{!props.item.MinValue && props.item.MaxValue && <QVNumber>Up to {props.item.MaxValue}</QVNumber>}
			</QVNumbers>
		</QuantitativeValueWrapper>
	) || null
}

//Applies label for each quantitative value
export interface QuantitativeValueListProps {
	label?: string;
	items: QuantitativeValue[];
}
export function QuantitativeValueList(props: QuantitativeValueListProps) {
	var itemsWithValues = props.items?.filter(item => item.Description) || [];
	return (
		itemsWithValues.length > 0 && 
		<QuantitativeValueListWrapper>
			{itemsWithValues.map(item => (
				<QuantitativeValueSingle
					label={props.label}
					item={item}
				/>
			))}
		</QuantitativeValueListWrapper>
	) || null
}

export function MonetaryAmountSingle(props: { label?: string, item: MonetaryAmount }) {
	return (
		props.item && (props.item.Value || props.item.MaxValue || props.item.MinValue) &&
		<QuantitativeValueWrapper>
			{props.label && <QVLabel>{props.label}</QVLabel>}
			{props.item.Description && <QVProperty>{props.item.Description}</QVProperty>}
			<QVNumbers>
				{(props.item.Value) && <QVNumber>{props.item.CurrencySymbol || ""}{commatizeNumber(props.item.Value)}</QVNumber>}
				{props.item.MinValue && props.item.MaxValue && <QVNumber>{props.item.CurrencySymbol || ""}{commatizeNumber(props.item.MinValue)} to {props.item.CurrencySymbol || ""}{commatizeNumber(props.item.MaxValue)}</QVNumber>}
				{props.item.MinValue && !props.item.MaxValue && <QVNumber>At least {props.item.CurrencySymbol || ""}{commatizeNumber(props.item.MinValue)}</QVNumber>}
				{!props.item.MinValue && props.item.MaxValue && <QVNumber>Up to {props.item.CurrencySymbol || ""}{commatizeNumber(props.item.MaxValue)}</QVNumber>}
			</QVNumbers>
		</QuantitativeValueWrapper>
	) || null
}

export function MonetaryAmountList(props: { label?: string, items: MonetaryAmount[] }) {
	var itemsWithValues = props.items?.filter(item => item.Value || item.MaxValue || item.MinValue) || [];
	return (
		itemsWithValues.length > 0 &&
		<QuantitativeValueListWrapper>
			{itemsWithValues.map(item => (
				<MonetaryAmountSingle
					label={props.label}
					item={item}
				/>
			))}
		</QuantitativeValueListWrapper>
	) || null
}

//Applies label at the section level
export interface QuantitativeValueListSectionProps {
	label: string;
	items: QuantitativeValue[];
}
export function QuantitativeValueListSection(props: QuantitativeValueListSectionProps) {
	return (
		props.items?.length > 0 &&
		<Section>
			<SectionLabel>{props.label}</SectionLabel>
			<QuantitativeValueList
				items={props.items}
			/>
		</Section>
	) || null
}

export function commatizeNumber(number: number): string {
	if (!number && number != 0) {
		return "";
	}

	var counter = 0;
	var result = [] as Array<string>;
	var textNumber = number.toString();
	var parts = textNumber.split(".");
	var numbers = parts[0].toString().split("");
	numbers.reverse().forEach((char, index) => {
		counter++;
		result.push(char);
		if (counter >= 3 && (index + 1 < numbers.length)) {
			result.push(",");
			counter = 0;
		}
	});

	return result.reverse().join("") + (parts[1] ? "." + parts[1] : "");
}

const QuantitativeValueListWrapper = styled.div`
	border-top: 1px solid #CCC;

	& :first-child {
		border: none;
	}
`;

const QuantitativeValueWrapper = styled.div`
	display: flex;
	border-top: 1px dashed #CCC;
	padding: 2.5px 5px;
	max-width: 1000px;

	& :first-child {
		border: none;
	}
`;

const QVProperty = styled.div`
	padding: 0 5px;
`;

const QVLabel = styled.div`
	padding: 0 5px;
	min-width: 200px;
	font-weight: bold;
`;

const QVNumbers = styled.div`
	margin-left: auto;
	display: flex;
`;

const QVNumber = styled.div`
	padding: 0 5px;
	min-width: 75px;
	text-align: right;
	font-size: 16px;
	font-weight: bold;
`;
