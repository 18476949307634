import React, { ReactNode, useState } from 'react';
import { PillContent, PillType, StyledPill, StyledUnremovablePill } from './styles';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    content: ReactNode | string;
    onClick?: () => void;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    leftIconOnActive?: ReactNode;
    rightIconOnActive?: ReactNode;
    pillType?: PillType;
	isActive?: boolean;
}

export default function Pill(props: Props) {
    const [isClicked, setIsClicked] = useState(false);

	function onClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		setIsClicked(!isClicked);
		props.onClick && props.onClick();
    }

    return (
        <StyledPill
            onClick={onClick}
            selected={props.isActive ?? isClicked}
            pillType={props.pillType}>
            {props.isActive ?? isClicked
                ? props.leftIconOnActive || props.leftIcon
                : props.leftIcon}
			<PillContent>{props.content}</PillContent>
			{props.isActive ?? isClicked ? (
				<span className="icon-right">
					{props.rightIconOnActive || props.rightIcon}
				</span>
			) : (
				<span className="icon-right">{props.rightIcon}</span>
			)}
        </StyledPill>
    );
}

export function UnremovablePill(props: Props) {
	return (
		<StyledUnremovablePill pillType={props.pillType}>
			<PillContent>{props.content}</PillContent>
		</StyledUnremovablePill>
	);
}