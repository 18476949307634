import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

export default function BackToTopButton() {
	useEffect(() => {
		var button = document.getElementsByClassName("backToTopButton")[0];
		window.addEventListener("scroll", () => {
			button && (window.scrollY > window.innerHeight) ? button.classList.add("visible") : button.classList.remove("visible");
		});
	});

	return (
		<UpButton title="Back to Top" className="backToTopButton" onClick={() => { window.scrollTo(0, 0); }}>&uarr;</UpButton>
	)
}

const UpButton = styled.button`
	font-size: 30px;
	width: 75px;
	height: 75px;
	padding: 10px;
	background-color: ${(p) => p.theme.color.blueDark};
	color: #FFF;
	border: none;
	position: fixed;
	bottom: 25px;
	right: 25px;
	transition: transform 1s;
	transform: rotateX(90deg);

	&:hover, &:focus {
		background-color: ${(p) => p.theme.color.aquaDark};
	}

	&.visible {
		transform: rotateX(0deg);
	}
`;