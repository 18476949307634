import styled from 'styled-components/macro';

export const Link = styled.a`
    text-decoration: none;
    display: flex;
	gap: 10px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: ${(props) => props.theme.color.greyLight};
    border: 1px solid ${(props) => props.theme.color.greyLight};
    border-radius: 20px;
    font-size: ${(props) => props.theme.fontSize.sm};
    line-height: 1.714;
    padding: 2px 10px;
    position: relative;

    &:hover {
        background-color: ${(props) => props.theme.color.white};
        border-color: ${(props) => props.theme.color.greyDark};
    }
`;

export const Label = styled.span`

`;
