import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
	Redirect,
	useHistory
} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import styled, { ThemeProvider } from 'styled-components/macro';
import { theme } from './shared/theme';
import GlobalStyle from './components/GlobalStyle';
import ReactGA from 'react-ga';

import TestPage from './pages/TestPage';
import HomePage from './pages/HomePage';
import ReportDataPage from './pages/ReportsDataPage';
import Header from './components/Header';
import OrganizationPage from './pages/OrganizationPage';
import Footer from './components/Footer';
import SearchPage from './pages/SearchPage';
import ComparePage from './pages/ComparePage';
import CompetencyPage from './pages/CompetencyPage';
//Help: what is supposed to be in this import? Only compare related classes?
import { MainItemBroadType } from './types/internal';
import { MainQueryType, Filter } from './types/external';
import CompareSelectedListPanel from './components/CompareSelectedListPanel';
import { BUCKET_STORAGE, initializeFilterSearchApiUrl, googleAnalyticsID, appURL } from './shared/globals';
import axios from 'axios';
//import { convertFilterItemToIFilterItem } from './types/converters';
import { defaultQuery } from './shared/data';
import CredentialPage from './pages/CredentialPage';
import AssessmentPage from './pages/AssessmentPage';
import LearningOpportunityPage from './pages/LearningOpportunityPage';
import CompetencyFrameworkPage from './pages/CompetencyFrameworkPage';
import CompetencyFrameworkPageV2 from './pages/CompetencyFrameworkPage/indexv2';
import ConceptSchemePage from './pages/ConceptSchemePage';
import PathwayPage from './pages/PathwayPage';
import PathwayPageV2 from './pages/PathwayPage/indexv2';
import PathwayPageV3 from './pages/PathwayPage/indexv3';
import PathwaySetPage from './pages/PathwaySetPage';
import ResourcesPage from './pages/ResourcesPage';
import JobPage from './pages/JobPage';
import OccupationPage from './pages/OccupationPage';

import WorkRolePage from './pages/WorkRolePage';
import TaskPage from './pages/TaskPage';
import RubricPage from './pages/RubricPage';
import ScheduledOfferingPage from './pages/ScheduledOfferingPage';
import SupportServicePage from './pages/SupportServicePage';
import TransferValuePage from './pages/TransferValuePage';
import TransferIntermediaryPage from './pages/TransferIntermediaryPage';
import CollectionPage from './pages/CollectionPage';
import CollectionPageV2 from './pages/CollectionPage/indexv2';
import PathwayComponentPage from './pages/PathwayComponentPage/index';
import CredentialingActionPage from './pages/CredentialingActionPage';

import BenchmarkReportPage from './pages/BenchmarkReportPage';
import WidgetPage from './pages/WidgetPage';
import WidgetConfigurePage from './pages/WidgetConfigurePage';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import WidgetViewPage from './pages/WidgetViewPage';
import handleSiteWidgetization from './utils/SiteWidgetizer.js';

import ProgressionModelPage from './pages/ProgressionModelPage';
import DataSetProfilePage from './pages/DataSetProfilePage';
import OutcomeDataPage from './pages/DataSetProfilePage';
import MetricPage from './pages/MetricPage';
import IndustryPage from './pages/IndustryPage';

import { updateCurrentQuery } from "./utils/SearchHelper";
library.add(fab);
library.add(fas);

handleSiteWidgetization();

const AppWrapper = styled.div`
    display: flex;
    max-width: ${(props) => props.theme.maxWidth};
    margin: auto;
    justify-content: center;
`;

const MainContent = styled.div<{ inOffset: boolean }>`
    max-width: 100%;
    @media (max-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        max-width: 100%;
    }
    flex: 1;
`;

export interface FilterContext {
    [key: string]: Filter[];
}

export interface QueryContext {
    query: MainQueryType;
    updateQuery: (query: MainQueryType) => void;
    resetQuery: () => void;
}

const emptyFilters = {
    organization: [],
    credential: [],
    assessment: [],
	competencyframework: [],
	collection: [],
    pathway: [],
    pathwayset: [],
    learningopportunity: [],
    //supportservice: [],
    //scheduledoffering: {},
    transfervalue: [],
    transferintermediary: [],
    creddentialingaction:[],
};

export const FilterContext = React.createContext<FilterContext>(emptyFilters);

export const QueryContext = React.createContext<QueryContext>({
    query: defaultQuery,
    updateQuery: () => {},
    resetQuery: () => {},
});


function App() {
    const [filters, setFilters] = useState<FilterContext>(emptyFilters);
    const [query, setQuery] = useState<MainQueryType>(defaultQuery);
    const [showComparePanel, setShowComparePanel] = useState(false);

    function updateQuery(newQuery: MainQueryType) {
        setQuery(newQuery);
    }

    function resetQuery() {
        setQuery(defaultQuery);
    }

    useEffect(() => {
        const cookies = document.cookie;
        if (cookies) {
            const crendentialfinder = cookies
                .split('; ')
                .find((row) => row.startsWith('credentialfinder'));
            if (!crendentialfinder) {
                window.localStorage.removeItem(BUCKET_STORAGE);
            }
        } else {
            window.localStorage.removeItem(BUCKET_STORAGE);
            // set cookies
        }
        document.cookie = 'credentialfinder=true';

		//get widget ID
		var widgetParam = "";
		new URLSearchParams(window.location.search).forEach(function (value, key) {
			if (key.toLowerCase() == "widgetid") {
				widgetParam = value;
			}
		});
		var widgetID = (window as any).Widget?.ID || widgetParam;

        // get all filters
        function getFilterByBroadType(broadType: string) {
            return new Promise<any[]>(async (resolve, reject) => {
                const filterURL = `${initializeFilterSearchApiUrl}/${broadType}` + "?widgetid=" + widgetID;
                try {
                    const res = await axios.get(filterURL);
                    const data = res.data;
                    if (data.Successful) {
                        resolve(data.Result?.Filters || []);
                    } else {
                        throw data;
                    }
                } catch (error) {
                    console.log(error);
                    resolve([]);
                }
            });
        }

        const fetchFilters = [
            'organization',
            'credential',
            'assessment',
            'learningopportunity',
			'pathway',
			'pathwayset',
            'competencyframework',
            'collection',
            'occupation', 'job', 'workrole','task',
            'credentialingaction',
            'supportservice',
            'scheduledoffering',
            'conceptscheme', 'progressionmodel',
            'outcomedata', 'datasetprofile',
            'transfervalue',
            'transferintermediary', 'rubric',
            'metric',
            'industry' 
        ].map((broadType) => getFilterByBroadType(broadType));

        //NOTE: the order of the following constants must match the related type from "fetchFilters "above
		Promise.all(fetchFilters).then((values) => {
            const [
                organizationFilter,
                credentialFilter,
                assessmentFilter,
                learningOpportunityFilter,
                pathwayFilter,
                pathwaySetFilter,
                competencyFrameworkFilter,
                collectionFilter,
                occupationFilter, jobFilter, workRoleFilter,taskFilter,
                credentialingActionFilter,
                supportServiceFilter,
                scheduledOfferingFilter,
                conceptSchemeFilter,
                progressionModelFilter,
                outcomeDataFilter,
                blankFilter,
                transferValueFilter,
                transferIntermediaryFilter,rubricFilter,
                metricFilter,
                industryFilter
            ] = values;

            function convertData(filter: Filter) {
                const items = filter.Items;
				if (items) {
					/*
                    const itemsWithIdentifier = items.map((item) =>
                        convertFilterItemToIFilterItem(item),
                    );
					return { ...filter, Items: itemsWithIdentifier };
					*/
					return { ...filter, Items: items };
                }
                return { ...filter, Items: [] };
            }
            //debugger;
			var convertedFilters = {
				organization: organizationFilter.map(convertData),
				credential: credentialFilter.map(convertData),
				assessment: assessmentFilter.map(convertData),
				learningopportunity: learningOpportunityFilter.map(convertData),
				pathway: pathwayFilter.map(convertData),
				pathwayset: pathwaySetFilter.map(convertData),
				competencyframework: competencyFrameworkFilter.map(convertData),
				collection: collectionFilter.map(convertData),
				scheduledoffering: scheduledOfferingFilter.map(convertData),
				supportservice: supportServiceFilter.map(convertData),
				conceptscheme: conceptSchemeFilter.map(convertData),
				progressionmodel: progressionModelFilter.map(convertData),
				outcomedata: outcomeDataFilter.map(convertData),
				occupation: occupationFilter.map(convertData),
				job: jobFilter.map(convertData),
				task: taskFilter.map(convertData),
                workrole: workRoleFilter.map(convertData),
                credentialingaction: credentialingActionFilter.map(convertData),
				//21-08-23 mp - uncommented this as filters were needed. 
				//22-04-01 mp - got an undefined error on this?
				//22-12-06 mp - enabled
				transfervalue: transferValueFilter.map(convertData),
				//??
				transferintermediary: transferIntermediaryFilter.map(convertData),
                rubric:rubricFilter.map(convertData),
                metric:metricFilter.map(convertData),
                industry: industryFilter.map(convertData),
			};

			(window as any).SearchFilters = convertedFilters;
			setFilters(convertedFilters);
            updateCurrentQuery();
        });
    }, []);

    return (
        <div>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <FilterContext.Provider value={filters}>
                    <QueryContext.Provider
                        value={{
                            query,
                            updateQuery,
                            resetQuery,
                        }}>
                            {/* Place all global styles for the application here */}
							<Router basename={process.env.REACT_APP_BASENAME}>
								<PageViewTracker />
                                <AppWrapper>
                                    <MainContent inOffset={showComparePanel}>
                                        <Header
											onCompareButtonClick={() =>
												setShowComparePanel(!showComparePanel)
											}
											showCompareButton={
												!showComparePanel
											}
											comparePanel={
												showComparePanel ?
													<CompareSelectedListPanel
														onClose={() =>
															setShowComparePanel(false)
														}
													/>
													: undefined
											}
                                        />
                                        <ScrollToTop>
                                            <Switch>
                                                <Route path="/test">
                                                    <TestPage />
                                                </Route>
                                                <Route
                                                    exact
                                                    path="/"
                                                    component={HomePage}
                                                />
                                                <Route
                                                    exact
                                                    path="/search"
                                                    component={SearchPage}
                                                />
                                                <Route
                                                    path="/reports"
                                                    component={ReportDataPage}
                                                />
												<Route
													path="/compare"
													component={ComparePage}
												/>
                                                <Route
                                                    path="/organization/:id"
                                                    component={OrganizationPage}
												/>
												<Route
													path="/detail/organization/:id"
													component={OrganizationPage}
                                            />
                                            <Route
                                                path="/credentialorganization/:id"
                                                component={OrganizationPage}
                                            />
                                            <Route
                                                path="/detail/credentialorganization/:id"
                                                component={OrganizationPage}
                                            />
                                                <Route
                                                    path="/credential/:id"
                                                    component={CredentialPage}
												/>
												<Route
													path="/detail/credential/:id"
													component={CredentialPage}
												/>
												<Route
													path="/assessment/:id"
													component={AssessmentPage}
												/>
												<Route
													path="/detail/assessment/:id"
													component={AssessmentPage}
												/>
												<Route
													path="/assessmentprofile/:id"
													component={AssessmentPage}
												/>
												<Route
													path="/learningopportunity/:id"
													component={
														LearningOpportunityPage
													}
												/>
												<Route
													path="/detail/learningopportunity/:id"
													component={
														LearningOpportunityPage
													}
												/>
												<Route
													path="/learningopportunityprofile/:id"
													component={
														LearningOpportunityPage
													}
                                                />
                                                <Route
                                                    path="/course/:id"
                                                    component={
                                                        LearningOpportunityPage
                                                    }
                                                />
                                                <Route
                                                    path="/detail/course/:id"
                                                    component={
                                                        LearningOpportunityPage
                                                    }
                                                />
                                                <Route
                                                    path="/learningprogram/:id"
                                                    component={
                                                        LearningOpportunityPage
                                                    }
                                                />
                                                <Route
                                                    path="/detail/learningprogram/:id"
                                                    component={
                                                        LearningOpportunityPage
                                                    }
                                                />
												<Route
													path="/competencyframeworkv1/:id"
													component={
														CompetencyFrameworkPage
													}
												/>
												<Route
													path="/competencyframework/:id"
													component={
														CompetencyFrameworkPageV2
													}
												/>
												<Route
													path="/detail/competency/:id"
													component={CompetencyPage}
												/>
												<Route
													path="/conceptscheme/:id"
													component={
														ConceptSchemePage
													}
                                                />
                                                <Route
                                                    path="/detail/conceptscheme/:id"
                                                    component={
                                                        ConceptSchemePage
                                                    }
                                            />
                                            <Route
                                                path="/progressionmodel/:id"
                                                component={
                                                    ProgressionModelPage
                                                }
                                            />
                                            <Route
                                                path="/detail/progressionmodel/:id"
                                                component={
                                                    ProgressionModelPage
                                                }
                                            />
                                            <Route
                                                path="/outcomedata/:id"
                                                component={
                                                    DataSetProfilePage
                                                }
                                            />
                                            <Route
                                                path="/detail/datasetprofile/:id"
                                                component={
                                                    DataSetProfilePage
                                                }
                                            /> <Route
                                                path="/datasetprofile/:id"
                                                component={
                                                    DataSetProfilePage
                                                }
                                            />
												<Route
													path="/pathwayv1/:id"
													component={PathwayPage}
												/>
												<Route
													path="/detail/pathwayv1/:id"
													component={PathwayPage}
												/>
												<Route
													path="/pathwayv2/:id"
													component={PathwayPageV2}
                                                />
                                                <Route
                                                    path="/detail/pathway/:id"
                                                    component={PathwayPageV3}
                                                />
												<Route
													path="/pathway/:id"
													component={PathwayPageV3}
												/>
                                                <Route
                                                    path="/pathwayset/:id"
                                                    component={PathwaySetPage}
                                                />
                                                <Route
                                                    path="/detail/pathwayset/:id"
                                                    component={PathwaySetPage}
                                            />
                                            <Route
                                                path="/pathwaycomponent/:id"
                                                component={PathwayComponentPage}
                                            />
												<Route
													path="/detail/pathwaycomponent/:id"
													component={PathwayComponentPage}
												/>
												<Route
													path="/transfervalue/:id"
													component={TransferValuePage}
												/>
												<Route
													path="/detail/transfervalue/:id"
													component={TransferValuePage}
												/>
												<Route
													path="/transfervalueprofile/:id"
													component={TransferValuePage}
                                                />
                                                <Route
                                                    path="/transferintermediary/:id"
                                                    component={TransferIntermediaryPage}
                                                />
                                                <Route
                                                    path="/detail/transferintermediary/:id"
                                                    component={TransferIntermediaryPage}
                                                />
												<Route
													path="/detail/collection/:id"
													component={CollectionPage}
												/>
												<Route
													path="/collection/:id"
													component={CollectionPage}
                                                />
                                                <Route
                                                    path="/collectionv2/:id"
                                                    component={CollectionPageV2}
                                                />
												<Route
													path="/competency/:id"
													component={CompetencyPage}
												/>
												<Route
													path="/supportservice/:id"
													component={SupportServicePage}
                                                />
                                                <Route
                                                    path="/detail/supportservice/:id"
                                                    component={SupportServicePage}
                                            />
                                            <Route
                                                path="/occupation/:id"
                                                component={OccupationPage}
                                            />
                                            <Route
                                                path="/detail/occupation/:id"
                                                component={OccupationPage}
                                            />
                                            <Route
                                                path="/job/:id"
                                                component={JobPage}
                                            />
                                            <Route
                                                path="/detail/job/:id"
                                                component={JobPage}
                                            />
                                            <Route
                                                path="/workrole/:id"
                                                component={WorkRolePage}
                                            />
                                            <Route
                                                path="/detail/workrole/:id"
                                                component={WorkRolePage}
                                            />
                                            <Route
                                                path="/task/:id"
                                                component={TaskPage}
                                            />
                                            <Route
                                                path="/detail/task/:id"
                                                component={TaskPage}
                                            />
                                            <Route
                                                path="/credentialingaction/:id"
                                                component={CredentialingActionPage}
                                            />
                                            <Route
                                                path="/detail/credentialingaction/:id"
                                                component={CredentialingActionPage}
                                            />
                                             <Route
                                                path="/rubric/:id"
                                                component={RubricPage}
                                            />
                                            <Route
                                                path="/detail/rubric/:id"
                                                component={RubricPage}
                                            />
                                             <Route
                                                path="/metric/:id"
                                                component={MetricPage}
                                            />
                                            <Route
                                                path="/detail/metric/:id"
                                                component={MetricPage}
                                            />
                                             <Route
                                                path="/industry/:id"
                                                component={IndustryPage}
                                            />
                                            <Route
                                                path="/detail/industry/:id"
                                                component={IndustryPage}
                                            />
                                               
                                                <Route
                                                    path="/resources/:id"
                                                    component={ResourcesPage}
                                                />
                                                <Route
                                                    path="/resource/:id"
                                                    component={ResourcesPage}
                                                />
                                                <Route
                                                    path="/benchmarkreport"
                                                    component={
                                                        BenchmarkReportPage
                                                    }
                                                />
												<Route
													path="/widget/configure"
													component={WidgetConfigurePage}
												/>
												<Route
													path="/widget/searchwidget"
													component={WidgetViewPage}
												/>
												<Route
													path="/widget"
													component={WidgetPage}
												/>
												<Route
													path="/w"
													component={WidgetViewPage}
												/>
                                                <Redirect
                                                    from="/search/credential"
                                                    to="/search?searchType=credential"
                                                />
                                                <Redirect
                                                    from="/credentials"
                                                    to="/search?searchType=credential"
                                                />
                                                <Redirect
                                                    from="/search/organization"
                                                    to="/search?searchType=organization"
                                                />
                                                <Redirect
                                                    from="/organizations"
                                                    to="/search?searchType=organization"
                                                />
                                                <Redirect
                                                    from="/search/credentialorganization"
                                                    to="/search?searchType=organization"
                                                />
                                                <Redirect
                                                    from="/search/credentialorganizations"
                                                    to="/search?searchType=organization"
                                                />
                                                <Redirect
                                                    from="/search/assessment"
                                                    to="/search?searchType=assessment"
                                                />
                                                <Redirect
                                                    from="/search/assessmentProfile"
                                                    to="/search?searchType=assessment"
                                                />
                                                <Redirect
                                                    from="/assessments"
                                                    to="/search?searchType=assessment"
                                                />
                                                <Redirect
                                                    from="/search/LearningOpportunity"
                                                    to="/search?searchType=learningopportunity"
                                                />
                                                <Redirect
                                                    from="/LearningOpportunities"
                                                    to="/search?searchType=learningopportunity"
                                                />
                                                <Redirect
                                                    from="/search/pathway"
                                                    to="/search?searchType=pathway"
                                                />
                                                <Redirect
                                                    from="/pathways"
                                                    to="/search?searchType=pathway"
                                                />
                                                <Redirect
                                                    from="/search/pathwayset"
                                                    to="/search?searchType=pathwayset"
                                                />
                                                <Redirect
                                                    from="/pathwaysets"
                                                    to="/search?searchType=pathwayset"
                                                />
												<Redirect
													from="/search/CompetencyFramework"
													to="/search?searchType=competencyframework"
                                                />
                                                <Redirect
                                                    from="/search/SupportService"
                                                    to="/search?searchType=supportservice"
                                                />
                                                <Redirect
                                                    from="/SupportServices"
                                                    to="/search?searchType=supportservice"
                                            />
                                            <Redirect
                                                from="/search/Occupation"
                                                to="/search?searchType=occupation"
                                            />
                                            <Redirect
                                                from="/Occupations"
                                                to="/search?searchType=occupation"
                                            />
                                            <Redirect
                                                from="/search/Job"
                                                to="/search?searchType=job"
                                            />
                                            <Redirect
                                                from="/Jobs"
                                                to="/search?searchType=job"
                                            />
                                            <Redirect
                                                from="/search/WorkRole"
                                                to="/search?searchType=workrole"
                                            />
                                            <Redirect
                                                from="/WorkRoles"
                                                to="/search?searchType=workrole"
											/>
											<Redirect
                                                from="/search/Task"
                                                to="/search?searchType=task"
                                            />
                                            <Redirect
                                                from="/Tasks"
                                                to="/search?searchType=task"
                                            />
                                            <Redirect
                                                from="/search/CredentialingAction"
                                                to="/search?searchType=credentialingaction"
                                            />
                                            <Redirect
                                                from="/CredentialingActions"
                                                to="/search?searchType=credentialingaction"
                                            />
                                            	<Redirect
                                                from="/search/Rubric"
                                                to="/search?searchType=rubric"
                                            />
                                            <Redirect
                                                from="/Rubrics"
                                                to="/search?searchType=rubric"
                                            />
                                            <Redirect
                                                from="/search/Metric"
                                                to="/search?searchType=metric"
                                            />
                                            <Redirect
                                                from="/Metrics"
                                                to="/search?searchType=metric"
                                            />
                                             <Redirect
                                                from="/search/Industry"
                                                to="/search?searchType=industry"
                                            />
                                            <Redirect
                                                from="/Industries"
                                                to="/search?searchType=industry"
                                            />
                                                <Redirect
                                                    from="/search/Competency"
                                                    to="/search?searchType=competencyframework"
                                                />
												<Redirect
													from="/Collections"
													to="/search?searchType=collection"
                                                />
                                                <Redirect
                                                    from="/search/Collection"
                                                    to="/search?searchType=collection"
                                                />
                                                <Redirect
                                                    from="/search/TransferValue"
                                                    to="/search?searchType=transfervalue"
                                                />
												<Redirect
													from="/TransferValues"
													to="/search?searchType=transfervalue"
                                                />
                                                <Redirect
                                                    from="/search/TransferIntermediary"
                                                    to="/search?searchType=transferintermediary"
                                                />
                                                <Redirect
                                                    from="/transferintermediaries"
                                                    to="/search?searchType=transferintermediary"
                                                />
												<Redirect
													from="/conceptschemes"
													to="/search?searchType=conceptscheme"
												/>
												<Redirect
													from="/search/conceptscheme"
													to="/search?searchType=conceptscheme"
                                            />
                                            <Redirect
                                                from="/progressionmodels"
                                                to="/search?searchType=progressionmodel"
                                            />
                                            <Redirect
                                                from="/search/progressionmodel"
                                                to="/search?searchType=progressionmodel"
                                            />
                                            <Redirect
                                                from="/outcomedata"
                                                to="/search?searchType=outcomedata"
                                            />
                                            <Redirect
                                                from="/search/Outcomedata"
                                                to="/search?searchType=outcomedata"
											/>
											<Redirect
                                                from="/search/DataSetProfile"
                                                to="/search?searchType=outcomedata"
                                            />
                                            </Switch>
                                        </ScrollToTop>
                                    </MainContent>
                                </AppWrapper>
                                <Footer />
                            </Router>
                    </QueryContext.Provider>
                </FilterContext.Provider>
            </ThemeProvider>
        </div>
    );
}

//Google analytics
function PageViewTracker() {
	const [currentTrackedPage, setCurrentTrackedPage] = useState("");
	//const browserHistory = useHistory(); //Doesn't seem to work?
	const location = useLocation();
	const locationPathName = location.pathname;

	useEffect(() => {
		if (appURL == "https://credentialfinder.org" && currentTrackedPage != locationPathName) {
			setCurrentTrackedPage(locationPathName);
			ReactGA.initialize(googleAnalyticsID);
			ReactGA.set({ page: locationPathName });
			console.log("Page View:", locationPathName);
			ReactGA.pageview(locationPathName);
		}
	}, [locationPathName]);

	return (<></>);
}

function ScrollToTop(props: any) {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return props.children;
}

export default App;
