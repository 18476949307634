import React from 'react';
import styled from 'styled-components/macro';
import { Outline as OutlineType } from '../../types/external';
import ModalLinks from '../DetailPage/ModalLinks';
import { Inline, InlineLabel } from "./styles";

export default function OutlineList(props: { items: OutlineType[] }) {
	return (props.items?.length > 0 &&
		<div>
			{props.items.map((item, index) => (
				<Outline item={item} key={"outline-" + index} />
			))}
		</div>
	) || null
}

export function Outline(props: { item: OutlineType }) {
	return (props.item && 
	<OutlineWrapper>
		{props.item.Image && <Image src={props.item.Image} alt={"Image for " + props.item.Label} />}
			<Content>
				
			<Label>
				{props.item.URL ?
					<a href={props.item.URL}>{props.item.Label}</a> :
					<>{props.item.Label}</>
				}
				</Label>
			<TextItem label="Type:" item={props.item.OutlineType} />
			{props.item.ExtraData && props.item.ExtraData.Label === 'CodedNotation' && (
				<TextItem label="Coded Notation:" item={props.item.ExtraData.Value} />
			)}
			{props.item.ProviderList && props.item.ProviderList.length > 0 ? (
			<div>
				{props.item.ProviderList.map((provider, index) => (
					provider.Label && provider.URL && (
						<div key={index}>
							<Provider> {index == 0 ? 'Providers:' : ''}
								<a href={provider.URL}>{provider.Label}</a>
							</Provider>
						</div>
					)
				))}
			</div>
			) : (
			props.item.Provider && (props.item.Provider.Label || props.item.Provider.URL) && (
				<Provider>Provider:
					<a href={props.item.Provider.URL}>{props.item.Provider.Label}</a>
				</Provider>
			))
			}
			{props.item.Description && <Description>{props.item.Description.length > 1000 ? props.item.Description.substring(0, 1000) + "..." : props.item.Description}</Description>}
			{props.item.Tags && <ModalLinks links={props.item.Tags} />}
		</Content>
	</OutlineWrapper>
	) || null
}
function TextItem(props: { label: string, item: string }) {
	return (props.item &&
		<Inline>
			<InlineLabel>{props.label}</InlineLabel>
			<div>{props.item}</div>
		</Inline>
	) || null
}
const OutlineWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	padding: 10px 0;

	&:first-child {
		padding: 0 0 10px 0;
	}

	&:not(:last-child) {
		border-bottom: 1px solid ${(p) => p.theme.color.aquaDark};
	}
`;

const Image = styled.img`
	flex: 1 1 120px;
	max-width: 120px;
	max-height: 120px;
	object-fit: contain;
	margin-right: 10px;

	@media (max-width: 600px){
		max-width: 60px;
		max-height: 60px;
	}
`;

const Content = styled.div`
	flex: 1 1 auto;
`;

const Label = styled.div`
	font-weight: bold;
	margin-bottom: 5px;
`;

const Provider = styled.div`
	font-size: 85%;
	font-style: italic;
	margin-bottom: 5px;
`;

const Description = styled.div`
	font-size: 90%;
	word-break: break-word;
	margin-bottom: 5px;
`;
