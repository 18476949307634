import { defaultMapSetting } from '../shared/data';
import { Place } from '../types/external';

export function setupMap(
    googleMap: google.maps.Map,
    maps: typeof google.maps,
	places: Array<Place>,
    defaultMapZoom?: number,
) {
    const markers: Array<google.maps.Marker> = [];
    const infoWindows: Array<google.maps.InfoWindow> = [];
    const bounds = new maps.LatLngBounds();

    places.forEach((place, index) => {
        if (place.Latitude && place.Longitude) {
			const latLng = { lat: place.Latitude, lng: place.Longitude };
			var marker = new maps.Marker({
				position: latLng,
				map: googleMap,
				label: place.MarkerLabel || "",
				title: place.MarkerTitle || ""
			});
			var addressText = [place.StreetAddress, place.AddressLocality, place.AddressRegion].filter(m => m && m.length > 0).join(", ") || "Unnamed Location";
			var infoWindow = new maps.InfoWindow({
				content: (place.ResourceName ? "<b>" + place.ResourceName + "</b><br />" : "") +
					(place.ResourceProviderName ? place.ResourceProviderName + "<br />" : "") +
					(place.Name ? place.Name + "<br />" : "") +
					//"<a href=\"https://www.google.com/maps/search/" + (place.Name || place.ResourceProviderName || "").replace(/ /g, "+") + "/@" + place.Latitude + "," + place.Longitude + ",15z\" target=\"_blank\">" + addressText + "</a>",
					"<a href=\"https://www.google.com/maps/search/?api=1&query=" + place.Latitude + "," + place.Longitude + "\" target=\"_blank\">" + addressText + "</a>",
			});
			marker.addListener("click", () => {
				//Highlight the result and scroll to it
				var resultDiv = document.querySelector("[data-meta_id='" + (place as any).identifier + "']");
				resultDiv?.scrollIntoView();
				resultDiv?.classList.add("highlighted");
				setTimeout(() => {
					resultDiv?.classList.remove("highlighted");
				}, 100);

				//Open the info window on the map
				infoWindows.filter(m => m != infoWindow).forEach(function (otherWindow) {
					otherWindow.close();
				})
				infoWindow.open(googleMap, marker);
				const markerPosition = marker.getPosition();
				if (markerPosition) {
					googleMap.panTo(markerPosition);
				}
			});

			//Make it possible to relate this marker back to its result
			marker.set("resultID", (place as any).identifier);
			markers.push(marker);
			infoWindows.push(infoWindow);
            bounds.extend(latLng);
        }
	});

    if (!bounds.isEmpty()) {
        googleMap.fitBounds(bounds);
    } else {
        googleMap.setCenter(defaultMapSetting.center);
        googleMap.setZoom(defaultMapSetting.zoom);
    }
    if (places.length === 1) {
        googleMap.setZoom(defaultMapZoom || 12);
    }
    return markers;
}
