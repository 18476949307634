import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RouteComponentProps } from 'react-router-dom';
import InnerHTML from 'dangerously-set-html-content';
import ActivityIndicator from '../../components/ActivityIndicator';
import pageIcon from '../../assets/images/icons/icon-pathways-white-green-01.svg';
import styled from 'styled-components/macro';
import Error from '../../components/Error';
import { legacySourceUrl } from '../../shared/globals';

interface Props {
    id: string;
}

export default function PathwayPage(props: RouteComponentProps<Props>) {
    const id = props.match.params.id;
    const [loading, setLoading] = useState(false);
    const [pageContent, setPageContent] = useState(' ');
    const [error, setError] = useState(false);

    useEffect(() => {
        setLoading(true);
        const url1 = `https://sandbox.credentialengine.org/finder/pathway/${id}?coreonly=true`;
		const url = `${legacySourceUrl}/pathway/${id}?coreonly=true`;
        axios
            .get(url)
            .then((res) => {
                setPageContent(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setError(true);
                setLoading(false);
            });
    }, []);
    if (loading) {
        return (
            <LoadingIndicatorWrapper>
                <ActivityIndicator />
            </LoadingIndicatorWrapper>
        );
    }
    if (error) {
        return <Error />;
    }
    return <InnerHTML html={pageContent} />;
}

const LoadingIndicatorWrapper = styled.div`
    text-align: center;
    padding: 50px;
`;
