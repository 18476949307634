import React from 'react';
import PageSectionItem from '../../components/PageSectionItem';
import { Description, Label, Section } from '../../components/DetailPage/styles';
import {
	ModalType,
} from '../../types/internal';
import {
	Assessment,
	LearningOpportunity,
} from '../../types/external';
import SearchPills from '../../components/SearchPills';
import { isFilled } from '../../validation';
import { Button } from '../../components/common';
import { ModalButtonAndWindow } from '../../components/Modal';
import ValueProfileList from '../../components/DetailPage/ValueProfile';
import { detailSectionProperties } from '../../utils';
import HasAnyFilled from '../../components/HasAnyFilled';
import DisplayPills from '../../components/PillDisplay';

interface Props {
    item: Assessment | LearningOpportunity;
    openedModal?: ModalType;
    onOpenModalClick: (modalType: ModalType) => () => void;
    onCloseModalClick: () => void;
}

// This is split out because we need to render it a second time on mobile devices.
export default function AboutDetail(props: Props) {
    const item = props.item;

    return (
        <>
            {item.LifeCycleStatusType && (
                <PageSectionItem>
                    <Label>Life Cycle Status</Label>
                    <DisplayPills links={[item.LifeCycleStatusType]} />
                </PageSectionItem>
            )}
            {isFilled(item.AudienceType) && (
                <PageSectionItem>
                    <Label>Audience Type</Label>
                    <SearchPills links={item.AudienceType} />
                </PageSectionItem>
            )}
            {isFilled(item.AudienceLevelType) && (
                <PageSectionItem>
                    <Label>Audience Level</Label>
                    <SearchPills links={item.AudienceLevelType} />
                </PageSectionItem>
            )}

            <HasAnyFilled
                data={item}
                keys={detailSectionProperties.assessmentMethod}>                                      
                <PageSectionItem>
                    <Label>Assessment Methods</Label>
                    {item.AssessmentMethodDescription && (
                        <div>{item.AssessmentMethodDescription}</div>
                    )}
                    {isFilled(item.AssessmentMethodType) && (
                        <SearchPills links={item.AssessmentMethodType} />
                    )}
                </PageSectionItem>
            </HasAnyFilled>
            <HasAnyFilled
                data={item}
                keys={detailSectionProperties.deliveryType}>                                      
                <PageSectionItem>
                    <Label>Delivery Type</Label>
                    {item.DeliveryTypeDescription && (
                        <div>{item.DeliveryTypeDescription}</div>
                    )}
                    {isFilled(item.DeliveryType) && (
                        <SearchPills links={item.DeliveryType} />
                    )}
                </PageSectionItem>
            </HasAnyFilled>

            {isFilled((item as Assessment).AssessmentUseType) && (
                <PageSectionItem>
                    <Label>Assessment Use</Label>
                    <SearchPills
                        links={(item as Assessment).AssessmentUseType}
                    />
                </PageSectionItem>
            )}
            <HasAnyFilled
                data={item}
                keys={detailSectionProperties.learningMethodType}>                                      
                <PageSectionItem>
                <Label>Learning Method Type</Label>
                    {item.LearningMethodDescription && (
                        <div>{item.LearningMethodDescription}</div>
                    )}
                    <SearchPills
                        links={
                            (item as LearningOpportunity).LearningMethodType
                        }
                    />
                </PageSectionItem>
			</HasAnyFilled>   

			{isFilled(item.ScheduleTimingType	) && (
                <PageSectionItem>
                    <Label>Schedule Timing Type</Label>
                    <DisplayPills links={item.ScheduleTimingType} />
                </PageSectionItem>
            )}
			{isFilled(item.OfferFrequencyType) && (
                <PageSectionItem>
                    <Label>Offer Frequency Type</Label>
                    <DisplayPills links={item.OfferFrequencyType} />
                </PageSectionItem>
            )}
			{isFilled(item.ScheduleFrequencyType) && (
                <PageSectionItem>
                    <Label>Schedule Frequency Type</Label>
                    <DisplayPills links={item.ScheduleFrequencyType} />
                </PageSectionItem>
            )}

            {isFilled((item as LearningOpportunity).DegreeConcentration) && (
                <Section>
                    <Label>Degree Concentration</Label>
                    <SearchPills
                        links={(item as LearningOpportunity).DegreeConcentration}
                    />
                </Section>
            )}
            <HasAnyFilled
                data={item}
                keys={[
                    ...detailSectionProperties.creditValue,
                ]}>                                    
                 <PageSectionItem>
					<Label>Credits</Label>
                    {item.CreditUnitTypeDescription && (
                        <Description>
                            {item.CreditUnitTypeDescription}
                        </Description>
                    )}                    
 					<ModalButtonAndWindow
						buttonLabel="Credit Values"
						resourceTitle={item.Name}
						items={item.CreditValue}
						Wrapper={Section}
						Content={() => <ValueProfileList items={item.CreditValue} />}
					/>
               </PageSectionItem>
            </HasAnyFilled>
            
        </>
    );
}
