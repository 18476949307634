import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//updates for data table testing
//yarn add react-data-table-component styled-components
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import axios from 'axios';
import { RouteComponentProps } from 'react-router-dom';
import InnerHTML from 'dangerously-set-html-content';
import ActivityIndicator from '../../components/ActivityIndicator';
import styled from 'styled-components/macro';
import pageIcon from '../../assets/images/icons/icon-credential-benchmark-report-white-green-01.svg';
import credentials_icon from '../../assets/images/icons/icon-credentials-white-green-01.svg';
import organizations_icon from '../../assets/images/icons/icon-organization-white-green-01.svg';
import assessments_icon from '../../assets/images/icons/icon-assessments-white-green-01.svg';
import learning_opportunities_icon from '../../assets/images/icons/icon-learning-opportunities-white-green-01.svg';
import costmanifest_icon from '../../assets/images/icons/icon-slider-credential-providers-01.svg';
import conditionmanifest_icon from '../../assets/images/icons/icon-slider-credential-providers-01.svg';
import pathways_icon from '../../assets/images/icons/icon-pathways-white-green-01.svg';
import transfervalue_icon from '../../assets/images/icons/icon-transfer-value-white-green-01.svg';
import transferintermediary_icon from '../../assets/images/icons/icon-transfer-value-white-green-01.svg';
import Error from '../../components/Error';
import { apiUrl } from '../../shared/globals';
import { legacySourceUrl } from '../../shared/globals';
import { WhiteLink } from '../../components/Banner/styles';
import { Wrapper } from '../../components/Error/styles';
import LoadingOrError from '../../components/LoadingOrError';
import { VStack } from '../../components/Stack';
import { PageDescription, PageSection, PageTitle } from '../HomePage/styles';
import { StyledHelpanel  } from './styles';
import { useGet, useMediaQuery } from '../../hooks';
import { theme } from '../../shared/theme';
import { BenchmarkReport } from '../../types/external';
import Banner from '../../components/Banner';
//not used
interface Props {
    benchmarkType: string;
}

export default function ReportsBenchmarkPage(props: RouteComponentProps<Props>) {
    let benchmarkType = props.match.params.benchmarkType;
    if(benchmarkType == null) {
        benchmarkType = "credential";
    }//
    //Setup tabs/entities. On change of currentEntity, the getPageData UseEffect will be called.
    //ISSUE: the whole page is being refreshed, rather than just the benchmarks
	const [currentEntity, setCurrentEntity] = useState("credential");
    function getLegacyLink() {
        const url = legacySourceUrl + "/Reports/BenchmarkReport/"
        const mystyle = {
            color: "transparent",
            //backgroundColor: "#0a2942",
            marginLeft: "10px"
        };
        return <>
            <WhiteLink href={url} target="legacy" style={mystyle} title="View in legacy finder">Legacy Version</WhiteLink>
            </>

	}
    const [
        pageDataIsLoading,
        pageDataHasError,
        getPageData,
    ] = useGet();
    // const [loading, setLoading] = useState(false);
    const [pageContent, setPageContent] = useState({} as BenchmarkReport);

    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });

      const url = `${apiUrl}/reports/benchmarks/` + currentEntity;
      useEffect(() => {
        getPageData(`${apiUrl}/reports/benchmarks/${currentEntity}`, (data: BenchmarkReport) => {
			setPageContent(data);
        });
    }, [getPageData, currentEntity]);

   
    //These columns have a text box for filtering. For example, in the "Policy" column type in "Recommended" to only show Labels that are not required but are recommended in the Minimum data policy. 
    return (
        <LoadingOrError
            isLoading={pageDataIsLoading}
            hasError={pageDataHasError}>
             <Banner
                icon={pageIcon}
                fallBackIcon={pageIcon}
                title={"Credential Benchmark Report"}
                subTitle={null}
                content={
					<div>This report is a count of CTDL terms by class published to the Credential Registry. Select any of the classes from the top of this page. The "Label" column represents the CTDL terms. The "Policy" column is based on the <a href="https://credreg.net/registry/policy#mindata" style={{ color: 'white' }} target="external">Credential Registry Minimum Data Policy</a>. The policy also identifies <a href="https://credreg.net/registry/benchmarks" style={{ color: 'white' }} target="external">Recommended Benchmarks</a> as models to include more comprehensive data that includes information valuable to different consumers, providers, employers, and agencies. The "Policy" column also includes some other types:
                        <div>
                            <ul>
                            <li>Requires OwnedBy or OfferedBy - At least of one OwnedBy or OfferedBy must be provided.</li>
                            <li>Required Conditionally - At least one of the properties that are required conditionally must be entered.</li>
                            </ul>
                        </div>   
                        <small><span  >{getLegacyLink()}</span></small>                 
                    </div>                    
                }        
            />
            <Wrapper>
   <VStack spacing={isMobile ? '0px' : '20px'}>
				<ReportTabButtons>
					<ReportTabButton onClick={setCurrentEntity} currentEntity={currentEntity} broadType="credential" label="Credential" icon={credentials_icon}  />
					<ReportTabButton onClick={setCurrentEntity} currentEntity={currentEntity} broadType="organization" label="Organization" icon={organizations_icon}  />
					<ReportTabButton onClick={setCurrentEntity} currentEntity={currentEntity} broadType="AssessmentProfile" label="Assessment" icon={assessments_icon} />
					<ReportTabButton onClick={setCurrentEntity} currentEntity={currentEntity} broadType="LearningOpportunity" label="Learning Opportunity" icon={learning_opportunities_icon}  />
                    
                    <ReportTabButton onClick={setCurrentEntity} currentEntity={currentEntity} broadType="CostManifest" label="Cost Manifest" icon={costmanifest_icon}  />
                    <ReportTabButton onClick={setCurrentEntity} currentEntity={currentEntity} broadType="ConditionManifest" label="Condition Manifest" icon={conditionmanifest_icon}  />
                    <ReportTabButton onClick={setCurrentEntity} currentEntity={currentEntity} broadType="Pathway" label="Pathway" icon={pathways_icon} />
                    <ReportTabButton onClick={setCurrentEntity} currentEntity={currentEntity} broadType="TransferValue" label="Transfer Value" icon={transfervalue_icon} />
                    <ReportTabButton onClick={setCurrentEntity} currentEntity={currentEntity} broadType="TransferIntermediary" label="Transfer Intermediary" icon={transferintermediary_icon} />
                        <ReportTabButton onClick={setCurrentEntity} currentEntity={currentEntity} broadType="SupportService" label="Support Service" icon={transferintermediary_icon} />
				</ReportTabButtons>


             
                <div style={{marginLeft: '50px'}} >
         
                    {pageContent.BenchmarkType && (
                        <h2>
                            {pageContent.BenchmarkType}
                        </h2>
                    )}
                    {pageContent.Benchmarks && (
                        <div>
                            <div>
                                <div style={{width: '20%',display: 'inline-block', fontWeight: 'bold'}} >Label</div>
                                <div style={{width: '20%',display: 'inline-block', fontWeight: 'bold'}} >Policy</div>
                                <div style={{width: '15%',display: 'inline-block', fontWeight: 'bold'}} >Total</div>
                                <div style={{width: '15%',display: 'inline-block', fontWeight: 'bold'}} >Percent Of Overall Total</div>
                            </div>
                            <div>
                                {pageContent.Benchmarks.map((profile) => (         
                                    <div>
                                    <div style={{width: '20%', display: 'inline-block'}} >{profile.Label}</div>
                                    <div style={{width: '20%',display: 'inline-block'}} >{profile.Policy}</div>
                                    <div style={{width: '15%',display: 'inline-block'}} >{profile.Total.toLocaleString()}</div>
                                    <div style={{width: '15%',display: 'inline-block'}} >{profile.PercentOfOverallTotal}</div>
                                    </div>
                                ))} 
                            </div>
                        </div>
                    )}	
     
                    </div>
                </VStack>
               
            </Wrapper>
        </LoadingOrError>
    );
}


function ReportTabButton(props: { broadType: string, currentEntity: string, label: string, icon: string, onClick: Dispatch<SetStateAction<string>> }) {
	return (
		<StyledReportTabButton onClick={() => props.onClick(props.broadType)} className={props.currentEntity == props.broadType ? "currentEntity" : ""}>
			<img alt="" src={props.icon} />
			<span>{props.label}</span>
		</StyledReportTabButton>
	)
}

const StyledReportTabButton = styled.button`
	display: flex;
	align-items: center;
	padding: 10px 20px;
	background-color: transparent;
	border: none;
	color: inherit;
	border-bottom: 2px solid transparent;

	&:hover, &:focus {
		background-color: rgba(255, 255, 255, 0.2);
	}

	&.currentEntity {
		opacity: 1;
		font-weight: bold;
		border-color: ${(props) => props.theme.color.aquaDark};
	}

	& img {
		width: 30px;
		margin-right: 10px;
	}

	@media(max-width: 675px){
		width: 100%;
		text-align: left;
		border-bottom: none;
		border-left: 5px solid transparent;
	}
`;
const ReportTabButtons = styled.div`
	display: flex;
	align-items: stretch;
	margin: 0 auto;
    background-color: ${(props) => props.theme.color.greyLight};
	@media(max-width: 1000px) {
		order: 3;
	}

	@media(max-width: 675px){
		display: block;
		margin: 0;
		width: 100%;
	}
`;