import React, { ReactComponentElement, ReactNode } from 'react';
import ActivityIndicator from '../ActivityIndicator';
import { Wrapper } from './styles';
import Error from '../Error';

interface Props {
    isLoading: boolean;
    hasError: boolean;
    children: ReactNode;
    loadingComponent?: ReactNode;
    errorComponent?: ReactNode;
}

const defaultLoadingComponent = (
    <Wrapper>
        <ActivityIndicator />
    </Wrapper>
);

const defaultErrorComponent = <Error />;

export default function LoadingOrError({
    isLoading,
    hasError,
    children,
    loadingComponent,
    errorComponent,
}: Props) {
    if (isLoading) {
        if (loadingComponent) {
            return <>{loadingComponent}</>;
        }
        return defaultLoadingComponent;
    }

    if (hasError) {
        if (errorComponent) {
            return <>{errorComponent}</>;
        }
        return defaultErrorComponent;
    }

    return <>{children}</>;
}
