import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import pathwayIcon from '../../assets/images/icons/icon-pathways-green-blue-01.svg';
import pageIcon from '../../assets/images/icons/icon-pathwaysets-white-green-01.svg';
import Banner from '../../components/Banner';
import { WhiteLink } from '../../components/Banner/styles';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import LinkObject from '../../components/DetailPage/LinkObject';
import OutlineList from '../../components/DetailPage/Outline';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import {
    Description,
    Label,
    Wrapper
} from '../../components/DetailPage/styles';
import HasAnyFilled from '../../components/HasAnyFilled';
import LoadingOrError from '../../components/LoadingOrError';
import PageSection from '../../components/PageSection';
import PageSectionItem from '../../components/PageSectionItem';
import { VStack } from '../../components/Stack';
import { useGet, useMediaQuery } from '../../hooks';
import { apiUrl, legacySourceUrl } from '../../shared/globals';
import { theme } from '../../shared/theme';
import { PathwaySet } from '../../types/external';
import { detailSectionProperties } from '../../utils';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";



interface Props {
    id: string;
}

export default function PathwaySetPage(props: RouteComponentProps<Props>) {
    const id = props.match.params.id;
    const [
        pageDataIsLoading,
        pageDataHasError,
        getPageData,
    ] = useGet();
    const [pageModel, setPageData] = useState({} as PathwaySet);
    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });

    useEffect(() => {
        getPageData(`${apiUrl}/PathwaySet/${id}`, (data: PathwaySet) => {
            setPageData(data);
        });
    }, [getPageData, id]);
    //

    function getSubTitleLink() {
        if (pageModel.OwnedByLabel && pageModel.OwnedByLabel.Label) {
            return pageModel.OwnedByLabel.URL ?
                <WhiteLink href={pageModel.OwnedByLabel.URL}>{pageModel.OwnedByLabel.Label}</WhiteLink> :
                <>{pageModel.OwnedByLabel.Label}</>;
        }
        return null;
    }

    function getLegacyLink() {
        if (pageModel.Meta_Id) {
            const url = legacySourceUrl + "/" + pageModel.BroadType + "/" + pageModel.Meta_Id;
            const mystyle = {
                color: "transparent",
                marginLeft: "10px"
            };
            return <>
                <WhiteLink href={url} target="legacy" style={mystyle} title="View in services application">Legacy</WhiteLink>
            </>

        }
        return null;
	}

	var typeLabel = widgetGetSingleLabelForText("Pathway Set");

    return (
        <LoadingOrError
            isLoading={pageDataIsLoading}
            hasError={pageDataHasError}>

            <Banner
                icon={pageIcon}
                fallBackIcon={pageIcon}
                type={pageModel.CTDLTypeLabel}
                title={pageModel.Name}
                subTitle={getSubTitleLink()}
                content={
                    <small>Last updated: {pageModel.Meta_LastUpdatedHeader} <span  >{getLegacyLink()}</span></small>
                }
            />
            <Wrapper>
                <VStack spacing={isMobile ? '0px' : '20px'}>
                    <HasAnyFilled
                        data={pageModel}
                        keys={detailSectionProperties.assessmentAbout}>
						<PageSection
							icon={aboutIcon}
							title={"About this " + typeLabel}
							variant="Highlight"
							description={"Basic information about this " + typeLabel}>
                            <PageSectionItem>
                                {pageModel.Description && (
                                    <Description>
                                        {pageModel.Description}
                                    </Description>
                                )}
                                <VStack spacing="10px">
                                    {pageModel.SubjectWebpage &&
                                        <LinkObject item={{ URL: pageModel.SubjectWebpage, Label: "View this " + typeLabel }} />
                                    }
                                </VStack>
                            </PageSectionItem>

                        </PageSection>
                    </HasAnyFilled>
					<AdditionalInformationPageSection item={pageModel} pageSectionDescription={"Identifiers and other information for the " + typeLabel} />
                </VStack>
                <VStack spacing={isMobile ? '0px' : '20px'}>
                    {pageModel.HasPathway &&
						<PageSection icon={pathwayIcon} title="Pathways" description={"Pathways part of this " + typeLabel}>
                        <OutlineList items={pageModel.HasPathway?.Values} />
                        </PageSection>
                    }


					<RelatedOrganizationPageSection item={pageModel} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " that own or approve of the " + typeLabel} />

                </VStack>
            </Wrapper>
        </LoadingOrError>
    );
}
//