import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';
import LoadingOrError from '../../components/LoadingOrError';
import PageSection from '../../components/PageSection';
import PageSectionItem from '../../components/PageSectionItem';
import { apiUrl } from '../../shared/globals';
import { theme } from '../../shared/theme';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import credentialIcon from '../../assets/images/icons/icon-credentials-white-green-01.svg';
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';
import processIcon from '../../assets/images/icons/icon-process-blue-green-01.svg';
import costIcon from '../../assets/images/icons/icon-costs-and-time-blue-green-01.svg';
import { VStack } from '../../components/Stack';
import { useMediaQuery, useGet } from '../../hooks';
import { Credential, Assessment, LearningOpportunity, ConditionManifest, CostManifest, ProcessProfile, DataSetProfile, AggregateDataProfile, Link } from '../../types/external';
import { ModalType } from '../../types/internal';
import { ModalButtonAndWindow } from '../../components/Modal';
import HasAnyFilled from '../../components/HasAnyFilled';
import {
    MobileAboutDetail,
    Section,
    Wrapper,
    Label,
    Inline,
	InlineLabel,
	Description
} from '../../components/DetailPage/styles';
import AboutDetail from './AboutDetail';
import ConnectionsSection           from '../../components/DetailPage/ConnectionsSection';
import DurationProfileList from '../../components/DetailPage/DurationProfile';
import FinancialAssistance from '../../components/DetailPage/FinancialAssistance';
import JurisdictionProfileList from '../../components/DetailPage/JurisdictionProfile';
import LinkObject, { PlainTextLink } from '../../components/DetailPage/LinkObject';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import LocationPageSection from '../../components/DetailPage/LocationPageSection';
import OccupationsAndIndustriesSection from '../../components/DetailPage/OccupationsAndIndustriesSection';
import OutlineList from '../../components/DetailPage/Outline';
import PageBanner from '../../components/DetailPage/PageBanner';
import QualityAssurancePageSection from '../../components/DetailPage/QualityAssurancePageSection';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import RevocationProfileList from '../../components/DetailPage/RevocationProfile';
import SubjectsAndKeywordsPageSection from '../../components/DetailPage/SubjectsAndKeywordsPageSection';
import { detailSectionProperties, isCredential } from '../../utils';
import { isFilled } from '../../validation';
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import ConditionProfileList, { getConditionProfileListButtonName } from '../../components/DetailPage/ConditionProfile';
import ConditionManifestList from '../../components/DetailPage/ConditionManifest';
import CostManifestList from '../../components/DetailPage/CostManifest';
import CostProfileList from '../../components/DetailPage/CostProfile';
import ProcessProfileList from '../../components/DetailPage/ProcessProfile';
import { OutcomesDataPageSectionItem } from '../../components/DetailPage/OutcomesDataPageSection';
import { renderJSONLDMetaTags } from '../../utils/JSONLDMetaHelper';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";
import { LinkItem } from '../../components/DetailPage/Shared';


interface Props {
    id: string;
}

export default function CredentialPage(props: RouteComponentProps<Props>) {
    const id = props.match.params.id;
    const [dataIsLoading, hasError, getData] = useGet();
    const [pageModel, setCredential] = useState({} as Credential);
    const [openedModal, setOpenedModal] = useState<ModalType>();
    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });

    function onOpenModalClick(modalType: ModalType) {
        return () => setOpenedModal(modalType);
    }

    function onCloseModalClick() {
        setOpenedModal(undefined);
    }

    useEffect(() => {
        getData(`${apiUrl}/credential/${id}`, (data: Credential) => {
			setCredential(data);
			renderJSONLDMetaTags(data, "Credential");
        });
	}, [getData, id]);

	var typeLabel = widgetGetSingleLabelForText("Credential");
	var typeLabelPlural = widgetGetPluralLabelForText("Credentials");

    return (
        <LoadingOrError isLoading={dataIsLoading} hasError={hasError}>
            <PageBanner
                item={pageModel}
                bannerIcon={pageModel.Image || pageModel.Meta_Icon || ''}
                fallbackIcon={credentialIcon}
            />
            <Wrapper>
                <VStack spacing={isMobile ? '0px' : '20px'}>
                    <HasAnyFilled
                        data={pageModel}
                        keys={[
                            ...detailSectionProperties.credentialAbout,
                            ...detailSectionProperties.credentialAboutConnections,
                        ]}>
                        <PageSection
							icon={aboutIcon}
							title={"About this " + typeLabel}
							variant="Highlight"
							description={"Basic information about the " + typeLabel}>
                            {pageModel.Description && (
                                <Description>
                                    {pageModel.Description}
                                </Description>
                            )}
                            <PageSectionItem>
								{pageModel.AvailableOnlineAt?.length > 0 &&
									pageModel.AvailableOnlineAt.map((url) => {
										<LinkObject item={{ URL: url, Label: "Available Online" }} />
									})
								}
								{pageModel.SubjectWebpage &&
									<LinkObject item={{ URL: pageModel.SubjectWebpage, Label: "View this " + typeLabel}} />
								}
								{pageModel.InCatalog &&
									<LinkObject item={{
										URL: pageModel.InCatalog, Label: "View this " + typeLabel + "in the Course Catalog " }} />
								}
								{pageModel.IsNonCredit !== undefined && pageModel.IsNonCredit == true && (
									<Inline>
										<InlineLabel>Is Non-Credit :</InlineLabel>
										<div>
											{pageModel.IsNonCredit
												? 'true'
												: 'false'}
										</div>
									</Inline>
								)}
							</PageSectionItem>
							{(pageModel.LatestVersion?.URL || pageModel.PreviousVersion?.URL || pageModel.NextVersion?.URL || pageModel.Supersedes?.URL || pageModel.SupersededBy?.URL) &&
								<PageSectionItem>
									<Label>Versions</Label>
									<PageSectionItem>
										<LinkItem label="Latest Version:" item={pageModel.LatestVersion} />
										<LinkItem label="Previous Version:" item={pageModel.PreviousVersion} />
										<LinkItem label="Next Version:" item={pageModel.NextVersion} />
										<LinkItem label="Supersedes:" item={pageModel.Supersedes} />
										<LinkItem label="Superseded By:" item={pageModel.SupersededBy} />
									</PageSectionItem>
								</PageSectionItem>
							}
							<OutcomesDataPageSectionItem aggregateDataProfiles={pageModel.AggregateData} dataSetProfiles={pageModel.ExternalDataSetProfiles} resourceTitle={pageModel.Name} />
                            <AboutDetail
                                credential={pageModel}
                                onOpenModalClick={onOpenModalClick}
                                onCloseModalClick={onCloseModalClick}
                                openedModal={openedModal}
                            />
                        </PageSection>
                        {isMobile && (
                            <MobileAboutDetail>
                                <AboutDetail
                                    credential={pageModel}
                                    onOpenModalClick={onOpenModalClick}
                                    onCloseModalClick={onCloseModalClick}
                                    openedModal={openedModal}
                                />
                            </MobileAboutDetail>
                        )}
                    </HasAnyFilled>
                    <HasAnyFilled
                        data={pageModel}
                        keys={[
                            ...detailSectionProperties.credentialCostAndTime,
                            ...detailSectionProperties.credentialCostAndTimeRenewal,
                            ...detailSectionProperties.credentialRequirements
                        ]}>
                        <PageSection
							icon={costIcon}
							title="Requirements, Costs, and Time"
							description={"How to earn the " + typeLabel}>
							<HasAnyFilled
								data={pageModel}
								keys={[
									...detailSectionProperties.credentialRequirements
								]}>
								<PageSectionItem>
									<Label>Requirements and Recommendations</Label>
									<ModalButtonAndWindow
										buttonLabel="Common Conditions"
										resourceTitle={pageModel.Name}
										items={pageModel.CommonConditions}
										Wrapper={Section}
										Content={() => <ConditionManifestList items={pageModel.CommonConditions} />}
									/>
									<ModalButtonAndWindow
										//buttonLabel="Requires"
										buttonLabel={getConditionProfileListButtonName("Requires", pageModel.Requires)}
										hideCount={true}
										resourceTitle={pageModel.Name}
										items={pageModel.Requires}
										Wrapper={Section}
										Content={() => <ConditionProfileList items={pageModel.Requires} />}
									/>
									<ModalButtonAndWindow
										//buttonLabel="Recommends"
										buttonLabel={getConditionProfileListButtonName("Recommends", pageModel.Recommends)}
										hideCount={true}
										resourceTitle={pageModel.Name}
										items={pageModel.Recommends}
										Wrapper={Section}
										Content={() => <ConditionProfileList items={pageModel.Recommends} />}
									/>
									<ModalButtonAndWindow
										//buttonLabel="Corequisite"
										buttonLabel={getConditionProfileListButtonName("Corequisite With", pageModel.Corequisite)}
										hideCount={true}
										resourceTitle={pageModel.Name}
										items={pageModel.Corequisite}
										Wrapper={Section}
										Content={() => <ConditionProfileList items={pageModel.Corequisite} />}
									/>
									<ModalButtonAndWindow
										//buttonLabel="CoPrerequisite"
										buttonLabel={getConditionProfileListButtonName("Co-Prerequisite With", pageModel.CoPrerequisite)}
										hideCount={true}
										resourceTitle={pageModel.Name}
										items={pageModel.CoPrerequisite}
										Wrapper={Section}
										Content={() => <ConditionProfileList items={pageModel.CoPrerequisite} />}
									/>
								</PageSectionItem>
							</HasAnyFilled>

							{isFilled(pageModel.RenewalFrequency) && (
								<PageSectionItem>
									<Label>Renewal Frequency</Label>
									<div>{pageModel.RenewalFrequency}</div>
								</PageSectionItem>
							)}
							<HasAnyFilled
								data={pageModel}
								keys={
									detailSectionProperties.credentialCostAndTimeRenewal
								}>
								<PageSectionItem>
									<Label>Renewal and Revocation</Label>
									<ModalButtonAndWindow
										buttonLabel="Renewal Conditions"
										resourceTitle={pageModel.Name}
										items={pageModel.Renewal}
										Wrapper={Section}
										Content={() => <ConditionProfileList items={pageModel.Renewal} />}
									/>
									<ModalButtonAndWindow
										buttonLabel="Renewed In"
										resourceTitle={pageModel.Name}
										items={pageModel.RenewedIn}
										Wrapper={Section}
										Content={() => <JurisdictionProfileList items={pageModel.RenewedIn} />}
										hideCount={true}
									/>								
									<ModalButtonAndWindow
										buttonLabel="Revoked In"
										resourceTitle={pageModel.Name}
										items={pageModel.RevokedIn}
										Wrapper={Section}
										Content={() => <JurisdictionProfileList items={pageModel.RevokedIn} />}
										hideCount={true}
									/>
									<ModalButtonAndWindow
										buttonLabel="Revocation"
										resourceTitle={pageModel.Name}
										items={pageModel.Revocation?.Values}
										Wrapper={Section}
										Content={() => <RevocationProfileList items={pageModel.Revocation?.Values} />}
									/>
								</PageSectionItem>
							</HasAnyFilled>
							{(isFilled(pageModel.CommonCosts) || isFilled(pageModel.EstimatedCost) || isFilled(pageModel.AssessmentCost) || isFilled(pageModel.LearningOpportunityCost) || isFilled(pageModel.FinancialAssistance)) &&
								<PageSectionItem>
									<Label>Cost Information</Label>
									<ModalButtonAndWindow
										buttonLabel="Common Costs"
										resourceTitle={pageModel.Name}
										items={pageModel.CommonCosts}
										Wrapper={Section}
										Content={() => <CostManifestList items={pageModel.CommonCosts} />}
									/>
									<ModalButtonAndWindow
										buttonLabel={(pageModel.CommonCosts?.length > 0 ? "Specific Costs" : typeLabel + " Costs")}
										resourceTitle={pageModel.Name}
										items={pageModel.EstimatedCost}
										Wrapper={Section}
										Content={() => <CostProfileList items={pageModel.EstimatedCost} />}
									/>
								<ModalButtonAndWindow
										buttonLabel={"Required " + widgetGetSingleLabelForText("Assessment") + " Costs"}
										resourceTitle={pageModel.Name}
										items={pageModel.AssessmentCost}
										Wrapper={Section}
										Content={() => <CostProfileList items={pageModel.AssessmentCost} />}
									/>
									<ModalButtonAndWindow
										buttonLabel={"Required " + widgetGetSingleLabelForText("Learning Opportunity") + " Costs"}
										resourceTitle={pageModel.Name}
										items={pageModel.LearningOpportunityCost}
										Wrapper={Section}
										Content={() => <CostProfileList items={pageModel.LearningOpportunityCost} />}
									/>
									<ModalButtonAndWindow
										buttonLabel="Financial Assistance"
										resourceTitle={pageModel.Name}
										items={pageModel.FinancialAssistance}
										Wrapper={Section}
										Content={() => <FinancialAssistance item={pageModel} />}
									/>
								</PageSectionItem>
							}

                            
							{isFilled(pageModel.EstimatedDuration) && (
								<PageSectionItem>
									<Label>Time to Complete</Label>
									<DurationProfileList items={pageModel.EstimatedDuration} />
								</PageSectionItem>
							)}
                            {isFilled(pageModel.AssessmentEstimatedDuration) && (
                                <PageSectionItem>
									<Label>Estimated Time to Complete Required {widgetGetPluralLabelForText("Assessments")}</Label>
									{pageModel.AssessmentEstimatedDuration?.Values.map((item: any) => (
										<DurationProfileSourceWrapper>
											<div><a href={item.URL} target="_blank">{item.Name}</a></div>
											<DurationProfileList items={item.EstimatedDuration} />
										</DurationProfileSourceWrapper>
									))}
                                </PageSectionItem>
                            )}
                             {isFilled(pageModel.LearningOpportunityEstimatedDuration) && (
                                <PageSectionItem>
                                    <Label>Estimated Time to Complete Required {widgetGetPluralLabelForText("Learning Opportunities")}</Label>
									{pageModel.LearningOpportunityEstimatedDuration?.Values.map((item: any) => (
										<DurationProfileSourceWrapper>
											<div><a href={item.URL} target="_blank">{item.Name}</a></div>
											<DurationProfileList items={item.EstimatedDuration} />
										</DurationProfileSourceWrapper>
									))}
                                </PageSectionItem>
							)}

                        </PageSection>
                    </HasAnyFilled>
					<SubjectsAndKeywordsPageSection item={pageModel} pageSectionDescription={"Subjects and Keywords related to the " + typeLabel} />
					<AdditionalInformationPageSection item={pageModel} pageSectionDescription={"Identifiers and other information for the " + typeLabel} />
                </VStack>
                <VStack>
					<RelatedOrganizationPageSection item={pageModel} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " that own, offer, or perform other services related to the " + typeLabel} />
					<HasAnyFilled
						data={pageModel}
						keys={[
							...detailSectionProperties.entityConnections,
							...detailSectionProperties.credentialAboutConnections,
						]}>
						<PageSection icon={connectionsIcon} title="Connections" description="Related Resources">
							<PageSectionItem>
								<ModalButtonAndWindow
									//buttonLabel={pageModel.HasPart?.Label || "Contains " + (pageModel.HasPart?.Values?.length || 0) + " Credentials"}
									buttonLabel={"Includes " + (pageModel.HasPart?.Total || 0) + " " + ((pageModel.HasPart?.Values?.length || 0) == 1 ? typeLabel : typeLabelPlural)}
									resourceTitle={pageModel.Name}
									items={pageModel.HasPart?.Values}
									Wrapper={Section}
									Content={() => <OutlineList items={pageModel.HasPart?.Values} />}
									hideCount={true}
								/>
								<ModalButtonAndWindow
									//buttonLabel={pageModel.IsPartOf?.Label || "Is Part of " + (pageModel.IsPartOf?.Values?.length || 0) + " Credentials"}
									buttonLabel={"Is Part Of " + (pageModel.IsPartOf?.Total || 0) + " " + ((pageModel.IsPartOf?.Values?.length || 0) == 1 ? typeLabel : typeLabelPlural)}
									resourceTitle={pageModel.Name}
									items={pageModel.IsPartOf?.Values}
									Wrapper={Section}
									Content={() => <OutlineList items={pageModel.IsPartOf?.Values} />}
									hideCount={true}
								/>
								<ConnectionsSection
									item={pageModel}
									openedModal={openedModal}
									onOpenModalClick={onOpenModalClick}
									onCloseModalClick={onCloseModalClick}
								/>
							</PageSectionItem>
						</PageSection>
					</HasAnyFilled>
                    <QualityAssurancePageSection
                        item={pageModel}
                        openedModal={openedModal}
                        onOpenModalClick={onOpenModalClick}
						onCloseModalClick={onCloseModalClick}
						pageSectionDescription="Quality Assurance for the Credential"
                    />
					<OccupationsAndIndustriesSection item={pageModel} pageSectionDescription={"Career Field information about the " + typeLabel} itemTypeLabel={typeLabelPlural} />
					<LocationPageSection item={pageModel} pageSectionDescription={"Geographic information about the " + typeLabel} />
                    <HasAnyFilled
                        data={pageModel}
                        keys={detailSectionProperties.credentialProcess}>
						<PageSection icon={processIcon} title="Process" description={"Processes related to the " + typeLabel}>
                            <PageSectionItem>
                                {pageModel.ProcessStandards && (
                                    <LinkObject item={pageModel.ProcessStandards} />
                                )}                                
                            </PageSectionItem>                       
                            
                            <HasAnyFilled
                                data={pageModel}
                                keys={detailSectionProperties.credentialProcessProfiles}>
                                <PageSectionItem> 
									<ModalButtonAndWindow
										buttonLabel="Administration Process"
										getDataOnClickFromURL={pageModel.AdministrationProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Administration Process"
										resourceTitle={pageModel.Name}
										items={[]}
										overrideCount={pageModel.AdministrationProcess?.Total}
									/>
									<ModalButtonAndWindow
										buttonLabel="Appeal Process"
										getDataOnClickFromURL={pageModel.AppealProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Appeal Process"
										resourceTitle={pageModel.Name}
										items={[]}
										overrideCount={pageModel.AppealProcess?.Total}
									/>
									<ModalButtonAndWindow
										buttonLabel="Development Process"
										getDataOnClickFromURL={pageModel.DevelopmentProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Development Process"
										resourceTitle={pageModel.Name}
										items={[]}
										overrideCount={pageModel.DevelopmentProcess?.Total}
									/>
									<ModalButtonAndWindow
										buttonLabel="Maintenance Process"
										getDataOnClickFromURL={pageModel.MaintenanceProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Maintenance Process"
										resourceTitle={pageModel.Name}
										items={[]}
										overrideCount={pageModel.MaintenanceProcess?.Total}
									/>
									<ModalButtonAndWindow
										buttonLabel="Complaint Process"
										getDataOnClickFromURL={pageModel.ComplaintProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Complaint Process"
										resourceTitle={pageModel.Name}
										items={[]}
										overrideCount={pageModel.ComplaintProcess?.Total}
									/>
									<ModalButtonAndWindow
										buttonLabel="Review Process"
										getDataOnClickFromURL={pageModel.ReviewProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Review Process"
										resourceTitle={pageModel.Name}
										items={[]}
										overrideCount={pageModel.ReviewProcess?.Total}
									/>
									<ModalButtonAndWindow
										buttonLabel="Revocation Process"
										getDataOnClickFromURL={pageModel.RevocationProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Revocation Process"
										resourceTitle={pageModel.Name}
										items={[]}
										overrideCount={pageModel.RevocationProcess?.Total}
									/>
                                </PageSectionItem>
                            </HasAnyFilled>
                        
                        </PageSection>
					</HasAnyFilled>
                </VStack>
            </Wrapper>
        </LoadingOrError>
	);

}

const DurationProfileSourceWrapper = styled.div`
	& .truncatedList {
		padding-left: 25px;
	}
`;