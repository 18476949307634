import React from 'react';
import PageSectionItem from '../../components/PageSectionItem';
import { Label } from '../../components/DetailPage/styles';
import { Organization } from '../../types/external';
import SearchPills, { AllPills } from '../../components/SearchPills';
import { isFilled } from '../../validation';
import DisplayPills from '../../components/PillDisplay';
interface Props {
    org: Organization;
}

// This is split out because we need to render it a second time on mobile devices.
export default function AboutDetail({ org }: Props) {
    return (
        <>
            {isFilled(org.AgentType) && (
                <PageSectionItem>
                    <Label>Organization Type</Label>
                    <SearchPills links={org.AgentType} />
                </PageSectionItem>
            )}
            {org.LifeCycleStatusType && (
                <PageSectionItem>
                    <Label>Life Cycle Status</Label>
                    <DisplayPills links={[org.LifeCycleStatusType]} />
                </PageSectionItem>
            )}
            {isFilled(org.AlternateName) && (
                <PageSectionItem noBorder>
                    <Label>Alternate Name(s)</Label>
                    {org.AlternateName.map((name, index) => (
                        <div key={`cr-an-${index}`}>{name}</div>
                    ))}
                </PageSectionItem>
            )}
            {isFilled(org.Connections) && (
                <PageSectionItem>
                    <Label>Connections</Label>
                    <AllPills links={org.Connections} />
                </PageSectionItem>
            )}
        </>
    );
}
