import React from 'react';
import styled from 'styled-components/macro';
import { Label, Section } from './styles';
import { Link } from '../../types/external';
import { ButtonLink } from '../common';
import { appURL } from '../../shared/globals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

export default function LinkObject(props: { item: Partial<Link>, overrideLabel?: string, hideExternalLinkIcon?: boolean, buttonType?: 'primary' | 'secondary' | 'tertiary' | 'filled' | undefined, isExternal?: boolean, linkTarget?: string }) {
	var isExternal = props.isExternal || isExternalURL(props.item?.URL);
	var label = props.item.Label || "Link";
	var linkButtonType = props.buttonType || "primary";
    return (
        <>
			{props.item.URL &&
				<ButtonLink target={props.linkTarget || (isExternal ? "external" : undefined)} buttonType={linkButtonType} href={props.item.URL}>
					{label}{isExternal && <SmallIcon icon={faExternalLinkAlt} />}
				</ButtonLink>
			}
			{!props.item.URL &&
				<Label>{label}</Label>
			}
			{props.item.Description &&
				<Section>
					<div>{props.item.Description}</div>
				</Section>
			}
        </>
    );
}

export function PlainTextLink(props: { item: Partial<Link>, overrideLabel?: string, hideExternalLinkIcon?: boolean }) {
	var isExternal = isExternalURL(props.item?.URL);

	return ( props.item?.URL &&
		<a href={props.item.URL} target={isExternal ? "_blank" : undefined}>{props.overrideLabel || props.item.Label || "Link"}{isExternal && <SmallIcon icon={faExternalLinkAlt} />}</a>
	) || null
}

export function PlainTextLinkList(props: { label: string, items: Array<Link>, WrapperTag?: (children: JSX.ElementChildrenAttribute) => JSX.Element, HeaderTag?: (children: JSX.ElementChildrenAttribute) => JSX.Element, asList?: boolean }) {
	var Wrapper = props.WrapperTag || PlainTextLinkListWrapper;
	var Header = props.HeaderTag || PlainTextLinkListHeader;
	return props.items?.length > 0 && (
		<Wrapper>
			<>
				<Header>{props.label}</Header>
				{props.asList == true ? (
					<ul>
						{props.items.map(item => (
							<li><PlainTextLink item={item} /></li>
						))}
					</ul>
				) : (
					props.items.map(item => (
						<PlainTextLink item={item} />
					))
				)}
			</>
		</Wrapper>
	) || null;
}

export function PlainTextLinkListFromURLs(props: { label: string, urls: Array<string>, WrapperTag?: (children: JSX.ElementChildrenAttribute) => JSX.Element, HeaderTag?: (children: JSX.ElementChildrenAttribute) => JSX.Element, asList?: boolean }) {
	return <PlainTextLinkList label={props.label} items={(props.urls || []).map(url => { return { URL: url, Label: url } as Link })} WrapperTag={props.WrapperTag} HeaderTag={props.HeaderTag} asList={props.asList} />
}

export function isExternalURL(url: string | undefined) : boolean {
	if (!url) {
		return false;
	}

	var lowerURL = url.toLowerCase();
	return lowerURL.indexOf("http") == 0 && lowerURL.indexOf(appURL.toLowerCase()) != 0;
}

const SmallIcon = styled(FontAwesomeIcon)`
	font-size: 85%;
	opacity: 0.85;
	margin-left: 0.4em;
`;

const PlainTextLinkListWrapper = styled.div`

`;

const PlainTextLinkListHeader = styled.div`
	font-weight: bold;
`;