import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import {
    FontAwesomeIcon,
    FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../shared/theme';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
    display: inline-block;
    animation: ${rotate} 2s linear infinite;
`;

interface Props {
    size?: FontAwesomeIconProps['size'];
    color?: FontAwesomeIconProps['color'];
}

export default function ActivityIndicator({ size, color }: Props) {
    return (
        <Spinner>
            <FontAwesomeIcon
                icon={faSpinner}
                size={size || '2x'}
                color={color || theme.color.aquaDark}
            />
        </Spinner>
    );
}
