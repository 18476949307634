import React from 'react';
import styled from 'styled-components/macro';
import { Inline, InlineLabel } from "./styles";
import { PlainTextLink } from "./LinkObject";
import { Link } from "../../types/external";

/* This file is for Components shared across Modal Windows and the main Detail Page */

export const Box = styled.div`
	border: 1px solid ${(p) => p.theme.color.aquaDark};
	margin-bottom: 10px;
`;

export const BoxLabel = styled.div`
	background-color: ${(p) => p.theme.color.aquaDark};
	padding: 5px 10px;
	font-size: 18px;
	font-weight: bold;
`;

export const BoxContent = styled.div`
	padding: 10px;
`;

export const Section = styled.div`
	padding: 5px 0;

	&:not(:last-child) {
		border-bottom: 1px solid #CCC;
	}
`;

export const SectionLabel = styled.div`
	font-size: 18px;
	padding: 5px 0;
`;

export const SectionContent = styled.div`
	padding: 0 0 0 20px;
	margin-bottom: 10px;

	& .withBelow {
		margin-bottom: 0;
	}

	& .withAbove {
		margin-top: 0;
	}

`;

export function SectionContentItem<T>(props: { label: string, data: T, RenderWith: (data: T) => JSX.Element }) {
	return (props.data && (Array.isArray(props.data) ? props.data.length > 0 : true) &&
		<SectionContentItemWrapper>
			{props.label && <b>{props.label}:</b>}
			{props.RenderWith(props.data)}
		</SectionContentItemWrapper>
	) || null
}

export function LinkItem(props: { label: string, item: any }) {
	if (!props.item) {
		return null;
	}

	var link = typeof (props.item) == "string" ? ({ Label: props.item, URL: props.item } as Link) : (props.item as Link);
	return (link.URL &&
		<Inline>
			<InlineLabel>{props.label}</InlineLabel>
			<PlainTextLink item={link} />
		</Inline>
	) || null
}

//Not exported to avoid confusion with SectionContentItem
const SectionContentItemWrapper = styled.div`
	display: flex;
	align-items: center;
	min-height: 30px;

	& > * { margin-right: 10px; }
`;
