import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { SConnector } from 'react-svg-connector';
import { HStack } from '../Stack';

import background from '../../assets/images/icons/background-dot-light-blue-01.svg';
import { useMediaQuery } from '../../hooks';
import { theme } from '../../shared/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const connectorSpan = 70;

const MobileCarousel = styled.div``;

const CarouselWrapper = styled(HStack)`
    cursor: pointer;
    background-image: url(${background});
    padding: 80px 60px;
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar {
        display: none; // hide scrollbar on safaris
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: hidden;
`;

const ConnectorBlock = styled(HStack)`
    width: 100%;
    position: relative;
    svg {
        position: absolute;
    }
`;

const Block = styled.div<{ isActive?: boolean }>`
    background-color: ${(props) =>
        props.isActive ? props.theme.color.greyLight : props.theme.color.white};
    min-width: 400px;
`;

const MobileSlider = styled(HStack)`
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
`;

const MobileBlock = styled.div`
    min-width: 100%;
    scroll-snap-align: center;
`;

const ConnectorBox = styled.div`
    min-width: ${connectorSpan + 'px'};
`;

const NavArrows = styled(HStack)`
    padding: 20px;
`;

interface Props {
    currentBlock?: number;
    blocks: React.ReactNode[];
}

export default function Carousel(props: Props) {
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const sliderRef = useRef<HTMLDivElement | null>(null);
    const [divHeight, setDivHeight] = useState(0);
    const [clientX, setClientX] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);

    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });

    function onMouseDown(e: React.MouseEvent) {
        setIsScrolling(true);
        setClientX(e.clientX);
    }

    function onMouseUp(e: React.MouseEvent) {
        setIsScrolling(false);
    }

    function onMouseMove(e: React.MouseEvent) {
        if (isScrolling) {
            if (wrapperRef.current) {
                const scrollX =
                    wrapperRef.current.scrollLeft - e.clientX + clientX;
                const newClientX = e.clientX;
                wrapperRef.current.scrollLeft = scrollX;
                setClientX(newClientX);
            }
        }
    }

    const offsetY = divHeight / 10;

    function renderRightConnector() {
        return (
            <ConnectorBox>
                <SConnector
                    startPoint={{ x: 0, y: divHeight / 2 - offsetY }}
                    endPoint={{ x: connectorSpan, y: divHeight / 2 + offsetY }}
                    stroke="black"
                />
            </ConnectorBox>
        );
    }

    function renderLeftConnector() {
        return (
            <ConnectorBox>
                <SConnector
                    startPoint={{ x: 0, y: divHeight / 2 - offsetY }}
                    endPoint={{
                        x: connectorSpan,
                        y: divHeight / 2 - offsetY * 3,
                    }}
                    stroke="black"
                />
            </ConnectorBox>
        );
    }

    function slide(direction: 'next' | 'prev') {
        if (sliderRef.current) {
            const stepX = sliderRef.current.offsetWidth;
            if (direction === 'next') {
                sliderRef.current.scrollLeft =
                    sliderRef.current.scrollLeft + stepX;
            } else {
                sliderRef.current.scrollLeft =
                    sliderRef.current.scrollLeft - stepX;
            }
        }
    }

    useEffect(() => {
        if (wrapperRef.current) {
            setDivHeight(wrapperRef.current.offsetHeight);

            const newScrollX =
                (wrapperRef.current.scrollWidth / props.blocks.length) *
                (props.currentBlock ? props.currentBlock : 0);
            wrapperRef.current.scroll({
                left: newScrollX - 150,
                behavior: 'smooth',
            });
        }
    }, [wrapperRef, props.currentBlock, props.blocks]);

    if (isMobile) {
        return (
            <MobileCarousel>
                <MobileSlider ref={sliderRef}>
                    {props.blocks.map((block, index) => (
                        <MobileBlock key={`cr-carousel-mobile-block${index}`}>
                            {block}
                        </MobileBlock>
                    ))}
                </MobileSlider>
                <NavArrows spacing="20px" justify="center" align="center">
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        size="2x"
                        onClick={() => slide('prev')}
                    />
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        size="2x"
                        onClick={() => slide('next')}
                    />
                </NavArrows>
            </MobileCarousel>
        );
    }

    return (
        <CarouselWrapper
            ref={wrapperRef}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}>
            {props.blocks.map((block, index) => (
                <ConnectorBlock key={'carousel-block-' + index}>
                    <Block isActive={props.currentBlock === index}>
                        {block}
                    </Block>
                    {index % 2 !== 0
                        ? renderLeftConnector()
                        : index !== props.blocks.length - 1
                        ? renderRightConnector()
                        : null}
                </ConnectorBlock>
            ))}
        </CarouselWrapper>
    );
}
