import React from 'react';
import { MainItemBroadType } from '../../types/internal';
import { isFilled } from '../../validation';
import PageSection from '../PageSection';
import PageSectionItem from '../PageSectionItem';
import subjectIcon from '../../assets/images/icons/icon-subjects-and-keywords-blue-green-01.svg';
import { Label } from './styles';
import SearchPills, { ReferenceFrameworkPills } from '../SearchPills';
import HasAnyFilled from '../HasAnyFilled';
import { detailSectionProperties } from '../../utils';

interface Props {
	item: MainItemBroadType;
	pageSectionDescription?: string;
}

export default function SubjectsAndKeywordsPageSection(props: Props) {

	var looseItem = props.item as any;

    return (
        <HasAnyFilled
            data={props.item}
            keys={detailSectionProperties.subjectsAndKeywords}>
			<PageSection icon={subjectIcon} title="Subjects and Keywords" description={props.pageSectionDescription}>
				{isFilled(looseItem.Subject) && (
                    <PageSectionItem>
                        <Label>Subjects</Label>
						<SearchPills links={looseItem.Subject} />
                    </PageSectionItem>
                )}
				{isFilled(looseItem.InstructionalProgramType) && (
                    <PageSectionItem>
                        <Label>Instructional Programs</Label>
						<ReferenceFrameworkPills links={looseItem.InstructionalProgramType} />
                    </PageSectionItem>
                )}
				{isFilled(looseItem.Keyword) && (
					<PageSectionItem>
						<Label>Keywords</Label>
						<SearchPills links={looseItem.Keyword} />
					</PageSectionItem>
				)}

				{/* CTDL-ASN Stuff */}
				{isFilled(looseItem.LocalSubject) && (
					<PageSectionItem>
						<Label>Subjects</Label>
						<SearchPills links={looseItem.LocalSubject} />
					</PageSectionItem>
				)}
				{isFilled(looseItem.ConceptTerm) && (
					<PageSectionItem>
						<Label>Terms</Label>
						<SearchPills links={looseItem.ConceptTerm} />
					</PageSectionItem>
				)}
				{isFilled(looseItem.ConceptKeyword) && (
					<PageSectionItem>
						<Label>Keywords</Label>
						<SearchPills links={looseItem.ConceptKeyword} />
					</PageSectionItem>
				)}
            </PageSection>
        </HasAnyFilled>
    );
}
