import React from 'react';
import styled from 'styled-components/macro';
import { faFilter, faSearch, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, ReferenceFramework } from '../../types/external';
import { Wrapper, PillWrapper } from './styles';
import PillLink from '../PillLink';
import { title } from 'process';
import TruncatedItemList from '../DetailPage/TruncatedItemList';
import { widgetReplacePluralLabelInText, widgetReplaceSingleLabelInText } from '../../utils/SiteWidgetizer';

interface Props {
    links: Array<Link>;
}

export function AllPills({ links }: Props) {
    return (
        <Wrapper>
            {(links || []).map((link, index) => (
                // const title="Opens search for '" + link.Label + "' in a new window";
                <PillWrapper key={`cr-search-pill-${link.URL}-${index}}]`}>
                    <PillLink
						href={link.URL}
						target="detailPage"
                        title={widgetReplaceSingleLabelInText(widgetReplacePluralLabelInText(link.Label))}
                        label={widgetReplaceSingleLabelInText(widgetReplacePluralLabelInText(link.Label))}
                        leftIcon={<FontAwesomeIcon icon={faFilter} size="sm" />}
                        rightIcon={
                            <FontAwesomeIcon icon={faSearch} size="sm" />
                        }
                    />
                </PillWrapper>
            ))}
        </Wrapper>
    );
}

export default function Pills({ links }: Props) {
	return (
		<TruncatedItemList
			ListItemsComponent={Wrapper}
			source={links}
			renderItemWith={(link, index) => {
				return (
				// const title="Opens search for '" + link.Label + "' in a new window";
				<PillWrapper key={`cr-search-pill-${link.URL}-${index}}]`}>
					<PillLink
						href={link.URL}
						target="detailPage"
						title={widgetReplaceSingleLabelInText(widgetReplacePluralLabelInText(link.Label))}
						label={widgetReplaceSingleLabelInText(widgetReplacePluralLabelInText(link.Label))}
						leftIcon={<FontAwesomeIcon icon={faFilter} size="sm" />}
						rightIcon={
							<FontAwesomeIcon icon={faSearch} size="sm" />
						}
					/>
				</PillWrapper>)
			}}
			truncateAfter={5}
		/>
	)
}

export function ReferenceFrameworkPills(props: { links: Array<ReferenceFramework> | Array<Link>, itemTypeLabel?: string; }) {
	//Translate the incoming data to Credential Alignment Object
	//removing Unknown Framework. Have to be careful if shown with data from a framework (probably unlikely)
	var items = ((props.links as any) || []).map((link: any) => {
		return {
			Label: link.Label || link.Name || "Unknown Name",
			Description: link.Description || link.Description,
			CodedNotation: link.CodedNotation,
			TargetNode: link.TargetNode,
			URL: link.URL,
			FrameworkName: link.FrameworkName || "",
			Framework: link.Framework || "https://null"
		} as ReferenceFramework
	}) as Array<ReferenceFramework>;

	//Group the incoming data
	var frameworks = [] as Array<any>;
	items.forEach(item => {
		var framework = frameworks.find(framework => framework.URL.toLowerCase() == item.Framework?.toLowerCase()) || frameworks.find(framework => framework.Name.toLowerCase() == item.FrameworkName?.toLowerCase());
		if (!framework) {
			framework = { Name: item.FrameworkName, URL: item.Framework, links: [] as Array<ReferenceFramework> };
			frameworks.push(framework);
		}
		framework.links.push(item);
	});

	return (
		<TruncatedItemList
			ListItemsComponent={Wrapper}
			source={frameworks}
			renderItemWith={(framework: any, index) => {
				return (
					<Framework>
						<FrameworkName>
							{framework.URL == "https://null" ? framework.Name : <a href={framework.URL} target="_blank">{framework.Name}</a>}
						</FrameworkName>
						<FrameworkItemList>
							{framework.links.map((link: ReferenceFramework) => (
								<FrameworkItem>
									<FrameworkItemName>{link.Label}</FrameworkItemName>
									{link.URL && <PillLink href={link.URL} title={"Search for other " + (props.itemTypeLabel || "resources in the registry") + " with this code"} label="" rightIcon={<FontAwesomeIcon icon={faSearch} size="sm" />} />}
									{link.TargetNode && <PillLink href={link.TargetNode} title="Learn more about this code (External Link)" label="" rightIcon={<FontAwesomeIcon icon={faExternalLinkAlt} size="sm" />} target="_blank" />}
								</FrameworkItem>
							))}
						</FrameworkItemList>
					</Framework>
				)
			}}
			truncateAfter={5}
		/>
	)
}

const Framework = styled.div`

`;

const FrameworkName = styled.div`
	font-weight: bold;
`;

const FrameworkItemList = styled.div`
	padding: 0 20px;
`;

const FrameworkItem = styled.div`
	display: flex; align-items: center;
	gap: 5px;
`;

const FrameworkItemName = styled.div`
	
`