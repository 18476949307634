import { Filter, MainQueryType } from '../types/external';

export function constructSearchQuery(
    query: MainQueryType,
    filters: Filter[],
) {
	return query;
    // do logic here to return URL + filter object
    let queryObject: MainQueryType = {
        ...query
	};
	/*
    // handle MainFilters
    Object.keys(query.MainFilters).forEach((key) => {
        // merge filter so
        const queryFilter = query.MainFilters[key];
        const filterWithKey = (filters || []).find((item) => item.Id == key);
        let newFilter: Partial<Filter> = {};
        if (filterWithKey) {
            newFilter = {
                ...filterWithKey,
                Items: [],
            };
        } else {
            // this is the case for the search base URL
            newFilter = {
                Id: key == '-1' ? '' : key,
                Items: [],
            };
		}
		if ((queryFilter as any).Items) { //Widget code injects this part
			newFilter.Items = (queryFilter as any).Items;
		}
		else {
			const filterItems = queryFilter.filterItems.map((item) => {
				const { identifier, parentId, ...rest } = item;
				return rest;
			});
			newFilter.Items = filterItems;
		}

		if (queryFilter.parameters) {
			newFilter.Parameters = queryFilter.parameters;
		}
		if ((queryFilter as any).Parameters) { //Widget code injects this part
			newFilter.Parameters = (queryFilter as any).Parameters;
		}
        queryObject.MainFilters.push(newFilter);
	});
	*/

    return { ...queryObject };
}
