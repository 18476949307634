import React, { useEffect, useRef, useState } from 'react';
import { delayCallbackExecution } from '../../utils';
import { TextField } from '../common';
import { Wrapper, Suggestions, Suggestion, SuggestionWrapper } from './styles';

interface Props {
    placeholder?: string;
    value: string;
    onChange?: (value: string) => any[] | Promise<any[]>;
    getOptionLabel?: (option: any) => string;
    onSelect?: (option: any) => void;
    renderOption?: (option: any) => React.ReactNode;
	notCloseOnSelect?: boolean;
	addItemHandler?: (searchText?: string, setSearchText?: React.Dispatch<React.SetStateAction<string>>, suggestions?: any[], onSelect?: any) => void;
	fieldName?: string;
}

export default function AutoCompletionTextField(props: Props) {
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [expanded, setExpanded] = useState(false);
    const [searchText, setSearchText] = useState(props.value || '');
    const ref = useRef<HTMLDivElement | null>(null);
	const [timer, setTimer] = useState<any>(0);

    async function onSearchTextChange(value: string) {
        // TODO: set a flag to avoid 1 redundant props.onChange
        const data = props.onChange ? await props.onChange(value) : [];
        if (data.length >= 15) {
            const newSuggestions = data.slice(0, 15);
            setSuggestions(newSuggestions);
        } else {
            setSuggestions(data);
        }
        setExpanded(true);
    }

    function onChange(event: React.ChangeEvent<HTMLInputElement>) {
        const newValue = event.target.value;
        setSearchText(newValue);
        setTimer(
            delayCallbackExecution(
                timer,
                () => onSearchTextChange(newValue),
                500,
            ),
        );
    }

    function onSelect(option: any) {
        if (!props.notCloseOnSelect) {
            setExpanded(false);
        }
        props.onSelect && props.onSelect(option);
    }

    function getOptionLabel(option: any) {
        if (typeof option === 'string') {
            return option;
        } else {
            return props.getOptionLabel ? props.getOptionLabel(option) : '';
        }
    }

	function handleEnter(event: any) {
		if (props.addItemHandler && (event.which == 13 || event.keyCode == 13)) {
			props.addItemHandler(searchText, setSearchText, suggestions, onSelect);
			setExpanded(false);
		}
	}

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setExpanded(false);
        }
    };

    const handleBlur = (event: any) => {
        if (ref.current && !ref.current.contains(event.relatedTarget)) {
            setExpanded(false);
        }
    };

    useEffect(() => {
        setSearchText(props.value);
    }, [props.value]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return (
        <Wrapper>
            <TextField
				placeholder={props.placeholder}
				onChange={onChange}
				value={searchText}
				onKeyUp={handleEnter}
				data-fieldName={props.fieldName}
            />
            <Suggestions ref={ref} onBlur={handleBlur}>
                {expanded && (
                    <Suggestions>
                        {suggestions.map((option, index) =>
                            props.renderOption ? (
                                <SuggestionWrapper
                                    key={`cr-dr-option-${index}`}
                                    onClick={() => onSelect(option)}>
                                    {props.renderOption(option)}
                                </SuggestionWrapper>
                            ) : (
                                <Suggestion
                                    key={`cr-dr-option-${index}`}
                                    onClick={() => onSelect(option)}>
                                    {getOptionLabel(option)}
                                </Suggestion>
                            ),
                        )}
                    </Suggestions>
                )}
            </Suggestions>
        </Wrapper>
    );
}
