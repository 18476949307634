import React from 'react';
import styled from 'styled-components/macro';
import additionalInfoIcon from '../../assets/images/icons/icon-additional-information-blue-green-01.svg';
import PageSection from '../PageSection';
import PageSectionItem from '../PageSectionItem';
import JSONLinkedDataModal from './JSONLinkedDataModal';
import { Label, Inline, InlineLabel } from './styles';
import { Section, SectionLabel, SectionContent } from './Shared';
import { ModalType } from '../../types/internal';
import { Link } from '../../types/external';
import { PlainTextLink } from './LinkObject';

export default function AdditionalInformationPageSection(props: { item: any, pageSectionDescription?: string }) {
	return ( props.item &&
	<PageSection icon={additionalInfoIcon} title="Additional Information" description={props.pageSectionDescription}>
		<InfoSection>
			{false && <Label>Identifiers</Label>}
			<SectionContent>
				<TextItem label="CTID:" item={props.item.CTID} />
				<TextItem label="Credential ID:" item={props.item.CredentialId} />
				<TextItem label="DUNS:" item={props.item.DUNS} />
				<TextItem label="FEIN:" item={props.item.FEIN} />
				<TextItem label="OPE ID:" item={props.item.OPEID} />
				<TextItem label="IPEDS ID:" item={props.item.IPEDSID} />
				<TextItem label="ISIC V4:" item={props.item.ISICV4} />
				<TextItem label="LEI Code:" item={props.item.LEICode} />
				<TextItem label="Coded Notation:" item={props.item.CodedNotation} />
				<TextItem label="Comment:" item={props.item.Comment} />
				<TextItem label="List Id:" item={props.item.ListId} />
				<TextItem label="Alternate Coded Notation:" item={props.item.AltCodedNotation} />
					<TextItem label="School Courses for the Exchange of Data code (SCED):" item={props.item.SCED} />

				{props.item.VersionIdentifier && <TextItem label={props.item.VersionIdentifier.IdentifierTypename} item={props.item.VersionIdentifier.IdentifierValueCode} />}
				{props.item.VersionIdentifier && <>
					{typeof (props.item.VersionIdentifier) == "string" && <LinkItem label="Version Identifier:" item={props.item.VersionIdentifier} />}
					{Array.isArray(props.item.VersionIdentifier) && props.item.VersionIdentifier.map((identifier: any, index: number) => (
						<>
							{(typeof (identifier) == "string" && identifier.toLowerCase().includes("http")) && <LinkItem label="Version Identifier:" item={identifier} />}
							{(typeof (identifier) == "string" && !identifier.toLowerCase().includes("http")) && <TextItem label="Version Identifier:" item={identifier} />}
							{(typeof (identifier) == "object") &&
								<>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<TextItem label={"Version Identifier:"} item={" "} />
										<TextItem label={identifier.IdentifierTypeName} item={identifier.IdentifierValueCode} />
										{identifier.IdentifierType && identifier.IdentifierType.startsWith('http') && (
											<LinkItem label="" item={identifier.IdentifierType} />
										)}
									</div>
								</>
							}
						</>
					))}
				</>}
				{props.item.Identifier && <>
					{typeof (props.item.Identifier) == "string" && <LinkItem label="Identifier:" item={props.item.Identifier} />}
					{Array.isArray(props.item.Identifier) && props.item.Identifier.map((identifier: any) => (
						<>
							{(typeof (identifier) == "string" && identifier.toLowerCase().includes("http")) && <LinkItem label="Identifier:" item={identifier} />}
							{(typeof (identifier) == "string" && !identifier.toLowerCase().includes("http")) && <TextItem label="Identifier:" item={identifier} />}
							{(typeof (identifier) == "object") &&
								<>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<TextItem label={identifier.IdentifierTypeName} item={identifier.IdentifierValueCode} />
									{identifier.IdentifierType && identifier.IdentifierType.startsWith('http') && (
										<LinkItem label="" item={identifier.IdentifierType} />
									)}
								</div>
								</>
							}
						</>
					))}
				</>}
			</SectionContent>
		</InfoSection>
		{(props.item.Meta_LastUpdated || props.item.FoundingDate || props.item.DateEffective || props.item.ExpirationDate || props.item.StartDate || props.item.EndDate) &&
			<InfoSection>
				{false && <Label>Dates</Label>}
					<SectionContent>
						<TextItem label="Last Updated:" item={props.item.Meta_LastUpdated} />
						<TextItem label="Founding Date:" item={props.item.FoundingDate} />
						<TextItem label="Date Effective:" item={props.item.DateEffective} />
						<TextItem label="Expiration Date:" item={props.item.ExpirationDate} />
						{/*<TextItem label="Start Date:" item={props.item.StartDate} />*/}
						{/*<TextItem label="End Date:" item={props.item.EndDate} />*/}
						<TextItem label="Date Copyrighted:" item={props.item.DateCopyrighted} />
						<TextItem label="Date Created:" item={props.item.DateCreated} />
						<TextItem label="Date Modified:" item={props.item.DateModified} />
						<TextItem label="Date Valid From:" item={props.item.DateValidFrom} />
						<TextItem label="Date Valid Until:" item={props.item.DateValidUntil} />
						<TextItem label="Repository Date:" item={props.item.RepositoryDate} />
					</SectionContent>
			</InfoSection>
		}
		{(props.item.AlternateName?.length > 0 || props.item.InLanguage?.length > 0 || props.item.License?.length > 0 || props.item.Rights?.length > 0 || props.item.HasScope?.length>0) &&
			<InfoSection>
				{false && <Label>Miscellaneous</Label>}
					<SectionContent>
						<TextItem label="Alternate Name(s):" item={props.item.AlternateName?.join(", ")} />
						<TextItem label="In Language:" item={props.item.InLanguage?.join(", ")} />
						<LinkItem label="License:" item={props.item.License} />
						{/*<TextItem label="HasScope:" item={props.item.HasScope} />*/}
						<TextItem label="Rights:" item={props.item.Rights} />
						<TextItem label="List ID:" item={props.item.ListID} />
						<TextItem label="Should Index:" item={props.item.ShouldIndex == true ? "Yes" : props.item.ShouldIndex == false ? "No" : ""} />
					</SectionContent>
			</InfoSection>
		}
		{/*{(props.item.LatestVersion?.URL || props.item.PreviousVersion?.URL || props.item.NextVersion?.URL || props.item.Supersedes?.URL || props.item.SupersededBy?.URL) &&*/}
		{/*	<InfoSection>*/}
		{/*		{false && <Label>Other Versions</Label>}*/}
		{/*			<SectionContent>*/}
		{/*				<LinkItem label="Latest Version:" item={props.item.LatestVersion} />*/}
		{/*				<LinkItem label="Previous Version:" item={props.item.PreviousVersion} />*/}
		{/*				<LinkItem label="Next Version:" item={props.item.NextVersion} />*/}
		{/*				<LinkItem label="Supersedes:" item={props.item.Supersedes} />*/}
		{/*				<LinkItem label="Superseded By:" item={props.item.SupersededBy} />*/}
		{/*			</SectionContent>*/}
		{/*	</InfoSection>*/}
		{/*}*/}
		<InfoSection>
			{false && <Label>Registry Data</Label>}
			<JSONLinkedDataModal item={props.item} />
		</InfoSection>
	</PageSection>
	) || null
}

function TextItem(props: { label: string, item: string }) {
	return (props.item &&
		<Inline>
			<InlineLabel>{props.label}</InlineLabel>
			<div>{props.item}</div>
		</Inline>
	) || null
}

function LinkItem(props: { label: string, item: any }) {
	if (!props.item) {
		return null;
	}

	var link = typeof (props.item) == "string" ? ({ Label: props.item, URL: props.item } as Link) : (props.item as Link);
	return (link.URL &&
		<Inline>
			<InlineLabel>{props.label}</InlineLabel>
			<PlainTextLink item={link} />
		</Inline>
	) || null
}

const InfoSection = styled(PageSectionItem)`
	padding: 0;
`;
