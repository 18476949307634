import styled, { css } from 'styled-components/macro';
import { HStack } from '../Stack';

export const HSection = styled(HStack)`
    > * {
        margin: 5px;
    }
    flex-wrap: wrap;
    margin: 10px 0;
`;
