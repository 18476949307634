import styled from 'styled-components/macro';
import { widgetGetColor } from '../../utils/SiteWidgetizer';

export const StyledDropdown = styled.div`
    cursor: pointer;
    position: relative;
`;

export const Header = styled.button`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
	color: ${(props) => widgetGetColor("SearchResultButton", true, "")};
    background-color: ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.aquaLight)};
    border: 1px solid ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.aquaLight)};
    font-size: ${(props) => props.theme.fontSize.lg};
    line-height: 1.125;
    padding: 0.65em 0.65em;
`;

export const Options = styled.div`
    border-bottom: 1px solid ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.aquaDark)};
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 10;
`;

export const HeaderIcon = styled.div`
    display: inline-block;
    margin-left: 10px;
`;

export const Option = styled.button<{ selected?: boolean }>`
	display: flex;
	align-items: center;
    border: 1px solid ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.aquaDark)};
    border-bottom: 0 none;
    border-radius: 0;
	color: ${(props) => props.selected ? widgetGetColor("SearchResultButton", true, "") : widgetGetColor("SearchResultButton", false, "")};
    background-color: ${(props) => props.selected ? widgetGetColor("SearchResultButton", false, props.theme.color.aquaLight) : widgetGetColor("SearchResultButton", true, props.theme.color.white)};
    cursor: pointer;
    font-size: ${(props) => props.theme.fontSize.md};
    width: 100%;
    text-align: left;
    padding: 10px;
    margin: 0;

    &:hover {
		color: ${(props) => widgetGetColor("SearchResultButton", true, "")};
        background-color: ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.aquaLight)};
    }
`;

export const OptionIcon = styled.img`
	margin-right: 10px;
	max-width: 25px;
	max-height: 25px;
`;