import React from 'react';
import styled from 'styled-components/macro';
import { ValueProfile as ValueProfileType } from '../../types/external';
import DisplayPills from '../../components/PillDisplay';
import { Box, BoxContent, Section, SectionContent, SectionContentItem } from './Shared';

export default function ValueProfileList(props: { items: ValueProfileType[] }) {
	return (props.items?.length > 0 &&
		<div>
		{props.items.map((item) => (
			<ValueProfile item={item} />
		))}
		</div>
	) || null
}

export function ValueProfile(props: { item: ValueProfileType }) {
	if (!props.item) {
		return null;
	}

	var valueText = [
		(props.item.Value && props.item.Value + " Credit" + (props.item.Value == 1 ? "" : "s")),
		((props.item.MinValue && props.item.MaxValue) && (props.item.MinValue + " to " + props.item.MaxValue + " Credits")),
		(props.item.MinValue && !props.item.MaxValue && "At least " + props.item.MinValue + " Credit" + (props.item.MinValue == 1 ? "" : "s")),
		(props.item.MaxValue && !props.item.MinValue && "Up to " + props.item.MaxValue + " Credit" + (props.item.MaxValue == 1 ? "" : "s")),
		(props.item.Percentage && ((props.item.Value || props.item.MinValue || props.item.MaxValue) ? "(" + props.item.Percentage + "%)" : props.item.Percentage + "% Credit"))
	].filter(m => m).join(" ");

	return (props.item &&
		<Section>
			<SectionContent>
				{props.item.Description && <div>{props.item.Description}</div>}
				<SectionContentItem label="Value" data={valueText} RenderWith={(data) => <div>{data}</div>} />
				<SectionContentItem label="Credit Level Type" data={props.item.CreditLevelType} RenderWith={(data) => <DisplayPills links={data} />} />
				<SectionContentItem label="Credit Unit Type" data={props.item.CreditUnitType} RenderWith={(data) => <DisplayPills links={data} />} />
				<SectionContentItem label="Subject" data={props.item.Subject} RenderWith={(data) => <DisplayPills links={data} />} />
			</SectionContent>
		</Section>
	) || null
}
