import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';
import Banner from '../../components/Banner';
import { WhiteLink } from '../../components/Banner/styles';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import LinkObject from '../../components/DetailPage/LinkObject';
import OutlineList from '../../components/DetailPage/Outline';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import {
  Description,
  FullWidthWrapper,
  InnerWrapper,
    Label, Section,
    Inline,
    InlineLabel,
  Wrapper
} from '../../components/DetailPage/styles';
import HasAnyFilled from '../../components/HasAnyFilled';
import LoadingOrError from '../../components/LoadingOrError';
import { ModalButtonAndWindow } from '../../components/Modal';
import PageSection from '../../components/PageSection';
import PageSectionItem from '../../components/PageSectionItem';
import { VStack } from '../../components/Stack';
import { useGet, useMediaQuery } from '../../hooks';
import { apiUrl, legacySourceUrl } from '../../shared/globals';
import { theme } from '../../shared/theme';
import { CredentialingAction } from '../../types/external';
import { detailSectionProperties } from '../../utils';
import SearchPills from '../../components/SearchPills';
import { isFilled } from '../../validation';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import ConditionProfileList, { getConditionProfileListButtonName } from "../../components/DetailPage/ConditionProfile";
import ConnectionsSection from "../../components/DetailPage/ConnectionsSection";
import { ModalType } from '../../types/internal';
import transferValueIcon from '../../assets/images/icons/icon-transfer-value-green-blue-01.svg';
import PageBanner from '../../components/DetailPage/PageBanner';
import pageIcon from '../../assets/images/icons/icon-quality-assurance-blue-green-01.svg';



interface Props {
  id: string;
}

export default function CredentialingActionPage(props: RouteComponentProps<Props>) {
  const id = props.match.params.id;
  const [
    pageDataIsLoading,
    pageDataHasError,
    getPageData,
  ] = useGet();
    const [pageModel, setPageData] = useState({} as CredentialingAction);
  const [openedModal, setOpenedModal] = useState<ModalType>();
    const startDate = pageModel.StartDate;
    const endDate = pageModel.EndDate;
  function onOpenModalClick(modalType: ModalType) {
    return () => setOpenedModal(modalType);
  }

  function onCloseModalClick() {
    setOpenedModal(undefined);
  }
    function TextItem(props: { label: string, item: string }) {
        return (props.item &&
            <Inline>
                <InlineLabel>{props.label}</InlineLabel>
                <div>{props.item}</div>
            </Inline>
        ) || null
    }
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
  });

  useEffect(() => {
      getPageData(`${apiUrl}/Action/${id}`, (data: CredentialingAction) => {
      setPageData(data);
    });
  }, [getPageData, id]);
  //

  function getSubTitleLink() {
    if (pageModel.OwnedByLabel && pageModel.OwnedByLabel.Label) {
      return pageModel.OwnedByLabel.URL ?
        <WhiteLink href={pageModel.OwnedByLabel.URL}>{pageModel.OwnedByLabel.Label}</WhiteLink> :
        <>{pageModel.OwnedByLabel.Label}</>;
    }
    return null;
  }

  function getLegacyLink() {
    if (pageModel.Meta_Id) {
      const url = legacySourceUrl + "/" + pageModel.BroadType + "/" + pageModel.Meta_Id;
      const mystyle = {
        //color: "rgba(0,0,0,.01)",
        color: "transparent",
        //backgroundColor: "rgba(0,0,0,.01)",
        marginLeft: "10px"
      };
      return <>
        <WhiteLink href={url} target="legacy" style={mystyle} title="View in services application">Legacy</WhiteLink>
      </>

    }
    return null;
  }

  var typeLabel = widgetGetSingleLabelForText("Quality Assurance Action");

  return (
    <LoadingOrError
      isLoading={pageDataIsLoading}
      hasError={pageDataHasError}>
          <PageBanner
              item={pageModel}
              bannerIcon={pageModel.Image || ''}
              fallbackIcon={pageIcon}
          />
      {/*<Banner*/}
      {/*  icon={pageIcon}*/}
      {/*        fallBackIcon={pageIcon}*/}
      {/*        type={pageModel?.CTDLTypeLabel}*/}
      {/*  title={pageModel.Name}*/}
      {/*  subTitle={getSubTitleLink()}*/}
      {/*  content={*/}
      {/*    <small>Last updated: {pageModel.Meta_LastUpdatedHeader} <span  >{getLegacyLink()}</span></small>*/}
      {/*  }*/}
      {/*/>*/}
      <Wrapper>
        <VStack spacing={isMobile ? '0px' : '20px'}>
          <HasAnyFilled
            data={pageModel}
            keys={detailSectionProperties.occupationAbout}>
            <PageSection
              icon={aboutIcon}
              title={"About this " + typeLabel}
              variant="Highlight"
              description={"Basic information about this " + typeLabel}>

              {pageModel.Description && (
                <PageSectionItem>
                  <Description>
                    {pageModel.Description}
                  </Description>
                </PageSectionItem>
                          )}
                          {/*{pageModel.ActionType && (*/}
                          {/*    <PageSectionItem>*/}
                          {/*        <Label>Action Type</Label>*/}
                          {/*        <SearchPills links={[pageModel.ActionType]} />*/}
                          {/*    </PageSectionItem>*/}
                          {/*)}*/}
                          {pageModel.EvidenceOfAction &&
                              <PageSectionItem>
                                  <LinkObject item={{ URL: pageModel.EvidenceOfAction, Label: "View the Evidence Of Action " }} />
                              </PageSectionItem>
                          }
                          {pageModel.ActionStatusType && (
                              <PageSectionItem>
                                  <Label>Action Status</Label>
                                  <SearchPills links={[pageModel.ActionStatusType]} />
                              </PageSectionItem>
                          )}
                          {(pageModel.StartDate || pageModel.EndDate) && (
                              <PageSectionItem>
                                  <TextItem label="Start Date:" item={pageModel.StartDate} />
                                  <TextItem label="End Date:" item={pageModel.EndDate} />
                              </PageSectionItem>
                          )}                       


              {/*{pageModel.SubjectWebpage &&*/}
              {/*    <PageSectionItem>*/}
              {/*        <LinkObject item={{ URL: pageModel.SubjectWebpage, Label: "View this " + typeLabel }} />*/}
              {/*    </PageSectionItem>*/}
              {/*}*/}

            </PageSection>
          </HasAnyFilled>
                  <AdditionalInformationPageSection item={pageModel} pageSectionDescription={"Identifiers and other information for the " + typeLabel} />
        </VStack>
        <VStack spacing={isMobile ? '0px' : '20px'}>

                  <RelatedOrganizationPageSection item={pageModel} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " that act or participate with the " + typeLabel} />
          <HasAnyFilled
            data={pageModel}
            keys={[
              ...detailSectionProperties.entityConnections
            ]}>
                      <PageSectionItem style={{ marginTop: -30 }}>
                              {pageModel.Object &&
                              <PageSection icon={transferValueIcon} title="Object" description="A resource upon which the action is carried out">
                                  <OutlineList items={pageModel.Object?.Values} />
                                  </PageSection>
                          }
                          {pageModel.Instrument &&
                              <PageSection icon={transferValueIcon} title="Instrument" description="The credential whose criteria was applied in executing this action">
                              <OutlineList items={pageModel.Instrument?.Values} />
                              </PageSection>
                          }
                          {/*{pageModel.Participant &&*/}
                          {/*    <PageSection icon={transferValueIcon} title="Participant" description="Participants related to this resource">*/}
                          {/*    <OutlineList items={pageModel.Participant?.Values} />*/}
                          {/*    </PageSection>*/}
                          {/*}*/}
              </PageSectionItem>
          </HasAnyFilled>
        </VStack>
      </Wrapper>
    </LoadingOrError>
  );
}
//
