import React, { useContext, useEffect, useState } from 'react';
import { ModalType } from '../../types/internal';
import {
	Assessment,
	LearningOpportunity,
	Credential,
    Outline,
} from '../../types/external';
import PageSection from '../PageSection';
import PageSectionItem from '../PageSectionItem';
import { Label, Section, UnorderedList, ListWithoutBullet } from './styles';
import { Button } from '../common';
import { ModalButtonAndWindow } from '../Modal';
import { isFilled } from '../../validation';
import HasAnyFilled from '../HasAnyFilled';
import { detailSectionProperties } from '../../utils';
import ConditionProfileList, { getConditionProfileListButtonName } from './ConditionProfile';
import { CompetencyFrameworkList } from '../../components/DetailPage/CompetencyFramework';
import OutlineList from './Outline';
import TruncatedItemList from './TruncatedItemList';
import { PlainTextLink } from './LinkObject';
import TextLink from '../TextLink';

interface Props {
    item: any;
    openedModal?: ModalType;
    onOpenModalClick: (modalType: ModalType) => () => void;
    onCloseModalClick: () => void;
}

export default function ConnectionsSection(props: Props) {
    return (
		<>
			{props.item?.Collections?.length > 0 && (
				<PageSectionItem>
					<Label>Included in Collections</Label>
					<TruncatedItemList
						ListItemsComponent={UnorderedList}
						source={props.item.Collections}
						renderItemWith={(item, index) => {
							return (
								<ListWithoutBullet key={`cr-collection-${index}`}>
									<div><a href={item.URL}>{item.Label}</a></div>
									{item.ExtraData && (
										<div>{[item.ExtraData.StartDate && "Start Date: " + item.ExtraData.StartDate, item.ExtraData.EndDate && "End Date: " + item.ExtraData.EndDate].filter((item: any) => { return item }).join(" | ")}</div>
									)}
								</ListWithoutBullet>
							)
						}}
						truncateAfter={5}
					/>
				</PageSectionItem>
			)}
			{(
				props.item.IsRequiredFor?.length > 0 ||
				props.item.IsRecommendedFor?.length > 0 ||
				props.item.AdvancedStandingFrom?.length > 0 ||
				props.item.IsAdvancedStandingFor?.length > 0 ||
				props.item.PreparationFrom?.length > 0 ||
				props.item.IsPreparationFor?.length > 0 ||
				props.item.SupportServiceCondition?.length > 0 ||
				props.item.TargetAssessment?.Values?.length > 0 ||
				props.item.TargetLearningOpportunity?.Values?.length > 0 ||
				props.item.HasTransferValue?.Values?.length > 0 ||
				props.item.HasSupportService?.Values?.length > 0 ||
				props.item.TargetPathway?.Values?.length > 0 ||
				props.item.ProvidesTransferValueFor?.Values?.length > 0 ||
				props.item.ReceivesTransferValueFrom?.Values?.length > 0 ||
				props.item.ObjectOfAction?.Values?.length > 0 ||
				props.item.HasRubric?.Values?.length > 0 ||
				props.item.RelatedActions?.Values?.length > 0
			) &&
				<PageSectionItem>
					{/*<Label>Connections to Registry Data</Label>*/}
					<ModalButtonAndWindow
						//buttonLabel="Is Required For"
						buttonLabel={getConditionProfileListButtonName("Is Required For", props.item.IsRequiredFor)}
						hideCount={true}
						resourceTitle={props.item.Name}
						items={props.item.IsRequiredFor}
						Wrapper={Section}
						Content={() => <ConditionProfileList items={props.item.IsRequiredFor} />}
					/>
					<ModalButtonAndWindow
						//buttonLabel="Is Recommended For"
						buttonLabel={getConditionProfileListButtonName("Is Recommended For", props.item.IsRecommendedFor)}
						hideCount={true}
						resourceTitle={props.item.Name}
						items={props.item.IsRecommendedFor}
						Wrapper={Section}
						Content={() => <ConditionProfileList items={props.item.IsRecommendedFor} />}
					/>
					<ModalButtonAndWindow
						//buttonLabel="Advanced Standing From"
						buttonLabel={getConditionProfileListButtonName("Is Advanced Standing From", props.item.AdvancedStandingFrom)}
						hideCount={true}
						resourceTitle={props.item.Name}
						items={props.item.AdvancedStandingFrom}
						Wrapper={Section}
						Content={() => <ConditionProfileList items={props.item.AdvancedStandingFrom} />}
					/>
					<ModalButtonAndWindow
						//buttonLabel="Is Advanced Standing For"
						buttonLabel={getConditionProfileListButtonName("Is Advanced Standing For", props.item.IsAdvancedStandingFor)}
						hideCount={true}
						resourceTitle={props.item.Name}
						items={props.item.IsAdvancedStandingFor}
						Wrapper={Section}
						Content={() => <ConditionProfileList items={props.item.IsAdvancedStandingFor} />}
					/>
					<ModalButtonAndWindow
						//buttonLabel="Preparation From"
						buttonLabel={getConditionProfileListButtonName("Preparation From", props.item.PreparationFrom)}
						hideCount={true}
						resourceTitle={props.item.Name}
						items={props.item.PreparationFrom}
						Wrapper={Section}
						Content={() => <ConditionProfileList items={props.item.PreparationFrom} />}
					/>
					<ModalButtonAndWindow
						//buttonLabel="Is Preparation For"
						buttonLabel={getConditionProfileListButtonName("Is Preparation For", props.item.IsPreparationFor)}
						hideCount={true}
						resourceTitle={props.item.Name}
						items={props.item.IsPreparationFor}
						Wrapper={Section}
						Content={() => <ConditionProfileList items={props.item.IsPreparationFor} />}
					/>
					<ModalButtonAndWindow
						buttonLabel="Has Transfer Value"
						resourceTitle={props.item.Name}
						items={props.item.HasTransferValue?.Values}
						Wrapper={Section}
						Content={() => <OutlineList items={props.item.HasTransferValue?.Values} />}
					/>
					<ModalButtonAndWindow
						buttonLabel="Has Support Service"
						resourceTitle={props.item.Name}
						items={props.item.HasSupportService?.Values}
						Wrapper={Section}
						Content={() => <OutlineList items={props.item.HasSupportService?.Values} />}
					/>
					<ModalButtonAndWindow
						buttonLabel="Pathway Connections"
						resourceTitle={props.item.Name}
						items={props.item.TargetPathway?.Values}
						Wrapper={Section}
						Content={() => <OutlineList items={props.item.TargetPathway?.Values} />}
					/>
					<ModalButtonAndWindow
						buttonLabel="Targeted Assessments"
						resourceTitle={props.item.Name}
						items={props.item.TargetAssessment?.Values}
						Wrapper={Section}
						Content={() => <OutlineList items={props.item.TargetAssessment?.Values} />}
					/>
					<ModalButtonAndWindow
						buttonLabel="Targeted Learning Opportunities"
						resourceTitle={props.item.Name}
						items={(props.item as LearningOpportunity).TargetLearningOpportunity?.Values}
						Wrapper={Section}
						Content={() => <OutlineList items={(props.item as LearningOpportunity).TargetLearningOpportunity?.Values} />}
					/>
					<ModalButtonAndWindow
						buttonLabel={"Provides TransferValue For"}
						resourceTitle={props.item.Name}
						items={props.item?.ProvidesTransferValueFor?.Values}
						Wrapper={Section}
						Content={() => <OutlineList items={props.item.ProvidesTransferValueFor?.Values} />}
						hideCount={true}
				/>
					<ModalButtonAndWindow
						buttonLabel={"Receives TransferValue From"}
						resourceTitle={props.item.Name}
						items={props.item?.ReceivesTransferValueFrom?.Values}
						Wrapper={Section}
						Content={() => <OutlineList items={props.item.ReceivesTransferValueFrom?.Values} />}
						hideCount={true}
				/>
				<ModalButtonAndWindow
					buttonLabel={"Object of Action"}
					resourceTitle={props.item.Name}
					items={props.item?.ObjectOfAction?.Values}
					Wrapper={Section}
					Content={() => <OutlineList items={props.item.ObjectOfAction?.Values} />}
					hideCount={true}
				/>
				<ModalButtonAndWindow
					buttonLabel={"Has " + (props.item?.HasRubric?.Total || 0) + " Rubric" + ((props.item?.HasRubric?.Values?.length || 0) == 1 ? "" : "s")}
					resourceTitle={props.item?.Name}
					items={props.item?.HasRubric?.Values}
					Wrapper={Section}
					Content={() => <OutlineList items={props.item?.HasRubric?.Values} />}
					hideCount={true}
				/>
				<ModalButtonAndWindow
					buttonLabel={"Related Actions"}
					resourceTitle={props.item?.Name}
					items={props.item?.RelatedActions?.Values}
					Wrapper={Section}
					Content={() => <OutlineList items={props.item?.RelatedActions?.Values} />}
					hideCount={true}
				/>
				</PageSectionItem>
			}
			{(props.item.RequiresCompetencies?.Values?.length > 0 || props.item.AssessesCompetencies?.Values?.length > 0 || props.item.TeachesCompetencies?.Values?.length > 0) &&
				<PageSectionItem>
				{/*<Label>Related Competencies</Label>*/}
					<ModalButtonAndWindow
						buttonLabel={"Requires " + props.item.RequiresCompetencies?.Total + " Competencies"}
						resourceTitle={props.item.Name}
						items={props.item.RequiresCompetencies?.Values}
						Wrapper={Section}
						Content={() => <CompetencyFrameworkList frameworkList={props.item.RequiresCompetencies} />}
						hideCount={true}
					/>
					<ModalButtonAndWindow
						buttonLabel={"Teaches " + props.item.TeachesCompetencies?.Total + " Competencies"}
						resourceTitle={props.item.Name}
						items={props.item.TeachesCompetencies?.Values}
						Wrapper={Section}
						Content={() => <CompetencyFrameworkList frameworkList={props.item.TeachesCompetencies} />}
						hideCount={true}
					/>
					<ModalButtonAndWindow
						buttonLabel={"Assesses " + props.item.AssessesCompetencies?.Total + " Competencies"}
						resourceTitle={props.item.Name}
						items={props.item.AssessesCompetencies?.Values}
						Wrapper={Section}
						Content={() => <CompetencyFrameworkList frameworkList={props.item.AssessesCompetencies} />}
						hideCount={true}
					/>
				</PageSectionItem>
			}
			{isFilled(props.item.TargetLearningResource) && (
				<PageSectionItem>
					{/*<Label>External Learning Resources</Label>*/}
					<TruncatedItemList
						ListItemsComponent={UnorderedList}
						source={props.item.TargetLearningResource}
						renderItemWith={(item, index) => {
							return (
								<ListWithoutBullet
									key={`cr-targetlearningresource-${index}`}>
									<PlainTextLink item={{ Label: item, URL: item }} />
								</ListWithoutBullet>
							)
						}}
						truncateAfter={5}
					/>
				</PageSectionItem>
			)}
			{(isFilled(props.item.AlignFrom) || isFilled(props.item.AlignTo) || isFilled(props.item.DerivedFrom)) && (
				<PageSectionItem>
					{/*<Label>Related Resources</Label>*/}
					{props.item.AlignFrom?.length > 0 && (
						<TruncatedItemList
							ListItemsComponent={UnorderedList}
							source={props.item.AlignFrom}
							renderItemWith={(item, index) => {
								return (
									<ListWithoutBullet key={`cr-alignfrom-${index}`}>
										<PlainTextLink item={{ Label: item, URL: item }} />
									</ListWithoutBullet>
								)
							}}
							truncateAfter={5}
						/>
					)}
					{props.item.AlignTo?.length > 0 && (
						<TruncatedItemList
							ListItemsComponent={UnorderedList}
							source={props.item.AlignTo}
							renderItemWith={(item, index) => {
								return (
									<ListWithoutBullet key={`cr-alignto-${index}`}>
										<PlainTextLink item={{ Label: item, URL: item }} />
									</ListWithoutBullet>
								)
							}}
							truncateAfter={5}
						/>
					)}
					{props.item.DerivedFrom?.length > 0 && (
						<TruncatedItemList
							ListItemsComponent={UnorderedList}
							source={props.item.DerivedFrom}
							renderItemWith={(item, index) => {
								return (
									<ListWithoutBullet key={`cr-derivedfrom-${index}`}>
										<PlainTextLink item={{ Label: item, URL: item }} />
									</ListWithoutBullet>
								)
							}}
							truncateAfter={5}
						/>
					)}
				</PageSectionItem>
			)}
        </>
    );
}
