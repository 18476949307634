import styled, { css } from 'styled-components/macro';
import { CleanList, FlexRow } from '../common';
import { widgetGetColor } from '../../utils/SiteWidgetizer';

export const HeaderMain = styled.header`
    border-bottom: 1px solid ${(p) => widgetGetColor("SiteHeader", false, p.theme.color.aquaDark)};
    padding: 0 20px;

    > ${FlexRow} {
        align-items: stretch;
        margin: 0 auto;
        max-width: 1400px;
    }
`;

export const CompareButtonWrapper = styled.div`
	position: relative;
`;

export const CompareButton = styled.button<{
    isActive?: boolean;
    disabled?: boolean;
}>`
    border: none;
    background: transparent;
    display: inline-flex;
    align-items: center;
    text-align: center;
    height: 100%;
    @media (min-width: ${(props) => props.theme.breakpoint.desktop}) {
        margin-left: 30px;
    }
    > * {
        background-color: ${(props) =>
            props.disabled
                ? props.theme.color.greyLight
				: widgetGetColor("SearchResultButton", false, props.theme.color.aquaDark)};
		color: ${(props) =>
		props.disabled
			? "black"
			: widgetGetColor("SearchResultButton", true, "black")};
    }
    ${(props) =>
        props.isActive &&
        css`
            background: linear-gradient(
                180deg,
                ${(props) => props.theme.color.brown} 4px,
                transparent 4px
            );
        `}
`;

export const CompareCount = styled.p`
    padding: 0.5em 0.8em;
    margin: 0;
`;

export const CompareIcon = styled.div`
    padding: 0.5em 0.8em;
    margin-left: 1px;
`;

export const HeaderLeft = styled.div`
	flex: 1 1 30%;
	display: flex;
	align-items: center;
    padding: 5px 0;
	min-height: 70px;

	@media (max-width: 700px) {
		display: block;
	}
`;

export const HeaderRight = styled.div`
	flex: 1 1 70%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
`;

export const Logo = styled.div`
	white-space: nowrap;

    > a {
        color: ${(props) => props.theme.color.blueDark};
        display: inline-block;
        font-size: ${(props) => props.theme.fontSize.md};
        font-weight: bold;
        text-decoration: none;
    }

	& img {
		max-height: 50px;
		max-width: 125px;
	}
`;

export const Tagline = styled.div`
	margin: 0 10px;
	font-size: 12px;
	font-style: italic;

	width: 300px;

	@media (max-width: 1300px) {
		width: 200px;
	}
	@media (max-width: 700px) {
		font-size: 8px;
	}
`;

export const MainMenu = styled(CleanList)`
    ul {
        padding: 0;
        z-index: 10;
        li {
            list-style: none outside none;
        }
    }

    // Style for mobile
    @media (max-width: calc(${(props) =>
            props.theme.breakpoint.desktop} - 1px)) {
        background-color: ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.blueDark)};
        display: none;
        padding: 2rem 1rem;
        position: fixed;
        height: 100vh;
        left: 0;
        top: 0;
        width: 100vw;
        z-index: 2;

        // Menu open
        .main-menu-open & {
            display: block;
        }

        > li {
            color: #fff;
            padding: 0.5rem;
            cursor: pointer;
            &:not(:last-child) {
                border-bottom: 1px solid
                    ${(props) => props.theme.color.aquaDark};
            }

            > a {
                font-size: ${(props) => props.theme.fontSize.md};
                font-weight: bold;
            }

            ul {
                margin: 1rem 0;
                padding-left: 1rem;
            }

            li:not(:last-child) {
                margin-bottom: 0.8rem;
            }
        }

        a {
            color: #fff;
            text-decoration: none;

            &:hover,
            .active {
                color: ${(props) => props.theme.color.aquaDark};
            }
        }
    }

    // Style for desktop
    @media (min-width: ${(props) => props.theme.breakpoint.desktop}) {
        display: flex;
        height: 100%;

        ul {
            background-color: ${(props) => props.theme.color.white};
            border: 1px solid ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.aquaDark)};
            display: none;
            left: 0;
            position: absolute;
            top: 100%;
            width: 240px;

            li {
                justify-content: flex-start;
                padding: 10px;

                &:hover {
                    background-color: ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.aquaLight)};
                    color: ${(props) => widgetGetColor("SearchResultButton", true, props.theme.color.blueDark)};

					a {
						color: ${(props) => widgetGetColor("SearchResultButton", true, "black")};
					}
                }

                a {
                    justify-content: flex-start;
                    padding: 0;
                }
            }
        }

        li {
            position: relative;

            &:hover ul {
                display: block;
            }
        }

        a {
            align-items: center;
            color: ${(props) => props.theme.color.blueDark};
            display: flex;
            height: 100%;
            justify-content: center;
            padding: 0 1em;
            text-decoration: none;

            &:hover {
                color: ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.aquaDark)};
            }

            &.active {
                background: linear-gradient(
                    180deg,
                    ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.brown)} 4px,
                    transparent 4px
                );
            }
        }
    }
`;

export const SearchLink = styled.li`
	display: flex;
	align-items: center;
	font-size: 0.875rem;
    cursor: pointer;

	&:not(:last-child) {
		border-bottom: 1px solid ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.aquaDark)};
	}

	& img {
		max-height: 25px;
		max-width: 25px;
		margin-right: 10px;
	}
`;
