import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { WorkforceDemand as WorkforceDemandType, Outline, WorkforceDemand, Link } from '../../types/external';
import { Box, BoxLabel, BoxContent, Section, SectionLabel, SectionContent, SectionContentItem } from './Shared';
import OutlineList from './Outline';
import JurisdictionProfileList from './JurisdictionProfile';
import DisplayPills from '../../components/PillDisplay';
import { PlainTextLink, PlainTextLinkListFromURLs } from '../DetailPage/LinkObject';
import DateRange from './DateRange';

export function WorkforceDemandAction(props: { item: WorkforceDemandType }) {
	return props.item && (
		<Box>
			<BoxLabel>{props.item.Name || "Workforce Demand"}</BoxLabel>
			<BoxContent>
				<Section>
					<SectionContent>
						{props.item.Description && <div>{props.item.Description}</div>}
						<DateRange FromDate={props.item.StartDate} ToDate={props.item.EndDate} />
						<div><b>CTID:</b> {props.item.CTID}</div>
						<DisplayPills links={[props.item.ActionStatusType]} />
						<DisplayPills links={props.item.Result} />
					</SectionContent>
				</Section>
				<Section>
					<PlainTextLinkListFromURLs label="Instrument" urls={props.item.Instrument} />
					<PlainTextLinkListFromURLs label="Object" urls={props.item.Object} />
					<PlainTextLinkListFromURLs label="Evidence" urls={props.item.EvidenceOfAction} />
					<PlainTextLinkListFromURLs label="Participant" urls={props.item.Participant} />
				</Section>
				<Section>
					<OutlineList items={props.item.ActingAgent} />
					<JurisdictionProfileList items={props.item.Jurisdiction} />
				</Section>
			</BoxContent>
		</Box>
	) || null;
}