import React from 'react';
import styled from 'styled-components/macro';
import { Section, Heading, Body } from './profileStyles';
import { SectionLabel, InlineLabelledItem } from '../DetailPage/styles';
import DisplayPills from '../../components/PillDisplay';
import JurisdictionProfileList from './JurisdictionProfile';
import ProcessProfileList from './ProcessProfile';
import OutlineList, { Outline } from '../../components/DetailPage/Outline';
import { TabSetOrSingle } from './TabSelector';
import DateRange from './DateRange';
import { EarningsDisplay } from './AggregateDataProfile';
import { MonetaryAmountList, QuantitativeValueList } from './QuantitativeValue';
import { DataSetProfile, DataSetTimeFrame, DataProfile, ProcessProfile, QuantitativeValue, MonetaryAmount, MonetaryAmountDistribution } from '../../types/external';
import { formatPrice } from '../../utils';

export interface DataSetProfileSingleProps {
	item: DataSetProfile;
}
export function DataSetProfileSingle(props: DataSetProfileSingleProps) {
	return (
		props.item &&
		<DataSetProfileWrapper>
			<Section>
				<Heading>{props.item.Name}</Heading>
				<Body>
					{
						props.item.Description &&
						<Section>
							{props.item.Description}
						</Section>
					}
					{
						props.item.DataSetTimePeriod?.length > 0 &&
						<Section>
							<SectionLabel>Applicable Time Periods</SectionLabel>
							<TabSetOrSingle
								activeIndex={0}
								items={props.item.DataSetTimePeriod.map(
									(timeFrame, index) => ({
										Label: timeFrame.Name || (timeFrame.StartDate && timeFrame.EndDate && (timeFrame.StartDate + " - " + timeFrame.EndDate)) || "Unnamed Time Frame",
										Content: <DataSetTimeFrameSingle item={timeFrame} />
									})
								)}
							/>
						</Section>
					}
					{
						props.item.DataSuppressionPolicy &&
						<Section>
							<b>Data Suppression Policy:</b> {props.item.DataSuppressionPolicy}
						</Section>
					}
					{
						props.item.SubjectIdentification &&
						<Section>
							<b>Subject Identification:</b> {props.item.SubjectIdentification}
						</Section>
					}
					{
						props.item.Source &&
						<Section>
							<a href={props.item.Source} target="_blank">Source Information</a>
						</Section>
					}
					{
						props.item.DistributionFile?.length > 0 &&
						<Section>
							<label>Distribution File:</label>
							{props.item.DistributionFile.map((url) => (
								<a href={url} target="_blank">{url}</a>
							))}
						</Section>
					}
					{
						props.item.InstructionalProgramType?.length > 0 &&
						<Section>
							<b>Instructional Program Type:</b>
							<DisplayPills
								links={props.item.InstructionalProgramType}
							/>
						</Section>
					}
					{
						props.item.Jurisdiction?.length > 0 &&
						<Section>
							<SectionLabel>Jurisdiction Information</SectionLabel>
							<JurisdictionProfileList items={props.item.Jurisdiction} />
						</Section>
					}

					{
						props.item.About?.length > 0 &&
						<Section>
							<SectionLabel>Applicable Resources</SectionLabel>
							<OutlineList items={props.item.About} />
						</Section>
					}
					{
						props.item.DataProvider &&
						<Section>
							<b>Data Provider:</b>
							<Outline item={props.item.DataProvider} />
						</Section>
					}
				</Body>
			</Section>
		</DataSetProfileWrapper>
	)
}

export interface DataSetTimeFrameSingleProps {
	item: DataSetTimeFrame;
}
export function DataSetTimeFrameSingle(props: DataSetTimeFrameSingleProps) {
	return (
		<DataSetTimeFrameWrapper>
			{
				props.item.Description &&
				<Section>
					{props.item.Description}
				</Section>
			}
			{
				(props.item.StartDate || props.item.EndDate) &&
				<Section>
					<DateRange
						FromDate={props.item.StartDate}
						ToDate={props.item.EndDate}
					/>
				</Section>
			}
			{
				(props.item.TimeIntervalDisplay) &&
				<Section>
					<b>Time Interval:</b> {props.item.TimeIntervalDisplay}
				</Section>
			}
			{
				props.item.DataSourceCoverageType?.length > 0 &&
				<Section>
					<b>Data Source Coverage Type:</b>
					<DisplayPills
						links={props.item.DataSourceCoverageType}
					/>
				</Section>
			}
			{
				props.item.DataAttributes?.length > 0 &&
				<Section>
					<SectionLabel>Details</SectionLabel>
					<TabSetOrSingle
						activeIndex={0}
						items={
							props.item.DataAttributes.map((dataProfile, index) => (
								{
									Label: (dataProfile.Description || "Data Profile " + index).substr(0, 50) + ((dataProfile.Description || "").length > 50 ? "..." : ""),
									Content: <DataProfileSingle
										item={dataProfile}
									/>
								}
							))
						}
					/>
					
				</Section>
			}
		</DataSetTimeFrameWrapper>
	)
}

export interface DataProfileSingleProps {
	item: DataProfile;
}
export function DataProfileSingle(props: DataProfileSingleProps) {
	return (
		<DataProfileWrapper>
			{
				props.item.Description &&
				<Section>
					{props.item.Description}
				</Section>
			}
			{
				props.item.Adjustment &&
				<Section>
					<b>Adjustment:</b> {props.item.Adjustment}
				</Section>
			}
			{
				props.item.EarningsDefinition &&
				<Section>
					<b>Earnings Definition:</b> {props.item.EarningsDefinition}
				</Section>
			}
			{
				props.item.EarningsThreshold &&
				<Section>
					<b>Earnings Threshold:</b> {props.item.EarningsThreshold}
				</Section>
			}
			{
				props.item.EmploymentDefinition &&
				<Section>
					<b>Employment Definition:</b> {props.item.EmploymentDefinition}
				</Section>
			}
			{
				props.item.FacultyToStudentRatio &&
				<Section>
					<b>Faculty To Student Ratio:</b> {props.item.FacultyToStudentRatio}
				</Section>
			}
			{
				props.item.WorkTimeThreshold &&
				<Section>
					<b>Work Time Threshold:</b> {props.item.WorkTimeThreshold}
				</Section>
			}
			{
				props.item.AdministrativeRecordType?.length > 0 &&
				<Section>
					<b>Administrative Record Type:</b>
					<DisplayPills
						links={props.item.AdministrativeRecordType}
					/>
				</Section>
			}
			{
				props.item.IncomeDeterminationType?.length > 0 &&
				<Section>
					<b>Income Determination Type:</b>
					<DisplayPills
						links={props.item.IncomeDeterminationType}
					/>
				</Section>
			}
			{
				props.item.EarningsDistribution?.length > 0 &&
				<Section>
					<SectionLabel>Earnings Distribution</SectionLabel>
					{props.item.EarningsDistribution.map((item) =>
						<MonetaryAmountDistributionSingle
							item={item}
						/>
					)}
				</Section>
			}
			<MonetaryAmountList label="Earnings Amount" items={props.item.EarningsAmount} />
			<QuantitativeValueList label="Data Available" items={props.item.DataAvailable} />
			<QuantitativeValueList label="Data Not Available" items={props.item.DataNotAvailable} />
			<QuantitativeValueList label="Demographics Earnings Rate" items={props.item.DemographicEarningsRate} />
			<QuantitativeValueList label="Demographic Employment Rate" items={props.item.DemographicEmploymentRate} />
			<QuantitativeValueList label="Employment Rate" items={props.item.EmploymentRate} />
			<QuantitativeValueList label="Holders In Set" items={props.item.HoldersInSet} />
			<QuantitativeValueList label="Industry Rate" items={props.item.IndustryRate} />
			<QuantitativeValueList label="Insufficient Employment Criteria" items={props.item.InsufficientEmploymentCriteria} />
			<QuantitativeValueList label="Meet Employment Criteria" items={props.item.MeetEmploymentCriteria} />
			<QuantitativeValueList label="Non Completers" items={props.item.NonCompleters} />
			<QuantitativeValueList label="Non Holders In Set" items={props.item.NonHoldersInSet} />
			<QuantitativeValueList label="Occupation Rate" items={props.item.OccupationRate} />
			<QuantitativeValueList label="Pass Rate" items={props.item.PassRate} />
			<QuantitativeValueList label="Regional Earnings Distribution" items={props.item.RegionalEarningsDistribution} />
			<QuantitativeValueList label="Regional Employment Rate" items={props.item.RegionalEmploymentRate} />
			<QuantitativeValueList label="Related Employment" items={props.item.RelatedEmployment} />
			<QuantitativeValueList label="Subjects In Set" items={props.item.SubjectsInSet} />
			<QuantitativeValueList label="Sufficient Employment Criteria" items={props.item.SufficientEmploymentCriteria} />
			<QuantitativeValueList label="Unrelated Employment" items={props.item.UnrelatedEmployment} />
			<QuantitativeValueList label="Total WIOA Completers" items={props.item.TotalWIOACompleters} />
			<QuantitativeValueList label="Total WIOA Participants" items={props.item.TotalWIOAParticipants} />
			<QuantitativeValueList label="Total WIOA Exiters" items={props.item.TotalWIOAExiters} />
		</DataProfileWrapper>
	)
}

export interface MonetaryAmountDistributionSingleProps {
	item: MonetaryAmountDistribution;
}
export function MonetaryAmountDistributionSingle(props: MonetaryAmountDistributionSingleProps) {
	return (
		<MonetaryAmountDistributionWrapper>
			<EarningsDisplay
				CurrencySymbol={props.item.Currency}
				MaxBarHeight={150}
				Values={[
					{ Label: "10th Percentile", Value: props.item.Percentile10 },
					{ Label: "25th Percentile", Value: props.item.Percentile25 },
					{ Label: "Median", Value: props.item.Median },
					{ Label: "75th Percentile", Value: props.item.Percentile75 },
					{ Label: "90th Percentile", Value: props.item.Percentile90 },
				]}
			/>
		</MonetaryAmountDistributionWrapper>
	)
}

const DataSetProfileListWrapper = styled.div`

`;

const DataSetProfileWrapper = styled.div`

`;

const DataSetTimeFrameWrapper = styled.div`

`;

const DataProfileWrapper = styled.div`

`;

const MonetaryAmountDistributionWrapper = styled.div`

`;