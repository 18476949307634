import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { ProcessProfile as ProcessProfileType } from '../../types/external';
import { Box, BoxLabel, BoxContent, Section, SectionLabel, SectionContent, SectionContentItem } from './Shared';
import DateRange from './DateRange';
import DisplayPills from '../../components/PillDisplay';
import JurisdictionProfileList from './JurisdictionProfile';
import OutlineList from './Outline';
import { PlainTextLink } from './LinkObject';
import LinkObject from './LinkObject';

export default function ProcessProfileList(props: { items: ProcessProfileType[] }) {
	return (props.items?.length > 0 &&
		<div>
			{props.items.map((item) => (
				<ProcessProfile item={item} />
			))}
		</div>
	) || null
}

export function ProcessProfile(props: { item: ProcessProfileType }) {
	return (props.item &&
		<Box>
			<BoxContent>
				<BasicInfo>
					<SectionContent>
						<div>{props.item.Description || "No Description"}</div>
						<DateRange FromDate={props.item.DateEffective} />
					</SectionContent>
					{props.item.SubjectWebpage && <LinkObject item={{ URL: props.item.SubjectWebpage, Label: "More Information" }} />}
				</BasicInfo>
				
				{(props.item.DataCollectionMethodType || props.item.ExternalInputType?.length > 0 || props.item.ProcessFrequency || props.item.VerificationMethodDescription || props.item.ScoringMethodDescription?.length > 0 || props.item.ScoringMethodExample || props.item.ProcessMethod || props.item.ProcessStandards) && 
				<Section>
					<SectionContent>
						<SectionLabel>Details:</SectionLabel>
						<SectionContentItem label="Data Collection Method Type" data={props.item.DataCollectionMethodType} RenderWith={(data) => <DisplayPills links={data} />} />
						<SectionContentItem label="External Input Type" data={props.item.ExternalInputType} RenderWith={(data) => <DisplayPills links={data} />} />
						<SectionContentItem label="Process Frequency" data={props.item.ProcessFrequency} RenderWith={(data) => <div>{data}</div>} />
						<SectionContentItem label="Verification Method" data={props.item.VerificationMethodDescription} RenderWith={(data) => <div>{data}</div>} />
						<SectionContentItem label="Scoring Method" data={props.item.ScoringMethodDescription} RenderWith={(data) => <div>{data}</div>} />
						<SectionContentItem label="Scoring Method Example" data={props.item.ScoringMethodExample} RenderWith={(data) => <PlainTextLink item={data} overrideLabel="View Example Data" />} />
						<SectionContentItem label="Process Method" data={props.item.ProcessMethod} RenderWith={(data) => <PlainTextLink item={data} overrideLabel="Process Method Info" />} />
						<SectionContentItem label="Process Standards" data={props.item.ProcessStandards} RenderWith={(data) => <PlainTextLink item={data} overrideLabel="Process Standards Info" />} />
					</SectionContent>
				</Section>
				}
				{props.item.ProcessingAgent?.Values?.length > 0 && (
					<Section>
						<SectionContent>
							<SectionLabel>Processing Agent:</SectionLabel>
							<OutlineList items={props.item.ProcessingAgent.Values} />
						</SectionContent>
					</Section>
				)}
				{props.item.TargetCredential?.Values?.length > 0 && (
					<Section>
						<SectionContent>
							<SectionLabel>Process for Credentials:</SectionLabel>
							<OutlineList items={props.item.TargetCredential.Values} />
						</SectionContent>
					</Section>
				)}
				{props.item.TargetAssessment?.Values?.length > 0 && (
					<Section>
						<SectionContent>
							<SectionLabel>Process for Assessments:</SectionLabel>
							<OutlineList items={props.item.TargetAssessment.Values} />
						</SectionContent>
					</Section>
				)}
				{props.item.TargetLearningOpportunity?.Values?.length > 0 && (
					<Section>
						<SectionContent>
							<SectionLabel>Process for Learning Opportunities:</SectionLabel>
							<OutlineList items={props.item.TargetLearningOpportunity.Values} />
						</SectionContent>
					</Section>
				)}
				{props.item.TargetCompetencyFramework?.Values?.length > 0 && (
					<Section>
						<SectionContent>
							<SectionLabel>Process for Competency Frameworks:</SectionLabel>
							<OutlineList items={props.item.TargetCompetencyFramework.Values} />
						</SectionContent>
					</Section>
				)}
				{props.item.Jurisdiction?.length > 0 && (
					<Section>
						<SectionContent>
							<SectionLabel>Jurisdiction Information:</SectionLabel>
							<JurisdictionProfileList items={props.item.Jurisdiction} />
						</SectionContent>
					</Section>
				)}
			</BoxContent>
		</Box>
	) || null
}

const BasicInfo = styled(Section)`
	display: flex;

	& > a {
		margin-left: auto;
	}
`;