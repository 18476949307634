import React, { ReactElement, useState } from 'react';
import styled, { DefaultTheme, StyledComponent } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleRight } from '@fortawesome/free-solid-svg-icons';

export interface TruncatedItemListProps {
	ListItemsComponent?: StyledComponent<any, DefaultTheme>, //Couldn't figure out how to get this to work with a vanilla element, may revisit it later
	source: any[],
	renderItemWith: (item: any, index?: number) => JSX.Element | null,
	truncateAfter: number,
	startExpanded?: boolean,
	listCSSClass?: string,
	listItemsCSSClass?: string,
	expandButtonCSSClass?: string
}

export default function TruncatedItemList(props: TruncatedItemListProps) {
	var [expanded, setExpanded] = useState(props.startExpanded || false);

	var ListItemsComponent = props.ListItemsComponent || TruncatedListItems;
	return (
		props.source?.length > 0 &&
		<TruncatedList className={"truncatedList " + props.listCSSClass}>
			<ListItemsComponent className={"truncatedListItems " + props.listItemsCSSClass}>
				{props.source.map((item, itemIndex) => (
					(expanded || itemIndex < props.truncateAfter) &&
					props.renderItemWith(item, itemIndex)
				))}
			</ListItemsComponent>
			{(props.source.length > props.truncateAfter) && (
				<ExpandButton onClick={() => { setExpanded(!expanded) }} className={"truncatedListButton " + props.expandButtonCSSClass}>
					{expanded && <span>Collapse List</span>}
					{!expanded && <span>Show All {props.source.length} Items</span>}
					<FontAwesomeIcon icon={expanded ? faAngleUp : faAngleRight} />
				</ExpandButton>
			)}
		</TruncatedList>
	) || null
}

const TruncatedList = styled.div`

`;

const TruncatedListItems = styled.div`

`;

const ExpandButton = styled.button`
	display: flex;
	align-items: center;
	font-weight: bold;
	background-color: transparent;
	border: none;
	width: 100%;
	padding: 5px 10px;

	&:hover, &:focus {
		background-color: rgba(78, 229, 225, 0.5);
	}

	& svg {
		font-size: 20px;
		margin-left: auto;
	}
`;