import React from 'react';
import styled from 'styled-components/macro';
import { ConditionManifest as ConditionManifestType, ConditionProfile as ConditionProfileType } from '../../types/external';
import { Box, BoxLabel, BoxContent, Section, SectionLabel, SectionContent, SectionContentItem } from './Shared';
import { TabSetOrSingle } from './TabSelector';
import { ConditionProfile } from './ConditionProfile';
import LinkObject from './LinkObject';

export default function ConditionManifestList(props: { items: ConditionManifestType[] }) {
	return (props.items?.length > 0 &&
		<div>
			{props.items.map((item) => (
				<ConditionManifest item={item} />
			))}
		</div>
	) || null
}

export function ConditionManifest(props: { item: ConditionManifestType }) {
	return (props.item &&
		<Box>
			<BoxLabel>{props.item.Name || "Common Conditions"}</BoxLabel>
			<BoxContent>
				<Section>
					<BasicInfo>
						{props.item.Description && <div>{props.item.Description}</div>}
						{props.item.SubjectWebpage && <LinkObject item={{ URL: props.item.SubjectWebpage, Label: "More Information" }} />}
					</BasicInfo>
				</Section>
				<ConditionProfileSet label="Requirements" items={props.item.Requires} />
				<ConditionProfileSet label="Recommendations" items={props.item.Recommends} />
				<ConditionProfileSet label="Renewal Requirements" items={props.item.Renewal} />
				<ConditionProfileSet label="Entry Requirements" items={props.item.EntryCondition} />
				<ConditionProfileSet label="Corequisite Requirements" items={props.item.Corequisite} />
				<ConditionProfileSet label="Co-Prerequisite Requirements" items={props.item.CoPrerequisite} />
			</BoxContent>
		</Box>
	) || null
}

function ConditionProfileSet(props: { label: string, items: ConditionProfileType[] }) {
	return (props.items?.length > 0 &&
		<Section>
			<SectionLabel>{props.label}</SectionLabel>
			<SectionContent>
				<TabSetOrSingle
					activeIndex={0}
					items={props.items.map((item, itemIndex) => {
						return {
							Label: item.Name || "Option " + (itemIndex + 1),
							Content: <ConditionProfile item={item} />
						}
					})}
				/>
			</SectionContent>
		</Section>
	) || null
}

const BasicInfo = styled.div`
	display: flex;

	& > div {
		flex: 1 1 auto;
	}

	& > a {
		margin-left: 10px;
	}
`;