import styled from 'styled-components/macro';
import { HStack, VStack } from '../../components/Stack';
import { useMediaQuery } from '../../hooks';
import { theme } from '../../shared/theme';
import { informationItems } from './data';
import background from '../../assets/images/icons/background.svg';
import curveLineBackground from '../../assets/images/icons/curved-line-background.svg';

const StyledInformationPanel = styled(HStack)`
    padding: 100px;
    background-image: url(${background}), url(${curveLineBackground});
    background-repeat: repeat, no-repeat;
    background-position: center;
    background-color: ${(props) => props.theme.color.aquaDark};
    @media (max-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        padding: 20px;
    }
`;
const LeftPanel = styled(VStack)`
    @media (max-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        flex: 0 0 100%;
    }
`;
const RightPanel = styled(VStack)``;
const Icon = styled.img`
    width: 60px;
    align-self: flex-start;
`;
const LeftTitle = styled.h2`
    margin-top: 0;
`;
const LeftDescription = styled.p`
    font-size: ${(props) => props.theme.fontSize.lg};
`;
const Title = styled.p`
    margin: 0;
    font-size: ${(props) => props.theme.fontSize.lg};
    font-weight: bold;
`;
const Description = styled.p`
    margin-top: 0;
`;

export default function InformationPanel() {
    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });

    return (
        <StyledInformationPanel spacing={isMobile ? '0' : '80px'} wrap="wrap">
            <LeftPanel>
                <LeftTitle>Reveal Reliable Information</LeftTitle>
                <LeftDescription>
                    The Registry contains trusted and reliable information that
                    helps learners and those seeking opportunities to explore
                    available pathways. This information fuels the creation of
                    services and tools that allow students, employers, and
                    workers to compare credentials and see the full range of
                    opportunities available for learning, advancement, and
                    meaningful careers.
                </LeftDescription>
            </LeftPanel>
            <RightPanel spacing="5px">
                {informationItems.map((item, index) => {
                    return (
                        <HStack spacing="10px" key={'info-item-' + index}>
                            <Icon alt="" src={item.icon} />
                            <VStack>
                                <Title>{item.title}</Title>
                                <Description>{item.description}</Description>
                            </VStack>
                        </HStack>
                    );
                })}
            </RightPanel>
        </StyledInformationPanel>
    );
}
