import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { RouteComponentProps } from 'react-router-dom';
import { apiUrl, appURL, registryURL } from '../../shared/globals';
import LoadingOrError from '../../components/LoadingOrError';
import PageBanner from '../../components/DetailPage/PageBanner';
import { WhiteLink } from '../../components/Banner/styles';
import { Description, Section, Label, Wrapper, FullWidthWrapper, InnerWrapper } from '../../components/DetailPage/styles';
import { VStack } from '../../components/Stack';
import pathwaysBannerIcon from '../../assets/images/icons/icon-pathways-white-green-01.svg';
import pathwaysIcon from '../../assets/images/icons/icon-pathways-green-blue-01.svg';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import PageSection from '../../components/PageSection';
import PageSectionItem from '../../components/PageSectionItem';
import LinkObject from '../../components/DetailPage/LinkObject';
import { LinkItem } from '../../components/DetailPage/Shared';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import RelatedOrganizationPageSection, { RelatedOrganizationPageSectionAJAXResourceList } from '../../components/DetailPage/RelatedOrganizationPageSection';
import SubjectsAndKeywordsPageSection from '../../components/DetailPage/SubjectsAndKeywordsPageSection';
import OccupationsAndIndustriesSection from '../../components/DetailPage/OccupationsAndIndustriesSection';
import AJAXResourceList, { createElement, getEnglish, renderText, renderLink, renderImage, renderElement, getResourceViaResourceCacheV3, getDetailPageHeaderModifiedDate, renderOutline, lookupSchema, appendAJAXCacheV3ResourceRaw } from '../../components/AJAXResourceList';
import { getResourceByURI, createKeywordSearchLink, createKeywordSearchLinkListFromCredentialAlignmentObjectList, createReferenceFrameworkFromCredentialAlignmentObject } from '../../components/GetResourceByURI';
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import { renderJSONLDMetaTags } from '../../utils/JSONLDMetaHelper';
import {
	Pathway as PathwayType,
	PathwayComponent as PathwayComponentType,
	ComponentCondition as ComponentConditionType,
	IdentifierValue as IdentifierValueType,
	ValueProfile as ValueProfileType,
	ConceptScheme,
	Concept as ConceptType,
	Link,
	Outline,
	AJAXSettingsOutline,
    PathwayComponent
} from '../../types/external';
import AJAXModal from '../../components/Modal/AJAXModal';
import { Concept, convertDataToConceptScheme, convertDataToConcept } from '../ConceptSchemePage';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";
import { ModalButtonAndWindow } from '../../components/Modal';
import OutlineList from '../../components/DetailPage/Outline';
import DisplayPills from '../../components/PillDisplay';

interface Props {
	id: string;
	targetCTID?: string;
}

export default function PathwayPageV3(props: RouteComponentProps<Props>) {
	const id = props.match.params.id;
	const [dataIsLoading, setDataIsLoading] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [rawPathwayData, setRawPathwayData] = useState({} as any);
	const [pageModel, setPathway] = useState({} as PathwayType);
	const [relatedItemsMap, setRelatedItemsMap] = useState({} as any);
	const [graph, setGraph] = useState([] as Array<any>);
	const [progressionModelData, setProgressionModelData] = useState({} as ConceptScheme);
	const [progressionLevelsData, setProgressionLevelsData] = useState([] as Array<ConceptType>)
	const [localAJAXCache, setLocalAJAXCache] = useState([] as Array<any>); //Used to trick react into re-rendering the data
	const [supportServiceData, setSupportServiceData] = useState([] as Array<Outline>);
	const [supportServiceWrapper, setSupportServiceWrapper] = useState({} as AJAXSettingsOutline);
	const [externalComponents, setExternalComponents] = useState([] as PathwayComponent[]);


	var rawPathwayDataNow = {} as any;
	var rawExtraDataNow = {} as any;
	var supportServices = [] as Array<Outline>;
	useEffect(() => {
		getResourceByURI(`${apiUrl}/dsp_pathway/${id}?includeRelatedResources=false&includeGraphData=true&perBranchLimit=-1`, setLocalAJAXCache, null, (loaded: any) => {
			//Get data
			rawPathwayDataNow = loaded.Result.Resource;
			rawExtraDataNow = loaded.Extra;
			renderJSONLDMetaTags(rawPathwayDataNow, "Pathway");
			setRawPathwayData(loaded.Result.Resource); //Takes extra rendering cycles for some stupid reason so double assign it
			var metadata = loaded.Result.Metadata || {};
			var relatedItemsMapData = loaded.Result.RelatedItemsMap || {};
			var graphItemsData = loaded.Result.RelatedItems || [] as Array<any>;
			setDataIsLoading(false);
			setPathway(convertDataToPathway(rawPathwayDataNow || { "ceterms:name": "Missing Pathway", "ceterms:description": "Unable to find data for identifier: " + id } as any, metadata, rawExtraDataNow));

			setGraph(graphItemsData);
			setRelatedItemsMap(relatedItemsMapData);
			console.log("Detail Data", { Pathway: pageModel, Graph: graph, GraphNow: loaded.Result.RelatedItems });

			//Get owner for header
			if (!pageModel.OwnedByLabel && rawPathwayDataNow["ceterms:ownedBy"]) {
				getResourceViaResourceCacheV3(rawPathwayDataNow["ceterms:ownedBy"]?.[0], (data: any) => {
					pageModel.OwnedByLabel = {
						Label: getEnglish(data?.["ceterms:name"]),
						URL: appURL + "/resources/" + data["ceterms:ctid"]
					} as Link;

					var updatedPathway = convertDataToPathway(rawPathwayDataNow || { "ceterms:name": "Missing Pathway", "ceterms:description": "Unable to find data for identifier: " + id } as any, metadata, rawExtraDataNow);
					setPathway({...updatedPathway, OwnedByLabel: pageModel.OwnedByLabel});
				});
			}

			//Get Progression Model
			if (!progressionModelData.CTID && rawPathwayDataNow["asn:hasProgressionModel"]) {
				getResourceViaResourceCacheV3(rawPathwayDataNow["asn:hasProgressionModel"].replace("/resources/", "/graph/"), (data: any) => {
					console.log("Got Progression Model Data", data);
					var progressionModel = data["@graph"]?.find((m: any) => m["@type"] == "asn:ProgressionModel");
					var progressionLevels = data["@graph"]?.filter((m: any) => m["@type"] == "asn:ProgressionLevel");

					setProgressionModelData(convertDataToConceptScheme(progressionModel, null, "", setLocalAJAXCache));

					//Forcibly convert the data because React can't handle it
					progressionLevels.forEach((level: any) => {
						if (!progressionLevelsData.find(m => m.CredentialRegistryURL == level["@id"])) {
							var convertedConcept = convertDataToConcept(level, setLocalAJAXCache);
							progressionLevelsData.push(convertedConcept);
						}
					});
					setProgressionLevelsData([...progressionLevelsData]);

				});
			}

			//Get Support Services
			if (!pageModel.HasSupportService && rawPathwayDataNow["ceterms:hasSupportService"]?.length > 0) {
				pageModel.HasSupportService = { Values: [{Label: "test"}] as Array<Outline> } as AJAXSettingsOutline;
				(rawPathwayDataNow["ceterms:hasSupportService"] || []).forEach((serviceURI: string) => {
					var holder = {} as Outline;
					pageModel.HasSupportService.Values.push(holder);
					supportServices.push(holder);
					getResourceViaResourceCacheV3(serviceURI, (data: any) => {
						console.log("Got support service data", data);
						holder.Label = getEnglish(data["ceterms:name"]) || "Unnamed Support Service";
						holder.Description = getEnglish(data["ceterms:description"]) || "No Description";
						holder.URL = appURL + "/resources/" + data["ceterms:ctid"];
						//setSupportServiceData([...supportServiceData, holder]);
						console.log("Support", supportServices);
						setSupportServiceWrapper({ ...pageModel.HasSupportService, Values: [...supportServices] }); //Have to do this to get around react's stupid garbage architecture
					});
				});
			}
		},
			(error: any) => {
				setHasError(true);
				console.log("Error loading data: ", error);
			});

		new URLSearchParams(window.location.search).forEach(function (value: string, key: string) {
			if (key.toLowerCase() == "memberctid") {
				jumpToProxyForCTID(value.toLowerCase(), 0);
			}
		});
	}, [id, localAJAXCache]);

	function convertDataToPathway(resource: any, metadata: any, rawExtraDataNow: any): PathwayType {
		return ({
			BroadType: "Pathway",
			CTDLType: "ceterms:Pathway",
			CTDLTypeLabel: "Pathway",
			Meta_Id: id,
			Meta_Language: resource["ceterms:inLanguage"]?.[0] || "en",
			Meta_LastUpdated: metadata?.DateModified || "Unknown",
			Meta_LastUpdatedHeader: getDetailPageHeaderModifiedDate(metadata?.DateModified),
			HasDestinationComponent: Array.isArray(resource["ceterms:hasDestinationComponent"]) ? resource["ceterms:hasDestinationComponent"][0] : resource["ceterms:hasDestinationComponent"],
			CredentialRegistryURL: resource["@id"],
			CTID: resource["ceterms:ctid"],
			InstructionalProgramType: resource["ceterms:instructionalProgramType"]?.map((m: any) => { return createReferenceFrameworkFromCredentialAlignmentObject("competencyframework", m) }),
			IndustryType: resource["ceterms:industryType"]?.map((m: any) => { return createReferenceFrameworkFromCredentialAlignmentObject("competencyframework", m) }),
			OccupationType: resource["ceterms:occupationType"]?.map((m: any) => { return createReferenceFrameworkFromCredentialAlignmentObject("competencyframework", m) }),
			Name: getEnglish(resource["ceterms:name"]),
			Description: getEnglish(resource["ceterms:description"]),
			SubjectWebpage: resource["ceterms:subjectWebpage"],
			Subject: resource["ceterms:subject"]?.map((m: any) => { return createKeywordSearchLink("pathway", getEnglish(m["ceterms:targetNodeName"])) }),
			HasMember: resource["ceterms:hasMember"] || [],
			HasPart: resource["ceterms:hasPart"] || [],
			RegistryData: { CTID: resource["ceterms:ctid"], Resource: { URL: resource["@id"], Label: "View Resource" }, Envelope: { URL: resource["@id"]?.replace("/resources/", "/envelopes/"), Label: "View Envelope" } },
			PreviousVersion: resource["ceterms:previousVersion"],
			LatestVersion: resource["ceterms:latestVersion"],
			NextVersion: resource["ceterms:nextVersion"],
			LifeCycleStatusType : resource["ceterms:lifeCycleStatusType"] && resource["ceterms:lifeCycleStatusType"]["ceterms:targetNodeName"] ? resource["ceterms:lifeCycleStatusType"]["ceterms:targetNodeName"]["en-US"] : null,
			Meta_JSON: resource,
			AllowUseOfPathwayDisplay: rawExtraDataNow?.AllowUseOfPathwayDisplay
		} as any) as PathwayType;
	}

	function jumpToProxyForCTID(targetCTID: string, attempts: number) {
		console.log("jumping to CTID", targetCTID)
		setTimeout(function () {
			var element = document.querySelector("[data-ctid='" + targetCTID + "']");
			if (element) {
				element.scrollIntoView();
			}
			else if (attempts <= 10) {
				jumpToProxyForCTID(targetCTID, attempts + 1);
			}
		}, 500);
	}
	const checkComponents = (components: any, haspart: any) => {
		const missingIds = [];
		if (components.length > 0) {
			for (const component of components) {
				const precededby = component['ceterms:precededBy'];
				if (Array.isArray(precededby)) {
					for (const id of precededby) {
						if (!haspart.includes(id)) {
							missingIds.push(id);
						}
					}
				} else {
					if (!haspart.includes(precededby) && precededby != undefined) {
						missingIds.push(precededby);
					}
				}
			}
		}
		return missingIds;
	};
	var pathwayComponents = graph.filter(m => m["ceasn:isPartOf"] == pageModel.CredentialRegistryURL);
	pathwayComponents.sort((a, b) => { return pageModel.HasDestinationComponent == a["@id"] ? -1 : 0 });
	var pathwayComponentURLs=pathwayComponents.map((m: any) => m["@id"]) as Array<string>;
	var topProgressionLevels = progressionModelData.HasTopConcept?.length > 0 ? progressionModelData.HasTopConcept.map((m) => { return progressionLevelsData.filter((n) => { return n.CredentialRegistryURL == m })[0] }).filter((m) => { return m }) : progressionLevelsData;
	;
	useEffect(() => {
		var hasExternalComponents = checkComponents(pathwayComponents, pageModel.HasPart);
		if (hasExternalComponents != null && hasExternalComponents.length > 0) {
			const updatedComponents: any[] = [];
			for (const component of hasExternalComponents) {
				const ctid = component.split("resources/")[1];
				var data = getResourceByURI(`${apiUrl}/PathwayComponent/${ctid}`, setLocalAJAXCache).Data;
				if (data != null && data.Result) {
					updatedComponents.push(data.Result);
				}
			}
			if (updatedComponents.length > 0 && updatedComponents.length != externalComponents.length) {
				setExternalComponents(updatedComponents);
			}
		}
	}, [pathwayComponents, pageModel.HasPart]);

	console.log(pageModel);

	var typeLabel = widgetGetSingleLabelForText("Pathway");
	console.log("Support Services", supportServiceWrapper.Values); //Requires having an array to actually hold the values as they are updated (because react won't) and an object in the state that gets updated (so react will notice the change)

	return (
		<LoadingOrError isLoading={dataIsLoading} hasError={hasError}>
			<PageBanner
				item={pageModel}
				bannerIcon={pathwaysBannerIcon}
				fallbackIcon={pathwaysBannerIcon}
			/>
			<Wrapper>
				<VStack>
					<PageSection
						icon={aboutIcon}
						title="About this Pathway"
						variant="Highlight"
						description={"Basic information about this " + typeLabel}>
						<PageSectionItem>
							<Description>{pageModel.Description || "No Description"}</Description>
							<VStack spacing="10px">
								{pageModel.AllowUseOfPathwayDisplay && <LinkObject item={{ URL: appURL + "/pathwaydisplay?id=" + id, Label: "Open in " + typeLabel + " Viewer" }} buttonType="primary" linkTarget="_blank" />}
								{!pageModel.AllowUseOfPathwayDisplay && <div style={{"textAlign": "center"}}><i>{typeLabel} Viewer is not available for this Resource.</i></div>}
								{pageModel.SubjectWebpage && <LinkObject item={{ URL: pageModel.SubjectWebpage, Label: "Webpage for this " + typeLabel }} buttonType="secondary" />}
							</VStack>
						</PageSectionItem>
						{pageModel.LifeCycleStatusType && (
							<PageSectionItem>
								<Label>Life Cycle Status</Label>
								{pageModel.LifeCycleStatusType}
								{/*<DisplayPills links={[pageModel.LifeCycleStatusType]} />*/}
							</PageSectionItem>
						)}
						{(pageModel.LatestVersion || pageModel.PreviousVersion || pageModel.NextVersion ) &&
							<PageSectionItem>
								<Label>Versions</Label>
							<PageSectionItem>
								{/*<LinkObject item={{ URL: pageModel.LatestVersion, Label: "View the Latest Version" }} />*/}
								{/*<LinkObject item={{ URL: pageModel.PreviousVersion, Label: "View the Previous Version " }} />*/}
								{/*<LinkObject item={{ URL: pageModel.NextVersion, Label: "View the Next Version " }} />*/}
									<LinkItem label="Latest Version:" item={pageModel.LatestVersion} />
									<LinkItem label="Previous Version:" item={pageModel.PreviousVersion} />
									<LinkItem label="Next Version:" item={pageModel.NextVersion} />
								</PageSectionItem>
							</PageSectionItem>
						}
					</PageSection>
				</VStack>
				<VStack>
					<RelatedOrganizationPageSectionAJAXResourceList source={rawPathwayData} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " related to the " + typeLabel} />
				</VStack>
			</Wrapper>
			<FullWidthWrapper>
				<style type="text/css">{`
					.pathwayComponentTotal { padding: 10px 5px; font-style: italic; }
					.pathwayComponent { display: flex; gap: 10px; padding: 10px; border-top: 1px solid #CCC; }
					.pathwayComponent .componentPrefixItem { flex: 0 0 100px; display: flex; flex-direction: column; justify-content: center; background-color: #F5F5F5; font-size: 12px; padding: 5px; min-width: 50px; max-width: 150px; word-break: break-word; }
					.pathwayComponent .componentPrefixItem.componentType { word-break: normal; }
					.pathwayComponent .componentContent {  }
					.pathwayComponent .componentContent .componentHeader { display: flex; gap: 10px; }
					.pathwayComponent .componentContent .componentHeader .componentName { font-weight: bold; }
					.pathwayComponent .componentContent .componentHeader .componentCredentialType { font-style: italic; }
					.pathwayComponent .pathwayComponentContent { flex: 1 1 auto; } 
				`}</style>
				<TabSetOrSingle
					activeIndex={0}
					items={[
						{
							Label: widgetGetPluralLabelForText("Pathway Components"),
							Content: (
								<PageSection icon={pathwaysIcon} title={widgetGetPluralLabelForText("Pathway Components")} description={widgetGetPluralLabelForText("Pathway Components") + " in this " + typeLabel}>
									<div className="pathwayComponentTotal">This pathway contains {pathwayComponentURLs.length + externalComponents.length} Components.</div>
									<AJAXResourceList urls={pathwayComponentURLs} onSuccess={(data: any, itemContainer: any, itemList: any) => renderPathwayComponentProxyForData(pageModel, data, itemContainer, itemList)} resources={graph} />
									{externalComponents?.map((component: PathwayComponent, index: number) => (
										<div className="pathwayComponent">
											<div className="componentPrefixItem">
												{component.PathwayComponentType}
											</div>
											<div className="componentPrefixItem">
												External Component
											</div>
											<div className="componentContent">
												<div className="componentHeader">
													<div className="componentName">
														<a href={window.location.origin + "/resources/" + component.CTID}> {component.Name}</a>
													</div>
													<div className="componentCredentialType">
														{component.CredentialType}
													</div>
												</div>
												<div className="pathwayComponentContent">
													{component.Description}
												</div>
											</div>
										</div>
									))}
								</PageSection>
							)
						},
						{
							Label: widgetGetPluralLabelForText("Progression Levels"),
							Content: (
								<PageSection icon={pathwaysIcon} title="Progression Levels" description={widgetGetPluralLabelForText("Progression Levels") + " in this " + widgetGetSingleLabelForText("Progression Model")}>
									{topProgressionLevels && topProgressionLevels.map((level) => (
										<Concept concept={level} allConcepts={progressionLevelsData} key={"concept-" + level.CredentialRegistryURL} />
									))}
								</PageSection>
							)
						},
						{
							Label: "Details",
							Content: (
								<InnerWrapper>
									<VStack>
										<AdditionalInformationPageSection item={pageModel} pageSectionDescription={"Additional information about the " + typeLabel} />
									</VStack>
									<VStack>
										<SubjectsAndKeywordsPageSection item={pageModel} pageSectionDescription={"Subjects and Keywords related to the " + typeLabel} />
										<OccupationsAndIndustriesSection item={pageModel} pageSectionDescription={"Career Field information about the " + typeLabel} itemTypeLabel={widgetGetPluralLabelForText("Pathways")} />
										<ModalButtonAndWindow
											buttonLabel={"Has " + widgetGetSingleLabelForText("Support Service")}
											resourceTitle={pageModel.Name}
											items={supportServiceWrapper.Values}
											Wrapper={Section}
											Content={() => <OutlineList items={supportServiceWrapper.Values} />}
										/>
									</VStack>
								</InnerWrapper>
							)
						}
					].filter(m => m.Content != null)}
				/>
			</FullWidthWrapper>
		</LoadingOrError>
	)
}

function renderPathwayComponentProxyForData(pathway: PathwayType, data: any, itemContainer: any, itemList: any) {
	itemContainer.innerHTML = "";
	itemContainer.classList.add("pathwayComponent");

	lookupSchema(schemaGraph => {
		//Left Margin things
		//Component Type
		renderText(itemContainer, getSchemaProperty(data["@type"], "rdfs:label", data["@type"]), "div", "componentPrefixItem componentType", "Component Type");
		renderText(itemContainer, data["ceterms:componentCategory"], "div", "componentPrefixItem componentCategory", "Component Designation");
		renderText(itemContainer, pathway.HasDestinationComponent == data["@id"] ? "Destination Component" : null, "div", "componentPrefixItem componentDestination", "This component represents the destination for the Pathway.");

		//Content
		var content = renderElement(itemContainer, true, "div", "componentContent", () => { });

		//Name
		var headerContent = renderElement(content, true, "div", "componentHeader", () => { });
		if (data["ceterms:proxyFor"]?.includes(registryURL)) {
			renderLink(headerContent, data["ceterms:proxyFor"].replace(registryURL, appURL).replace("/graph/", "/resources/"), getEnglish(data["ceterms:name"] || "Unnamed Resource"), "_blank", "componentName componentNameLink");
		}
		else {
			renderText(headerContent, getEnglish(data["ceterms:name"] || "Unnamed Resource"), "div", "componentName");
		}

		//Credential Type (if applicable)
		if (data["ceterms:credentialType"]) {
			var type = data["ceterms:credentialType"]?.replace("https://purl.org/ctdl/terms/", "ceterms:");
			renderText(headerContent, "(" + getSchemaProperty(type, "rdfs:label", type) + ")", "div", "componentCredentialType")
		}

		//Description
		renderText(content, getEnglish(data["ceterms:description"] || data["ceasn:description"] || data["skos:definition"] || data["ceasn:competencyText"] || "No Description"), "div", "componentDescription");

		function getSchemaProperty(termURI: string, schemaProperty: string, fallback: string): any {
			return getEnglish(schemaGraph.find(m => m["@id"] == termURI)?.[schemaProperty] || fallback);
		}
	});

}

