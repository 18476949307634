import React from 'react';
import styled from 'styled-components/macro';
import { Box, BoxLabel, BoxContent, Section, SectionContent } from '../../components/DetailPage/Shared';
import { CompetencyFramework as CompetencyFrameworkType, Competency as CompetencyType, AJAXSettings } from '../../types/external';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TabSetOrSingle } from './TabSelector';
import { appURL } from '../../shared/globals';
import AJAXResourceList, { appendAJAXCacheV3Resource } from '../../components/AJAXResourceList';
import { CompetencyStyleBlock, renderCompetencyFromRDF } from '../../components/DetailPage/Competency';

export function CompetencyFrameworkList(props: { frameworkList: AJAXSettings; }) {
	return (
		<TabSetOrSingle
			activeIndex={0}
			items={(props.frameworkList.Values || []).map(
				(framework, index) => (
					{
						Label: (framework as CompetencyFrameworkType).Name,
						Content: <CompetencyFrameworkAJAX framework={framework as CompetencyFrameworkType} />
					}
				)
			)}
		/>
	)
}

export function CompetencyFramework(props: { framework: CompetencyFrameworkType }) {
	return (
		<Box>
			{props.framework.CTID && <BoxLabel><a href={appURL + "/resources/" + props.framework.CTID}>{props.framework.Name}</a></BoxLabel>}
			{!props.framework.CTID && <BoxLabel>{props.framework.Name}</BoxLabel>}
			<BoxContent>
				<Section>
					<SectionContent>{props.framework.Description}</SectionContent>
				</Section>
				<Section>
					{props.framework.Meta_HasPart.filter((competency) => (props.framework.HasTopChild.indexOf(competency.CredentialRegistryURL) > -1)).map((competency) => (
						<Competency competency={competency} competencyList={props.framework.Meta_HasPart} />
					))}
				</Section>
			</BoxContent>
		</Box>
	)
}

export function CompetencyFrameworkAJAX(props: { framework: CompetencyFrameworkType }) {
	var applicableCompetencies = props.framework.Meta_HasPart.filter(competency => competency.Meta_IsReferenced);
	applicableCompetencies.filter(m => m.CredentialRegistryURL.indexOf("_:") == 0).forEach(competency => {
		appendAJAXCacheV3Resource({
			URL: competency.CredentialRegistryURL,
			Data: competency
		});
	});
	var applicableCompetencyURLs = applicableCompetencies.map(competency => competency.CredentialRegistryURL);
	return (
		<Box>
			{props.framework.CTID && <BoxLabel><a href={appURL + "/resources/" + props.framework.CTID}>{props.framework.Name}</a></BoxLabel>}
			{!props.framework.CTID && <BoxLabel>{props.framework.Name}</BoxLabel>}
			<BoxContent>
				<Section>
					<SectionContent>{props.framework.Description}</SectionContent>
				</Section>
				<Section>
					<AJAXResourceList
						//urls={props.framework.HasTopChild.filter(url => applicableCompetencyURLs.includes(url))}
						urls={applicableCompetencyURLs}
						onSuccess={(data, itemContent, listContent) => renderCompetencyFromRDF(data, itemContent, [], false)}
						StyleComponent={CompetencyStyleBlock}
					/>
				</Section>
			</BoxContent>
		</Box>
	)
}

export function Competency(props: { competency: CompetencyType, competencyList: Array<CompetencyType> }) {
	return (
		<CompetencyWrapper>
			<CompetencyData>
				<CompetencyPrefix>
					<CompetencyIconItem>{
						props.competency.Meta_IsReferenced ?
							<FontAwesomeIcon icon="check" title="This competency is referenced directly"></FontAwesomeIcon> :
							<FontAwesomeIcon icon="info" title="This competency is not directly referenced, but is displayed for context"></FontAwesomeIcon>
					}</CompetencyIconItem>
					{props.competency.CompetencyCategory ? <CompetencyPrefixItem>{props.competency.CompetencyCategory}</CompetencyPrefixItem> : null}
					{props.competency.ListID ? <CompetencyPrefixItem>{props.competency.ListID}</CompetencyPrefixItem> : null}
					{props.competency.CodedNotation ? <CompetencyPrefixItem>{props.competency.CodedNotation}</CompetencyPrefixItem> : null}
				</CompetencyPrefix>
				<CompetencyContent>
					{props.competency.CompetencyLabel ? <CompetencyLabel>{props.competency.CompetencyLabel}</CompetencyLabel> : null}
					{props.competency.CompetencyText ? <div>{props.competency.CompetencyText}</div> : null}
					{props.competency.Comment ? <div>{props.competency.Comment}</div> : null}
				</CompetencyContent>
			</CompetencyData>
			{props.competency.HasChild?.length > 0 ? (
				<CompetencyChildren>
					{props.competency.HasChild.map(function (childURI) {
						const child = props.competencyList.filter((item) => (item.CredentialRegistryURL == childURI))[0];
						if (child) {
							return (
								<Competency competency={child} competencyList={props.competencyList} />
							)
						}
					})}
				</CompetencyChildren>
			) : null}
		</CompetencyWrapper>
	)
}

const CompetencyWrapper = styled.div`
	padding: 5px;

	&:not(:last-child) {
		border-bottom: 1px solid #DDD;
	}
`;

const CompetencyData = styled.div`
	display: flex;
`;

const CompetencyPrefix = styled.div`
	flex: 1 1 auto;
	max-width: 300px;
	display: flex;
	margin-right: 10px;
	font-size: 80%;
`;

const CompetencyIconItem = styled.div`
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 2.5px 5px;
	opacity: 0.8;
`;

const CompetencyPrefixItem = styled.div`
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-width: 50px;
	max-width: 150px;
	padding: 2.5px 5px;
	background-color: #EEE;
	margin: 1px;
`;

const CompetencyContent = styled.div`
	flex: 1 1 100%;
`;

const CompetencyLabel = styled.div`
	font-weight: bold;
`;

const CompetencyChildren = styled.div`
	padding: 5px 0 0 25px;
`;
