import React, { ReactNode } from 'react';
import {
    Left,
    Center,
    Right,
    SubTitle,
    Wrapper,
    Inner,
    Type,
    Title,
    Content,
	Icon,
	BadgeIcon
} from './styles';
import { legacySourceUrl } from '../../shared/globals';

type Props = Partial<{
	icon: string;
	hasBadge?: boolean;
    fallBackIcon?: string;
    title: string;
	subTitle: ReactNode;
    content: ReactNode;
    type: string;
    otherContent: ReactNode;
}>;

export default function Banner({
	icon,
	hasBadge,
    fallBackIcon,
    title,
    subTitle,
    content,
    type,
    otherContent,
}: Props) {
    function onIconLoadingError(e: any) {
        e.target.src = fallBackIcon;
    }
    return (
        <Wrapper>
            <Inner>
                <Left>
					<Icon alt="" src={icon} onError={onIconLoadingError} />
					{hasBadge && <BadgeIcon alt="" src={legacySourceUrl + "/images/icons/flat_badge_cropped.png"} />}
                </Left>
                <Center>
					{type && (
						<Type>{type}</Type>
					)}
                    <Title>{title}</Title>
					{subTitle && (
						<SubTitle>{subTitle}</SubTitle>
					)}
                    <Content>{content}</Content>
                </Center>
                {otherContent && <Right>{otherContent}</Right>}
            </Inner>
        </Wrapper>
    );
}
