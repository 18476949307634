import React from 'react';
import { Link } from 'react-router-dom';
import { Outline } from '../../types/external';
import { getLinkType } from '../../utils';

interface Props {
    outline: Outline;
}
export default function TextLink({ outline }: Props) {
    const linkType = getLinkType(outline.URL);

    switch (linkType) {
        case 'external':
            return (
                <a href={outline.URL} target="external">
                    {outline.Label}
                </a>
            );
        case 'internal':
            return <Link to={outline.URL} target="detailPage">{outline.Label}</Link>;
        case 'none':
            return <>{outline.Label}</>;
    }
}
