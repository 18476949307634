import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Assessment, ConditionProfile as ConditionProfileType, Outline, QuantitativeValue } from '../../types/external';
import { Box, BoxLabel, BoxContent, Section, SectionLabel, SectionContent, SectionContentItem } from './Shared';
import OutlineList from './Outline';
import JurisdictionProfileList from './JurisdictionProfile';
import CostManifestList from './CostManifest'
import CostProfileList from './CostProfile'
import DisplayPills from '../../components/PillDisplay';
import ValueProfileList from './ValueProfile';
import DateRange from './DateRange';
import { TabSetOrSingle } from './TabSelector';
import { ModalButtonAndWindow } from '../Modal';
import { CompetencyFrameworkList } from './CompetencyFramework';
import { widgetGetPluralLabelForText, widgetReplaceSingleLabelInText, widgetReplacePluralLabelInText } from '../../utils/SiteWidgetizer';

export default function ConditionProfileList(props: { items: ConditionProfileType[] }) {
	return (props.items?.length > 0 &&
		<div>
			{props.items.map((item) => (
				<ConditionProfile item={item} />
			))}
		</div>
	) || null
}

export function ConditionProfile(props: { item: ConditionProfileType }) {
	return (props.item &&
		<Box>
			<BoxLabel>{props.item.Name || "Conditions"}</BoxLabel>
			<BoxContent>
				<Section>
					<SectionContent>
						<div>{props.item.Description || "No Description"}</div>
						{props.item.Condition?.length > 0 &&
							<ul className="withAbove">
								{props.item.Condition.map((condition) => (
									<li>{condition}</li>
								))}
							</ul>
						}
					{props.item.SubjectWebpage &&
						<a href={props.item.SubjectWebpage} target="_blank">See Web Page for these Conditions</a>
					}
					</SectionContent>
				</Section>
				{(props.item.DateEffective || props.item.SubmissionOf?.length > 0 || props.item.SubmissionOfDescription || props.item.Weight) && 
					<Section>
						<SectionLabel>Details:</SectionLabel>
						<SectionContent>
							<DateRange FromDate={props.item.DateEffective} />
							{(props.item.SubmissionOf?.length > 0 || props.item.SubmissionOfDescription) &&
								<div>
									<div><b>Submission Of:</b> {props.item.SubmissionOfDescription}</div>
									{props.item.SubmissionOf?.length > 0 && 
										<ul className="withAbove">
											{props.item.SubmissionOf.map((submissionItem) => (
												<li>{submissionItem.indexOf("http") == 0 ? <a href={submissionItem} target="_blank">{submissionItem}</a> : <>{submissionItem}</>}</li>
											))}
										</ul>
									}
								</div>
							}
							<SectionContentItem label="Weight/Importance" data={props.item.Weight} RenderWith={(data) => <div>{data}</div>} />
						</SectionContent>
					</Section>
				}
				{(props.item.AudienceLevelType?.length > 0 || props.item.AudienceType?.length > 0 || props.item.Experience || props.item.YearsOfExperience || props.item.MinimumAge) &&
					<Section>
						<SectionLabel>Applicable Audience:</SectionLabel>
						<SectionContent>
							<SectionContentItem label="Audience Type" data={props.item.AudienceType} RenderWith={(data) => <DisplayPills links={data} />} />
							<SectionContentItem label="Audience Level Type" data={props.item.AudienceLevelType} RenderWith={(data) => <DisplayPills links={data} />} />
							<SectionContentItem label="Relevant Experience" data={props.item.Experience} RenderWith={(data) => <div>{data}</div>} />
							<SectionContentItem label="Years of Experience" data={props.item.YearsOfExperience} RenderWith={(data) => <div>{data}</div>} />
							<SectionContentItem label="Minimum Age" data={props.item.MinimumAge} RenderWith={(data) => <div>{data}</div>} />
						</SectionContent>
					</Section>
				}
				{(props.item.CommonCosts?.Values?.length > 0 || props.item.EstimatedCost?.length > 0) && 
					<Section>
						<SectionLabel>Cost Information:</SectionLabel>
						<SectionContent>
							<CostManifestList items={props.item.CommonCosts?.Values} />
							<CostProfileList items={props.item.EstimatedCost} />
						</SectionContent>
					</Section>
				}
				{(props.item.CreditValue?.length > 0 || props.item.CreditUnitTypeDescription) &&
					<Section>
						<SectionLabel>Credit Information:</SectionLabel>
						<SectionContent>
							{props.item.CreditUnitTypeDescription && <div>{props.item.CreditUnitTypeDescription}</div>}
							<ValueProfileList items={props.item.CreditValue} />
						</SectionContent>
					</Section>
				}
				{props.item.Jurisdiction?.length > 0 &&
					<Section>
						<SectionLabel>Jurisdiction Information:</SectionLabel>
						<SectionContent>
							<JurisdictionProfileList items={props.item.ResidentOf} />
							<JurisdictionProfileList items={props.item.Jurisdiction} />
						</SectionContent>
					</Section>
				}
				{props.item.ResidentOf?.length > 0 &&
					<Section>
						<SectionLabel>Residency Information:</SectionLabel>
						<SectionContent>
							<JurisdictionProfileList items={props.item.ResidentOf} />
						</SectionContent>
					</Section>
				}
				{props.item.TargetCredential?.Values?.length > 0 &&
					<Section>
						<SectionLabel>{widgetGetPluralLabelForText("Credentials")}:</SectionLabel>
						<SectionContent>
							<OutlineList items={props.item.TargetCredential.Values} />
						</SectionContent>
					</Section>
				}
				{props.item.TargetAssessment?.Values?.length > 0 &&
					<Section>
						<SectionLabel>{widgetGetPluralLabelForText("Assessments")}:</SectionLabel>
						<SectionContent>
							<OutlineList items={props.item.TargetAssessment.Values} />
						</SectionContent>
					</Section>
				}
				{props.item.TargetLearningOpportunity?.Values?.length > 0 &&
					<Section>
						<SectionLabel>{widgetGetPluralLabelForText("Learning Opportunities")}:</SectionLabel>
						<SectionContent>
							<OutlineList items={props.item.TargetLearningOpportunity.Values} />
						</SectionContent>
					</Section>
				}
				{props.item.TargetOccupation?.Values?.length > 0 &&
					<Section>
						<SectionLabel>{widgetGetPluralLabelForText("Occupations")}:</SectionLabel>
						<SectionContent>
							<OutlineList items={props.item.TargetOccupation.Values} />
						</SectionContent>
					</Section>
				}
				{props.item.TargetJob?.Values?.length > 0 &&
					<Section>
						<SectionLabel>{widgetGetPluralLabelForText("Jobs")}:</SectionLabel>
						<SectionContent>
							<OutlineList items={props.item.TargetJob.Values} />
						</SectionContent>
					</Section>
				}
			{(props.item.TargetCompetency?.Values?.length > 0 ) &&
				<Section>			
				<SectionLabel>Related {widgetGetPluralLabelForText("Competencies")}</SectionLabel>
					<ModalButtonAndWindow
					buttonLabel={"Requires " + props.item.TargetCompetency?.Total + " " + widgetGetPluralLabelForText("Competencies")}
						resourceTitle={props.item.Name}
						items={props.item.TargetCompetency?.Values}
						Wrapper={Section}
						Content={() => <CompetencyFrameworkList frameworkList={props.item.TargetCompetency} />}
						hideCount={true}
					/>
				</Section>
	
			}
				{props.item.AssertedBy?.Values?.length > 0 &&
					<Section>
						<SectionLabel>Asserted By:</SectionLabel>
						<SectionContent>
							<OutlineList items={props.item.AssertedBy.Values as Outline[]} />
						</SectionContent>
					</Section>
				}
				{props.item.AlternativeCondition?.length > 0 &&
				<Section>
					<SectionLabel>Alternatives and Options:</SectionLabel>
					<SectionContent>
						<TabSetOrSingle
							activeIndex={0}
							items={props.item.AlternativeCondition.map((item, itemIndex) => {
								return {
									Label: item.Name || "Option " + (itemIndex + 1),
									Content: <ConditionProfile item={item} key={"alternate-conditions-" + props.item.Meta_Id + "-" + itemIndex} />
								}
							})}
							key={'alternative-' + props.item.Meta_Id}
						/>
					</SectionContent>
				</Section>
				}
			</BoxContent>
		</Box>
	)
}

export function getConditionProfileListButtonName(prefixText: string, conditionProfileList: Array<ConditionProfileType>): string {
	if (!conditionProfileList || conditionProfileList.length == 0) {
		return prefixText + "Specific Details";
	}

	var countsText = [
		singleOrMulti(getDistinctCount(conditionProfileList, m => m.TargetCredential?.Values, m => m.TargetCredential?.Total || 0), "{count} Credential{s}"),
		singleOrMulti(getDistinctCount(conditionProfileList, m => m.TargetAssessment?.Values, m => m.TargetAssessment?.Total || 0), "{count} Assessment{s}"),
		singleOrMulti(getDistinctCount(conditionProfileList, m => m.TargetLearningOpportunity?.Values, m => m.TargetLearningOpportunity?.Total || 0), "{count} Learning Opportunit{ies}"),
		singleOrMulti(getDistinctCount(conditionProfileList, m => m.TargetOccupation?.Values, m => m.TargetOccupation?.Total || 0), "{count} Occupation{s}"),
		singleOrMulti(getDistinctCount(conditionProfileList, m => m.TargetCompetency?.Values?.flatMap(n => n.Meta_HasPart), m => m.TargetCompetency?.Total || 0), "{count} Competenc{ies}"),
		singleOrMulti(getDistinctCount(conditionProfileList, m => m.AlternativeCondition, m => 0), "Has {count} Alternative{s}"),
	].filter(m => m).join(", ");

	return prefixText + " " + (countsText ? countsText : "Specific Details") + (conditionProfileList.length > 1 ? " (Across " + conditionProfileList.length + " Sets of Conditions)" : "");

	function getDistinctCount(list: Array<ConditionProfileType>, getTargets: (profile: ConditionProfileType) => Array<any> | undefined, getFallbackCount: (profile: ConditionProfileType) => number): number {
		var distinctIDs = list.flatMap(m => getTargets(m) || []).map(m => m.CTID || m.Meta_Id || m.URL || JSON.stringify(m)).filter((value, index, self) => { return self.indexOf(value) == index });
		var fallbackTotal = list.flatMap(m => getFallbackCount(m)).reduce((prev, next) => prev + next, 0);
		return distinctIDs.length || fallbackTotal;
	}

	function singleOrMulti(count: number, labelTemplate: string) : string {
		var text = count == 0 ? "" : labelTemplate.replace(/{count}/g, count.toString()).replace(/{s}/g, count == 1 ? "" : "s").replace(/{ies}/g, count == 1 ? "y" : "ies");
		return count == 0 ? "" : count == 1 ? widgetReplaceSingleLabelInText(text) : widgetReplacePluralLabelInText(text);
	}
	
}

const ConditionProfileWrapper = styled.div`

`;

const DisplayItem = styled.div`

`;
