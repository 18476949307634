import styled, { css } from 'styled-components';
import { widgetGetColor } from '../../utils/SiteWidgetizer';

const PageSectionItem = styled.div<{
    filled?: boolean;
    noBorder?: boolean;
}>`
    display: flex;
    flex-direction: column;
    padding: 0 0 15px 0;
	margin: 0 0 15px 0;
    justify-content: 'space-around';
    border-bottom: ${(p) => `1px solid ${widgetGetColor("SiteHeader", false, p.theme.color.aquaDark)}`};
    &:last-child {
        border-bottom: none;
    }
    ${(p) =>
        p.filled &&
        css`
            background-color: ${p.theme.color.aquaDark};
            margin: 0 -10px -10px -10px;
            padding: 20px 10px;
            @media (max-width: ${(props) =>
                    props.theme.breakpoint.tabletPortrait}) {
                margin-bottom: 0;
            }
        `}
    ${(p) =>
        p.noBorder &&
        css`
            border-bottom: none;
        `}
`;

export default PageSectionItem;
