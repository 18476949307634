import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';
import { legacySourceUrl } from '../../shared/globals';
import axios from 'axios';
import ActivityIndicator from '../../components/ActivityIndicator';
import Error from '../../components/Error';
import InnerHTML from 'dangerously-set-html-content';

export default function WidgetConfigurePage(props: RouteComponentProps) {
	const [loading, setLoading] = useState(false);
	const [pageContent, setPageContent] = useState(' ');
	const [error, setError] = useState(false);

	useEffect(() => {
		setLoading(true);
		const url = `${legacySourceUrl}/widget/searchwidget?coreonly=true`;
		axios
			.get(url)
			.then((res) => {
				setPageContent(res.data);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setError(true);
				setLoading(false);
			});
	}, []);
	if (loading) {
		return (
			<LoadingIndicatorWrapper>
				<ActivityIndicator />
			</LoadingIndicatorWrapper>
		);
	}
	if (error) {
		return <Error />;
	}
	return <InnerHTML html={pageContent} />;
}

const LoadingIndicatorWrapper = styled.div`
    text-align: center;
    padding: 50px;
`;
