import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { Organization, Credential, Assessment, LearningOpportunity, ScheduledOffering, Place as PlaceType, SupportService } from '../../types/external';
import { Place } from './Place';
import { isFilled } from '../../validation';
import PageSection from '../PageSection';
import PageSectionItem from '../PageSectionItem';
import locationsIcon from '../../assets/images/icons/icon-location-blue-green-01.svg';
import {
    Label,
    MapWrapper,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
	faChevronRight,
	faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import GoogleMap from 'google-map-react';
import { defaultMapSetting } from '../../shared/data';
import { googleMapsKey } from '../../shared/globals';
import { setupMap } from '../../services/map';
import JurisdictionProfileList from '../../components/DetailPage/JurisdictionProfile';
import { detailSectionProperties } from '../../utils';
import HasAnyFilled from '../../components/HasAnyFilled';
import ContactPointList from './ContactPoint';

interface Props {
	item: Credential | Assessment | LearningOpportunity | Organization | ScheduledOffering | SupportService;
	pageSectionDescription?: string;
}

export default function LocationPageSection(props: Props) {
    const googleMap = useRef<google.maps.Map>();
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);

	var generic = props.item as any;
	var locations: PlaceType[] = generic.Address || generic.AvailableAt || [];

	//Filter out empty addresses
	locations = locations.filter((item) => { return Object.keys(item).length > 0 });

	//Filter out locations whose only property is TargetContactPoint
	var contactOnlyLocations = locations.filter((location) => (Object.keys(location).length == 1 && location.TargetContactPoint?.length > 0));
	locations = locations.filter((location) => (contactOnlyLocations.indexOf(location) == -1));

	//Inject Location IDs for accessibility and to reference the numbered icons on the page
	locations = locations.map((location, index) => {
		return {
			...location,
			MarkerLabel: location.MarkerLabel || (index + 1).toString()
		}
	});

	//Get a list of all real locations with contacts
	var allLocationContacts = locations.filter(m => m.TargetContactPoint).flatMap(m => m.TargetContactPoint);

	//Get a list of all contact-only data
	var nonLocationContacts = contactOnlyLocations.flatMap((location) => (location.TargetContactPoint));
	if (generic.Email?.length > 0 || generic.SocialMedia?.length > 0) {
		nonLocationContacts.push({
			Name: "",
			Email: generic.Email,
			SocialMedia: generic.SocialMedia,
			ContactType: "",
			FaxNumber: "",
			Telephone: []
		});
	}

	//Handle map marker clicks
    function nextMapMarkerOnClick(direction: 'left' | 'right') {
        return () => {
            const nextIndex =
                direction === 'left'
                    ? selectedAddressIndex - 1
                    : selectedAddressIndex + 1;
            const fallbackIndex =
                direction === 'left' ? locations.length - 1 : 0;

            if (locations[nextIndex]) {
                setSelectedAddressIndex(nextIndex);
            } else if (locations[fallbackIndex]) {
                setSelectedAddressIndex(fallbackIndex);
            }
        };
    }

    useEffect(() => {
        if (isFilled(locations) && googleMap.current) {
            const lat = locations[selectedAddressIndex].Latitude;
            const lng = locations[selectedAddressIndex].Longitude;
			googleMap.current.panTo({ lat, lng });
			googleMap.current.setZoom(12);
        }
	}, [googleMap, locations, selectedAddressIndex]);

	//Handle jurisdiction text
    const entityType = props.item.BroadType;
	var mainJurisdicionLabel = "This " + entityType + " has these assertions in:";
	var jurisdictionExceptionLabel = "This " + entityType + " does not have these assertions in:";
    if(entityType == "Organization") {
        mainJurisdicionLabel = "This organization has a notable presence in:";
        jurisdictionExceptionLabel = "This organization does not have a notable presence in:";
	}

    return (
        <HasAnyFilled
            data={props.item}
            keys={[
                ...detailSectionProperties.locations,
				...detailSectionProperties.jurisdiction,
				...detailSectionProperties.contact
            ]}>
			<PageSection icon={locationsIcon} title="Locations" description={props.pageSectionDescription}>
                {isFilled(props.item.AvailabilityListing) && (
                    <PageSectionItem>
                        <Label>Availability</Label>
                        {props.item.AvailabilityListing.map(
                            (listing, index) => (
                                <AvailabilityLink
                                    href={listing}
                                    key={`cr-availability-${index}`}>
                                    {listing}
								</AvailabilityLink>
                            ),
                        )}
                    </PageSectionItem>
                )}
                {isFilled(locations) && (
                    <PageSectionItem>
                        <LocationScroller>
                            {locations.length > 1 && (
								<LocationScrollerButton
									title="Previous Address"
									onClick={nextMapMarkerOnClick('left')}
								>
                                    <FontAwesomeIcon
                                        icon={faChevronLeft}
                                        size="lg"
                                    />
                                </LocationScrollerButton>
                            )}
                            <LocationScrollerContent>
								<NumberedLocation
									number={parseInt(locations[selectedAddressIndex].MarkerLabel)}
									place={locations[selectedAddressIndex]}
								/>
							</LocationScrollerContent>
                            {locations.length > 1 && (
								<LocationScrollerButton
									title="Next Address"
									onClick={nextMapMarkerOnClick('right')}
								>
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        size="lg"
                                    />
                                </LocationScrollerButton>
                            )}
                        </LocationScroller>
                        <MapWrapper>
                            <GoogleMap
                                defaultCenter={defaultMapSetting.center}
                                defaultZoom={defaultMapSetting.zoom}
                                bootstrapURLKeys={{
                                    key: googleMapsKey,
                                }}
                                yesIWantToUseGoogleMapApiInternals
                                onGoogleApiLoaded={({ map, maps }) => {
                                    setupMap(map, maps, locations || []);
                                    googleMap.current = map;
                                }}
                            />
                        </MapWrapper>
						{(locations.length > 1 || allLocationContacts.length > 0) && (
							<LocationListing label={locations.length + " Location" + (locations.length == 1 ? "" : "s") + (allLocationContacts.length > 0 ? " and " + allLocationContacts.length + " Contact" + (allLocationContacts.length == 1 ? "" : "s") : "")}>
								{locations.map((place, index) => (
									<LocationListingItem>
										<LocationListingItemButton
											onClick={() => setSelectedAddressIndex(index)}
											key={`cr-location-${index}`}
										>
											<NumberedLocation
												number={index + 1}
												place={place}
											/>
										</LocationListingItemButton>
										<ContactPointList
											items={place.TargetContactPoint}
										/>
									</LocationListingItem>
								))}
                            </LocationListing>
                        )}
                    </PageSectionItem>
                )}
				{nonLocationContacts?.length > 0 &&
					(
					<PageSectionItem>
						<Label>General Contact Information</Label>
						<ContactPointList
							items={nonLocationContacts}
						/>
					</PageSectionItem>
					)
				}
				{isFilled(props.item.SameAs) && (
					<PageSectionItem>
						<Label>Same As</Label>
						{props.item.SameAs.map((url) => (
							<div key={`cr-same-as-${url}`}>
								<a href={url}>{url}</a>
							</div>
						))}
					</PageSectionItem>
				)}
				{isFilled(props.item.Jurisdiction) && (
					<PageSectionItem>
						<Label>Jurisdictions</Label>
						<JurisdictionProfileList
							mainJurisdictionLabel={mainJurisdicionLabel}
							jurisdictionExceptionLabel={jurisdictionExceptionLabel}
							items={props.item.Jurisdiction}
						/>
					</PageSectionItem>
				)}
            </PageSection>
        </HasAnyFilled>
    );
}

function NumberedLocation(props: { number: number; place: PlaceType; }) {
	if (props.place != null) {
		return (
			<NumberedLocationWrapper>
				<LocationNumber>{props.number}</LocationNumber>
				<Place item={props.place} />
			</NumberedLocationWrapper>
		);
	}
	return null;
}

function LocationListing(props: { label: string, children: ReactNode }) {
	const [isOpen, setIsOpen] = useState(false);

	function openOrClose() {
		setIsOpen(!isOpen);
	}

	return (
		<>
			<LocationListingHeading onClick={openOrClose}>
				<b>{props.label}</b>
				<FontAwesomeIcon icon={isOpen ? faChevronRight : faChevronDown} />
			</LocationListingHeading>
			{isOpen ? (
				<LocationListingContent>{props.children}</LocationListingContent>
			) : null}
		</>
	);
}

const LocationScroller = styled.div`
    display: flex;
    align-items: stretch;
    margin-bottom: 10px;
`;

const LocationScrollerButton = styled.button`
    cursor: pointer;
	background-color: transparent;
	border: none;

	&:hover, &:focus {
		background-color: ${(p) => p.theme.color.aquaLight};
	}
`;

const LocationListingItem = styled.div`
	& .ContactPointList {
		padding: 0 10px;
	}

	&:not(:last-child) { border-bottom: 1px solid #CCC; }
`;

const LocationListingItemButton = styled.button`
	display: block;
	border-radius: 3px;
	width: 100%;
	text-align: left;
	border: none;
	background-color: transparent;
	padding: 5px 10px;
	cursor: pointer;

	&:hover, &:focus {
		background-color: ${(p) => p.theme.color.aquaLight};
	}

`;

export const LocationNumber = styled.label`
    background-color: ${(p) => p.theme.color.aquaDark};
    padding: 5px 10px;
    border-radius: 50%;
    margin-right: 10px;
`;

const LocationScrollerContent = styled.div`
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	padding: 0 10px;
`;

const NumberedLocationWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

const LocationListingContent = styled.div`
    padding-top: 10px;
`;

const LocationListingHeading = styled.button`
    display: flex;
    align-items: center;
    cursor: pointer;
	background-color: transparent;
	border: none;
	padding: 10px;
	border-radius: 3px;

	&:hover, &:focus {
		background-color: ${(p) => p.theme.color.aquaLight};
	}

	& svg {
		margin-left: auto;
		font-size: 20px;
	}
`;

const AvailabilityLink = styled.a`
	display: block;
	margin-bottom: 5px;
	word-break: break-word;
`;