import styled from 'styled-components/macro';
import { Button } from '../common';
import { Header as DropdownHeader } from '../Dropdown/styles';
import { HStack, VStack } from '../Stack';

export const StyledSearchBar = styled(HStack)`
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    > * {
        margin-bottom: 10px;
    }
`;

export const MobileStyledSearchBar = styled(VStack)``;

export const Section = styled(VStack)<{ minWidth?: number }>`
    ${(props) => props.minWidth && `min-width: ${props.minWidth}px`}
`;

export const LocationSection = styled(Section)`
    flex: 0;
    min-width: 175px;
`;

export const SectionTitle = styled.h2`
    padding: 0;
    margin: 0;
`;

export const SearchButton = styled(Button)`
    min-width: 80px;
    padding: 0.65em 5px;
`;

export const MobileSearchText = styled.p`
    font-size: ${(props) => props.theme.fontSize.lg};
    margin: 0;
    font-weight: bold;
`;

export const MobileSearchIconButton = styled(Button)`
    padding: 5px;
    min-width: 40px;
`;

export const ClearButton = styled(Button)`
	border: none;
	border-radius: 3px;
	height: 40px;
	width: 40px;
	min-width: 0;
	padding: 0;
`;