import React from 'react';
import styled from 'styled-components/macro';
import { Place as PlaceType } from '../../types/external';

export default function PlaceList(props: { items: PlaceType[] }) {
	return (props.items?.length > 0 &&
		<div className="PlaceList">
			{props.items.map((item) => (
				<Place item={item} />
			))}
		</div>
	) || null
}

export function Place(props: { item: PlaceType }) {
	return (props.item &&
		<PlaceWrapper className="Place">
			<Header>
				{props.item.Name && <Name>{props.item.Name}</Name>}
				{props.item.Identifier && props.item.Identifier.map((identifier) => (
					<Identifier>
						{identifier.IdentifierType ?
							(<b><a href={identifier.IdentifierType} target="_blank">{identifier.IdentifierTypeName || "Identifier"}</a>:</b>) :
							(<b>{identifier.IdentifierTypeName || "Identifier"}:</b>)}
						{identifier.IdentifierValueCode || "No Value"}
					</Identifier>
				))}
			</Header>
			{props.item.Description && <Description>{props.item.Description}</Description>}
			<AddressText>
				{props.item.StreetAddress && <StreetAddress>{props.item.StreetAddress ? props.item.StreetAddress : ""}</StreetAddress>}
				{(props.item.AddressLocality || props.item.AddressRegion) && <AddressLocalityAndRegion>{props.item.AddressLocality ? props.item.AddressLocality + ", " : ""} {props.item.AddressRegion ? props.item.AddressRegion : ""}</AddressLocalityAndRegion>}
				{props.item.PostalCode && <PostalCode>{props.item.PostalCode}</PostalCode>}
				{props.item.AddressCountry && <AddressCountry className="addressCountry">{props.item.AddressCountry}</AddressCountry>}
			</AddressText>
		</PlaceWrapper>
	) || null
}

const PlaceWrapper = styled.div`
	flex: 1 1 auto;
	padding: 5px 0;
	line-height: 1.3em;
`;

const Header = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
`

const Name = styled.div`
	font-weight: bold;
	margin-right: 10px;

	&:empty {
		display: none;
	}
`;

const Description = styled.div`
	padding: 1px 5px;
	font-size: 85%;
`;

const Identifier = styled.div`
	display: flex;
	align-items: baseline;
	padding: 0 10px;

	&:not(:first-child) {
		border-left: 1px solid #CCC;
	}

	& b {
		margin-right: 0.5em;
	}
`;

const AddressText = styled.div`
	display: flex;
	align-items: stretch;
	font-size: 85%;

	& div {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
`;

const StreetAddress = styled.div`
	border-right: 1px solid #CCC;
	padding-right: 10px;

	&:empty {
		display: none;
	}
`;

const AddressLocalityAndRegion = styled.div`
	padding: 0 10px;
	border-right: 1px solid #CCC;

	&:empty {
		display: none;
	}
`;

const PostalCode = styled.div`
	padding: 0 10px;
	border-right: 1px solid #CCC;
`;

const AddressCountry = styled.div`
	padding-left: 10px;
	text-align: right;
`;

