//Render from Finder API
export function renderJSONLDMetaTags(resource, broadType, modifierMethod) {
	//Skip if empty/null/etc
	if (!resource) {
		return;
	}

	//Match the broad type to a known type
	var renderHelper = [
		{ broadType: "organization", renderMethod: renderAPI_Organization },
		{ broadType: "credential", renderMethod: renderAPI_Credential },
		{ broadType: "assessment", renderMethod: renderAPI_Assessment },
		{ broadType: "learningopportunity", renderMethod: renderAPI_LearningOpportunity },
		{ broadType: "conceptscheme", renderMethod: renderLD_ConceptScheme },
		{ broadType: "competencyframework", renderMethod: renderLD_CompetencyFramework },
		{ broadType: "competency", renderMethod: renderLD_Competency },
		{ broadType: "collection", renderMethod: renderLD_Collection },
		{ broadType: "pathway", renderMethod: renderAPI_Pathway },
	].find(m => m.broadType == broadType.toLowerCase());

	//Get the data and modify as needed
	var result = {
		"@context": "https://schema.org/",
		"@type": "CreativeWork",
	};

	//Append Common properties
	append(result, "additionalType", (resource.CTDLType || resource["@type"])?.replace("ceterms:", "https://purl.org/ctdl/terms/").replace("ceasn:", "https://purl.org/ctdlasn/terms/").replace("qdata:", "https://credreg.net/qdata/terms/"));
	append(result, "name", resource.Name || resource["ceterms:name"] || resource["ceasn:name"] || resource["ceasn:competencyLabel"] || resource["skos:prefLabel"], resource.Meta_Language);
	append(result, "alternateName", resource.AlternateName || resource["ceterms:alternateName"] || resource["skos:altLabel"], resource.Meta_Language, true);
	append(result, "description", resource.Description || resource["ceterms:description"] || resource["ceasn:description"] || resource["ceasn:competencyText"] || resource["skos:definition"], resource.Meta_Language);
	append(result, "image", resource.Image || resource["ceterms:image"]);
	append(result, "sameAs", resource.RegistryData?.Resource?.URL || resource["@id"]);
	append(result, "identifier", resource.CTID || resource["ceterms:ctid"], false, false, ctid => { return { "@type": "PropertyValue", "propertyID": "https://purl.org/ctdl/terms/ctid", "value": ctid } });
	append(result, "inLanguage", resource.Meta_Language || resource["ceterms:inLanguage"] || resource["ceasn:inLanguage"], null, true);

	//Append Type-specific properties
	(renderHelper?.renderMethod || render_Generic)(resource, result);
	modifierMethod?.(resource, result);

	//Render the data
	console.log("Rendering data for " + broadType + " as schema:" + result["@type"], { source: resource, result: result });
	renderJSONLDMetaTag(result);
}
//

//Append data if it exists
function append(container, property, value, asLangStringLanguage, asArray, modMethod) {
	if (value) {
		var converted;

		//If the object is already a language map, just use it
		if (typeof (value) == "object" && !Array.isArray(value)) {
			converted = value;
		}
		//Otherwise, convert it
		else {
			//If the value needs to be an array, ensure that it is one
			converted = asArray ? (Array.isArray(value) ? value : [value]) : value;
			//If the value needs to (also) be a langString, turn it into an object with the appropriate language key
			if (asLangStringLanguage) {
				var temp = {};
				temp[asLangStringLanguage] = converted;
				converted = temp;
			}
		}

		container[property] = modMethod ? modMethod(converted) : converted;
	}
}
//

//Create or update the meta tag in the header
export function renderJSONLDMetaTag(data) {
	var tag = document.head.querySelector(".jsonldmeta");
	if (!tag) {
		tag = document.createElement("script");
		tag.type = "application/ld+json";
		tag = document.head.appendChild(tag);
	}
	tag.innerHTML = JSON.stringify(data);
}
//

//--- Methods to render data for specific types ---//
function render_Generic(resource, result) {
	
}
//

function renderAPI_Organization(resource, result) {
	result["@type"] = "Organization";
}
//

function renderAPI_Credential(resource, result) {
	result["@type"] = "EducationalOccupationalCredential";
}
//

function renderAPI_Assessment(resource, result) {
	result["@type"] = "Quiz";
}
//

function renderAPI_LearningOpportunity(resource, result) {
	result["@type"] = resource.CTDLType == "ceterms:LearningProgram" ? "EducationalOccupationalProgram" : "Course";
}
//

function renderAPI_Pathway(resource, result) {
	result["@type"] = "Collection"; //Pathway is almost a subclass of Collection
}
//

function renderLD_ConceptScheme(resource, result) {
	result["@type"] = "https://www.w3.org/2009/08/skos-reference/skos.html#ConceptScheme";
}
//

function renderLD_CompetencyFramework(resource, result) {
	result["@type"] = "Collection"; //Competency Framework is a subclass of Collection
}
//

function renderLD_Competency(resource, result) {
	result["@type"] = "DefinedTerm"; //No schema.org class for Competency
}
//

function renderLD_Collection(resource, result) {
	result["@type"] = "Collection";
}
//

