import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    HeaderMain,
    HeaderLeft,
    HeaderRight,
    Logo,
    MainMenu,
    CompareButton,
    CompareIcon,
    CompareCount,
	SearchLink,
	CompareButtonWrapper,
	Tagline
} from './styles';
import { mainSearchTypeOptions } from '../../shared/data';
import { configName } from '../../shared/globals';

import { FlexRow } from '../common';
import { NavLink } from 'react-router-dom';
import HamburguerMenu from '../HamburgerMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faBars, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { QueryContext } from '../../App';
import { useLocation, useHistory } from 'react-router-dom';
import { MainSearchOption } from '../../types/internal'; import styled from 'styled-components/macro';
// import logo from '../../assets/images/common/logo_credential-finder.png';
import { widgetGetColor, widgetGetPluralLabelForText } from '../../utils/SiteWidgetizer';
import { getCompareItems, updateStateOnCompareChange } from '../../utils/LocalStorage';

interface Props {
    onCompareButtonClick: () => void;
	showCompareButton: boolean;
	comparePanel?: JSX.Element;
}

export default function Header(props: Props) {
    const queryContext = useContext(QueryContext);
    const location = useLocation();
    const history = useHistory();
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
	const [compareItems, setCompareItems] = useState(getCompareItems());

    const showCompareButton =
        props.showCompareButton &&
        ( location.pathname !== '/');

    const hamburgerClick = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    function onCompareButtonClick() {
        props.onCompareButtonClick();
    }

    useEffect(() => {
        setIsMobileMenuOpen(false);
    }, [location]);

	useEffect(() => {
		updateStateOnCompareChange(() => { setCompareItems([ ...getCompareItems() ]); });
		setCompareItems(getCompareItems());
	}, []);

	function findType(type: string): MainSearchOption {
		return mainSearchTypeOptions.filter(m => m.key == type)[0] ?? mainSearchTypeOptions[0];
	}

	function widgetHideFilter(name: string): boolean {
		return (window as any).Widget?.Config?.HideGlobalFilters?.includes(name);
	}

	function skipToContent() {
		(document.querySelector("#ContentBegins") as any).focus();
	}

	var widgetConfig = (window as any)?.Widget.Config;
	var widgetLogoURL = widgetConfig?.LogoUrl;
	var widgetLogoFile = widgetConfig?.LogoImage?.Files?.[0]?.RawData;
	var widgetBannerText = widgetConfig?.BannerText;

	//console.log("Location", location.pathname + location.search);

    return (
        <HeaderMain className={isMobileMenuOpen ? 'main-menu-open' : ''}>
            <FlexRow>
                <HeaderLeft>
                    <Logo>
						<NavLink to={"/" + location.search}>
							{widgetLogoURL && <img src={widgetLogoURL} alt="" />}
							{widgetLogoFile && <img src={widgetLogoFile} alt="" />}
							{!widgetLogoURL && !widgetLogoFile && "Credential Finder"}
						</NavLink>
					</Logo>
					{/*widgetConfig && <Tagline>{widgetBannerText}</Tagline>*/}
					{!widgetConfig && <Tagline>The Credential Finder is a tool for exploring all of the information published to the Credential Registry</Tagline>}
					<NavigationSkipButton onClick={() => skipToContent()}>Skip to Content</NavigationSkipButton>
				</HeaderLeft>
                <HeaderRight>
					<MainMenu>
						{!widgetConfig && (
							<li>
								<NavLink exact to="/">
									Home
								</NavLink>
							</li>
						)}
                        <li>
							<NavLink to={"/search" + location.search}>Search</NavLink>
							<ul>
								{!widgetHideFilter("FindCredential") &&
									<SearchLink>
										<NavLink to="/credentials">
											<img src={findType("credential").icon} alt="" />
											{widgetGetPluralLabelForText("Credentials")}
										</NavLink>
									</SearchLink>
								}
								{!widgetHideFilter("FindOrganization") &&
									<SearchLink>
										<NavLink to="/organizations">
											<img src={findType("organization").icon} alt="" />
											{widgetGetPluralLabelForText("Organizations")}
										</NavLink>
									</SearchLink>
								}
								{!widgetHideFilter("FindAssessment") &&
									<SearchLink>
										<NavLink to="/assessments">
											<img src={findType("assessment").icon} alt="" />
											{widgetGetPluralLabelForText("Assessments")}
										</NavLink>
									</SearchLink>
								}
								{!widgetHideFilter("FindLearningOpportunity") &&
									<SearchLink>
										<NavLink to="/search/learningopportunity">
											<img src={findType("learningopportunity").icon} alt="" />
											{widgetGetPluralLabelForText("Learning Opportunities")}
										</NavLink>
									</SearchLink>
								}
								{!widgetHideFilter("FindCompetencyFramework") &&
									<SearchLink>
										<NavLink to="/search/competencyframework">
											<img src={findType("competencyframework").icon} alt="" />											
											{widgetGetPluralLabelForText("Competency Frameworks")}
										</NavLink>
									</SearchLink>
								}
								{!widgetHideFilter("FindCollection") &&
									<SearchLink>
										<NavLink to="/search/collection">
											<img src={findType("collection").icon} alt="" />
											{widgetGetPluralLabelForText("Collections")}
										</NavLink>
									</SearchLink>
								}
								{!widgetHideFilter("FindPathway") &&
									<SearchLink>
										<NavLink to="/search/pathway">
											<img src={findType("pathway").icon} alt="" />
											{widgetGetPluralLabelForText("Pathways")}
										</NavLink>
									</SearchLink>
								}
								{/*{!widgetHideFilter("FindPathwaySet") &&*/}
								{/*	<SearchLink>*/}
								{/*		<NavLink to="/search/pathwayset">*/}
								{/*			<img src={findType("pathwayset").icon} alt="" />*/}
								{/*			{widgetGetPluralLabelForText("Pathway Sets")}*/}
								{/*		</NavLink>*/}
								{/*	</SearchLink>*/}
								{/*}*/}
								{/*{!widgetHideFilter("FindScheduledOffering") &&*/}
								{/*	<SearchLink>*/}
								{/*		<NavLink to="/search/ScheduledOffering">*/}
								{/*			<img src={findType("scheduledoffering").icon} alt="" />*/}
								{/*			{widgetGetPluralLabelForText("Scheduled Offerings")}*/}
								{/*		</NavLink>*/}
								{/*	</SearchLink>*/}
								{/*}*/}
								{!widgetHideFilter("FindOutcomeData") &&
									<SearchLink>
										<NavLink to="/search/OutcomeData">
											<img src={findType("outcomedata").icon} alt="" />
											{widgetGetPluralLabelForText("Outcome Data")}
										</NavLink>
									</SearchLink>
								}
								{!widgetHideFilter("FindSupportService") &&
									<SearchLink>
										<NavLink to="/search/SupportService">
											<img src={findType("supportservice").icon} alt="" />
											{widgetGetPluralLabelForText("Support Services")}
										</NavLink>
									</SearchLink>
								}

								{!widgetHideFilter("FindOccupation")  && 
									<SearchLink>
										<NavLink to="/search/Occupation">
											<img src={findType("occupation").icon} alt="" />
											{widgetGetPluralLabelForText("Occupations")}
										</NavLink>
									</SearchLink>
								}
								
								{!widgetHideFilter("FindJob") && 
									<SearchLink>
										<NavLink to="/search/Job">
											<img src={findType("job").icon} alt="" />
											{widgetGetPluralLabelForText("Jobs")}
										</NavLink>
									</SearchLink>
								}
							
								{!widgetHideFilter("FindWorkRole") &&
									<SearchLink>
										<NavLink to="/search/WorkRole">
											<img src={findType("workrole").icon} alt="" />
											{widgetGetPluralLabelForText("Work Roles")}
										</NavLink>
									</SearchLink>
								}
								{!widgetHideFilter("FindTask") &&
									<SearchLink>
										<NavLink to="/search/Task">
											<img src={findType("task").icon} alt="" />
											{widgetGetPluralLabelForText("Tasks")}
										</NavLink>
									</SearchLink>
								}
								{!widgetHideFilter("FindTransferValue") &&
									<SearchLink>
										<NavLink to="/search/transfervalue">
											<img src={findType("transfervalue").icon} alt="" />
											{widgetGetPluralLabelForText("Transfer Values")}
										</NavLink>
									</SearchLink>
								}
								{!widgetHideFilter("FindTransferIntermediary") &&
									<SearchLink>
									<NavLink to="/search/transferintermediary">
											<img src={findType("transferintermediary").icon} alt="" />
											{widgetGetPluralLabelForText("Transfer Intermediaries")}
										</NavLink>
									</SearchLink>
								}
								{!widgetHideFilter("FindConceptScheme") &&
									<SearchLink>
										<NavLink to="/search/conceptscheme">
											<img src={findType("competencyframework").icon} alt="" />
											{widgetGetPluralLabelForText("Concept Schemes")}
										</NavLink>
									</SearchLink>
								}
								{!widgetHideFilter("FindProgressionModel") && 
									<SearchLink>
										<NavLink to="/search/ProgressionModel">
											<img src={findType("competencyframework").icon} alt="" />
											{widgetGetPluralLabelForText("Progression Models")}
										</NavLink>
									</SearchLink>
								}
								{!widgetHideFilter("FindCredentialingAction") &&
									<SearchLink>
										<NavLink to="/search/CredentialingAction">
											<img src={findType("credentialingaction").icon} alt="" />
											{widgetGetPluralLabelForText("Quality Assurance Actions")}
										</NavLink>
									</SearchLink>
								}
									{!widgetHideFilter("FindRubric")  &&
									<SearchLink>
										<NavLink to="/search/Rubric">
											<img src={findType("rubric").icon} alt="" />
											{widgetGetPluralLabelForText("Rubrics")}
										</NavLink>
									</SearchLink>
								}
								{!widgetHideFilter("FindMetric")  &&
									<SearchLink>
										<NavLink to="/search/Metric">
											<img src={findType("metric").icon} alt="" />
											{widgetGetPluralLabelForText("Metrics")}
										</NavLink>
									</SearchLink>
								}
								{!widgetHideFilter("FindIndustry")  &&
									<SearchLink>
										<NavLink to="/search/Industry">
											<img src={findType("industry").icon} alt="" />
											{widgetGetPluralLabelForText("Industries")}
										</NavLink>
									</SearchLink>
								}
                            </ul>
                        </li>
                        <li className="widgetHide">
                            <NavLink to="/widget">Finder Widgets</NavLink>
                        </li>
						<li className="widgetHide">
                            <NavLink to="/reports">Reports and Data</NavLink>
                        </li>
						<li className="widgetHide">
                            <NavLink to="/benchmarkreport">
                                Benchmark Reports
                            </NavLink>
                        </li>
					</MainMenu>
					<CompareButtonWrapper>
						{true && (
							<CompareButton
								isActive={location.pathname === '/compare'}
								disabled={compareItems.length == 0}
								onClick={onCompareButtonClick}>
								<CompareCount>
									Compare ({compareItems.length})
								</CompareCount>
								<CompareIcon>
									<FontAwesomeIcon icon={faAngleLeft} size="lg" />
								</CompareIcon>
							</CompareButton>
						)}
						{props.comparePanel}
					</CompareButtonWrapper>
					<MobileMenuButton onClick={hamburgerClick} className={isMobileMenuOpen ? "open" : ""}>
						{isMobileMenuOpen && <FontAwesomeIcon icon={faTimes} />}
						{!isMobileMenuOpen && ((window as any).Widget?.Config ? <FontAwesomeIcon icon={faSearch} /> : <FontAwesomeIcon icon={faBars} className="wide" />)}
					</MobileMenuButton>
					{
						/*
						<HamburguerMenu
							onClick={hamburgerClick}
							isMenuOpen={isMobileMenuOpen}
						/>
						*/
					}
                </HeaderRight>
			</FlexRow>
			<ContentBegins tabIndex={0} id="ContentBegins">Content after this point</ContentBegins>
        </HeaderMain>
    );
}

const MobileMenuButton = styled.button`
	color: ${(props) => widgetGetColor("SearchResultButton", false, "#000")};
	background-color: ${(props) => widgetGetColor("SearchResultButton", true, "transparent")};
	border: none;
	width: 40px;
	height: 40px;
	font-size: 26px;

	& .wide {
		transform: scaleX(1.25);
	}

	&:hover, &:focus {
		color: ${(props) => widgetGetColor("SearchResultButton", true, props.theme.color.aquaDark)};
		background-color: ${(props) => widgetGetColor("SearchResultButton", false, "transparent")};
	}

	@media(min-width: 1200px){
		display: none;
	}

	&.open {
        position: fixed;
        right: 1rem;
        top: 1rem;
        z-index: 100;
		color: ${(props) => widgetGetColor("SearchResultButton", true, props.theme.color.aquaDark)};
		background-color: ${(props) => widgetGetColor("SearchResultButton", false, "transparent")};

		&:hover, &:focus {
			color: ${(props) => widgetGetColor("SearchResultButton", false, "#FFF")};
			background-color: ${(props) => widgetGetColor("SearchResultButton", true, "transparent")};
		}
    }
`;

const NavigationSkipButton = styled.button`
	opacity: 0;
	margin: 0;
	padding: 0;
	height: 0;
	overflow: hidden;

	& :focus {
		height: 50px;
		line-height: 16px;
		opacity: 1;
		padding: 5px;
		background-color: #FFF;
		color: #000;
	}
`;

const ContentBegins = styled.div`
	opacity: 0;
	margin: 0;
	padding: 0;
	height: 0;
	overflow: hidden;
`;
