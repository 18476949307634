import React from 'react';
import { Link } from 'react-router-dom';
import { appURL } from '../../shared/globals';
import poweredByImage from '../../assets/images/common/powered_by_credential_registry.png'
import {
    FooterTop,
    FooterMiddle,
    FooterMain,
    Title,
	FooterMenu,
	PoweredBy
} from './styles';

function ItemLink(props: any) {
    return (
        <li>
            <Link {...props}>{props.children}</Link>
        </li>
    );
}

function ItemA(props: any) {
    return (
        <li>
            <a {...props}>{props.children}</a>
        </li>
    );
}

export default function Footer() {
    return (
        <FooterMain className="widgetHide">
            <FooterTop>
				<PoweredBy src={poweredByImage} alt="Powered by the Credential Registry" />
            </FooterTop>
            <FooterMiddle>
                <div>
                    <FooterMenu>
                        <ItemLink to="/">Home</ItemLink>
                        <ItemLink to="/search">Search</ItemLink>
                        <ItemLink to="/reports">Reports and Data</ItemLink>
                        <ItemLink to="/benchmarkreport">
                            Benchmark Reports
                        </ItemLink>
                    </FooterMenu>
                </div>
                <div>
                    <Title>Follow Us:</Title>
                    <FooterMenu>
                        <ItemA
                            href="https://twitter.com/credengine"
                            target="external"
                            rel="noreferrer">
                            Twitter
                        </ItemA>
                        <ItemA
                            href="https://www.facebook.com/credengine"
                            target="external"
                            rel="noreferrer">
                            Facebook
                        </ItemA>
                        <ItemA
                            href="https://www.youtube.com/channel/UCyTpUktFYQNlLrLR4O_AcQA"
                            target="external"
                            rel="noreferrer">
                            YouTube
                        </ItemA>
                        <ItemA
                            href="https://www.linkedin.com/company/credential-engine"
                            target="external"
                            rel="noreferrer">
                            LinkedIn
                        </ItemA>
                    </FooterMenu>
                </div>
                <div>
                    <Title>Contact Us:</Title>
                    <FooterMenu>
                        <ItemA href="mailto:info@credentialengine.org">
                            Email Us
                        </ItemA>
                    </FooterMenu>
                </div>

                <div>
                    <Title>Information:</Title>
                    <FooterMenu>
						<ItemA href="https://credentialengine.org/about/funders/" target="_blank">
							Supported By
						</ItemA>
                    </FooterMenu>
                </div>
                <div>
                    <Title>Policies and Terms of Use:</Title>
                    <FooterMenu>
                        <ItemA
                            href="http://credentialengine.org/privacy-policy/"
                            target="external"
                            rel="noreferrer">
                            Privacy Policy
                        </ItemA>
                        <ItemA
                            href="http://credentialengine.org/terms/"
                            target="external"
                            rel="noreferer">
                            Terms of Use
                        </ItemA>
                        <ItemA
                            href="http://credentialengine.org/publishers-agreement/"
                            target="external"
                            rel="noreferer">
                            Publishers Agreement
                        </ItemA>
                        <ItemA
                            href="http://credreg.net/registry/policy#mindata"
                            target="external"
                            rel="noreferer">
                            Minimum Data Policy
                        </ItemA>
                        <ItemA
                            href="http://credreg.net/registry/policy#currency"
                            target="external"
                            rel="noreferer">
                            Data Currency Policy
                        </ItemA>
                    </FooterMenu>
                </div>
                <div>
                    <Title>Websites:</Title>
                    <FooterMenu>
                        <ItemA
                            href="http://www.credentialengine.org"
                            target="external"
                            rel="noreferrer">
                            Credential Engine
                        </ItemA>
                        <ItemA
                            href="http://credreg.net"
                            target="external"
                            rel="noreferrer">
                            Credential Engine Technical Planning
                        </ItemA>
                    </FooterMenu>
                </div>
            </FooterMiddle>
        </FooterMain>
    );
}
