import React from 'react';
import { MainItemBroadType } from '../../types/internal';
import {
	Assessment,
	Credential,
	LearningOpportunity,
} from '../../types/external';
import { isFilled } from '../../validation';
import PageSection from '../PageSection';
import PageSectionItem from '../PageSectionItem';
import SearchPills, { ReferenceFrameworkPills } from '../SearchPills';
import { Label } from './styles';
import occupationIcon from '../../assets/images/icons/icon-occupations-and-industries-blue-green-01.svg';
import HasAnyFilled from '../HasAnyFilled';
import { detailSectionProperties } from '../../utils';

interface Props {
	item: MainItemBroadType;
	itemTypeLabel?: string;
	pageSectionDescription?: string;
}

export default function OccupationsAndIndustriesSection(props: Props) {

	var looseItem = props.item as any;

    return (
        <HasAnyFilled
            data={props.item}
            keys={detailSectionProperties.occupationsAndIndustries}>
            <PageSection
				icon={occupationIcon}
				title="Occupations and Industries"
				description={props.pageSectionDescription}>
				{isFilled(looseItem.OccupationType) && (
                    <PageSectionItem>
                        <Label>Occupations</Label>
						<ReferenceFrameworkPills links={looseItem.OccupationType} itemTypeLabel={props.itemTypeLabel} />
                    </PageSectionItem>
                )}
				{isFilled(looseItem.IndustryType) && (
                    <PageSectionItem>
                        <Label>Industries</Label>
						<ReferenceFrameworkPills links={looseItem.IndustryType} itemTypeLabel={props.itemTypeLabel} />
                    </PageSectionItem>
                )}
            </PageSection>
        </HasAnyFilled>
    );
}
