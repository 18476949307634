import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;

export const Suggestions = styled.div`
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 10;
`;

export const Suggestion = styled.button`
    font-size: ${(props) => props.theme.fontSize.sm};
    background-color: ${(props) => props.theme.color.white};
    display: block;
    text-align: left;
    width: 100%;
    padding: 10px;
    border: 1px solid ${(props) => props.theme.color.greyLight};
    &:hover {
        background-color: ${(props) => props.theme.color.greyLight};
    }
`;

export const SuggestionWrapper = styled.div``;
