import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(10, 41, 66, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledModal = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(p) => p.theme.color.white};
	min-height: 80%;
    max-height: 80%;
    width: 80%;
    @media (max-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        width: 95%;
    }
`;

export const Body = styled.div`
    overflow: auto;
	padding: 20px;
`;

export const Heading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 20px 9px 20px;
	border-bottom: 1px solid ${(p) => p.theme.color.aquaDark};
`;

export const HeadingText = styled.h3`
	margin: 0;
	padding: 0;
	font-size: 26px;
`;

export const SubHeading = styled.div`
	padding: 5px 20px 7px 20px;
	font-size: 25px;
	border-bottom: 1px solid ${(p) => p.theme.color.aquaDark};
`;

export const Close = styled.button`
    cursor: pointer;
    border: none;
    background-color: transparent;
`;
