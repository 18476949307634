import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import costIcon from '../../assets/images/icons/icon-costs-and-time-blue-green-01.svg';
import pageIcon from '../../assets/images/icons/icon-transfer-value-green-blue-01.svg';
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';
import Banner from '../../components/Banner';
import { WhiteLink } from '../../components/Banner/styles';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import LinkObject from '../../components/DetailPage/LinkObject';
import OutlineList from '../../components/DetailPage/Outline';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import {
    Description,
    FullWidthWrapper,
    InnerWrapper,
    Label, Section,
    Wrapper
} from '../../components/DetailPage/styles';
import HasAnyFilled from '../../components/HasAnyFilled';
import LoadingOrError from '../../components/LoadingOrError';
import { ModalButtonAndWindow } from '../../components/Modal';
import PageSection from '../../components/PageSection';
import PageSectionItem from '../../components/PageSectionItem';
import { VStack } from '../../components/Stack';
import { useGet, useMediaQuery } from '../../hooks';
import { apiUrl, legacySourceUrl } from '../../shared/globals';
import { theme } from '../../shared/theme';
import { Occupation } from '../../types/external';
import { detailSectionProperties } from '../../utils';
import SearchPills from '../../components/SearchPills';
import { isFilled } from '../../validation';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";
import SubjectsAndKeywordsPageSection from '../../components/DetailPage/SubjectsAndKeywordsPageSection';
import OccupationsAndIndustriesSection from '../../components/DetailPage/OccupationsAndIndustriesSection';
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import ConditionProfileList, { getConditionProfileListButtonName } from "../../components/DetailPage/ConditionProfile";
import ConnectionsSection from "../../components/DetailPage/ConnectionsSection";
import { ModalType } from '../../types/internal';
import LocationPageSection from '../../components/DetailPage/LocationPageSection';
import DisplayPills from '../../components/PillDisplay';
import { CompetencyFrameworkList } from '../../components/DetailPage/CompetencyFramework';



interface Props {
    id: string;
}

export default function OccupationPage(props: RouteComponentProps<Props>) {
    const id = props.match.params.id;
    const [
        pageDataIsLoading,
        pageDataHasError,
        getPageData,
    ] = useGet();
    const [pageModel, setPageData] = useState({} as Occupation);
    const [openedModal, setOpenedModal] = useState<ModalType>();

    function onOpenModalClick(modalType: ModalType) {
        return () => setOpenedModal(modalType);
    }

    function onCloseModalClick() {
        setOpenedModal(undefined);
    }
    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });

    useEffect(() => {
        getPageData(`${apiUrl}/Occupation/${id}`, (data: Occupation) => {
            setPageData(data);
        });
    }, [getPageData, id]);
    console.log(pageModel);
    //

    function getSubTitleLink() {
        if (pageModel.OwnedByLabel && pageModel.OwnedByLabel.Label) {
            return pageModel.OwnedByLabel.URL ?
                <WhiteLink href={pageModel.OwnedByLabel.URL}>{pageModel.OwnedByLabel.Label}</WhiteLink> :
                <>{pageModel.OwnedByLabel.Label}</>;
        }
        return null;
    }

    function getLegacyLink() {
        if (pageModel.Meta_Id) {
            const url = legacySourceUrl + "/" + pageModel.BroadType + "/" + pageModel.Meta_Id;
            const mystyle = {
                //color: "rgba(0,0,0,.01)",
                color: "transparent",
                //backgroundColor: "rgba(0,0,0,.01)",
                marginLeft: "10px"
            };
            return <>
                <WhiteLink href={url} target="legacy" style={mystyle} title="View in services application">Legacy</WhiteLink>
            </>

        }
        return null;
    }
	
	var typeLabel = widgetGetSingleLabelForText("Occupation");

    return (
        <LoadingOrError
            isLoading={pageDataIsLoading}
            hasError={pageDataHasError}>

            <Banner
                icon={pageIcon}
                fallBackIcon={pageIcon}
                type={pageModel.CTDLTypeLabel}
                title={pageModel.Name}
                subTitle={getSubTitleLink()}
                content={
                    <small>Last updated: {pageModel.Meta_LastUpdatedHeader} <span  >{getLegacyLink()}</span></small>
                }
            />
            <Wrapper>
                <VStack spacing={isMobile ? '0px' : '20px'}>
                    <HasAnyFilled
                        data={pageModel}
                        keys={detailSectionProperties.occupationAbout}>
                        <PageSection
							icon={aboutIcon}
							title={"About this " + typeLabel}
							variant="Highlight"
							description={"Basic information about this " + typeLabel}>

                            {pageModel.Description && (
                                <PageSectionItem>
                                    <Description>
                                        {pageModel.Description}
                                    </Description>
                                </PageSectionItem>
                            )}


                            {pageModel.SubjectWebpage &&
                                <PageSectionItem>
                                    <LinkObject item={{ URL: pageModel.SubjectWebpage, Label: "View this " + typeLabel }} />
                                </PageSectionItem>
                            }
                            {pageModel.LifeCycleStatusType && (
                                <PageSectionItem>
                                    <Label>Life Cycle Status</Label>
                                    <DisplayPills links={[pageModel.LifeCycleStatusType]} />
                                </PageSectionItem>
                            )}
                            {pageModel.Classification &&
                                <PageSectionItem>
                                    <Label>Classification</Label>
                                        {pageModel.Classification.Values.map((item, index) => (
                                            <div key={index}>
                                                {item.URL ? (
                                                    <a href={item.URL}>
                                                        {item.Label}
                                                    </a>
                                                ) : (
                                                    <span>{item.Label}</span>
                                                )}
                                                <Description>
                                                    {item.Description}
                                                </Description>
                                            </div>
                                        ))}
                                </PageSectionItem>
                            }
                            {pageModel.InCatalog &&
                                <LinkObject item={{
                                    URL: pageModel.InCatalog, Label: "View Resource "
                                }} />
                            }


                        </PageSection>
                    </HasAnyFilled>
                    <HasAnyFilled
                        data={pageModel}
                        keys={[
                            ...detailSectionProperties.workClassRequirements,
                        ]}>
                        <PageSection
                            icon={costIcon}
                            title="Requirements, Costs, and Time"
                            description={"How to earn the " + typeLabel}>
                            <HasAnyFilled
                                data={pageModel}
                                keys={[
                                    "Requires"
                                ]}>
                                <PageSectionItem>
                                    <Label>Requirements and Recommendations</Label>
                                    {/*<ModalButtonAndWindow
										buttonLabel="Common Conditions"
										resourceTitle={pageModel.Name}
										items={pageModel.CommonConditions}
										Wrapper={Section}
										Content={() => <ConditionManifestList items={pageModel.CommonConditions} />}
									/>*/}
                                    <ModalButtonAndWindow
                                        //buttonLabel="Requires"
                                        buttonLabel={getConditionProfileListButtonName("Requires", pageModel.Requires)}
                                        hideCount={true}
                                        resourceTitle={pageModel.Name}
                                        items={pageModel.Requires}
                                        Wrapper={Section}
                                        Content={() => <ConditionProfileList items={pageModel.Requires} />}
                                    />
                                    {/*<ModalButtonAndWindow
										//buttonLabel="Recommends"
										buttonLabel={getConditionProfileListButtonName("Recommends", pageModel.Recommends)}
										hideCount={true}
										resourceTitle={pageModel.Name}
										items={pageModel.Recommends}
										Wrapper={Section}
										Content={() => <ConditionProfileList items={pageModel.Recommends} />}
									/>*/}
                                </PageSectionItem>
                            </HasAnyFilled>
                        </PageSection>
                    </HasAnyFilled>
                    <VStack>
                        <SubjectsAndKeywordsPageSection item={pageModel} pageSectionDescription="Subjects and Keywords related to the Occupation" />
                        <OccupationsAndIndustriesSection item={pageModel} pageSectionDescription="Career Field information about the Occupation" />
                    </VStack>
					{/*<AdditionalInformationPageSection item={pageModel} pageSectionDescription={"Identifiers and other information for the " + typeLabel} />*/}
                </VStack>
                <VStack spacing={isMobile ? '0px' : '20px'}>
                    <RelatedOrganizationPageSection item={pageModel} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " that assert the " + typeLabel} />
                    <HasAnyFilled
                        data={pageModel}
                        keys={[
                            ...detailSectionProperties.entityConnections
                        ]}>
                        <PageSection icon={connectionsIcon} title="Connections" description="Related Resources">
                            <PageSectionItem>
                                <ModalButtonAndWindow
                                    buttonLabel={"Has " + (pageModel.HasJob?.Total || 0) + " Job" + ((pageModel.HasJob?.Values?.length || 0) == 1 ? "" : "s")}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.HasJob?.Values}
                                    Wrapper={Section}
                                    Content={() => <OutlineList items={pageModel.HasJob?.Values} />}
                                    hideCount={true}
                                />
                                <ModalButtonAndWindow
                                    buttonLabel={"Has " + (pageModel.HasTask?.Total || 0) + " Task" + ((pageModel.HasTask?.Values?.length || 0) == 1 ? "" : "s")}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.HasTask?.Values}
                                    Wrapper={Section}
                                    Content={() => <OutlineList items={pageModel.HasTask?.Values} />}
                                    hideCount={true}
                                />
                                <ModalButtonAndWindow
                                    buttonLabel={"Has " + (pageModel.HasWorkRole?.Total || 0) + " WorkRole" + ((pageModel.HasWorkRole?.Values?.length || 0) == 1 ? "" : "s")}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.HasWorkRole?.Values}
                                    Wrapper={Section}
                                    Content={() => <OutlineList items={pageModel.HasWorkRole?.Values} />}
                                    hideCount={true}
                                />
                                {/*<ModalButtonAndWindow*/}
                                {/*    buttonLabel={"Has " + (pageModel.HasTask?.Total || 0) + " Task " + ((pageModel.HasTask?.Values?.length || 0) == 1 ? "Role" : "Roles")}*/}
                                {/*    resourceTitle={pageModel.Name}*/}
                                {/*    items={pageModel.HasTask?.Values}*/}
                                {/*    Wrapper={Section}*/}
                                {/*    Content={() => <OutlineList items={pageModel.HasTask?.Values} />}*/}
                                {/*    hideCount={true}*/}
                                {/*/>*/}
                                <ModalButtonAndWindow
                                    buttonLabel={"Has " + (pageModel.HasSpecialization?.Total || 0) + " Specialization" + ((pageModel.HasSpecialization?.Values?.length || 0) == 1 ? "" : "s")}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.HasSpecialization?.Values}
                                    Wrapper={Section}
                                    Content={() => <OutlineList items={pageModel.HasSpecialization?.Values} />}
                                    hideCount={true}
                                />
                                <ModalButtonAndWindow
                                    buttonLabel={"Knowledge Embodied " + pageModel.KnowledgeEmbodied?.Total + " Competencies"}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.KnowledgeEmbodied?.Values}
                                    Wrapper={Section}
                                    Content={() => <CompetencyFrameworkList frameworkList={pageModel.KnowledgeEmbodied} />}
                                    hideCount={true}
                                />
                                <ModalButtonAndWindow
                                    buttonLabel={"Ability Embodied " + pageModel.AbilityEmbodied?.Total + " Competencies"}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.AbilityEmbodied?.Values}
                                    Wrapper={Section}
                                    Content={() => <CompetencyFrameworkList frameworkList={pageModel.AbilityEmbodied} />}
                                    hideCount={true}
                                />
                                <ModalButtonAndWindow
                                    buttonLabel={"Skill Embodied " + pageModel.SkillEmbodied?.Total + " Competencies"}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.SkillEmbodied?.Values}
                                    Wrapper={Section}
                                    Content={() => <CompetencyFrameworkList frameworkList={pageModel.SkillEmbodied} />}
                                    hideCount={true}
                                />
                                <ModalButtonAndWindow
                                    buttonLabel={(pageModel.PhysicalCapabilityType?.Total || 0) + " Physical Capability Type" + ((pageModel.PhysicalCapabilityType?.Values?.length || 0) == 1 ? "" : "s")}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.PhysicalCapabilityType?.Values}
                                    Wrapper={Section}
                                    Content={() => <OutlineList items={pageModel.PhysicalCapabilityType?.Values} />}
                                    hideCount={true}
                                />
                                <ModalButtonAndWindow
                                    buttonLabel={(pageModel.PerformanceLevelType?.Total || 0) + " Performance Level Type" + ((pageModel.PerformanceLevelType?.Values?.length || 0) == 1 ? "" : "s")}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.PerformanceLevelType?.Values}
                                    Wrapper={Section}
                                    Content={() => <OutlineList items={pageModel.PerformanceLevelType?.Values} />}
                                    hideCount={true}
                                />
                                <ModalButtonAndWindow
                                    buttonLabel={(pageModel.EnvironmentalHazardType?.Total || 0) + " Environmental Hazard Type" + ((pageModel.EnvironmentalHazardType?.Values?.length || 0) == 1 ? "" : "s")}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.EnvironmentalHazardType?.Values}
                                    Wrapper={Section}
                                    Content={() => <OutlineList items={pageModel.EnvironmentalHazardType?.Values} />}
                                    hideCount={true}
                                />
                                <ModalButtonAndWindow
                                    buttonLabel={(pageModel.SensoryCapabilityType?.Total || 0) + " Sensory Capability Type" + ((pageModel.SensoryCapabilityType?.Values?.length || 0) == 1 ? "" : "s")}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.SensoryCapabilityType?.Values}
                                    Wrapper={Section}
                                    Content={() => <OutlineList items={pageModel.SensoryCapabilityType?.Values} />}
                                    hideCount={true}
                                />
                                {/*<ModalButtonAndWindow*/}
                                {/*    buttonLabel={(pageModel.Classification?.Total || 0) + " Classification" + ((pageModel.Classification?.Values?.length || 0) == 1 ? "" : "s")}*/}
                                {/*    resourceTitle={pageModel.Name}*/}
                                {/*    items={pageModel.Classification?.Values}*/}
                                {/*    Wrapper={Section}*/}
                                {/*    Content={() => <OutlineList items={pageModel.Classification?.Values} />}*/}
                                {/*    hideCount={true}*/}
                                {/*/>*/}
                                <ModalButtonAndWindow
                                    buttonLabel={"Requires " + pageModel.TargetCompetency?.Total + " " + widgetGetPluralLabelForText("Competencies")}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.TargetCompetency?.Values}
                                    Wrapper={Section}
                                    Content={() => <OutlineList items={pageModel.TargetCompetency?.Values} />}
                                    hideCount={true}
                                />
                        {/*        <ModalButtonAndWindow*/}
                        {/*                buttonLabel={"Provides TransferValue For"}*/}
                        {/*                resourceTitle={pageModel.Name}*/}
                        {/*                items={pageModel.ProvidesTransferValueFor?.Values}*/}
                        {/*                Wrapper={Section}*/}
                        {/*                Content={() => <OutlineList items={pageModel.ProvidesTransferValueFor?.Values} />}*/}
                        {/*                hideCount={true}*/}
				                    {/*/>*/}
                        {/*            <ModalButtonAndWindow*/}
                        {/*                buttonLabel={"Receives TransferValue From"}*/}
                        {/*                resourceTitle={pageModel.Name}*/}
                        {/*                items={pageModel?.ReceivesTransferValueFrom?.Values}*/}
                        {/*                Wrapper={Section}*/}
                        {/*                Content={() => <OutlineList items={pageModel?.ReceivesTransferValueFrom?.Values} />}*/}
                        {/*                hideCount={true}*/}
                        {/*            />*/}
                                <ConnectionsSection
                                    item={pageModel}
                                    openedModal={openedModal}
                                    onOpenModalClick={onOpenModalClick}
                                    onCloseModalClick={onCloseModalClick}
                                />
                            </PageSectionItem>
                        </PageSection>
                    </HasAnyFilled>
                </VStack>
            </Wrapper>
            <FullWidthWrapper>
                <TabSetOrSingle
                    activeIndex={0}
                    items={[
                        {
                            Label: "Details",
                            Content: (
                                <InnerWrapper>
                                    <VStack>
                                        <AdditionalInformationPageSection item={pageModel} pageSectionDescription="Additional information about the Occupation" />
                                    </VStack>
                                    {isFilled(pageModel.SameAs) && (
                                        <PageSectionItem>
                                            <Label>Same As</Label>
                                            {pageModel.SameAs.map((url) => (
                                                <div key={`cr-same-as-${url}`}>
                                                    <a href={url}>{url}</a>
                                                </div>
                                            ))}
                                        </PageSectionItem>
                                    )}
                                </InnerWrapper>
                            )
                        }
                    ].filter(m => m.Content != null)}
                />
            </FullWidthWrapper>
        </LoadingOrError>
    );
}
//
