import styled, { css } from 'styled-components/macro';

export type PillType = 'filter' | 'filter-button';

interface PillProps {
    selected?: boolean;
    pillType?: PillType;
}

export const StyledPill = styled.button<PillProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    background-color: ${(props) => props.theme.color.greyLight};
    border: 1px solid ${(props) => props.theme.color.greyLight};
    border-radius: 20px;
    font-size: ${(props) => props.theme.fontSize.sm};
    line-height: 1.714;
    padding: 0.2em 2rem 0.2em 1em;
    position: relative;

    &:hover {
        background-color: ${(props) => props.theme.color.white};
        border-color: ${(props) => props.theme.color.greyDark};
    }

    ${(outProps) => {
        switch (outProps.pillType) {
            case 'filter-button':
                return css`
                    background-color: ${(props) =>
                        outProps.selected
                            ? props.theme.color.greyDark
                            : 'transparent'};
                    border-color: ${(props) => props.theme.color.greyDark};
                    color: ${(props) =>
                        outProps.selected
                            ? props.theme.color.white
                            : undefined};

                    &:hover {
                        background-color: ${(props) =>
                            props.theme.color.greyDark};
                        border-color: ${(props) => props.theme.color.greyDark};
                        color: ${(props) => props.theme.color.white};
                    }
                `;
        }
    }}

    .icon-right {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

export const StyledUnremovablePill = styled.button<PillProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
	cursor: default;
    background-color: ${(props) => props.theme.color.greyLight};
    border: 1px solid ${(props) => props.theme.color.greyLight};
    border-radius: 20px;
    font-size: ${(props) => props.theme.fontSize.sm};
    line-height: 1.714;
    padding: 0.2em 1em 0.2em 1em;
    position: relative;
`;

export const PillContent = styled.span`
    margin: 0 10px;
`;
