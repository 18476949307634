import React, { useContext, useState, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components/macro';
import { MainItemBroadType } from '../../types/internal';
import { Button } from '../common';
import Banner from '../Banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { isOrganization, isTransferValue} from '../../utils';
import { WhiteLink } from '../Banner/styles';
import { legacySourceUrl } from '../../shared/globals';
import { addCompareItem, getCompareItemByCTID, removeCompareItem, getCompareItems, updateStateOnCompareChange } from '../../utils/LocalStorage';
import ActivityIndicator from '../ActivityIndicator';
import { widgetGetColor, widgetGetSingleLabelForCTDLType } from '../../utils/SiteWidgetizer';


interface Props {
    item: MainItemBroadType;
    bannerIcon: string;
	fallbackIcon?: string;
}

export default function PageBanner(props: Props) {
	const [compareItems, setCompareItems] = useState(getCompareItems());

	useEffect(() => {
		updateStateOnCompareChange(() => { setCompareItems([...getCompareItems()]); });
		setCompareItems(getCompareItems());
	}, []);

    function getSubTitle() {
        return isOrganization(props.item)
            ? undefined
            : props.item.OwnedByLabel && props.item.OwnedByLabel.Label;
    }

	function getSubTitleLink() {
		if (!isOrganization(props.item) && props.item.OwnedByLabel && props.item.OwnedByLabel.Label) {
			return props.item.OwnedByLabel.URL ?
				<WhiteLink href={props.item.OwnedByLabel.URL}>{props.item.OwnedByLabel.Label}</WhiteLink> :
				<>{props.item.OwnedByLabel.Label}</>;
		}
		return null;
	}

    
	function getLegacyLink() {
		if ( props.item.Meta_Id ) {
            const url = legacySourceUrl + "/" + props.item.BroadType + "/" + props.item.Meta_Id;
            const mystyle = {
				color: "transparent",
				//backgroundColor: "#0a2942",
                marginLeft: "10px"
              };
			return <>
				<WhiteLink href={url} target="legacy" style={mystyle} title="View legacy">Legacy Version</WhiteLink>
                </>
				
		}
		return null;
	}

	var hasBadge: boolean = (props.item as any).Meta_HasVerificationBadge;

	function handleCompare() {
		getCompareItems().find((item: any) => item.CTID == props.item.CTID) == null ? addCompareItem(props.item) : removeCompareItem(props.item);
	}

	console.log("Item", props);

    return (
        <Banner
			icon={props.bannerIcon}
			hasBadge={hasBadge}
            fallBackIcon={props.fallbackIcon}
            type={widgetGetSingleLabelForCTDLType(props.item.CTDLType, props.item.CTDLTypeLabel) + (hasBadge ? " + Badge Issued" : "")}
            title={props.item.Name}
            subTitle={getSubTitleLink()}
            content={
                <small>Last updated: {props.item.Meta_LastUpdatedHeader} <span>{getLegacyLink()}</span></small>
            }
			otherContent={
				<>
					{(((props.item as any)?.CTDLType != "ceasn:Competency") && ((props.item as any)?.CTDLType != "CredentialingAction")) && (
						<Button buttonType="secondary" onClick={() => handleCompare()}>
							{compareItems.find((item: any) => item.CTID == props.item.CTID) != null ? (
								<FontAwesomeIcon icon={faCheck} />
							) : (
								'Compare'
							)}
						</Button>
					)}
					<CustomButtonBox resource={props.item} />
				</>
            }
        />
    );
}

function CustomButtonBox(props: { resource: any }) {
	//Initialize the container's identifier once per page load
	const [customButtonBoxID, setCustomButtonBoxID] = useState("");
	if (!customButtonBoxID) {
		setCustomButtonBoxID((crypto as any).randomUUID() as string);
	}

	//Call the function to setup the container
	var isInIframe = window != window.parent;
	useEffect(() => {
		if (!props.resource?.Name) {
			return;
		}
		setupCustomButtonBox();
	}, [props.resource]);

	//Setup the container
	function setupCustomButtonBox() {
		//Cancel if we aren't in an iframe
		if (!isInIframe) {
			return;
		}

		//Find the container or try again
		var container = document.getElementById(customButtonBoxID) as any;
		if (!container) {
			setTimeout(setupCustomButtonBox, 100);
		}
		else {
			//Create an event listener for when we are told to render the custom button
			window.addEventListener("message", event => {
				var eventData = typeof (event.data) == "string" ? JSON.parse(event.data) : event.data;
				if (event.source == window.parent && eventData?.Type == "RenderCustomButton") {
					//Wipe out the container's contents
					container.innerHTML = "";

					//If there is any content...
					if (eventData.Data.Content) {
						//Create the button
						var button = document.createElement("button");
						button.style.cssText = eventData.Data.Style;
						button.innerHTML = eventData.Data.Content;

						//Add a listener for when the button is clicked
						button.addEventListener("click", click => {
							window.parent.postMessage(JSON.stringify({
								Type: "CustomButtonClicked",
								Data: props.resource
							}), "*");
							button.disabled = true;
							button.innerHTML = "Loading... " + renderToString(<ActivityIndicator size="sm" />);
						});

						//Attach the button
						container.appendChild(button);
					}
				}
			});

			//Tell the parent page that we've loaded the data
			window.parent.postMessage(JSON.stringify({
				Type: "ResourceLoaded",
				Data: props.resource
			}), "*");
		}
	}

	//Return the container or null
	return isInIframe ? <CustomButtonContainer id={customButtonBoxID}></CustomButtonContainer> : null;
}

const CustomButtonContainer = styled.div`
	padding: 5px 0;
	
	& button {
		display: block;
		width: 100%;
		border: none;
		border-radius: 3px;
		padding: 10px;
		font-size: 1rem;
		line-height: 1.125;
		background: ${(props) => widgetGetColor("SearchResultLink", false, props.theme.color.aquaDark)};
        border-color: ${(props) => widgetGetColor("SearchResultLink", true, props.theme.color.aquaDark)};
        color: ${(props) => widgetGetColor("SearchResultLink", true, props.theme.color.blueDark)};

		&:hover {
			filter: brightness(1.5);
		}
	}
`;
