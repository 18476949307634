import { getUUID } from "../components/MiniSearch";
import { apiUrl } from "../shared/globals";
import { updateCurrentQuery } from './SearchHelper';

//Global widget object
window.Widget = window.Widget || {
	Parameter: "widgetid",
	ID: 0,
	URLName: "",
	InWidgetMode: false,
	StyleRules: [],
	StyleTag: null,
	Config: null,
	DefaultSearchType: null
};

//Apply widgetization sitewide
export default function handleSiteWidgetization() {
	new URLSearchParams(window.location.search).forEach(function (value, key) {
		if (key.toLowerCase() == window.Widget.Parameter) {
			console.log("Widget detected:", value);
			window.Widget.ID = isNaN(value) ? 0 : parseInt(value);
			window.Widget.URLName = isNaN(value) ? value : "";
			console.log("Widget ID:", window.Widget.ID);
			console.log("Widget URLName:", window.Widget.URLName);
		}
		if (key.toLowerCase() == "searchtype") {
			window.Widget.DefaultSearchType = value;
		}
	});

	//Skip the rest if there is no widget to apply
	if (!window.Widget.ID && !window.Widget.URLName) {
		return;
	}

	//Force async so other stuff renders first, then apply the widgetization
	setTimeout(applyWidgetization, 0); 
}
//

function applyWidgetization() {
	//Skip if already applied
	if (window.Widget.ID == window.Widget.Config?.Id && window.Widget.URLName == window.Widget.Config?.URLName) {
		return;
	}

	//Get the data for the widget
	getData(apiUrl + "/widget/getconfig/" + (window.Widget.ID || window.Widget.URLName), (response) => {
	//getData("https://sandbox.credentialengine.org/finderapi/widget/getconfig/" + window.Widget.ID, (response) => {
		//Attach data
		console.log("Got widget data", response);
		window.Widget.Config = response.Result;
		window.Widget.ID = window.Widget.Config.Id;
		window.Widget.URLName = window.Widget.Config.UrlName;

		//Apply one-time changes
		enterWidgetMode();

		//Apply per-widget changes
		applyWidgetConfig();
	});
}
//

//Apply changes that only need to be done once
function enterWidgetMode() {
	//Skip if already done
	if (window.Widget.InWidgetMode) {
		return;
	}
	window.Widget.InWidgetMode = true;

	//Apply changes
	//Hide things that should be hidden in a widget
	select(".widgetHide").forEach(element => {
		hide(element);
	});

	//Show things that should only show in a widget
	select(".widgetShow").forEach(element => {
		show(element);
	});

	//Widgetize links on click
	window.addEventListener("mousedown", function (e) {
		widgetizeLink(e.srcElement);
	});

	//Widgetize links on keyup
	window.addEventListener("keyup", function (e) {
		widgetizeLink(e.srcElement);
	});

	//Create a style tag to inject styles into
	window.Widget.StyleTag = document.createElement("style");
	document.body.appendChild(window.Widget.StyleTag);

	//Handle resizing
	window.addEventListener("resize", function () {
		handleResize();
	});

	//Force resizing despite react
	setInterval(handleResize, 250);

	//Update query
	updateCurrentQuery();
}
//

//Widgetize a link
function widgetizeLink(element) {
	if (element.tagName?.toLowerCase() == "a") {
		//element.target = ""; //Commented out so that links will continue to open in a new window
		if (!element.href.includes(window.Widget.Parameter)) {
			var url = new URL(element.href);
			url.searchParams.append(window.Widget.Parameter, window.Widget.ID);
			window.Widget.DefaultSearchType && url.searchParams.append("searchType", window.Widget.DefaultSearchType);
			element.href = url.toString();
		}
	}
	//Handle links that have other elements inside of them
	else if (element.parentNode && element.parentNode != element) {
		widgetizeLink(element.parentNode);
	}
}
//

//Apply changes unique to a given widget
function applyWidgetConfig() {
	console.log("Applying widget config", window.Widget.Config);
}
//

//Handle resizing
function handleResize() {
	var height = document.body.clientHeight + 20;
	var message = { action: "resize", width: "100% !important", height: height + "px !important" };
	window.parent?.postMessage(JSON.stringify(message), "*");
}
//

//Helpful mappings
var WidgetToFinderMap = {
	"credential": {
		GlobalHideName: "FindCredential",
		TypeName: "CredentialFilters",
		FilterMap: {
			"filter:AssessmentDeliveryType": "AssessmentDeliveryTypes",
			"filter:AudienceLevelType": "AudienceLevelTypes",
			"filter:AudienceType": "AudienceTypes",
			"filter:Competencies": "Competencies",
			"filter:Connections": "CredentialConnections",
			"filter:CredentialStatus": "CredentialStatusTypes",
			"filter:CredentialType": "CredentialTypes",
			"filter:InLanguage": "Languages",
			"filter:IndustryType": "Industries",
			"filter:InstructionalProgramType": "InstructionalProgramTypes",
			"filter:LearningDeliveryType": "LearningDeliveryTypes",
			"filter:OccupationType": "Occupations",
			"filter:OtherFilters": "OtherFilters",
			"filter:QAReceived": "QualityAssurance",
			"filter:CollectionType": "Collections",
			"filter:Subjects": "Subjects"
		}
	},
	"organization": {
		GlobalHideName: "FindOrganization",
		TypeName: "OrganizationFilters",
		FilterMap: {
			"filter:OrganizationTypes": "OrganizationTypes",
			"filter:IndustryType": "Industries",
			"filter:ServiceTypes": "ServiceTypes",
			"filter:SectorTypes": "SectorTypes",
			"filter:ClaimTypes": "ClaimTypes",
			"filter:QAReceived": "QualityAssurance",
			"filter:QAPerformed": "QualityAssurancePerformed",
			"filter:OtherFilters": "OtherFilters"
		}
	},
	"assessment": {
		GlobalHideName: "FindAssessment",
		TypeName: "AssessmentFilters",
		FilterMap: {
			"filter:Competencies": "Competencies",
			"filter:Subjects": "Subjects",
			"filter:Connections": "AssessmentConnections",
			"filter:AssessmentDeliveryType": "DeliveryMethodTypes",
			"filter:AudienceLevelType": "AudienceLevelTypes",
			"filter:AudienceType": "AudienceTypes",
			"filter:CollectionType": "Collections",
			"filter:IndustryType": "Industries", 
			"filter:OccupationType": "Occupations",
			"filter:InstructionalProgramType": "InstructionalProgramTypes",
			"filter:QAReceived": "QualityAssurance",
			"filter:InLanguage": "Languages",
			"filter:OtherFilters": "OtherFilters"
		}
	},
	"learningopportunity": {
		GlobalHideName: "FindLearningOpportunity",
		TypeName: "LearningOpportunityFilters",
		FilterMap: {
			"filter:LearningTypes": "LearningTypes",
			"filter:LifeCycleStatusTypes": "LifeCycleTypes",
			"filter:Competencies": "Competencies",
			"filter:Subjects": "Subjects",
			"filter:Connections": "LearningOpportunityConnections",
			"filter:AudienceLevelType": "AudienceLevelTypes",
			"filter:AudienceType": "AudienceTypes",
			"filter:LearningDeliveryType": "DeliveryMethodTypes",
			"filter:LearningMethodType": "LearningMethodTypes",
			"filter:CollectionType": "Collections",
			"filter:IndustryType": "Industries",
			"filter:OccupationType": "Occupations",
			"filter:InstructionalProgramType": "InstructionalProgramTypes",
			"filter:QAReceived": "QualityAssurance",
			"filter:InLanguage": "Languages",
			"filter:OtherFilters": "OtherFilters"
		}
	},
	"pathway": {
		GlobalHideName: "FindPathway",
		TypeName: "PathwayFilters",
		FilterMap: {
			"filter:Subjects": "unknown", //Not included in the widget config page
			"filter:IndustryType": "unknown", //Not included in the widget config page
			"filter:OccupationType": "unknown" //Not included in the widget config page
		}
	},
	"pathwayset": {
		GlobalHideName: "FindPathwaySet",
		TypeName: "PathwaySetFilters",
		FilterMap: {}
	},
	"competencyframework": {
		GlobalHideName: "FindCompetencyFramework",
		TypeName: "CompetencyFrameworkFilters",
		FilterMap: {}
	},
	"collection": {
		GlobalHideName: "FindCollection",
		TypeName: "CollectionFilters",
		FilterMap: {
			"filter:CollectionType": "CollectionTypes"
		}
	},
	"conceptscheme": {
		GlobalHideName: "FindConceptScheme",
		TypeName: "ConceptSchemeFilters",
		FilterMap: {}
	},
	"progressionmodel": {
		GlobalHideName: "FindProgressionModel",
		TypeName: "ProgressionModelFilters",
		FilterMap: {}
	},
	"scheduledoffering": {
		GlobalHideName: "FindScheduledOffering",
		TypeName: "ScheduledOfferingFilters",
		FilterMap: {}
	},
	"supportservice": {
		GlobalHideName: "FindSupportService",
		TypeName: "SupportServiceFilters",
		FilterMap: {}
	},
	"outcomedata": {
		GlobalHideName: "FindOutcomeData",
		TypeName: "OutcomeDataFilters",
		FilterMap: {}
	},
	"occupation": {
		GlobalHideName: "FindOccupation",
		TypeName: "OccupationFilters",
		FilterMap: {}
	},
	"job": {
		GlobalHideName: "FindJob",
		TypeName: "JobFilters",
		FilterMap: {}
	},
	"task": {
		GlobalHideName: "FindTask",
		TypeName: "TaskFilters",
		FilterMap: {}
	},
	"workrole": {
		GlobalHideName: "FindWorkRole",
		TypeName: "WorkRoleFilters",
		FilterMap: {}
	},
	"transfervalue": {
		GlobalHideName: "FindTransferValue",
		TypeName: "TransferValueFilters",
		FilterMap: {}
	},
	"credentialingaction": {
		GlobalHideName: "FindCredentialingAction",
		TypeName: "CredentialingActionFilters",
		FilterMap: {}
	},
	"transferintermediary": {
		GlobalHideName: "FindTransferIntermediary",
		TypeName: "TransferIntermediaryFilters",
		FilterMap: {}
	},
	"rubric": {
		GlobalHideName: "FindRubric",
		TypeName: "RubricFilters",
		FilterMap: {}
	},
	"metric": {
		GlobalHideName: "FindMetric",
		TypeName: "MetricFilters",
		FilterMap: {}
	},
	"industry": {
		GlobalHideName: "FindIndustry",
		TypeName: "IndustryFilters",
		FilterMap: {}
	},
};

//Enable something to hide itself based on search type
export function doesWidgetHideThisSearchType(searchType) {
	return window.Widget?.Config?.HideGlobalFilters?.includes(WidgetToFinderMap[searchType?.toLowerCase()]?.GlobalHideName);
}
//

//Apply a widget's stuff to a query
export function widgetApplyWidgetToQuery(query, searchType) {
	if (window.Widget?.Config) {
		//Add widget ID
		query.WidgetId = window.Widget.Config.Id;
		query.HasCredentialPotentialResults = window.Widget.Config?.HasCredentialPotentialResults;
		//Add filters
		var filterSet = WidgetToFinderMap[searchType?.toLowerCase()];
		query.MainFilters = query.MainFilters.filter(m => !m.WidgetName); //Remove existing widget filters so react doesn't double-include them
		if (filterSet) {
			//Role filters
			addRoles(window.Widget?.Config?.[filterSet.TypeName]?.QualityAssurance, "Quality Assurance by:");
			addRoles(window.Widget?.Config?.[filterSet.TypeName]?.Provider, "Provided by:");

			//Text filters
			addTexts(filterSet?.Subjects);
			addTexts(filterSet?.Competencies);
			addTexts(filterSet?.Industries);
			addTexts(filterSet?.Occupations);
			addTexts(filterSet?.InstructionalProgramTypes);
			addTexts(filterSet?.Collections);

			//Location filters
			var locations = window.Widget.Config.Locations;
			if (locations && (Object.keys(locations).length > 1 || locations.IsAvailableOnline == true)) {
				var locationsFilterItem = {
					WidgetName: "widget-locationset",
					Label: "Location(s)",
					Items: [{
						Text: [(locations.Countries || []).concat(locations.Regions || []).concat(locations.Cities || []).length > 0 ? "Applicable Locations" : "", locations.IsAvailableOnline ? "Available Online" : ""].filter(m => m.length > 0).join(" + "),
						Type: "LOCATIONSET",
						URI: "LOCATIONSET",
						Values: locations
					}]
				};
				query.MainFilters.push(locationsFilterItem);
			}

			//Potential Results filters
			var potentialResults = window.Widget?.Config?.[filterSet.TypeName]?.PotentialResults?.map(item => item.Id);
			if (potentialResults?.length > 0) {
				query.MainFilters.push({
					WidgetName: "widget-potentialresults",
					Label: "Potential Results",
					Items: [{
						Text: potentialResults.length + " Items"
					}],
					Parameters: {
						n: "potentialresults",
						ids: potentialResults
					}
				})
			}
			//Part of Collection Results filters
			var collectionResults = window.Widget?.Config?.[filterSet.TypeName]?.CollectionResults?.map(item => item.Id);
			if (collectionResults?.length > 0) {
				query.MainFilters.push({
					WidgetName: "widget-collectionresults",
					Label: "In Collection",
					Items: [{
						Text: collectionResults.length + " Items"
					}],
					Parameters: {
						n: "collectionresults",
						ids: collectionResults
					}
				})
			}
		}

		function addRoles(roles, messagePrefix) {
			if (roles) {
				roles.Organizations?.forEach(organization => {
					query.MainFilters.push({
						WidgetName: "widget-roles",
						Label: messagePrefix,
						Items: [{
							Text: organization.Name
						}],
						Parameters: { n: "organizationroles", aid: organization.Id, rid: roles.RoleIds }
					});
				});
			}
		}

		function addTexts(textArray) {
			if (textArray && textArray.length > 0) {
				query.MainFilters.push({
					WidgetName: "widget-text",
					Label: "Widget Text",
					InterfaceType: "interfaceType:Text",
					Items: textArray?.map(text => {
						return {
							Text: text
						};
					})
				});
			}
		}
	}
}
//

//Remove filters and/or filter items
export function widgetPruneFiltersAndOptions(filters, searchType) {
	var filterSet = WidgetToFinderMap[searchType?.toLowerCase()];
	if (filterSet) {
		var hiders = window.Widget?.Config?.[filterSet.TypeName]?.HideFiltersAndItems || [];
		var updated = [];
		filters.forEach(filter => {
			var hider = hiders.filter(m => m.Identifier == filterSet.FilterMap[filter.URI])[0];
			if (hider) {
				if (!hider.Selected && hider.ItemIDs?.length > 0) {
					filter.Items = filter.Items.filter(m => !hider.ItemIDs.includes(m.Id));
					updated.push(filter);
				}
			}
			else {
				updated.push(filter);
			}
		});

		return updated;
	}

	return filters;
}
//

//SiteHeader, SearchResultLink, SearchResultButton, LogoBox
export function widgetGetColor(colorFor, getForegroundColor, fallback) {
	var colorSet = window.Widget?.Config?.WidgetColors?.filter(m => m.ColorFor?.toLowerCase() == colorFor.toLowerCase())[0];
	if (colorSet) {
		var foregroundColor = colorSet.UseDefaultForegroundColor ? fallback : colorSet.ForegroundColor;
		var backgroundColor = colorSet.UseDefaultBackgroundColor ? fallback : colorSet.BackgroundColor;
		return getForegroundColor ? foregroundColor : backgroundColor;
	}
	else {
		return fallback;
	}
}
//

function updateWidgetStyles() {
	window.Widget.StyleTag.innerHTML = window.Widget.StyleRules.join("\n");
}
//

//Helper functions
function getData(url, success, error) {
	fetch(url).then(response => {
		return response.text();
	}).then(text => {
		var value = JSON.parse(text);
		success?.(value);
	}).catch(response => {
		console.log("Error", response);
		error?.(response);
	});
}
//

function select(selector, element) {
	return Array.from((element || document).querySelectorAll(selector));
}
//

function hide(element) {
	element.dataset.originalDisplay = element.style.display;
	element.style.display = "none";
}
//

function show(element) {
	element.style.display = element.dataset.widgetDisplay || element.dataset.originalDisplay || "";
}
//

function create(element, classList) {
	var element = document.createElement(element);
	classList?.forEach(item => { element.classList.add(item); });
	return element;
}
//

function append(element, container) {
	container.appendChild(element);
	return element;
}
//

function postData(url, data, success, error) {
	fetch(url, {
		method: "POST",
		headers: { "Accept": "application/json", "Content-Type": "application/json" },
		body: JSON.stringify(data)
	}).then(response => {
		return response.text();
	}).then(text => {
		var value = JSON.parse(text);
		success?.(value);
	}).catch(response => {
		console.log("Error", response);
		error?.(response);
	});
}
//

export function widgetGetSingleLabelForCTDLType(type, fallback){
	return window.Widget?.Config?.LabelMaps?.find(m => m.CTDLType == type)?.SingleLabel || fallback || type || "Resource";
}
//

export function widgetGetPluralLabelForCTDLType(type, fallback) {
	return window.Widget?.Config?.LabelMaps?.find(m => m.CTDLType == type)?.PluralLabel || fallback || type || "Resources";
}
//

export function widgetGetSingleLabelForBroadType(type, fallback){
	return window.Widget?.Config?.LabelMaps?.find(m => m.BroadType == type)?.SingleLabel || fallback || type || "Resource";
}
//

export function widgetGetPluralLabelForBroadType(type, fallback) {
	return window.Widget?.Config?.LabelMaps?.find(m => m.BroadType == type)?.PluralLabel || fallback || type || "Resources";
}
//

export function widgetGetSingleLabelForText(text, fallback){
	return window.Widget?.Config?.LabelMaps?.find(m => m.SingleReplace == text)?.SingleLabel || fallback || text || "Resource";
}
//

export function widgetGetPluralLabelForText(text, fallback) {
	return window.Widget?.Config?.LabelMaps?.find(m => m.PluralReplace == text)?.PluralLabel || fallback || text || "Resources";
}
//

function replaceTextWith(text, replaceType, labelType) {
	text = text || "";

	window.Widget?.Config?.LabelMaps?.forEach(Map => {
		text = text.replace(new RegExp(Map[replaceType], "gi"), Map[labelType]);
	});

	return text;
}

export function widgetReplaceSingleLabelInText(text) {
	return replaceTextWith(text, "SingleReplace", "SingleLabel");
}
//

export function widgetReplacePluralLabelInText(text) {
	return replaceTextWith(text, "PluralReplace", "PluralLabel");
}
//
