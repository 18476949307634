import styled from 'styled-components/macro';
import { ContentDelimiter } from '../common';
import { widgetGetColor } from '../../utils/SiteWidgetizer';

export const Wrapper = styled.div`
    background-color: ${(p) => (window as any).Widget?.ID ? "#333" : p.theme.color.blueDark};
    padding: 1.5rem 0 2rem;
	border-top: ${(p) => (window as any).Widget?.ID ? ("5px solid " + widgetGetColor("SiteHeader", false, "#CCC")) : "none"};
`;

export const Inner = styled(ContentDelimiter)`
    display: grid;
    grid-gap: 30px;
    grid-template-areas:
        'left center'
        'left right';
    grid-template-columns: fit-content(100px) 1fr;

    @media (min-width: ${(p) => p.theme.breakpoint.tabletLandscape}) {
        grid-template-areas: 'left center right';
        grid-template-columns: fit-content(150px) 1fr fit-content(250px);

    }
`;

export const Left = styled.div`
	position: relative;
    grid-area: left;
`;

export const Center = styled.div`
    grid-area: center;
`;

export const Right = styled.div`
    grid-area: right;
    color: ${(p) => p.theme.color.white};
`;

export const Icon = styled.img`
    height: auto;
    width: auto;
`;

export const Type = styled.small`
    color: ${(p) => (window as any).Widget?.ID ? "#FFF" : p.theme.color.aquaDark};
`;

export const Title = styled.h1`
    margin-block: 7px;
	color: ${(p) => (window as any).Widget?.ID ? "#FFF" : p.theme.color.aquaDark};
`;

export const SubTitle = styled.div`
    color: ${(p) => p.theme.color.white};
    margin-bottom: 7px;
    font-weight: 700;
`;

export const Content = styled.div`
    color: ${(p) => p.theme.color.white};
`;

export const WhiteLink = styled.a`
	color: ${(p) => p.theme.color.white};
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
`;

export const BadgeIcon = styled.img`
	position: absolute;
	bottom: 0;
	right: -10px;
	max-width: 55px;
	max-height: 55px;
`;