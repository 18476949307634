/**
 * The default theme
 */
import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
    color: {
        blueDark: '#0A2942',
        brown: '#996C48',
        greyDark: '#607484',
        greyLight: '#E6EAEC',
        aquaDark: '#4EE5E1',
        aquaLight: '#DCFAF9',
        white: '#FFFFFF',
    },
    breakpoint: {
        tabletPortrait: '600px',
        tabletLandscape: '900px',
        desktop: '1200px',
    },
    fontSize: {
        hero: '2.625rem' /* 42px */,
        xxxl: '1.75rem' /* 28px */,
        xxl: '1.5rem' /* 24px */,
        xl: '1.3125rem' /* 21px */,
        lg: '1rem' /* 16px */,
        md: '0.9375rem' /* 15px */,
        sm: '0.875rem' /* 14px */,
        xs: '0.8125rem' /* 13px */,
    },
    maxWidth: '1400px',
};

export { theme };
