// Please define all custom hooks here
import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

function getQueryType(query: string): [type: 'max' | 'min', size: number] {
    const [type, size] = query.split(':');
    const reg = /\D/g;
    let validatedSize = parseInt(size.trim().replaceAll(reg, ''));
    if (isNaN(validatedSize)) {
        validatedSize = 0;
    }
    return [type.includes('max') ? 'max' : 'min', validatedSize];
}

export function useMediaQuery({ query }: { query: string }) {
    const [matchQuery, setMatchQuery] = useState(false);

    useEffect(() => {
        const [type, matchSize] = getQueryType(query);
        function handleResize() {
            const { width, height } = getWindowDimensions();
            if (type === 'max') {
                setMatchQuery(width <= matchSize);
            } else {
                setMatchQuery(width >= matchSize);
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [query]);

    return matchQuery;
}
// A common function to get data from the server
export function useGet(): [
    boolean,
    boolean,
    (url: string, onSuccess: (data: any) => void) => void,
] {
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    // Wrap this in useCallback to prevent effects that depend on it re-running.
    const get = useCallback((url: string, onSuccess: (data: any) => void) => {
        setHasError(false);
        setIsLoading(true);
        axios
            .get(url)
            .then((response) => {
                setIsLoading(false);
                const data = response.data;
                if (data.Successful === false) {
                    throw data;
                } else {
                    data.Result ? onSuccess(data.Result) : onSuccess(data);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setHasError(true);
                console.error(error);
            });
    }, []);

    return [isLoading, hasError, get];
}
