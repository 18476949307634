import React from 'react';
import styled from 'styled-components/macro';
import { findIconDefinition, IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContactPoint as ContactPointType } from '../../types/external';

export default function ContactPointList(props: { items: ContactPointType[]; }) {
	if (props.items?.length > 0) {
		return (
			<div className="ContactPointList">
				{props.items.map((item) => (
					<ContactPoint item={item} />
				))}
			</div>
		)
	}
	return null;
}

export function ContactPoint(props: { item: ContactPointType }) {
	return (props.item &&
		<ContactPointWrapper className="ContactPoint">
			<ContactDetails>
				{props.item.Name && <ContactName><b>{props.item.Name}</b></ContactName>}
				{props.item.ContactType && <ContactType><b>Type:</b> {props.item.ContactType}</ContactType>}
				{props.item.Telephone?.length > 0 && <ContactTelephone><b>Phone:</b> {props.item.Telephone.join(", ")}</ContactTelephone>}
				{props.item.FaxNumber && <ContactFaxNumber><b>Fax:</b> {props.item.FaxNumber}</ContactFaxNumber>}
				{props.item.Email?.length > 0 && <ContactEmail><b>Email:</b>{props.item.Email.map((email) => <a href={"mailto:" + email} target="_blank">{email}</a>)}</ContactEmail>}
			</ContactDetails>
			{props.item.SocialMedia?.length > 0 &&
			<ContactSocialMedia>
				{props.item.SocialMedia.map((url) =>
					<a href={url} target="_blank" title={url}>
						<SocialMediaIcon url={url} />
					</a>
				)}
			</ContactSocialMedia>
			}
		</ContactPointWrapper>
	)
}

export function SocialMediaIcon(props: { url: string }) {
	//It's okay to ignore the typescript errors here
	try {
		var domainParts = props.url.toLowerCase().replace("http://", "").replace("https://", "").split("/")[0].split(".");
		var domain = domainParts[domainParts.length - 2];

		var definition =
			findIconDefinition({ prefix: "fab", iconName: domain + "-square" as IconName }) ||
			findIconDefinition({ prefix: "fab", iconName: domain as IconName }) ||
			findIconDefinition({ prefix: "fas", iconName: "hashtag" });

		return (
			<FontAwesomeIcon icon={[definition.prefix, definition.iconName]} size="2x" />
		)
	}
	catch (e) {
		console.log("Error parsing social media URL: ", { url: props.url, error: e });
		return null;
	}
}

const ContactPointWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 5px;
	font-size: 85%;

	&:not(:last-child) {
		border-bottom: 1px dashed #CCC;
	}
`;

const ContactDetails = styled.div`
	flex: 1 1 auto;
	margin-right: auto;
	padding-right: 10px;

	&:empty {
		display: none;
	}
`;

const ContactName = styled.div`

`;

const ContactType = styled.div`
	
`;

const ContactTelephone = styled.div`

`;

const ContactFaxNumber = styled.div`

`;

const ContactEmail = styled.div`
	display: flex;
	flex-wrap: wrap;

	& a {
		margin: 0 5px;
	}
`;

const ContactSocialMedia = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;

	& a {
		margin: 2px;
	}
`;
