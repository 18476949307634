import React from 'react';
import styled from 'styled-components/macro';
import { JurisdictionProfile as JurisdictionProfileType } from '../../types/external';
import { Outline } from './Outline';
import { Section, SectionLabel, SectionContent } from './Shared';
import PlaceList, { Place } from './Place';

export default function JurisdictionProfileList(props: { items: JurisdictionProfileType[], mainJurisdictionLabel?: string, jurisdictionExceptionLabel?: string, isGlobalJurisdictionText?: string }) {
	return (props.items?.length > 0 &&
		<div className="JurisdictionProfileList">
			{props.items.map((item) => (
				<JurisdictionProfile
					item={item}
					mainJurisdictionLabel={props.mainJurisdictionLabel}
					jurisdictionExceptionLabel={props.jurisdictionExceptionLabel}
					isGlobalJurisdictionText={props.isGlobalJurisdictionText}
				/>
			))}
		</div>
	) || null
}

export function JurisdictionProfile(props: { item: JurisdictionProfileType, mainJurisdictionLabel?: string, jurisdictionExceptionLabel?: string, isGlobalJurisdictionText?: string }) {
	return (props.item &&
		<JurisdictionProfileWrapper className="JurisdictionProfile">
			{props.item.Description && 
				<Section>{props.item.Description}</Section>
			}
			{props.item.GlobalJurisdiction && 
				<Section><b>{props.isGlobalJurisdictionText || "The jurisdiction is global."}</b></Section>
			}
			{props.item.MainJurisdiction && 
				<Section>
					<SectionContent>
						<div><b>{props.mainJurisdictionLabel || "Applies In"}</b></div>
						<Place item={props.item.MainJurisdiction} />
					</SectionContent>
				</Section>
			}
			{props.item.JurisdictionException?.length > 0 &&
				<Section>
					<SectionContent>
						<div><b>{props.jurisdictionExceptionLabel || "Does Not Apply In"}</b></div>
						<PlaceList items={props.item.JurisdictionException} />
					</SectionContent>
				</Section>
			}
			{props.item.AssertedBy &&
				<Section>
					<SectionLabel>Asserted By</SectionLabel>
					<SectionContent>
						<Outline item={props.item.AssertedBy} />
					</SectionContent>
				</Section>
			}
		</JurisdictionProfileWrapper>
	)
}

const JurisdictionProfileWrapper = styled.div`
	&:not(:last-child) {
		border-bottom: 1px dashed #CCC;
	}
`;