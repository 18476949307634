import React, { useContext, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { InternalButtonLink } from '../common';
import { HStack } from '../Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAngleDown,
    faAngleUp,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../shared/theme';
import { widgetGetColor, widgetGetPluralLabelForText } from '../../utils/SiteWidgetizer';
import { getCompareItemsByType, removeCompareItem, updateStateOnCompareChange } from '../../utils/LocalStorage';
import { configName } from '../../shared/globals';

export default function CompareSelectedListPanel(props: { onClose: () => void }) {
	return (
		<Wrapper>
			<HStack justify="space-between" align="center">
				<CompareButton buttonType="primary" to={"/compare" + window.location.search} target="comparePage">
					Go to Compare Page
				</CompareButton>
				<ComparePanelCloseButton onClick={() => props.onClose()} >
					<FontAwesomeIcon icon={faTimes} size="2x" />
				</ComparePanelCloseButton>
			</HStack>
			<CompareTypes>
				<CollapsibleCompareItems
					typeLabel={widgetGetPluralLabelForText("Credentials")}
					broadType="credential"
				/>
				<CollapsibleCompareItems
					typeLabel={widgetGetPluralLabelForText("Organizations")}
					broadType="organization"
				/>
				<CollapsibleCompareItems
					typeLabel={widgetGetPluralLabelForText("Assessments")}
					broadType="assessment"
				/>
				<CollapsibleCompareItems
					typeLabel={widgetGetPluralLabelForText("Learning Opportunities")}
					broadType="learningopportunity"
				/>
				<CollapsibleCompareItems
					typeLabel={widgetGetPluralLabelForText("Transfer Value Profiles")}
					broadType="transfervalue"
				/>
				<CollapsibleCompareItems
					typeLabel={widgetGetPluralLabelForText("Pathways")}
					broadType="pathway"
				/>
				<CollapsibleCompareItems
					typeLabel={widgetGetPluralLabelForText("Competency Frameworks")}
					broadType="competencyframework"
				/>
				<CollapsibleCompareItems
					typeLabel={widgetGetPluralLabelForText("Collections")}
					broadType="collection"
				/>
				<CollapsibleCompareItems
					typeLabel={widgetGetPluralLabelForText("Concept Schemes")}
					broadType="conceptscheme"
				/>
				<CollapsibleCompareItems
					typeLabel={widgetGetPluralLabelForText("Jobs")}
					broadType="job"
				/>
				{/*<CollapsibleCompareItems
					typeLabel="Competencies"
					broadType="competency"
				/>*/}
			</CompareTypes>
		</Wrapper>
	);
}
//

function CollapsibleCompareItems(props: { typeLabel: string, broadType: string }) {
	const [expanded, setExpanded] = useState(false);
	const [items, setItems] = useState([] as Array<any>);

	useEffect(() => {
		updateStateOnCompareChange(() => { setItems(getCompareItemsByType(props.broadType)); });
		setItems(getCompareItemsByType(props.broadType));
	}, []);

	return (
		<CompareItemsWrapper>
			<CompareItemsWrapperTitle onClick={() => setExpanded(!expanded)} aria-label={'Expand the list of ' + props.typeLabel + ' to be compared'}>
				<CompareItemTitle>{items.length} {props.typeLabel}</CompareItemTitle>
				<IconButton icon={expanded ? faAngleUp : faAngleDown} size="lg" />
			</CompareItemsWrapperTitle>
			{expanded &&
				items.map((item) => (
					<CompareItemsWrapperItem
						key={`cr-compare-item-${item.BroadType}-${item.Meta_Id}`}>
						<CompareSubItemTitle>{item.Name}</CompareSubItemTitle>
						<CompareItemRemoveButton onClick={() => removeCompareItem(item)} aria-label={'Remove ' + item.Name} >
							<FontAwesomeIcon icon={faTimes} size="lg" />
						</CompareItemRemoveButton>
					</CompareItemsWrapperItem>
				))}
		</CompareItemsWrapper>
	);
}
//

const Wrapper = styled.div`
    flex: 0 0 350px;
    background-color: ${(props) => widgetGetColor("SiteHeader", false, props.theme.color.aquaDark)};
    padding: 20px;
	position:absolute;
	right: 0;
	z-index: 50;
    @media (max-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        position: absolute;
        top: 0;
        right: 0;
        min-height: 100vh;
        width: 80%;
    }
`;

const CompareTypes = styled.div`
    padding: 10px 0;
    > :not(:last-child) {
        border-bottom: 1px solid ${theme.color.white};
    }
`;

const IconButton = styled(FontAwesomeIcon)`
    cursor: pointer;
	margin-left: 5px;
	color: ${(props) => widgetGetColor("SearchResultButton", true, "black")}
`;

const CompareButton = styled(InternalButtonLink)`
    color: ${(props) => widgetGetColor("SearchResultButton", true, props.theme.color.aquaDark)};
	border-color: ${(props) => widgetGetColor("SearchResultButton", true, props.theme.color.aquaDark)}
`;

const ComparePanelCloseButton = styled.button`
	background-color: transparent;
	border: none;
	color: ${(props) => widgetGetColor("SearchResultButton", true, "black")}
`;

const CompareItemsWrapper = styled.div`
    padding: 10px 0;
`;

const CompareItemTitle = styled.h4`
	flex: 1 1 100%;
	margin: 0;
	color: ${(props) => widgetGetColor("SearchResultButton", true, "black")}
`;

const CompareSubItemTitle = styled.p`
	flex: 1 1 100%;
	margin: 0;
	color: ${(props) => widgetGetColor("SearchResultButton", true, "black")}
`;

const CompareItemsWrapperTitle = styled.button`
	display: flex;
	background-color: transparent;
	border: none;
	width: 100%;
	text-align: left;
	padding: 5px;
	&:hover {
		background-color: rgba(255,255,255,0.3);
	}
`;

const CompareItemsWrapperItem = styled.div`
	display: flex;
	padding: 5px 10px;
	align-items: baseline;
`;

const CompareItemRemoveButton = styled.button`
	background-color: transparent;
	border: none;
	color: ${(props) => widgetGetColor("SearchResultButton", true, "black")};
	&:hover {
		background-color: rgba(255,255,255,0.3);
	}
`;
