import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { StyledDropdown, Header, HeaderIcon, Options, Option, OptionIcon } from './styles';

interface Props {
    defaultOptionIndex?: number;
    options: any[];
    onChange?: (option: any) => void;
	getOptionLabel?: (option: any) => string;
	ariaLabel: string;
}

export default function Dropdown(props: Props) {
    const [selectIndex, setSelectIndex] = useState(
        props.defaultOptionIndex ?? props.options.length > 0 ? 0 : -1,
    );
    const ref = useRef<HTMLDivElement | null>(null);
    const [expanded, setExpanded] = useState(false);

    function onChange(option: any, index: number) {
        setSelectIndex(index);
        setExpanded(!expanded);
        props.onChange && props.onChange(option);
    }

    function getOptionLabel(option: any) {
        if (option === undefined) {
            return '';
        }
        if (typeof option === 'string') {
            return option;
        } else {
            return props.getOptionLabel ? props.getOptionLabel(option) : '';
        }
    }

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setExpanded(false);
        }
    };

    const handleBlur = (event: any) => {
        if (ref.current && !ref.current.contains(event.relatedTarget)) {
            setExpanded(false);
        }
    };

    useEffect(() => {
        if (props.defaultOptionIndex !== undefined) {
            setSelectIndex(props.defaultOptionIndex);
        }
    }, [props.defaultOptionIndex]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

	//Initialize for new query handller. May need to remove this if it causes issues?
	useEffect(() => {
		setSelectIndex(props.defaultOptionIndex || 0);
		props.onChange && props.onChange(props.options[props.defaultOptionIndex || 0]);
	}, []);

	function handleKeyUp(event: React.KeyboardEvent<HTMLDivElement>) {
		/*
		switch (event.keyCode) {
			case 36: { setSelectIndex(0); event.stopPropagation(); event.preventDefault(); break; } //Home Key
			case 38: { setSelectIndex(selectIndex - 1 <= 0 ? 0 : selectIndex - 1); event.stopPropagation(); event.preventDefault(); break; } //Up Arrow
			case 40: { setSelectIndex(selectIndex + 1 >= props.options.length ? props.options.length - 1 : selectIndex + 1); event.stopPropagation(); event.preventDefault(); break; } //Down Arrow
			case 35: { setSelectIndex(props.options.length - 1); event.stopPropagation(); event.preventDefault(); break; } //End Key
			default: break;
		}

		switch (event.keyCode) {
			case 38: { window.dispatchEvent(new KeyboardEvent("keydown", { keyCode: 9, shiftKey: true })); break; }
			case 40: { window.dispatchEvent(new KeyboardEvent("keydown", { keyCode: 9 })); break; }
			default: break;
		}
		*/
	}

	var currentIndex = selectIndex;
	return (
		<StyledDropdown role="listbox" aria-label={props.ariaLabel} onKeyUp={(event) => handleKeyUp(event)}>
            <Header onClick={() => setExpanded(!expanded)}>
                {getOptionLabel(props.options[selectIndex])}
                <HeaderIcon>
                    <FontAwesomeIcon icon={faAngleDown} />
                </HeaderIcon>
            </Header>
				<Options ref={ref} onBlur={handleBlur} style={{ display: expanded ? "" : "none" }}>
					{props.options.map((option, index) => (
						<Option
							role="option"
							key={`dr-${getOptionLabel(option)}-${index}`}
							selected={index === selectIndex}
							onClick={() => onChange(option, index)}>
							{option.icon && <OptionIcon src={option.icon} />}
							{getOptionLabel(option)}
						</Option>
					))}
				</Options>
        </StyledDropdown>
    );
}
