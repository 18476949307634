import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import SearchBar from '../../components/SearchBar';
import Carousel from '../../components/Carousel';
import TabBar from '../../components/TabBar';
import {
    ApplicationDevelopersBlock,
    CredentialProvidersBlock,
    EmployersBlock,
    GovernmentAgenciesBlock,
    IndividualsBlock,
    QualityAssuranceOrgnizationsBlock,
    SkillAndCompetencyCreatorsBlock,
} from '../../components/CarouselBlock';
import InformationPanel from './InformationPanel';
import { HStack, VStack } from '../../components/Stack';

import { SConnector } from 'react-svg-connector';
import { theme } from '../../shared/theme';
import {
    Wrapper,
    PageDescription,
    PageSection,
    PageTitle,
    StatData,
    StatItem,
    StatItemTitle,
    StatItemValue,
    ConnectorBox,
    Title,
} from './styles';
import axios from 'axios';
import { apiUrl } from '../../shared/globals';
import { Link } from '../../types/external';
import { statDataLabel } from '../../shared/data';
import LinkObject from '../../components/DetailPage/LinkObject';

const items = [
    'Individuals',
    'Credential Providers',
    'Government Agencies',
    'Application Developers',
    'Skill and Competency Creators',
    'Employers',
    'Quality Assurance Organizations',
];

const blocks = [
    <IndividualsBlock />,
    <CredentialProvidersBlock />,
    <GovernmentAgenciesBlock />,
    <ApplicationDevelopersBlock />,
    <SkillAndCompetencyCreatorsBlock />,
    <EmployersBlock />,
    <QualityAssuranceOrgnizationsBlock />,
];

function HomePage(props: RouteComponentProps) {
    const [currentBlock, setCurrentBlock] = useState(0);
    const [statData, setStatData] = useState<Link[]>([]);
    const history = useHistory();

    function onTabClick(value: string, index: number) {
        setCurrentBlock(index);
    }

    function onSearchButtonClick() {
        history.push('/search');
    }

    useEffect(() => {
        // call API
        axios.get(apiUrl + '/FinderHome').then((res) => {
            const data = res.data;
            if (data.Successful) {
                const statData = data.Result?.map((item: Link) => {
                    return {
                        ...item,
                        Label: statDataLabel[item.Label] || item.Label,
                    };
                });
                setStatData(statData || []);
            }
        });
    }, []);

    return (
        <Wrapper>
            <HStack>
                <ConnectorBox>
                    <SConnector
                        startPoint={{ x: 0, y: 140 }}
                        endPoint={{ x: 140, y: 20 }}
                        stroke={theme.color.aquaDark}
                    />
                </ConnectorBox>
                <VStack>
                    <PageTitle>
                        Discover Credentials. Explore Opportunities
                    </PageTitle>
                    <PageDescription>
						Discover and compare credential opportunities, build widgets, and access our linked open data network for timely and trusted information about credentials including competencies and education and career pathways. Here you can explore the Credential Registry to understand how data that you and others contribute can be used to fuel the creation of services and tools that allow students, employers, and workers to compare credentials and choose the best option.
					</PageDescription>
                </VStack>
                <ConnectorBox />
            </HStack>
            <HStack align="flex-start">
                <ConnectorBox />
                <SearchBar onSearchButtonClick={onSearchButtonClick} />
                <ConnectorBox height="178px">
                    <SConnector
                        startPoint={{ x: 5, y: 48 }}
                        endPoint={{ x: 140, y: 168 }}
                        stroke={theme.color.brown}
                    />
                </ConnectorBox>
            </HStack>
            <PageSection>
                <Title style={{ fontSize: '36px' }}>
                    What's in the Registry?
                </Title>
                <StatData spacing="20px">
                    {statData.map((item, index) => (
                        <StatItem
                            key={'stat-item-' + index}
                            onClick={() => history.push(item.URL)}>
                            <StatItemValue>{item.Total}</StatItemValue>
                            <StatItemTitle>{item.Label}</StatItemTitle>
                        </StatItem>
                    ))}
                </StatData>
			</PageSection>
			<HStack style={{ margin: '50px 0' }}>
				<ConnectorBox>
				</ConnectorBox>
				<VStack>
					<Title style={{ fontSize: '36px' }}>
						Overview and Navigation Tips
					</Title>
					<PageDescription>
						If you are new to the Credential Finder or want to get more familiar with the updated interface, we recommend exploring our <a href="https://credentialengine.org/credential-finder/" target="_blank">overview page</a> to learn how tools like the finder help make credential data more accessible and actionable. It includes helpful navigation tips, videos on changes we have implemented, and an invitation to <a href="https://credentialengine.org/finder-feedback/" target="_blank">share your feedback</a> so we may improve tools like the Credential Finder or collaborate to develop custom tools that meet the needs of your stakeholders. You can also learn more about <a href="https://credentialengine.org/about/" target="_blank">Credential Engine</a> and how tools like the Finder help <a href="https://credentialengine.org/2021/08/04/credential-transparency-illuminates-paths-to-a-better-future-2/" target="_blank">illuminate paths to a better future</a>.
						<LinkButtonSection>
							<LinkObject item={{ Label: "Overview Page", URL: "https://credentialengine.org/credential-finder/" } as Link} buttonType="secondary" />
							<LinkObject item={{ Label: "Feedback Page", URL: "https://credentialengine.org/finder-feedback/" } as Link} buttonType="secondary" />
							<LinkObject item={{ Label: "About Credential Engine", URL: "https://credentialengine.org/about/" } as Link} buttonType="secondary" />
						</LinkButtonSection>
					</PageDescription>
				</VStack>
				<ConnectorBox>
				</ConnectorBox>
			</HStack>

            <PageSection>
				<Title style={{ fontSize: '36px' }}>
                    Who is Credential Finder for?
                </Title>
                <TabBar items={items} onTabClick={onTabClick} />
                <Carousel currentBlock={currentBlock} blocks={blocks} />
            </PageSection>
			<InformationPanel />
        </Wrapper>
    );
}

export default HomePage;

const LinkButtonSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 10px 0;

	& > * {
		margin: 5px 10px;
		min-width: 300px;
	}
`