import styled, { css } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { widgetGetColor } from '../../utils/SiteWidgetizer';

export const Hero = styled.h2`
    font-size: ${(props) => props.theme.fontSize.hero};
    font-weight: 400;
    line-height: 1.095;
`;

interface ButtonProps {
    buttonType?: 'primary' | 'secondary' | 'tertiary' | 'filled';
    inline?: boolean;
}

export const Button = styled.button<ButtonProps>`
    background: transparent;
    border: 1px solid ${(props) => props.theme.color.blueDark};
    color: ${(props) => props.theme.color.blueDark};
    cursor: pointer;
    font-size: ${(props) => props.theme.fontSize.lg};
    font-weight: 500;
    line-height: 1.125;
    min-width: 350px;
    padding: 0.65em 1.65em;
	 border-radius: 3px;
    &:hover {
        filter: brightness(1.5);
    }

    ${(props) =>
        !props.buttonType &&
        css`
            &:hover {
                background: ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.blueDark)};
                color: ${(props) => widgetGetColor("SearchResultButton", true, props.theme.color.white)};
            }
        `}

    ${(props) =>
        props.buttonType === 'primary' &&
        css`
            background: ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.blueDark)};
            border-color: ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.blueDark)};
            color: ${(props) => widgetGetColor("SearchResultButton", true, props.theme.color.white)};
        `}
    ${(props) =>
        props.buttonType === 'secondary' &&
        css`
            background: ${(props) => widgetGetColor("SearchResultLink", false, props.theme.color.aquaDark)};
            border-color: ${(props) => widgetGetColor("SearchResultLink", true, props.theme.color.aquaDark)};
            color: ${(props) => widgetGetColor("SearchResultLink", true, props.theme.color.blueDark)};
        `}
        ${(props) =>
        props.buttonType === 'tertiary' &&
        css`
            background: ${(props) => widgetGetColor("LogoBox", false, props.theme.color.brown)};
            border-color: ${(props) => widgetGetColor("LogoBox", false, props.theme.color.brown)};
            color: ${(props) => widgetGetColor("LogoBox", true, props.theme.color.white)};

            &:hover {
                filter: brightness(1.25);
            }
        `}
    ${(props) =>
        props.buttonType === 'filled' &&
        css`
            background: ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.blueDark)};
            color: ${(props) => widgetGetColor("SearchResultButton", true, props.theme.color.white)};
        `}
    ${(props) =>
        props.inline &&
        css`
            align-self: flex-start;
        `}
`;

export const ButtonLink = styled(Button.withComponent('a'))<{ type?: 'link' }>`
    align-self: flex-start;
    text-decoration: none;
    text-align: center;
    display: inline-block;

    ${(props) =>
        props.type === 'link' &&
        css`
            background: ${(props) => widgetGetColor("SearchResultButton", true, props.theme.color.white)};
            color: ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.blueDark)};
            border: none;
        `}
`;

type LinkWithoutButtonTypeProps = ButtonProps & LinkProps;

// Pick out Button prop values (like buttonType) that cause warnings when passed to a Link component.
const LinkWithoutButtonProps = ({
    buttonType,
    inline,
    ...linkProps
}: LinkWithoutButtonTypeProps) => <Link {...linkProps} />;

export const InternalButtonLink = styled(
    Button.withComponent(LinkWithoutButtonProps),
)`
    align-self: flex-start;
    text-decoration: none;
    text-align: center;
    display: inline-block;
`;

export const CleanList = styled.ul`
    margin: 0;
    padding: 0;

    > li {
        list-style: none outside none;
    }
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

export const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`;

export const TextField = styled.input`
    border: 1px solid ${(props) => widgetGetColor("SearchResultLink", false, props.theme.color.aquaDark)};
    border-radius: 0;
    font-size: ${(props) => props.theme.fontSize.lg};
    line-height: 1.125;
    padding: 0.625em 0.65em;
    margin: 0;
    width: 100%;
`;

export const ContentDelimiter = styled.div`
    margin: 0 auto;
    max-width: ${(props) => props.theme.maxWidth};
    padding: 0 20px;
`;
