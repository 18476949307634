//TBD integrate in env
export const apiUrl = process.env.REACT_APP_API_URL || 'https://sandbox.credentialengine.org/finderAPI';
export const legacySourceUrl = process.env.REACT_APP_LEGACY_SOURCE_URL || 'https://sandbox.credentialengine.org/services';
export const appURL = process.env.PUBLIC_URL || "https://credentialfinder.org";
export const registryURL = process.env.REACT_APP_REGISTRY_URL || "https://credentialengineregistry.org";
export const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY || 'AIzaSyAnierCr9ypDBt9AQzlrwTKCFt-5JBJMdE';
export const initializeFilterSearchApiUrl = apiUrl + '/search/initialize';
export const autocompleteFilterSearchApiUrl = apiUrl + '/search/autocomplete';
export const locationBoxSearchApiUrl = apiUrl + '/search/location';
export const BUCKET_STORAGE = 'cr-compare-bucket';
export const googleAnalyticsID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || "UA-77712853-2";
export const appBaseName = process.env.REACT_APP_BASENAME || "";
export const configName = process.env.REACT_APP_CONFIG_NAME || "unknown";
