import React, { useContext, useRef, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LoadingOrError from '../../components/LoadingOrError';
import PageSection from '../../components/PageSection';
import PageSectionItem from '../../components/PageSectionItem';
import {
    MobileAboutDetail,
    Description,
    Wrapper,
    Inline,
    InlineLabel,
    Section,
} from '../../components/DetailPage/styles';
import { apiUrl } from '../../shared/globals';
import { theme } from '../../shared/theme';
import opportunityIcon from '../../assets/images/icons/icon-learning-opportunities-white-green-01.svg';
import courseIcon from '../../assets/images/icons/icon-courses-white-green-01.svg';
import learningProgramIcon from '../../assets/images/icons/icon-learning-programs-white-green-01.svg';

import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';
import { VStack } from '../../components/Stack';
import { useMediaQuery, useGet } from '../../hooks';
import { ModalType } from '../../types/internal';
import { LearningOpportunity } from '../../types/external';
import { isFilled } from '../../validation';
import AboutDetail from '../AssessmentPage/AboutDetail';
import OccupationsAndIndustriesSection from '../../components/DetailPage/OccupationsAndIndustriesSection';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import SubjectsAndKeywordsPageSection from '../../components/DetailPage/SubjectsAndKeywordsPageSection';
import QualityAssurancePageSection from '../../components/DetailPage/QualityAssurancePageSection';
import LocationPageSection from '../../components/DetailPage/LocationPageSection';
import PageBanner from '../../components/DetailPage/PageBanner';
import LinkObject from '../../components/DetailPage/LinkObject';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import CostsAndTimePageSection from '../../components/DetailPage/CostsAndTimePageSection';
import HasAnyFilled from '../../components/HasAnyFilled';
import { detailSectionProperties } from '../../utils';
import ConnectionsSection from '../../components/DetailPage/ConnectionsSection';
import OutlineList from '../../components/DetailPage/Outline';
import { ModalButtonAndWindow } from '../../components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import { OutcomesDataPageSectionItem } from '../../components/DetailPage/OutcomesDataPageSection';
import { renderJSONLDMetaTags } from '../../utils/JSONLDMetaHelper';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText, widgetReplacePluralLabelInText } from '../../utils/SiteWidgetizer';

interface Props {
    id: string;
}

export default function LearningOpportunityPage(
    props: RouteComponentProps<Props>,
) {
    const id = props.match.params.id;
    const [
        opportunityDataIsLoading,
        opportunityDataHasError,
        getPageData,
    ] = useGet();
    const [opportunity, setOpportunity] = useState({} as LearningOpportunity);
    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });
    const [openedModal, setOpenedModal] = useState<ModalType>();

    function onOpenModalClick(modalType: ModalType) {
        return () => setOpenedModal(modalType);
    }

    function onCloseModalClick() {
        setOpenedModal(undefined);
    }

    useEffect(() => {
        getPageData(
            `${apiUrl}/learningopportunity/${id}`,
            (data: LearningOpportunity) => {
				setOpportunity(data);
				renderJSONLDMetaTags(data, "LearningOpportunity");
            },
        );
    }, [getPageData, id]);

	var typeLabel = widgetGetSingleLabelForText(opportunity.CTDLTypeLabel, "Learning Opportunity");

    return (
        <LoadingOrError
            isLoading={opportunityDataIsLoading}
            hasError={opportunityDataHasError}>
            <PageBanner
                item={opportunity}
                bannerIcon={opportunity.LearningEntityTypeId == "36" ? learningProgramIcon : opportunity.LearningEntityTypeId == "37" ? courseIcon: opportunityIcon}
            />
            <Wrapper>
                <VStack spacing={isMobile ? '0px' : '20px'}>
                    <HasAnyFilled
                        data={opportunity}
                        keys={detailSectionProperties.loppAbout}>
                        <PageSection
                            icon={aboutIcon}
                            title={"About this " + typeLabel}
                            variant="Highlight"
                            description={"Basic information about the " + typeLabel}
                        >
                            {opportunity.Description && (
                                <Description>
                                    {opportunity.Description}
                                </Description>
                            )}
                            <PageSectionItem>
                                {opportunity.AvailableOnlineAt?.length > 0 &&
                                    opportunity.AvailableOnlineAt.map((url) => {
                                        <LinkObject item={{ URL: url, Label: "Available Online" }} />
                                    })
                                }
                                {opportunity.SubjectWebpage &&
                                    <LinkObject item={{ URL: opportunity.SubjectWebpage, Label: "View this " + typeLabel }} />
                                }
                                {opportunity.InCatalog &&
                                    <LinkObject item={{ URL: opportunity.InCatalog, Label: "View this " + typeLabel + "in the Course Catalog "
                                    }} />
                                }
                                {opportunity.IsNonCredit !== undefined && opportunity.IsNonCredit == true && (
                                    <Inline>
                                        <InlineLabel>Is Non-Credit :</InlineLabel>
                                        <div>
                                            {opportunity.IsNonCredit
                                                ? 'true'
                                                : 'false'}
                                        </div>
                                    </Inline>
                                )}
                            </PageSectionItem>
							<OutcomesDataPageSectionItem aggregateDataProfiles={opportunity.AggregateData} dataSetProfiles={opportunity.ExternalDataSetProfiles} resourceTitle={opportunity.Name} />
                            <AboutDetail
                                item={opportunity}
                                onOpenModalClick={onOpenModalClick}
                                onCloseModalClick={onCloseModalClick}
                                openedModal={openedModal}
                            />
                        </PageSection>
                        {isMobile && (
                            <MobileAboutDetail>
                                <AboutDetail
                                    item={opportunity}
                                    onOpenModalClick={onOpenModalClick}
                                    onCloseModalClick={onCloseModalClick}
                                    openedModal={openedModal}
                                />
                            </MobileAboutDetail>
                        )}
                    </HasAnyFilled>
                    <CostsAndTimePageSection
                        item={opportunity}
                        onOpenModalClick={onOpenModalClick}
                        onCloseModalClick={onCloseModalClick}
                        openedModal={openedModal}
                        pageSectionDescription={"How to pursue and complete the " + typeLabel}
                    />
                    <SubjectsAndKeywordsPageSection item={opportunity} pageSectionDescription={"Type of material covered by the " + typeLabel} />
                    <AdditionalInformationPageSection item={opportunity} pageSectionDescription={"Identifiers and other information for the " + typeLabel} />
                </VStack>
                <VStack spacing={isMobile ? '0px' : '20px'}>
                    <RelatedOrganizationPageSection item={opportunity} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " that own, offer, or perform other services related to the " + typeLabel}/>
					<HasAnyFilled
                        data={opportunity}
                        keys={[
							...detailSectionProperties.entityConnections,
							...detailSectionProperties.loppConnections,
                        ]}>
						<PageSection
                            icon={connectionsIcon}
                            title="Connections"
							description="Related Resources"
						>
							<PageSectionItem>
								<ModalButtonAndWindow
									buttonLabel={opportunity.HasPart?.Label || "Contains " + widgetGetPluralLabelForText("Learning Opportunities")}
									resourceTitle={opportunity.Name}
									items={opportunity.HasPart?.Values}
									Wrapper={Section}
									Content={() => <OutlineList items={opportunity.HasPart?.Values} />}
								/>
								<ModalButtonAndWindow
									buttonLabel={opportunity.IsPartOf?.Label || "Is Part of " + widgetGetPluralLabelForText("Learning Opportunities")}
									resourceTitle={opportunity.Name}
									items={opportunity.IsPartOf?.Values}
									Wrapper={Section}
									Content={() => <OutlineList items={opportunity.IsPartOf?.Values} />}
                                />
                                <ModalButtonAndWindow
                                    buttonLabel={opportunity.Prerequisite?.Label || "Prerequisites"}
                                    resourceTitle={opportunity.Name}
                                    items={opportunity.Prerequisite?.Values}
                                    Wrapper={Section}
                                    Content={() => <OutlineList items={opportunity.Prerequisite?.Values} />}
                                />
								<ConnectionsSection
									item={opportunity}
									openedModal={openedModal}
									onOpenModalClick={onOpenModalClick}
									onCloseModalClick={onCloseModalClick}
								/>
							</PageSectionItem>
						 </PageSection>
                    </HasAnyFilled>
                    <QualityAssurancePageSection
                        item={opportunity}
                        openedModal={openedModal}
                        onCloseModalClick={onCloseModalClick}
                        onOpenModalClick={onOpenModalClick}
                        pageSectionDescription={"Quality Assurance for the " + typeLabel}
                    />
					<OccupationsAndIndustriesSection item={opportunity} pageSectionDescription={"Career Field information about the " + typeLabel} itemTypeLabel={widgetReplacePluralLabelInText("Learning Opportunities") + ", " + widgetReplacePluralLabelInText("Learning Programs") + ", or " + widgetReplacePluralLabelInText("Courses")} />
                    <LocationPageSection item={opportunity} pageSectionDescription={"Geographic information about the " + typeLabel} />
                </VStack>
            </Wrapper>
        </LoadingOrError>
    );
}
