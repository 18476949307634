import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { RevocationProfile as RevocationProfileType } from '../../types/external';
import { Box, BoxLabel, BoxContent, Section, SectionLabel, SectionContent, SectionContentItem } from './Shared';
import DateRange from './DateRange';
import JurisdictionProfileList from './JurisdictionProfile';
import LinkObject from './LinkObject';

export default function CostProfileList(props: { items: RevocationProfileType[] }) {
	return (props.items?.length > 0 &&
		<div>
			{props.items.map((item) => (
				<RevocationProfile item={item} />
			))}
		</div>
	) || null
}

export function RevocationProfile(props: { item: RevocationProfileType }) {
	return (props.item && 
		<RevocationProfileWrapper>
			<BasicInfo>
				<SectionContent>
					<div>{props.item.Description || "No Description"}</div>
					<DateRange FromDate={props.item.DateEffective} />
				</SectionContent>
				{props.item.RevocationCriteria?.URL && <LinkObject item={{ URL: props.item.RevocationCriteria?.URL, Label: "Revocation Criteria" }} />}
			</BasicInfo>
			{props.item.Jurisdiction?.length > 0 && (
				<Section>
					<SectionContent>
						<SectionLabel>Jurisdiction Information:</SectionLabel>
						<JurisdictionProfileList items={props.item.Jurisdiction} />
					</SectionContent>
				</Section>
			)}
		</RevocationProfileWrapper>
	) || null
}

const RevocationProfileWrapper = styled.div`
	
`;

const BasicInfo = styled(Section)`
	display: flex;

	& > a {
		margin-left: auto;
	}
`;