import React from 'react';
import { AggregateDataProfile } from '../../types/external';
import { Section, Heading, Body } from './profileStyles';
import { SectionLabel, InlineLabelledItem } from '../DetailPage/styles';
import DateRange from './DateRange';
import styled from 'styled-components/macro';
import JurisdictionProfileList from './JurisdictionProfile';
import { QuantitativeValueList } from './QuantitativeValue';
import { TabSetOrSingle } from './TabSelector';
import { DataSetProfileSingle } from './DataSetProfile';
import { formatPrice } from '../../utils';

export interface AggregateDataProfileSectionProps { item: AggregateDataProfile; }
export function AggregateDataProfileSection(props: AggregateDataProfileSectionProps) {

	return (
		<Section>
			<Heading>{props.item.Name || "Outcomes Data"}</Heading>
			<Body>
				{
					props.item.Description &&
					<Section>{props.item.Description}</Section>
				}
				{
					props.item.DemographicInformation &&
					<Section>
						<b>Demographic Information:</b> {props.item.DemographicInformation}
					</Section>
				}
				{
					props.item.FacultyToStudentRatio &&
					<Section>
						<b>Faculty To Student Ratio:</b> {props.item.FacultyToStudentRatio}
					</Section>
				}
				{
					(props.item.DateEffective || props.item.ExpirationDate) &&
					<Section>
						<DateRange
							FromDate={props.item.DateEffective}
							ToDate={props.item.ExpirationDate}
						/>
					</Section>
				}
				{
					( props.item.PostReceiptMonths ) &&
					<Section>
						<b>Post Receipt Months:</b> {props.item.PostReceiptMonths}
					</Section>
				}
				{
					(props.item.LowEarnings || props.item.MedianEarnings || props.item.HighEarnings) &&
					<Section>
						<SectionLabel>Earnings</SectionLabel>
						<EarningsDisplay
							CurrencySymbol={props.item.CurrencySymbol}
							MaxBarHeight={150}
							Values={[
								{ Label: "Lower Interquartile Earnings", Value: props.item.LowEarnings },
								{ Label: "Median Earnings", Value: props.item.MedianEarnings },
								{ Label: "Upper Interquartile Earnings", Value: props.item.HighEarnings }
							]}
						/>
					</Section>
				}
				{
					props.item.JobsObtainedList?.length > 0 &&
					<Section>
						<SectionLabel>Jobs Obtained</SectionLabel>
						<QuantitativeValueList
							items={props.item.JobsObtainedList}
						/>
					</Section>
				}
				{
					props.item.Jurisdiction?.length > 0 &&
					<Section>
						<SectionLabel>Jurisdiction Information</SectionLabel>
						<JurisdictionProfileList items={props.item.Jurisdiction} />
					</Section>
				}
				{
					props.item.RelevantDataSet?.length > 0 &&
					<Section>
						<SectionLabel>Details</SectionLabel>
						<TabSetOrSingle
							activeIndex={0}
							items={props.item.RelevantDataSet.map(
								(dataSetProfile, index) => (
									{
										Label: dataSetProfile.Name,
										Content: <DataSetProfileSingle item={dataSetProfile} />
									}
								)
							)}
						/>
					</Section>
				}
			</Body>
		</Section>
	) || "error"
}

export interface AggregateDataProfileListProps { items: Array<AggregateDataProfile>; }
export default function AggregateDataProfileList(props: AggregateDataProfileListProps) {
	console.log("Source Data", props.items)
	return (
		(props?.items?.length > 0) &&
		(
			<AggregateDataProfiles>
				{
					props.items.map((data) => (
						<AggregateDataProfileSection
							item={data}
						/>
					))
				}
			</AggregateDataProfiles>
		)
	) || null;
}

export interface EarningsDisplayProps {
	CurrencySymbol: string;
	Values: EarningsDisplayItemData[];
	MaxBarHeight: number;
}
export interface EarningsDisplayItemData {
	Label: string;
	Value?: number;
}
export function EarningsDisplay(props: EarningsDisplayProps) {
	var values = (props.Values || []).filter(m => m.Value != null && m.Value != undefined);
	const max = Math.max.apply(null, values.map(m => m.Value || 1));

	return ( values.length > 1 &&
		<EarningsDisplayWrapper>
			{
				values.map((data) => (
					<EarningsDisplayItem>
						<EarningsItemBar style={{ height: (((data.Value || 0) / max) * props.MaxBarHeight) + "px" }}></EarningsItemBar>
						<EarningsItemLabel>{data.Label}</EarningsItemLabel>
						<EarningsItemValue>{formatPrice(data.Value || 0, props.CurrencySymbol)}</EarningsItemValue>
					</EarningsDisplayItem>
				))
			}
		</EarningsDisplayWrapper>
	) || (
		<div><b>{values[0].Label}:</b> {formatPrice(values[0].Value || 0, props.CurrencySymbol)}</div>
	)
}

const AggregateDataProfiles = styled.div`
	
`;

const EarningsDisplayWrapper = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: space-between;
`;

const EarningsDisplayItem = styled.div`
	flex: 1 1 50%;
	margin: 0 10px;
	text-align: center;
`;

const EarningsItemBar = styled.div`
	background-color: ${(p) => p.theme.color.aquaDark};
	min-height: 5px;
`;

const EarningsItemLabel = styled.div`

`;

const EarningsItemValue = styled.div`

`;