import React from 'react';
import { AggregateDataProfile, DataSetProfile } from '../../types/external';
import PageSection from '../../components/PageSection';
import PageSectionItem from '../../components/PageSectionItem';
import { ModalButtonAndWindow } from '../../components/Modal';
import AggregateDataProfileList from '../../components/DetailPage/AggregateDataProfile';
import { DataSetProfileSingle } from '../../components/DetailPage/DataSetProfile';
import { Section, Label } from '../../components/DetailPage/styles';
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';


export function OutcomesDataPageSectionItem(props: {
	aggregateDataProfiles: Array<AggregateDataProfile>,
	dataSetProfiles: Array<DataSetProfile>,
	resourceTitle: string
}) {
	if ((props.aggregateDataProfiles?.length || 0) == 0 && (props.dataSetProfiles?.length || 0) == 0) {
		return null;
	}

	//Filter out External Data Set Profiles that are already referenced by an Aggregate Data Profile
	var dataSetProfileCTIDsReferencedByAggregateDataProfiles = (props.aggregateDataProfiles || [] as Array<AggregateDataProfile>).filter(adp => { return adp.RelevantDataSet?.length > 0 }).flatMap(adp => adp.RelevantDataSet).map(otherDSP => otherDSP.CTID);
	var externalDataSetProfilesSansAggregateDataProfileReferences = props.dataSetProfiles?.filter(dsp => { return !dataSetProfileCTIDsReferencedByAggregateDataProfiles.includes(dsp.CTID) }) || [];

	return (
		<PageSectionItem>
			<Label>Outcomes Data</Label>
			<ModalButtonAndWindow
				buttonLabel="Summary Outcomes Information"
				resourceTitle={props.resourceTitle}
				hideCount={true}
				items={props.aggregateDataProfiles}
				Wrapper={Section}
				Content={() => <AggregateDataProfileList items={props.aggregateDataProfiles} key="aggregate_" />}
			/>
			<ModalButtonAndWindow
				buttonLabel="Detailed Outcomes Information"
				resourceTitle={props.resourceTitle}
				hideCount={true}
				items={externalDataSetProfilesSansAggregateDataProfileReferences}
				Wrapper={Section}
				Content={() =>
					<TabSetOrSingle
						activeIndex={0}
						items={externalDataSetProfilesSansAggregateDataProfileReferences.map(
							(dataSetProfile, index) => (
								{
									Label: dataSetProfile.Name || ("Data Set " + (index + 1) + (dataSetProfile.Meta_LastUpdatedHeader ? ": " + dataSetProfile.Meta_LastUpdatedHeader : "")),
									Content: <DataSetProfileSingle item={dataSetProfile} />
								}
							)
						)}
					/>
				}
			/>
		</PageSectionItem>
	)
}