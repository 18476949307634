//Get something out of localStorage
export function getFromLocalStorage(propertyName) {
	return window.localStorage[propertyName] ? JSON.parse(window.localStorage[propertyName]) : null;
}
//

//Put something into localStorage
export function saveToLocalStorage(propertyName, value) {
	window.localStorage[propertyName] = JSON.stringify(value);
}
//

//Remove something from localStorage
export function deleteFromLocalStorage(propertyName) {
	delete window.localStorage[propertyName];
}
//

//Compare helper functions
export function addCompareItem(item) {
	console.log("Added Compare Item", item);
	var compareGraph = getCompareItems();
	if (compareGraph.find(m => m.CTID == item.CTID) == null) {
		compareGraph.push(item);
		saveCompareItems(compareGraph);
	}
}
//

export function removeCompareItem(item) {
	var compareGraph = getCompareItems();
	if (compareGraph.find(m => m.CTID == item.CTID) != null) {
		compareGraph = compareGraph.filter(m => m.CTID != item.CTID);
		saveCompareItems(compareGraph);
	}
}
//

export function getCompareItems() {
	//Keep it loaded to improve performance
	window.SiteCompareData = window.SiteCompareData || getFromLocalStorage("SiteCompareData") || [];
	return window.SiteCompareData;
}
//

export function saveCompareItems(compareGraph) {
	saveToLocalStorage("SiteCompareData", compareGraph);
	window.SiteCompareData = compareGraph;
	window.dispatchEvent(new Event("SiteCompareDataUpdated"));
}
//

export function getCompareItemsByType(type) {
	type = (type || "").toLowerCase();
	return getCompareItems().filter(m => m.BroadType?.toLowerCase() == type || m.CTDLType?.toLowerCase() == type);
}
//

export function getCompareItemByCTID(ctid) {
	return getCompareItems().find(m => m.CTID == ctid);
}
//

export function addOrUpdateCompareItem(item) {
	var items = getCompareItems();
	var match = items.find(m => m.CTID == item.CTID);
	match ? items.splice(items.indexOf(match), 1, item) : items.push(item);
	saveCompareItems(items);
}
//

export function updateStateOnCompareChange(updateStateMethod) {
	window.addEventListener("SiteCompareDataUpdated", updateStateMethod);
}
//

window.saveCompareItems = saveCompareItems;