import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import pageIcon from '../../assets/images/icons/icon-scoring-method-blue-green-01.svg';
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';
import Banner from '../../components/Banner';
import { WhiteLink } from '../../components/Banner/styles';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import LinkObject from '../../components/DetailPage/LinkObject';
import { LinkItem } from '../../components/DetailPage/Shared';
import OutlineList from '../../components/DetailPage/Outline';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import SubjectsAndKeywordsPageSection from '../../components/DetailPage/SubjectsAndKeywordsPageSection';
import OccupationsAndIndustriesSection from '../../components/DetailPage/OccupationsAndIndustriesSection';
import {
    Description,
    FullWidthWrapper,
    InnerWrapper,
    Label,
    Wrapper
} from '../../components/DetailPage/styles';
import HasAnyFilled from '../../components/HasAnyFilled';
import LoadingOrError from '../../components/LoadingOrError';
import { ModalButtonAndWindow } from '../../components/Modal';
import PageSection from '../../components/PageSection';
import PageSectionItem from '../../components/PageSectionItem';
import { VStack, HStack } from '../../components/Stack';
import { useGet, useMediaQuery } from '../../hooks';
import { apiUrl, legacySourceUrl } from '../../shared/globals';
import { theme } from '../../shared/theme';
import { Rubric, ConceptScheme, RubricLevel, RubricCriterion, CriterionLevel } from '../../types/external';
import { detailSectionProperties } from '../../utils';
import SearchPills from '../../components/SearchPills';
import { isFilled } from '../../validation';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import ConditionProfileList, { getConditionProfileListButtonName } from "../../components/DetailPage/ConditionProfile";
import ConnectionsSection from "../../components/DetailPage/ConnectionsSection";
import { ModalType } from '../../types/internal';
import DisplayPills from '../../components/PillDisplay';
import { Section, SectionLabel, SectionContent } from '../../components/DetailPage/Shared';
import { Inline, InlineLabel } from '../../components/DetailPage/styles';
import { Concept, convertDataToConceptScheme, convertDataToConcept } from '../ConceptSchemePage';



interface Props {
    id: string;
}

export default function RubricPage(props: RouteComponentProps<Props>) {
    const id = props.match.params.id;
    const [
        pageDataIsLoading,
        pageDataHasError,
        getPageData,
    ] = useGet();
    const [pageModel, setPageData] = useState({} as Rubric);
    const [openedModal, setOpenedModal] = useState<ModalType>();
    const [progressionModelData, setProgressionModelData] = useState({} as ConceptScheme);
    function onOpenModalClick(modalType: ModalType) {
        return () => setOpenedModal(modalType);
    }

    function onCloseModalClick() {
        setOpenedModal(undefined);
    }


    useEffect(() => {
        getPageData(`${apiUrl}/Rubric/${id}`, (data: Rubric) => {
            debugger;
            setPageData(data);
        });
    }, [getPageData, id]);

    //

    function getSubTitleLink() {
        if (pageModel.OwnedByLabel && pageModel.OwnedByLabel.Label) {
            return pageModel.OwnedByLabel.URL ?
                <WhiteLink href={pageModel.OwnedByLabel.URL}>{pageModel.OwnedByLabel.Label}</WhiteLink> :
                <>{pageModel.OwnedByLabel.Label}</>;
        }
        return null;
    }
    function TextItem(props: { label: string, item: string }) {
        return (props.item &&
            <Inline>
                <InlineLabel>{props.label}</InlineLabel>
                <div>{props.item}</div>
            </Inline>
        ) || null
    }
    function NumberItem(props: { label: string, item: number }) {
        return (props.item &&
            <Inline>
                <InlineLabel>{props.label}</InlineLabel>
                <div>{props.item}</div>
            </Inline>
        ) || null
    }
    function getLegacyLink() {
        if (pageModel.Meta_Id) {
            const url = legacySourceUrl + "/" + pageModel.BroadType + "/" + pageModel.Meta_Id;
            const mystyle = {
                //color: "rgba(0,0,0,.01)",
                color: "transparent",
                //backgroundColor: "rgba(0,0,0,.01)",
                marginLeft: "10px"
            };
            return <>
                <WhiteLink href={url} target="legacy" style={mystyle} title="View in services application">Legacy</WhiteLink>
            </>

        }
        return null;
    }
    const CompetencyCTID = styled.div`
	padding: 0 10px;
	font-size: 90%;
`;
    const styles: { [key: string]: React.CSSProperties } = {
        tableContainer: {
            borderCollapse: 'collapse',
        },
        headerCell: {
            border: '0.5px solid #ddd',
            padding: '4px',
            textAlign: 'left',
            verticalAlign: 'top',
            backgroundColor: '#DCFAF9',
            width: '550px',
            fontWeight: 'normal',

        },
        dataCell: {
            border: '1px solid #ddd',
            padding: '4px',
            textAlign: 'left',
            verticalAlign: 'top',
            width: '550px',
        },
        cell: {
            border: '1px solid #ddd', 
            padding: '4px',
            verticalAlign: 'top',
            textAlign: 'left',
        }
    };

    var typeLabel = widgetGetSingleLabelForText("Rubric");
    return (
        <LoadingOrError
            isLoading={pageDataIsLoading}
            hasError={pageDataHasError}>

            <Banner
                icon={pageIcon}
                fallBackIcon={pageIcon}
                type={pageModel.CTDLTypeLabel}
                title={pageModel.Name}
                subTitle={getSubTitleLink()}
                content={
                    <small>Last updated: {pageModel.Meta_LastUpdatedHeader} <span  >{getLegacyLink()}</span></small>
                }
            />
            <Wrapper>
                <VStack >
                    <HasAnyFilled
                        data={pageModel}
                        keys={detailSectionProperties.occupationAbout}>
                        <PageSection
                            icon={aboutIcon}
                            title={"About this " + typeLabel}
                            variant="Highlight"
                            description={"Basic information about this " + typeLabel}>

                            {pageModel.Description && (
                                <PageSectionItem>
                                    <Description>
                                        {pageModel.Description}
                                    </Description>
                                </PageSectionItem>
                            )}

                            {pageModel.SubjectWebpage &&
                                <PageSectionItem>
                                    <LinkObject item={{ URL: pageModel.SubjectWebpage, Label: "View this " + typeLabel }} />
                                </PageSectionItem>
                            }
                            {pageModel.LifeCycleStatusType && (
                                <PageSectionItem>
                                    <Label>Life Cycle Status</Label>
                                    <DisplayPills links={[pageModel.LifeCycleStatusType]} />
                                </PageSectionItem>
                            )}
                            {pageModel.InCatalog &&
                                <LinkObject item={{
                                    URL: pageModel.InCatalog, Label: "View this " + typeLabel + "in the Course Catalog "
                                }} />
                            }

                            {isFilled(pageModel.AudienceType) && (
                                <PageSectionItem>
                                    <Label>Audience Type</Label>
                                    <SearchPills links={pageModel.AudienceType} />
                                </PageSectionItem>
                            )}
                            {isFilled(pageModel.AudienceLevelType) && (
                                <PageSectionItem>
                                    <Label>Audience Level</Label>
                                    <SearchPills links={pageModel.AudienceLevelType} />
                                </PageSectionItem>
                            )}
                            {isFilled(pageModel.EducationLevelType
                            ) && (
                                    <PageSectionItem>
                                        <Label>Education Level Type</Label>
                                        <SearchPills links={pageModel.EducationLevelType} />
                                    </PageSectionItem>
                                )}
                            {isFilled(pageModel.DeliveryType) && (
                                <PageSectionItem>
                                    <Label>Delivery Type</Label>
                                    <SearchPills links={pageModel.DeliveryType} />
                                </PageSectionItem>
                            )}
                            {isFilled(pageModel.EvaluatorType) && (
                                <PageSectionItem>
                                    <Label>Evaluator Type</Label>
                                    <SearchPills links={pageModel.EvaluatorType} />
                                </PageSectionItem>
                            )}
                            {(pageModel.LatestVersion?.URL || pageModel.PreviousVersion?.URL || pageModel.NextVersion?.URL) &&
                                <PageSectionItem>
                                    <Label>Versions</Label>
                                    <PageSectionItem>
                                        <LinkItem label="Latest Version:" item={pageModel.LatestVersion} />
                                        <LinkItem label="Previous Version:" item={pageModel.PreviousVersion} />
                                        <LinkItem label="Next Version:" item={pageModel.NextVersion} />
                                    </PageSectionItem>
                                </PageSectionItem>
                            }

                        </PageSection>
                    </HasAnyFilled>

                </VStack>

                <VStack>

                    <RelatedOrganizationPageSection item={pageModel} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " that create and publish the " + typeLabel} />
                    <HasAnyFilled
                        data={pageModel}
                        keys={[
                            ...detailSectionProperties.entityConnections
                        ]}>
                        <PageSection icon={connectionsIcon} title="Connections" description="Related Resources">
                            <PageSectionItem>
                                <ModalButtonAndWindow
                                    buttonLabel={"Has " + (pageModel.HasCriterionCategorySet?.Total || 0) + " CriterionCategorySet" + ((pageModel.HasCriterionCategorySet?.Values?.length || 0) == 1 ? "" : "ren")}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.HasCriterionCategorySet?.Values}
                                    Wrapper={Section}
                                    Content={() => <OutlineList items={pageModel.HasCriterionCategorySet?.Values} />}
                                    hideCount={true}
                                />
                                <ModalButtonAndWindow
                                    buttonLabel={(pageModel.TargetOccupation?.Total || 0) + " Target Occupation" + ((pageModel.TargetOccupation?.Values?.length || 0) == 1 ? "" : "s")}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.TargetOccupation?.Values}
                                    Wrapper={Section}
                                    Content={() => <OutlineList items={pageModel.TargetOccupation?.Values} />}
                                    hideCount={true}
                                />
                                <ModalButtonAndWindow
                                    buttonLabel={(pageModel.Classification?.Total || 0) + " Classification" + ((pageModel.Classification?.Values?.length || 0) == 1 ? "" : "s")}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.Classification?.Values}
                                    Wrapper={Section}
                                    Content={() => <OutlineList items={pageModel.Classification?.Values} />}
                                    hideCount={true}
                                />
                                <ModalButtonAndWindow
                                    buttonLabel={(pageModel.DerivedFrom?.Total || 0) + " DerivedFrom" + ((pageModel.DerivedFrom?.Values?.length || 0) == 1 ? "" : "s")}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.DerivedFrom?.Values}
                                    Wrapper={Section}
                                    Content={() => <OutlineList items={pageModel.DerivedFrom?.Values} />}
                                    hideCount={true}
                                />
                                <ConnectionsSection
                                    item={pageModel}
                                    openedModal={openedModal}
                                    onOpenModalClick={onOpenModalClick}
                                    onCloseModalClick={onCloseModalClick}
                                />
                            </PageSectionItem>
                        </PageSection>
                    </HasAnyFilled>

                    {pageModel.HasScope && (
                        <PageSectionItem>
                            <Description>
                                <b>Has Scope:</b> {pageModel.HasScope}
                            </Description>
                        </PageSectionItem>
                    )}
                </VStack>

            </Wrapper>
            <FullWidthWrapper>
                <TabSetOrSingle
                    activeIndex={0}
                    items={[
                        {
                            Label: "Rubric",
                            Content: (
                                <div style={styles.tableContainer}>
                                    <table style={styles.table}>
                                        <thead>
                                            <tr>
                                                <th style={styles.headerCell}></th>
                                                {pageModel?.RubricLevels?.map((level, index) => (
                                                    <th key={index} style={styles.headerCell} title="Rubric Level">
                                                        {/*<CompetencyCTID title="Type">*/}
                                                        {/*    <b>Type:</b> Rubric Level*/}
                                                        {/*</CompetencyCTID>*/}
                                                        {level.Name && (
                                                            <CompetencyCTID>
                                                              {level.Name}
                                                            </CompetencyCTID>
                                                        )}
                                                        {level.Description && (
                                                            <CompetencyCTID title="Description">
                                                               {level.Description}
                                                            </CompetencyCTID>
                                                        )}
                                                        {level.ListID && (
                                                            <CompetencyCTID title="ListID">
                                                                <b>List ID:</b> {level.ListID}
                                                            </CompetencyCTID>
                                                        )}
                                                        {level.CodedNotation && (
                                                            <CompetencyCTID title="CodedNotation">
                                                                <b>Coded Notation:</b> {level.CodedNotation}
                                                            </CompetencyCTID>
                                                        )}
                                                        {level.HasProgressionLevel?.Values?.length > 0 && <CompetencyCTID title="HasProgressionLevel"><b>Has Progression Level:</b>
                                                            <a href={level.HasProgressionLevel?.Values[0].URL}>{level.HasProgressionLevel?.Values[0].Label}</a>
                                                        </CompetencyCTID>}
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pageModel?.RubricCriterions?.map((criterion, rowIndex) => (
                                                <tr key={rowIndex} title="Rubric Criterion">
                                                    <th style={styles.headerCell}>
                                                        {/*<CompetencyCTID title="Type">*/}
                                                        {/*    <b>Type:</b> Rubric Criterion*/}
                                                        {/*</CompetencyCTID>*/}
                                                        {criterion.Name && (
                                                            <CompetencyCTID >
                                                               {criterion.Name}
                                                            </CompetencyCTID>
                                                        )}
                                                        {criterion.Description && (
                                                            <CompetencyCTID title="Description">
                                                                {criterion.Description}
                                                            </CompetencyCTID>
                                                        )}
                                                        {criterion.CTID && (
                                                            <CompetencyCTID title="CTID">
                                                                <b>CTID:</b>
                                                                <i>{criterion.CTID}</i>
                                                            </CompetencyCTID>
                                                        )}
                                                        {criterion.ListID && (
                                                            <CompetencyCTID title="ListID">
                                                                <b>List ID:</b> {criterion.ListID}
                                                            </CompetencyCTID>
                                                        )}
                                                        {criterion.CodedNotation && (
                                                            <CompetencyCTID title="CodedNotation">
                                                                <b>Coded Notation:</b> {criterion.CodedNotation}
                                                            </CompetencyCTID>
                                                        )}
                                                        {criterion.Weight && (
                                                            <CompetencyCTID title="Weight">
                                                                <b>Weight:</b> {criterion.Weight}
                                                            </CompetencyCTID>
                                                        )}
                                                        {criterion.HasProgressionLevel?.Values?.length > 0 && <CompetencyCTID title="HasProgressionLevel"><b>Has Progression Level: </b>
                                                            <a href={criterion.HasProgressionLevel?.Values[0].URL}> {criterion.HasProgressionLevel?.Values[0].Label}</a>
                                                        </CompetencyCTID>}
                                                        {criterion.TargetTask?.Values?.length > 0 && (
                                                            <CompetencyCTID title="TargetTask">
                                                                <b>TargetTask:</b>
                                                                <ul>
                                                                    {criterion.TargetTask?.Values.map((targettask, index) => (
                                                                        <li key={index}>
                                                                            <a href={targettask.URL}>{targettask.Label}</a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </CompetencyCTID>
                                                        )}
                                                        {criterion.TargetCompetency?.Values?.length > 0 && (
                                                            <CompetencyCTID title="TargetCompetency">
                                                                <b>TargetCompetency:</b>
                                                                <ul>
                                                                    {criterion.TargetCompetency?.Values.map((targettask, index) => (
                                                                        <li key={index}>
                                                                            <a href={targettask.URL}>{targettask.Label}</a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </CompetencyCTID>
                                                        )}
                                                    </th>
                                                 
                                                    {pageModel?.RubricLevels?.map((level, colIndex) => (
                                                        <td key={colIndex} style={styles.cell} title="Criterion Level">
                                                            {level?.HasCriterionLevel?.map(criterionLevel => (
                                                                criterion?.HasCriterionLevel?.some(c => c.RowId === criterionLevel.RowId) && (
                                                                    <div key={criterionLevel.RowId}>
                                                                        {/*<CompetencyCTID title="Type">*/}
                                                                        {/*    <b>Type:</b> Criterion Level*/}
                                                                        {/*</CompetencyCTID>*/}
                                                                        {criterionLevel.BenchmarkLabel && (
                                                                            <CompetencyCTID title="BenchmarkLabel">
                                                                                {criterionLevel.BenchmarkText ? (
                                                                                    <b>{criterionLevel.BenchmarkLabel}</b>
                                                                                ) : (
                                                                                    criterionLevel.BenchmarkLabel
                                                                                )}
                                                                                
                                                                            </CompetencyCTID>
                                                                        )}
                                                                        {criterionLevel.BenchmarkText && (
                                                                            <CompetencyCTID title="BenchmarkText">
                                                                               {criterionLevel.BenchmarkText}
                                                                            </CompetencyCTID>
                                                                        )}
                                                                        {criterionLevel.ListID && (
                                                                            <CompetencyCTID title="ListID">
                                                                                <b>List ID:</b> {criterionLevel.ListID}
                                                                            </CompetencyCTID>
                                                                        )}
                                                                        {criterionLevel.CodedNotation && (
                                                                            <CompetencyCTID title="CodedNotation">
                                                                                <b>Coded Notation:</b> {criterionLevel.CodedNotation}
                                                                            </CompetencyCTID>
                                                                        )}
                                                                        {criterionLevel.Feedback && (
                                                                            <CompetencyCTID title="Feedback">
                                                                                <b>Feedback:</b> {criterionLevel.Feedback}
                                                                            </CompetencyCTID>
                                                                        )}
                                                                        {criterionLevel.Value > 0 && (
                                                                            <CompetencyCTID title="Value">
                                                                                <b>Value:</b> {criterionLevel.Value}
                                                                            </CompetencyCTID>
                                                                        )}
                                                                        {criterionLevel.MaxValue > 0 && (
                                                                            <CompetencyCTID title="MaxValue">
                                                                                <b>Max Value:</b> {criterionLevel.MaxValue}
                                                                            </CompetencyCTID>
                                                                        )}
                                                                        {criterionLevel.MinValue > 0 && (
                                                                            <CompetencyCTID title="MinValue">
                                                                                <b>Min Value:</b> {criterionLevel.MinValue}
                                                                            </CompetencyCTID>
                                                                        )}
                                                                        {criterionLevel.Percentage > 0 && (
                                                                            <CompetencyCTID title="Percentage">
                                                                                <b>Percentage:</b> {criterionLevel.Percentage}
                                                                            </CompetencyCTID>
                                                                        )}
                                                                        {criterionLevel.MaxPercentage > 0 && (
                                                                            <CompetencyCTID title="MaxPercentage">
                                                                                <b>Max Percentage:</b> {criterionLevel.MaxPercentage}
                                                                            </CompetencyCTID>
                                                                        )}
                                                                        {criterionLevel.MinPercentage > 0 && (
                                                                            <CompetencyCTID title="MinPercentage">
                                                                                <b>Min Percentage:</b> {criterionLevel.MinPercentage}
                                                                            </CompetencyCTID>
                                                                        )}

                                                                    </div>
                                                                )
                                                            ))}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>


                                )
                        },
                        {
                            Label: "Details",
                            Content: (
                                <InnerWrapper>
                                    <VStack>
                                        <AdditionalInformationPageSection item={pageModel} pageSectionDescription="Additional information about the Pathway" />
                                    </VStack>
                                    <VStack>
                                        <SubjectsAndKeywordsPageSection item={pageModel} pageSectionDescription={"Type of material covered by the " + typeLabel} />
                                        <OccupationsAndIndustriesSection item={pageModel} pageSectionDescription="Career Field information about the Rubric" />
                                    </VStack>

                                </InnerWrapper>
                            )
                        }
                    ].filter(m => m.Content != null)}
                />
            </FullWidthWrapper>

        </LoadingOrError>
    );
}


//
