import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';
import { Button } from '../../components/common';
import { HStack } from '../../components/Stack';
import { widgetGetColor } from '../../utils/SiteWidgetizer';

export const Wrapper = styled.div`
    max-width: ${(props) => props.theme.maxWidth};
`;

export const TopRegion = styled.div`
    background-color: ${(p) => p.theme.color.greyLight};
    padding: 40px 100px;
    @media (max-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        padding: 20px;
    }
`;

export const ResultsSection = styled(HStack)`
    position: relative;
    justify-content: center;
`;

export const ResultsSectionBody = styled.div`
    width: 100%;
    padding: 0 20px;
`;

export const ResultList = styled.div`
    max-width: 1140px;
    width: 100%;
`;

export const ResultItem = styled.div`
    padding: 2rem 0;
	transition: background-color 0.25s;

    &:not(:last-child) {
        border-bottom: 1px solid ${(p) => widgetGetColor("SiteHeader", false, p.theme.color.aquaDark)};
    }

	&.highlighted {
		background-color: #5F7;
	}
`;

export const ResultBodytHeader = styled(HStack)`
    justify-content: space-between;
    align-items: center;
    margin: auto;
    border-bottom: 4px solid ${(props) => widgetGetColor("SiteHeader", false, props.theme.color.aquaDark)};
    flex-wrap: wrap;
    max-width: 1140px;
`;

export const ResultListHeaderSection = styled(HStack)`
    align-items: center;
    flex-wrap: wrap;

    > * {
        margin-bottom: 10px;
    }
`;

export const ResultBodyContent = styled(HStack)`
    border-top: 1px solid ${(props) => widgetGetColor("SiteHeader", false, props.theme.color.aquaDark)};
    justify-content: center;
    @media (max-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        flex-wrap: wrap;
    }
`;

export const FilterButton = styled(Button)`
    min-width: 80px;
    color: ${(props) => widgetGetColor("SearchResultButton", true, props.theme.color.aquaDark)};
	background-color: ${(props) => widgetGetColor("SearchResultButton", false, "")};
`;

export const ButtonIcon = styled(FontAwesomeIcon)`
    padding: 0 5px;
`;

export const IconButton = styled(Button)`
    flex: 0 1 auto;
    min-width: 80px;
    padding: 0.2em;
`;

export const ExportButton = styled(Button)`
    flex: 0 1 auto;
    min-width: 80px;
    padding: 0.2em;
	height: 40px;
`;

export const DropdownSection = styled.div<{ minWidth?: string }>`
    flex: 0 1 auto;
    min-width: ${(props) => props.minWidth || '120px'};
    @media (max-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        flex: 1;
    }
`;

export const DropdownGroup = styled(HStack)`
    @media (max-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        flex-basis: 100%; // force wrap
    }
`;

export const GroupButton = styled(HStack)`
    flex: 0 1 auto;
`;

export const FilterPills = styled(HStack)`
    padding: 10px;
    flex-wrap: wrap;
    > * {
        margin-bottom: 5px;
    }
    max-width: 1140px;
    margin: auto;
`;

export const PillContent = styled.p`
    margin: 0;
`;

export const ResultCount = styled.h2`
    padding-right: 20px;
    @media (max-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        max-width: 200px;
    }
`;

export const LoadingIndicatorWrapper = styled.div`
    text-align: center;
    padding: 50px;
`;

export const ResultListItems = styled.div``;

export const MapWrapper = styled.div`
    padding: 20px 0px 20px 20px;
    width: 100%;
    height: 100vh;
	max-height: 1000px;
    position: sticky;
    top: 0;
    order: 2;
    @media (max-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        order: 0;
        padding: 20px 0;
        position: static;
        height: 350px;
    }
`;
