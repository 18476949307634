import React, { ReactNode, useState } from 'react';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { theme } from '../../shared/theme';
import { useMediaQuery } from '../../hooks';
import {
    Wrapper,
    Icon,
	HeaderWithNote,
	HeaderWithNoteContent,
	HeaderNote,
    Title,
    MobileIcon,
    MobileText,
    MobileHeader,
    MobilePanel,
    MobilePanelHeader,
    MobilePanelTitle,
    MobilePanelContent,
    MobileBorder,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type Variant = 'Highlight' | 'Shadow' | 'Plain';

export interface Props {
    icon: string;
    title: string;
    children: ReactNode;
	variant?: Variant;
	description?: string;
}

export default function PageSection({ icon, title, variant, children, description }: Props) {
    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });
    const [panelIsClosed, setPanelIsClosed] = useState(true);

    function openOrClosePanel() {
        setPanelIsClosed(!panelIsClosed);
    }

    if (isMobile) {
        if (panelIsClosed) {
            return (
                <MobileHeader variant={variant} onClick={openOrClosePanel}>
                    <MobileIcon src={icon} />
                    <MobileText>
                        <div>{title}</div>
                        <FontAwesomeIcon icon={faAngleRight} size="lg" />
                    </MobileText>
                    <MobileBorder />
                </MobileHeader>
            );
        }
        return (
            <MobilePanel variant={variant}>
                <MobilePanelHeader onClick={openOrClosePanel}>
                    <FontAwesomeIcon
                        icon={faAngleLeft}
                        size="lg"
                        color={theme.color.aquaDark}
                    />
                    <MobilePanelTitle>{title}</MobilePanelTitle>
                </MobilePanelHeader>
                <MobilePanelContent>{children}</MobilePanelContent>
            </MobilePanel>
        );
    }

    return (
        <Wrapper variant={variant}>
            <HeaderWithNote>
				<Icon alt="" src={icon} />
				<HeaderWithNoteContent>
					<Title>{title}</Title>
					{description && <HeaderNote>{description}</HeaderNote>}
				</HeaderWithNoteContent>
			</HeaderWithNote>
            {children}
        </Wrapper>
    );
}
