import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const PillWrapper = styled.div`
    margin: 5px;
`;
