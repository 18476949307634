import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

interface Props {
	items: TabItem[];
	activeIndex: number;
}

export interface TabItem {
	Label: string;
	Content: JSX.Element | null;
}

export function TabSetOrSingle(props: Props) {
	var validItems = props.items?.filter(m => m != null && m.Content != null) || [];
	if (validItems.length == 1) {
		return (
			<>{validItems[0].Content}</>
		)
	}
	else if (validItems.length > 1) {
		return (
			<TabSet
				items={validItems}
				activeIndex={props.activeIndex}
			/>
		)
	}
	else {
		return null;
	}
}

export default function TabSet(props: Props) {
	const [activeIndex, setActiveIndex] = useState(props.activeIndex);

	function switchTab(index: number) {
		setActiveIndex(index);
	}

	useEffect(() => {
		setActiveIndex(props.activeIndex || 0);
	}, [props.activeIndex]);

	return (
		<TabSetWrapper>
			<TabButtonsWrapper>
				<TabButtons>
					{
						props.items.map((item, index) =>
							<TabButton
								onClick={() => switchTab(index)}
								className={index == activeIndex ? "currentTab" : ""}
								key={'tab-button-' + index}
							>
								{item.Label}
							</TabButton>
						)
					}
				</TabButtons>
			</TabButtonsWrapper>
			<TabContents>
				{props.items.map((item, index) =>
					<Tab className={activeIndex == index ? "currentTab" : ""}>
						{item.Content}
					</Tab>
				)}
			</TabContents>
		</TabSetWrapper>
	)
}

const TabSetWrapper = styled.div`
	
`;

const TabButtonsWrapper = styled.div`
	overflow-x: auto;
	margin-bottom: 10px;
`;

const TabButtons = styled.div`
	display: flex;
	align-items: stretch;
	border-bottom: 1px solid ${(p) => p.theme.color.aquaDark};
`;

const TabButton = styled.button`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	font-size: 16px;
	padding: 12px;
	margin: 0 5px;
	min-width: 150px;
	align-items: center;
	background-color: ${(p) => p.theme.color.aquaLight};
	border: none;
	color: ${(p) => p.theme.color.blueDark};
	font-weight: 500;
	border-radius: 3px 3px 0 0;

	& :hover, & :focus {
		background-color: ${(p) => p.theme.color.aquaDark};
	}

	&.currentTab {
		border-bottom: 2px solid #000;
		margin-bottom: -1px;
		text-shadow: 0 0 #000;
		padding-bottom: 11px;
		background-color: ${(p) => p.theme.color.aquaDark};
	}

`;

const TabContents = styled.div`
	position: relative;
`;

//Have to use weird transform tricks instead of display: none because react will wipe out divs in hidden tabs otherwise
const Tab = styled.div`
	&:not(.currentTab) {
		position: absolute;
		top: 0;
		left: 0;
		transform: rotateY(90deg);
		transform-origin: left center;
	}
`;