import React from 'react';
import { DurationProfile as DurationProfileType } from '../../types/external';
import TruncatedItemList from '../DetailPage/TruncatedItemList';
import styled from 'styled-components/macro';

export default function DurationProfileList(props: { items: DurationProfileType[], truncateAfter?: number }) {
    return ( props.items?.length > 0 &&
		<TruncatedItemList
			source={props.items}
			renderItemWith={(item, index) => {
				return (
					<DurationProfile item={item} key={`cr-duration-${index}`} />
				)
			}}
			truncateAfter={props.truncateAfter || 5}
		/>
    ) || null
}

export function DurationProfile(props: { item: DurationProfileType }) {
	return (props.item &&
		<DurationProfileWrapper>
		{ props.item.Description?.length > 0 &&
			<div>{props.item.Description}</div>
		}
		{props.item.DurationSummary?.length > 0 &&
			<div>
				<b>Estimated time to complete: </b>
				{props.item.DurationSummary}
			</div>
		}			
		</DurationProfileWrapper>
	) || null
}

const DurationProfileWrapper = styled.div`

`;