import React from 'react';
import styled from 'styled-components/macro';

export interface Props {
	FromDate?: string;
	ToDate?: string;
	BothDatesPreText?: string;
	BothDatesMidText?: string;
	FromOnlyPreText?: string;
	ToOnlyPreText?: string;
}
export default function DateRange(props: Props) {
	//Can't set default values on props directly because TS complains, so use some extra variables
	var bothDatesPreText = props.BothDatesPreText || "Data Set Start Date";
	var bothDatesMidText = props.BothDatesMidText || "Data Set End Date";
	var fromOnlyPreText = props.FromOnlyPreText || "Data Set Start Date";
	var toOnlyPreText = props.ToOnlyPreText || "Data Set End Date";

	return (
		(props.FromDate && props.ToDate && <DateRangeSet><b>{bothDatesPreText}</b><span>{props.FromDate}</span><b>{bothDatesMidText}</b><span>{props.ToDate}</span></DateRangeSet>) ||
		(props.FromDate && !props.ToDate && <DateRangeSet><b>{fromOnlyPreText}</b><span>{props.FromDate}</span></DateRangeSet>) ||
		(!props.FromDate && props.ToDate && <DateRangeSet><b>{toOnlyPreText}</b><span>{props.ToDate}</span></DateRangeSet>) ||
		null
	)
}

const DateRangeSet = styled.div`
	display: flex;
	flex-wrap: wrap;

	& > *:not(:last-child) {
		margin-right: 0.8em;
	}
`;