import React, { ReactNode, useRef, useState, useEffect } from 'react';
import styled, { DefaultTheme, StyledComponent } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Wrapper, StyledModal, Close, Heading, HeadingText, SubHeading, Body } from './styles';
import { Button } from '../common';
import { englishOrNull } from '../GetResourceByURI';
import { postData } from '../MiniSearch';
import { apiUrl, appURL } from '../../shared/globals';
import ActivityIndicator from '../ActivityIndicator';
import { Outline as OutlineType } from '../../types/external';
import { Outline } from '../DetailPage/Outline';
import { getResourceByURLWithoutState } from '../GetResourceByURI';
import { useGet } from '../../hooks';


export interface ModalButtonAndWindowProps {
	items: any[];
	buttonLabel: string;
	resourceTitle: string;
	modalTitle?: string;
	hideCount?: boolean;
	Content: (loadedData?: any) => JSX.Element;
	Wrapper?: StyledComponent<any, DefaultTheme, {}, never> | undefined
	ButtonContent?: () => JSX.Element;
	onButtonClick?: () => void;
	getDataOnClickFromURL?: string;
	overrideCount?: number;
}
export function ModalButtonAndWindow(props: ModalButtonAndWindowProps) {
	var OuterWrapper = props.Wrapper || ModalButtonWrapper;
	const [isOpen, setIsOpen] = useState(false);
	const [loadedData, setLoadedData] = useState(null as any);
	const [isLoading, hasError, get] = useGet();

	function clickInsideModal(e: React.MouseEvent<HTMLElement>) {
		e.stopPropagation();
	}

	function loadData() {
		if (props.getDataOnClickFromURL) {
			get(props.getDataOnClickFromURL, (result: any) => {
				setLoadedData(result);
			});
		}
	}

	return (props.items?.length > 0 || props.getDataOnClickFromURL || loadedData) && (
		<OuterWrapper>
			<InvisibleButton onFocus={() => setIsOpen(false)}>Close modal window</InvisibleButton>
			<Button inline onClick={() => { setIsOpen(!isOpen); props.onButtonClick?.(); loadData(); }} style={props.ButtonContent ? { padding: "1px" } : {}}>
				{props.ButtonContent && <props.ButtonContent />}
				{!props.ButtonContent && (props.buttonLabel + (props.hideCount ? "" : " (" + (props.overrideCount || props.items.length) + ")"))}
			</Button>
			{isOpen &&
				<Wrapper onClick={() => setIsOpen(false)}>
					<StyledModal onClick={clickInsideModal}>
						<Heading>
							<HeadingText>{props.resourceTitle}</HeadingText>
							<Close onClick={() => setIsOpen(false)} aria-label={"Close modal window"}>
								<FontAwesomeIcon size={'2x'} icon={faTimes} />
							</Close>
						</Heading>
						<SubHeading>
							{props.modalTitle || props.buttonLabel}
						</SubHeading>
						<Body>
							{props.getDataOnClickFromURL && !loadedData && <ActivityIndicator />}
							{(loadedData || !props.getDataOnClickFromURL) && <props.Content loadedData={loadedData} />}
						</Body>
					</StyledModal>
				</Wrapper>
			}
			<InvisibleButton onFocus={() => setIsOpen(false)}>Close modal window</InvisibleButton>
		</OuterWrapper>
	) || null
}

export interface ModalButtonAndWindowWithRegistrySearchProps extends ModalButtonAndWindowProps {
	ctdlQuery: any,
	ResultRenderer?: (data: any) => JSX.Element,
	hasResultsHandler?: () => void,
	noResultsHandler?: () => void
}
export function ModalButtonAndWindowWithRegistrySearch( props: ModalButtonAndWindowWithRegistrySearchProps ) {
	return <ModalButtonAndWindowWithSearch
		items={props.items}
		buttonLabel={props.buttonLabel}
		resourceTitle={props.resourceTitle}
		Content={props.Content}
		queryURL={apiUrl + "/detail/registrysearch"}
		ResultRenderer={props.ResultRenderer}
		getQuery={(skip: number, take: number) => {
			return {
				Query: props.ctdlQuery,
				Skip: skip,
				Take: take,
				UseBetaAPI: true
			};
		}}
		getResultsFromResponse={(response: any) => {
			return response.Result.data;
		}}
		getTotalResultsFromResponse={(response: any) => {
			var total = response.Result.total;
			if (total == 0) {
				props.noResultsHandler?.();
			}
			else {
				props.hasResultsHandler?.();
			}
			return total;
		}}
	/>
}

export interface ModalButtonAndWindowWithSearchProps extends ModalButtonAndWindowProps {
	getQuery: (skip: number, take: number) => any,
	getResultsFromResponse: (response: any) => Array<any>,
	getTotalResultsFromResponse: (response: any) => number,
	queryURL: string,
	ResultRenderer?: (data: any) => JSX.Element
}
export function ModalButtonAndWindowWithSearch( props: ModalButtonAndWindowWithSearchProps ) {
	var OuterWrapper = props.Wrapper || ModalButtonWrapper;
	const [isOpen, setIsOpen] = useState(false);
	const [searchState, setSearchState] = useState({
		TotalResults: 0,
		Skip: 0,
		Take: 10,
		Busy: false,
		AllowSearch: true,
		Error: null as any
	});
	const [loadedResults, setLoadedResults] = useState([] as Array<any>);
	const [renderTrick, setRenderTrick] = useState({ render: true });
	var scrollTimer = null as any;

	function clickInsideModal(e: React.MouseEvent<HTMLElement>) {
		e.stopPropagation();
	}

	var ResultRenderer = props.ResultRenderer || SimpleResult;

	function doSearch() {
		console.log("search entered");
		if (!searchState.AllowSearch) {
			return;
		}

		console.log("search begins");
		searchState.Busy = true;
		searchState.AllowSearch = false;
		refreshSearchStateAndResults();
		postData(
			props.queryURL,
			props.getQuery(searchState.Skip, searchState.Take),
			(response: any) => {
				setLoadedResults(loadedResults.concat(props.getResultsFromResponse(response)));
				searchState.TotalResults = props.getTotalResultsFromResponse(response);
				searchState.Skip += searchState.Take;
				searchState.Busy = false;
				if (searchState.Skip < searchState.TotalResults) {
					setTimeout(() => {
						searchState.AllowSearch = true; //Allow another search if there is another page of results to get
						refreshSearchStateAndResults();
					}, 200);
				}
				refreshSearchStateAndResults();
			},
			(error: any) => {
				searchState.Error = error;
				refreshSearchStateAndResults();
			}
		);
	}

	useEffect(() => {
		doSearch();
	}, []);

	function handleScroll(e: any) {
		console.log("scroll");
		if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 250) {
			console.log("triggered");
			clearTimeout(scrollTimer);
			scrollTimer = setTimeout(() => {
				console.log("doing search");
				doSearch();
			}, 250);
		}
	}

	function refreshSearchStateAndResults() {
		setSearchState(searchState);
		setRenderTrick({ render: !renderTrick.render });
	}

	return searchState.TotalResults > 0 && (
		<OuterWrapper data-render={renderTrick}>
			{searchState.TotalResults > 0 && 
				<Button inline onClick={() => setIsOpen(!isOpen)}>
					{props.buttonLabel + (props.hideCount ? "" : " (" + searchState.TotalResults + ")")}
				</Button>
			}
			{isOpen &&
				<Wrapper onClick={() => setIsOpen(false)}>
					<StyledModal onClick={clickInsideModal}>
						<Heading>
							<HeadingText>{props.resourceTitle}</HeadingText>
							<Close onClick={() => setIsOpen(false)} aria-label={"Close modal window for '" + props.resourceTitle + "'"}>
								<FontAwesomeIcon size={'2x'} icon={faTimes} />
							</Close>
						</Heading>
						<SubHeading>
							{searchState.TotalResults + " " + (props.modalTitle || props.buttonLabel)}
						</SubHeading>
						<Body onScroll={handleScroll}>
							{loadedResults.map((result, index) => (
								<ResultRenderer data={result} key={"result-" + index} />
							))}
							<ActivityWrapper>
								{searchState.Busy && <ActivityIndicator />}
							</ActivityWrapper>
						</Body>
					</StyledModal>
				</Wrapper>
			}
		</OuterWrapper>
	) || null
}

export function SimpleResult(props: { data: any }) {
	var type = props.data["@type"];
	var ctid = props.data["ceterms:ctid"] || "";
	var url = appURL + "/resources/" + ctid;
	if (type == "ceasn:Competency") {
		var host = props.data["ceasn:isPartOf"] || props.data["ceterms:isMemberOf"];
		url = appURL + "/resources/" + (Array.isArray(host) ? host[0] : host) + "?competencyCTID=" + ctid;
	}
	var name = englishOrNull(props.data["ceterms:name"]) || englishOrNull(props.data["ceasn:name"]) || englishOrNull(props.data["skos:prefLabel"]) || englishOrNull(props.data["ceasn:competencyLabel"]) || "";
	var description = englishOrNull(props.data["ceterms:description"]) || englishOrNull(props.data["ceasn:description"]) || englishOrNull(props.data["skos:definition"]) || englishOrNull(props.data["ceasn:competencyText"]) || "";
	return (
		<SimpleResultWrapper>
			{name && <SimpleName target="_blank" href={url}>{name}</SimpleName>}
			{description && <SimpleDescription>{description}</SimpleDescription>}
		</SimpleResultWrapper>
	)
}

export function OutlineResult(props: { data: any }) {
	const [provider, setProvider] = useState({} as OutlineType);
	var ctid = props.data["ceterms:ctid"] || "";
	var url = appURL + "/resources/" + ctid;
	var name = englishOrNull(props.data["ceterms:name"]) || englishOrNull(props.data["ceasn:name"]) || englishOrNull(props.data["skos:prefLabel"]) || englishOrNull(props.data["ceasn:competencyLabel"]) || "";
	var description = englishOrNull(props.data["ceterms:description"]) || englishOrNull(props.data["ceasn:description"]) || englishOrNull(props.data["skos:definition"]) || englishOrNull(props.data["ceasn:competencyText"]) || "";
	var image = props.data["ceterms:image"] || "";
	var providerURLRaw = [props.data["ceterms:ownedBy"], props.data["ceterms:offeredBy"], props.data["ceasn:creator"], props.data["ceasn:publisher"], [""]].filter(m => m && m.length > 0)[0] || [""];
	var providerURL = Array.isArray(providerURLRaw) ? providerURLRaw[0] : providerURLRaw;

	useEffect(() => {
		if (providerURL && providerURL.length > 0) {
			getResourceByURLWithoutState(providerURL, null, (response: any) => {
				setProvider({
					Label: englishOrNull(response["ceterms:name"]),
					URL: appURL + "/resources/" + response["ceterms:ctid"]
				} as OutlineType);
			});
		}
	}, []);

	var item = {
		Label: name,
		Description: description,
		Image: image,
		URL: url,
		Provider: provider
	} as OutlineType;
	
	return (
		<Outline item={item} />
	)
}

const SimpleResultWrapper = styled.div`
	padding: 10px 0;
	border-top: 1px solid #CCC;
`;

const SimpleName = styled.a`
	display: block;
	font-weight: bold;
`;

const SimpleDescription = styled.div`
	padding: 0 5px;
`;

const ActivityWrapper = styled.div`
	min-height: 100px;
	padding: 10px;
	text-align: center;
`;

const ModalButtonWrapper = styled.div`
	margin-bottom: 10px;
`;

const InvisibleButton = styled.button`
	opacity: 0;
	height: 0;
	overflow: hidden;
	margin: 0;
	padding: 0;
	width: 0;
	border: none;
`;