import React from 'react';
import { VerificationServiceProfile as ProfileType } from '../../types/external';
import VerificationServiceProfile from '../VerificationServiceProfile';

interface Props {
    profiles: Array<ProfileType>;
}

export default function Profiles({ profiles }: Props) {
    if (profiles == null || profiles.values == null) {
        return null;
    }
    return (
        <div>
            {profiles.map((profile, index) => (
                <VerificationServiceProfile key={`cr-vsp-${index}`} profile={profile} />
            ))}
        </div>
    );
}
