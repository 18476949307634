import React, { useRef, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LoadingOrError from '../../components/LoadingOrError';
import PageSection from '../../components/PageSection';
import PageSectionItem from '../../components/PageSectionItem';
import {
    MobileAboutDetail,
    Description,
    Label,
    Wrapper,
    ListWithoutBullet,
} from '../../components/DetailPage/styles';
import { apiUrl } from '../../shared/globals';
import { theme } from '../../shared/theme';
import orgIcon from '../../assets/images/icons/icon-organization-white-green-01.svg';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import sectorsIcon from '../../assets/images/icons/icon-occupations-and-industries-blue-green-01.svg';
import processIcon from '../../assets/images/icons/icon-process-blue-green-01.svg';
import AboutDetail from './AboutDetail';
import { VStack } from '../../components/Stack';
import { useMediaQuery, useGet } from '../../hooks';
import { ModalType } from '../../types/internal';
import SearchPills from '../../components/SearchPills';
import { Organization, VerificationServiceProfile, CostManifest, ConditionManifest, ProcessProfile } from '../../types/external';
import LocationPageSection from '../../components/DetailPage/LocationPageSection';
import PageBanner from '../../components/DetailPage/PageBanner';
import QualityAssurancePageSection from '../../components/DetailPage/QualityAssurancePageSection';
import LinkObject from '../../components/DetailPage/LinkObject';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import { detailSectionProperties } from '../../utils';
import HasAnyFilled from '../../components/HasAnyFilled';
import TextLink from '../../components/TextLink';
import VerificationServiceProfiles from '../../components/VerificationServiceProfile/list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { ModalButtonAndWindow } from '../../components/Modal';
import CostManifestList from '../../components/DetailPage/CostManifest';
import ConditionManifestList from '../../components/DetailPage/ConditionManifest';
import ProcessProfileList from '../../components/DetailPage/ProcessProfile';
import { renderJSONLDMetaTags } from '../../utils/JSONLDMetaHelper';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from '../../utils/SiteWidgetizer';



interface Props {
    id: string;
}

export default function OrganizationPage(props: RouteComponentProps<Props>) {
    const id = props.match.params.id;
    const [orgDataIsLoading, orgDataHasError, getOrgData] = useGet();
    const [org, setOrg] = useState({} as Organization);
    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });
    const mapMarkerListeners = useRef<Array<google.maps.MapsEventListener>>([]);
    const [openedModal, setOpenedModal] = useState<ModalType>();

    function onOpenModalClick(modalType: ModalType) {
        return () => setOpenedModal(modalType);
    }

    function onCloseModalClick() {
        setOpenedModal(undefined);
    }

    useEffect(() => {
        function removeMapMarkerListeners() {
            mapMarkerListeners.current.forEach((listener) => {
                listener.remove();
            });
        }
        return removeMapMarkerListeners;
    }, []);
    useEffect(() => {
        getOrgData(`${apiUrl}/organization/${id}`, (data: Organization) => {
			setOrg(data);
			renderJSONLDMetaTags(data, "Organization");
            // check condition (prototyping if doing fetch after load - not implemented)
            if ( data && data.HasVerificationService && data.HasVerificationService.Total > 0 ) {

            }
            // possibly disable button or would have to handle user clicking button before data is available
            // prefetch data
            // setState()
        });
    }, [getOrgData, id]);

	var typeLabel = widgetGetSingleLabelForText(org.CTDLTypeLabel, "Organization");

    return (
        <LoadingOrError isLoading={orgDataIsLoading} hasError={orgDataHasError}>
            <PageBanner
                item={org}
                bannerIcon={org.Image || ''}
                fallbackIcon={orgIcon}
            />
            <Wrapper>
                <VStack spacing={isMobile ? '0px' : '20px'}>
                    <HasAnyFilled
                        data={org}
                        keys={detailSectionProperties.organizationAbout}>
                        <PageSection
							icon={aboutIcon}
							title={"About this " + typeLabel}
							variant="Highlight"
							description={"Basic information about the " + typeLabel}>
                            <PageSectionItem>
                                {org.Description && (
                                    <Description>{org.Description}</Description>
                                )}
								{org.SubjectWebpage && (
									<LinkObject item={{ URL: org.SubjectWebpage, Label: "View this " + typeLabel }} />
                                )}
                            </PageSectionItem>
                            {org.MissionAndGoalsStatement && (
                                <PageSectionItem>
									<LinkObject item={org.MissionAndGoalsStatement} />
                                </PageSectionItem>
                            )}
                            {org.MissionAndGoalsStatementDescription && (
                                <PageSectionItem>
                                    <LinkObject item={org.MissionAndGoalsStatementDescription} />
                                </PageSectionItem>
                            )}
                            {org.AgentPurpose && (
                                <PageSectionItem>
									<LinkObject item={org.AgentPurpose} />
                                </PageSectionItem>
                            )}
                            {org.AgentPurposeDescription && (
                                <PageSectionItem>
                                    <LinkObject item={org.AgentPurposeDescription} />
                                </PageSectionItem>
                            )}
                            <AboutDetail org={org} />

                             {org.ParentOrganization && org.ParentOrganization.Values && (
                                <PageSectionItem>	
									<label>Parent {typeLabel}</label>
                                    <ul>
                                    {org.ParentOrganization.Values.map(
                                        (item, index) => (
                                            <ListWithoutBullet
                                                key={`cr-parentorg-${index}`}>
                                                <TextLink outline={item} />
                                            </ListWithoutBullet>
                                        ),
                                    )}
                                </ul>
                                </PageSectionItem>		
				            )}
                            {org.Department && org.Department.Values && (
                                <PageSectionItem>	
                                    <label>Departments</label>			
                                    <ul>
                                    {org.Department.Values.map(
                                        (item, index) => (
                                            <ListWithoutBullet
                                                key={`cr-department-${index}`}>
                                                <TextLink outline={item} />
                                            </ListWithoutBullet>
                                        ),
                                    )}
                                </ul>
                                </PageSectionItem>		
				            )}
                             {org.SubOrganization && org.SubOrganization.Values && (
                                <PageSectionItem>	
									<label>Sub-{typeLabel}(s)</label>
                                    <ul>
                                    {org.SubOrganization.Values.map(
                                        (item, index) => (
                                            <ListWithoutBullet
                                                key={`cr-suborganization-${index}`}>
                                                <TextLink outline={item} />
                                            </ListWithoutBullet>
                                        ),
                                    )}
                                </ul>
                                </PageSectionItem>		
				            )}
                        </PageSection>
                        {isMobile && (
                            <MobileAboutDetail>
                                <AboutDetail org={org} />
                            </MobileAboutDetail>
                        )}
                    </HasAnyFilled>
                    <HasAnyFilled
                        data={org}
                        keys={detailSectionProperties.organizationSectors}>
                        <PageSection
							icon={sectorsIcon}
							title="Sectors and Industries"
							description={"Fields in which the " + typeLabel + " operates"}>
                            {org.AgentSectorType && (
                                <PageSectionItem>
                                    <Label>Sector</Label>
                                    <SearchPills links={org.AgentSectorType} />
                                </PageSectionItem>
                            )}
                            {org.IndustryType && (
                                <PageSectionItem>
                                    <Label>Industries</Label>
                                    <SearchPills links={org.IndustryType} />
                                </PageSectionItem>
                            )}
                            {org.ServiceType && (
                                <PageSectionItem>
                                    <Label>Services</Label>
                                    <SearchPills links={org.ServiceType} />
                                </PageSectionItem>
                            )}
                            {org.Keyword && (
                                <PageSectionItem>
                                    <Label>Keywords:</Label>
                                    <SearchPills links={org.Keyword} />
                                </PageSectionItem>
                            )}
                        </PageSection>
                    </HasAnyFilled>
					<AdditionalInformationPageSection item={org} pageSectionDescription={"Identifiers and other information for the " + typeLabel} />
                </VStack>
                <VStack spacing={isMobile ? '0px' : '20px'}>
                    <QualityAssurancePageSection
						item={org}
						openedModal={openedModal}
						onOpenModalClick={onOpenModalClick}
						onCloseModalClick={onCloseModalClick}
						pageSectionDescription={"Quality Assurance for the " + typeLabel}
                    />
					<LocationPageSection item={org} pageSectionDescription={"Geographic information about the " + typeLabel} />
                    <HasAnyFilled
                        data={org}
                        keys={detailSectionProperties.organizationProcess}>
						<PageSection icon={processIcon} title="Process" description={"Processes performed within or by the " + typeLabel}>
                            {org.SupportServiceStatement && (
                                <PageSectionItem>
									<LinkObject item={org.SupportServiceStatement} />
                                </PageSectionItem>
							)}
							{org.TransferValueStatement && (
                                <PageSectionItem>
									<LinkObject item={org.TransferValueStatement} />
                                </PageSectionItem>
                            )}
                         <HasAnyFilled
                            data={org}
                            keys={detailSectionProperties.organizationManifests}>
                            <PageSectionItem>
                                {org.HasCostManifest && org.HasCostManifest.Total > 0 && (
									<>
										<ModalButtonAndWindow
											buttonLabel="Cost Manifests"
											getDataOnClickFromURL={org.HasCostManifest.URL}
											Content={(props: { loadedData: any }) => (<CostManifestList items={props.loadedData as Array<CostManifest>} />)}
											modalTitle="Cost Manifests"
											resourceTitle={org.Name}
											items={[]}
											overrideCount={org.HasCostManifest.Total}
										/>
									</>
                                )}
                                {org.HasConditionManifest && org.HasConditionManifest.Total > 0 && (
									<>
										<ModalButtonAndWindow
											buttonLabel="Condition Manifests"
											getDataOnClickFromURL={org.HasConditionManifest.URL}
											Content={(props: { loadedData: any }) => (<ConditionManifestList items={props.loadedData as Array<ConditionManifest>} />)}
											modalTitle="Condition Manifests"
											resourceTitle={org.Name}
											items={[]}
											overrideCount={org.HasConditionManifest.Total}
										/>
									</>
                                )}
                                </PageSectionItem>
                            </HasAnyFilled>
                            <HasAnyFilled
                                data={org}
                                keys={detailSectionProperties.organizationProcessProfiles}>
                                <PageSectionItem> 
									<ModalButtonAndWindow
										buttonLabel="Administration Process"
										getDataOnClickFromURL={org.AdministrationProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Administration Process"
										resourceTitle={org.Name}
										items={[]}
										overrideCount={org.AdministrationProcess?.Total}
									/>
									<ModalButtonAndWindow
										buttonLabel="Appeal Process"
										getDataOnClickFromURL={org.AppealProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Appeal Process"
										resourceTitle={org.Name}
										items={[]}
										overrideCount={org.AppealProcess?.Total}
									/>
									<ModalButtonAndWindow
										buttonLabel="Development Process"
										getDataOnClickFromURL={org.DevelopmentProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Development Process"
										resourceTitle={org.Name}
										items={[]}
										overrideCount={org.DevelopmentProcess?.Total}
									/>
									<ModalButtonAndWindow
										buttonLabel="Maintenance Process"
										getDataOnClickFromURL={org.MaintenanceProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Maintenance Process"
										resourceTitle={org.Name}
										items={[]}
										overrideCount={org.MaintenanceProcess?.Total}
									/>
									<ModalButtonAndWindow
										buttonLabel="Complaint Process"
										getDataOnClickFromURL={org.ComplaintProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Complaint Process"
										resourceTitle={org.Name}
										items={[]}
										overrideCount={org.ComplaintProcess?.Total}
									/>
									<ModalButtonAndWindow
										buttonLabel="Review Process"
										getDataOnClickFromURL={org.ReviewProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Review Process"
										resourceTitle={org.Name}
										items={[]}
										overrideCount={org.ReviewProcess?.Total}
									/>
									<ModalButtonAndWindow
										buttonLabel="Revocation Process"
										getDataOnClickFromURL={org.RevocationProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Revocation Process"
										resourceTitle={org.Name}
										items={[]}
										overrideCount={org.RevocationProcess?.Total}
									/>
                                </PageSectionItem>
                            </HasAnyFilled>
                            
                            {org.HasVerificationService && org.HasVerificationService.Total > 0 && (
                                <PageSectionItem>
									{/*<VerificationProfilesModal
                                        profiles={org.HasVerificationService}
                                        resourceTitle={org.Name}
                                        modalWindow='VerificationService'
                                        openedModal={openedModal}
                                        onOpenModalClick={onOpenModalClick}
                                        onCloseModalClick={onCloseModalClick}
									/>*/}
									<ModalButtonAndWindow
										buttonLabel={widgetGetPluralLabelForText("Verification Service Profiles")}
										getDataOnClickFromURL={org.HasVerificationService.URL}
										Content={(props: { loadedData: any }) => (<VerificationServiceProfiles profiles={props.loadedData as Array<VerificationServiceProfile>} />)}
										modalTitle={widgetGetPluralLabelForText("Verification Service Profiles")}
										resourceTitle={org.Name}
										items={[]}
										overrideCount={org.HasVerificationService.Total}
									/>
                                    </PageSectionItem>
                                )}
                        </PageSection>
                    </HasAnyFilled>
                </VStack>
            </Wrapper>
        </LoadingOrError>
    );
}
