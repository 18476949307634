import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import styled from 'styled-components/macro';
import jsonLDIcon from '../../assets/images/icons/icon-jsonld.png';
import { useGet } from '../../hooks';
import { ModalType } from '../../types/internal';
import { isFilled } from '../../validation';
import LoadingOrError from '../LoadingOrError';
import { ModalButtonAndWindow } from '../Modal';
import { VStack } from '../Stack';
import LinkObject from './LinkObject';
import {
    Inline,
    InlineLabel,
    Label,
} from './styles';
import { Button } from '../common';
import ActivityIndicator from '../ActivityIndicator';

interface Props {
	item: any;
}

export default function JSONLinkedDataModal({ item }: Props) {
    const [isLoading, hasError, get] = useGet();
	const [JSONLinkedData, setJSONLinkedData] = useState();
	const [modalIsOpen, setModalIsOpen] = useState(false);

	function getJSONLinkedData(tries: number) {
		if (item?.RegistryData?.Resource?.URL && tries >= 0) {
			get(item.RegistryData.Resource.URL, (data: any) => {
				setJSONLinkedData(data);
				setModalIsOpen(true);
			});
		}
		else {
			console.log("retrying", tries);
			setTimeout(() => {
				getJSONLinkedData(tries - 1);
			}, 100);
		}
	}

	useEffect(() => {
		if (item.RegistryData) {
			getJSONLinkedData(10);
		}
	}, [item])

	return (!item.RegistryData || !isFilled(item.RegistryData)) ? null : (
        <div>
            <LoadingOrError
                isLoading={isLoading}
                hasError={hasError}
				loadingComponent={
					<JSONLDIconButton>
					<div><ActivityIndicator /></div>
					<img src={jsonLDIcon} />
					</JSONLDIconButton>
				}
			>
				<ModalButtonAndWindow
					items={[{}]}
					buttonLabel=""
					ButtonContent={() => (
						<>
							{item.RegistryData && (
								<JSONLDIconButton>
									<div>Registry Data</div>
									<img src={jsonLDIcon} alt="JSON-LD logo" />
								</JSONLDIconButton>
							)}
							{!item.RegistryData && (
								<JSONLDIconButton>
									<div><ActivityIndicator /></div>
									<img src={jsonLDIcon} alt="JSON-LD logo" />
								</JSONLDIconButton>
							)}
						</>
					)}
					hideCount={true}
					resourceTitle={item.Name}
					modalTitle="Registry Data"
					Content={() => (
						<VStack spacing={'5px'}>
							{item.RegistryData && item.RegistryData.CTID && (
								<Inline>
									<InlineLabel>CTID:</InlineLabel>
									<div>{item.CTID}</div>
								</Inline>
							)}
							{item.RegistryData && item.RegistryData.Envelope && (
								<LinkObject item={item.RegistryData.Envelope} />
							)}
							{item.RegistryData && item.RegistryData.Resource && (
								<LinkObject item={item.RegistryData.Resource} />
							)}
							{JSONLinkedData && (
								<div>
									<Label>Raw Metadata:</Label>
									<pre>
										{JSON.stringify(JSONLinkedData, null, 4)}
									</pre>
								</div>
							)}
						</VStack>
					)}
				/>
            </LoadingOrError>
        </div>
    );
}

const JSONLDIconButton = styled.div`
	display: flex;
	align-items: center;
	padding: 1px;
	min-height: 40px;

	& div {
		flex: 1 1 auto;
	}

	& img {
		width: 50px;
		height: 50px;
	}
`;