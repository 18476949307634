import React, { ReactNode, useRef, useState, useEffect } from 'react';
import styled, { DefaultTheme, StyledComponent } from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../common';
import { englishOrNull } from '../GetResourceByURI';
import { Wrapper, StyledModal, Close, Heading, HeadingText, SubHeading, Body } from './styles';
import { getResourceByURLWithoutState, getAJAXCacheV2 } from '../GetResourceByURI';
import ActivityIndicator from '../ActivityIndicator';

export default function AJAXModal(props: { urls: Array<string>, buttonLabel: string, resourceTitle: string, SuccessRenderMethod: (data: any, index: number) => JSX.Element, ErrorRenderMethod: (error: any, index: number) => JSX.Element }) {
	const [isOpen, setIsOpen] = useState(false);
	const [loaders, setLoaders] = useState([] as Array<any>);
	var scrollTimer = null as any;
	var urls = (props.urls || [] as Array<string>);
	if (!urls.map) {
		debugger;
	}

	function refreshLoaders() {
		setLoaders(urls.map(url => {
			var cached = getAJAXCacheV2().find((cached: any) => cached.URL == url);
			return {
				URL: url,
				Success: cached?.Response,
				Error: cached?.Error
			}
		}));
	}

	function loadItems(threshold: number) {
		Array.from(document.getElementsByClassName("modalWrapperItemWrapper"))
			.filter((item: any) => item && item.dataset && item.dataset.status == "waiting" && item.offsetTop <= threshold + 50)
			.forEach((element: any) => {
				getResourceByURLWithoutState(element.dataset.url, null, refreshLoaders, refreshLoaders);
			}
		);
	}

	function handleScroll(e: any) {
		clearTimeout(scrollTimer);
		scrollTimer = setTimeout(() => {
			loadItems(e.target.scrollTop + e.target.scrollHeight);
		}, 250);
	}

	useEffect(() => {
		refreshLoaders();
		loadItems(document.getElementsByClassName("modalWrapperBody")[0]?.clientHeight || 250);
	}, [isOpen]);

	return urls.length > 0 ? (
		<ModalWrapper>
			<InvisibleButton onFocus={() => setIsOpen(false)}>Close modal window</InvisibleButton>
			<Button onClick={() => setIsOpen(!isOpen)}>{props.buttonLabel}</Button>
			{isOpen && (
				<Wrapper onClick={() => setIsOpen(false)}>
					<StyledModal onClick={(e: React.MouseEvent<HTMLElement>) => { e.stopPropagation(); }}>
						<Heading>
							<HeadingText>{props.resourceTitle}</HeadingText>
							<Close onClick={() => setIsOpen(false)} aria-label={"Close modal window for '" + props.resourceTitle + "'"}>
								<FontAwesomeIcon size="2x" icon={faTimes} />
							</Close>
						</Heading>
						<SubHeading>
							{props.buttonLabel}
						</SubHeading>
						<RelativeBody onScroll={handleScroll} className="modalWrapperBody">
							{loaders.map((loader: any, index: number) => (
								<ItemWrapper className="modalWrapperItemWrapper" data-url={loader.URL} data-status={loader.Success ? "success" : loader.Error ? "error" : "waiting"}>
									{loader.Success && <props.SuccessRenderMethod data={loader.Success} index={index} />}
									{loader.Error && <props.ErrorRenderMethod data={loader.Error} index={index} />}
									{!loader.Success && !loader.Error && <LoadingWrapper><ActivityIndicator size="sm" /></LoadingWrapper>}
								</ItemWrapper>
							))}
						</RelativeBody>
					</StyledModal>
				</Wrapper>
			)}
			<InvisibleButton onFocus={() => setIsOpen(false)}>Close modal window</InvisibleButton>
		</ModalWrapper>
	) : null;
}

const ModalWrapper = styled.div`
	margin-bottom: 10px;
`;

const RelativeBody = styled(Body)`
	position: relative;
`;

const ItemWrapper = styled.div`
	padding: 5px 0;
	&:not(:first-child) {
		border-top: 1px solid #CCC;
	}
`;

const LoadingWrapper = styled.div`

`;

const InvisibleButton = styled.button`
	opacity: 0;
	height: 0;
	overflow: hidden;
	margin: 0;
	padding: 0;
	width: 0;
	border: none;
`;