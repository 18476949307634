import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { CostProfile as CostProfileType, CostItem as CostItemType } from '../../types/external';
import { Box, BoxLabel, BoxContent, Section, SectionLabel, SectionContent, SectionContentItem } from './Shared';
import DateRange from './DateRange';
import DisplayPills from '../../components/PillDisplay';
import { formatPrice } from '../../utils';
import LinkObject from './LinkObject';

export default function CostProfileList(props: { items: CostProfileType[] }) {
	return (props.items?.length > 0 && 
		<div>
		{props.items.map((item) => (
			<CostProfile item={item} />
		))}
		</div>
	) || null
}

export function CostProfile(props: { item: CostProfileType }) {
	return (props.item && 
		<CostProfileWrapper>
			<div><b>{props.item.Name || "Cost Information"}</b></div>
			<BasicInfo>
				<div>
					<div>{props.item.Description}</div>
					<DateRange FromDate={props.item.StartDate} ToDate={props.item.EndDate} />
					{props.item.Condition?.length > 0 &&
						<ul className="withAbove">
							{props.item.Condition.map((condition) => (
								<li>{condition}</li>
							))}
						</ul>
					}
				</div>
				{props.item.CostDetails && <LinkObject item={{ URL: props.item.CostDetails, Label: "Cost Details" }} />}
			</BasicInfo>
			{props.item.CostItems?.length > 0 &&
				<CostItemList items={props.item.CostItems} currencySymbol={props.item.CurrencySymbol} />
			}
		</CostProfileWrapper>
	) || null
}

export function CostItemList(props: { items: CostItemType[], currencySymbol: string }) {
	return (props.items?.length > 0 &&
		<div>
		{props.items.map((item) => (
			<CostItem item={item} currencySymbol={props.currencySymbol} />
		))}
		</div>
	) || null
}

export function CostItem(props: { item: CostItemType, currencySymbol: string }) {
	return (props.item && 
		<CostItemWrapper>
			<SectionContentItem label="Cost Type" data={[props.item.DirectCostType]} RenderWith={(data) => <DisplayPills links={data} />} />
			<SectionContentItem label="Applicable Audience" data={props.item.AudienceType} RenderWith={(data) => <DisplayPills links={data} />} />
			<SectionContentItem label="Residency Conditions" data={props.item.ResidencyType} RenderWith={(data) => <DisplayPills links={data} />} />
			<SectionContentItem label="Payment Pattern" data={props.item.PaymentPattern} RenderWith={(data) => <div>{data}</div>} />
			<Price>{formatPrice(props.item.Price, props.currencySymbol)}</Price>
		</CostItemWrapper>
	) || null
}

const CostProfileWrapper = styled.div`
	
`;

const BasicInfo = styled.div`
	display: flex;
	margin-bottom: 5px;

	& > div {
		flex: 1 1 auto;
	}

	& > a {
		margin-left: 10px;
	}
`;

const CostItemWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
	padding: 2px 10px;
	border-top: 1px dashed #CCC;

	& > *:not(:last-child) {
		min-width: 10%;
		margin-right: 10px;
	}
`;

const Price = styled.div`
	font-size: 16px;
	font-weight: bold;
	margin-left: auto;
	padding: 0 10px;
`;