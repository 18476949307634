import axios from 'axios';
import { appURL } from '../../shared/globals';

export var ajaxCacheData = [];

export function getResourceByURI(uri, updateState, onLoading, onSuccess, onError) {
	uri = (uri || "").replace("http://", "https://");
	console.log("Received request to get resource via URI: " + uri, ajaxCacheData);
	var match = ajaxCacheData.filter(m => { return m.URI == uri })[0];
	if (match) {
		match.Status == "loading" && onLoading?.();
		match.Status == "success" && onSuccess?.(match.Data);
		match.Status == "error" && onError?.(match.Data);
	}
	else {
		match = {
			URI: uri,
			Data: null,
			Status: "loading"
		};
		ajaxCacheData.push(match);

		axios.get(uri).then((response) => {
			console.log("Got resource by URI", response);
			match.Data = response.data;
			match.Status = "success";
			console.log("Updating state");
			updateState?.([]);
			onSuccess?.(match.Data);
		}).catch((response) => {
			console.log("Error getting resource by URI", response);
			match.Data = response.data;
			match.Status = "error";
			console.log("Updating state");
			updateState?.([]);
			onError?.(match.Data);
		});

		updateState?.([]);
		onLoading?.();
	}

	return match;
}

export function englishOrNull(languageMap) {
	if (typeof languageMap === "string") {
        return languageMap;
    }
    //for all english
    if (languageMap?.["en-US"]) return languageMap["en-US"];
    if (languageMap?.["en-us"]) return languageMap["en-us"];

    // // Check for any key that starts with "en-"
    for (const key in languageMap) {
		debugger;
        if (key.startsWith("en")) {
            return languageMap[key];
        }
    }
	return null;
}

export function createKeywordSearchLink(searchType, text) {
	return { Label: text, URL: appURL + "/search?autosearch=true&searchType=" + searchType + "&keywords=" + text };
}

export function createKeywordSearchLinkListFromCredentialAlignmentObjectList(searchType, itemList) {
	return itemList?.map(item => { return createKeywordSearchLink(searchType, englishOrNull(item["ceterms:targetNodeName"])); });
}

export function createReferenceFrameworkFromCredentialAlignmentObject(searchType, cao) {
	var label = englishOrNull(cao["ceterms:targetNodeName"]) || cao["ceterms:codedNotation"];
	var url = appURL + "/search?autosearch=true&searchType=" + searchType + "&keywords=" + label;
	return {
		Label: label,
		URL: url,
		CodedNotation: cao["ceterms:codedNotation"],
		Description: englishOrNull(cao["ceterms:targetNodeDescription"]),
		TargetNode: cao["ceterms:targetNode"],
		FrameworkName: englishOrNull(cao["ceterms:frameworkName"]),
		Framework: cao["ceterms:framework"]
	};
}

window.AJAXCacheV2 = window.AJAXCacheV2 || [];
export var AJAXCacheV2 = window.AJAXCacheV2;

export function addResourceToCache(url, data) {
	var match = window.AJAXCacheV2.find(m => m.URL == url);
	if (!match) {
		window.AJAXCacheV2.push({
			URL: url,
			Response: data,
			Error: null,
			Status: "success",
			OnSuccess: [],
			OnError: []
		});
	}
}

export function getResourceByURLWithoutState(url, onLoading, onSuccess, onError, customConfig) {
	//Find the request in the queue
	url = (url || "").replace("http://", "https://");
	var match = window.AJAXCacheV2.filter(m => m.URL == url)[0];

	//If not found, create it
	if (!match) {
		match = {
			URL: url,
			Response: null,
			Error: null,
			Status: "loading",
			OnSuccess: [], //Success and Error handlers get .push()'d later, so no need to include them here
			OnError: []
		};
		window.AJAXCacheV2.push(match);

		//Get the data, then...
		customConfig = customConfig || { headers: { "Accept": "application/json" } };
		axios.get(url, customConfig).then(response => {
			//On success, run all of the success handlers associated with this match
			match.Response = response.data;
			match.Status = "success";
			console.log("Got resource by URL", match);

			match.OnSuccess.forEach(handler => {
				handler?.(match.Response);
			});
		}).catch(error => {
			//On error, run all of the error handlers associated with this match
			match.Error = error;
			match.Status = "error";
			console.log("Error getting resource by URL", match);

			match.OnError.forEach(handler => {
				handler?.(match.Error);
			})
		});

	}

	//If the match was found and it's still loading, then...
	if (match.Status == "loading") {
		//Add the success and error handlers to its handler lists and run the loading handler
		onSuccess && match.OnSuccess.push(onSuccess);
		onError && match.OnError.push(onError);
		onLoading?.();
	}
	//If the match already loaded, just run the appropriate handler
	else {
		match.Status == "success" && onSuccess?.(match.Response);
		match.Status == "error" && onError?.(match.Error);
	}

	return match;
}

//Utility method to get something by URL (either locally or by fetching it), then run a render method
export function getAndRenderByURL(url, localGraph, fallbackObject, renderMethod) {
	//If the data is found in the local graph, run the render method
	url = (url || "").replace("http://", "https://");
	var data = localGraph.filter(m => m["@id"] == url)[0];
	if (data) {
		renderMethod?.(data);
		return;
	}
	//Otherwise, get the data...
	else {
		getResourceByURLWithoutState(url, null,
			//On success, run the render method
			(response) => {
				renderMethod?.(response);
			},
			//On error, run the render method with a fallback object
			(error) => {
				fallbackObject = {
					...(fallbackObject || {}),
					"request:error": error
				};
				renderMethod?.(fallbackObject);
			}
		);
	}
}
//

export function getAJAXCacheV2() {
	return AJAXCacheV2;
}
//