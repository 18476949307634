export function getCurrentQuery() {
	window.SearchQuery = window.SearchQuery || {};
	return window.SearchQuery;
}

export function setCurrentQuery(query) {
	window.SearchQuery = query || {
		Keywords: [],
		MainFilters: [],
		MapFilter: {},
		SkipPages: 0,
		PageSize: 20,
		SortOrder: "sortOrder:MostRelevant"
	};
}

export function registerCurrentQueryListener(name, method) {
	window.SearchQueryListeners = window.SearchQueryListeners || [];
	if (name && method && !window.SearchQueryListeners.find(m => m.Name == name)) {
		window.SearchQueryListeners.push({ Name: name, Method: method });
	}
}

export function updateCurrentQuery(updateMethod) {
	var query = getCurrentQuery();
	updateMethod?.(query);
	console.log("Query updated", query);
	window.dispatchEvent(new Event("SearchQueryUpdated"));
	window.SearchQueryListeners?.forEach(listener => {
		console.log("Running listener: " + listener.Name);
		listener.Method();
	});
}

export function delayThenDo(timeoutName, delayMS, method) {
	window.TimeoutList = window.TimeoutList || {};
	clearTimeout(window.TimeoutList[timeoutName]);
	window.TimeoutList[timeoutName] = setTimeout(method, delayMS);
}

export function addFilterItem(filterID, filterURI, filterLabel, itemID, itemURI, itemText, afterOnRemove) {
	var query = getCurrentQuery();
	query.MainFilters = query.MainFilters || [];
	var matchingFilter = query.MainFilters.find(m => m.Id == filterID);
	if (matchingFilter) {
		matchingFilter.Items = matchingFilter.Items || [];
		var matchingFilterItem = matchingFilter.Items?.find(m => getByIDOrURIOrText(m, itemID, itemURI, itemText));
		if (!matchingFilterItem) {
			matchingFilter.Items.push({
				Id: itemID,
				URI: itemURI,
				Text: itemText,
				onRemove: function () {
					removeFilterItem(filterID, filterURI, filterLabel, itemID, itemURI, itemText);
					afterOnRemove?.();
				}
			});
		}
	}
	else {
		query.MainFilters.push({
			Id: filterID,
			Label: filterLabel,
			URI: filterURI,
			Items: [
				{
					Id: itemID,
					URI: itemURI,
					Text: itemText,
					onRemove: function () {
						removeFilterItem(filterID, filterURI, filterLabel, itemID, itemURI, itemText);
						afterOnRemove?.();
					}
				}
			]
		});
	}
	updateCurrentQuery();
}

export function removeFilterItem(filterID, filterURI, filterLabel, itemID, itemURI, itemText) {
	var query = getCurrentQuery();
	var matchingFilter = query.MainFilters.find(m => m.Id == filterID);
	if (matchingFilter) {
		matchingFilter.Items = matchingFilter.Items || [];
		var matchingItem = matchingFilter.Items?.find(m => getByIDOrURIOrText(m, itemID, itemURI, itemText));
		if (matchingItem) {
			matchingFilter.Items = matchingFilter.Items.filter(m => m != matchingItem);
			if (matchingFilter.IsPreselectedFilter) {
				window.RemovedPreselectedFilter = true; //Hack
			}
		}
		if (matchingFilter.Items.length == 0) {
			query.MainFilters = query.MainFilters.filter(m => m != matchingFilter);
		}
	}
	updateCurrentQuery();
}

export function toggleFilterItem(filterID, filterURI, filterLabel, itemID, itemURI, itemText) {
	var query = getCurrentQuery();
	if (query.MainFilters?.find(m => m.Id == filterID)?.Items?.find(m => getByIDOrURIOrText(m, itemID, itemURI, itemText))) {
		console.log("Removing Filter Item", { filterID: filterID, filterLabel: filterLabel, itemID: itemID, itemURI: itemURI, itemText: itemText });
		removeFilterItem(filterID, filterURI, filterLabel, itemID, itemURI, itemText);
	}
	else {
		console.log("Adding Filter Item", { filterID: filterID, filterLabel: filterLabel, itemID: itemID, itemURI: itemURI, itemText: itemText });
		addFilterItem(filterID, filterURI, filterLabel, itemID, itemURI, itemText);
	}
}

export function getByIDOrURIOrText(FilterItem, itemID, itemURI, itemText) {
	return itemID ? FilterItem.Id == itemID :
		itemURI ? FilterItem.URI == itemURI :
		itemText ? FilterItem.Text == itemText :
		false;
}
