import React from 'react';
import {
    Assessment,
    Credential,
    LearningOpportunity,
    Organization,
} from '../../types/external';
import { Box, BoxLabel, BoxContent } from './Shared';
import JurisdictionProfileList from './JurisdictionProfile';


export default function JurisdictionAssertions(props: { item: Organization | Assessment | LearningOpportunity | Credential }) {
    return (
		<div>
			{props.item.AccreditedIn &&
				<Box>
					<BoxLabel>Accredited In</BoxLabel>
					<BoxContent>
						<JurisdictionProfileList items={props.item.AccreditedIn} />
					</BoxContent>
				</Box>
			}
			{props.item.ApprovedIn &&
				<Box>
					<BoxLabel>Approved In</BoxLabel>
					<BoxContent>
						<JurisdictionProfileList items={props.item.ApprovedIn} />
					</BoxContent>
				</Box>
			}
			{props.item.OfferedIn &&
				<Box>
					<BoxLabel>Offered In</BoxLabel>
					<BoxContent>
						<JurisdictionProfileList items={props.item.OfferedIn} />
					</BoxContent>
				</Box>
			}
			{props.item.RecognizedIn &&
				<Box>
					<BoxLabel>Recognized In</BoxLabel>
					<BoxContent>
						<JurisdictionProfileList items={props.item.RecognizedIn} />
					</BoxContent>
				</Box>
			}
			{props.item.RegulatedIn &&
				<Box>
					<BoxLabel>Regulated In</BoxLabel>
					<BoxContent>
						<JurisdictionProfileList items={props.item.RegulatedIn} />
					</BoxContent>
				</Box>
			}
        </div>
    );
}
