import React, { useRef, useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components/macro';
import { appURL, registryURL } from '../../shared/globals';
import { theme } from '../../shared/theme';
import ActivityIndicator from '../ActivityIndicator';

export interface PreloadedResource {
	URL: string,
	Data: any
}

export interface ResourceWrapper {
	URL: string,
	Data?: any,
	Error?: any,
	OnSuccess?: Array<(data: any) => void>,
	OnError?: Array<(data: any) => void>
}

export default function AJAXResourceList(props: {
	urls: Array<string>,
	resources?: Array<PreloadedResource>,
	onSuccess?: (data: any, itemContainer: Element, itemList?: Element) => void,
	onError?: (data: any, itemContainer: Element, itemList?: Element) => void,
	StyleComponent?: () => JSX.Element,
}) {
	//Initialize
	const [listID, setListID] = useState("");
	if (!listID) {
		setListID((crypto as any).randomUUID() as string);
	}

	//Kick off the render process
	useEffect(() => {
		setupRenderedContainer();
	}, []);

	//Get the rendered container by brute force because react sucks, then render stuff
	function setupRenderedContainer() {
		var itemList = document.getElementById(listID);
		if (!itemList) {
			setTimeout(setupRenderedContainer, 100);
		}
		else {
			if (!itemList.classList.contains("setup")) {
				itemList.classList.add("setup");
				setupAJAXResourceList(itemList, props.urls, props.resources, props.onSuccess, props.onError);
			}
		}
	}

	//Return the div
	return props.urls && props.urls.length > 0 && (
		<>
			{props.StyleComponent && <props.StyleComponent />}
			<AJAXResourceListWrapper id={listID}></AJAXResourceListWrapper>
		</>
	) || null;
}

export function setupAJAXResourceList(
	itemList: Element,
	urls: Array<string>,
	resources?: Array<PreloadedResource>,
	onSuccess?: (data: any, itemContainer: Element, itemList?: Element) => void,
	onError?: (data: any, itemContainer: Element, itemList?: Element) => void,
) {
	//Validation
	if (!itemList || !urls || urls.length == 0) {
		return;
	}

	//Flag the list
	itemList.classList.add("ajaxResourceList");

	//Variables
	var cacheV3 = getAJAXCacheV3();
	var listItemBoxes = [] as Array<Element>;
	var listeners = [] as any;

	//Store resources that are already obtained
	if (resources && resources.length > 0) {
		resources.forEach(resource => {
			appendAJAXCacheV3Resource(resource);
		});
	}

	//Pre-create each URL's container so that they show up in order
	urls.forEach(url => {
		var box = document.createElement("div");
		box.classList.add("resourceListItem");
		box.dataset.url = url;
		itemList.appendChild(box);
		box.innerHTML = renderToString(<ActivityIndicator size="sm" />);
		listItemBoxes.push(box);
	});

	//Check for scrollable parents, up to and including the window, and bind scroll handlers
	var listeningScrollableElements = [] as Array<any>;
	var scrollableProperties = ["overflow", "overflowX", "overflowY"];

	addScrollHandlerToScrollableElements(itemList);

	function addScrollHandlerToScrollableElements(element: any) {
		if (element == document || element == window) {
			addScrollHandler(window as any);
			return;
		}
		else {
			try { //For some reason, react still causes this to be called once in a while, and it crashes the entire page, so wrap it in a try-catch
				var style = getComputedStyle(element) as any;
				scrollableProperties.forEach(property => {
					if ((style[property] == "auto" || style[property] == "scroll") && !listeningScrollableElements.includes(element)) {
						listeningScrollableElements.push(element);
						addScrollHandler(element);
					}
				});
				addScrollHandlerToScrollableElements(element.parentNode);
			}
			catch (e) {}
		}
	}

	//Refresh the list when the element scrolls
	function addScrollHandler(element: any) {
		var listener = element.addEventListener("scroll", () => {
			refreshList();
		});
		listeners.push({ element: element, listener: listener });
	}

	//Auto-trigger the first scroll
	refreshList();

	//Helper Functions

	//Check to see which elements are within the container's viewport, and fetch/render them
	function refreshList() {
		//For some reason this still gets called even though the listeners are removed, I hate react
		if (!itemList) {
			return;
		}
		//Remove event listeners and delete the list if react has detached it, since react will stupidly create a brand new list instead of just reattaching this one
		if (!itemList.parentNode || itemList.clientHeight == 0 || !getComputedStyle(itemList).display) {
			listeners.forEach((item: any) => {
				item.element.removeEventListener("scroll", item.listener);
			});
			itemList.remove(); //Detach from document
			itemList.innerHTML = ""; //If for some reason the delete fails too, at least empty out the element
			(itemList as any) = null; //Hopefully flag for garbage collection
			return;
		}
		//Otherwise, calculate and load stuff as normal
		var threshold = (window as any).innerHeight + 50; //getBoundingClientRect().top is relative to the window even in a scrollable div, so use this as the threshold
		listItemBoxes.filter(box => !box.classList.contains("triggered")).forEach(box => {
			var top = box.getBoundingClientRect().top;
			if (top >= 0 && top <= threshold) {
				box.classList.add("triggered");
				getResourceViaResourceCacheV3((box as any).dataset.url, (data: any) => {
					(onSuccess || defaultSuccess)(data, box, itemList);
				}, (data: any) => {
					defaultError(data, box, itemList);
					onError?.(data, box, itemList);
				});
			}
		});
	}

	//Default success handler
	function defaultSuccess(data: any, itemContainer: Element, itemList?: Element) {
		var container = itemContainer as any;
		container.innerHTML = "Loaded: <a href=\"" + (container.dataset?.url || "") + "\">" + (container.dataset?.url || "") + "</a>";
	}

	//Default error handler
	function defaultError(data: any, itemContainer: Element, itemList?: Element) {
		var container = itemContainer as any;
		container.innerHTML = "<a href=\"" + (container.dataset?.url || "") + "\">" + (container.dataset?.url || "") + "</a>";
		console.log("Error Loading Item", {
			data: data,
			itemContainer: itemContainer,
			itemList: itemList
		});
	}
}


const AJAXResourceListWrapper = styled.div`

	& .resourceListItem {
		min-height: 50px;
	}
`;

//Utility methods
//Get the AJAX Cache V3
export function getAJAXCacheV3(): Array<ResourceWrapper> {
	if (!(window as any).AJAXCacheV3) {
		(window as any).AJAXCacheV3 = [] as Array<ResourceWrapper>;
	}
	return (window as any).AJAXCacheV3 as Array<ResourceWrapper>;
}

//Get a resource from AJAX Cache V3 based on its URL
export function getAJAXCacheV3Resource(url: string) {
	return getAJAXCacheV3().find(resource => resource.URL == url);
}

//Append a resource object to Ajax Cache V3 if it isn't already present, and return the resource either way
export function appendAJAXCacheV3ResourceRaw(url: string, resourceData: any) {
	appendAJAXCacheV3Resource({
		URL: url,
		Data: resourceData
	});
}
export function appendAJAXCacheV3Resource(resource: ResourceWrapper) {
	var cacheV3 = getAJAXCacheV3();
	var wrapper = {
		URL: resource.URL,
		Data: resource.Data,
		Error: resource.Error,
		OnSuccess: resource.OnSuccess || [] as Array<(data: any) => void>,
		OnError: resource.OnError || [] as Array<(data: any) => void>
	};
	var match = cacheV3.find(item => item.URL == wrapper.URL);
	if (!match) {
		cacheV3.push(wrapper);
	}
	return match || wrapper;
}

//Combine all requests for a URL into a single object with 1-n success/error handlers, and process them when the object is loaded
export function getResourceViaResourceCacheV3(url: string, onSuccess?: (data: any) => void, onError?: (data: any) => void) {
	//Get the matching data
	url = url.replace(/^http:\/\//i, "https://");
	var match = getAJAXCacheV3Resource(url);

	//If it was found, simulate the async rendering (for consistency) and process the result
	if (match) {
		if (match.Data && onSuccess) {
			setTimeout(() => {
				onSuccess((match || {}).Data);
			}, 0);
		}
		else if (match.Error && onError) {
			setTimeout(() => {
				onError((match || {}).Error);
			}, 0);
		}
		else {
			onSuccess && match.OnSuccess?.push(onSuccess);
			onError && match.OnError?.push(onError);
		}
	}
	//Otherwise, append the resource object and its handlers, then fetch the resource and process the results
	else {
		var appended = appendAJAXCacheV3Resource({
			URL: url,
			OnSuccess: onSuccess ? [onSuccess] : [],
			OnError: onError ? [onError] : []
		});
		fetch(url)
			.then(response => {
				if (response.ok) {
					return response.text();
				}
				else {
					throw response;
				}
			})
			.then(text => {
				var result = JSON.parse(text);
				console.log("Loaded " + url, result);
				appended.Data = result;
				appended.OnSuccess?.forEach(successMethod => {
					successMethod(appended.Data);
				});
				appended.OnSuccess = [];
			})
			.catch(error => {
				console.log("Error loading " + url, error);
				appended.Error = error;
				appended.OnError?.forEach(errorMethod => {
					errorMethod(appended.Error);
				});
				appended.OnError = [];
			});
	}
}

//Utility functions common to rendering after data has been fetched
export function createElement(elementType: string, cssClass: string) {
	var element = document.createElement(elementType);
	cssClass.split(" ").forEach(cssItem => cssItem && element.classList.add(cssItem));
	return element;
}

export function getEnglish(data: any) {
	return !data ? null : (data["en-us"] || data["en-US"] || data["en"] || data);
}

export function renderText(container: any, content: any, elementType?: string, cssClass?: string, title?: string, handler?: (box: any, text: string) => void) {
	return renderElement(container, content, elementType || "div", cssClass || "", (element, content) => {
		var text = getEnglish(content);
		element.innerHTML = text;
		title && (element.title = title);
		handler?.(element, text);
	});
}

export function renderLink(container: any, contentURL: string, contentLabel?: string, target?: string, cssClass?: string, title?: string, handler?: (link: any, url: string) => void) {
	return renderElement(container, contentURL, "a", cssClass || "", (element, content) => {
		element.href = contentURL;
		element.innerHTML = contentLabel || contentURL;
		target && (element.target = target);
		title && (element.title = title);
		handler?.(element, contentURL);
	});
}

export function renderImage(container: any, contentURL: string, cssClass?: string, title?: string, altText?: string, handler?: (image: any, url: string) => void) {
	return renderElement(container, contentURL, "img", cssClass || "", (image, url) => {
		image.src = contentURL;
		image.altText = altText || "";
		title && (image.title = title);
		handler?.(image, contentURL);
	});
}

export function renderElement(container: any, content: any, elementType: string, cssClass: string, handler: (element: any, content: any) => void) {
	if (content) {
		var element = container.appendChild(createElement(elementType || "div", cssClass || ""));
		handler?.(element, content);

		return element;
	}

	return null;
}

export function getDetailPageHeaderModifiedDate(dateString: string) {
	try {
		var modified = new Date(dateString);
		return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][modified.getMonth()] + " " + modified.getDate() + ", " + modified.getFullYear();
	}
	catch (e) {
		return "Unknown"
	}
}

export function lookupSchema(handler: (schemaGraph: Array<any>) => void) {
	//If the graph object exists...
	if ((window as any).SchemaGraph) {
		//If the data is already loaded...
		if ((window as any).SchemaGraph.Loaded) {
			//Mimic the async nature of the request and call the handler
			setTimeout(function () {
				handler?.((window as any).SchemaGraph.Graph);
			}, 0);
		}
		//If the data isn't loaded yet...
		else {
			//Queue the handler
			handler && (window as any).SchemaGraph.Handlers.push(handler);
		}
	}
	//If the graph object does not exist...
	else {
		//Create the graph object
		(window as any).SchemaGraph = {
			Loaded: false,
			Graph: [],
			Handlers: [],
			Timestamp: Date.now()
		};

		//Try to get the data from the local storage
		var cachedText = (window as any).localStorage?.getItem("ajaxv3:SchemaGraph");
		if (cachedText) {
			var cachedData = JSON.parse(cachedText);
			console.log("Loading schema from local storage...");
			//If the cache is less than 12 hours old, use it
			if (cachedData.Timestamp - Date.now() < 43200000) {
				//Set the graph object
				(window as any).SchemaGraph = cachedData;

				//Call the handler
				setTimeout(function () {
					handler?.((window as any).SchemaGraph.Graph);
				}, 0);

				//Return in order to avoid calling the rest of the method
				return;
			}
		}

		//Otherwise, load the data
		console.log("Fetching schema from credreg.net...");
		handler && (window as any).SchemaGraph.Handlers.push(handler);
		getResourceViaResourceCacheV3("https://credreg.net/ctdl/schema/encoding/json", (ctdlData) => {
			getResourceViaResourceCacheV3("https://credreg.net/ctdlasn/schema/encoding/json", (ctdlasnData) => {
				getResourceViaResourceCacheV3("https://credreg.net/qdata/schema/encoding/json", (qdataData) => {
					//Combine and deduplicate the schema objects
					(ctdlData["@graph"]).concat(ctdlasnData["@graph"]).concat(qdataData["@graph"]).forEach((item: any) => {
						if (!(window as any).SchemaGraph.Graph.find((graphItem: any) => graphItem["@id"] == item["@id"])) {
							(window as any).SchemaGraph.Graph.push(item);
						}
					});

					//Set the graph object as loaded and call its handlers
					(window as any).SchemaGraph.Loaded = true;
					(window as any).SchemaGraph.Handlers.forEach((handler: any) => {
						handler?.((window as any).SchemaGraph.Graph);
					});

					//Clear its handlers
					(window as any).SchemaGraph.Handlers = [];

					//Cache the object
					(window as any).localStorage?.setItem("ajaxv3:SchemaGraph", JSON.stringify((window as any).SchemaGraph));
				});
			})
		})
	}
}

export function renderOutline(data: any, itemContainer: Element, itemList?: Element) {
	var container = itemContainer as any;
	container.innerHTML = "";
	container.classList.add("outline");
	renderImage(container, data["ceterms:image"], "outlineImage", "Resource image");
	var content = renderElement(container, true, "div", "outlineContent", () => { });
	if (data["@id"]?.includes(registryURL + "/resources/ce-")) {
		renderLink(content, appURL + "/resources/" + data["ceterms:ctid"], getEnglish(data["ceterms:name"] || data["ceasn:name"] || data["skos:prefLabel"] || data["ceasn:competencyLabel"] || "Unnamed Resource"), "_blank", "outlineName");
		renderText(content, getEnglish(data["ceterms:description"] || data["ceasn:description"] || data["skos:definition"] || data["ceasn:competencyText"] || "No Description"), "div", "outlineDescription");
	}
	else {
		renderLink(content, data["@id"], "External Resource", "_blank", "outlineName external");
		renderText(content, "External Link to " + data["@id"], "div", "outlineDescription");
	}

}

export function OutlineStyleBlock() {
	return <style type="text/css">{`
		.outline { display: flex; align-items: flex-start; padding: 10px 0; gap: 10px; }
		.outline:not(:last-child) { border-bottom: 1px solid ${theme.color.aquaDark}; }
		.outline .outlineImage { flex: 1 1 120px; max-width: 120px; max-height: 120px; }
		.outline .outlineContent {  }
		.outline .outlineContent .outlineName { font-weight: bold; margin-bottom: 5px; }
		.outline .outlineContent .outlineDescription { font-size: 90%; word-break: break-word; margin-bottom: 5px; }
	`}</style>
}
