import styled from 'styled-components/macro';
import { ContentDelimiter } from '../../components/common';
import { HStack, VStack } from '../../components/Stack';

export const Wrapper = styled(ContentDelimiter)`
    padding-top: 80px;
    @media (max-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        padding-top: 40px;
    }
`;

export const PageSection = styled.div`
    padding-top: 40px;
`;

export const PageTitle = styled.p`
    font-size: 40px;
    font-weight: bold;
    margin: 0;
    line-height: 1;
`;

export const PageDescription = styled.p``;

export const Title = styled.p`
    text-align: center;
    font-weight: bold;
    margin: 0;
`;

export const StatData = styled(HStack)`
	display: flex;
	flex-wrap: wrap;
    justify-content: center;
    padding: 40px 0;
    > * {
        margin-bottom: 20px;
    }
`;
export const StatItem = styled.button`
	flex: 1 1 25%;
	background: transparent;
    border: none;
    text-align: center;
	padding: 10px;
	min-width: 300px;

	/* Fix broken margin rules that are coming from somewhere I can't find and which probably wouldn't be safe to change anyway */
	&:first-child {
		margin: 0;
	}
	&:last-child {
		margin: 0;
	}
	&:not(:first-child) {
		margin: 0;
	}
	&:not(:last-child) {
		margin: 0;
	}
`;
//prototype: set font smaller so fits on one line on a desktop
export const StatItemValue = styled.h1`
    font-size: 64px;
    color: ${(props) => props.theme.color.brown};
    margin: 0;
`;
export const StatItemTitle = styled.p`
    margin: 0;
    font-size: 24px;
    text-align: center;
    line-height: 1.125;
`;

export const ConnectorBox = styled.div<{ height?: string }>`
    min-width: 150px;
    max-width: 150px;
    height: ${(props) => props.height || '150px'};
    @media (max-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        display: none;
    }
`;
