import React from 'react';
import { Link } from '../../types/external';
import { ModalLinksWrapper, ModalLink } from './styles';
import { Button, InternalButtonLink } from '../common';
import { getLinkType } from '../../utils';
import PillLink from '../PillLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import LinkObject from './LinkObject';

interface Props {
    links: Array<Link>;
}

export default function ModalLinks({ links }: Props) {
    const buttonType = 'secondary';

    function renderModalLink(link: Link) {
        const linkType = getLinkType(link.URL);

        switch (linkType) {
            case 'external':
                if (link.URL.toLowerCase().indexOf("/search") > -1) {
					return (
						<PillLink
							href={link.URL}
							title={link.Label}
							label={link.Label}
							leftIcon={<FontAwesomeIcon icon={faFilter} size="sm" />}
							rightIcon={
								<FontAwesomeIcon icon={faSearch} size="sm" />
							}
						/>
					);
				}
				else {
					return ( link && <LinkObject item={link} /> )
				}
				
			case 'internal':
				if (link.URL.toLowerCase().indexOf("/search") > -1) {
					return (
						<PillLink
							href={link.URL}
							title={link.Label}
							label={link.Label}
							leftIcon={<FontAwesomeIcon icon={faFilter} size="sm" />}
							rightIcon={
								<FontAwesomeIcon icon={faSearch} size="sm" />
							}
						/>
					);
				}
				else {
					return (
						<InternalButtonLink buttonType={buttonType} to={link.URL} target="searchWindow">
							{link.Label}
						</InternalButtonLink>
					);
				}
            case 'none':
                return <Button buttonType={buttonType}>{link.Label}</Button>;
        }
    }

    return (
        <ModalLinksWrapper>
            {links.map((link, index) => (
                <ModalLink key={`cr-ml-${index}`}>
                    {renderModalLink(link)}
                </ModalLink>
            ))}
        </ModalLinksWrapper>
    );
}
