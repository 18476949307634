import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { apiUrl } from '../../shared/globals';
import { RouteComponentProps } from 'react-router-dom';
import LoadingOrError from '../../components/LoadingOrError';
import { appURL, appBaseName } from '../../shared/globals';
import { ConceptScheme, Concept as ConceptType, Outline, Link, AJAXSettings } from '../../types/external';
import conceptsBannerIcon from '../../assets/images/icons/icon-competencies-white-green-01.svg';
import conceptsIcon from '../../assets/images/icons/icon-competencies-blue-green-01.svg';
import PageBanner from '../../components/DetailPage/PageBanner';
import {
	Wrapper,
	FullWidthWrapper,
	InnerWrapper,
	Description,
    Label,
    Section
} from '../../components/DetailPage/styles';
import { VStack } from '../../components/Stack';
import PageSection from '../../components/PageSection';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import { getResourceByURI, englishOrNull, createKeywordSearchLink, getResourceByURLWithoutState } from '../../components/GetResourceByURI';
import ActivityIndicator from '../../components/ActivityIndicator';
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import LinkObject from '../../components/DetailPage/LinkObject';
import PageSectionItem from '../../components/PageSectionItem';
import SearchPills from '../../components/SearchPills';
import SubjectsAndKeywordsPageSection from '../../components/DetailPage/SubjectsAndKeywordsPageSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { renderJSONLDMetaTags } from '../../utils/JSONLDMetaHelper';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";
import { ModalButtonAndWindow } from '../../components/Modal';
import OutlineList from '../../components/DetailPage/Outline';


interface Props {
	id: string;
	targetCTID?: string;
}

export default function ConceptSchemePage(props: RouteComponentProps<Props>) {
	const id = props.match.params.id;
	const [dataIsLoading, setDataIsLoading] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [conceptScheme, setConceptScheme] = useState({} as ConceptScheme);
	const [concepts, setConcepts] = useState([] as Array<ConceptType>);
	const [localAJAXCache, setLocalAJAXCache] = useState([] as Array<any>); //Used to trick react into re-rendering the data

	//This is so backwards I hate this
	const [publicationStatusText, setPublicationStatusText] = useState("");
	const [relatedWorkRoles, setRelatedWorkRoles] = useState({} as AJAXSettings);

	useEffect(() => {
		console.log("App Name", appBaseName);
		getResourceByURI(`${apiUrl}/dsp_conceptscheme/${id}`, setLocalAJAXCache, null, (loaded: any) => {
			var data = loaded.Result;
			renderJSONLDMetaTags(data.Resource, "ConceptScheme");
			setDataIsLoading(false);
			setConceptScheme(convertDataToConceptScheme(data.Resource || { "ceasn:name": "Missing Concept Scheme", "ceasn:description": "Unable to find data for identifier: " + id }, data.Metadata, id, setLocalAJAXCache));
			setConcepts(data.RelatedItems.filter((m: any) => { return m["skos:inScheme"] == data.Resource["@id"] || m["ceasn:inProgressionModel"] == data.Resource["@id"] }).map((m: ConceptType) => { return convertDataToConcept(m, setLocalAJAXCache) }));

			if (conceptScheme.PublicationStatusType) {
				getResourceByURLWithoutState(conceptScheme.PublicationStatusType, null, (success: any) => {
					setPublicationStatusText(englishOrNull(success?.["@graph"]?.[0]?.["skos:prefLabel"]));
					console.log(publicationStatusText);
				}, (error: any) => {
					setPublicationStatusText("Error loading Status: " + conceptScheme.PublicationStatusType);
				});
			}
		},
		(error: any) => {
			setHasError(true);
			console.log("Error loading data: ", error);
		});
		new URLSearchParams(window.location.search).forEach(function (value: string, key: string) {
			if (key.toLowerCase() == "conceptctid" || key.toLowerCase() == "targetctid") {
				jumpToConceptCTID(value.toLowerCase(), 0);
			}
		});

		function jumpToConceptCTID(targetCTID: string, attempts: number) {
			setTimeout(function () {
				var element = document.getElementById(targetCTID);
				if (element && element.getElementsByClassName("conceptContent").length > 0) {
					element.scrollIntoView();
				}
				else if (attempts <= 10) {
					jumpToConceptCTID(targetCTID, attempts + 1);
				}
			}, 500);
		}


	}, [id, localAJAXCache]);

	var topCompetencies = conceptScheme.HasTopConcept?.length > 0 ? conceptScheme.HasTopConcept.map((m) => { return concepts.filter((n) => { return n.CredentialRegistryURL == m })[0] }).filter((m) => { return m }) : concepts;
	var typeLabel = widgetGetSingleLabelForText("Concept Scheme");
	useEffect(() => {
		const ctid = conceptScheme.CTID;
		if (ctid != null) {
			getResourceByURLWithoutState(`${apiUrl}/GetRelatedResource/${ctid}/34`, null, (success: any) => {
				setRelatedWorkRoles(success.Result);
			});
		}

	}, [conceptScheme]);

	return (
		<LoadingOrError isLoading={dataIsLoading} hasError={hasError}>
			<PageBanner
				item={conceptScheme}
				bannerIcon={conceptsBannerIcon}
				fallbackIcon={conceptsBannerIcon}
			/>
			<Wrapper>
				<VStack>
					<PageSection icon={aboutIcon} title={"About this " + typeLabel} variant="Highlight" description={"Basic information about the " + typeLabel}>
						<Description>{conceptScheme.Description || "No description available"}</Description>
						{conceptScheme.Source && (
							<PageSectionItem>
								{conceptScheme.Source.map((url) => (<LinkObject item={{ URL: url, Label: "View this " + typeLabel }} />))}
							</PageSectionItem>
						)}
						{conceptScheme.PublicationStatusType && (
							<PageSectionItem>
								<Label>Publication Status Type</Label>
								{publicationStatusText && <a href={conceptScheme.PublicationStatusType} target="_blank">{publicationStatusText}</a>}
								{!publicationStatusText && <div><ActivityIndicator size="sm" /></div>}
							</PageSectionItem>
						)}
					</PageSection>
				</VStack>
				<VStack>
					<RelatedOrganizationPageSection item={conceptScheme} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " related to the " + typeLabel} />
				</VStack>
			</Wrapper>
			<FullWidthWrapper>
				<TabSetOrSingle
					activeIndex={0}
					items={[
						{
							Label: "Concepts",
							Content: (
								<>
									<AlignmentListHelper />
									<PageSection icon={conceptsIcon} title="Concepts" description={widgetGetPluralLabelForText("Concepts") + " in the " + typeLabel}>
										{topCompetencies && topCompetencies.map((concept) => (
											<Concept concept={concept} allConcepts={concepts} key={"concept-" + concept.CredentialRegistryURL} />
										))}
									</PageSection>
								</>
							)
						},
						{
							Label: "Details",
							Content: (
								<InnerWrapper>
									<VStack>
										<AdditionalInformationPageSection item={conceptScheme} pageSectionDescription={"Additional information about the " + typeLabel} />
									</VStack>
									<VStack>
										<SubjectsAndKeywordsPageSection item={conceptScheme} pageSectionDescription={"Subjects and Keywords related to the " + typeLabel} />
										{(
											relatedWorkRoles.Total > 0
										) && (
											<PageSection icon={connectionsIcon} title="Connections" description={"Related Resources"}>
											<ModalButtonAndWindow
												buttonLabel={"Related WorkRoles "}
												resourceTitle={conceptScheme.Name}
												items={relatedWorkRoles?.Values}
												Wrapper={Section}
												Content={() => <OutlineList items={relatedWorkRoles?.Values} />}
												hideCount={true}
											/>
											</PageSection>
											)}
									</VStack>
								</InnerWrapper>
							)
						}
					].filter(m => m.Content != null)}
				/>
				
			</FullWidthWrapper>
		</LoadingOrError>
	)
}

export function convertDataToConceptScheme(resource: any, metadata: any, id: string, setLocalAJAXCache: any): ConceptScheme {
	return {
		BroadType: "ConceptScheme",
		CTDLType: "skos:ConceptScheme",
		CTDLTypeLabel: "Concept Scheme",
		Meta_Id: id,
		CTID: resource["ceterms:ctid"],
		Meta_LastUpdated: metadata?.DateModified || "Unknown",
		Meta_LastUpdatedHeader: metadata?.DateModified || "Unknown",
		Meta_Language: resource["ceasn:inLanguage"]?.[0] || "en",
		Name: englishOrNull(resource["ceasn:name"]),
		Description: englishOrNull(resource["ceasn:description"]),
		Source: Array.isArray(resource["ceasn:source"]) ? resource["ceasn:source"] : [resource["ceasn:source"]],
		InLanguage: resource["ceasn:inLanguage"],
		ConceptKeyword: englishOrNull(resource["ceasn:conceptKeyword"])?.map((m: string) => { return createKeywordSearchLink("conceptscheme", m) }),
		ConceptTerm: resource["ceasn:conceptTerm"]?.map((m: string) => { return createKeywordSearchLink("conceptscheme", englishOrNull(m)) }),
		Creator: resource["ceasn:creator"]?.map((m: string) => { return getOrganizationOutlineByURI(m, "creator", setLocalAJAXCache) }),
		DateCreated: resource["ceasn:dateCreated"],
		DateCopyrighted: resource["ceasn:dateCopyrighted"],
		DateModified: resource["ceasn:dateModified"],
		License: resource["ceasn:license"],
		PublicationStatusType: resource["ceasn:publicationStatusType"]?.replace("http://", "https://")?.replace("publicationStatus:", "https://credreg.net/ctdlasn/vocabs/publicationStatus/"),
		Publisher: resource["ceasn:publisher"]?.map((m: string) => { return getOrganizationOutlineByURI(m, "publisher", setLocalAJAXCache) }),
		PublisherName: resource["ceasn:publisherName"] && [englishOrNull(resource["ceasn:publisherName"])],
		Rights: resource["ceasn:rights"],
		RightsHolder: resource["ceasn:rightsHolder"] && getOrganizationOutlineByURI(resource["ceasn:rightsHolder"], "rightsholder", setLocalAJAXCache),
		CredentialRegistryURL: resource["@id"],
		HasTopConcept: resource["skos:hasTopConcept"],
		RegistryData: { CTID: resource["ceterms:ctid"], Resource: { URL: resource["@id"], Label: "View Resource" }, Envelope: { URL: resource["@id"]?.replace("/resources/", "/envelopes/"), Label: "View Envelope" } },
		Meta_JSON: resource,
	} as ConceptScheme;
}


export function convertDataToConcept(resource: any, setLocalAJAXCache: any): ConceptType {
	return {
		CredentialRegistryURL: resource["@id"],
		CTID: resource["ceterms:ctid"],
		PrefLabel: englishOrNull(resource["skos:prefLabel"]),
		HiddenLabel: englishOrNull(resource["skos:hiddenLabel"]),
		AltLabel: englishOrNull(resource["skos:altLabel"]),
		Broader: resource["skos:broader"],
		BroadMatch: resource["skos:broadMatch"],
		ChangeNote: englishOrNull(resource["skos:changeNote"]),
		CloseMatch: resource["skos:closeMatch"],
		ExactMatch: resource["skos:exactMatch"],
		InScheme: resource["skos:inScheme"],
		Narrower: resource["skos:narrower"],
		NarrowMatch: resource["skos:narrowMatch"],
		Related: resource["skos:related"],
		TopConceptOf: resource["skos:topConceptOf"],
		Definition: englishOrNull(resource["skos:definition"]),
		Notation: resource["skos:notation"],
		RelatedWorkRole: resource["ceterms:ctid"] && getRelatedWorkRole(resource["ceterms:ctid"]),
		Note: englishOrNull(resource["skos:note"]),
		Meta_JSON: resource
	} as ConceptType;
}

function getOrganizationOutlineByURI(uri: string, temp: string, setLocalAJAXCache: any): Outline {
	var data = getResourceByURI(uri, setLocalAJAXCache).Data;
	return {
		Label: englishOrNull(data?.["ceterms:name"]) || <ActivityIndicator size='sm' color='inherit' />,
		URL: appURL + "/organization/" + uri.split("/resources/")[1],
		Image: data?.["ceterms:image"]
	} as Outline;
}

function getRelatedWorkRole(ctid: string) {
	var workroles= null;
	if (ctid != null) {
		getResourceByURLWithoutState(`${apiUrl}/GetRelatedResource/${ctid}/34`, null, (success: any) => {
			console.log("Success",success.Result);
			workroles= success.Result;
		});
		return workroles;
	}
}



function AlignmentListHelper() {
	wireupExtras();
	return (
		<>
			<style type="text/css">{`
				.concept { border-top: 1px solid #CCC; }
				.concept:empty { display: none; }
				.concept .conceptExtras:empty { display: none; }
				.concept .conceptExtras .conceptExtraListWrapper:not(.expanded) .conceptExtraListSummaryButton svg.expandedIcon { display: none; }
				.concept .conceptExtras .conceptExtraListWrapper.expanded .conceptExtraListSummaryButton svg.collapsedIcon { display: none; }
				.concept .conceptExtras .conceptExtraListWrapper:not(.expanded) .conceptExtraListValues .conceptExtraListItem:not(:first-child) { display: none; }
				.concept .conceptChildren { padding-left: 25px; }
			`}</style>
		</>
	)
}
function wireupExtras() {
	setTimeout(function () {
		var items = Array.from(document.querySelectorAll(".conceptExtraListWrapper:not(.setup)") || []);
		items.forEach(function (item) {
			item.classList.add("setup");
			item.querySelector(".conceptExtraListSummaryButton")?.addEventListener("click", function () {
				item.classList.contains("expanded") ? item.classList.remove("expanded") : item.classList.add("expanded");
			});
		});
		if (items.length > 0) {
			wireupExtras();
		}
	}, 100);
}

export function Concept(props: { concept: ConceptType, allConcepts: Array<ConceptType> | null }) {
	return props.concept && (
		<ConceptWrapper className="concept" data-ctid={props.concept.CTID} id={props.concept.CTID}>
			<ConceptContent className="conceptContent">
				<ConceptPrefix>
					{props.concept.Notation && <ConceptPrefixItem>{props.concept.Notation}</ConceptPrefixItem>}
				</ConceptPrefix>
				<ConceptBody>
					{(props.concept.PrefLabel || props.concept.AltLabel || props.concept.HiddenLabel) && (
						<ConceptName>
							{props.concept.PrefLabel && <span title="Preferred Label">{props.concept.PrefLabel}</span>}
							{props.concept.AltLabel && <span title="Alternate Label">{props.concept.AltLabel}</span>}
							{props.concept.HiddenLabel && <span title="Hidden Label">{props.concept.HiddenLabel}</span>}
						</ConceptName>
					)}
					{props.concept.Definition && <ConceptDefinition title="Definition">{props.concept.Definition}</ConceptDefinition>}
					{props.concept.Note && <ConceptNote title="Note">{props.concept.Note}</ConceptNote>}
					{props.concept.ChangeNote && <ConceptNote title="Change Note">{props.concept.ChangeNote}</ConceptNote>}
					{props.concept.CTID && <ConceptCTID title="CTID"><b>CTID:</b> <span>{props.concept.CTID}</span></ConceptCTID>}
				</ConceptBody>
			</ConceptContent>
			<ConceptExtras className="conceptExtras">
				<ConceptAlignmentList source={props.concept} relatedItems={props.allConcepts?.map(m => m.Meta_JSON) || []} />
				{props.concept.RelatedWorkRole?.Total > 0 &&
					<RelatedWorkRoles relatedResource={props.concept.RelatedWorkRole} />
				}
			</ConceptExtras>
			{(props.allConcepts || []).length > 0 && props.concept.Narrower?.length > 0 &&
				<ChildConcepts>
					{props.concept.Narrower.map((narrowerURI) => (
						<Concept concept={(props.allConcepts || []).filter((m) => { return m.CredentialRegistryURL == narrowerURI })[0]} allConcepts={props.allConcepts} key={"concept-" + narrowerURI} />
					))}
				</ChildConcepts>
			}
		</ConceptWrapper>
	) || null;
}

export function ConceptAlignmentList(props: { source: ConceptType, relatedItems: Array<any> }) {
	var conceptAlignments = [] as Array<any>;
	var alignmentTypes = [
		{ label: "Broad Match", property: "skos:broadMatch" },
		{ label: "Close Match", property: "skos:closeMatch" },
		{ label: "Exact Match", property: "skos:exactMatch" },
		{ label: "Narrow Match", property: "skos:narrowMatch" },
		{ label: "Related", property: "skos:related" }
	];

	alignmentTypes.forEach(type => {
		(props.source.Meta_JSON[type.property] || []).forEach((alignment: string) => {
			var match = props.relatedItems.filter(item => {
				return item["@id"] == alignment
			})[0];

			conceptAlignments.push(
				match ?
					{
						alignmentPrefix: "",
						alignmentLabel: type.label,
						alignmentTerm: type.property,
						alignmentPostfix: ":",
						targetURL: "/conceptscheme/ce-" + match["skos:inScheme"]?.split("/ce-")[1] + "?conceptCTID=" + match["ceterms:ctid"],
						targetLabel: englishOrNull(match["skos:prefLabel"])
					}
					:
					{
						alignmentPrefix: "External ",
						alignmentLabel: type.label,
						alignmentTerm: type.property,
						alignmentPostfix: ":",
						targetURL: alignment,
						targetLabel: alignment
					}
			);
		});
	});

	alignmentTypes.forEach(type => {
		props.relatedItems.forEach(relatedItem => {
			if (relatedItem[type.property]?.indexOf(props.source.Meta_JSON["@id"]) > -1) {
				conceptAlignments.push(
					{
						alignmentPrefix: "Reverse ",
						alignmentLabel: type.label,
						alignmentTerm: type.property,
						alignmentPostfix: " reference from:",
						targetURL: "/conceptscheme/ce-" + relatedItem["skos:inScheme"]?.split("/ce-")[1] + "?conceptCTID=" + relatedItem["ceterms:ctid"],
						targetLabel: englishOrNull(relatedItem["skos:prefLabel"])
					}
				)
			}
		});
	});

	return conceptAlignments.length > 0 && (
		<ConceptExtraListSimple
			count={conceptAlignments.length}
			label="Alignment"
			values={conceptAlignments.map((m, index) => ( 
				<AlignmentWrapper className="conceptExtraListItem alignmentWrapper" key={props.source.Meta_JSON["ceterms:ctid"] + "-" + m.alignmentTerm + "-" + index}>
					{(m.targetURL == m.targetLabel && m.targetURL.indexOf("credentialengineregistry.org") > 0) ?(
						<RenderAsyncResource url={m.targetURL} RenderComponent={(conceptData: any) => (
								<RenderAsyncResource url={conceptData.data["skos:inScheme"]||conceptData.data["ceasn:inProgressionModel"]} RenderComponent={(conceptSchemeData: any) => (
									<>{m.alignmentPrefix}<a href={m.alignmentTerm.replace("skos:", "https://credreg.net/ctdlasn/terms/")} target="_blank">{m.alignmentLabel}</a>{m.alignmentPostfix} <a href={appURL + "/conceptscheme/" + conceptSchemeData.data["ceterms:ctid"]} target="_blank">{englishOrNull(conceptSchemeData.data["ceasn:name"])}</a> : <a href={appURL + "/conceptscheme/" + conceptSchemeData.data["ceterms:ctid"] + "?targetCTID=" + conceptData.data["ceterms:ctid"]} target="_blank">{englishOrNull(conceptData.data["skos:prefLabel"])}</a></>
								)} /> 
						)} />
					) : (
						<>{m.alignmentPrefix}<a href={m.alignmentTerm.replace("skos:", "https://credreg.net/ctdlasn/terms/")} target="_blank">{m.alignmentLabel}</a>{m.alignmentPostfix} <a href={m.targetURL} target="_blank">{m.targetLabel}</a></>
					)}
				</AlignmentWrapper>
			))}
		/>
	) || null
}

export function RelatedWorkRoles(props: { relatedResource: AJAXSettings }) {
	const containerRef = useRef<HTMLUListElement | null>(null);

	const toggleVisibility = () => {
		if (containerRef.current) {
			if (containerRef.current.style.display === 'none') {
				containerRef.current.style.display = 'block';
				document.getElementById('arrowIconDown')?.setAttribute('style', 'display: none');
				document.getElementById('arrowIconUp')?.setAttribute('style', '');
			} else {
				containerRef.current.style.display = 'none';
				document.getElementById('arrowIconDown')?.setAttribute('style', '');
				document.getElementById('arrowIconUp')?.setAttribute('style', 'display: none');
			}
		}
	};

	return props.relatedResource.Total > 0 ? (
		<div style={{ marginLeft: '20px' }}>
			<button onClick={toggleVisibility} className="toggleButton" style={{ fontSize: '12px', border: 'none' }}>
				{containerRef.current && props.relatedResource.Total + ' Related Work Roles '}
				<span id="arrowIconDown" className="arrow-down">&#9660;</span>
				<span id="arrowIconUp" className="arrow-up" style={{ display: 'none' }}>&#9650;</span>
			</button>
			<ul ref={containerRef} style={{ display: 'none', listStyleType: 'none', margin: 0, padding: 0, fontSize: '12px' }}>
				{props.relatedResource.Values.map((m, index) => (
					<li key={index}>
						<a href={m.URL} target="_blank" rel="noopener noreferrer">
							{m.Label}
						</a>
						<br/>
						{m.Description}
					</li>
				))}
			</ul>
		</div>
  ) : null;
}


//Helper interface for RenderAsyncResource
interface ResourceData {
	URL: string,
	Data?: any | null,
	Error?: string | null,
	Triggered: boolean
}
//Condense multiple requests for the same resource into a single waiter, and only start requesting it when something scrolls into view
//For some reason, the RenderComponent function will receive an extra layer of "data" object, so keep that in mind when calling this method
function RenderAsyncResource(props: { url: string, RenderComponent: (props: { data: any }) => React.ReactElement, debugFlag?: string }) {
	//Only used to trigger React to re-render
	const [items, setItems] = useState([] as Array<ResourceData>);
	//Stores ref to the rendered spinner
	const waiterRef = useRef<HTMLDivElement>(null);

	//Ensure the window has a ResourceData array regardless of what initialized it
	if (!(window as any).ResourceData) {
		(window as any).ResourceData = [];
	}

	//Ensure the window has a ScrollHelper array regardless of what initialized it
	if (!(window as any).ScrollHelper) {
		(window as any).ScrollHelper = [];
		//Efficiently loop through ScrollHelpers on scroll and execute their .run() function if they are active
		(window as any).addEventListener("scroll", function () {
			for (var i = 0; i < (window as any).ScrollHelper.length; i++) {
				(window as any).ScrollHelper[i].active && (window as any).ScrollHelper[i].run();
			}
		});
	}

	//Initialize the Component when the spinner renders
	var checkInterval = 0 as any;
	useEffect(() => {
		//If there is a rendered spinner, but the ScrollHelper hasn't been injected into the window's ScrollHelper array yet...
		if (waiterRef.current && !(window as any).ScrollHelper.find((m: any) => m.current == waiterRef.current)) {
			//Create a ScrollHelper and inject it
			console.log("Adding waiter ref to scroll listener");
			(window as any).ScrollHelper.push({
				//Reference to the rendered spinner, used for finding this ScrollHelper later
				current: waiterRef.current,
				//Indicates whether or not this ScrollHelper is active - use this instead of removing it from the array so we don't risk accidentally adding it twice in the midst of React's async nonsense
				active: true,
				//Function to run every time window onscroll is checked
				run: checkScrollPosition
			});
		}

		//Also force a check periodically to handle things like accordions or things that modify CSS instead of using React's render loop
		clearInterval(checkInterval);
		checkInterval = setInterval(checkScrollPosition, 500);
	}, [waiterRef.current]); //Only trigger this init when the spinner changes

	function checkScrollPosition() {
		//If the rendered spinner is visible on the window...
		var top = (waiterRef.current?.getBoundingClientRect().top || 0);
		if ((window as any).outerHeight > top && top > 0) {
			//Trigger the AJAX call (this will also trigger event handlers for anything else that references that same URL, should be okay enough performance-wise)
			loadData();
			//Deactivate the ScrollHelper so it doesn't get triggered twice
			var scrollHelper = (window as any).ScrollHelper.find((m: any) => m.current == waiterRef.current);
			scrollHelper && (scrollHelper.active = false);
			//Clear the periodic checker
			clearInterval(checkInterval);
		}
	}

	//Get the ResourceData for the current URL
	var resourceDataArray = (window as any).ResourceData as Array<ResourceData>;
	var resourceData = resourceDataArray.find(m => m.URL == props.url) || { URL: props.url, Triggered: false };

	//AJAX load the data
	function loadData() {
		//If the current ResourceData has not already been triggered...
		if (!resourceData.Triggered) {
			//Trigger it
			resourceData.Triggered = true;
			console.log("loading data", resourceData.URL);
			//Push it into the window's ResourceData array
			resourceDataArray.push(resourceData);
			//Trigger React to re-render
			setItems([...((window as any).ResourceData as Array<ResourceData>)]);
			//Fetch the data and handle errors
			fetch(resourceData.URL).then(result => {
				try {
					if (result.ok) {
						return result.json();
					}
					else {
						resourceData = (resourceData as ResourceData);
						resourceData.Error = result.statusText;
						setItems([...((window as any).ResourceData as Array<ResourceData>)]);
						console.log("Error loading URL", { debugFlag: props.debugFlag, resourceData: resourceData });
					}
				}
				catch (e) {
					resourceData = (resourceData as ResourceData);
					resourceData.Error = (e as any).toString();
					setItems([...((window as any).ResourceData as Array<ResourceData>)]);
					console.log("Error loading or parsing URL", { debugFlag: props.debugFlag, resourceData: resourceData });
				}
			}).then(json => {
				resourceData = (resourceData as ResourceData);
				resourceData.Data = json;
				setItems([...((window as any).ResourceData as Array<ResourceData>)]);
				console.log("Loaded URL", { debugFlag: props.debugFlag, resourceData: resourceData });
			});
		}
	}

	//Return the rendered component if data is present, otherwise return a generic error div if there was an error, otherwise return a spinner if nothing has happened/we're still waiting
	return resourceData.Data ? <props.RenderComponent data={resourceData.Data} data-debugflag={props.debugFlag} /> :
		resourceData.Error ? <div data-debugflag={props.debugFlag} className="renderAsyncResourceError"><div>Error loading data for {resourceData.URL}</div><div>{resourceData.Error}</div></div> :
			<div ref={waiterRef}><ActivityIndicator data-url={resourceData.URL} data-debugflag={props.debugFlag} size="sm" /></div>
}

export function ConceptExtraListSimple(props: { values: Array<ReactNode>, count: number, label: string }) {
	return props.values?.length > 0 && (
		<ConceptExtraListWrapper className="conceptExtraListWrapper">
			{props.count > 1 && <ConceptExtraListSummaryButton className="conceptExtraListSummaryButton">{props.count} {props.label + "s"} <FontAwesomeIcon className="expandedIcon" icon={faAngleDown} /><FontAwesomeIcon className="collapsedIcon" icon={faAngleRight} /></ConceptExtraListSummaryButton>}
			{props.count == 1 && <ConceptExtraListSummaryWrapper className="conceptExtraListSummaryWrapper">{props.count} {props.label}</ConceptExtraListSummaryWrapper>}
			<ConceptExtraListValues className="conceptExtraListValues">
				{props.values}
			</ConceptExtraListValues>
		</ConceptExtraListWrapper>
	) || null
}

const ConceptWrapper = styled.div`
	padding: 5px;
	border-top: 1px solid #CCC;
`;

const ConceptContent = styled.div`
	display: flex;
	padding-left: 10px;

`;

const ConceptPrefix = styled.div`

`;

const ConceptPrefixItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 12px;
	min-width: 50px;
	max-width: 150px;
	word-break: break-word;
	margin-right: 5px;
	background-color: #F5F5F5;
	padding: 5px;
	text-align: center;
`;

const ConceptBody = styled.div`

`;

const ChildConcepts = styled.div`
	padding-left: 25px;
`;

const ConceptName = styled.div`
	font-weight: bold;

	& span:not(:first-child) {
		border-left: 1px solid #CCC;
		padding-left: 10px;
		margin-left: 10px;
	}
`;

const ConceptDefinition = styled.div`
	
`;

const ConceptNote = styled.div`
	font-style: italic;
	padding: 0 10px;
	font-size: 90%;
`;

const ConceptCTID = styled.div`
	padding: 5px 10px;
	font-size: 12px;
	font-style: italic;
`;

const ConceptExtras = styled.div`
	padding: 5px 0 5px 10px;
`;

const ConceptExtraListWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	font-size: 12px;
	border-top: 1px dashed #CCC;
	padding: 5px 5px;

`;

const ConceptExtraListSummaryWrapper = styled.div`
	min-width: 150px;
	margin-right: 10px;
	padding: 1px 5px;
`;

const ConceptExtraListSummaryButton = styled.button`
	display: flex;
	align-items: center;
	min-width: 150px;
	margin-right: 10px;
	border: none;
	background-color: transparent;
	font-size: 12px;
	padding: 1px 5px;

	& svg {
		margin-left: auto;
	}

	&:hover, &:focus {
		cursor: pointer;
		text-decoration: underline;
	}
`;

const ConceptExtraListValues = styled.div`
	width: 100%;
`;


const ConceptExtraListItem = styled.div`
	padding: 2.5px 0;
	border-top: 1px dashed #CCC;

	&:first-child {
		border: none;
	}

	& a {
		word-break: break-word;
	}
`;

const AlignmentWrapper = styled(ConceptExtraListItem)`

`;
