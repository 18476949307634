import styled from 'styled-components/macro';
import { ContentDelimiter } from '../../components/common';
import background from '../../assets/images/icons/background-dot-light-blue-01.svg';

export const Wrapper = styled(ContentDelimiter)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 80px;
    padding-top: 40px;
    padding-bottom: 60px;
    background-image: url(${background});
    @media (max-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        grid-template-columns: 1fr;
        gap: 0;
        padding: 0;
    }

	&:not(:last-child) {
		padding-bottom: 0;
	}
`;

export const FullWidthWrapper = styled(ContentDelimiter)`
	background-image: url(${ background });
	padding: 10px 20px 60px 20px;
`;

export const InnerWrapper = styled(Wrapper)`
	padding: 0;
`;

export const MobileAboutDetail = styled.div`
    background-color: ${(p) => p.theme.color.aquaLight};
    padding: 0 10px;
`;

export const Description = styled.div`
    margin-bottom: 30px;
	word-break: break-word;
	white-space: pre-wrap;
`;

export const Label = styled.label`
	font-size: 18px;
    margin-bottom: 10px;
`;

export const InlineLabel = styled.label`
    margin-right: 10px;
`;

export const Inline = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

export const MapWrapper = styled.div`
    width: auto;
    height: 500px;
    margin-bottom: 10px;
`;

export const SectionLabel = styled.div`
	font-size: 18px;
	border-top: 1px solid #CCC;
	padding: 5px 0;
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const InlineLabelledItem = styled.div`
	display: flex;
	align-items: baseline;
	margin-bottom: 10px;

	& > * {
		margin: 0 5px;
	}

	&:last-of-type {
        margin: 0;
    }
`;

export const Id = styled.span`
    margin-right: 5px;

    &:last-of-type {
        margin-right: 0;
    }
`;

export const SocialLink = styled.a`
    cursor: pointer;
    margin-right: 15px;

    &:last-of-type {
        margin-right: 0;
    }
`;

export const ModalLinksWrapper = styled.div`
	display: flex;
    flex-wrap: wrap;
	padding: 5px 0;
`;

export const ModalLink = styled.span`
    margin: 1px 5px;
    display: inline-block;

    &:first-of-type {
        margin-left: 0;
    }
    &:last-of-type {
        margin-right: 0;
    }
`;

export const UnorderedList = styled.ul`
	margin: 0;
`;

export const ListWithoutBullet = styled.li`
    list-style: none;
`;
