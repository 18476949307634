export function isFilled(item: any) {
    if (Array.isArray(item)) {
        return item.length > 0;
    }

    if (Number.isInteger(item)) {
        return true;
    }

    return Boolean(item);
}
