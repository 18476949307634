import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const PillWrapper = styled.div`
    margin: 2px 5px;
    background-color: ${(props) => props.theme.color.greyLight};
    border: 1px solid ${(props) => props.theme.color.greyLight};
    border-radius: 20px;
`;

export const Label = styled.span`
    margin: 0 10px;
`;
