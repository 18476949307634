import React, { ReactNode, useEffect, useState, useRef } from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components/macro';
import { apiUrl, appBaseName, appURL, registryURL } from '../../shared/globals';
import { RouteComponentProps } from 'react-router-dom';
import LoadingOrError from '../../components/LoadingOrError';
import { Collection as CollectionType, Outline, Link, AJAXSettingsOutline, Competency, ConditionProfile, ValueProfile, CostManifest, CostProfile, AJAXSettings, JurisdictionProfile, CostItem, Place, IdentifierValue } from '../../types/external';
import PageBanner from '../../components/DetailPage/PageBanner';
import {
	Wrapper,
	FullWidthWrapper,
	InnerWrapper,
	Description,
	Section,
	Label
} from '../../components/DetailPage/styles';
import { VStack } from '../../components/Stack';
import PageSection from '../../components/PageSection';
import { getResourceByURI, englishOrNull, createKeywordSearchLink, getResourceByURLWithoutState, createKeywordSearchLinkListFromCredentialAlignmentObjectList } from '../../components/GetResourceByURI';
import competenciesbannerIcon from '../../assets/images/icons/icon-competencies-white-green-01.svg';
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import LinkObject from '../../components/DetailPage/LinkObject';
import PageSectionItem from '../../components/PageSectionItem';
import SearchPills from '../../components/SearchPills';
import SubjectsAndKeywordsPageSection from '../../components/DetailPage/SubjectsAndKeywordsPageSection';
import OccupationsAndIndustriesSection from '../../components/DetailPage/OccupationsAndIndustriesSection';
import RelatedOrganizationPageSection, { RelatedOrganizationPageSectionAJAXResourceList } from '../../components/DetailPage/RelatedOrganizationPageSection';
import ActivityIndicator from '../../components/ActivityIndicator';
import { convertDataToCompetency, renderCompetency } from '../CompetencyFrameworkPage/indexv2';
import { createMiniSearch, getUUID, getElementEventually } from '../../components/MiniSearch';
import { ModalButtonAndWindow, ModalButtonAndWindowWithRegistrySearch, OutlineResult } from '../../components/Modal';
import AJAXModal from '../../components/Modal/AJAXModal';
import { renderCompetencyFromRDF, CompetencyStyleBlock } from '../../components/DetailPage/Competency';
import AJAXResourceList, { appendAJAXCacheV3ResourceRaw, createElement, getEnglish, renderText, renderLink, renderImage, renderElement, getResourceViaResourceCacheV3, lookupSchema, getDetailPageHeaderModifiedDate } from '../../components/AJAXResourceList';
import ConditionProfileList from '../../components/DetailPage/ConditionProfile';
import { renderJSONLDMetaTags } from '../../utils/JSONLDMetaHelper';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";
import { LinkItem } from "../../components/DetailPage/Shared";

interface Props {
	id: string;
	targetCTID?: string;
}

export default function CollectionPage(props: RouteComponentProps<Props>) {
	const id = props.match.params.id;
	const [dataIsLoading, setDataIsLoading] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [collection, setCollection] = useState({} as CollectionType);
	const [rawCollectionData, setRawCollectionData] = useState({} as any);
	const [relatedItemsMap, setRelatedItemsMap] = useState({} as any);
	const [graph, setGraph] = useState([] as Array<any>);
	const [localAJAXCache, setLocalAJAXCache] = useState([] as Array<any>); //Used to trick react into re-rendering the data
	const [membershipConditionList, setMembershipConditionList] = useState([] as Array<ConditionProfile>);

	var rawCollectionDataNow = {} as any;
	useEffect(() => {
		//var usingCTID = isNaN(id as any);
		//var url = usingCTID ? `${registryURL}/graph/${id}` : `${apiUrl}/detail/collection/${id}`;
		//getResourceByURI(url, setLocalAJAXCache, null, (loaded: any) => {
		getResourceByURI(`${apiUrl}/dsp_collection/${id}?includeRelatedResources=false&includeGraphData=true&perBranchLimit=-1`, setLocalAJAXCache, null, (loaded: any) => {
			//Get data
			rawCollectionDataNow = loaded.Result.Resource;
			renderJSONLDMetaTags(rawCollectionDataNow, "Collection");
			setRawCollectionData(loaded.Result.Resource); //Takes extra rendering cycles for some stupid reason so double assign it
			var metadata = loaded.Result.Metadata || {};
			var relatedItemsMapData = loaded.Result.RelatedItemsMap || {};
			var graphItemsData = loaded.Result.RelatedItems || [] as Array<any>;
			setDataIsLoading(false);
			setCollection(convertDataToCollection(rawCollectionDataNow || { "ceterms:name": "Missing Collection", "ceterms:description": "Unable to find data for identifier: " + id } as any, metadata));

			//Convert data
			convertDataToConditionProfileList(rawCollectionDataNow?.["ceterms:membershipCondition"], membershipConditionList, setMembershipConditionList);
			setGraph(graphItemsData);
			setRelatedItemsMap(relatedItemsMapData);
			console.log("Detail Data", { Collection: collection, Graph: graph });

			//Temporary(?) - Get link(s) to a search for members of the collection
			getResourceByURI(`${apiUrl}/collection/${id}`, setLocalAJAXCache, null, (loaded: any) => {
				collection.Connections = loaded?.Result?.Connections;
				setCollection({ ...collection });
			});

			//Get owner for header
			if (!collection.OwnedByLabel && rawCollectionDataNow["ceterms:ownedBy"]) {
				getResourceViaResourceCacheV3(rawCollectionDataNow["ceterms:ownedBy"]?.[0], (data: any) => {
					collection.OwnedByLabel = {
						Label: englishOrNull(data?.["ceterms:name"]),
						URL: appURL + "/resources/" + data["ceterms:ctid"]
					} as Link;
					//setCollection({ ...collection }); //Will randomly reset the collection data depending on which async process happens fastest, so just recalculate the whole thing
					var updatedCollection = convertDataToCollection(rawCollectionDataNow || { "ceterms:name": "Missing Collection", "ceterms:description": "Unable to find data for identifier: " + id } as any, metadata);
					setCollection({ ...updatedCollection, OwnedByLabel: collection.OwnedByLabel });
				});
			}
		},
			(error: any) => {
				setHasError(true);
				console.log("Error loading data: ", error);
			});

		new URLSearchParams(window.location.search).forEach(function (value: string, key: string) {
			if (key.toLowerCase() == "memberctid") {
				jumpToMemberCTID(value.toLowerCase(), 0);
			}
		});
	}, [id, localAJAXCache]);

	function convertDataToCollection(resource: any, metadata: any): CollectionType {
		return {
			BroadType: "Collection",
			CTDLType: "ceterms:Collection",
			CTDLTypeLabel: "Collection",
			Meta_Id: id,
			Meta_Language: resource["ceasn:inLanguage"]?.[0] || "en",
			Meta_LastUpdated: metadata?.DateModified || "Unknown",
			Meta_LastUpdatedHeader: getDetailPageHeaderModifiedDate(metadata?.DateModified),
			CTID: resource["ceterms:ctid"],
			Name: englishOrNull(resource["ceterms:name"]),
			Description: englishOrNull(resource["ceterms:description"]),
			SubjectWebpage: resource["ceterms:subjectWebpage"],
			InCatalog: resource["ceterms:inCatalog"],
			LatestVersion: resource["ceterms:latestVersion"],
			PreviousVersion: resource["ceterms:previousVersion"],
			NextVersion: resource["ceterms:nextVersion"],
			VersionIdentifier: createIdentifier(resource["ceterms:versionIdentifier"]),
			Subject: resource["ceterms:subject"]?.map((m: any) => { return createKeywordSearchLink("collection", getEnglish(m["ceterms:targetNodeName"])) }),
			//Keyword: englishOrNull(resource["ceterms:keyword"])?.map((m: string) => { return createKeywordSearchLink("collection", m) }), //Need to ensure keywords are being published as a multi-value field before uncommenting this
			//CollectionType: resource["ceterms:collectionType"]?.map((m: any) => { return createKeywordSearchLink("collection", englishOrNull(m["ceterms:targetNodeName"])) }),
			CollectionType: createKeywordSearchLinkListFromCredentialAlignmentObjectList("collection", resource["ceterms:collectionType"]),
			HasMember: resource["ceterms:hasMember"] || [],
			//OwnedBy: resource["ceterms:ownedBy"] && { Values: resource["ceterms:ownedBy"]?.map((m: string) => { return getOrganizationOutlineByURI(m) }) } as AJAXSettingsOutline,
			RegistryData: { CTID: resource["ceterms:ctid"], Resource: { URL: resource["@id"], Label: "View Resource" }, Envelope: { URL: resource["@id"]?.replace("/resources/", "/envelopes/"), Label: "View Envelope" } },
			//OwnedByLabel: resource["ceterms:ownedBy"]?.map((m: string) => { return getOrganizationOutlineByURI(m) })?.map((m: any) => { return { Label: m.Label, URL: m.URL } as Link; })[0],
			//MembershipCondition: resource["ceterms:membershipCondition"]?.map((m: any) => { return convertDataToConditionProfile(m); }),
			Meta_JSON: resource
		} as CollectionType;
	}

	function jumpToMemberCTID(targetCTID: string, attempts: number) {
		console.log("jumping to CTID", targetCTID)
		setTimeout(function () {
			var element = document.querySelector("[data-ctid='" + targetCTID + "']");
			if (element) {
				element.scrollIntoView();
			}
			else if (attempts <= 10) {
				jumpToMemberCTID(targetCTID, attempts + 1);
			}
		}, 500);
	}

	function convertDataToConditionProfileList(sourceList: any, stateList: Array<any>, setStateMethod: any) {
		if (sourceList && sourceList.length > 0) {
			setStateMethod(sourceList.map((m: any) => { return {} as ConditionProfile }));
			sourceList.forEach((sourceItem: any, index: number) => {
				convertDataToConditionProfile(sourceItem, index, stateList, setStateMethod);
			});
		}
	}

	function convertDataToConditionProfile(source: any, index: number, stateList: Array<any>, setStateMethod: any) {
		//Convert basic data
		var converted = convertBasicDataToBasicConditionProfile(source);

		//Load additional reference data
		//It looks like CommonCosts is never used in the condition profile(?)
		loadResourceSetAsAJAXSettingsOutline(source["ceterms:assertedBy"], converted, "AssertedBy", refreshProfile);
		loadResourceSetAsAJAXSettingsOutline(source["ceterms:targetCredential"], converted, "TargetCredential", refreshProfile);
		loadResourceSetAsAJAXSettingsOutline(source["ceterms:targetAssessment"], converted, "TargetAssessment", refreshProfile);
		loadResourceSetAsAJAXSettingsOutline(source["ceterms:targetLearningOpportunity"], converted, "TargetLearningOpportunity", refreshProfile);
		loadResourceSetAsAJAXSettingsOutline(source["ceterms:targetCompetency"], converted, "TargetCompetency", refreshProfile);

		//Refresh the profile at least once regardless
		refreshProfile();

		function refreshProfile() {
			stateList[index] = converted;
			setStateMethod([...stateList]);
		}
	}

	function loadResourceSetAsAJAXSettingsOutline(sourceURIs: Array<string>, converted: ConditionProfile, propertyName: string, refreshProfile: any) {
		if (!sourceURIs || sourceURIs.length == 0) {
			return;
		}

		(converted as any)[propertyName] = {
			Total: sourceURIs.length,
			Values: sourceURIs.map(uri => {
				return { Label: "Loading..." } as Outline;
			})
		} as AJAXSettingsOutline

		sourceURIs.forEach((uri, index) => {
			getResourceViaResourceCacheV3(uri, data => {
				(converted as any)[propertyName].Values[index] = {
					Label: englishOrNull(data["ceterms:name"]),
					Description: englishOrNull(data["ceterms:description"]),
					URL: appURL + "/resources/" + data["ceterms:ctid"],
					Image: data["ceterms:image"]
				} as Outline;
				refreshProfile();
			}, error => {
				(converted as any)[propertyName].Values[index].Label = "Error loading data for URI: " + uri;
				refreshProfile();
			});
		});
	}

	function convertBasicDataToBasicConditionProfile(source: any) {
		return {
			//Alternative Condition isn't fully supported here, because React will only allow the useState function to be called from inside a Component function and there's no /good/ way to handle the recursive nature of Alternative Condition here
			AlternativeCondition: source["ceterms:alternativeCondition"]?.map((item: any) => { return convertBasicDataToBasicConditionProfile(item); }),
			Name: englishOrNull(source["ceterms:name"]) || "",
			Description: englishOrNull(source["ceterms:description"]) || "",
			Condition: englishOrNull(source["ceterms:condition"]),
			SubmissionOf: englishOrNull(source["ceterms:submissionOf"]),
			SubmissionOfDescription: englishOrNull(source["ceterms:submissionOfDescription"]),
			CreditUnitTypeDescription: englishOrNull(source["ceterms:creditUnitTypeDescription"]),
			SubjectWebpage: source["ceterms:subjectWebpage"],
			DateEffective: source["ceterms:dateEffective"],
			Experience: englishOrNull(source["ceterms:expereince"]),
			Weight: source["ceterms:weight"],
			YearsOfExperience: source["ceterms:yearsOfExperience"],
			MinimumAge: source["ceterms:minimumAge"],
			AudienceLevelType: createKeywordSearchLinkListFromCredentialAlignmentObjectList("collection", source["ceterms:audienceLevelType"]),
			AudienceType: createKeywordSearchLinkListFromCredentialAlignmentObjectList("collection", source["ceterms:audienceType"]),
			CreditValue: createValueProfileListFromSourceList(source["ceterms:creditValue"]),
			EstimatedCost: createCostProfileListFromSourceList(source["ceterms:estimatedCost"]),
			Jurisdiction: createJurisdictionProfileListFromSourceList(source["ceterms:jurisdiction"]),
			ResidentOf: createJurisdictionProfileListFromSourceList(source["ceterms:residentOf"]),
		} as ConditionProfile;
	}

	function createValueProfileListFromSourceList(creditValueList: Array<any>) : Array<ValueProfile> | null {
		return creditValueList?.map(source => {
			return {
				CreditLevelType: createKeywordSearchLinkListFromCredentialAlignmentObjectList("collection", source["ceterms:creditLevelType"]),
				CreditUnitType: createKeywordSearchLinkListFromCredentialAlignmentObjectList("collection", source["ceterms:creditUnitType"]),
				Subject: createKeywordSearchLinkListFromCredentialAlignmentObjectList("collection", source["ceterms:subject"]),
				Description: englishOrNull(source["schema:description"]),
				MaxValue: source["schema:maxValue"] || 0,
				MinValue: source["schema:minValue"] || 0,
				Percentage: source["qdata:percentage"] || 0,
				Value: source["schema:value"] || 0
			} as ValueProfile;
		});
	}

	function createCostProfileListFromSourceList(costProfileList: Array<any>): Array<CostProfile> | null {
		return costProfileList?.map(source => {
			return {
				Description: englishOrNull(source["ceterms:description"]) || "",
				Name: englishOrNull(source["ceterms:name"]) || "",
				Condition: englishOrNull(source["ceterms:condition"]),
				CostDetails: source["ceterms:costDetails"],
				Currency: source["ceterms:currency"],
				CurrencySymbol: source["ceterms:currency"]?.toLowerCase() == "usd" ? "$" : source["ceterms:currency"],
				StartDate: source["ceterms:startDate"],
				EndDate: source["ceterms:endDate"],
				CostItems: createCostItemListFromSourceItem(source),
				Jurisdiction: createJurisdictionProfileListFromSourceList(source["ceterms:jurisdiction"])
			} as CostProfile;
		});
	}

	function createCostItemListFromSourceItem(source: any): Array<CostItem> | null {
		return source?.["ceterms:directCostType"]?.map((costType: any) => {
			return {
				AudienceType: createKeywordSearchLinkListFromCredentialAlignmentObjectList("collection", source["ceterms:audienceType"]),
				ResidencyType: createKeywordSearchLinkListFromCredentialAlignmentObjectList("collection", source["ceterms:residencyType"]),
				DirectCostType: createKeywordSearchLinkListFromCredentialAlignmentObjectList("collection", [costType])[0],
				PaymentPattern: englishOrNull(source["ceterms:paymentPattern"]),
				Price: source["ceterms:price"] || 0
			} as CostItem
		});
	}

	function createJurisdictionProfileListFromSourceList(jurisdictionProfileList: Array<any>): Array<JurisdictionProfile> | null {
		return jurisdictionProfileList?.map(source => {
			return {
				//AssertedBy is not worth bothering with
				Description: englishOrNull(source["ceterms:description"]) || "",
				GlobalJurisdiction: source["ceterms:isGlobalJurisdiction"] == true ? true : false,
				MainJurisdiction: !source["ceterms:mainJurisdiction"] ? {} : createPlaceListFromSourceList(source["ceterms:mainJurisdiction"])[0],
				JurisdictionException: createPlaceListFromSourceList(source["ceterms:jurisdictionException"])
			} as JurisdictionProfile;
		});
	}

	function createPlaceListFromSourceList(placeList: Array<any>): Array<Place> {
		return (placeList || []).map(source => {
			return {
				AddressCountry: englishOrNull(source["ceterms:addressCountry"]),
				AddressRegion: englishOrNull(source["ceterms:addressRegion"]),
				AddressLocality: englishOrNull(source["ceterms:addressLocality"]),
				StreetAddress: englishOrNull(source["ceterms:streetAddress"]),
				PostalCode: source["ceterms:postalCode"],
				PostOfficeBoxNumber: source["ceterms:postOfficeBoxNumber"]
				//Identifier is not worth bothering with
				//TargetContactPoint is not worth bothering with
			} as Place;
		});
	}

	function createIdentifier(identifier: Array<any>) {
		return (identifier || []).map(source => {
			return {
				IdentifierType: englishOrNull(source["ceterms:identifierType"]),
				IdentifierTypeName: englishOrNull(source["ceterms:identifierTypeName"]),
				IdentifierValueCode: englishOrNull(source["ceterms:identifierValueCode"]),
			} as IdentifierValue;
		});
	}
	//Get all of the real URIs to fetch
	var collectionMembers = graph.filter(item => item["@type"] == "ceterms:CollectionMember");
	var collectionMemberResourceURLs = rawCollectionData?.["ceterms:hasMember"]?.filter((uri: string) => uri.includes("http")).concat(collectionMembers.map(member => member["ceterms:proxyFor"])) || [];

	//Inject resources and collection members for later use by the AJAXResourceList
	graph.forEach(item => {
		appendAJAXCacheV3ResourceRaw(item["@id"], item);
	});

	var typeLabel = widgetGetSingleLabelForText("Collection");

	return (
		<LoadingOrError isLoading={dataIsLoading} hasError={hasError}>
			<PageBanner
				item={collection}
				bannerIcon={competenciesbannerIcon}
				fallbackIcon={competenciesbannerIcon}
			/>
			<Wrapper>
				<VStack>
					<PageSection icon={aboutIcon} title={"About this " + typeLabel } variant="Highlight" description={"Basic information about the " + typeLabel }>
						<Description>{collection.Description || "No description available"}</Description>
						{collection.SubjectWebpage && (
							<PageSectionItem>
								<LinkObject item={{ URL: collection.SubjectWebpage, Label: "View this " + typeLabel }} />
							</PageSectionItem>
						)}
						{collection.CollectionType?.length > 0 && (
							<PageSectionItem>
								<Label>Collection Type</Label>
								<SearchPills links={collection.CollectionType} />
							</PageSectionItem>
						)}
						{collection.Connections?.length > 0 && (
							<PageSectionItem>
								<Label>Search Collection Members</Label>
								<SearchPills links={collection.Connections} />
							</PageSectionItem>
						)}
						{collection.InCatalog && (
							<PageSectionItem>
								<LinkObject item={{ URL: collection.InCatalog, Label: "View this " + typeLabel + " in the Course Catalog " }} />
							</PageSectionItem>
						)
						}
						{(collection.LatestVersion || collection.PreviousVersion || collection.NextVersion) &&
							<PageSectionItem>
								<Label>Versions</Label>
								<PageSectionItem>
									<LinkItem label="Latest Version:" item={collection.LatestVersion} />
									<LinkItem label="Previous Version:" item={collection.PreviousVersion} />
									<LinkItem label="Next Version:" item={collection.NextVersion} />
								</PageSectionItem>
							</PageSectionItem>
						}
					</PageSection>
				</VStack>
				<VStack>
					<RelatedOrganizationPageSectionAJAXResourceList source={rawCollectionData} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " related to the " + typeLabel } />
				</VStack>
			</Wrapper>
			<FullWidthWrapper>
				<style type="text/css">{`
					.competency { border-top: 1px solid #CCC; }
					.competency:empty { display: none; }
					.competency .competencyExtras:empty { display: none; }
					.competency .competencyExtras .competencyExtraListWrapper:not(.expanded) .competencyExtraListSummaryButton svg.expandedIcon { display: none; }
					.competency .competencyExtras .competencyExtraListWrapper.expanded .competencyExtraListSummaryButton svg.collapsedIcon { display: none; }
					.competency .competencyExtras .competencyExtraListWrapper:not(.expanded) .competencyExtraListValues .competencyExtraListItem:not(:first-child) { display: none; }
					.competency .competencyChildren { padding-left: 25px; }
				`}</style>
				<TabSetOrSingle
					activeIndex={0}
					items={[
						{
							Label: typeLabel + " Members",
							Content: (
								<PageSection icon={connectionsIcon} title="Members" description={"Members of the " + typeLabel }>
									<CollectionMemberCount>This {typeLabel} contains {rawCollectionData?.["ceterms:hasMember"]?.length} members.</CollectionMemberCount>
									<AJAXResourceList urls={collectionMemberResourceURLs} onSuccess={(data: any, itemContainer: any, itemList: any) => renderCollectionMemberDataV2(data, itemContainer, graph)} StyleComponent={CollectionMemberStyleBlock} />
								</PageSection>
							)
						},
						{
							Label: "Details",
							Content: (
								<InnerWrapper>
									<VStack>
										<AdditionalInformationPageSection item={collection} pageSectionDescription={"Additional information about the " + typeLabel } />
									</VStack>
									<VStack>
										{membershipConditionList?.length > 0 && (
											<PageSection icon={aboutIcon} title="Membership Conditions" description={"Resources must meet these requirements to be included in this " + typeLabel }>
												<ModalButtonAndWindow
													buttonLabel="Membership Conditions"
													resourceTitle={collection.Name}
													items={membershipConditionList}
													Wrapper={Section}
													Content={() => <ConditionProfileList items={membershipConditionList} />}
												/>
											</PageSection>
										)}
										<SubjectsAndKeywordsPageSection item={collection} pageSectionDescription={"Subjects and Keywords related to the " + typeLabel } />
										<OccupationsAndIndustriesSection item={collection} pageSectionDescription={"Career Field information about the " + typeLabel} itemTypeLabel={widgetGetPluralLabelForText("Collections")} />
										<RelatedDataPageSection item={collection} relatedItemsMap={relatedItemsMap} />
									</VStack>
								</InnerWrapper>
							)
						}
					].filter(m => m.Content != null)}
				/>
			</FullWidthWrapper>
		</LoadingOrError>
	)
}

function renderCollectionMemberDataV2(data: any, itemContainer: any, graph: Array<any>) {
	//Finish setting up the box
	itemContainer.classList.add("collectionMemberListItem");
	itemContainer.innerHTML = "";

	//If there is an associated collection member, render it
	var typeLabel = widgetGetSingleLabelForText("Collection");
	var member = graph.find(item => item["ceterms:proxyFor"] == data["@id"]);
	if (member) {

		var memberBox = itemContainer.appendChild(createElement("div", "collectionMemberBox"));
		//renderText(memberBox, member["ceterms:name"], "div", "collectionMemberName", "Collection Member Name");
		//renderText(memberBox, member["ceterms:description"], "div", "collectionMemberDescription", "Collection Member Description");
		renderText(memberBox, member["ceterms:startDate"] ? "Membership Begins: " + member["ceterms:startDate"] : "", "div", "collectionMemberDate", "Start date of the membership of this Resource in this " + typeLabel + ".");
		renderText(memberBox, member["ceterms:endDate"] ? "Membership Ends: " + member["ceterms:endDate"] : "", "div", "collectionMemberDate", "End date of the membership of this Resource in this " + typeLabel + ".");

	}

	//Render the member resource data
	var resourceBox = itemContainer.appendChild(createElement("div", "resourceBox"));

	//If it's a competency, use the competency renderer
	if (data["@type"] == "ceasn:Competency") {
		renderCompetencyFromRDF(data, resourceBox, [], false);
	}
	//Otherwise, use the vanilla renderer
	else {
	
		var resourceBoxImage = resourceBox.appendChild(createElement("div", "resourceBoxImage"));
		var resourceBoxContent = resourceBox.appendChild(createElement("div", "resourceBoxContent"));
		var providerSource = data["ceterms:ownedBy"] || data["ceterms:offeredBy"] || data["ceasn:creator"] || data["ceasn:publisher"] || "";
		var providerURI = Array.isArray(providerSource) ? providerSource[0] : providerSource;
		var classification = data["ceterms:classification"] || "";
		//to add this css onely when there is an image
		if (data["ceterms:image"] || getImageUrl(data["@type"])) {
			resourceBox.style.display = 'flex';
			resourceBox.style.flexDirection = 'row';
		}
		renderImage(resourceBoxImage, (data["ceterms:image"] || getImageUrl(data["@type"] )), "resourceImage");
		renderElement(resourceBoxContent, " ", "div", "resourceHeader", (element, content) => {
			element.innerHTML = "";
			renderLink(element, appURL + "/resources/" + data["ceterms:ctid"], getEnglish(data["ceterms:name"] || data["ceasn:name"] || data["skos:prefLabel"] || "Unnamed Resource"), "_blank", "resourceLink");
			//renderText(element, "(" + (data["@type"] || "Unknown Type") + ")", "div", "resourceType");
			lookupSchema((schemaGraph) => {
				var label = englishOrNull(schemaGraph.find((graphItem: any) => {
					return graphItem["@id"] == data["@type"]
				})?.["rdfs:label"] || data["@type"] || "Unknown Type").replace(" Profile", "");
				renderText(element, "(" + label + ")", "div", "resourceType");
			});
		});
		providerURI && renderElement(resourceBoxContent, providerURI, "div", "resourceProvider", (box, uri) => {
			getResourceViaResourceCacheV3(uri, (data) => {
				box.innerHTML = "Provider: ";
				renderLink(box, appURL + "/organization/" + data["ceterms:ctid"], getEnglish(data["ceterms:name"]), "_blank");
			});
		});
		renderText(resourceBoxContent, getEnglish(data["ceterms:description"] || data["ceasn:description"] || data["skos:prefLabel"] || "No Description"), "div", "resourceDescription");
		if (classification && classification.length > 0) {
			const totalConcepts = classification.length;
			const totalConceptsButton = document.createElement("button");
			totalConceptsButton.innerHTML = totalConcepts + " Concepts";
			totalConceptsButton.classList.add("toggleButton");
			const downArrowIcon = document.createElement("span");
			downArrowIcon.innerHTML = "&#9660;";
			const upArrowIcon = document.createElement("span");
			upArrowIcon.innerHTML = "&#9650;";
			upArrowIcon.style.display = "none";
			totalConceptsButton.appendChild(downArrowIcon);
			totalConceptsButton.appendChild(upArrowIcon);
			resourceBox.appendChild(totalConceptsButton);

			const classificationContainer = document.createElement("div");
			classificationContainer.classList.add("classificationContainer");

			classification.forEach((classificationItem:any, index: any) => {
				renderElement(classificationContainer, classificationItem, "div", "classification", (box, uri) => {
					getResourceViaResourceCacheV3(uri, (data) => {
						renderLink(box, appURL + "/conceptscheme/" + data["skos:topConceptOf"].substring(data["skos:topConceptOf"].lastIndexOf("/") + 1) + "?targetCTID=" + data["ceterms:ctid"], getEnglish(data["skos:prefLabel"]), "_blank");
						renderText(classificationContainer, getEnglish(data["skos:definition"] || ""), "div", "conceptDescription");
					});
				});
			});

			resourceBox.appendChild(classificationContainer);

			classificationContainer.style.display = "block";

			totalConceptsButton.addEventListener("click", function () {
				if (classificationContainer.style.display === "none") {
					classificationContainer.style.display = "block";
					downArrowIcon.style.display = "none";
					upArrowIcon.style.display = "inline";
				} else {
					classificationContainer.style.display = "none";
					downArrowIcon.style.display = "inline";
					upArrowIcon.style.display = "none";
				}
			});
		} else {
		}



	}
}

function CollectionMemberStyleBlock() {
	return (
		<>
			<CompetencyStyleBlock />
			<VanillaRendererStyleBlock />
		</>
	)
}
//get iconurl with type
function getImageUrl(credentialType: any) {
	if (credentialType.includes("Badge")) {
		return "https://apps.credentialengine.org/services/images/icons/flat_badge_cropped.png";
	}else if (credentialType.includes("Certification") || credentialType.includes("Certificate") ) {
		return "https://apps.credentialengine.org/services/images/icons/flat_certificate_cropped.png";
	} else if (credentialType.includes("Degree")) {
		return "https://apps.credentialengine.org/services/images/icons/flat_degree_cropped.png";
	} else if (credentialType.includes("License")) {
		return "https://apps.credentialengine.org/services/images/icons/flat_license_cropped.png";
	} else if (credentialType.includes("Diploma")) {
		return "https://apps.credentialengine.org/services/images/icons/flat_diploma_cropped.png";
	} else if (credentialType.includes("Credential")) {
		return "https://apps.credentialengine.org/services/images/icons/flat_microcredential_cropped.png";
	}
}

const VanillaRendererStyleBlock = function () {
	return (<style type="text/css">{`
		.collectionMemberListItem { padding: 10px; border-top: 1px solid #CCC; }
		.collectionMemberListItem .collectionMemberBox { display: flex; gap: 20px; font-size: 12px; white-space: nowrap; font-style: italic; }
		.collectionMemberListItem .resourceBox {  }
		.collectionMemberListItem .resourceBox .resourceHeader { display: flex; gap: 10px; }
		.collectionMemberListItem .resourceBox .resourceHeader .resourceLink { font-weight: bold; }
		.collectionMemberListItem .resourceBox .resourceHeader .resourceType { font-style: italic; }
		.collectionMemberListItem .resourceBox .resourceProvider { font-size: 12px; padding: 0 10px; font-style: italic; }
		.collectionMemberListItem .resourceBox .resourceDescription { font-size: 14px; }
		.collectionMemberListItem .resourceBox .resourceBoxImage { max-width: 100px; max-height: 100px; flex: 0 0 auto; gap: 10px;}
		.collectionMemberListItem .resourceBox .resourceBoxContent {  margin-left: 10px;}
		.toggleButton { font-size: 12px; border: none; }
		.classificationContainer { margin-top: 5px; padding: 5px; border-radius: 5px; max-height: 150px;font-size: 12px; margin-right: 30px;}
	`}</style>);
}



function RelatedDataPageSection(props: { item: any, relatedItemsMap: any }) {
	if (!props.item || !props.relatedItemsMap || props.relatedItemsMap.length == 0 || Object.keys(props.relatedItemsMap).length == 0) {
		return null;
	}

	var relatedItemSets = [
		{ labelTemplate: "{number} Related Credentials", pathMatch: "< ceterms:Credential$", relatedItemURIs: [] as Array<string> },
		{ labelTemplate: "{number} Related Assessments", pathMatch: "< ceterms:AssessmentProfile$", relatedItemURIs: [] as Array<string> },
		{ labelTemplate: "{number} Related Learning Opportunities", pathMatch: "< ceterms:LearningOpportunityProfile$", relatedItemURIs: [] as Array<string> },
		{ labelTemplate: "{number} Related Courses", pathMatch: "< ceterms:Course$", relatedItemURIs: [] as Array<string> },
		{ labelTemplate: "{number} Related WorkRoles", pathMatch: "< ceterms:WorkRole$", relatedItemURIs: [] as Array<string> },
		{ labelTemplate: "{number} Related Jobs", pathMatch: "< ceterms:Job$", relatedItemURIs: [] as Array<string> },
		{ labelTemplate: "{number} Related Occupations", pathMatch: "< ceterms:Occupation$", relatedItemURIs: [] as Array<string> },
		{ labelTemplate: "{number} Related Tasks", pathMatch: "< ceterms:Task$", relatedItemURIs: [] as Array<string> }

	];

	//For each related item set...
	relatedItemSets.forEach(set => {
		//Find the related item maps that match its path and extract their URIs. For each such URI...
		props.relatedItemsMap.filter((map: any) => { return map.Path.match(set.pathMatch)?.length > 0 }).flatMap((map: any) => { return map.URIs }).forEach((uri: string) => {
			//Add it to the related item set's URIs list if it isn't already present
			if (!set.relatedItemURIs.includes(uri)) {
				set.relatedItemURIs.push(uri);
			}
		});
	});

	//Return null if there are no related item URIs that match
	if (relatedItemSets.filter((set: any) => { return set.relatedItemURIs.length > 0 }).length == 0) {
		return null;
	}

	{/*<AJAXModalForRelatedItems relatedItemsMap={props.relatedItemsMap} labelTemplate={set.labelTemplate} pathMatch={set.pathMatch} />*/ }
	return (
		<PageSection icon={connectionsIcon} title="Connections" description="Related Resources">
			<PageSectionItem className="relatedDataModals">
				{relatedItemSets.map((set: any) => (
					<ModalButtonAndWindow
						buttonLabel={set.labelTemplate.replace(/{number}/g, set.relatedItemURIs.length.toString())}
						resourceTitle={props.item.Name}
						items={set.relatedItemURIs}
						Wrapper={Section}
						Content={() => <AJAXResourceList urls={set.relatedItemURIs} onSuccess={(data: any, itemContainer: any, itemList: any) => renderCollectionMemberDataV2(data, itemContainer, [])} StyleComponent={CollectionMemberStyleBlock} />}
						hideCount={true}
					/>
				))}
			</PageSectionItem>
		</PageSection>
	)
}


function AJAXModalForRelatedItems(props: { relatedItemsMap: any, labelTemplate: string, pathMatch: string }) {
	if (!props.relatedItemsMap) {
		return null;
	}

	var maps = props.relatedItemsMap.filter((item: any) => item.Path.match(props.pathMatch));
	if (maps.length == 0) {
		return null;
	}

	var urls = maps.flatMap((map: any) => map.URIs);
	return <AJAXModal
		urls={urls}
		buttonLabel={props.labelTemplate.replace(/{number}/g, urls.length)}
		resourceTitle={props.labelTemplate.replace(/{number}/g, "").replace(/  /g, " ").trim()}
		SuccessRenderMethod={RenderGenericItem}
		ErrorRenderMethod={RenderError}
	/>
}

function RenderGenericItem(props: { data: any, index: number }) {
	const [provider, setProvider] = useState({} as any);

	useEffect(() => {
		var providerURI = (props.data["ceterms:ownedBy"] || props.data["ceterms:offeredBy"] || props.data["ceasn:creator"] || props.data["ceasn:publisher"] || [])[0];
		if (providerURI) {
			getResourceByURLWithoutState(providerURI, null, (result: any) => {
				setProvider(result);
			}, (error: any) => {
				console.log("Error", error);
				setProvider({ "ceterms:name": { "en": "Error loading provider data." }, "ceterms:ctid": "" });
			});
		}
	}, []);

	return <GenericItem>
		<GenericTitle href={appURL + "/resources/" + props.data["ceterms:ctid"]}>{englishOrNull(props.data["ceterms:name"] || props.data["ceasn:name"])}</GenericTitle>
		{provider ? <GenericProvider>Provider: <a href={appURL + "/organization/" + provider["ceterms:ctid"]} >{englishOrNull(provider["ceterms:name"])}</a></GenericProvider> : <div><ActivityIndicator size="sm" /></div>}
		<GenericDescription>{englishOrNull(props.data["ceterms:description"] || props.data["ceasn:description"])}</GenericDescription>
	</GenericItem>
}

function RenderError(props: { error: any, index: number }) {
	return <Error>{props.error}</Error>
}

const CollectionMembersListWrapper = styled.div`
	
`;

const CollectionMemberCount = styled.div`
	padding: 10px 5px; font-style: italic;
`;

const CollectionItem = styled.div`
	border-top: 1px solid #CCC;
`;

const MemberDataWrapper = styled.div`
	padding: 10px;
`;

const CollectionMemberHeader = styled.div`
	display: flex; padding: 5px 10px; font-style: italic; font-size: 12px;

	& span {
		margin-right: 20px;
	}
`;

const MemberHeader = styled.div`
	display: flex;
`;

const MemberName = styled.div`
	font-weight: bold;
	margin-right: 20px;
`;

const MemberType = styled.div`
	font-style: italic;
`;

const MemberDescription = styled.div`
	padding: 0 10px; font-size: 14px;
`;

const RelatedListItem = styled.div`

`

const GenericItem = styled(RelatedListItem)`

`;

const GenericTitle = styled.a`
	display: block;
	font-weight: bold;
`;

const GenericProvider = styled.div`
	display: block;
	padding: 2px 5px;
	font-size: 85%;
	font-style: italic;
`;

const GenericDescription = styled.div`

`;

const Error = styled(RelatedListItem)`

`;
