import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { apiUrl } from '../../shared/globals';
import { RouteComponentProps } from 'react-router-dom';
import LoadingOrError from '../../components/LoadingOrError';
import { appURL, appBaseName } from '../../shared/globals';
import { ProgressionModel, ProgressionLevel as ProgressionLevelType, Outline, Link } from '../../types/external';
import conceptsBannerIcon from '../../assets/images/icons/icon-competencies-white-green-01.svg';
import conceptsIcon from '../../assets/images/icons/icon-competencies-blue-green-01.svg';
import PageBanner from '../../components/DetailPage/PageBanner';
import {
	Wrapper,
	FullWidthWrapper,
	InnerWrapper,
	Description,
	Label
} from '../../components/DetailPage/styles';
import { VStack } from '../../components/Stack';
import PageSection from '../../components/PageSection';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import { getResourceByURI, englishOrNull, createKeywordSearchLink, getResourceByURLWithoutState } from '../../components/GetResourceByURI';
import ActivityIndicator from '../../components/ActivityIndicator';
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import LinkObject from '../../components/DetailPage/LinkObject';
import PageSectionItem from '../../components/PageSectionItem';
import SearchPills from '../../components/SearchPills';
import SubjectsAndKeywordsPageSection from '../../components/DetailPage/SubjectsAndKeywordsPageSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { renderJSONLDMetaTags } from '../../utils/JSONLDMetaHelper';


interface Props {
	id: string;
	targetCTID?: string;
}

export default function ProgressionModelPage(props: RouteComponentProps<Props>) {
	const id = props.match.params.id;
	const [dataIsLoading, setDataIsLoading] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [progressionModel, setProgressionModel] = useState({} as ProgressionModel);
	const [progressionLevels, setProgressionLevels] = useState([] as Array<ProgressionLevelType>);
	const [localAJAXCache, setLocalAJAXCache] = useState([] as Array<any>); //Used to trick react into re-rendering the data

	//This is so backwards I hate this
	const [publicationStatusText, setPublicationStatusText] = useState("");

	useEffect(() => {
		console.log("App Name", appBaseName);
		getResourceByURI(`${apiUrl}/dsp_progressionmodel/${id}`, setLocalAJAXCache, null, (loaded: any) => {
			var data = loaded.Result;
			renderJSONLDMetaTags(data.Resource, "ProgressionModel");
			setDataIsLoading(false);
			setProgressionModel(convertDataToProgressionModel(data.Resource || { "ceasn:name": "Missing Progression Model", "ceasn:description": "Unable to find data for identifier: " + id }, data.Metadata, id, setLocalAJAXCache));
			setProgressionLevels(data.RelatedItems.filter((m: any) => { return m["skos:inScheme"] == data.Resource["@id"] || m["ceasn:inProgressionModel"] == data.Resource["@id"] }).map((m: ProgressionLevelType) => { return convertDataToConcept(m) }));

			if (progressionModel.PublicationStatusType) {
				getResourceByURLWithoutState(progressionModel.PublicationStatusType, null, (success: any) => {
					setPublicationStatusText(englishOrNull(success?.["@graph"]?.[0]?.["skos:prefLabel"]));
					console.log(publicationStatusText);
				}, (error: any) => {
					setPublicationStatusText("Error loading Status: " + progressionModel.PublicationStatusType);
				});
			}
		},
			(error: any) => {
				setHasError(true);
				console.log("Error loading data: ", error);
			});
		new URLSearchParams(window.location.search).forEach(function (value: string, key: string) {
			if (key.toLowerCase() == "conceptctid" || key.toLowerCase() == "targetctid") {
				jumpToConceptCTID(value.toLowerCase(), 0);
			}
		});

		function jumpToConceptCTID(targetCTID: string, attempts: number) {
			setTimeout(function () {
				var element = document.getElementById(targetCTID);
				if (element && element.getElementsByClassName("conceptContent").length > 0) {
					element.scrollIntoView();
				}
				else if (attempts <= 10) {
					jumpToConceptCTID(targetCTID, attempts + 1);
				}
			}, 500);
		}


	}, [id, localAJAXCache]);

	var topCompetencies = progressionModel.HasTopConcept?.length > 0 ? progressionModel.HasTopConcept.map((m) => { return progressionLevels.filter((n) => { return n.CredentialRegistryURL == m })[0] }).filter((m) => { return m }) : progressionLevels;

	return (
		<LoadingOrError isLoading={dataIsLoading} hasError={hasError}>
			<PageBanner
				item={progressionModel}
				bannerIcon={conceptsBannerIcon}
				fallbackIcon={conceptsBannerIcon}
			/>
			<Wrapper>
				<VStack>
					<PageSection icon={aboutIcon} title="About this Progression Model" variant="Highlight" description="Basic information about the Progression Model">
						<Description>{progressionModel.Description || "No description available"}</Description>
						{progressionModel.Source && (
							<PageSectionItem>
								{progressionModel.Source.map((url) => (<LinkObject item={{ URL: url, Label: "View this Progression Model" }} />))}
							</PageSectionItem>
						)}
						{progressionModel.PublicationStatusType && (
							<PageSectionItem>
								<Label>Publication Status Type</Label>
								{publicationStatusText && <a href={progressionModel.PublicationStatusType} target="_blank">{publicationStatusText}</a>}
								{!publicationStatusText && <div><ActivityIndicator size="sm" /></div>}
							</PageSectionItem>
						)}
					</PageSection>
				</VStack>
				<VStack>
					<RelatedOrganizationPageSection item={progressionModel} pageSectionDescription="Organizations related to the Progression Model" />
				</VStack>
			</Wrapper>
			<FullWidthWrapper>
				<TabSetOrSingle
					activeIndex={0}
					items={[
						{
							Label: "Progression Levels",
							Content: (
								<>
									<AlignmentListHelper />
									<PageSection icon={conceptsIcon} title="Progression Levels" description="Progression Levels in the Progression Model">
										{topCompetencies && topCompetencies.map((progressionLevel) => (
											<ProgressionLevel progressionLevel={progressionLevel} allConcepts={progressionLevels} key={"progressionLevel-" + progressionLevel.CredentialRegistryURL} />
										))}
									</PageSection>
								</>
							)
						},
						{
							Label: "Details",
							Content: (
								<InnerWrapper>
									<VStack>
										<AdditionalInformationPageSection item={progressionModel} pageSectionDescription="Additional information about the Progression Model" />
									</VStack>
									<VStack>
										<SubjectsAndKeywordsPageSection item={progressionModel} pageSectionDescription="Subjects and Keywords related to the Progression Model" />
									</VStack>
								</InnerWrapper>
							)
						}
					].filter(m => m.Content != null)}
				/>

			</FullWidthWrapper>
		</LoadingOrError>
	)
}

export function convertDataToProgressionModel(resource: any, metadata: any, id: string, setLocalAJAXCache: any): ProgressionModel {
	return {
		BroadType: "ProgressionModel",
		CTDLType: "skos:ProgressionModel",
		CTDLTypeLabel: "Progression Model",
		Meta_Id: id,
		CTID: resource["ceterms:ctid"],
		Meta_LastUpdated: metadata?.DateModified || "Unknown",
		Meta_LastUpdatedHeader: metadata?.DateModified || "Unknown",
		Meta_Language: resource["ceasn:inLanguage"]?.[0] || "en",
		Name: englishOrNull(resource["ceasn:name"]),
		Description: englishOrNull(resource["ceasn:description"]),
		Source: Array.isArray(resource["ceasn:source"]) ? resource["ceasn:source"] : [resource["ceasn:source"]],
		InLanguage: resource["ceasn:inLanguage"],
		ConceptKeyword: englishOrNull(resource["ceasn:conceptKeyword"])?.map((m: string) => { return createKeywordSearchLink("conceptscheme", m) }),
		ConceptTerm: resource["ceasn:conceptTerm"]?.map((m: string) => { return createKeywordSearchLink("conceptscheme", englishOrNull(m)) }),
		Creator: resource["ceasn:creator"]?.map((m: string) => { return getOrganizationOutlineByURI(m, "creator", setLocalAJAXCache) }),
		DateCreated: resource["ceasn:dateCreated"],
		DateCopyrighted: resource["ceasn:dateCopyrighted"],
		DateModified: resource["ceasn:dateModified"],
		License: resource["ceasn:license"],
		PublicationStatusType: resource["ceasn:publicationStatusType"]?.replace("http://", "https://")?.replace("publicationStatus:", "https://credreg.net/ctdlasn/vocabs/publicationStatus/"),
		Publisher: resource["ceasn:publisher"]?.map((m: string) => { return getOrganizationOutlineByURI(m, "publisher", setLocalAJAXCache) }),
		PublisherName: resource["ceasn:publisherName"] && [englishOrNull(resource["ceasn:publisherName"])],
		Rights: resource["ceasn:rights"],
		RightsHolder: resource["ceasn:rightsHolder"] && getOrganizationOutlineByURI(resource["ceasn:rightsHolder"], "rightsholder", setLocalAJAXCache),
		CredentialRegistryURL: resource["@id"],
		HasTopConcept: resource["skos:hasTopConcept"],
		RegistryData: { CTID: resource["ceterms:ctid"], Resource: { URL: resource["@id"], Label: "View Resource" }, Envelope: { URL: resource["@id"]?.replace("/resources/", "/envelopes/"), Label: "View Envelope" } },
		Meta_JSON: resource,
	} as ProgressionModel;
}


export function convertDataToConcept(resource: any): ProgressionLevelType {
	return {
		CredentialRegistryURL: resource["@id"],
		CTID: resource["ceterms:ctid"],
		PrefLabel: englishOrNull(resource["skos:prefLabel"]),
		HiddenLabel: englishOrNull(resource["skos:hiddenLabel"]),
		AltLabel: englishOrNull(resource["skos:altLabel"]),
		Broader: resource["skos:broader"],
		BroadMatch: resource["skos:broadMatch"],
		ChangeNote: englishOrNull(resource["skos:changeNote"]),
		CloseMatch: resource["skos:closeMatch"],
		ExactMatch: resource["skos:exactMatch"],
		InScheme: resource["skos:inScheme"],
		Narrower: resource["skos:narrower"],
		NarrowMatch: resource["skos:narrowMatch"],
		Related: resource["skos:related"],
		TopConceptOf: resource["skos:topConceptOf"],
		Definition: englishOrNull(resource["skos:definition"]),
		Notation: resource["skos:notation"],
		Note: englishOrNull(resource["skos:note"]),
		Meta_JSON: resource
	} as ProgressionLevelType;
}

function getOrganizationOutlineByURI(uri: string, temp: string, setLocalAJAXCache: any): Outline {
	var data = getResourceByURI(uri, setLocalAJAXCache).Data;
	return {
		Label: englishOrNull(data?.["ceterms:name"]) || <ActivityIndicator size='sm' color='inherit' />,
		URL: appURL + "/organization/" + uri.split("/resources/")[1],
		Image: data?.["ceterms:image"]
	} as Outline;
}



function AlignmentListHelper() {
	wireupExtras();
	return (
		<>
			<style type="text/css">{`
				.progressionLevel { border-top: 1px solid #CCC; }
				.progressionLevel:empty { display: none; }
				.progressionLevel .conceptExtras:empty { display: none; }
				.progressionLevel .conceptExtras .conceptExtraListWrapper:not(.expanded) .conceptExtraListSummaryButton svg.expandedIcon { display: none; }
				.progressionLevel .conceptExtras .conceptExtraListWrapper.expanded .conceptExtraListSummaryButton svg.collapsedIcon { display: none; }
				.progressionLevel .conceptExtras .conceptExtraListWrapper:not(.expanded) .conceptExtraListValues .conceptExtraListItem:not(:first-child) { display: none; }
				.progressionLevel .conceptChildren { padding-left: 25px; }
			`}</style>
		</>
	)
}
function wireupExtras() {
	setTimeout(function () {
		var items = Array.from(document.querySelectorAll(".conceptExtraListWrapper:not(.setup)") || []);
		items.forEach(function (item) {
			item.classList.add("setup");
			item.querySelector(".conceptExtraListSummaryButton")?.addEventListener("click", function () {
				item.classList.contains("expanded") ? item.classList.remove("expanded") : item.classList.add("expanded");
			});
		});
		if (items.length > 0) {
			wireupExtras();
		}
	}, 100);
}

export function ProgressionLevel(props: { progressionLevel: ProgressionLevelType, allConcepts: Array<ProgressionLevelType> | null }) {
	return props.progressionLevel && (
		<ConceptWrapper className="progressionLevel" data-ctid={props.progressionLevel.CTID} id={props.progressionLevel.CTID}>
			<ConceptContent className="conceptContent">
				<ConceptPrefix>
					{props.progressionLevel.Notation && <ConceptPrefixItem>{props.progressionLevel.Notation}</ConceptPrefixItem>}
				</ConceptPrefix>
				<ConceptBody>
					{(props.progressionLevel.PrefLabel || props.progressionLevel.AltLabel || props.progressionLevel.HiddenLabel) && (
						<ConceptName>
							{props.progressionLevel.PrefLabel && <span title="Preferred Label">{props.progressionLevel.PrefLabel}</span>}
							{props.progressionLevel.AltLabel && <span title="Alternate Label">{props.progressionLevel.AltLabel}</span>}
							{props.progressionLevel.HiddenLabel && <span title="Hidden Label">{props.progressionLevel.HiddenLabel}</span>}
						</ConceptName>
					)}
					{props.progressionLevel.Definition && <ConceptDefinition title="Definition">{props.progressionLevel.Definition}</ConceptDefinition>}
					{props.progressionLevel.Note && <ConceptNote title="Note">{props.progressionLevel.Note}</ConceptNote>}
					{props.progressionLevel.ChangeNote && <ConceptNote title="Change Note">{props.progressionLevel.ChangeNote}</ConceptNote>}
					{props.progressionLevel.CTID && <ConceptCTID title="CTID"><b>CTID:</b> <span>{props.progressionLevel.CTID}</span></ConceptCTID>}
				</ConceptBody>
			</ConceptContent>
			<ConceptExtras className="conceptExtras">
				<ConceptAlignmentList source={props.progressionLevel} relatedItems={props.allConcepts?.map(m => m.Meta_JSON) || []} />
			</ConceptExtras>
			{(props.allConcepts || []).length > 0 && props.progressionLevel.Narrower?.length > 0 &&
				<ChildConcepts>
					{props.progressionLevel.Narrower.map((narrowerURI) => (
						<ProgressionLevel progressionLevel={(props.allConcepts || []).filter((m) => { return m.CredentialRegistryURL == narrowerURI })[0]} allConcepts={props.allConcepts} key={"progressionLevel-" + narrowerURI} />
					))}
				</ChildConcepts>
			}
		</ConceptWrapper>
	) || null;
}

export function ConceptAlignmentList(props: { source: ProgressionLevelType, relatedItems: Array<any> }) {
	var conceptAlignments = [] as Array<any>;
	var alignmentTypes = [
		{ label: "Broad Match", property: "skos:broadMatch" },
		{ label: "Close Match", property: "skos:closeMatch" },
		{ label: "Exact Match", property: "skos:exactMatch" },
		{ label: "Narrow Match", property: "skos:narrowMatch" },
		{ label: "Related", property: "skos:related" }
	];

	alignmentTypes.forEach(type => {
		(props.source.Meta_JSON[type.property] || []).forEach((alignment: string) => {
			var match = props.relatedItems.filter(item => {
				return item["@id"] == alignment
			})[0];

			conceptAlignments.push(
				match ?
					{
						alignmentPrefix: "",
						alignmentLabel: type.label,
						alignmentTerm: type.property,
						alignmentPostfix: ":",
						targetURL: "/conceptscheme/ce-" + match["skos:inScheme"]?.split("/ce-")[1] + "?conceptCTID=" + match["ceterms:ctid"],
						targetLabel: englishOrNull(match["skos:prefLabel"])
					}
					:
					{
						alignmentPrefix: "External ",
						alignmentLabel: type.label,
						alignmentTerm: type.property,
						alignmentPostfix: ":",
						targetURL: alignment,
						targetLabel: alignment
					}
			);
		});
	});

	alignmentTypes.forEach(type => {
		props.relatedItems.forEach(relatedItem => {
			if (relatedItem[type.property]?.indexOf(props.source.Meta_JSON["@id"]) > -1) {
				conceptAlignments.push(
					{
						alignmentPrefix: "Reverse ",
						alignmentLabel: type.label,
						alignmentTerm: type.property,
						alignmentPostfix: " reference from:",
						targetURL: "/conceptscheme/ce-" + relatedItem["skos:inScheme"]?.split("/ce-")[1] + "?conceptCTID=" + relatedItem["ceterms:ctid"],
						targetLabel: englishOrNull(relatedItem["skos:prefLabel"])
					}
				)
			}
		});
	});

	return conceptAlignments.length > 0 && (
		<ConceptExtraListSimple
			count={conceptAlignments.length}
			label="Alignment"
			values={conceptAlignments.map((m, index) => (
				<AlignmentWrapper className="conceptExtraListItem alignmentWrapper" key={props.source.Meta_JSON["ceterms:ctid"] + "-" + m.alignmentTerm + "-" + index}>
					{m.alignmentPrefix}<a href={m.alignmentTerm.replace("skos:", "https://credreg.net/ctdlasn/terms/")} target="_blank">{m.alignmentLabel}</a>{m.alignmentPostfix} <a href={m.targetURL} target="_blank">{m.targetLabel}</a>
				</AlignmentWrapper>
			))}
		/>
	) || null
}

export function ConceptExtraListSimple(props: { values: Array<ReactNode>, count: number, label: string }) {
	return props.values?.length > 0 && (
		<ConceptExtraListWrapper className="conceptExtraListWrapper">
			{props.count > 1 && <ConceptExtraListSummaryButton className="conceptExtraListSummaryButton">{props.count} {props.label + "s"} <FontAwesomeIcon className="expandedIcon" icon={faAngleDown} /><FontAwesomeIcon className="collapsedIcon" icon={faAngleRight} /></ConceptExtraListSummaryButton>}
			{props.count == 1 && <ConceptExtraListSummaryWrapper className="conceptExtraListSummaryWrapper">{props.count} {props.label}</ConceptExtraListSummaryWrapper>}
			<ConceptExtraListValues className="conceptExtraListValues">
				{props.values}
			</ConceptExtraListValues>
		</ConceptExtraListWrapper>
	) || null
}

const ConceptWrapper = styled.div`
	padding: 5px;
	border-top: 1px solid #CCC;
`;

const ConceptContent = styled.div`
	display: flex;
	padding-left: 10px;

`;

const ConceptPrefix = styled.div`

`;

const ConceptPrefixItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 12px;
	min-width: 50px;
	max-width: 150px;
	word-break: break-word;
	margin-right: 5px;
	background-color: #F5F5F5;
	padding: 5px;
	text-align: center;
`;

const ConceptBody = styled.div`

`;

const ChildConcepts = styled.div`
	padding-left: 25px;
`;

const ConceptName = styled.div`
	font-weight: bold;

	& span:not(:first-child) {
		border-left: 1px solid #CCC;
		padding-left: 10px;
		margin-left: 10px;
	}
`;

const ConceptDefinition = styled.div`
	
`;

const ConceptNote = styled.div`
	font-style: italic;
	padding: 0 10px;
	font-size: 90%;
`;

const ConceptCTID = styled.div`
	padding: 5px 10px;
	font-size: 12px;
	font-style: italic;
`;

const ConceptExtras = styled.div`
	padding: 5px 0 5px 10px;
`;

const ConceptExtraListWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	font-size: 12px;
	border-top: 1px dashed #CCC;
	padding: 5px 5px;

`;

const ConceptExtraListSummaryWrapper = styled.div`
	min-width: 150px;
	margin-right: 10px;
	padding: 1px 5px;
`;

const ConceptExtraListSummaryButton = styled.button`
	display: flex;
	align-items: center;
	min-width: 150px;
	margin-right: 10px;
	border: none;
	background-color: transparent;
	font-size: 12px;
	padding: 1px 5px;

	& svg {
		margin-left: auto;
	}

	&:hover, &:focus {
		cursor: pointer;
		text-decoration: underline;
	}
`;

const ConceptExtraListValues = styled.div`
	width: 100%;
`;


const ConceptExtraListItem = styled.div`
	padding: 2.5px 0;
	border-top: 1px dashed #CCC;

	&:first-child {
		border: none;
	}

	& a {
		word-break: break-word;
	}
`;

const AlignmentWrapper = styled(ConceptExtraListItem)`

`;
