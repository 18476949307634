import styled from 'styled-components/macro';

type FlexJustify =
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'start'
    | 'end'
    | 'left'
    | 'right';

type FlexAlign = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';

type FlexWrap = 'nowrap' | 'wrap' | 'wrap-reverse';

// this is to mimic the HStack of swiftUI
const Stack = styled.div<{
    spacing?: string;
    justify?: FlexJustify;
    align?: FlexAlign;
    flex?: number;
    wrap?: FlexWrap;
}>`
    display: flex;
    justify-content: ${(props) => props.justify || 'flex-start'};
    align-items: ${(props) => props.align || 'stretch'};
    flex-wrap: ${(props) => props.wrap || 'nowrap'};
    flex: ${(props) => props.flex ?? 1};
`;

export const HStack = styled(Stack)`
    > :not(:first-child, :last-child) {
        margin-left: ${(props) => props.spacing};
        margin-right: ${(props) => props.spacing};
    }
    > :last-child {
        margin-left: ${(props) => props.spacing};
    }
    > :first-child {
        margin-right: ${(props) => props.spacing};
    }
`;

export const HStackFilterItem = styled(HStack)`
	align-items: baseline;
	margin-bottom: 10px;
	& :hover {
		background-color: rgba(255,255,255,0.1);
		cursor: pointer;
	}
`;

export const VStack = styled(Stack)`
    flex-direction: column;
    > :not(:first-child, :last-child) {
        margin-top: ${(props) => props.spacing};
        margin-bottom: ${(props) => props.spacing};
    }
    > :last-child {
        margin-top: ${(props) => props.spacing};
    }
    > :first-child {
        margin-bottom: ${(props) => props.spacing};
    }
`;
