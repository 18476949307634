import React, { useContext, useEffect, useState } from 'react';
import { ModalType } from '../../types/internal';
import {
	Assessment,
	LearningOpportunity,
	ConditionManifest,
	CostManifest
} from '../../types/external';
import PageSection from '../PageSection';
import PageSectionItem from '../PageSectionItem';
import costIcon from '../../assets/images/icons/icon-costs-and-time-blue-green-01.svg';
import { Label, Section } from './styles';
import { Button } from '../common';
import { ModalButtonAndWindow } from '../Modal';
import { isFilled } from '../../validation';
import FinancialAssistance from './FinancialAssistance';
import HasAnyFilled from '../HasAnyFilled';
import { detailSectionProperties } from '../../utils';
import ConditionProfileList, { getConditionProfileListButtonName } from './ConditionProfile';
import ConditionManifestList from './ConditionManifest';
import CostManifestList from './CostManifest';
import CostProfileList from './CostProfile';

interface Props {
    item: Assessment | LearningOpportunity;
    openedModal?: ModalType;
    onOpenModalClick: (modalType: ModalType) => () => void;
	onCloseModalClick: () => void;
	pageSectionDescription?: string;
}

export default function CostsAndTimePageSection(props: Props) {
    const [openedModal, setOpenedModal] = useState<ModalType>();
    function onOpenModalClick(modalType: ModalType) {
        return () => setOpenedModal(modalType);
    }

    function onCloseModalClick() {
        setOpenedModal(undefined);
    }
    return (
        <HasAnyFilled
            data={props.item}
            keys={detailSectionProperties.costAndTime}>
			<PageSection icon={costIcon} title="Requirements, Costs, and Time" description={props.pageSectionDescription}>
				<HasAnyFilled
					data={props.item}
					keys={[
						...detailSectionProperties.credentialRequirements
					]}>
					<PageSectionItem>
						<Label>Requirements and Recommendations</Label>
						<ModalButtonAndWindow
							buttonLabel="Common Conditions"
							resourceTitle={props.item.Name}
							items={props.item.CommonConditions}
							Wrapper={Section}
							Content={() => <ConditionManifestList items={props.item.CommonConditions} />}
						/>
						<ModalButtonAndWindow
							//buttonLabel="Requires"
							buttonLabel={getConditionProfileListButtonName("Requires", props.item.Requires)}
							hideCount={true}
							resourceTitle={props.item.Name}
							items={props.item.Requires}
							Wrapper={Section}
							Content={() => <ConditionProfileList items={props.item.Requires} />}
						/>
						<ModalButtonAndWindow
							//buttonLabel="Recommends"
							buttonLabel={getConditionProfileListButtonName("Recommends", props.item.Recommends)}
							hideCount={true}
							resourceTitle={props.item.Name}
							items={props.item.Recommends}
							Wrapper={Section}
							Content={() => <ConditionProfileList items={props.item.Recommends} />}
						/>
						<ModalButtonAndWindow
							//buttonLabel="Corequisite"
							buttonLabel={getConditionProfileListButtonName("Corequisite With", props.item.Corequisite)}
							hideCount={true}
							resourceTitle={props.item.Name}
							items={props.item.Corequisite}
							Wrapper={Section}
							Content={() => <ConditionProfileList items={props.item.Corequisite} />}
						/>
						<ModalButtonAndWindow
							//buttonLabel="CoPrerequisite"
							buttonLabel={getConditionProfileListButtonName("Co-Prerequisite With", props.item.CoPrerequisite)}
							hideCount={true}
							resourceTitle={props.item.Name}
							items={props.item.CoPrerequisite}
							Wrapper={Section}
							Content={() => <ConditionProfileList items={props.item.CoPrerequisite} />}
						/>
						<ModalButtonAndWindow
							//buttonLabel="Entry Condition"
							buttonLabel={getConditionProfileListButtonName("Entry Requirements:", props.item.EntryCondition)}
							hideCount={true}
							resourceTitle={props.item.Name}
							items={props.item.EntryCondition}
							Wrapper={Section}
							Content={() => <ConditionProfileList items={props.item.EntryCondition} />}
						/>
					</PageSectionItem>
				</HasAnyFilled>
				{(props.item.CommonCosts?.length > 0 || props.item.EstimatedCost?.length > 0 || props.item.FinancialAssistance?.length > 0) &&
					<PageSectionItem>
						<Label>Cost Information</Label>
						<ModalButtonAndWindow
							buttonLabel="Common Costs"
							resourceTitle={props.item.Name}
							hideCount={true}
							items={props.item.CommonCosts}
							Wrapper={Section}
							Content={() => <CostManifestList items={props.item.CommonCosts} />}
						/>
						<ModalButtonAndWindow
							buttonLabel={(props.item.CommonCosts?.length > 0 ? "Specific Costs" : "Costs")}
							resourceTitle={props.item.Name}
							hideCount={true}
							items={props.item.EstimatedCost}
							Wrapper={Section}
							Content={() => <CostProfileList items={props.item.EstimatedCost} />}
						/>
						<ModalButtonAndWindow
							buttonLabel="Financial Assistance"
							resourceTitle={props.item.Name}
							hideCount={true}
							items={props.item.FinancialAssistance}
							Wrapper={Section}
							Content={() => <FinancialAssistance item={props.item} />}
						/>
					</PageSectionItem>
				}
                {isFilled(props.item.EstimatedDuration) && (
                    <PageSectionItem>
                        <Label>Time to Complete</Label>
                        <Section>
                            {props.item.EstimatedDuration.map((item, index) => (
                                <div key={`cr-duration-${index}`}>
                                    <p>
                                        <strong>Estimated: </strong>
                                        {item.DurationSummary}
                                    </p>
                                    <p>{item.Description}</p>
                                </div>
                            ))}
                        </Section>
                    </PageSectionItem>
                )}
            </PageSection>
        </HasAnyFilled>
    );
}
