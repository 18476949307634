import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components/macro';
import { Button } from '../common';
import { HStack, VStack } from '../Stack';
import { widgetGetColor } from '../../utils/SiteWidgetizer';

export const Wrapper = styled.div`
    background-color: ${(props) => widgetGetColor("SiteHeader", false, props.theme.color.blueDark)};
    color: ${(props) => widgetGetColor("SiteHeader", true, props.theme.color.white)};
    padding: 20px;
    min-width: 350px;
    max-width: 350px;

    @media (max-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 10;
    }
`;

export const TitleBlock = styled(HStack)`
    justify-content: space-between;
    border-bottom: 4px solid ${(props) => widgetGetColor("SiteHeader", true, props.theme.color.aquaDark)};
    padding-bottom: 20px;
`;
export const Title = styled.h2`
    color: ${(props) => widgetGetColor("SiteHeader", true, props.theme.color.white)};
    margin: 0;
`;

export const HideButton = styled.button`
    background: transparent;
    color: ${(props) => widgetGetColor("SiteHeader", true, props.theme.color.white)};
    border: none;
    align-items: center;
    display: flex;
    padding: 0;
`;

export const Checkbox = styled.input``;

export const FilterItemOption = styled(VStack)`
    padding: 5px 0;

    input {
        background-color: ${(props) => props.theme.color.greyDark};
        padding: 5px;
        color: ${(props) => widgetGetColor("SiteHeader", true, props.theme.color.white)};
        font-size: ${(props) => props.theme.fontSize.sm};
    }
`;
export const FilterItemLabel = styled.p`
    margin: 0;
    cursor: pointer;
`;
export const FilterItemDescription = styled.p`
    margin: 0;
    font-size: ${(props) => props.theme.fontSize.sm};
`;

export const FilterItemAddButton = styled(Button)`
    padding: 0 10px;
    font-weight: normal;
    min-width: 50px;
    font-size: ${(props) => props.theme.fontSize.sm};
`;

export const AddedItem = styled(HStack)`
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 0 10px;
    background-color: ${(props) => props.theme.color.greyDark};
`;

export const IconButton = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

export const StyledFilterOption = styled.button`
    font-size: ${(props) => props.theme.fontSize.sm};
    background-color: ${(props) => widgetGetColor("SiteHeader", false, props.theme.color.blueDark)};
    text-align: left;
    color: ${(props) => widgetGetColor("SiteHeader", true, props.theme.color.white)};
    width: 100%;
    padding: 10px;
    border: 1px solid ${(props) => widgetGetColor("SiteHeader", true, props.theme.color.aquaLight)};
    &:hover {
        background-color: ${(props) => widgetGetColor("SiteHeader", true, props.theme.color.greyDark)};
        color: ${(props) => widgetGetColor("SiteHeader", false, "white")};
    }
`;
