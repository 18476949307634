import React, { useEffect, useState } from 'react';
import { Redirect, useHistory  } from "react-router-dom";
import axios from 'axios';
import { RouteComponentProps } from 'react-router-dom';
import { apiUrl } from '../../shared/globals';
import InnerHTML from 'dangerously-set-html-content';
import ActivityIndicator from '../../components/ActivityIndicator';
import styled from 'styled-components/macro';
import Error from '../../components/Error';

interface Props {
    id: string;
}

export default function ResourcesPage(props: RouteComponentProps<Props>) {
    const id = props.match.params.id;
    const [loading, setLoading] = useState(false);
    const [pageContent, setPageContent] = useState(' ');
    const [error, setError] = useState(false);
    const testurl = `${apiUrl}/resources/` + id;
    // Using useHistory Hook
    let history = useHistory();
    useEffect(() => {
        setLoading(true);
		const url = `${apiUrl}/resources/` + id;
        axios
            .get(url)
            .then((res) => {
                //return is currently an Outline with a relative URL
                history.push("/" + res.data.Result.URL);
                //can't have useEffect under another useEffect
                // useEffect(() => {
                //     window.location.href = "credential/" + id;//TBD
                //   }, []);
                //setPageContent(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setError(true);
                setLoading(false);
            });
    }, []);
    if (loading) {
        return (
            <LoadingIndicatorWrapper>
                <ActivityIndicator />
            </LoadingIndicatorWrapper>
        );
    }
    if (error) {
        return <Error />;
    }
    return <InnerHTML html={pageContent} />;
}

const LoadingIndicatorWrapper = styled.div`
    text-align: center;
    padding: 50px;
`;