import React, { ReactNode, useContext } from 'react';
import styled from 'styled-components/macro';
import { Button } from '../common';
import { VStack } from '../Stack';

import credential_icon from '../../assets/images/icons/icon-slider-credential-providers-01.svg';
import qa_organizations_icon from '../../assets/images/icons/icon-slider-quality-assurance-organizations-01.svg';
import employers_icon from '../../assets/images/icons/icon-slider-employers-01.svg';
import individuals_icon from '../../assets/images/icons/icon-slider-individual-01.svg';
import government_agencies_icon from '../../assets/images/icons/icon-slider-government-agencies-01.svg';
import application_developers_icon from '../../assets/images/icons/icon-slider-application-developers-01.svg';
import skills_competency_creators_icon from '../../assets/images/icons/icon-slider-skill-and-competency-creators-01.svg';
import { QueryContext } from '../../App';
import { defaultQuery } from '../../shared/data';
import { useHistory } from 'react-router-dom';

interface CarouseBlockProps {
    icon: string;
    title: string;
    subTitle: string;
    content: ReactNode;
    button: ReactNode;
}

const Wrapper = styled(VStack)<{ isActive?: boolean }>`
    padding: 20px;
    border: 1px solid ${(props) => props.theme.color.greyLight};
    justify-content: space-between;
    user-select: none;
    height: 100%;
`;

const CarouselBody = styled.div``;
const Icon = styled.img`
    height: 40px;
    align-self: flex-start;
`;
const Title = styled.h2``;
const SubTitle = styled.label``;
const Content = styled.div``;
const CarouselButton = styled.div``;

export default function CarouseBlock(props: CarouseBlockProps) {
    const queryContext = useContext(QueryContext);
    const history = useHistory();

    function onExploreButtonClick(_e: any) {
        queryContext.updateQuery({ ...defaultQuery, SearchType: 'credential' });
        history.push('/search');
    }
    return (
        <Wrapper>
            <CarouselBody>
                <Icon alt="" src={props.icon} />
                <Title>{props.title}</Title>
                <SubTitle>{props.subTitle}</SubTitle>
                <Content>{props.content}</Content>
            </CarouselBody>
            <CarouselButton onClick={onExploreButtonClick}>
                {props.button}
            </CarouselButton>
        </Wrapper>
    );
}

export function CredentialProvidersBlock() {
    return (
        <CarouseBlock
            icon={credential_icon}
            title="Credential Providers / Issuers"
            subTitle="See where your credentials fit in the landscape"
            content={
                <>
                    <p>
                        Our information can help you build better connections
                        for the credentials you provide. We encourage you to
                        explore how comparable credentials from other providers
                        lead to different career opportunities, what
                        competencies and skills are built into different
                        credentials, and which are in demand.
                    </p>
                    <p>
                        Use our data to help inform the credential opportunities
                        you offer, and understand how your credentials compare
                        locally and nationally.
                    </p>
                </>
            }
            button={<Button buttonType="secondary">Explore</Button>}
        />
    );
}

export function QualityAssuranceOrgnizationsBlock() {
    return (
        <CarouseBlock
            icon={qa_organizations_icon}
            title="Quality Assurance Organizations"
            subTitle="Variety can be good, but not when it comes to quality"
            content={
                <>
                    <p>
                        Browse through thousands of verified credential, skill,
                        and competency data to inform quality assurance across
                        credentials in your region.
                    </p>
                    <p>
                        Learn how credentials and competencies align to quality
                        frameworks.
                    </p>
                </>
            }
            button={<Button buttonType="primary">Explore</Button>}
        />
    );
}
export function IndividualsBlock() {
    return (
        <CarouseBlock
            icon={individuals_icon}
            title="Job Seekers / Individuals"
            subTitle="Reliable information to help find your path"
            content={
                <>
                    <p>
                        Whether you’re just starting out, advancing your career,
                        or just looking for new skills, Credential Finder helps
                        you find the path. We know it can be confusing to
                        navigate through thousands of degrees, certificates, and
                        other credential options. Having the right information
                        is key.
                    </p>
                    <p>
                        Use our reliable and up-to-date credential information
                        to confidently map your own path forward.
                    </p>
                </>
            }
            button={<Button buttonType="primary">Explore</Button>}
        />
    );
}
export function GovernmentAgenciesBlock() {
    return (
        <CarouseBlock
            icon={government_agencies_icon}
            title="Local, State, and Federal Government Agencies"
            subTitle="Data that empowers decision making"
            content={
                <>
                    <p>
                        Our credential information can help you understand the
                        opportunities available for your constituents so that
                        you may make better decisions about supports, resources,
                        and services to meet your needs and keep up with a
                        rapidly changing economy.
                    </p>
                    <p>
                        Use our data in ways that are specific to your context
                        to help with strategic planning and meet your goals.
                    </p>
                </>
            }
            button={<Button buttonType="tertiary">Explore</Button>}
        />
    );
}
export function ApplicationDevelopersBlock() {
    return (
        <CarouseBlock
            icon={application_developers_icon}
            title="Application Designers and Developers"
            subTitle="An API to build your own applications"
            content={
                <>
                    <p>
                        Credential Finder is just a demonstration of what you
                        can do with transparent credential information. Take
                        advantage of a complete suite of APIs and methods to
                        build your own applications based on data in the
                        Credential Registry.
                    </p>
                    <p>
                        Use our trusted and relevant data to build your own
                        custom solutions that help meet your specific needs.
                    </p>
                </>
            }
            button={<Button buttonType="primary">Explore</Button>}
        />
    );
}
export function SkillAndCompetencyCreatorsBlock() {
    return (
        <CarouseBlock
            icon={skills_competency_creators_icon}
            title="Skill and Competency Creators"
            subTitle="Create new opportunities and refine existing ones"
            content={
                <>
                    <p>
                        You want to create opportunities for people to acquire
                        in-demand skills when they need them. Learn what skills
                        and competencies are available, what they mean, and what
                        may be missing across local and state regions.
                    </p>
                    <p>
                        Our data facilitates understanding of how others are
                        describing different competencies and skills so that you
                        can use that information to create new opportunities or
                        refine existing skills and credentials.
                    </p>
                </>
            }
            button={<Button buttonType="secondary">Explore</Button>}
        />
    );
}
export function EmployersBlock() {
    return (
        <CarouseBlock
            icon={employers_icon}
            title="Employers"
            subTitle="Hire the right people"
            content={
                <>
                    <p>
                        Use our reliable information to know which credentials
                        represent the knowledge and skills you want in your
                        employees, and which credential and skills providers
                        offer them.
                    </p>
                    <p>
                        Compare options and make informed choices that can
                        expand your talent pool.
                    </p>
                </>
            }
            button={<Button buttonType="tertiary">Explore</Button>}
        />
    );
}
