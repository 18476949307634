import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LoadingOrError from '../../components/LoadingOrError';
import PageSection from '../../components/PageSection';
import PageSectionItem from '../../components/PageSectionItem';
import {
    MobileAboutDetail,
    Description,
    Label,
    Wrapper,
    Inline,
    InlineLabel,
    Section,
} from '../../components/DetailPage/styles';
import { apiUrl, legacySourceUrl } from '../../shared/globals';
import { theme } from '../../shared/theme';

import pageIcon from '../../assets/images/icons/icon-transfer-value-white-green-01.svg';
import transferValueIcon from '../../assets/images/icons/icon-transfer-value-green-blue-01.svg';
import transferIntermediaryIcon from '../../assets/images/icons/icon-transfer-intermediary-blue-green-01.svg';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';
import additionalInfoIcon from '../../assets/images/icons/icon-additional-information-blue-green-01.svg';
import processIcon from '../../assets/images/icons/icon-process-blue-green-01.svg';
import JSONLinkedDataModal from '../../components/DetailPage/JSONLinkedDataModal';
import Banner from '../../components/Banner';
import LinkObject, { PlainTextLink } from '../../components/DetailPage/LinkObject';
import { VStack } from '../../components/Stack';
import { useMediaQuery, useGet } from '../../hooks';
import { ModalType } from '../../types/internal';
import { Link, TransferValue } from '../../types/external';
import { isFilled } from '../../validation';
import DisplayPills from '../../components/PillDisplay';
import HasAnyFilled from '../../components/HasAnyFilled';
import { detailSectionProperties } from '../../utils';
import { WhiteLink } from '../../components/Banner/styles';
import { ModalButtonAndWindow } from '../../components/Modal';
import ValueProfileList from '../../components/DetailPage/ValueProfile';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import OutlineList from '../../components/DetailPage/Outline';
import ProcessProfileList from '../../components/DetailPage/ProcessProfile';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";
import ConnectionsSection from "../../components/DetailPage/ConnectionsSection";

interface Props {
    id: string;
}

export default function TransferValuePage(props: RouteComponentProps<Props>) {
    const id = props.match.params.id;
    const [
        pageDataIsLoading,
        pageDataHasError,
        getPageData,
	] = useGet();

	const [ pageModel, setPageData ] = useState({} as TransferValue);
	const startDate = pageModel.StartDate;
	const endDate = pageModel.EndDate;
	const [openedModal, setOpenedModal] = useState<ModalType>();

	function onOpenModalClick(modalType: ModalType) {
		return () => setOpenedModal(modalType);
	}

	function onCloseModalClick() {
		setOpenedModal(undefined);
	}
    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });

    useEffect(() => {
        getPageData(`${apiUrl}/transfervalue/${id}`, (data: TransferValue) => {
			setPageData(data);
        });
    }, [getPageData, id]);
	//

	function getSubTitleLink() {
		if ( pageModel.OwnedByLabel && pageModel.OwnedByLabel.Label) {
			return pageModel.OwnedByLabel.URL ?
				<WhiteLink href={pageModel.OwnedByLabel.URL}>{pageModel.OwnedByLabel.Label}</WhiteLink> :
				<>{pageModel.OwnedByLabel.Label}</>;
		}
		return null;
	}

	function getLegacyLink() {
		if (pageModel.Meta_Id) {
			const url = legacySourceUrl + "/" + pageModel.BroadType + "/" + pageModel.Meta_Id;
			const mystyle = {
				//color: "rgba(0,0,0,.01)",
				color: "transparent",
				//backgroundColor: "rgba(0,0,0,.01)",
				marginLeft: "10px"
			};
			return <>
				<WhiteLink href={url} target="legacy" style={mystyle} title="View in services application">Legacy</WhiteLink>
			</>

		}
		return null;
	}

	var typeLabel = widgetGetSingleLabelForText("Transfer Value");

    return (
        <LoadingOrError
            isLoading={pageDataIsLoading}
            hasError={pageDataHasError}>
            
            <Banner
            icon={pageIcon}
            fallBackIcon={pageIcon}
            type={pageModel.CTDLTypeLabel}
            title={pageModel.Name}
            subTitle={getSubTitleLink()}
            content={
                <small>Last updated: {pageModel.Meta_LastUpdatedHeader} <span  >{getLegacyLink()}</span></small>
            } 
        />
            <Wrapper>
                <VStack spacing={isMobile ? '0px' : '20px'}>
                    <HasAnyFilled
                        data={pageModel}
                        keys={detailSectionProperties.assessmentAbout}>
                        <PageSection
							icon={aboutIcon}
							title={"About this " + typeLabel}
							variant="Highlight"
							description={"Basic information about this " + typeLabel}>
                            <PageSectionItem>
                                {pageModel.Description && (
                                    <Description>
                                        {pageModel.Description}
                                    </Description>
                                )}
                                <VStack spacing="10px">                                 
									{pageModel.SubjectWebpage &&
										<LinkObject item={{ URL: pageModel.SubjectWebpage, Label: "View this " + typeLabel }} />
									}
								</VStack>
								{pageModel.LifeCycleStatusType && (
									<PageSectionItem>
										<Label>Life Cycle Status</Label>
										<DisplayPills links={[pageModel.LifeCycleStatusType]} />
									</PageSectionItem>
								)}
								{pageModel.InCatalog &&
									<LinkObject item={{
										URL: pageModel.InCatalog, Label: "View this " + typeLabel + "in the Course Catalog "
									}} />
								}
							</PageSectionItem>
							{(pageModel.StartDate || pageModel.EndDate) && (
							<PageSectionItem>
									<TextItem label="Start Date:" item={pageModel.StartDate} />
									<TextItem label="End Date:" item={pageModel.EndDate} />
								</PageSectionItem>
							)}

							{(pageModel.LatestVersion?.URL || pageModel.PreviousVersion?.URL || pageModel.NextVersion?.URL || pageModel.Supersedes?.URL || pageModel.SupersededBy?.URL) &&
								<PageSectionItem>
									<Label>Versions</Label> 
									<PageSectionItem>
										<LinkItem label="Latest Version:" item={pageModel.LatestVersion} />
										<LinkItem label="Previous Version:" item={pageModel.PreviousVersion} />
										<LinkItem label="Next Version:" item={pageModel.NextVersion} />
										<LinkItem label="Supersedes:" item={pageModel.Supersedes} />
										<LinkItem label="Superseded By:" item={pageModel.SupersededBy} />
									</PageSectionItem>
								</PageSectionItem>
							}
							{pageModel.TransferValue && 
								<PageSectionItem>
									<Label>Transfer Value Credit</Label>
									<ValueProfileList items={pageModel.TransferValue} />
								</PageSectionItem>
							
							}

                        </PageSection>
					</HasAnyFilled>
					<AdditionalInformationPageSection item={pageModel} pageSectionDescription={"Identifiers and other information for the " + typeLabel} />
				</VStack>
				<VStack spacing={isMobile ? '0px' : '5px'}>

					<RelatedOrganizationPageSection item={pageModel} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " that own or approve of the " + typeLabel} />

					<HasAnyFilled
						data={pageModel}
						keys={[
							...detailSectionProperties.entityConnections
						]}
					>
						<PageSection icon={connectionsIcon} title="Connections" description="Related Resources">
							<ModalButtonAndWindow
								buttonLabel={"Related Assessment"}
								resourceTitle={pageModel.Name}
								items={pageModel.RelatedAssessment?.Values}
								Wrapper={Section}
								Content={() => <OutlineList items={pageModel.RelatedAssessment?.Values} />}
								hideCount={true}
							/>
							<ModalButtonAndWindow
								buttonLabel={"Related Credential"}
								resourceTitle={pageModel.Name}
								items={pageModel?.RelatedCredential?.Values}
								Wrapper={Section}
								Content={() => <OutlineList items={pageModel?.RelatedCredential?.Values} />}
								hideCount={true}
							/>
							<ModalButtonAndWindow
								buttonLabel={"Related LearningOpputunity"}
								resourceTitle={pageModel.Name}
								items={pageModel?.RelatedLearningOpp?.Values}
								Wrapper={Section}
								Content={() => <OutlineList items={pageModel?.RelatedLearningOpp?.Values} />}
								hideCount={true}
							/>
							<ModalButtonAndWindow
								buttonLabel={"Related Job"}
								resourceTitle={pageModel.Name}
								items={pageModel?.RelatedJob?.Values}
								Wrapper={Section}
								Content={() => <OutlineList items={pageModel?.RelatedJob?.Values} />}
								hideCount={true}
							/>
							<ModalButtonAndWindow
								buttonLabel={"Related Occupation"}
								resourceTitle={pageModel.Name}
								items={pageModel?.RelatedOccupation?.Values}
								Wrapper={Section}
								Content={() => <OutlineList items={pageModel?.RelatedOccupation?.Values} />}
								hideCount={true}
							/>
							<ConnectionsSection
								item={pageModel}
								openedModal={openedModal}
								onOpenModalClick={onOpenModalClick}
								onCloseModalClick={onCloseModalClick}
							/>
						</PageSection>
					</HasAnyFilled>
					{pageModel.TransferValueFrom &&
						<PageSection icon={transferValueIcon} title="Transfer Value From" description="Transfer Value is supplied by these Resources">
							<OutlineList items={pageModel.TransferValueFrom?.Values} />
						</PageSection>
					}
					{pageModel.TransferValueFor &&
						<PageSection icon={transferValueIcon} title="Transfer Value For" description="Transfer Value is accepted by these Resources">
							<OutlineList items={pageModel.TransferValueFor?.Values} />
						</PageSection>
					}
					{pageModel.DerivedFrom &&
						<PageSection icon={transferValueIcon} title="Derived From" description="A Transfer Value Profile from which this organization will derive credit or other transfer value.">
						<OutlineList items={pageModel.DerivedFrom?.Values} />
						</PageSection>
					}					
					{/*{pageModel.DerivedFrom?.Values?.length > 0 &&*/}
					{/*	<PageSection icon={connectionsIcon} title="Connections" description={"Resources related to the " + typeLabel}>*/}
					{/*		<PageSectionItem>*/}
					{/*			<ModalButtonAndWindow*/}
					{/*				buttonLabel="Derived From"*/}
					{/*				resourceTitle={pageModel.Name}*/}
					{/*				items={pageModel.DerivedFrom?.Values}*/}
					{/*				Wrapper={Section}*/}
					{/*				Content={() => <OutlineList items={pageModel.DerivedFrom?.Values} />}*/}
					{/*			/>*/}
					{/*		</PageSectionItem>*/}
					{/*	</PageSection>*/}
					{/*}*/}
					{pageModel.HasIntermediaryFor &&
						<PageSection icon={transferIntermediaryIcon} title="Has Intermediary For" description={"This " + typeLabel + " is part of the following " + widgetGetPluralLabelForText("Transfer Intermediaries")}>
						<OutlineList items={pageModel.HasIntermediaryFor?.Values} />
						</PageSection>
					}

					<HasAnyFilled
                        data={pageModel}
                        keys={detailSectionProperties.transferValueProcess}>
                        <PageSection icon={processIcon} title="Process">
							{/*<PageSectionItem>*/}
							{/*	<ModalButtonAndWindow*/}
							{/*		buttonLabel="Administration Process"*/}
							{/*		resourceTitle={pageModel.Name}*/}
							{/*		items={pageModel.AdministrationProcess?.Values}*/}
							{/*		Wrapper={Section}*/}
							{/*		Content={() => <ProcessProfileList items={pageModel.AdministrationProcess?.Values} />}*/}
							{/*	/>*/}
							{/*</PageSectionItem>*/}
							<PageSectionItem>
								<ModalButtonAndWindow
									buttonLabel="Development Process"
									resourceTitle={pageModel.Name}
									items={pageModel.DevelopmentProcess?.Values}
									Wrapper={Section}
									Content={() => <ProcessProfileList items={pageModel.DevelopmentProcess?.Values} />}
								/>
							</PageSectionItem>
                        </PageSection>
					</HasAnyFilled>
				</VStack>
			</Wrapper>
        </LoadingOrError>
	);
	function TextItem(props: { label: string, item: string }) {
		return (props.item &&
			<Inline>
				<InlineLabel>{props.label}</InlineLabel>
				<div>{props.item}</div>
			</Inline>
		) || null
	}
	function LinkItem(props: { label: string, item: any }) {
		if (!props.item) {
			return null;
		}

		var link = typeof (props.item) == "string" ? ({ Label: props.item, URL: props.item } as Link) : (props.item as Link);
		return (link.URL &&
			<Inline>
				<InlineLabel>{props.label}</InlineLabel>
				<PlainTextLink item={link} />
			</Inline>
		) || null
	}
}
//
