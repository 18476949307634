import React from 'react';
import { faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '../../types/external';
import { Label, Wrapper, PillWrapper } from './styles';

interface Props {
    links: Array<Link>;
}

export default function Pills({ links }: Props) {
    return (
        <Wrapper>
            {links.map((link, index) => (
                <PillWrapper key={`cr-search-pill-${link.URL}-${index}}]`}>
                    <Label>
                        <small>{link.Label}</small>
                    </Label>
                    
                </PillWrapper>
            ))}
        </Wrapper>
    );
}