import { Variant } from '.';
import styled, { css } from 'styled-components/macro';
import { VStack } from '../Stack';
import { widgetGetColor } from '../../utils/SiteWidgetizer';

interface Props {
    variant?: Variant;
}

export const Wrapper = styled.div<Props>`
    display: flex;
    flex-direction: column;
    padding: 20px;
    ${(p) =>
        p.variant === 'Highlight' &&
        css`
            background-color: ${(p) => (window as any).Widget?.ID ? "#F5F5F5" : p.theme.color.aquaLight};
			outline: 1px solid ${(p) => (window as any).Widget?.ID ? "#CCC" : "transparent"};
        `}
    ${(p) =>
        p.variant === 'Shadow' &&
        css`
            box-shadow: 3px 3px 30px 0px #c2c3d0;
        `};
    ${(p) =>
        p.variant === 'Plain' &&
        css`
            background-color: ${(p) => p.theme.color.white};
        `}
`;

export const Header = styled.div`
    border-bottom: ${(p) => css`3px solid ${p.theme.color.aquaDark}`};
    margin-bottom: 10px;
`;

export const Title = styled.h2`
    margin-block: 7px;
`;

export const Icon = styled.img`
    width: 50px;
    height: 50px;
`;

export const MobileHeader = styled.div<Props>`
    display: flex;
    flex-wrap: wrap;
    padding: 7px 10px;
    cursor: pointer;
    ${(p) =>
        p.variant === 'Highlight' &&
        css`
            background-color: ${(p) => p.theme.color.aquaLight};
        `}
`;

export const MobileBorder = styled.div`
    border-bottom: ${(p) => css`1px solid ${p.theme.color.aquaDark}`};
    margin-top: 10px;
    width: 100%;

    ${VStack}:last-child ${MobileHeader}:last-child & {
        border-bottom: none;
    }
`;

export const MobileIcon = styled(Icon)`
    margin-right: 10px;
`;

export const MobileText = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
`;

export const MobilePanel = styled.div<Props>`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
    overflow-y: scroll;
    background-color: ${(p) =>
        p.variant === 'Highlight'
            ? p.theme.color.aquaLight
            : p.theme.color.white};
`;

export const MobilePanelHeader = styled.div`
    display: flex;
    background-color: ${(p) => p.theme.color.blueDark};
    padding: 10px;
    align-items: center;
    cursor: pointer;
`;

export const MobilePanelTitle = styled.div`
    margin-left: 10px;
    color: ${(p) => p.theme.color.aquaDark};
    font-size: ${(p) => p.theme.fontSize.lg};
    font-weight: 700;
`;

export const MobilePanelContent = styled.div`
    padding: 10px;
`;

export const HeaderWithNote = styled.div`
	display: flex;
	align-items: center;
    border-bottom: ${(p) => css`3px solid ${widgetGetColor("SiteHeader", false, p.theme.color.aquaDark)}`};
    margin-bottom: 10px;

	& h2 {
		margin: 0;
	}

	& img {
		width: 50px;
		height: 50px;
		margin: 0 10px 10px 0;
	}
`;

export const HeaderWithNoteContent = styled.div`
	flex: 1 1 auto;
`;

export const HeaderNote = styled.div`
	font-size: 90%;
	font-style: italic;
`;