import React, { ReactNode, useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components/macro';
import { apiUrl, appBaseName, appURL, registryURL } from '../../shared/globals';
import { RouteComponentProps } from 'react-router-dom';
import LoadingOrError from '../../components/LoadingOrError';
import { CompetencyFramework as CompetencyFrameworkType, Competency as CompetencyType, Outline, Link, WorkforceDemand, AJAXSettings, IdentifierValue } from '../../types/external';
import { Concept } from '../ConceptSchemePage';
import competenciesbannerIcon from '../../assets/images/icons/icon-competencies-white-green-01.svg';
import competenciesIcon from '../../assets/images/icons/icon-competencies-blue-green-01.svg';
import PageBanner from '../../components/DetailPage/PageBanner';
import {
	Wrapper,
	FullWidthWrapper,
	InnerWrapper,
	Description,
	Label,
    Section
} from '../../components/DetailPage/styles';
import { VStack } from '../../components/Stack';
import PageSection from '../../components/PageSection';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import { getResourceByURI, englishOrNull, createKeywordSearchLink, getAndRenderByURL, getResourceByURLWithoutState, createReferenceFrameworkFromCredentialAlignmentObject } from '../../components/GetResourceByURI';
import ActivityIndicator from '../../components/ActivityIndicator';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import LinkObject, { PlainTextLink, PlainTextLinkList, PlainTextLinkListFromURLs } from '../../components/DetailPage/LinkObject';
import PageSectionItem from '../../components/PageSectionItem';
import SearchPills from '../../components/SearchPills';
import SubjectsAndKeywordsPageSection from '../../components/DetailPage/SubjectsAndKeywordsPageSection';
import OccupationsAndIndustriesSection from '../../components/DetailPage/OccupationsAndIndustriesSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WorkforceDemandAction } from '../../components/DetailPage/WorkforceDemandAction';
import AJAXModal from '../../components/Modal/AJAXModal';
import AJAXResourceList, { appendAJAXCacheV3ResourceRaw, createElement, getEnglish, renderText, renderLink, renderImage, renderElement, getResourceViaResourceCacheV3 } from '../../components/AJAXResourceList';
import { ModalButtonAndWindow } from '../../components/Modal';
import { CompetencyStyleBlock, renderCompetencyFromRDF } from '../../components/DetailPage/Competency';
import { renderJSONLDMetaTags } from '../../utils/JSONLDMetaHelper';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";
import OutlineList from '../../components/DetailPage/Outline';

interface Props {
	id: string;
}

export default function CompetencyPage(props: RouteComponentProps<Props>) {
	const id = props.match.params.id;
	const [dataIsLoading, setDataIsLoading] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [competency, setCompetency] = useState({} as CompetencyType);
	const [localAJAXCache, setLocalAJAXCache] = useState([] as Array<any>); //Used to trick react into re-rendering the data
	const [partOfFramework, setPartOfFramework] = useState({} as Link);
	const [memberOfCollections, setMemberOfCollections] = useState([] as Array<Link>);
	const [substantiatingCompetencyFrameworks, setSubstantiatingCompetencyFrameworks] = useState([] as Array<Link>);
	const [substantiatingCredentials, setSubstantiatingCredentials] = useState([] as Array<Link>);
	const [substantiatingOrganizations, setSubstantiatingOrganizations] = useState([] as Array<Link>);
	const [substantiatingOccupations, setSubstantiatingOccupations] = useState([] as Array<Link>);
	const [substantiatingJobs, setSubstantiatingJobs] = useState([] as Array<Link>);
	const [substantiatingWorkRoles, setSubstantiatingWorkRoles] = useState([] as Array<Link>);
	const [substantiatingTasks, setSubstantiatingTasks] = useState([] as Array<Link>);
	const [isChildOf, setIsChildOf] = useState([] as Array<Link>);
	const [derivedFrom, setDerivedFrom] = useState([] as Array<Link>);
	const [complexityLevel, setComplexityLevel] = useState([] as Array<Link>);
	const [educationLevelType, setEducationLevelType] = useState([] as Array<Link>);
	const [environmentalHazardType, setEnvironmentalHazardType] = useState([] as Array<Link>);
	const [performanceLevelType, setPerformanceLevelType] = useState([] as Array<Link>);
	const [physicalCapabilityType, setPhysicalCapabilityType] = useState([] as Array<Link>);
	const [sensoryCapabilityType, setSensoryCapabilityType] = useState([] as Array<Link>);
	const [hasProgressionLevel, setHasProgressionLevel] = useState([] as Array<Link>);
	const [relatedItemsMap, setRelatedItemsMap] = useState({} as any);
	const [relatedWorkRoles, setRelatedWorkRoles] = useState({} as AJAXSettings);

	useEffect(() => {
		getResourceByURI(`${apiUrl}/dsp_competency/${id}?includeRelatedResources=false&perBranchLimit=-1&includeMetadata=true`, setLocalAJAXCache, null,
		//getResourceByURI(`${apiUrl}/detail/descriptionsetbyctid/${id}?includeRelatedResources=false&perBranchLimit=-1&includeMetadata=true`, setLocalAJAXCache, null,
			(loaded: any) => {
				setDataIsLoading(false);
				setCompetency(convertDataToCompetency(loaded?.Result?.Resource || { "ceasn:competencyLabel": "Missing Competency", "ceasn:description": "Unable to find data for identifier: " + id }, loaded?.Result?.Metadata));
				renderJSONLDMetaTags(loaded?.Result?.Resource, "Competency");
				setRelatedItemsMap(loaded?.Result?.RelatedItemsMap || []);
				console.log("Competency", competency);
				console.log("Related Items Map", relatedItemsMap);

				getLinks(competency.Meta_JSON, "ceasn:isPartOf", "Competency Framework", "competencyframework", "ceasn:name", null, setPartOfFramework, true, "?targetCTID=" + competency.CTID);
				getLinks(competency.Meta_JSON, "ceterms:isMemberOf", "Collection", "collection", "ceterms:name", memberOfCollections, setMemberOfCollections, true, "?targetCTID=" + competency.CTID);
				getLinks(competency.Meta_JSON, "ceasn:substantiatingCompetencyFramework", "Competency Framework", "competencyframework", "ceasn:name", substantiatingCompetencyFrameworks, setSubstantiatingCompetencyFrameworks, true);
				getLinks(competency.Meta_JSON, "ceasn:substantiatingCredential", "Credential", "credential", "ceterms:name", substantiatingCredentials, setSubstantiatingCredentials, true);
				getLinks(competency.Meta_JSON, "ceasn:substantiatingOrganization", "Organization", "organization", "ceterms:name", substantiatingOrganizations, setSubstantiatingOrganizations, true);
				getLinks(competency.Meta_JSON, "ceasn:substantiatingOccupation", "Occupation", "occupation", "ceterms:name", substantiatingOccupations, setSubstantiatingOccupations, false);
				getLinks(competency.Meta_JSON, "ceasn:substantiatingJob", "Job", "job", "ceterms:name", substantiatingJobs, setSubstantiatingJobs, false);
				getLinks(competency.Meta_JSON, "ceasn:substantiatingWorkrole", "Work Role", "workrole", "ceterms:name", substantiatingWorkRoles, setSubstantiatingWorkRoles, true);
				getLinks(competency.Meta_JSON, "ceasn:substantiatingTask", "Task", "task", "ceterms:name", substantiatingTasks, setSubstantiatingTasks, false);
				getLinks(competency.Meta_JSON, "ceasn:isChildOf", "Competency", "competency", "ceasn:competencyText", isChildOf, setIsChildOf, true);
				//getLinks(competency.Meta_JSON, "ceasn:hasChild", "Competency", "competency", "ceasn:competencyText", hasChild, setHasChild, true); //Loads too much, do this in a modal instead
				getLinks(competency.Meta_JSON, "ceasn:derivedFrom", "Competency", "competency", "ceasn:competencyText", derivedFrom, setDerivedFrom, true);
				getLinks(competency.Meta_JSON, "ceasn:complexityLevel", "Concept", "concept", "skos:prefLabel", complexityLevel, setComplexityLevel, true);
				getLinks(competency.Meta_JSON, "ceasn:educationLevelType", "Concept", "concept", "skos:prefLabel", educationLevelType, setEducationLevelType, true);
				getLinks(competency.Meta_JSON, "ceterms:environmentalHazardType", "Concept", "concept", "skos:prefLabel", environmentalHazardType, setEnvironmentalHazardType, true);
				getLinks(competency.Meta_JSON, "ceterms:performanceLevelType", "Concept", "concept", "skos:prefLabel", performanceLevelType, setPerformanceLevelType, true);
				getLinks(competency.Meta_JSON, "ceterms:physicalCapabilityType", "Concept", "concept", "skos:prefLabel", physicalCapabilityType, setPhysicalCapabilityType, true);
				getLinks(competency.Meta_JSON, "ceterms:sensoryCapabilityType", "Concept", "concept", "skos:prefLabel", sensoryCapabilityType, setSensoryCapabilityType, true);
				getLinks(competency.Meta_JSON, "ceasn:hasProgressionLevel", "Concept", "concept", "skos:prefLabel", hasProgressionLevel, setHasProgressionLevel, true);

				(Array.from(competency.Meta_JSON?.["ceasn:conceptTerm"] || [])).forEach((uri: any) => { //Array.from is only used here because TypeScript will not get out of my way without it
					getResourceByURLWithoutState(uri, null, (success: any) => {
						competency.ConceptTerm.push(createKeywordSearchLink("competencyframework", englishOrNull(success["skos:prefLabel"])) as Link);
						setLocalAJAXCache(localAJAXCache);
						setCompetency({ ...competency });
					});
				});
				
				setLocalAJAXCache(localAJAXCache);
			},
			(error: any) => {
				setHasError(true);
				console.log("Error loading data: ", error);
			}
		);

		
	}, [id, localAJAXCache])

	function convertDataToCompetency(resource: any, metadata: any): CompetencyType {
		console.log("Resource", resource);
		return {
			BroadType: "Competency",
			CTDLType: "ceasn:Competency",
			CTDLTypeLabel: "Competency",
			Meta_Id: id,
			Meta_Language: resource["ceasn:inLanguage"]?.[0] || "en",
			Meta_LastUpdatedHeader: metadata?.DateModified || resource["ceasn:dateModified"],
			CTID: resource["ceterms:ctid"],
			CredentialRegistryURL: resource["@id"],
			CompetencyLabel: englishOrNull(resource["ceasn:competencyLabel"]),
			Name: englishOrNull(resource["ceasn:competencyLabel"]) || "Competency",
			CompetencyText: englishOrNull(resource["ceasn:competencyText"]),
			CompetencyCategory: englishOrNull(resource["ceasn:competencyCategory"]),
			DateCreated: resource["ceasn:dateCreated"],
			DateModified: resource["ceasn:dateModified"],
			ConceptKeyword: englishOrNull(resource["ceasn:conceptKeyword"])?.map((m: string) => { return createKeywordSearchLink("competencyframework", m) }),
			ConceptTerm: [] as Array<Link>,
			LocalSubject: (Array.isArray(englishOrNull(resource["ceasn:localSubject"])) ? englishOrNull(resource["ceasn:localSubject"]) : [englishOrNull(resource["ceasn:localSubject"])]).filter((m: any) => m)?.map((m: string) => { return createKeywordSearchLink("competencyframework", englishOrNull(m)) }), //LocalSubject is supposed to be multiple, but there was old data for the SNHU framework on sandbox, so do this for now
			Comment: englishOrNull(resource["ceasn:comment"]),
			InstructionalProgramType: resource["ceterms:instructionalProgramType"]?.map((m: any) => { return createReferenceFrameworkFromCredentialAlignmentObject("competencyframework", m) }),
			IndustryType: resource["ceterms:industryType"]?.map((m: any) => { return createReferenceFrameworkFromCredentialAlignmentObject("competencyframework", m) }),
			OccupationType: resource["ceterms:occupationType"]?.map((m: any) => { return createReferenceFrameworkFromCredentialAlignmentObject("competencyframework", m) }),
			ListID: resource["ceasn:listID"],
			License: resource["ceasn:license"],
			Identifier: resource["ceasn:identifier"],
			VersionIdentifier: createIdentifier(resource["ceterms:versionIdentifier"]),
			CodedNotation: resource["ceasn:codedNotation"],
			InLanguage: resource["ceasn:inLanguage"],
			HasChild: resource["ceasn:hasChild"],
			IsChildOf: resource["ceasn:isChildOf"],
			ShouldIndex: resource["ceasn:shouldIndex"],
			Weight: resource["ceasn:weight"],
			RegistryData: { CTID: resource["ceterms:ctid"], Resource: { URL: resource["@id"], Label: "View Resource" }, Envelope: { URL: resource["@id"]?.replace("/resources/", "/envelopes/"), Label: "View Envelope" } },
			Creator: resource["ceasn:creator"]?.map((m: string) => { return getOrganizationOutlineByURI(m) }),
			OwnedByLabel: resource["ceasn:creator"]?.map((m: string) => { return getOrganizationOutlineByURI(m) })?.map((m: any) => { return { Label: m.Label, URL: m.URL } as Link; })[0],
			Meta_JSON: resource
		} as CompetencyType;
	};

	function getLinks(source: any, property: string, typeLabel: string, searchType: string, targetNameProperty: string, currentValue: any, setMethod: any, convertToFinderURL: boolean, appendText?: string) {
		source = source || {};
		var isArrayValue = Array.isArray(source[property]);
		var value = (isArrayValue ? source[property] : [source[property]]).filter((m: any) => m);
		value.forEach((url: string) => {
			getResourceByURLWithoutState(url, 
				null,
				(response: any) => {
					appendLink(englishOrNull(response[targetNameProperty]) || "Error loading " + typeLabel + " Name", url, response["ceterms:ctid"], convertToFinderURL, appendText);
				},
				(error: any) => {
					appendLink("Error loading " + typeLabel + " Data: " + error, url, "", convertToFinderURL, appendText);
				}
			);
		});


		function appendLink(label: string, url: string, ctid: string, convertToFinderURL: boolean, appendText?: string) {
			var append = appendText || "";
			var alteredURL = (convertToFinderURL && ctid.length > 0) ? appURL + "/" + searchType + "/" + ctid + append : url + append;

			var link = {
				Label: label,
				URL: alteredURL
			} as Link;

			if (isArrayValue) {
				var match = currentValue.find((m: any) => m.URL == url);

				if (match) {
					match.Label = label;
					match.URL = alteredURL;
				}
				else {
					currentValue.push(link);
				}
				setMethod([...currentValue]);
			}
			else {
				setMethod(link);
			}
		}
	}

	function getOrganizationOutlineByURI(uri: string): Outline {
		var data = getResourceByURI(uri, setLocalAJAXCache).Data;
		return {
			Label: englishOrNull(data?.["ceterms:name"]) || <ActivityIndicator size='sm' color='inherit' />,
			URL: appURL + "/organization/" + uri.split("/resources/")[1],
			Image: data?.["ceterms:image"]
		} as Outline;
	}

	function createIdentifier(identifier: Array<any>) {
		return (identifier || []).map(source => {
			return {
				IdentifierType: englishOrNull(source["ceterms:identifierType"]),
				IdentifierTypeName: englishOrNull(source["ceterms:identifierTypeName"]),
				IdentifierValueCode: englishOrNull(source["ceterms:identifierValueCode"]),
			} as IdentifierValue;
		});
	}

	var typeLabel = widgetGetSingleLabelForText("Competency");
	var typeLabelPlural = widgetGetPluralLabelForText("Competencies");
	useEffect(() => {
		const ctid = competency.CTID;
		if (ctid != null) {
			var data = getResourceByURI(`${apiUrl}/GetRelatedResource/${ctid}/34`, setLocalAJAXCache).Data;//WorkRolesEntityTypeId is 34
			if (data != null && data.Result) {
				setRelatedWorkRoles(data.Result);
			}
		}

	}, [competency]);
	if (memberOfCollections != null && memberOfCollections.length > 1) {
		console.log(memberOfCollections);
		const distinctData = memberOfCollections.filter((Link, index, self) =>
			index === self.findIndex((l) => l.URL === Link.URL)
		);
		setMemberOfCollections(distinctData);
	}
	

	return (
		<LoadingOrError isLoading={dataIsLoading} hasError={hasError}>
			<PageBanner
				item={competency}
				bannerIcon={competenciesbannerIcon}
				fallbackIcon={competenciesbannerIcon}
			/>
			<Wrapper>
				<VStack>
					<PageSection icon={aboutIcon} title={"About this " + typeLabel} variant="Highlight" description={"Basic information about the " + typeLabel}>
						<PageSectionItem>
							<Description>
								<div>{competency.CompetencyText || "No description available"}</div>
								{competency.Comment?.length > 0 && <Comment><b>Comments:</b>{competency.Comment.map(m => <div>{m}</div>)}</Comment>}
								{competency.CompetencyCategory && <Comment><b>Competency Category:</b> {competency.CompetencyCategory}</Comment>}
								{competency.Weight && <Comment><b>Weight:</b> {competency.Weight}</Comment>}
							</Description>
						</PageSectionItem>
						<RelatedLinkSetFromURLs label="Source Documentation" urls={competency?.Meta_JSON?.["ceasn:sourceDocumentation"]} />
					</PageSection>
					<SubjectsAndKeywordsPageSection item={competency} pageSectionDescription={"Subjects and Keywords related to the " + typeLabel} />
					<RelatedLinkSetFromURLs label="Knowledge Embodied" urls={competency?.Meta_JSON?.["ceasn:knowledgeEmbodied"]} />
					<RelatedLinkSetFromURLs label="Skill Embodied" urls={competency?.Meta_JSON?.["ceasn:skillEmbodied"]} />
					<RelatedLinkSetFromURLs label="Ability Embodied" urls={competency?.Meta_JSON?.["ceasn:abilityEmbodied"]} />
					<RelatedLinkSetFromURLs label="Task Embodied" urls={competency?.Meta_JSON?.["ceasn:taskEmbodied"]} />
					<RelatedLinkSet label="Complexity Level" links={complexityLevel} />
					<RelatedLinkSet label="Education Level Type" links={educationLevelType} />
					<RelatedLinkSet label="Environmental Hazard Type" links={environmentalHazardType} />
					<RelatedLinkSet label="Performance Level Type" links={performanceLevelType} />
					<RelatedLinkSet label="Physical Capability Type" links={physicalCapabilityType} />
					<RelatedLinkSet label="Sensory Capability Type" links={sensoryCapabilityType} />
					<RelatedLinkSet label="Has Progression Level" links={hasProgressionLevel} />
					<AdditionalInformationPageSection item={competency} pageSectionDescription={"Identifiers and other information for the " + typeLabel} />
				</VStack>
				<VStack>
					<PageSection icon={connectionsIcon} title="Connections" description="Related Resources">
						<RelatedLinkSet label={"Part of " + widgetGetSingleLabelForText("Competency Framework")} links={partOfFramework?.URL ? [partOfFramework] : []} />
						<RelatedLinkSet label={"Member of " + widgetGetPluralLabelForText("Collections")} links={memberOfCollections} />
						<RelatedLinkSet label={"Substantiating " + widgetGetPluralLabelForText("Competency Frameworks")} links={substantiatingCompetencyFrameworks} />
						<RelatedLinkSet label={"Substantiating " + widgetGetPluralLabelForText("Credentials")} links={substantiatingCredentials} />
						<RelatedLinkSet label={"Substantiating " + widgetGetPluralLabelForText("Organizations")} links={substantiatingOrganizations} />
						<RelatedLinkSet label={"Substantiating " + widgetGetPluralLabelForText("Occupations")} links={substantiatingOccupations} />
						<RelatedLinkSet label={"Substantiating " + widgetGetPluralLabelForText("Jobs")} links={substantiatingJobs} />
						<RelatedLinkSet label={"Substantiating " + widgetGetPluralLabelForText("Work Roles")} links={substantiatingWorkRoles} />
						<RelatedLinkSet label={"Substantiating " + widgetGetPluralLabelForText("Tasks")} links={substantiatingTasks} />
						<RelatedLinkSetFromURLs label="Substantiating Resources" urls={competency?.Meta_JSON?.["ceasn:substantiatingResource"]} />
						<RelatedLinkSet label={"Parent " + widgetGetPluralLabelForText("Competencies")} links={isChildOf} />
						<RelatedLinkSet label={"Derived From " + widgetGetPluralLabelForText("Competencies")} links={derivedFrom} />
						<AJAXModalForCompetencyConnections competency={competency} relatedItemsMap={relatedItemsMap} urlProperty="ceasn:hasChild" labelTemplate={"{number} Child " + typeLabelPlural} />
						<AJAXModalForCompetencyConnections competency={competency} relatedItemsMap={relatedItemsMap} urlProperty="ceasn:alignFrom" labelTemplate={"Align from {number} " + typeLabelPlural} />
						<AJAXModalForCompetencyConnections competency={competency} relatedItemsMap={relatedItemsMap} urlProperty="ceasn:alignTo" labelTemplate={"Align to {number} " + typeLabelPlural} />
						<AJAXModalForCompetencyConnections competency={competency} relatedItemsMap={relatedItemsMap} urlProperty="ceasn:exactAlignment" labelTemplate={"Exact Alignment with {number} " + typeLabelPlural} />
						<AJAXModalForCompetencyConnections competency={competency} relatedItemsMap={relatedItemsMap} urlProperty="ceasn:broadAlignment" labelTemplate={"Broad Alignment with {number} " + typeLabelPlural} />
						<AJAXModalForCompetencyConnections competency={competency} relatedItemsMap={relatedItemsMap} urlProperty="ceasn:narrowAlignment" labelTemplate={"Narrow Alignment with {number} " + typeLabelPlural} />
						<AJAXModalForCompetencyConnections competency={competency} relatedItemsMap={relatedItemsMap} urlProperty="ceasn:majorAlignment" labelTemplate={"Major Alignment with {number} " + typeLabelPlural} />
						<AJAXModalForCompetencyConnections competency={competency} relatedItemsMap={relatedItemsMap} urlProperty="ceasn:minorAlignment" labelTemplate={"Minor Alignment with {number} " + typeLabelPlural} />
						<AJAXModalForCompetencyConnections competency={competency} relatedItemsMap={relatedItemsMap} urlProperty="ceasn:prerequisiteAlignment" labelTemplate={"Prerequisite Alignment with {number} " + typeLabelPlural} />
						<AJAXModalForCompetencyConnections competency={competency} relatedItemsMap={relatedItemsMap} urlProperty="ceasn:inferredCompetency" labelTemplate={"{number} Inferred " + typeLabelPlural} />
						<AJAXModalForCompetencyConnections competency={competency} relatedItemsMap={relatedItemsMap} urlProperty="ceasn:comprisedOf" labelTemplate={"Comprised of {number} " + typeLabelPlural} />
						<AJAXModalForCompetencyConnections competency={competency} relatedItemsMap={relatedItemsMap} urlProperty="ceasn:isVersionOf" labelTemplate={"Version of {number} " + typeLabelPlural} />
						<AJAXModalForCompetencyConnections competency={competency} relatedItemsMap={relatedItemsMap} urlProperty="ceasn:crossSubjectReference" labelTemplate={"{number} Cross-Subject Reference " + typeLabelPlural} />
						<AJAXModal urls={competency?.Meta_JSON?.["ceterms:hasWorkforceDemand"]} buttonLabel={"Has " + competency?.Meta_JSON?.["ceterms:hasWorkforceDemand"]?.length + " Workforce Demand Items"} resourceTitle="Workforce Demand" SuccessRenderMethod={RenderWorkforceDemand} ErrorRenderMethod={RenderError} />
						<ModalButtonAndWindow
							buttonLabel={"Related WorkRoles "}
							resourceTitle={competency.Name}
							items={relatedWorkRoles?.Values}
							Wrapper={Section}
							Content={() => <OutlineList items={relatedWorkRoles?.Values} />}
							hideCount={true}
						/>
					</PageSection>
					<OccupationsAndIndustriesSection item={competency} pageSectionDescription={"Career Field information about the " + typeLabel} />
				</VStack>
			</Wrapper>
		</LoadingOrError>
	)
}

function AJAXModalForCompetencyConnections(props: { competency: CompetencyType, urlProperty: string, labelTemplate: string, relatedItemsMap: Array<any> }) {
	//Outbound connections
	var outboundValue = props.competency?.Meta_JSON?.[props.urlProperty] || [] as Array<string>;
	var outboundURLs = Array.isArray(outboundValue) ? outboundValue : [outboundValue];

	//Inbound connections
	var inboundURLs = [] as Array<string>;
	props.relatedItemsMap.filter((map: any) => { return map.Path.match("< " + props.urlProperty + " < ceasn:Competency$")?.length > 0 }).flatMap((map: any) => { return map.URIs }).forEach((url: string) => {
		if (!inboundURLs.includes(url)) {
			inboundURLs.push(url);
		}
	});

	//Return set
	return (
		<>
			{outboundURLs?.length > 0 && (
				<ModalButtonAndWindow
					buttonLabel={props.labelTemplate.replace(/{number}/g, outboundURLs.length.toString())}
					resourceTitle={props.labelTemplate.replace(/{number}/g, "").replace(/  /g, " ").trim()}
					items={outboundURLs}
					Content={() => <AJAXResourceList urls={outboundURLs} onSuccess={(data, itemContent, listContent) => renderCompetencyFromRDF(data, itemContent, props.relatedItemsMap, false)} StyleComponent={CompetencyStyleBlock} />}
					hideCount={true}
				/>
			)}
			{inboundURLs?.length > 0 && (
				<ModalButtonAndWindow
					buttonLabel={"External/Incoming Connection: " + props.labelTemplate.replace(/{number}/g, inboundURLs.length.toString())}
					resourceTitle={"External/Incoming Connection: " + props.labelTemplate.replace(/{number}/g, "").replace(/  /g, " ").trim()}
					items={inboundURLs}
					Content={() => <AJAXResourceList urls={inboundURLs} onSuccess={(data, itemContent, listContent) => renderCompetencyFromRDF(data, itemContent, props.relatedItemsMap, false)} StyleComponent={CompetencyStyleBlock} />}
					hideCount={true}
				/>
			)}
		</>
	)
}


function RenderCompetencyLink(props: { data: any, index: number }) {
	const [framework, setFramework] = useState({} as any);

	useEffect(() => {
		if (props.data["ceasn:isPartOf"]) {
			getResourceByURLWithoutState(props.data["ceasn:isPartOf"], null, (data: any) => {
				setFramework(data);
			}, (error: any) => {
				console.log("Error loading framework " + props.data["ceasn:isPartOf"], error);
				setFramework({ "ceasn:name": "Error Loading Framework", "ceterms:ctid": "ce-" + props.data["ceasn:isPartOf"].split("/ce-")[1] });
			});
		}
	}, []);

	return <CompetencyLink>
		<div>
			{framework && <CompetencyLinkFrameworkName><a href={appURL + "/competencyframework/" + framework["ceterms:ctid"]}>{englishOrNull(framework["ceasn:name"])}</a>: </CompetencyLinkFrameworkName>}
			<a href={appURL + "/competency/" + props.data["ceterms:ctid"]}>{englishOrNull(props.data["ceasn:competencyLabel"]) || props.data["ceterms:ctid"]}</a>
		</div>
		<div>{englishOrNull(props.data["ceasn:competencyText"])}</div>
	</CompetencyLink>
}

function RenderWorkforceDemand(props: { data: any, index: number }) {
	return <WorkforceDemandAction item={{
		Name: englishOrNull(props.data["ceterms:name"]),
		Description: englishOrNull(props.data["ceterms:description"])
		//TODO: expand this later
	} as WorkforceDemand} />
}

function RenderError(props: { error: any, index: number }) {
	return <Error>{props.error}</Error>
}

function RelatedLinkSet(props: { label: string, links: Array<Link> }) {
	return props.links?.length > 0 && ( <RelatedLinkList
		label={props.label}
		items={Array.isArray(props.links) ? props.links : [props.links]}
		WrapperTag={PageSectionItem}
		HeaderTag={PageSectionItemHeader}
		asList={true}
	/>) || null
}

function RelatedLinkSetFromURLs(props: { label: string, urls: Array<string> }) {
	return props.urls?.length > 0 && (<PlainTextLinkListFromURLs
		label={props.label}
		urls={Array.isArray(props.urls) ? props.urls : [props.urls]}
		WrapperTag={PageSectionItem}
		HeaderTag={PageSectionItemHeader}
		asList={true}
	/>) || null
}

const PageSectionItemHeader = styled.div`
	font-weight: bold;
`;

const Comment = styled.div`
	font-style: italic;
	padding: 5px;
`;

const RelatedLinkList = styled(PlainTextLinkList)`
	& a {
		margin-bottom: 5px;
	}
`;

const RelatedListItem = styled.div`

`;

const CompetencyLink = styled(RelatedListItem)`

`;

const CompetencyLinkFrameworkName = styled.span`
	font-weight: bold;
	margin-right: 10px;
`;

const Error = styled(RelatedListItem)`

`;
