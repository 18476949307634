import React, { useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HStack, HStackFilterItem } from '../Stack';
import AutoCompletionTextField from '../AutoCompletionTextField';
import {
    isCheckboxFilter,
    isAutocompleteFilter,
    isTextboxFilter,
} from '../../utils';
import { autocompleteFilterSearchApiUrl } from '../../shared/globals';
import axios from 'axios';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Filter, FilterItem } from '../../types/external';
//import { convertFilterItemToIFilterItem } from '../../types/converters';
import { FilterContext, QueryContext } from '../../App';
import { constructSearchQuery } from '../../services/api';
import {
    StyledFilterOption,
    Checkbox,
    FilterItemAddButton,
    FilterItemDescription,
    FilterItemLabel,
    FilterItemOption,
    AddedItem,
    IconButton,
} from './styles';
import { widgetReplacePluralLabelInText, widgetReplaceSingleLabelInText, widgetApplyWidgetToQuery, widgetGetSingleLabelForText } from '../../utils/SiteWidgetizer';
import { addFilterItem, getCurrentQuery, removeFilterItem, toggleFilterItem, updateCurrentQuery } from "../../utils/SearchHelper";

interface FilterItemProps {
    searchType: string;
    filter: Filter;
    filterIdentifier: string;
    item: FilterItem;
    autoCompleteFilterItems: FilterItem[];
    status: boolean;
    onSelectFilter: (filter: Filter, item: FilterItem) => void;
}

export default function CFilterItem(props: FilterItemProps) {
    const isCheckbox = isCheckboxFilter(props.item);
    const isAutocompleteTextBox = isAutocompleteFilter(props.item);
    const isTextBox = isTextboxFilter(props.item);
	const [searchText, setSearchText] = useState('');
	const queryContext = useContext(QueryContext);
	const filterContext = useContext(FilterContext);


    function getOptionLabel(item: FilterItem) {
        return item.Label || '';
    }

	async function getAutoCompleteSuggestions(text: string) {
        setSearchText(text);

		const query = {
			...constructSearchQuery(queryContext.query, filterContext[queryContext.query.SearchType]),
			Keywords: text,
			AutocompleteContext: props.filter.URI
		};

		widgetApplyWidgetToQuery(query, queryContext.query.SearchType);

        try {
			const url = `${autocompleteFilterSearchApiUrl}`;
            const res = await axios.post(url, query);
            const data = res.data;
            if (data.Successful) {
                // TODO: API should return consistent format, empty Items array
				if (data.Result.Items) {
					return data.Result.Items;
                }
            }
            return [];
        } catch (error) {
            return [];
        }
	}

    function onTextBoxSearchChange(text: string) {
		setSearchText(text);
		if (isAutocompleteTextBox) {
			return getAutoCompleteSuggestions(text);
		}
		return [];
	}

	function onSelectSuggestedItem(suggestItem: FilterItem) {
		if (isAutocompleteTextBox) {
			addFilterItem(props.filter.Id, props.filter.URI, props.filter.Label, suggestItem.Id, "", suggestItem.Text); //Don't use suggestItem.URI because all suggested items have the same URI and it causes problems later
		}
	}

	function onAddItemFromTextBox() {
		searchText && addFilterItem(props.filter.Id, props.filter.URI, props.filter.Label, 0, "", searchText);
	}

    function renderFilterOption(item: FilterItem) {
        return (
            <StyledFilterOption
                key={`cr-autocomplete-suggestions-${item.URI}-${
                    item.Label || item.Text
                }`}>
                {item.Label || item.Text}
            </StyledFilterOption>
        );
    }

	function isChecked(filterID: any, itemID: any) {
		return (getCurrentQuery().MainFilters || []).find((m: any) => m.Id == filterID)?.Items.find((m: any) => m.Id == itemID) != null;
	}

	function toggleCheckBoxItem() {
		//Toggle the item in the data
		toggleFilterItem(props.filter.Id, props.filter.URI, props.filter.Label, props.item.Id, props.item.URI, props.item.Label);

		//Find out whether the box is checked
		var isTheBoxChecked = isChecked(props.filter.Id, props.item.Id);

		//Find the checkbox in the HTML, if present (based on either its ID or its URI), and force it to reflect the proper status
		var box1 = (document.querySelector("[data-filterid='" + props.filter.Id + "'] [data-filteritemid='" + props.item.Id + "']") as any);
		box1 && (box1.checked = isTheBoxChecked);
		var box2 = (document.querySelector("[data-filterid='" + props.filter.Id + "'] [data-filteritemuri='" + props.item.URI + "']") as any);
		box2 && (box2.checked = isTheBoxChecked);
	}

    if (isCheckbox) {
		return (
			<HStackFilterItem
				align="flex-start"
				spacing="5px"
				onClick={toggleCheckBoxItem}
			>
				<Checkbox
					aria-label={props.item.Label}
					type="checkbox"
					checked={isChecked(props.filter.Id, props.item.Id)}
					data-filteritemtype={"filterType:Checkbox"}
					data-filteritemid={props.item.Id}
					data-filteritemuri={props.item.URI}
					data-filteritemlabel={props.item.Label}
                />
                <FilterItemOption>
                    <FilterItemLabel>{widgetGetSingleLabelForText(props.item.Label)}</FilterItemLabel>
                    <FilterItemDescription>{widgetReplaceSingleLabelInText(widgetReplacePluralLabelInText(props.item.Description))}</FilterItemDescription>
                </FilterItemOption>
			</HStackFilterItem>
        );
    }

	if (isTextBox || isAutocompleteTextBox) {
		var currentItems = (getCurrentQuery().MainFilters || []).find((m: any) => m.Id == props.filter.Id)?.Items.filter((m: any) => !m.URI) || [];
        return (
            <FilterItemOption spacing="5px"
						data-filteritemtype={"filterType:TextBox"}
						data-filteritemid={props.item.Id}
						data-filteritemuri={props.item.URI}
						>
                <FilterItemLabel>{props.item.Label}</FilterItemLabel>
				<HStackFilterItem spacing="4px">
                    <AutoCompletionTextField
						placeholder="Type some text and press Enter"
						value={searchText}
						onChange={onTextBoxSearchChange}
						onSelect={onSelectSuggestedItem}
						getOptionLabel={getOptionLabel}
						renderOption={renderFilterOption}
						addItemHandler={onAddItemFromTextBox}
                    />
                    <FilterItemAddButton
                        buttonType="secondary"
                        onClick={onAddItemFromTextBox}>
                        Add
                    </FilterItemAddButton>
				</HStackFilterItem>
				{currentItems.map((item: any) => (
					<AddedItem
						data-filteritemtext={item.Text}
						key={`cr-autocomplete-added-${item.Text}`}
					>
                        {item.Text}
						<RemoveItem onClick={() => {
							removeFilterItem(props.filter.Id, props.filter.URI, props.filter.Label, 0, "", item.Text);
						}} />
                    </AddedItem>
                ))}
            </FilterItemOption>
        );
    }

    return null;
}

function RemoveItem(props: { onClick: () => void }) {
	return <RemoveItemButton
		title="Remove Item"
		onClick={props.onClick}
	>
		<FontAwesomeIcon icon={faTimes} size="1x" />
	</RemoveItemButton>
}

const RemoveItemButton = styled.button`
	background-color: transparent;
	color: #FFF;
	border: none;
`;