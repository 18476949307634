import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import pageIcon from '../../assets/images/icons/icon-transfer-value-green-blue-01.svg';
import costIcon from '../../assets/images/icons/icon-costs-and-time-blue-green-01.svg';
import Banner from '../../components/Banner';
import { WhiteLink } from '../../components/Banner/styles';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import LinkObject from '../../components/DetailPage/LinkObject';
import OutlineList from '../../components/DetailPage/Outline';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import {
    Description,
    FullWidthWrapper,
    InnerWrapper,
    Label, Section,
    Wrapper
} from '../../components/DetailPage/styles';
import HasAnyFilled from '../../components/HasAnyFilled';
import LoadingOrError from '../../components/LoadingOrError';
import { ModalButtonAndWindow } from '../../components/Modal';
import PageSection from '../../components/PageSection';
import PageSectionItem from '../../components/PageSectionItem';
import { VStack } from '../../components/Stack';
import { useGet, useMediaQuery } from '../../hooks';
import { apiUrl, legacySourceUrl } from '../../shared/globals';
import { theme } from '../../shared/theme';
import { SupportService } from '../../types/external';
import { detailSectionProperties } from '../../utils';
import SearchPills from '../../components/SearchPills';
import { isFilled } from '../../validation';
import CostManifestList from '../../components/DetailPage/CostManifest';
import CostProfileList from '../../components/DetailPage/CostProfile';
import DurationProfileList from '../../components/DetailPage/DurationProfile';
import LocationPageSection from '../../components/DetailPage/LocationPageSection';
import SubjectsAndKeywordsPageSection from "../../components/DetailPage/SubjectsAndKeywordsPageSection";
import OccupationsAndIndustriesSection from "../../components/DetailPage/OccupationsAndIndustriesSection";
import FinancialAssistance from "../../components/DetailPage/FinancialAssistance";
import DisplayPills from '../../components/PillDisplay';
import ConditionManifestList from "../../components/DetailPage/ConditionManifest";
import ConditionProfileList, { getConditionProfileListButtonName } from "../../components/DetailPage/ConditionProfile";
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';
import ConnectionsSection from "../../components/DetailPage/ConnectionsSection";
import { ModalType } from "../../types/internal";
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";




interface Props {
    id: string;
}

export default function SupportServicePage(props: RouteComponentProps<Props>) {
    const id = props.match.params.id;
    const [
        pageDataIsLoading,
        pageDataHasError,
        getPageData,
    ] = useGet();
    const [pageModel, setPageData] = useState({} as SupportService);
    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });

	useEffect(() => {
        getPageData(`${apiUrl}/SupportService/${id}`, (data: SupportService) => {
            setPageData(data);
        });
    }, [getPageData, id]);
    //

    function getSubTitleLink() {
        if (pageModel.OwnedByLabel && pageModel.OwnedByLabel.Label) {
            return pageModel.OwnedByLabel.URL ?
                <WhiteLink href={pageModel.OwnedByLabel.URL}>{pageModel.OwnedByLabel.Label}</WhiteLink> :
                <>{pageModel.OwnedByLabel.Label}</>;
        }
        return null;
    }

	const [openedModal, setOpenedModal] = useState<ModalType>();

    function onOpenModalClick(modalType: ModalType) {
        return () => setOpenedModal(modalType);
    }

    function onCloseModalClick() {
        setOpenedModal(undefined);
    }


	function getLegacyLink() {
        if (pageModel.Meta_Id) {
            const url = legacySourceUrl + "/" + pageModel.BroadType + "/" + pageModel.Meta_Id;
            const mystyle = {
                //color: "rgba(0,0,0,.01)",
                color: "transparent",
                //backgroundColor: "rgba(0,0,0,.01)",
                marginLeft: "10px"
            };
            return <>
                <WhiteLink href={url} target="legacy" style={mystyle} title="View in services application">Legacy</WhiteLink>
            </>

        }
        return null;
    }
	
	var typeLabel = widgetGetSingleLabelForText("Support Service");

    return (
        <LoadingOrError
            isLoading={pageDataIsLoading}
            hasError={pageDataHasError}>

            <Banner
                icon={pageIcon}
                fallBackIcon={pageIcon}
                type={pageModel.CTDLTypeLabel}
                title={pageModel.Name}
                subTitle={getSubTitleLink()}
                content={
                    <small>Last updated: {pageModel.Meta_LastUpdatedHeader} <span  >{getLegacyLink()}</span></small>
                }
            />
            <Wrapper>
                <VStack spacing={isMobile ? '0px' : '20px'}>
                    <HasAnyFilled
                        data={pageModel}
                        keys={detailSectionProperties.supportServiceAbout}>
                        <PageSection
							icon={aboutIcon}
							title={"About this " + typeLabel}
							variant="Highlight"
							description={"Basic information about this " + typeLabel}>

                            {pageModel.Description && (
                                <PageSectionItem>
                                    <Description>
                                        {pageModel.Description}
                                    </Description>
                                </PageSectionItem>
                            )}
							{pageModel.AccommodationType && (
								<PageSectionItem>
									<Label>Accommodation Type</Label>
									<SearchPills links={pageModel.AccommodationType} />
								</PageSectionItem>
							)}
							{pageModel.SupportServiceType && (
								<PageSectionItem>
									<Label>Support Service Category</Label>
									<SearchPills links={pageModel.SupportServiceType} />
								</PageSectionItem>
							)}
							{pageModel.DeliveryType && (
								<PageSectionItem>
									<Label>Delivery Type</Label>
									<SearchPills links={pageModel.DeliveryType} />
								</PageSectionItem>
							)}
							{pageModel.LifeCycleStatusType && (
								<PageSectionItem>
									<Label>Life Cycle Status</Label>
									<DisplayPills links={[pageModel.LifeCycleStatusType]} />
								</PageSectionItem>
							)}
                            {pageModel.SubjectWebpage &&
                                <PageSectionItem>
                                    <LinkObject item={{ URL: pageModel.SubjectWebpage, Label: "View this " + typeLabel }} />
                                </PageSectionItem>
                            }

                        </PageSection>
					</HasAnyFilled>
                </VStack>
                <VStack spacing={isMobile ? '0px' : '20px'}>
					<RelatedOrganizationPageSection item={pageModel} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " that offer the " + typeLabel} />
					<LocationPageSection item={pageModel} pageSectionDescription={"Geographic information about the " + typeLabel} />
					{isFilled(pageModel.SupportServiceReferencedBy) &&
						<ModalButtonAndWindow
						buttonLabel={pageModel.SupportServiceReferencedBy?.Label || "Support Service Refrences"}
							resourceTitle={pageModel.Name}
						items={pageModel.SupportServiceReferencedBy?.Values}
							Wrapper={Section}
						Content={() => <OutlineList items={pageModel.SupportServiceReferencedBy?.Values} />}
						/>
					}
					{pageModel.IsSpecificServiceOf?.Values?.length > 0 && 
						<PageSection icon={connectionsIcon} title={"Part of " + typeLabel} description={"This is a more specific part of the following " + widgetGetPluralLabelForText("Support Services")}>
							<OutlineList items={pageModel.IsSpecificServiceOf?.Values} />
						</PageSection>
					}
                </VStack>
			</Wrapper>
			<FullWidthWrapper>
				<TabSetOrSingle
					activeIndex={0}
					items={[
						{
							Label: "Has Support Services",
							Content: (
								(
									pageModel.HasSpecificService?.Values?.length > 0 &&
									<PageSection icon={connectionsIcon} title={"More Specific " + widgetGetPluralLabelForText("Support Services")} description={"Specific " + widgetGetPluralLabelForText("Support Services") + " that are part of this " + typeLabel}>
										{/*<AJAXResourceList urls={collectionMemberResourceURLs} onSuccess={(data: any, itemContainer: any, itemList: any) => renderCollectionMemberDataV2(data, itemContainer, graph)} StyleComponent={CollectionMemberStyleBlock} />*/}
										<OutlineList items={pageModel.HasSpecificService?.Values} />
									</PageSection>
								) || null
							)
						},
						{
							Label: "Details",
							Content: (
								<InnerWrapper>
									<VStack>
										{(isFilled(pageModel.CommonConditions) || isFilled(pageModel.SupportServiceConditon) || isFilled(pageModel.CommonCosts) || isFilled(pageModel.EstimatedCost) || isFilled(pageModel.FinancialAssistance)) &&
											<PageSection
												icon={costIcon}
												title="Requirements, Costs, and Time"
												description="How to earn the Credential">
												{(isFilled(pageModel.CommonConditions) || isFilled(pageModel.SupportServiceConditon)) &&
													<PageSectionItem>
														<Label>Requirements and Recommendations</Label>
														<ModalButtonAndWindow
															buttonLabel="Common Conditions"
															resourceTitle={pageModel.Name}
															items={pageModel.CommonConditions}
															Wrapper={Section}
															Content={() => <ConditionManifestList items={pageModel.CommonConditions} />}
														/>
														<ModalButtonAndWindow
															//buttonLabel="Requires"
															buttonLabel={getConditionProfileListButtonName("Support Service Condition", pageModel.SupportServiceConditon)}
															hideCount={true}
															resourceTitle={pageModel.Name}
															items={pageModel.SupportServiceConditon}
															Wrapper={Section}
															Content={() => <ConditionProfileList items={pageModel.SupportServiceConditon} />}
														/>
													</PageSectionItem>
												}
												{(isFilled(pageModel.CommonCosts) || isFilled(pageModel.EstimatedCost) || isFilled(pageModel.FinancialAssistance)) &&
													<PageSectionItem>
														<Label>Cost Information</Label>
														<ModalButtonAndWindow
															buttonLabel="Common Costs"
															resourceTitle={pageModel.Name}
															items={pageModel.CommonCosts}
															Wrapper={Section}
															Content={() => <CostManifestList items={pageModel.CommonCosts} />}
														/>
														<ModalButtonAndWindow
															buttonLabel="Specific Costs"
															resourceTitle={pageModel.Name}
															items={pageModel.EstimatedCost}
															Wrapper={Section}
															Content={() => <CostProfileList items={pageModel.EstimatedCost} />}
														/>
														<ModalButtonAndWindow
															buttonLabel="Financial Assistance"
															resourceTitle={pageModel.Name}
															items={pageModel.FinancialAssistance}
															Wrapper={Section}
															Content={() => <FinancialAssistance item={pageModel} />}
														/>
													</PageSectionItem>
												}
											</PageSection>
										}
										<AdditionalInformationPageSection item={pageModel} pageSectionDescription={"Identifiers and other information for the " + typeLabel} />
									</VStack>
									<VStack>
										<HasAnyFilled
											data={pageModel}
											keys={[
												...detailSectionProperties.entityConnections
											]}>
											<PageSection
												icon={connectionsIcon}
												title="Connections"
												description="Related Resources"
											>
												<PageSectionItem>
													<ConnectionsSection
														item={pageModel}
														openedModal={openedModal}
														onOpenModalClick={onOpenModalClick}
														onCloseModalClick={onCloseModalClick}
													/>
												</PageSectionItem>
											 </PageSection>
										</HasAnyFilled>
										<OccupationsAndIndustriesSection item={pageModel} pageSectionDescription={"Career Field information about the " + typeLabel} itemTypeLabel={widgetGetPluralLabelForText("Support Services")} />
									</VStack>
								</InnerWrapper>
							)
						}
					]}
				/>
			</FullWidthWrapper>
        </LoadingOrError>
    );
}
//