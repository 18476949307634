import React from 'react';
import {
    Assessment,
    Credential,
    LearningOpportunity,
    SupportService,
} from '../../types/external';
import { Section } from './styles';
import { Body, Heading } from './profileStyles';
import { isFilled } from '../../validation';
import { decode } from 'html-entities';
import DisplayPills from '../../components/PillDisplay';
import LinkObject from './LinkObject';

interface Props {
    item: Assessment | LearningOpportunity | Credential | SupportService;
}

export default function FinancialAssistance({ item }: Props) {
    return (
        <div>
            {item.FinancialAssistance.map((profile, index) => (
                <Section key={`cr-financial-assistance-${index}`}>
                    <Heading>{profile.Name}</Heading>
                    <Body>
                        {profile.Description && (
                            <Section>
                                <label>Description: </label>
                                <div>{profile.Description}</div>
                            </Section>
                        )}
                        {isFilled(profile.FinancialAssistanceType) && (
                            <Section>
                                <label>Financial Assistance Type:</label>
                                <DisplayPills
                                    links={profile.FinancialAssistanceType}
                                />
                            </Section>
                        )}
                        {isFilled(profile.FinancialAssistanceValue) && (
                            <Section>
                                <label>Financial Assistance Value:</label>
                                {profile.FinancialAssistanceValue.map(
                                    (value, index) => (
                                        <div key={`cr-fav-${index}`}>
                                            {decode(value)}
                                        </div>
                                    ),
                                )}
                            </Section>
                        )}
                        {profile.SubjectWebpage && (
                            <Section>
								{profile.SubjectWebpage && <LinkObject item={{ URL: profile.SubjectWebpage, Label: "More Information" }} />}
                            </Section>
                        )}
                    </Body>
                </Section>
            ))}
        </div>
    );
}
