import styled from 'styled-components/macro';

export const Wrapper = styled.article``;

//
// -- Header --
//
export const GraphHeader = styled.header`
    margin-bottom: 3rem;
`;

export const GraphTitle = styled.h2`
    margin: 0;
`;

export const GraphCounter = styled.div`
    color: ${(p) => p.theme.color.brown};
    font-size: 5rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 1rem;
`;

export const HeaderTitle = styled.h3`
    font-size: ${(props) => props.theme.fontSize.lg};
    line-height: 1.125;
    margin: 0;
`;

export const HeaderContent = styled.div``;

//
// -- Body/Main --
//
export const GraphMain = styled.div`
    @media (min-width: ${(p) => p.theme.breakpoint.tabletPortrait}) {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: 350px 1fr;
    }
`;

//
// -- Filters --
//
export const Filters = styled.ul`
    border-top: 1px solid ${(p) => p.theme.color.greyLight};
    margin: 0;
    padding: 0;
`;

export const FilterItem = styled.li`
    border-bottom: 1px solid ${(p) => p.theme.color.greyLight};
    line-height: 1;
    list-style: none outside none;
    margin: 0;
`;

export const FilterButton = styled.button`
    background: transparent;
    border: 0 none;
    box-shadow: none;
    margin: 0;
    padding: 0.4rem 1rem;
    text-align: left;
    width: 100%;

    &.active,
    &:hover {
        background: linear-gradient(
            90deg,
            ${(p) => p.theme.color.aquaDark} 4px,
            transparent 4px
        );
    }

    &.active {
        font-weight: 700;
    }
`;

//
// -- Footer --
//
export const GraphFooter = styled.footer`
    margin-top: 3rem;
`;

export const FooterCounter = styled.div`
    font-size: ${(props) => props.theme.fontSize.xxl};
    font-weight: 700;
    line-height: 1.1667;
`;

export const FooterTitle = styled.h3`
    font-size: ${(props) => props.theme.fontSize.lg};
    line-height: 1.125;
    margin: 0;
`;

export const FooterContent = styled.div``;
