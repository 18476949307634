import styled from 'styled-components/macro';

export const Body = styled.div`
    padding: 10px;
    border: ${(p) => `1px solid ${p.theme.color.aquaDark}`};
`;

export const Heading = styled.div`
    padding: 10px;
    background-color: ${(p) => p.theme.color.aquaDark};
`;

export const Description = styled.div`
    margin-bottom: 10px;
`;

export const Section = styled.div`
    margin-bottom: 10px;

    &:last-of-type {
        margin-bottom: 0;
    }
`;

export const Type = styled.span`
    padding: 10px;
    background-color: ${(p) => p.theme.color.aquaLight};
    display: inline-block;
`;
