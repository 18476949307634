import React, { useState } from 'react';
import { TogglerButton, ExpandableP } from './styles';

interface Props {
    text: string;
}
export default function ExpandableText({ text }: Props) {
    const [showMore, setShowMore] = useState(false);

    const isExpandable = text.length > 300;
    return (
        <div>
            {isExpandable ? (
                <>
                    {showMore ? (
                        <ExpandableP>{text}</ExpandableP>
                    ) : (
                        <ExpandableP>
                            {text.substring(0, 300) + '...'}
                        </ExpandableP>
                    )}
                    <TogglerButton onClick={() => setShowMore(!showMore)}>
                        {showMore ? 'Show less' : 'Show more'}
                    </TogglerButton>
                </>
            ) : (
                <p>{text}</p>
            )}
        </div>
    );
}
