import React, { ReactNode, useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import styled from 'styled-components/macro';
import { apiUrl, appBaseName, appURL, registryURL } from '../../shared/globals';
import { RouteComponentProps } from 'react-router-dom';
import LoadingOrError from '../../components/LoadingOrError';
import { CompetencyFramework as CompetencyFrameworkType, Competency as CompetencyType, Outline, Link, IdentifierValue, AJAXSettings } from '../../types/external';
import { Concept } from '../ConceptSchemePage';
import competenciesbannerIcon from '../../assets/images/icons/icon-competencies-white-green-01.svg';
import competenciesIcon from '../../assets/images/icons/icon-competencies-blue-green-01.svg';
import PageBanner from '../../components/DetailPage/PageBanner';
import {
	Wrapper,
	FullWidthWrapper,
	InnerWrapper,
	Description,
	Label,
	Section
} from '../../components/DetailPage/styles';
import { VStack } from '../../components/Stack';
import PageSection from '../../components/PageSection';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';
import RelatedOrganizationPageSection, { RelatedOrganizationPageSectionAJAXResourceList } from '../../components/DetailPage/RelatedOrganizationPageSection';
import { getResourceByURI, englishOrNull, createKeywordSearchLink, getAndRenderByURL, getResourceByURLWithoutState, createReferenceFrameworkFromCredentialAlignmentObject, addResourceToCache } from '../../components/GetResourceByURI';
import ActivityIndicator from '../../components/ActivityIndicator';
import { CompetencyFramework } from '../../components/DetailPage/CompetencyFramework';
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import LinkObject from '../../components/DetailPage/LinkObject';
import OutlineList from '../../components/DetailPage/Outline';
import PageSectionItem from '../../components/PageSectionItem';
import SearchPills from '../../components/SearchPills';
import SubjectsAndKeywordsPageSection from '../../components/DetailPage/SubjectsAndKeywordsPageSection';
import OccupationsAndIndustriesSection from '../../components/DetailPage/OccupationsAndIndustriesSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ConnectionsSectionForDSP, { DSPConnection } from '../../components/DetailPage/ConnectionsSectionForDSP';
import { renderCompetencyFromRDF, CompetencyStyleBlock } from '../../components/DetailPage/Competency';
import AJAXResourceList, { getDetailPageHeaderModifiedDate, OutlineStyleBlock, renderOutline } from '../../components/AJAXResourceList';
import { ModalButtonAndWindow } from '../../components/Modal';
import { renderJSONLDMetaTags } from '../../utils/JSONLDMetaHelper';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";
import { LinkItem } from '../../components/DetailPage/Shared';


interface Props {
	id: string;
	targetCTID?: string;
}

export default function CompetencyFrameworkPageV2(props: RouteComponentProps<Props>) {
	const id = props.match.params.id;
	const [dataIsLoading, setDataIsLoading] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [competencyFramework, setCompetencyFramework] = useState({} as CompetencyFrameworkType);
	const [competencies, setCompetencies] = useState([] as Array<CompetencyType>);
	const [localAJAXCache, setLocalAJAXCache] = useState([] as Array<any>); //Used to trick react into re-rendering the data
	const [relatedItems, setRelatedItems] = useState([] as Array<any>);
	const [relatedItemsMap, setRelatedItemsMap] = useState([] as Array<any>);
	const [rawCompetencyFramework, setRawCompetencyFramework] = useState({} as any);
	const [relatedWorkRoles, setRelatedWorkRoles] = useState({} as AJAXSettings);

	//This is so backwards I hate this
	const [publicationStatusText, setPublicationStatusText] = useState("");

	useEffect(() => {

		getResourceByURI(`${apiUrl}/dsp_competencyframework/${id}?includeRelatedResources=true&perBranchLimit=20`, setLocalAJAXCache, null,
			(loaded: any) => {
				var data = loaded.Result;
				renderJSONLDMetaTags(data.Resource, "CompetencyFramework");
				setRawCompetencyFramework(loaded.Result.Resource); //Takes extra rendering cycles for some stupid reason so double assign it
				setDataIsLoading(false);
				setCompetencyFramework(convertDataToCompetencyFramework(data.Resource || { "ceasn:name": "Missing Competency Framework", "ceasn:description": "Unable to find data for identifier: " + id }, data.Metadata));
				setCompetencies(data.RelatedItems?.filter((m: any) => { return m && m["ceasn:isPartOf"] == data?.Resource?.["@id"] }).map((m: CompetencyType) => { return convertDataToCompetency(m) }) || []);
				setRelatedItems(data.RelatedItems);
				setRelatedItemsMap(data.RelatedItemsMap);
				data.RelatedItems?.forEach((item: any) => {
					if (item["@id"]) {
						addResourceToCache(item["@id"], item);
					}
				});
				console.log("DSP Data", { Framework: competencyFramework, Competencies: competencies, Map: data.RelatedItemsMap, Related: data.RelatedItems });

				if (competencyFramework.PublicationStatusType) {
					getResourceByURLWithoutState(competencyFramework.PublicationStatusType, null, (success: any) => {
						setPublicationStatusText(englishOrNull(success?.["@graph"]?.[0]?.["skos:prefLabel"]));
						console.log(publicationStatusText);
					}, (error: any) => {
						setPublicationStatusText("Error loading Status: " + competencyFramework.PublicationStatusType);
					});
				}

				(Array.from(competencyFramework.Meta_JSON?.["ceasn:conceptTerm"] || [])).forEach((uri: any) => { //Array.from is only used here because TypeScript will not get out of my way without it
					getResourceByURLWithoutState(uri, null, (success: any) => {
						competencyFramework.ConceptTerm.push(createKeywordSearchLink("competencyframework", englishOrNull(success["skos:prefLabel"])) as Link);
					});
				});
			},
			(error: any) => {
				setHasError(true);
				console.log("Error loading data: ", error);
			}
		);

		new URLSearchParams(window.location.search).forEach(function (value: string, key: string) {
			if (key.toLowerCase() == "competencyctid" || key.toLowerCase() == "targetctid") {
				console.log("Jumping to CTID", value.toLowerCase());
				jumpToCompetencyCTID(value.toLowerCase(), 0);
			}
		});
	}, [id, localAJAXCache]);

	function jumpToCompetencyCTID(targetCTID: string, attempts: number) {
		setTimeout(function () {
			var element = document.getElementById(targetCTID);
			if (element && element.getElementsByClassName("competencyContent").length > 0) {
				element.scrollIntoView();
			}
			else if (attempts <= 10) {
				jumpToCompetencyCTID(targetCTID, attempts + 1);
			}
		}, 500);
	}

	function convertDataToCompetencyFramework(resource: any, metadata: any): CompetencyFrameworkType {
		return {
			BroadType: "CompetencyFramework",
			CTDLType: "ceasn:CompetencyFramework",
			CTDLTypeLabel: "Competency Framework",
			Meta_Id: id,
			Meta_Language: resource["ceasn:inLanguage"]?.[0] || "en",
			CTID: resource["ceterms:ctid"],
			Meta_LastUpdated: metadata?.DateModified || "Unknown",
			Meta_LastUpdatedHeader: getDetailPageHeaderModifiedDate(metadata?.DateModified),
			Name: englishOrNull(resource["ceasn:name"]),
			Description: englishOrNull(resource["ceasn:description"]),
			Source: resource["ceasn:source"],
			InLanguage: resource["ceasn:inLanguage"],
			ConceptKeyword: englishOrNull(resource["ceasn:conceptKeyword"])?.map((m: string) => { return createKeywordSearchLink("competencyframework", m) }),
			//ConceptTerm: resource["ceasn:conceptTerm"]?.map((m: string) => { return createKeywordSearchLink("competencyframework", englishOrNull(m)) }),
			ConceptTerm: [] as Array<Link>,
			InstructionalProgramType: resource["ceterms:instructionalProgramType"]?.map((m: any) => { return createReferenceFrameworkFromCredentialAlignmentObject("competencyframework", m) }),
			IndustryType: resource["ceterms:industryType"]?.map((m: any) => { return createReferenceFrameworkFromCredentialAlignmentObject("competencyframework", m) }),
			OccupationType: resource["ceterms:occupationType"]?.map((m: any) => { return createReferenceFrameworkFromCredentialAlignmentObject("competencyframework", m) }),
			HasProgressionModel: resource["asn:hasProgressionModel"], //Should use the same data conversion process as concept schemes
			LocalSubject: resource["ceasn:localSubject"]?.map((m: string) => { return createKeywordSearchLink("competencyframework", englishOrNull(m)) }),
			Creator: resource["ceasn:creator"]?.map((m: string) => { return getOrganizationOutlineByURI(m) }),
			AlignFrom: resource["ceasn:alignFrom"],
			AlignTo: resource["ceasn:alignTo"],
			DerivedFrom: resource["ceasn:derivedFrom"],
			DateCreated: resource["ceasn:dateCreated"],
			DateCopyrighted: resource["ceasn:dateCopyrighted"],
			DateModified: resource["ceasn:dateModified"],
			DateValidFrom: resource["ceasn:dateValidFrom"],
			DateValidUntil: resource["ceasn:dateValidUntil"],
			License: resource["ceasn:license"],
			PublicationStatusType: resource["ceasn:publicationStatusType"],
			EducationLevelType: resource["ceasn:educationLevelType"],
			Publisher: resource["ceasn:publisher"]?.map((m: string) => { return getOrganizationOutlineByURI(m) }),
			PublisherName: englishOrNull(resource["ceasn:publisherName"]),
			Rights: englishOrNull(resource["ceasn:rights"]),
			RightsHolder: resource["ceasn:rightsHolder"] && (Array.isArray(resource["ceasn:rightsHolder"]) ? resource["ceasn:rightsHolder"].map(m => getOrganizationOutlineByURI(m)) : [getOrganizationOutlineByURI(resource["ceasn:rightsHolder"])]),
			CredentialRegistryURL: resource["@id"],
			HasTopChild: resource["ceasn:hasTopChild"],
			LatestVersion: resource["ceterms:latestVersion"],
			PreviousVersion: resource["ceterms:previousVersion"],
			NextVersion: resource["ceterms:nextVersion"],
			VersionIdentifier: createIdentifier(resource["ceterms:versionIdentifier"]),
			OwnedByLabel: (resource["ceasn:creator"] || resource["ceasn:publisher"])?.map((m: string) => { return getOrganizationOutlineByURI(m) })?.map((m: any) => { return { Label: m.Label, URL: m.URL } as Link; })[0],
			RegistryData: { CTID: resource["ceterms:ctid"], Resource: { URL: resource["@id"], Label: "View Resource" }, Envelope: { URL: resource["@id"]?.replace("/resources/", "/envelopes/"), Label: "View Envelope" } },
			Meta_JSON: resource || {}
		} as CompetencyFrameworkType;
	}

	function getOrganizationOutlineByURI(uri: string): Outline {
		var data = getResourceByURI(uri, setLocalAJAXCache).Data;
		return {
			Label: englishOrNull(data?.["ceterms:name"]) || <ActivityIndicator size='sm' color='inherit' />,
			URL: appURL + "/organization/" + uri.split("/resources/")[1],
			Image: data?.["ceterms:image"]
		} as Outline;
	}

	var topCompetencies = competencyFramework.HasTopChild?.length > 0 ? competencyFramework.HasTopChild.map((m) => { return competencies.filter((n) => { return n.CredentialRegistryURL == m })[0] }).filter((m) => { return m }) : competencies;
	var renderedCompetencies = [] as Array<any>;

	var referencedByCredentials = getDSPConnectionURIs(new RegExp("ceterms:Credential$"));
	var referencedByAssessments = getDSPConnectionURIs(new RegExp("ceterms:AssessmentProfile$"));
	var referencedByLearningOpportunities = getDSPConnectionURIs(new RegExp("ceterms:LearningOpportunityProfile$"));
	var referencesConceptSchemes = getDSPConnectionURIs(new RegExp("skos:ConceptScheme$"));
	var referencedByWorkRoles = getDSPConnectionURIs(new RegExp("ceterms:WorkRole$"));
	var referencedByJobs = getDSPConnectionURIs(new RegExp("ceterms:Job$"));
	var referencedByOccupations = getDSPConnectionURIs(new RegExp("ceterms:Occupation$"));
	var referencedByTasks = getDSPConnectionURIs(new RegExp("ceterms:Task$"));

	function getDSPConnectionURIs(expression: RegExp): Array<string> {
		var uris = [] as Array<string>;
		var matches = relatedItemsMap?.filter(m => { return expression.test(m.Path) }) || [];
		matches.forEach((match) => {
			uris = uris.concat(match.URIs?.filter((m: string) => { return uris.indexOf(m) == -1 }) || []);
		});
		return uris;
	}

	var typeLabel = widgetGetSingleLabelForText("Competency Framework");

	useEffect(() => {
		const ctid = competencyFramework.CTID;
		if (ctid != null) {
			var data = getResourceByURI(`${apiUrl}/GetRelatedResource/${ctid}/34`, setLocalAJAXCache).Data;//WorkRolesEntityTypeId is 34
			if (data != null && data.Result) {
				setRelatedWorkRoles(data.Result);
			}
		}
		
	}, [competencyFramework]);
	return (
		<LoadingOrError isLoading={dataIsLoading} hasError={hasError}>
			<PageBanner
				item={competencyFramework}
				bannerIcon={competenciesbannerIcon}
				fallbackIcon={competenciesbannerIcon}
			/>
			<Wrapper>
				<VStack>
					<PageSection icon={aboutIcon} title={"About this " + typeLabel} variant="Highlight" description={"Basic information about the " + typeLabel}>
						<Description>{competencyFramework.Description || "No description available"}</Description>
						{competencyFramework.Rights && <Rights><b>Rights:</b> {competencyFramework.Rights}</Rights>}
						{competencyFramework.Source?.length > 0 && (
							<PageSectionItem>
								{competencyFramework.Source.map((m) => (
									<LinkObject item={{ URL: m, Label: "Source Documentation" }} />
								))}
							</PageSectionItem>
						)}
						{competencyFramework.PublicationStatusType && (
							<PageSectionItem>
								<Label>Publication Status Type</Label>
								{publicationStatusText && <a href={competencyFramework.PublicationStatusType} target="_blank">{publicationStatusText}</a>}
								{!publicationStatusText && <div><ActivityIndicator size="sm" /></div>}
							</PageSectionItem>
						)}
						{competencyFramework.EducationLevelType?.length > 0 && (
							<PageSectionItem>
								<Label>Education Level Type</Label>
								{competencyFramework.EducationLevelType.map((m) => (
									<a href={m} target="_blank">Education Level Type</a>
								))}
								{/*<SearchPills links={[competencyFramework.EducationLevelType]} /> //TODO: Add this when we have a good way to do so*/}
							</PageSectionItem>
						)}
						{competencyFramework.ConceptTerm?.length > 0 && (
							<PageSectionItem>
								<Label>Concepts</Label>
								<SearchPills links={competencyFramework.ConceptTerm} />
							</PageSectionItem>
						)}
						{(competencyFramework.LatestVersion || competencyFramework.PreviousVersion || competencyFramework.NextVersion) &&
							<PageSectionItem>
								<Label>Versions</Label>
								<PageSectionItem>
									<LinkItem label="Latest Version:" item={competencyFramework.LatestVersion} />
									<LinkItem label="Previous Version:" item={competencyFramework.PreviousVersion} />
									<LinkItem label="Next Version:" item={competencyFramework.NextVersion} />
								</PageSectionItem>
							</PageSectionItem>
						}
					</PageSection>
				</VStack>
				<VStack>
					<RelatedOrganizationPageSectionAJAXResourceList source={rawCompetencyFramework} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " related to the " + typeLabel} />
				</VStack>
			</Wrapper>
			<FullWidthWrapper>
				<TabSetOrSingle
					activeIndex={0}
					items={[
						{
							Label: widgetGetPluralLabelForText("Competencies"),
							Content: (
								<PageSection icon={competenciesIcon} title={widgetGetPluralLabelForText("Competencies")} description={widgetGetPluralLabelForText("Competencies") + " in the " + typeLabel}>
									<CompetencyListSummary>This {typeLabel} contains {competencies.length} Competencies.</CompetencyListSummary>
									<AJAXResourceList
										urls={topCompetencies.map(m => m.CredentialRegistryURL)}
										resources={relatedItems.map(m => { return { URL: m["@id"], Data: m } })}
										onSuccess={(data, itemContent, listContent) => renderCompetencyFromRDF(data, itemContent, relatedItemsMap, true )}
										StyleComponent={CompetencyStyleBlock}
									/>
									{/*
										<CompetencyListManual topCompetencies={topCompetencies} allCompetencies={competencies} relatedItems={relatedItems} renderedCompetencies={renderedCompetencies} setLocalAJAXCache={setLocalAJAXCache} />
									 */}
								</PageSection>
							)
						},
						{
							Label: "Details",
							Content: (
								<InnerWrapper>
									<VStack>
										<AdditionalInformationPageSection item={competencyFramework} pageSectionDescription={"Additional information about the " + typeLabel} />
									</VStack>
									<VStack>
										<SubjectsAndKeywordsPageSection item={competencyFramework} pageSectionDescription={"Subjects and Keywords related to the " + typeLabel} />
										<OccupationsAndIndustriesSection item={competencyFramework} pageSectionDescription={"Career Field information about the " + typeLabel} itemTypeLabel={widgetGetPluralLabelForText("Competency Frameworks")} />
										{/*<ConnectionsSectionForDSP
											resourceTitle={competencyFramework.Name}
											resourceTypeLabel="Competency Framework"
											relatedItemsMap={relatedItemsMap}
											relatedItems={relatedItems}
											dspConnections={[
												{ LabelTemplate: "Referenced by # Credential{s}", SearchType: "credential", Regex: new RegExp("ceterms:Credential$") },
												{ LabelTemplate: "Referenced by # Assessment{s}", SearchType: "assessment", Regex: new RegExp("ceterms:AssessmentProfile$") },
												{ LabelTemplate: "Referenced by # Learning Opportunit{ies}", SearchType: "learningopportunity", Regex: new RegExp("ceterms:LearningOpportunityProfile$") },
												{ LabelTemplate: "References # Concept Scheme{s}", SearchType: "conceptscheme", Regex: new RegExp("skos:ConceptScheme") },
											]}
										/>*/}
										{(
											referencedByCredentials.length > 0 ||
											referencedByAssessments.length > 0 ||
											referencedByLearningOpportunities.length > 0 ||
											referencesConceptSchemes.length > 0 ||
											relatedWorkRoles.Total > 0 || referencedByTasks.length > 0 || referencedByJobs.length > 0 || referencedByOccupations.length > 0
										) && (
												<PageSection icon={connectionsIcon} title="Connections" description={"Related Resources" /*"Relationships between the " + props.resourceTypeLabel + " and other data in the Registry"*/}>
													<ConnectionsSectionForDSPModal
														competencyFramework={competencyFramework}
														uriList={referencedByCredentials}
														label={"Referenced by " + referencedByCredentials.length + " " + (referencedByCredentials.length == 1 ? widgetGetSingleLabelForText("Credential") : widgetGetPluralLabelForText("Credentials"))}
													/>
													<ConnectionsSectionForDSPModal
														competencyFramework={competencyFramework}
														uriList={referencedByAssessments}
														label={"Referenced by " + referencedByAssessments.length + " " + (referencedByAssessments.length == 1 ? widgetGetSingleLabelForText("Assessment") : widgetGetPluralLabelForText("Assessments"))}
													/>
													<ConnectionsSectionForDSPModal
														competencyFramework={competencyFramework}
														uriList={referencedByLearningOpportunities}
														label={"Referenced by " + referencedByLearningOpportunities.length + " " + (referencedByLearningOpportunities.length == 1 ? widgetGetSingleLabelForText("Learning Opportunity") : widgetGetPluralLabelForText("Learning Opportunities"))}
													/>
													<ConnectionsSectionForDSPModal
														competencyFramework={competencyFramework}
														uriList={referencesConceptSchemes}
														label={"References " + referencesConceptSchemes.length + " " + (referencesConceptSchemes.length == 1 ? widgetGetSingleLabelForText("Concept Scheme") : widgetGetPluralLabelForText("Concept Schemes"))}
													/>
												<ModalButtonAndWindow
													buttonLabel={"Related WorkRoles "}
													resourceTitle={competencyFramework.Name}
													items={relatedWorkRoles?.Values}
													Wrapper={Section}
													Content={() => <OutlineList items={relatedWorkRoles?.Values} />}
													hideCount={true}
												/>
													<ConnectionsSectionForDSPModal
														competencyFramework={competencyFramework}
														uriList={referencedByJobs}
														label={"References " + referencedByJobs.length + " " + (referencedByJobs.length == 1 ? widgetGetSingleLabelForText("Job") : widgetGetPluralLabelForText("Jobs"))}
													/>
													<ConnectionsSectionForDSPModal
														competencyFramework={competencyFramework}
														uriList={referencedByOccupations}
														label={"References " + referencedByOccupations.length + " " + (referencedByOccupations.length == 1 ? widgetGetSingleLabelForText("Occupation") : widgetGetPluralLabelForText("Occupations"))}
													/>
													<ConnectionsSectionForDSPModal
														competencyFramework={competencyFramework}
														uriList={referencedByTasks}
														label={"References " + referencedByTasks.length + " " + (referencedByTasks.length == 1 ? widgetGetSingleLabelForText("Task") : widgetGetPluralLabelForText("Tasks"))}
													/>
												</PageSection>
											)}
									</VStack>
								</InnerWrapper>
							)
						}
					].filter(m => m.Content != null)}
				/>
			</FullWidthWrapper>
		</LoadingOrError>
	)
}

function createIdentifier(identifier: Array<any>) {
	return (identifier || []).map(source => {
		return {
			IdentifierType: englishOrNull(source["ceterms:identifierType"]),
			IdentifierTypeName: englishOrNull(source["ceterms:identifierTypeName"]),
			IdentifierValueCode: englishOrNull(source["ceterms:identifierValueCode"]),
		} as IdentifierValue;
	});
}

function ConnectionsSectionForDSPModal(props: { competencyFramework: CompetencyFrameworkType, uriList: Array<string>, label: string }) {
	return props.uriList?.length > 0 && (
		<ModalButtonAndWindow
			buttonLabel={props.label}
			resourceTitle={props.competencyFramework.Name}
			items={props.uriList}
			Wrapper={Section}
			Content={() => <AJAXResourceList
				urls={props.uriList}
				onSuccess={(data, itemContent, listContent) => renderOutline(data, itemContent, listContent)}
				StyleComponent={OutlineStyleBlock}
			/>}
			hideCount={true}
		/>
	) || null
}

export function convertDataToCompetency(resource: any): CompetencyType {
	return {
		BroadType: "Competency",
		CTDLType: "ceasn:Competency",
		CTDLTypeLabel: "Competency",
		Meta_Language: resource["ceasn:inLanguage"]?.[0] || "en",
		CredentialRegistryURL: resource["@id"],
		CTID: resource["ceterms:ctid"],
		CompetencyLabel: englishOrNull(resource["ceasn:competencyLabel"]),
		CompetencyText: englishOrNull(resource["ceasn:competencyText"]),
		CompetencyCategory: englishOrNull(resource["ceasn:competencyCategory"]),
		Comment: englishOrNull(resource["ceasn:comment"]),
		ListID: resource["ceasn:listID"],
		CodedNotation: resource["ceasn:codedNotation"],
		HasChild: resource["ceasn:hasChild"],
		Meta_JSON: resource
	} as CompetencyType;
}

export function CompetencyListManual(props: { topCompetencies: Array<CompetencyType>, allCompetencies: Array<CompetencyType> | null, relatedItems: Array<any>, renderedCompetencies: Array<any>, setLocalAJAXCache: React.Dispatch<React.SetStateAction<any[]>> }) {
	useEffect(() => {
		var container = document.getElementById("mainCompetencyBox");
		if (container && Array.from(container.classList).indexOf("rendered") == -1) {
			container.classList.add("rendered");
			props.topCompetencies.forEach((competency) => {
				renderCompetency(competency, props.allCompetencies, props.relatedItems, props.renderedCompetencies, container, props.setLocalAJAXCache);
			});
		}
	});
	return (
		<>
			<style type="text/css">{`
				.competency { border-top: 1px solid #CCC; }
				.competency:empty { display: none; }
				.competency .competencyExtras:empty { display: none; }
				.competency .competencyExtras .competencyExtraListWrapper:not(.expanded) .competencyExtraListSummaryButton svg.expandedIcon { display: none; }
				.competency .competencyExtras .competencyExtraListWrapper.expanded .competencyExtraListSummaryButton svg.collapsedIcon { display: none; }
				.competency .competencyExtras .competencyExtraListWrapper:not(.expanded) .competencyExtraListValues .competencyExtraListItem:not(:first-child) { display: none; }
				.competency .competencyChildren { padding-left: 25px; }
			`}</style>
			<div id="mainCompetencyBox"></div>
		</>
	)
}

export function renderCompetency(competency: CompetencyType, allCompetencies: Array<CompetencyType> | null, relatedItems: Array<any>, renderedCompetencies: Array<any>, container: any, setLocalAJAXCache: React.Dispatch<React.SetStateAction<any[]>>) {
	if (competency && renderedCompetencies.indexOf(competency) == -1) {
		renderedCompetencies.push(competency);
		//Render the competency div immediately so that competencies always render in the correct order
		var wrapper = document.createElement("div");
		wrapper.classList.add("competency");
		wrapper.id = competency.CTID;
		wrapper.dataset.ctid = competency.CTID;
		container.appendChild(wrapper);
		wrapper.innerHTML = renderToString(ActivityIndicator({ size: "sm" }));

		//Asynchronously render the contents of the competency div
		setTimeout(function () {
			//Main Content
			wrapper.innerHTML = renderToString(
				<>
					<CompetencyContent className="competencyContent">
						<CompetencyPrefix>
							{competency.CompetencyCategory && <CompetencyPrefixItem title="Competency Category">{competency.CompetencyCategory}</CompetencyPrefixItem>}
							{competency.ListID && <CompetencyPrefixItem title="List ID">{competency.ListID}</CompetencyPrefixItem>}
							{competency.CodedNotation && <CompetencyPrefixItem title="Coded Notation">{competency.CodedNotation}</CompetencyPrefixItem>}
							{competency.Meta_JSON["ceasn:altCodedNotation"] && <CompetencyPrefixItem title="Alt Coded Notation">{competency.Meta_JSON["ceasn:altCodedNotation"]}</CompetencyPrefixItem>}
						</CompetencyPrefix>
						<CompetencyBody>
							{competency.CompetencyLabel && <CompetencyLabel title="Competency Label">{competency.CompetencyLabel}</CompetencyLabel>}
							{competency.CompetencyText && <CompetencyText title="Competency Text">{competency.CompetencyText}</CompetencyText>}
							{competency.Comment?.length > 0 && <Comment title="Comment">{competency.Comment.map((m) => (<div>{m}</div>))}</Comment>}
							{competency.CTID?.length > 0 && <CompetencyCTID title="Competency CTID"><b>CTID:</b> <a href={appURL + "/competency/" + competency.CTID}>{competency.CTID}</a></CompetencyCTID>}
						</CompetencyBody>
					</CompetencyContent>
					<CompetencyExtras className="competencyExtras">
						<CompetencyAlignmentList source={competency} relatedItems={relatedItems} />
						<CompetencyTagList source={competency} relatedItems={relatedItems} />
						<CredentialAlignmentObjectSet source={competency} property="ceterms:industryType" label="Industry Type" />
						<CredentialAlignmentObjectSet source={competency} property="ceterms:occupationType" label="Occupation Type" />
						<CredentialAlignmentObjectSet source={competency} property="ceterms:instructionalProgramType" label="Instructional Program Type" />
						<CompetencySubstantiationList source={competency} />
					</CompetencyExtras>
				</>
			);

			//Interaction - wire this up first so it doesn't impact child competencies
			Array.from(wrapper?.querySelectorAll(".competencyExtraListWrapper") || []).forEach(function (item) {
				item.querySelector(".competencyExtraListSummaryButton")?.addEventListener("click", function () {
					item.classList.contains("expanded") ? item.classList.remove("expanded") : item.classList.add("expanded");
				});
			});

			//Lookups for referenced data
			var references = Array.from(wrapper?.querySelectorAll("[data-lookup") || []);
			references.forEach((item: any) => {
				if (!item.classList.contains("loaded")) {
					getAndRenderByURL(item.dataset.lookup, relatedItems, { "ceasn:competencyText": { "en": "Error loading competency: " + item.dataset.lookup } }, (competency: any) => {
						item.classList.add("loaded");
						item.innerHTML = englishOrNull(competency["ceasn:competencyText"]) || "Error: No competency text: " + item.dataset.lookup;
						var isPartOf = competency["ceasn:isPartOf"];
						var isMemberOf = competency["ceterms:isMemberOf"];
						if (isPartOf || isMemberOf) {
							var containerURI = isPartOf || isMemberOf || null;
							var containerURLPart = isPartOf ? "competencyframework" : isMemberOf ? "collection" : "resources";
							var itemCTIDTarget = isPartOf ? "competencyCTID=" : isMemberOf ? "memberCTID=" : "targetCTID=";
							var urlFirstPart = getFinderDomainFromRegistryURL(isPartOf) || appURL;

							item.href = urlFirstPart + "/" + containerURLPart + "/ce-" + containerURI.split("/ce-")[1] + "?" + itemCTIDTarget + "=" + competency["ceterms:ctid"];
							getAndRenderByURL(isPartOf, relatedItems, { "ceterms:name": { "en": "Error loading resource: " + isPartOf } }, (container: any) => {
								var containerNode = document.createElement("a");
								containerNode.innerHTML = englishOrNull(container["ceasn:name"]) || englishOrNull(container["ceterms:name"]) || "Unknown Resource";
								containerNode.href = urlFirstPart + "/" + containerURLPart + "/" + container["ceterms:ctid"];
								containerNode.target = "_blank";
								item.parentNode.insertBefore(containerNode, item);
								var separator = document.createTextNode(" | ");
								item.parentNode.insertBefore(separator, item);
							});
						}
					});
				}
			});

			//Children
			if (competency.HasChild?.length > 0 && (allCompetencies || []).length > 0) {
				var children = document.createElement("div");
				children.classList.add("competencyChildren");
				wrapper.appendChild(children);
				competency.HasChild.forEach((childURI) => {
					renderCompetency((allCompetencies || []).filter((m) => { return m.CredentialRegistryURL == childURI })[0], allCompetencies, relatedItems, renderedCompetencies, children, setLocalAJAXCache);
				});
			}

		}, 1);
	}
}

//Hack
function getFinderDomainFromRegistryURL(registryURL: string): string {
	var url = registryURL || "";
	return url.includes("sandbox.credentialengineregistry.org") ? "https://sandbox.credentialengine.org/finder" :
		url.includes("staging.credentialengineregistry.org") ? "https://staging.credentialengine.org/finder" :
			url.includes("localhost") ? "http://localhost:3000" :
				"https://credentialfinder.org";
}

function CompetencyAlignmentList(props: { source: CompetencyType, relatedItems: Array<any> }) {
	var competencyAlignments = [] as Array<any>;
	var alignmentTypes = [
		{ label: "Exact Alignment", property: "ceasn:exactAlignment" },
		{ label: "Prerequisite Alignment", property: "ceasn:prerequisiteAlignment" },
		{ label: "Broad Alignment", property: "ceasn:broadAlignment" },
		{ label: "Narrow Alignment", property: "ceasn:narrowAlignment" },
		{ label: "Major Alignment", property: "ceasn:majorAlignment" },
		{ label: "Minor Alignment", property: "ceasn:minorAlignment" },
		{ label: "Knowledge Embodied", property: "ceasn:knowledgeEmbodied" },
		{ label: "Skill Embodied", property: "ceasn:skillEmbodied" },
		{ label: "Ability Embodied", property: "ceasn:abilityEmbodied" },
		{ label: "Align To", property: "ceasn:alignTo" },
		{ label: "Align From", property: "ceasn:alignFrom" },
		{ label: "Cross Subject Reference", property: "ceasn:crossSubjectReference" },
		{ label: "Is Version Of", property: "ceasn:isVersionOf" },
	];

	alignmentTypes.forEach(type => {
		var value = props.source.Meta_JSON[type.property] || [];
		var list = Array.isArray(value) ? value : [value];
		list.forEach((alignment: string) => {
			var match = props.relatedItems.filter(item => {
				return item["@id"] == alignment
			})[0];

			if (!match && alignment.indexOf("credentialengineregistry.org/resources/") > -1) {
				competencyAlignments.push({
					lookup: alignment,
					alignmentPrefix: "",
					alignmentLabel: type.label,
					alignmentTerm: type.property,
					alignmentPostfix: ":",
					targetURL: alignment,
					targetLabel: <><ActivityIndicator size="sm" /> Loading...</>
				})
			}
			else {
				var framework = props.relatedItems.filter(item => { return match?.["ceasn:isPartOf"] == item["@id"] })[0];
				competencyAlignments.push(
					match ?
						{
							alignmentPrefix: "",
							alignmentLabel: type.label,
							alignmentTerm: type.property,
							alignmentPostfix: ":",
							targetURL: "/competencyframework/ce-" + match["ceasn:isPartOf"]?.split("/ce-")[1] + "?competencyCTID=" + match["ceterms:ctid"],
							targetLabel: englishOrNull(match["ceasn:competencyText"]),
							alignmentFrameworkURL: framework ? "/competencyframework/" + framework["ceterms:ctid"] : "",
							alignmentFrameworkName: englishOrNull(framework?.["ceasn:name"])
						}
						:
						{
							alignmentPrefix: "External ",
							alignmentLabel: type.label,
							alignmentTerm: type.property,
							alignmentPostfix: ":",
							targetURL: alignment,
							targetLabel: alignment
						}
				);
			}
		});
	});

	alignmentTypes.forEach(type => {
		props.relatedItems.forEach(relatedItem => {
			if (relatedItem[type.property]?.indexOf(props.source.Meta_JSON["@id"]) > -1) {
				var framework = props.relatedItems.filter(item => { return relatedItem["ceasn:isPartOf"] == item["@id"] })[0];
				competencyAlignments.push(
					{
						alignmentPrefix: "Reverse ",
						alignmentLabel: type.label,
						alignmentTerm: type.property,
						alignmentPostfix: " reference from:",
						targetURL: "/competencyframework/ce-" + relatedItem["ceasn:isPartOf"]?.split("/ce-")[1] + "?competencyCTID=" + relatedItem["ceterms:ctid"],
						targetLabel: englishOrNull(relatedItem["ceasn:competencyText"]),
						alignmentFrameworkURL: framework ? "/competencyframework/" + framework["ceterms:ctid"] : "",
						alignmentFrameworkName: englishOrNull(framework?.["ceasn:name"])
					}
				)
			}
		});
	});

	return competencyAlignments.length > 0 && (
		<CompetencyExtraListSimple
			count={competencyAlignments.length}
			label="Alignment"
			values={competencyAlignments.map((m, index) => (
				<AlignmentWrapper className="competencyExtraListItem alignmentWrapper" key={props.source.Meta_JSON["ceterms:ctid"] + "-" + m.alignmentTerm + "-" + index}>
					{m.alignmentPrefix}<a href={m.alignmentTerm.replace("ceasn:", "https://credreg.net/ctdlasn/terms/")} target="_blank">{m.alignmentLabel}</a>{m.alignmentPostfix} {m.alignmentFrameworkURL && <a className="frameworkLink" href={m.alignmentFrameworkURL} target="_blank">{m.alignmentFrameworkName}</a>}{m.alignmentFrameworkURL ? ": " : ""}<a href={m.targetURL} target="_blank" data-lookup={m.lookup}>{m.targetLabel}</a>
				</AlignmentWrapper>
			))}
		/>
	) || null
}

function CompetencyTagList(props: { source: CompetencyType, relatedItems: Array<any> }) {
	var data = props.source.Meta_JSON;
	var tagTypes = [
		{ label: "Identifier URL", value: data["ceasn:identifier"]?.length > 0 && <LinkList items={data["ceasn:identifier"]} />, count: data["ceasn:identifier"]?.length },
		{ label: "Keyword", value: data["ceasn:conceptKeyword"] && <LinkList items={englishOrNull(data["ceasn:conceptKeyword"])} />, count: englishOrNull(data["ceasn:conceptKeyword"])?.length },
		{ label: "Local Subject", value: data["ceasn:localSubject"] && <LinkList items={englishOrNull(data["ceasn:localSubject"])} />, count: englishOrNull(data["ceasn:localSubject"])?.length },
		{ label: "Concept", value: data["ceasn:conceptTerm"] && <ConceptListViaLookup uris={data["ceasn:conceptTerm"]} relatedItems={props.relatedItems} />, count: data["ceasn:conceptTerm"]?.length },
		{ label: "Complexity Level", value: data["ceasn:complexityLevel"] && <ConceptListViaLookup uris={data["ceasn:complexityLevel"]} relatedItems={props.relatedItems} />, count: data["ceasn:complexityLevel"]?.length },
		{ label: "Education Level Type", value: data["ceasn:educationLevelType"] && <ConceptListViaLookup uris={data["ceasn:educationLevelType"]} relatedItems={props.relatedItems} />, count: data["ceasn:educationLevelType"]?.length },
		{ label: "Environmental Hazard Type", value: data["ceterms:environmentalHazardType"] && <ConceptListViaLookup uris={data["ceasn:environmentalHazardType"]} relatedItems={props.relatedItems} />, count: data["ceasn:environmentalHazardType"]?.length },
		{ label: "Performance Level Type", value: data["ceterms:performanceLevelType"] && <ConceptListViaLookup uris={data["ceasn:performanceLevelType"]} relatedItems={props.relatedItems} />, count: data["ceasn:performanceLevelType"]?.length },
		{ label: "Physical Capability Type", value: data["ceterms:physicalCapabilityType"] && <ConceptListViaLookup uris={data["ceasn:physicalCapabilityType"]} relatedItems={props.relatedItems} />, count: data["ceasn:physicalCapabilityType"]?.length },
		{ label: "Sensory Capability Type", value: data["ceterms:sensoryCapabilityType"] && <ConceptListViaLookup uris={data["ceasn:sensoryCapabilityType"]} relatedItems={props.relatedItems} />, count: data["ceasn:sensoryCapabilityType"]?.length }
	].filter(m => m.value);
	/*
	return tagTypes.length > 0 && (
		<CompetencyExtraListSimple
			count={tagTypes.length}
			label="Additional Connection"
			values={tagTypes.map((m, index) => (
				<TagSetWrapper className="competencyExtraListItem tagSetWrapper" key={props.source.Meta_JSON["ceterms:ctid"] + "-" + index }>
					<TagSetLabel>{m.label}:</TagSetLabel>
					<TagSetValues>{m.value}</TagSetValues>
				</TagSetWrapper>
			))}
		/>
	) || null
	*/
	return tagTypes.length > 0 && (
		<>
			{tagTypes.map((m, index) => (
				<CompetencyExtraListSimple
					count={m.count}
					label={m.label}
					values={(Array.isArray(m.value) ? m.value : [m.value]).map((n: any, index: number) => (
						<TagSetWrapper className="competencyExtraListItem tagSetWrapper" key={props.source.Meta_JSON["ceterms:ctid"] + "-" + index}>
							<TagSetValues>{n}</TagSetValues>
						</TagSetWrapper>
					))}
				/>
			))}
		</>
	) || null
}

function CredentialAlignmentObjectSet(props: { source: CompetencyType, property: string, label: string }) {
	var alignmentObjects = (props.source.Meta_JSON[props.property] || []) as Array<any>;
	return alignmentObjects.length > 0 && (
		<CompetencyExtraListSimple
			count={alignmentObjects.length}
			label={props.label}
			values={alignmentObjects.map((m, index) => (
				<AlignmentObjectWrapper className="competencyExtraListItem alignmentObjectWrapper" key={props.source.Meta_JSON["ceterms:ctid"] + "-" + props.property + "-" + index}>
					{(m["ceterms:codedNotation"] || m["ceterms:targetNodeName"] || m["ceterms:targetNode"]) && (
						<AlignmentObjectHeader>
							{m["ceterms:codedNotation"] && <div>{m["ceterms:codedNotation"]}</div>}
							{m["ceterms:targetNodeName"] && <div>{englishOrNull(m["ceterms:targetNodeName"])}</div>}
							{m["ceterms:targetNode"] && <a href={m["ceterms:targetNode"]} target="_blank">{m["ceterms:targetNode"]}</a>}
						</AlignmentObjectHeader>
					)}
					{m["ceterms:targetNodeDescription"] && (
						<AlignmentObjectBody>{englishOrNull(m["ceterms:targetNodeDescription"])}</AlignmentObjectBody>
					)}
				</AlignmentObjectWrapper>
			))}
		/>
	) || null
}

function CompetencySubstantiationList(props: { source: CompetencyType }) {
	var substantiationTypes = [
		{ label: "Inferred Competency", property: "ceasn:inferredCompetency", useFinderURL: false },
		{ label: "Source Documentation", property: "ceasn:sourceDocumentation", useFinderURL: false },
		{ label: "Substantiating Competency Framework", property: "ceasn:substantiatingCompetencyFramework", useFinderURL: true },
		{ label: "Substantiating Credential", property: "ceasn:substantiatingCredential", useFinderURL: true },
		{ label: "Substantiating Job", property: "ceasn:substantiatingJob", useFinderURL: false },
		{ label: "Substantiating Occupation", property: "ceasn:substantiatingOccupation", useFinderURL: false },
		{ label: "Substantiating Resource", property: "ceasn:substantiatingResource", useFinderURL: false },
		{ label: "Substantiating Task", property: "ceasn:substantiatingTask", useFinderURL: false },
		{ label: "Substantiating Work Role", property: "ceasn:substantiatingWorkRole", useFinderURL: false }
	].filter(m => (props.source.Meta_JSON[m.property] as any) != undefined);

	return substantiationTypes.length > 0 && (
		<CompetencyExtraListSimple
			count={substantiationTypes.length}
			label="Substantiating Link"
			values={substantiationTypes.map((m, index) => (
				<TagSetWrapper className="competencyExtraListItem tagSetWrapper" key={props.source.Meta_JSON["ceterms:ctid"] + "-" + index}>
					<TagSetLabel>{m.label}</TagSetLabel>
					<TagSetValues>
						{(Array.isArray(props.source.Meta_JSON[m.property]) ? props.source.Meta_JSON[m.property] : [props.source.Meta_JSON[m.property]]).map((value: string) => (
							<a href={m.useFinderURL ? value?.replace(registryURL, appURL) : value} target="_blank">{m.useFinderURL ? value?.replace(registryURL, appURL) : value}</a>
						))}
					</TagSetValues>
				</TagSetWrapper>
			))}
		/>
	) || null
}

function LinkList(props: { items: Array<string> }) {
	return props.items?.length > 0 && (
		<>
			{props.items.map((item, index) => (
				<a href={item.indexOf("http") == 0 ? item : "/search?searchtype=competencyframework&keywords=" + encodeURIComponent(item)} target="_blank">{item}</a>
			))}
		</>
	) || null
}

function ConceptListViaLookup(props: { uris: Array<string>, relatedItems: Array<any> }) {
	var uris = props.uris || [] as Array<string>;
	var root = (crypto as any).randomUUID();

	uris.forEach(uri => {
		getResourceByURLWithoutState(uri, null, (data: any) => {
			var concept = data?.["@graph"]?.find((m: any) => m["@type"] == "skos:Concept") || data;
			var schemeURI = Array.isArray(concept["skos:inScheme"]) ? concept["skos:inScheme"][0] : concept["skos:inScheme"] || "";
			var renderURL = uri.includes("credentialengineregistry.org") ? (appURL + "/conceptscheme/ce-" + schemeURI.split("/ce-")[1] + "?targetCTID=" + concept["ceterms:ctid"]) : uri;
			var renderLabel = englishOrNull(concept["skos:prefLabel"]) || renderURL;
			var renderDefinition = englishOrNull(concept["skos:definition"]);
			/*
			console.log("Data", {
				Concept: concept,
				UUID: root,
				URL: uri,
				RenderURL: renderURL,
				RenderLabel: renderLabel,
				RenderDefinition: renderDefinition
			});
			*/
			renderContent(root, uri, 50, (div: any) => {
				div.innerHTML = renderToString(<><a href={renderURL} title="Label"><ConceptPrefLabel>{renderLabel}</ConceptPrefLabel></a>{renderDefinition && ": "}{renderDefinition && <ConceptDefinition>{renderDefinition}</ConceptDefinition>}</>);
			});
		}, (error: any) => {
			renderContent(root, uri, 50, (div: any) => {
				div.innerHTML = "Error looking up Concept: " + uri;
			});
		})
	});

	function renderContent(unique: string, uri: string, attempts: number, renderer: any) {
		if (attempts > 0) {
			var div = document.querySelector("[data-unique='" + unique + "'][data-uri='" + uri + "']");
			//console.log("Selector", "[data-unique='" + unique + "'][data-uri='" + uri + "']");
			//console.log("Div", div);
			if (div) {
				renderer(div);
			}
			else {
				setTimeout(() => {
					renderContent(unique, uri, attempts - 1, renderer);
				}, 10);
			}
		}
	}

	return uris.length > 0 && (
		<>
			{uris.map(uri => (
				<div className="lookupConcept" data-unique={root} data-uri={uri}><ActivityIndicator size="sm" /></div>
			))}
		</>
	) || null
}

function CompetencyExtraListSimple(props: { values: Array<ReactNode>, count: number, label: string }) {
	return props.values?.length > 0 && (
		<CompetencyExtraListWrapper className="competencyExtraListWrapper">
			{props.count > 1 && <CompetencyExtraListSummaryButton className="competencyExtraListSummaryButton">{props.count} {props.label + "s"} <FontAwesomeIcon className="expandedIcon" icon={faAngleDown} /><FontAwesomeIcon className="collapsedIcon" icon={faAngleRight} /></CompetencyExtraListSummaryButton>}
			{props.count == 1 && <CompetencyExtraListSummaryWrapper className="competencyExtraListSummaryWrapper">{props.count} {props.label}</CompetencyExtraListSummaryWrapper>}
			<CompetencyExtraListValues className="competencyExtraListValues">
				{props.values}
			</CompetencyExtraListValues>
		</CompetencyExtraListWrapper>
	) || null
}

const CompetencyListSummary = styled.div`
	padding: 5px;
	font-weight: bold;
`;

const CompetencyPrefix = styled.div`
	display: flex;

	&:empty {
		display: none;
	}
`;

const CompetencyPrefixItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 12px;
	min-width: 50px;
	max-width: 150px;
	word-wrap: break-word;
	margin-right: 5px;
	background-color: #F5F5F5;
	padding: 5px;
	text-align: center;
`;

const CompetencyContent = styled.div`
	display: flex;
	padding: 5px 0;
`;

const CompetencyBody = styled.div`
	margin-left: 5px;
`;

const CompetencyLabel = styled.div`
	font-weight: bold;
`;

const CompetencyText = styled.div`

`;

const Comment = styled.div`
	font-style: italic;
	padding: 0 10px;
	font-size: 90%;
`;

const CompetencyCTID = styled.div`
	padding: 5px 10px;
	font-size: 12px;
	font-style: italic;
`;

const CompetencyExtras = styled.div`
	padding: 5px 0 5px 10px;
`;

const CompetencyExtraListWrapper = styled.div`
	display: flex;
	align-items: baseline;
	font-size: 12px;
	border-top: 1px dashed #CCC;
	padding: 5px 5px;

`;

const CompetencyExtraListSummaryWrapper = styled.div`
	min-width: 150px;
	margin-right: 10px;
	padding: 1px 5px;
`;

const CompetencyExtraListSummaryButton = styled.button`
	display: flex;
	align-items: center;
	min-width: 150px;
	margin-right: 10px;
	border: none;
	background-color: transparent;
	font-size: 12px;
	padding: 1px 5px;

	& svg {
		margin-left: auto;
	}

	&:hover, &:focus {
		cursor: pointer;
		text-decoration: underline;
	}
`;

const CompetencyExtraListValues = styled.div`
	width: 100%;
`;

const CompetencyExtraListItem = styled.div`
	padding: 2.5px 0;
	border-top: 1px dashed #CCC;

	&:first-child {
		border: none;
	}

	& a {
		word-break: break-word;
	}
`;

const AlignmentWrapper = styled(CompetencyExtraListItem)`

`;

const AlignmentObjectWrapper = styled(CompetencyExtraListItem)`

`;

const AlignmentObjectHeader = styled.div`
	display: flex;
	flex-wrap: wrap;

	& > * {
		margin-right: 10px;
	}

	& a {
		margin-left: auto;
		margin-right: 0;
	}
`;

const AlignmentObjectBody = styled.div`

`;

const TagSetWrapper = styled(CompetencyExtraListItem)`
	display: flex;
`;

const TagSetLabel = styled.div`
	margin-right: 10px;
`;

const TagSetValues = styled.div`
	> * {
		margin: 1px 5px;
	}
`;

const ConceptPrefLabel = styled.span`

`;

const ConceptDefinition = styled.span`

`;

const Rights = styled.div`
	padding: 10px;
`;
