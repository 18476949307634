import styled from 'styled-components/macro';
import { CleanList, ContentDelimiter } from '../common';

export const Title = styled.h5`
    font-size: ${(props) => props.theme.fontSize.md};
    margin: 0 0 0.5em 0;
`;

export const FooterTop = styled(ContentDelimiter)`
    margin: 0 auto 2rem;

    .site-name {
        font-size: ${(props) => props.theme.fontSize.lg};
        font-weight: 700;
    }
`;

export const FooterMiddle = styled(ContentDelimiter)`
    margin: 0 auto 2rem;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: ${(props) => props.theme.breakpoint.desktop}) {
        grid-template-columns: repeat(6, 1fr);
    }
`;

export const FooterBottom = styled(ContentDelimiter)`
    margin: 3rem auto 0;
`;

export const FooterMain = styled.footer`
    border-top: 1px solid ${(props) => props.theme.color.aquaDark};
    padding: 4rem 0 2rem;

	@media print {
		display: none;
	}
`;

export const FooterMenu = styled(CleanList)`
    margin: 0 0 1.5rem;

    li {
        margin-bottom: 0.5em;
    }

    a {
        color: ${(props) => props.theme.color.blueDark};
        text-decoration: none;
    }
`;

export const LogoListWrapper = styled.div`
    h5 {
        font-size: ${(props) => props.theme.fontSize.md};
        font-weight: normal;
        margin: 0 0 1.5em 0;
    }
`;

export const LogoList = styled(CleanList)`
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, auto);

    @media (min-width: ${(props) => props.theme.breakpoint.tabletPortrait}) {
        grid-template-columns: repeat(4, auto);
    }

    @media (min-width: ${(props) => props.theme.breakpoint.desktop}) {
        grid-template-columns: repeat(7, auto);
    }

    li {
        margin-bottom: 1rem;
    }

    a {
        color: ${(props) => props.theme.color.blueDark};
        text-decoration: none;
    }

    img {
        width: 100%;
    }
`;

export const PoweredBy = styled.img`
	max-width:150px;
`;