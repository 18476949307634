import React from 'react';
import styled from 'styled-components/macro';
import { CostManifest as CostManifestType } from '../../types/external';
import { Box, BoxLabel, BoxContent, Section, SectionLabel, SectionContent, SectionContentItem } from './Shared';
import DateRange from './DateRange';
import { TabSetOrSingle } from './TabSelector';
import { CostProfile } from './CostProfile';
import LinkObject from './LinkObject';

export default function CostManifestList(props: { items: CostManifestType[] }) {
	return ( props.items?.length > 0 &&
		<div>
		{props.items.map((item) => (
			<CostManifest item={item} />
		))}
		</div>
	) || null
}

export function CostManifest(props: { item: CostManifestType }) {
	return ( props.item &&
		<Box>
		<BoxLabel>{props.item.Name || "Common Costs"}</BoxLabel>
		<BoxContent>
			<Section>
				<BasicInfo>
					<div>
						{props.item.Description && <div>{props.item.Description}</div>}
						<DateRange FromDate={props.item.StartDate} ToDate={props.item.EndDate} />
					</div>
					{props.item.CostDetails && <LinkObject item={{ URL: props.item.CostDetails, Label: "More Information" }} />}
				</BasicInfo>
			</Section>
			{props.item.EstimatedCost?.length > 0 &&
				<Section>
					<SectionLabel>Applicable Cost Information</SectionLabel>
					<SectionContent>
						<TabSetOrSingle
							activeIndex={0}
							items={props.item.EstimatedCost.map((item, itemIndex) => {
								return {
									Label: item.Name || "Cost Data " + (itemIndex + 1),
									Content: <CostProfile item={item} />
								}
							})}
						/>
					</SectionContent>
				</Section>
			}
		</BoxContent>
		</Box>
	) || null
}

const BasicInfo = styled.div`
	display: flex;

	& > div {
		flex: 1 1 auto;
	}

	& > a {
		margin-left: 10px;
	}
`;