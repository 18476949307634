import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RouteComponentProps } from 'react-router-dom';
import InnerHTML from 'dangerously-set-html-content';
import ActivityIndicator from '../../components/ActivityIndicator';
import styled from 'styled-components/macro';
import Error from '../../components/Error';
import { legacySourceUrl } from '../../shared/globals';

interface Props {
    id: string;
}

export default function ReportsPage(props: RouteComponentProps<Props>) {
    const id = props.match.params.id;
    const [loading, setLoading] = useState(false);
    const [pageContent, setPageContent] = useState(' ');
    const [error, setError] = useState(false);

    useEffect(() => {
        setLoading(true);
        const url = `${legacySourceUrl}/reports/CredentialRegistryData?context=newFinder&coreonly=true`;
        axios
            .get(url)
            .then((res) => {
                setPageContent(res.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setError(true);
                setLoading(false);
            });
    }, []);
    if (loading) {
        return (
            <LoadingIndicatorWrapper>
                <ActivityIndicator />
            </LoadingIndicatorWrapper>
        );
    }
    if (error) {
        return <Error />;
    }
	return <>
		<InnerHTML html={pageContent} />
		<style type="text/css">
			{"h1 { color: #4EE5E1; background-color: #0A2942; padding: 20px 10px; }"}
		</style>
		</>;
}

const LoadingIndicatorWrapper = styled.div`
    text-align: center;
    padding: 50px;
`;
