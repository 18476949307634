import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import SearchBar from '../../components/SearchBar';
import SearchResultItem from '../../components/SearchResultItem';
import GoogleMap from 'google-map-react';
import {
    FilterButton,
    ResultItem,
    ResultList,
    ResultBodytHeader,
    ResultListHeaderSection,
    TopRegion,
    IconButton,
    ButtonIcon,
    Wrapper,
    DropdownSection,
    GroupButton,
    FilterPills,
    PillContent,
    DropdownGroup,
    ResultCount,
    ResultsSection,
    LoadingIndicatorWrapper,
    ResultListItems,
    MapWrapper,
    ResultsSectionBody,
	ResultBodyContent,
	ExportButton
} from './styles';
import {
    MainFilterItemInterface,
    MainSearchType,
} from '../../types/internal';
import {
    faAngleRight,
    faAngleDown,
    faLocationArrow,
    faChevronRight,
    faList,
    faMapMarkerAlt,
    faFilter,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { faListAlt, faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pill, { UnremovablePill } from '../../components/Pill';
import { HStack, VStack } from '../../components/Stack';
import FilterPanel from '../../components/FilterPanel';
import { FilterContext, QueryContext } from '../../App';
import {
    FilterField,
    getMapInfoWindowFromSearchResultsLocations,
    getResultsLocationField,
} from '../../utils';
import Dropdown from '../../components/Dropdown';
import {
    defaultMapSetting,
    defaultQuery,
    resultDisplayOptions,
    sortOptions,
} from '../../shared/data';
import { constructSearchQuery } from '../../services/api';
import { useMediaQuery } from '../../hooks';
import { theme } from '../../shared/theme';
import axios from 'axios';
import { apiUrl, appURL, registryURL, googleMapsKey, appBaseName } from '../../shared/globals';
import ActivityIndicator from '../../components/ActivityIndicator';
import EmptyResult from './EmptyResult';
import { Organization, MainQueryResponse, Place, MainQueryType, Filter, FilterItem } from '../../types/external';
import { setupMap } from '../../services/map';
//import { convertFilterItemToIFilterItem } from '../../types/converters';
import SearchResultPillSection from '../../components/SearchResultPillSection';
import BackToTopButton from '../../components/BackToTopButton';
import { widgetPruneFiltersAndOptions, widgetApplyWidgetToQuery } from '../../utils/SiteWidgetizer';
import { getUUID } from '../../components/MiniSearch';
import { getCurrentQuery, updateCurrentQuery, registerCurrentQueryListener, delayThenDo, toggleFilterItem } from '../../utils/SearchHelper';
import { getExportCSVFromItems } from "../../utils/JSONtoCSV";

const emptySearchResult: MainQueryResponse = {
    TotalResults: 0,
    Results: [],
};
export default function SearchPage() {
	const filterContext = useContext(FilterContext);
    const queryContext = useContext(QueryContext);
    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });

    const [panelIsOpen, setPanelIsOpen] = useState(false);
    const [viewMode, setViewMode] = useState<'list' | 'map'>('list');
    const [searchResult, setSearchResult] = useState<MainQueryResponse>(
        emptySearchResult,
    );
    const [initialLoading, setInitialLoading] = useState(true);
    const [scrollLoading, setScrollLoading] = useState(false);
    const [searchAble, setSearchAble] = useState(true);
    const resultRef = useRef<HTMLDivElement | null>(null);
    const googleMap = useRef<google.maps.Map>();
    const googleMaps = useRef<typeof google.maps>();
    const mapMarkers = useRef<any>();
    const [displayMode, setDisplayMode] = useState<
        'showAll' | 'showDescription' | 'showButton'
		>('showAll');
	const [inExportMode, setInExportMode] = useState(false);
	const [isExportRunning, setIsExportRunning] = useState(false);

    const availableFilters = useMemo(() => {
        return filterContext[queryContext.query.SearchType] || [];
    }, [queryContext.query.SearchType, filterContext]);


	//New search processing
	//console.log("filtercontext", filterContext);
	var parametersFromURL = {} as any;
	new URLSearchParams(window.location.search).forEach((value, key) => {
		var name = key.toLowerCase();
		parametersFromURL[name] = parametersFromURL[name] ? [...parametersFromURL[name], value] : [value];
	});
	useEffect(() => {
		//Set to help track things later
		(window as any).IsOriginalSearchType = true;

		//Force addition of keywords from URL parameters if applicable, but then enable it to change after 3 seconds
		if (parametersFromURL.keywords) {
			(document.querySelector("[data-fieldname='MainKeywords']") as any).value = parametersFromURL.keywords;
			(window as any).StarterKeywords = Array.isArray(parametersFromURL.keywords) ? parametersFromURL.keywords[0] : parametersFromURL.keywords;
			setTimeout(function () {
				(window as any).StarterKeywords = null;
			}, 3000);
		}
	}, []);
	registerCurrentQueryListener("DoMainSearch", function () { delayThenDo("DoMainSearch", 250, doSearch); });
	function doSearch() {
		//Get query
		var query = getCurrentQuery();
		
		//Get filters
		query.MainFilters = query.MainFilters || [];

		//Add keywords if applicable
		query.Keywords = query.Keywords || (window as any).StarterKeywords;

		//Add SortOrder
		query.SortOrder = query.SortOrder || "sortOrder:MostRelevant";
		
		//Add PageSize and SkipPages
		query.PageSize = query.PageSize || 20;
		query.SkipPages = query.SkipPages || 0;

		//Append filters from URL unless the search type has been changed
		if ((window as any).IsOriginalSearchType) {
			//Inject Organization roles (unless it was already removed)
			if (!(window as any).RemovedOrganizationRoleFilter && parametersFromURL.filterparameters?.length > 0 && !query.MainFilters.find((m: any) => m.Parameters)) {
				query.MainFilters.push({
					Label: "Organization Role",
					Items: [{
						Text: (Array.isArray(parametersFromURL.filteritemtext) ? parametersFromURL.filteritemtext[0] : parametersFromURL.filteritemtext) || "Organization Role Filter",
						onRemove: function () {
							(window as any).RemovedOrganizationRoleFilter = true;
							updateCurrentQuery();
						}
					}],
					Parameters: JSON.parse(parametersFromURL.filterparameters)
				});
			}
			//Inject Preselected filters (e.g. industry, ____Type, etc) (unless it was already removed)
			else if (!(window as any).RemovedPreselectedFilter && parametersFromURL.filterid && (parametersFromURL.filteritemid || parametersFromURL.filteritemtext)) {
				var filterID = Array.isArray(parametersFromURL.filterid) ? parametersFromURL.filterid[0] : parametersFromURL.filterid;
				var filterItemID = Array.isArray(parametersFromURL.filteritemid) ? parametersFromURL.filteritemid[0] : parametersFromURL.filteritemid;
				var filterData = (window as any).SearchFilters?.[query.SearchType?.toLowerCase()]?.find((m: any) => m.Id == filterID);
				var filterLabel = filterData?.Label || "Filter";
				var filterItemLabel = filterData?.Items?.find((m: any) => m.Id == filterItemID)?.Label || "Preselected Item";
				query.MainFilters = query.MainFilters.filter((m: any) => !m.IsPreselectedFilter);
				query.MainFilters.push({
					Label: filterLabel,
					Items: [{
						Id: filterItemID,
						Text: (Array.isArray(parametersFromURL.filteritemtext) ? parametersFromURL.filteritemtext[0] : parametersFromURL.filteritemtext) || filterItemLabel,
						onRemove: function () {
							(window as any).RemovedPreselectedFilter = true;
							query.MainFilters = query.MainFilters.filter((m: any) => !m.IsPreselectedFilter);
							updateCurrentQuery();
						}
					}],
					Id: filterID,
					IsPreselectedFilter: true
				});
			}
		}

		//Append widget filters if applicable
		widgetApplyWidgetToQuery(query, query.SearchType);



		//TODO: Clean up unused code from the old filter handling
		//TODO: Test everything during/after cleanup



		//Re-render the filter pills
		var pillBox = (document.querySelector(".filterPillsSection") as any);
		if (pillBox) {
			pillBox.innerHTML = "";
			query.MainFilters.forEach((Filter: any) => {
				Filter.Items?.forEach((Item: any) => {
					var button = document.createElement("button");
					button.classList.add("pillButton");
					if (Item.onRemove) {
						button.disabled = false;
						button.innerHTML = "<b>" + Filter.Label + ":</b> <span>" + Item.Text + "</span><span class=\"remove\">X</span>";
						button.onclick = function () {
							Item.onRemove();
							var box1 = (document.querySelector("[data-filterid='" + Filter.Id + "'] [data-filteritemid='" + Item.Id + "']") as any);
							box1 && (box1.checked = false);
							var box2 = (document.querySelector("[data-filterid='" + Filter.Id + "'] [data-filteritemuri='" + Item.URI + "']") as any);
							box2 && (box2.checked = false);
							//var text = (document.querySelector("[data-filterid='" + Filter.Id + "'] [data-filteritemtext='" + Item.Text + "']") as any);
							//text && (text.parentNode.removeChild(text));
						}
					}
					else {
						button.disabled = true;
						button.innerHTML = "<b>" + Filter.Label + (Filter.Label[Filter.Label.length - 1] == ":" ? "" : ":") + "</b> <span>" + Item.Text + "</span>";
					}

					pillBox.appendChild(button);
				});
			});
		}

		//Only run the query if it has actually changed, or if query.Force is true (i.e. if the user clicked in the search bar and hit enter, or clicked the search button)
		var stringified = JSON.stringify(query);
		if (query.Force || stringified != (window as any).PreviousMainSearchStringified) {
			query.Force = false;
			(window as any).PreviousMainSearchStringified = stringified;
			console.log("Doing main search...", query);

			queryContext.updateQuery({ ...query });
		}
		else {
			console.log("Skipping main search");
		}
	}

	function onSearchButtonClick() {
		queryContext.updateQuery({
			...queryContext.query,
			SkipPages: 0,
		});
	}

	function onUpdateFilter(filter: Filter, filterItem: FilterItem) {
		toggleFilterItem(filter.Id, filter.URI, filter.Label, filterItem.Id, filterItem.Text);
    }

    function getDefaultSortOption() {
        const index = sortOptions.findIndex(
            (option) => option.key == queryContext.query.SortOrder,
        );
        if (index == -1) {
            return 0;
        }
        return index;
    }
	
    const resultsLocations = useMemo(() => {
        let locations: Place[] = [];
        const locationField = getResultsLocationField(
            queryContext.query.SearchType,
		);
		var mapFilter = queryContext.query?.MapFilter as any || null;
		var latitude25Unit = 0.362; //One degree of latitude is 69 miles, so this represents 25 miles of latitude
		var longitude25Unit = 0.463; //One degree of longitude is a little over 54 miles, so this represents 25 miles of longitude
        searchResult.Results.map((result) => {
            const resultLocations = result[locationField];
            if (resultLocations) {
                if (Array.isArray(resultLocations)) {
					resultLocations.forEach((place, index) => {
						var isValidLocation = true;
						if (mapFilter && mapFilter.BBoxCenterLatitude && mapFilter.BBoxCenterLongitude) {
							mapFilter.PositionType = mapFilter.PositionType || "positionType:In";
							var isInPlace = place.AddressRegion?.toLowerCase() == mapFilter.Region?.toLowerCase() && (mapFilter.Label == mapFilter.Region ? true : place.AddressLocality?.toLowerCase() == mapFilter.Label?.toLowerCase());
							var latitudeDistance = Math.abs(place.Latitude - mapFilter.BBoxCenterLatitude);
							var longitudeDistance = Math.abs(place.Longitude - mapFilter.BBoxCenterLongitude);
							var latitudeDistanceThreshold = ({ "positionType:Near_25": latitude25Unit * 1, "positionType:Near_50": latitude25Unit * 2, "positionType:Near_100": latitude25Unit * 3 } as any)[mapFilter.PositionType] || 0;
							var longitudeDistanceThreshold = ({ "positionType:Near_25": longitude25Unit * 1, "positionType:Near_50": longitude25Unit * 2, "positionType:Near_100": longitude25Unit * 3 } as any)[mapFilter.PositionType] || 0;
							isValidLocation = mapFilter.PositionType == "positionType:In" && isInPlace ? true : (latitudeDistanceThreshold > latitudeDistance && longitudeDistanceThreshold > longitudeDistance);
							console.log("Place", { place: place, isInPlace: isInPlace, latDistance: latitudeDistance, lonDistance: longitudeDistance });
						}
						if (isValidLocation) {
							locations.push({
								...place,
								ResourceName: result.Name || "Unknown Resource",
								ResourceProviderName: result.OwnedByLabel?.Label || "",
								MarkerTitle: (result.Name || "Unknown Resource") + ", Location " + (index + 1),
								identifier: result.Meta_Id,
								//label: getMapInfoWindowFromSearchResultsLocations(
								//	queryContext.query.SearchType,
								//	result,
								//),
							});
						}
                    });
                }
            }
        });
        // clear all current markers
        if (mapMarkers.current) {
            mapMarkers.current.forEach((marker: google.maps.Marker) => {
                marker.setMap(null);
            });
            mapMarkers.current = [];
        }
        // fit bounds
        if (googleMap.current && googleMaps.current) {
            mapMarkers.current = setupMap(
                googleMap.current,
                googleMaps.current,
                locations,
                defaultMapSetting.zoom,
            );
        }

        return locations;
    }, [searchResult.Results]);

	//Use brute force to prevent the results div jumping to the top
	//Really awful hack but React sucks that much
	useEffect(() => {
		(document.querySelector("#mainResultContainer") as any).style.minHeight = document.querySelector("#mainResultContainer")?.clientHeight + "px";
	}, [searchResult.Results]);
	useEffect(() => {
		if (queryContext.query.SkipPages == 0) {
			(document.querySelector("#mainResultContainer") as any).style.minHeight = "0px";
		}
	}, [queryContext.query]);
	//

    useEffect(() => {
        function loadMore() {
            if (resultRef.current) {
                if (
                    window.innerHeight + document.documentElement.scrollTop >=
                    resultRef.current.scrollHeight + 1
                ) {
                    if (!initialLoading && !scrollLoading && searchAble) {
						console.log("setScrollLoading", "true");
						setScrollLoading(true);

						updateCurrentQuery((query: any) => {
							query.PageSize = 20;
							query.SkipPages = query.SkipPages == undefined ? 0 : query.SkipPages + 1;
						});
						/* */

                        queryContext.updateQuery({
                            ...queryContext.query,
                            SkipPages: (queryContext.query.SkipPages || 0) + 1,
                        });
                    }
                }
            }
        }

        window.addEventListener('scroll', loadMore);

        return () => {
            window.removeEventListener('scroll', loadMore);
        };
    }, [
        resultRef,
        scrollLoading,
        //queryContext.query,
        initialLoading,
        searchAble,
    ]);

	useEffect(() => {
		function generateQueryFromURL() {
			//Get from URL
			var parameters = {} as any;
			new URLSearchParams(window.location.search).forEach((value, key) => {
				var name = key.toLowerCase();
				parameters[name] = parameters[name] ? [...parameters[name], value] : [value];
			});

			//Convert
			var filterId = parameters.filterid?.[0] || "-1";
			var filterItemId = parameters.filteritemid?.[0] || "";
			var filterItemText = parameters.filteritemtext?.[0] || "";
			var keywords = parameters.keywords?.[0] || "";
			var filterParameters = parameters.filterparameters?.[0];
			var searchType = ((parameters.searchtype?.[0] || "").toLowerCase() as MainSearchType) || 'credential';
			console.log("Keywords from URL:", keywords);

			//If valid...
			if (!(filterId == '-1' && filterItemId == '' && filterItemText == '' && !filterParameters)) {
				//Create the filter
				var newFilter = {
					Id: filterId,
					Items: [{
						Id: filterItemId,
						Text: filterItemText
					}] as Array<FilterItem>,
				} as Filter;

				//Inject parameters if present
				if (filterParameters) {
					try {
						newFilter.Parameters = JSON.parse(filterParameters);
					}
					catch (error) { }
				}

				//Lookup the filter from the filterContext to get its URI
				var filterFromContext = (filterContext[searchType] || []).find(filter => filter.Id == filterId);
				if (filterFromContext) {
					newFilter.URI = filterFromContext.URI;
				}			
				//Update the query
				queryContext.updateQuery({
					...queryContext.query,
					Keywords: keywords,
					MainFilters: [...queryContext.query.MainFilters, newFilter],
					SearchType: searchType,
				});
			}
			else if (keywords) {
				//Update the query
				queryContext.updateQuery({
					...queryContext.query,
					Keywords: keywords,
					SearchType: searchType,
				});
			}
		}

        // dirty check
        if (filterContext['credential'].length > 0) {
            generateQueryFromURL();
        }
    }, [filterContext]);

	//Inject widget-based filters
	useEffect(() => {
		if ((window as any).Widget?.ID) {
			widgetApplyWidgetToQuery(queryContext.query, queryContext.query.SearchType);
			queryContext.updateQuery({
				...queryContext.query
			});
		}
	}, [filterContext, queryContext.query.SearchType]);

    useEffect(() => {
        let isCurrentFetch = true;
		console.log("Current Fetch = true");
        async function getSearchResults() {
            const query = constructSearchQuery(
                queryContext.query,
                filterContext[queryContext.query.SearchType],
			);
			widgetApplyWidgetToQuery(queryContext.query, queryContext.query.SearchType);

			//Skip the first double query but let the second run so that event handlers for resource rendering still trigger
			if ((query.SkipPages || 0) > 0 && (query.SkipPages || 0) != (window as any).OldSkipPages) {
				console.log("Skipping first double query", query);
				(window as any).OldSkipPages = query.SkipPages || 0;
				return;
			}

			/*
			//Skip double queries - works, but causes results to stop rendering after 3 pages for some reason
			var textQuery = JSON.stringify(query);
			console.log("Current query", textQuery);
			console.log("Old query", (window as any).OldQuery);
			if ((window as any).OldQuery == textQuery) {
				console.log("Skipping query because it is the same as before");
				return;
			}
			else {
				console.log("Updating old query");
				(window as any).OldQuery = textQuery;
			}
			*/

            if (!scrollLoading) {
                setInitialLoading(true);
			}

			const isFirstPage = queryContext.query.SkipPages == 0;
            const url = apiUrl + '/search';
            let results: any[] = [];
            let totalResults = 0;
			try {
				console.log("Doing search");
                const res = await axios.post(url, query);
                if (res.data.Successful) {
                    // results = [];
					console.log("Successful results", res);
                    results = res.data.Result?.Results || [];
                    totalResults = res.data.Result?.TotalResults || 0;
				} else {
					console.log("Inner error", res);
                    results = [];
                }
			} catch (error) {
				console.log("Outer error");
                results = [];
			}
			if (isCurrentFetch == true) {
				console.log("Is Current Fetch...");
				//if (scrollLoading) {
				if (!isFirstPage) {
					console.log("Not first page");
					//Hack to work around paging searches happening twice, and probably other problems
					var appendResults = results;//.filter(m => searchResult.Results.find(n => n.CTID == m.CTID) == null); //Should be fixed now that the first of the double-queries is getting skipped 
					var newSearchResults = [];
					if (appendResults.length > 0) {
						console.log("Appending results", appendResults);
						newSearchResults = searchResult.Results.concat(
							appendResults
						);
					}
					else {
						newSearchResults = [...searchResult.Results];
						console.log("Skipping appending results twice", results);
					}
					//Always set results to ensure other page events happen as expected even if nothing was appended(?)
					setSearchResult({
						...searchResult,
						Results: newSearchResults,
					});
				} else {
					console.log("First page, resetting results");
					setSearchResult({
						TotalResults: totalResults,
						Results: results,
					});
				}
				console.log("Settting searchAble");
				if (results.length < 20) {
					setSearchAble(false);
				} else {
					setSearchAble(true);
				}
				setInitialLoading(false);
				console.log("setScrollLoading", "false");
				setScrollLoading(false);
			}
			else {
				console.log("Is not Current Fetch...");
				//setSearchResult({ ...searchResult, Results: [...searchResult.Results] });
			}
        }
        getSearchResults();

        return () => {
			isCurrentFetch = false;
			console.log("Current Fetch = false");
        };
    }, [queryContext.query]);

	//Handle connecting result buttons to map markers
	//Work around setState being async via a bunch of stupid extra steps
	//First create the state stuff
	const [focusedOnMarkersForResult, setfocusedOnMarkersForResult] = useState(0);

	//When the button is clicked, set the view mode to map and then set the state to reference either the selected marker, or all of them
	function focusOnMarkersForResult(resultID: number) {
		setViewMode("map");
		setfocusedOnMarkersForResult(focusedOnMarkersForResult == resultID ? 0 : resultID);
	}

	//When the state actually gets around to changing, start trying to do things with the map
	useEffect(() => {
		tryFocusOnMarkersForResult(focusedOnMarkersForResult, 50);
	}, [focusedOnMarkersForResult]);

	//No callbacks to be able to tell when the map is ready, so brute force it with a timeout loop
	function tryFocusOnMarkersForResult(resultID: number, attemptsRemaining: number) {
		if (!mapMarkers.current && attemptsRemaining > 0 && resultID > 0) {
			setTimeout(() => {
				tryFocusOnMarkersForResult(resultID, attemptsRemaining - 1);
			}, 100);
		}
		//Once it's finally ready to do something useful, and if there are actually any markers to manipulate...
		else if (mapMarkers.current && mapMarkers.current.length > 0) {
			//Get the markers for this result
			var markersForResult = mapMarkers.current.filter((m: any) => { return resultID > 0 ? m.get("resultID") == resultID : true });
			console.log("Focusing on markers for result " + resultID, markersForResult.map((m: any) => { return m.get("resultID") }));

			//Set each marker's opacity
			mapMarkers.current.forEach(function (marker: any) {
				marker.setOpacity((marker.get("resultID") == resultID || resultID == 0) ? 1 : 0.01);
			});

			//Refit the map to the bounds of the selected markers
			//Have to do this in an if because TypeScript won't quit complaining about how googleMap.current might be "possibly undefined" even when optional chaining is used
			if (googleMap.current) {
				var bounds = new google.maps.LatLngBounds();
				markersForResult.forEach(function (marker: any) {
					bounds.extend(marker.getPosition());
				});
				googleMap.current.fitBounds(bounds);
				googleMap.current.getZoom() > 15 && googleMap.current.setZoom(15);
			}
		}
	}
	//

	//Have to split this into a start function and a useEffect due to React's stupid async render garbage
	function startExport() {
		setInExportMode(true);
		setIsExportRunning(true);
	}
	//

	useEffect(() => {
		if (inExportMode && isExportRunning) {
			(window as any).ExportTransactionGUID = (crypto as any).randomUUID();
			(window as any).TrackExportProgress = true;
			(window as any).MaxExportTrackingTickets = 10000;
			updateExportWindow(0, 0);
			//setExportTransactionGUID((crypto as any).randomUUID()); //Doesn't actually happen in time to allow this to work
			var query = { ...queryContext.query, SkipPages: 0, PageSize: 500, IsExportMode: true };
			axios.post(apiUrl + "/export?transactionGUID=" + (window as any).ExportTransactionGUID, query).then(response => {
				console.log("Loaded CSV Data", response);
				(window as any).TrackExportProgress = false;
				setTimeout(() => getExportProgress(true), 1000);

				if (response.data.Successful) {
					var file = new Blob([response.data.Result], { type: "text/csv" });
					var dataURL = window.URL.createObjectURL(file);
					var link = document.createElement("a");
					link.download = "Resource Export.csv";
					link.href = dataURL;
					link.click();
					window.URL.revokeObjectURL(dataURL);
					link.remove();

					//setIsExportRunning(false); //Causes page crash
					//setInExportMode(false); //Causes page crash
				}
				else {
					alert("One or more errors were encountered during the export:\n" + (response.data.Messages || []).join("\n"));
				}

			}, error => {
				console.log("Error loading CSV Data", error);
				//setIsExportRunning(false); //Causes page crash
				(window as any).TrackExportProgress = false;
				setTimeout(() => getExportProgress(true), 1000);

			});

			getExportProgress();
		}

	}, [inExportMode, isExportRunning])
	//

	function getExportProgress(force?: boolean) {
		//if ((inExportMode && isExportRunning) || force) { //Doesn't actually shut off if isExportRunning is set to false. The state doesn't update properly for some reason.
		if (((window as any).TrackExportProgress && (window as any).MaxExportTrackingTickets > 0) || force) {
			(window as any).MaxExportTrackingTickets--; //Prevent exports from running forever
			axios.get(apiUrl + "/export/getprogress/" + (window as any).ExportTransactionGUID).then(response => {
				console.log("Got progress", response);
				if (response?.data?.Result?.TotalItems && response?.data?.Result?.ProcessedItems) {
					updateExportWindow(response.data.Result.ProcessedItems, response.data.Result.TotalItems);
				}
				setTimeout(getExportProgress, 1000);
			});
		}
	}
	//

	function cancelExport() {
		axios.get(apiUrl + "/export/cancel/" + (window as any).ExportTransactionGUID).then(response => {
			console.log("Canceled transaction", response);
			setTimeout(() => getExportProgress(true), 1000);
		});
	}
	//


	function updateExportWindow(count: number, total: number) {
		try {
			var percentage = total == 0 ? "0%" : Math.ceil((count / total) * 100) + "%";
			(Array.from(document.getElementsByClassName("exportLabel"))[0] as any).innerHTML = "Exporting Results... (" + count + " of " + total + ")";
			var bar = (Array.from(document.getElementsByClassName("exportBar"))[0] as any);
			bar.style.width = percentage;
			bar.innerHTML = percentage;
		}
		catch (e) {}
	}
	//

	//Apply widgetization to the filters
	var widgetAvailableFilters = widgetPruneFiltersAndOptions(availableFilters, queryContext.query.SearchType);
	//console.log("Available filters", availableFilters);
	//console.log("Widget Available filters", widgetAvailableFilters);
	//console.log("App Base Name", appBaseName);


    return (
		<Wrapper>
			<style type="text/css">{`
				.filterPillsSection { display: flex; flex-wrap: wrap; gap: 5px; padding: 10px; max-width: 1140px; margin: auto; }
				.filterPillsSection .pillButton { display: flex; gap: 5px; align-items: center; border-radius: 20px; border: 1px solid transparent; background-color: #E6EAEC; color: #000; padding: 5px 10px; }
				.filterPillsSection .pillButton:is(:hover, :focus) { border-color: #000; }
				.filterPillsSection .pillButton:disabled { cursor: default; border-color: transparent; } 
				.filterPillsSection .pillButton .remove { font-weight: bold; margin-left: 10px; }
			`}</style>
            <TopRegion>
                <SearchBar onSearchButtonClick={onSearchButtonClick} />
            </TopRegion>
            <ResultsSection>
                {panelIsOpen && (
                    <FilterPanel
                        searchType={queryContext.query.SearchType}
						availableFilters={widgetAvailableFilters}
                        currentFilters={queryContext.query.MainFilters}
                        onHide={() => setPanelIsOpen(false)}
                        onUpdateFilter={onUpdateFilter}
                    />
                )}
                <ResultsSectionBody ref={resultRef}>
                    <ResultBodytHeader>
                        <ResultCount>
                            {initialLoading
                                ? `Searching...`
                                : `${searchResult.TotalResults} results`}
                        </ResultCount>
                        {!panelIsOpen && (
                            <FilterButton
                                onClick={() => setPanelIsOpen(true)}
                                buttonType="primary">
                                <HStack
                                    align="center"
                                    justify="center"
                                    spacing="5px">
                                    Filter
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        size="sm"
                                    />
                                </HStack>
                            </FilterButton>
                        )}
                        <ResultListHeaderSection
                            justify="flex-end"
                            flex={2}
                            spacing={isMobile ? '0' : '5px'}>
                            <DropdownGroup spacing="5px" justify="flex-end">
                                <DropdownSection>
									<Dropdown
										ariaLabel="Display Mode"
                                        options={resultDisplayOptions}
                                        onChange={(option) =>
                                            setDisplayMode(option.key)
                                        }
                                        getOptionLabel={(option) =>
                                            option.value
                                        }
                                    />
                                </DropdownSection>
                                <DropdownSection>
									<Dropdown
										ariaLabel="Sort Order"
										options={sortOptions}
										defaultOptionIndex={getDefaultSortOption()}
										onChange={(option) => {
											updateCurrentQuery((query: any) => {
												query.SortOrder = option.key;
											});

                                            queryContext.updateQuery({
                                                ...queryContext.query,
                                                SortOrder: option.key
                                            });
										} }
                                        getOptionLabel={(option) =>
                                            option.value
                                        }
                                    />
                                </DropdownSection>
                            </DropdownGroup>
                            <GroupButton>
                                <IconButton
                                    onClick={() => setViewMode('list')}
                                    buttonType={
                                        viewMode === 'list'
                                            ? 'primary'
                                            : undefined
                                    }>
                                    <HStack align="center" justify="center">
                                        <ButtonIcon icon={faList} size="lg" />
                                        List
                                    </HStack>
                                </IconButton>
                                <IconButton
                                    onClick={() => setViewMode('map')}
                                    buttonType={
                                        viewMode === 'map'
                                            ? 'primary'
                                            : undefined
                                    }>
                                    <HStack align="center" justify="center">
                                        <ButtonIcon
                                            icon={faMapMarkerAlt}
                                            size="2x"
                                        />
                                        Map
                                    </HStack>
								</IconButton>
							</GroupButton>
							{queryContext.query.SearchType != 'competencyframework' && 
							<ExportButton buttonType="primary" onClick={() => setInExportMode(true)}>Export</ExportButton>
							}
                        </ResultListHeaderSection>
                    </ResultBodytHeader>
					{/*renderFilterPills()*/}
					<div className="filterPillsSection"></div>
                    <ResultBodyContent id="mainResultContainer">
                        {viewMode === 'map' && (
                            <MapWrapper>
                                <GoogleMap
                                    defaultCenter={defaultMapSetting.center}
                                    defaultZoom={defaultMapSetting.zoom}
                                    bootstrapURLKeys={{
                                        key: googleMapsKey,
                                    }}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }) => {
                                        mapMarkers.current = setupMap(
                                            map,
                                            maps,
											resultsLocations
                                        );
                                        googleMap.current = map;
                                        googleMaps.current = maps;
                                    }}
                                />
                            </MapWrapper>
                        )}
                        <ResultList>
                            {initialLoading ? (
                                <LoadingIndicatorWrapper>
                                    <ActivityIndicator />
                                </LoadingIndicatorWrapper>
                            ) : (
                                <ResultListItems>
                                    {searchResult.TotalResults === 0 && (
                                        <EmptyResult type={queryContext.query.SearchType} />
                                    )}
                                    {searchResult.Results.map((item, index) => {
                                        return (
											<ResultItem key={index} data-meta_id={item.Meta_Id}>
                                                <SearchResultItem
													type={queryContext.query.SearchType}
													item={item}
													showBody={displayMode == 'showAll' || displayMode == 'showDescription'}
													locations={item[getResultsLocationField(queryContext.query.SearchType)]}
													onLocationButtonClick={() => focusOnMarkersForResult(item.Meta_Id)}
                                                />
                                                {(displayMode == 'showAll' || displayMode == 'showButton') && (
                                                    <SearchResultPillSection
														indentifier={index}
														tagSets={item.SearchTags}
														onUpdateFilter={onUpdateFilter}
														searchType={queryContext.query.SearchType}
														detailPageLink={(appBaseName.length <= 1 ? "" : appBaseName + "/") + queryContext.query.SearchType + "/" + item.Meta_Id + "/" + item.Meta_FriendlyName}
                                                    />
                                                )}
                                            </ResultItem>
                                        );
                                    })}
                                </ResultListItems>
                            )}
                            {scrollLoading && (
                                <LoadingIndicatorWrapper>
                                    <ActivityIndicator />
                                </LoadingIndicatorWrapper>
                            )}
                        </ResultList>
                    </ResultBodyContent>
                </ResultsSectionBody>
			</ResultsSection>
			<BackToTopButton />		
			{inExportMode && <ExportDisplay totalResults={searchResult.TotalResults} inExportMode={inExportMode} setInExportMode={setInExportMode} isExportRunning={isExportRunning} setIsExportRunning={setIsExportRunning} startExport={startExport} cancelExport={cancelExport} />}
        </Wrapper>
    );
}

const ExportDisplay = function (props: { totalResults: number, inExportMode: boolean, setInExportMode: any, isExportRunning: boolean, setIsExportRunning: any, startExport: any, cancelExport: any }) {
	var estimatedExportTime = Math.ceil(((props.totalResults / 25) * 0.5) / 60); //25 = the page size of the export. Update it here if that changes. 0.5 = how long per page.
	return <ExportOverlay>
		<ExportModal>
			<ExportLabelWrapper>
				<ExportLabel className="exportLabel">Export{props.isExportRunning && "ing"} Results{props.isExportRunning && "..."}</ExportLabel>
				{props.isExportRunning && <ActivityIndicator size="sm" />}
				<div style={{marginLeft: "auto"}}></div>
				{!props.isExportRunning && <ExportStartButton onClick={() => { (window as any).AllowExportProgress = true; props.startExport(); }}>Start Export</ExportStartButton>}
				<ExportCancelButton onClick={() => { (window as any).AllowExportProgress = false; props.setIsExportRunning(false); props.setInExportMode(false); props.cancelExport(); }}>Cancel/Close</ExportCancelButton>
			</ExportLabelWrapper>
			<ExportDescription>
				<p>The Credential Finder export contains limited key information about resources and may take a few minutes to perform. For better performance, please narrow your search results.</p>
				<p>{/*Estimated time to export {props.totalResults} resources: {estimatedExportTime < 1 ? "Less than one minute" : estimatedExportTime + " minute(s)"}. */}Click the "Start Export" button to begin.</p>
				<p>For a thorough export of Registry data, please <a href="https://credreg.net/registry/handbook#consume" target="_blank">review our consuming options</a> and <a href="mailto:info@credentialengine.org">contact Credential Engine</a>.</p>
			</ExportDescription>
			<ExportBarWrapper>
				<ExportBar className="exportBar">0%</ExportBar>
			</ExportBarWrapper>
		</ExportModal>
	</ExportOverlay>
}

const ExportOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background-color: rgba(0,0,0,0.5);
`;

const ExportModal = styled.div`
	width: 90vw;
	max-width: 800px;
	background-color: #FFF;
	padding: 10px;
	margin: 50px auto;
	border-radius: 3px;

	& p {
		margin: 5px 0;
	}
`;

const ExportLabelWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 5px 10px;
`;

const ExportStartButton = styled.button`
	background-color: #3D3;
	border: none;
	border-radius: 3px;

	&:hover, :focus {
		background-color: #5D5;
		cursor: pointer;
	}
`;

const ExportCancelButton = styled.button`
	background-color: #FAA;
	border: none;
	border-radius: 3px;

	&:hover, :focus {
		background-color: #F77;
		cursor: pointer;
	}
`;

const ExportDescription = styled.div`
	padding: 5px 10px;
`;

const ExportLabel = styled.div`
	font-weight: bold;
`;

const ExportBarWrapper = styled.div`
	background-color: #777;
	padding: 1px;
	height: 32px;
	border-radius: 3px;
`;

const ExportBar = styled.div`
	background-color: #3E3;
	padding: 5px;
	font-weight: bold;
	height: 30px;
	min-width: 40px;
	width: 0%;
	transition: width 0.2s;
	text-align: center;
	border-radius: 3px;
`;

