import React from 'react';
import PageSectionItem from '../../components/PageSectionItem';
import { Label, Section } from '../../components/DetailPage/styles';
import { Credential } from '../../types/external';
import { ModalType } from '../../types/internal';
import SearchPills from '../../components/SearchPills';
import { isFilled } from '../../validation';


interface Props {
    credential: Credential;
    onOpenModalClick: (modalType: ModalType) => () => void;
    onCloseModalClick: () => void;
    openedModal: ModalType | undefined;
}

export default function AboutDetail({
    credential,
    onOpenModalClick,
    onCloseModalClick,
    openedModal,
}: Props) {
    return (
        <>
            {credential.CredentialType && (
                <PageSectionItem>
                    <Label>Credential Type</Label>
                    <SearchPills links={[credential.CredentialType]} />
                </PageSectionItem>
            )}
            {credential.CredentialStatusType && (
                <PageSectionItem>
                    <Label>Credential Status</Label>
                    <SearchPills links={[credential.CredentialStatusType]} />
                </PageSectionItem>
            )}
            {credential.AudienceType && (
                <PageSectionItem>
                    <Label>Audience Type</Label>
                    <SearchPills links={credential.AudienceType} />
                </PageSectionItem>
            )}
            {credential.AudienceLevelType && (
                <PageSectionItem>
                    <Label>Audience Level</Label>
                    <SearchPills links={credential.AudienceLevelType} />
                </PageSectionItem>
            )}
            {credential.LearningDeliveryType && (
                <PageSectionItem>
                    <Label>Learning Delivery Type</Label>
                    <SearchPills links={credential.LearningDeliveryType} />
                </PageSectionItem>
            )}
            {credential.AssessmentDeliveryType && (
                <PageSectionItem>
                    <Label>Assessment Delivery</Label>
                    <SearchPills links={credential.AssessmentDeliveryType} />
                </PageSectionItem>
            )}
			{isFilled(credential.DegreeConcentration) && (
				<Section>
					<Label>Degree Concentration</Label>
					<SearchPills
						links={credential.DegreeConcentration}
					/>
				</Section>
			)}
			{isFilled(credential.DegreeMajor) && (
				<Section>
					<Label>Degree Major</Label>
					<SearchPills links={credential.DegreeMajor} />
				</Section>
			)}
			{isFilled(credential.DegreeMinor) && (
				<Section>
					<Label>Degree Minor</Label>
					<SearchPills links={credential.DegreeMinor} />
				</Section>
			)}
        </>
    );
}
