import styled from 'styled-components/macro';

export const TogglerButton = styled.button`
    background: none;
    border: 0 none;
    bottom: 0;
    box-shadow: none;
    color: ${(p) => p.theme.color.brown};
    text-align: left;
    padding: 5px 0;
`;

export const ExpandableP = styled.p`
    margin-bottom: 0;
`;
