import credentialsIcon from '../../assets/images/icons/icon-credentials-blue-white-01.svg';
import learningOpportunitiesIcon from '../../assets/images/icons/icon-learning-opportunities-blue-white-01.svg';
import assessmentsIcon from '../../assets/images/icons/icon-assessments-blue-white-01.svg';
import qualityAssuranceIcon from '../../assets/images/icons/icon-quality-assurance-blue-white-01.svg';
import skillsAndCompetenciesIcon from '../../assets/images/icons/icon-competencies-blue-white-01.svg';
import pathwaysIcon from '../../assets/images/icons/icon-pathways-blue-white-01.svg';
import transferValueIcon from '../../assets/images/icons/icon-transfer-value-blue-white-01.svg';

export const informationItems = [
    {
        icon: credentialsIcon,
        title: 'Credential',
        description:
            'Degrees, certificates, badges, and more that show what an individual knows and can do.',
    },
    {
        icon: learningOpportunitiesIcon,
        title: 'Learning Opportunities',
        description:
            'Formal opportunities that allow individuals to earn credentials or specific competencies and skills to advance their knowledge and job prospects.',
    },
    {
        icon: assessmentsIcon,
        title: 'Assessments',
        description:
            'Activities that evaluate what an individual knows and can do as a result of a specific learning opportunity or a collection of them en route to earning a credential.',
    },
    {
        icon: qualityAssuranceIcon,
        title: 'Quality Assurance',
        description:
            'Processes that help ensure credentials, learning opportunities, and individual skills and competencies are trusted, valuable, reliable.',
    },
    {
        icon: skillsAndCompetenciesIcon,
        title: 'Skills and Competencies',
        description:
            'Abilities that individuals can acquire and have the capacity to apply in the workplace and real-world situations.',
    },
    {
        icon: pathwaysIcon,
        title: 'Pathways',
        description:
            'Direct connections between education opportunities, skills/competencies, and the workforce that help people find clear avenues to meet their needs.',
    },
    {
        icon: transferValueIcon,
        title: 'Transfer Value',
        description:
            'How well an individual credential, learning opportunity, or competency will relate to and apply in other skill development and/or career advancement opportunities.',
    },
];
