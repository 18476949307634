import React from 'react';
import { VerificationServiceProfile as ProfileType } from '../../types/external';
import OutlineList from '../../components/DetailPage/Outline';

import { Body, Description, Heading } from './styles';
import { Label, Section } from '../../components/DetailPage/styles';
import DisplayPills from '../../components/PillDisplay';
import { isFilled } from '../../validation';
import PageSectionItem from '../../components/PageSectionItem';
import TextLink from '../TextLink';
import CostProfileList from '../DetailPage/CostProfile';
import LinkObject from '../DetailPage/LinkObject';
import { widgetGetPluralLabelForText } from "../../utils/SiteWidgetizer";

interface Props { 
    profile: ProfileType;
}

export default function VerificationServiceProfile({ profile }: Props) {
	if (!profile || !isFilled(profile)) {
		return null;
	}
    return (
        <div>
            <Body>
                <Description>{profile.Description}</Description>
                {profile.SubjectWebpage && (
					<Section>
						{profile.SubjectWebpage && <LinkObject item={{ URL: profile.SubjectWebpage, Label: "More Information" }} />}
					</Section>
				)}
				{profile.OfferedBy && (
                    <p>
                        <strong>Offered By: </strong>
                        <TextLink outline={profile.OfferedBy.Values[0]} />
                    </p>
                )}
				{profile.DateEffective && (
                <Section>
                    <div><b>Date Effective: </b>{profile.DateEffective}</div>
                </Section>
            	)}
				{profile.EstimatedCost && (
					<Section>
						<div><b>Estimated Costs</b></div>
						<CostProfileList items={profile.EstimatedCost} />	
					</Section>		
				)}

           
				{profile.VerifiedClaimType && (
						<Section>
						<Label>Verified Claim Type</Label>
						<DisplayPills links={profile.VerifiedClaimType} />
						</Section>
				)}
			
			
				{isFilled(profile.OfferedIn) && (
					<Section>
						<label>Offered In Jurisdiction:</label>
						{profile.OfferedIn.map(
							(property, index) => (
								<div key={`cr-ofrjurisdiction-${index}`} style={{backgroundColor:"whiteSmoke"}}>
									<p>
									{property.Description && (
										<Description>
											{property.Description}
										</Description>
									)}
									{property.GlobalJurisdiction && (
										<Section>                    
											<label>Globally Applicable</label>
											<div>This property is global.</div>
										</Section>
									)}
									{property.AssertedBy && (
										<p>
											<strong>Asserted By: </strong>
											<TextLink outline={property.AssertedBy} />
										</p>
									)}
									{property.MainJurisdiction && (
										<Section>
											<label>This profile has these assertions in:</label>
											<div>{property.MainJurisdiction.Name}</div>
										</Section>
									)}
									{property.JurisdictionException && (
										<Section>
											<label>This profile does not have these assertions in:</label>
											<div>
												{property.JurisdictionException.map(
													(place) => (
														<div key={`cr-ofrplace-${place.Name}`}>
															{place.Name}
														</div>
													),
												)}
											</div>
										</Section>
									)}
									</p>								
								</div>
							),
						)}
					</Section>
				)}
				{isFilled(profile.Jurisdiction) && (
					<Section>
						<label>Jurisdiction:</label>
						{profile.Jurisdiction.map(
							(jurisdiction, index) => (
								<div key={`cr-jurisdiction-${index}`}>
									<p>
									{jurisdiction.Description && (
										<Description>
											{jurisdiction.Description}
										</Description>
									)}
									{jurisdiction.GlobalJurisdiction && (
										<Section>                    
											<label>Globally Applicable</label>
											<div>This jurisdiction is global.</div>
										</Section>
									)}
									{jurisdiction.AssertedBy && (
										<p>
											<strong>Asserted By: </strong>
											<TextLink outline={jurisdiction.AssertedBy} />
										</p>
									)}
									{jurisdiction.MainJurisdiction && (
										<Section>
											<label>This profile has these assertions in:</label>
											<div>{jurisdiction.MainJurisdiction.Name}</div>
										</Section>
									)}
									{jurisdiction.JurisdictionException && (
										<Section>
											<label>This profile does not have these assertions in:</label>
											<div>
												{jurisdiction.JurisdictionException.map(
													(place) => (
														<div key={`cr-place-${place.Name}`}>
															{place.Name}
														</div>
													),
												)}
											</div>
										</Section>
									)}
									</p>
									
								</div>
							),
						)}
					</Section>
				)}
				{profile.HolderMustAuthorizeLabel && (
					<Section>				
						<label>Holder Authorization: </label>
                    	<div>{profile.HolderMustAuthorizeLabel}</div>
					</Section>		
				)}
				{profile.VerificationDirectory && profile.VerificationDirectory.URL && (
					<Section>
						{profile.VerificationDirectory && <LinkObject item={profile.VerificationDirectory} />}
					</Section>
				)}
				{profile.VerificationMethodDescription && (
					<Section>				
						<Description>{profile.VerificationMethodDescription}</Description>
					</Section>		
				)}
				{profile.VerificationService && profile.VerificationService.URL && (
					<Section>
						{profile.VerificationService && <LinkObject item={profile.VerificationService} />}
					</Section>
				)}

						
				{profile.TargetCredential && profile.TargetCredential.Values && (
					<Section>	
						<label>{"Target " + widgetGetPluralLabelForText("Credentials")}</label>
						<OutlineList
							items={profile.TargetCredential.Values}
						/>
					</Section>		
				)}
				
            </Body>
        </div>
    );
}
