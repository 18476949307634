import React from 'react';
import {
    FilterButton,
    FilterItem,
    Filters,
    FooterContent,
    FooterCounter,
    FooterTitle,
    GraphCounter,
    GraphFooter,
    GraphHeader,
    GraphMain,
    GraphTitle,
    HeaderContent,
    HeaderTitle,
    Wrapper,
} from './styles';

interface Props {
    id: string;
    title: string;
    titleCounter: number;
    headerTitle: string;
    headerContent: string;
    footerTitle?: string;
    footerContent?: string;
    footerCounter?: number;
    filters: { label: string; action: string }[];
}

function Stats(props: Props) {
    return (
        <Wrapper id={props.id}>
            <GraphHeader>
                <GraphTitle>{props.title}</GraphTitle>
                <GraphCounter>{props.titleCounter}</GraphCounter>
                <HeaderTitle>{props.headerTitle}</HeaderTitle>
                <HeaderContent>{props.headerContent}</HeaderContent>
            </GraphHeader>
            <GraphMain>
                <Filters>
                    {props.filters.map((filter, index) => {
                        return (
                            <FilterItem key={index}>
                                <FilterButton
                                    onClick={() => console.log(filter.action)}>
                                    {filter.label}
                                </FilterButton>
                            </FilterItem>
                        );
                    })}
                </Filters>
                <div>
                    The dynamic graphic goes here.
                    <br />
                    It will be implemented later, when we have a better idea
                    about the API and library to use.
                </div>
            </GraphMain>
            {(props.footerContent ||
                props.footerTitle ||
                props.footerContent) && (
                <GraphFooter>
                    {props.footerCounter && (
                        <FooterCounter>{props.footerCounter}</FooterCounter>
                    )}
                    {props.footerTitle && (
                        <FooterTitle>{props.footerTitle}</FooterTitle>
                    )}
                    {props.footerContent && (
                        <FooterContent>{props.footerContent}</FooterContent>
                    )}
                </GraphFooter>
            )}
        </Wrapper>
    );
}

export default Stats;
