import { apiUrl } from '../../shared/globals';

export function createMiniSearch(container, query, renderInitialUI, renderNoResultsUI, renderHasResultsUI, renderResultList, renderResultMethod, handleError, getScrollContext, pageSize) {
	if (!container) {
		return;
	}

	//Create the search box
	var searchBox = document.createElement("div");
	searchBox.classList.add("miniSearch");
	container.appendChild(searchBox);
	var resultList;

	//Other search attributes
	var busy = false;
	var resultsData = [];
	var totalResults = 0;
	var skip = 0;
	var take = pageSize || 10;
	var hasResultsUI = false;

	//Render a UI to show while the first search is being performed
	renderInitialUI?.(searchBox);

	//Trigger the search
	/*
	window.addEventListener("scroll", () => {
		if (!busy && skip * take < totalResults && searchBox.offsetTop + searchBox.offsetHeight <= window.scrollY + window.innerHeight) {
			doSearch();
		}
	});
	*/

	//Force the initial search
	doSearch();

	//Method to perform the search
	function doSearch() {
		busy = true;
		postData(apiUrl + "/detail/registrysearch", {
			Query: query,
			Skip: skip,
			Take: take,
			UseBetaAPI: true
		}, response => {
			//Set the results and total results
			resultsData = response.Result.data;
			totalResults = response.Result.total;
			//Render no results UI if no results
			if (totalResults == 0) {
				renderNoResultsUI?.(searchBox, searchBox);
			}
			//Otherwise, render a page of results, wait a moment, then reenable the search so another page can be loaded
			else {
				if (!hasResultsUI) {
					renderHasResultsUI?.(searchBox, resultsData, totalResults);
					resultList = renderResultList?.(searchBox);
					if (!resultList) {
						resultList = document.createElement("div");
						searchBox.appendChild(resultList);
					}
					resultList.classList.add("miniSearchResults");
					var scroller = getScrollContext?.(resultList) || window;
					scroller.addEventListener("scroll", () => {
						var isNearBottom = scroller == window ? searchBox.offsetTop + searchBox.offsetHeight <= window.scrollY + window.innerHeight : scroller.scrollTop >= scroller.scrollHeight;
						if (!busy && skip * take < totalResults && isNearBottom) {
							doSearch();
						}
					});
					hasResultsUI = true;
				}
				renderResultsPage(resultList, resultsData, renderResultMethod);
				setTimeout(() => {
					busy = false; //Uncomment this to reenable the search
					skip += take;
				}, 250);
			}
		}, error => {
			handleError?.(searchBox, searchBox, error);
		});
	}
}
//

//Render a page of results using the provided method for each result
function renderResultsPage(container, resultsData, renderResultMethod) {
	resultsData?.forEach(resultData => {
		var wrapper = document.createElement("div");
		wrapper.classList.add("resultWrapper");
		container.appendChild(wrapper);
		setTimeout(() => {
			renderResultMethod(wrapper, resultData);
		}, 0);
	});
}
//

//Generic post data method - may be better to put this somewhere else and import it
export function postData(url, data, success, error) {
	fetch(url, {
		method: "POST",
		headers: { "Accept": "application/json", "Content-Type": "application/json" },
		body: JSON.stringify(data)
	}).then(response => {
		return response.text();
	}).then(text => {
		var value = JSON.parse(text);
		success?.(value);
	}).catch(response => {
		console.log("Error", response);
		error?.(response);
	});
}
//

//Generic UUID method - may be better to put this somewhere else
export function getUUID() {
	return crypto.randomUUID();
}
//

//Generic method to get an element after react's lifecycle eventually renders it
export function getElementEventually(getMethod, afterGet) {
	var attempts = 10;
	var keepTrying;
	keepTrying = setInterval(() => {
		console.log("Trying to get element...", attempts);
		var element = getMethod();
		if (element || attempts <= 0) {
			clearInterval(keepTrying);
			if (element) {
				console.log("Found element", attempts);
				afterGet(element);
			}
		}
		attempts--;
	}, 1000);
}
//