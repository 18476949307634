import React, { Dispatch, SetStateAction, useEffect, useContext, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';

import credentials_icon from '../../assets/images/icons/icon-credentials-white-green-01.svg';
import organizations_icon from '../../assets/images/icons/icon-organization-white-green-01.svg';
import assessments_icon from '../../assets/images/icons/icon-assessments-white-green-01.svg';
import learning_opportunities_icon from '../../assets/images/icons/icon-learning-opportunities-white-green-01.svg';
import transfer_values_icon from '../../assets/images/icons/icon-transfer-value-white-green-01.svg';
import pathways_icon from '../../assets/images/icons/icon-pathways-white-green-01.svg';
import competency_frameworks_icon from '../../assets/images/icons/icon-competencies-white-green-01.svg';
import concept_schemes_icon from '../../assets/images/icons/icon-competencies-white-green-01.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight, faExternalLinkAlt, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import ActivityIndicator from '../../components/ActivityIndicator';
import { apiUrl, BUCKET_STORAGE, appURL, configName } from '../../shared/globals';
import axios from 'axios';
import { formatPrice } from '../../utils';
import { MainItemBroadType, MainSearchOption } from '../../types/internal';
import {
	Credential,
	Organization,
	Assessment,
	LearningOpportunity,
	TransferValue,
	Pathway,
	//PathwaySet,
	CompetencyFramework,
	Collection,
	ConceptScheme,
	Link,
	CostProfile,
	DurationProfile,
	Competency,
    ConditionProfile,
    Outline,
    Place,
    ContactPoint,
    ReferenceFramework,
    FinancialAssistanceProfile,
    DataSetProfile,
    AggregateDataProfile,
    DataSetTimeFrame,
    DataProfile,
    QuantitativeValue
} from '../../types/external';
import { doesWidgetHideThisSearchType, widgetGetColor, widgetGetPluralLabelForText } from '../../utils/SiteWidgetizer';
import { addCompareItem, getCompareItemByCTID, getCompareItems, getCompareItemsByType, removeCompareItem, addOrUpdateCompareItem, updateStateOnCompareChange } from '../../utils/LocalStorage';
import { getResourceByURLWithoutState } from '../../components/GetResourceByURI';
import { getUUID } from '../../components/MiniSearch';
import Dropdown from '../../components/Dropdown';
import { mainSearchTypeOptions } from '../../shared/data';
import PillLink from '../../components/PillLink';
import { renderToString } from 'react-dom/server';

interface CompareDataProperty {
	label: string;
	value: (item: any) => JSX.Element | null;
	expanded: boolean;
}

export default function ComparePage(props: RouteComponentProps) {
	//Setup tabs
	const [currentTab, setCurrentTab] = useState("credential");
	const [compareItems, setCompareItems] = useState(getCompareItems());

	//Get the raw data out of localStorage or initialize empty containers
	var rawCompareData = getRawCompareData();
	//getCompareDataFromShareLink(compareContext);

	useEffect(() => {
		getCompareDataFromShareLink();
		updateStateOnCompareChange(() => { setCompareItems(getCompareItems()); });
		setCompareItems(getCompareItems());
	}, []);

	//Remove the share link data from the URL so this process doesn't cause things to get re-added if the user removes them since React will reread the URL and try to force it to
	useEffect(() => {
		setTimeout(function () {
			var currentParams = new URLSearchParams(window.location.search);
			var sharelessParams = new URLSearchParams("");
			currentParams.forEach((value, key) => {
				if (!key.toLowerCase().includes("share_") && !sharelessParams.get(key)) {
					sharelessParams.append(key, value);
				}
			});
			window.history.replaceState(null, document.title, window.location.href.split("?")[0] + (Array.from(sharelessParams.keys()).length > 0 ? "?" + sharelessParams.toString() : ""));
		}, 500);
	}, []);

	//Show the tab from the selected search type, if applicable
	useEffect(() => {
		var searchType = new URLSearchParams(window.location.search?.toLowerCase()).get("searchtype") || "credential";
		setCurrentTab(searchType);
	}, []);

	//Setup the methods that will read data out of the source data
	const [credentialCompareData, setCredentialCompareData] = useState([
		{ label: "", value: (item: Credential) => <HtmlLink source={{ Label: item.Name, URL: appURL + "/credential/" + item.Meta_Id }} cssClass="resourceName" /> },
		{ label: "", value: (item: Credential) => <HtmlLink source={item.OwnedByLabel} /> },
		{ label: "", value: (item: Credential) => <HtmlDiv source={item.CTDLTypeLabel} cssClass="credentialType" /> },
		{ label: "Description", value: (item: Credential) => <HtmlDiv source={item.Description} />, expanded: true },
		{ label: "Audience Types", value: (item: Credential) => <LinksAsPlainList source={item.AudienceType} /> },
		{ label: "Audience Levels", value: (item: Credential) => <LinksAsPlainList source={item.AudienceLevelType} /> },
		//{ label: "Industries", value: (item: Credential) => <LinksAsPlainList source={item.IndustryType} /> },
		//{ label: "Occupations", value: (item: Credential) => <LinksAsPlainList source={item.OccupationType} /> },
		{ label: "Industries", value: (item: Credential) => <ReferenceFrameworkList source={item.IndustryType} /> },
		{ label: "Occupations", value: (item: Credential) => <ReferenceFrameworkList source={item.OccupationType} /> },
		{ label: "Estimated Costs", value: (item: Credential) => <CostProfileList source={item.EstimatedCost} /> },
		{ label: "Estimated Time to Earn", value: (item: Credential) => <DurationProfileList source={item.EstimatedDuration} /> },
		{ label: "Financial Assistance", value: (item: Credential) => <LinksAsPlainList source={item.FinancialAssistance?.flatMap((m: FinancialAssistanceProfile) => m.FinancialAssistanceType).filter(m => m)} /> },
		{ label: "Location(s)", value: (item: Credential) => <PlaceList source={item.AvailableAt} /> },
		{ label: "Requirements Summary", value: (item: Credential) => <ConditionProfileList source={item.Requires} /> },
		{ label: "Required for Credential(s)", value: (item: Credential) => <OutlinesAsLinkList source={item.IsRequiredFor?.flatMap((m: ConditionProfile) => m.TargetCredential?.Values).filter(m => m)} showType={true} /> },
		{ label: "Outcomes Summary", value: (item: Credential) => <OutcomeSummaryList aggregateData={item.AggregateData} externalData={item.ExternalDataSetProfiles} />}
	]);

	const [organizationCompareData, setOrganizationCompareData] = useState([
		{ label: "", value: (item: Organization) => <HtmlLink source={{ Label: item.Name, URL: appURL + "/organization/" + item.Meta_Id }} cssClass="resourceName" /> },
		{ label: "Description", value: (item: Organization) => <HtmlDiv source={item.Description} />, expanded: true },
		{ label: "Organization Types", value: (item: Organization) => <LinksAsPlainList source={item.AgentType} /> },
		{ label: "Organization Sectors", value: (item: Organization) => <LinksAsPlainList source={item.AgentSectorType} /> },
		{ label: "Service Types", value: (item: Organization) => <LinksAsPlainList source={item.ServiceType} /> },
		//{ label: "Industries", value: (item: Organization) => <LinksAsPlainList source={item.IndustryType} /> },
		{ label: "Industries", value: (item: Organization) => <ReferenceFrameworkList source={item.IndustryType} /> },
		{ label: "Location(s)", value: (item: Organization) => <PlaceList source={item.Address} /> }
	]);

	const [assessmentCompareData, setAssessmentCompareData] = useState([
		{ label: "", value: (item: Assessment) => <HtmlLink source={{ Label: item.Name, URL: appURL + "/assessment/" + item.Meta_Id }} cssClass="resourceName" /> },
		{ label: "", value: (item: Assessment) => <HtmlLink source={item.OwnedByLabel} /> },
		{ label: "Description", value: (item: Assessment) => <HtmlDiv source={item.Description} />, expanded: true },
		{ label: "Audience Types", value: (item: Assessment) => <LinksAsPlainList source={item.AudienceType} /> },
		{ label: "Audience Levels", value: (item: Assessment) => <LinksAsPlainList source={item.AudienceLevelType} /> },
		{ label: "Assessment Method Types", value: (item: Assessment) => <LinksAsPlainList source={item.AssessmentMethodType} /> },
		{ label: "Delivery Method Types", value: (item: Assessment) => <LinksAsPlainList source={item.DeliveryType} /> },
		{ label: "Industries", value: (item: Assessment) => <ReferenceFrameworkList source={item.IndustryType} /> },
		{ label: "Occupations", value: (item: Assessment) => <ReferenceFrameworkList source={item.OccupationType} /> },
		{ label: "Estimated Costs", value: (item: Assessment) => <CostProfileList source={item.EstimatedCost} /> },
		{ label: "Estimated Time to Complete", value: (item: Assessment) => <DurationProfileList source={item.EstimatedDuration} /> },
		{ label: "Financial Assistance", value: (item: Assessment) => <LinksAsPlainList source={item.FinancialAssistance?.flatMap((m: FinancialAssistanceProfile) => m.FinancialAssistanceType).filter(m => m)} /> },
		{ label: "Location(s)", value: (item: Assessment) => <PlaceList source={item.AvailableAt} /> },
		{ label: "Entry Requirements Summary", value: (item: Assessment) => <ConditionProfileList source={item.EntryCondition} /> },
		{ label: "Completion Requirements Summary", value: (item: Assessment) => <ConditionProfileList source={item.Requires} /> },
		{ label: "Required for Credential(s)", value: (item: Assessment) => <OutlinesAsLinkList source={item.IsRequiredFor?.flatMap((m: ConditionProfile) => m.TargetCredential?.Values).filter(m => m)} showType={true} /> },
		{ label: "Outcomes Summary", value: (item: Credential) => <OutcomeSummaryList aggregateData={item.AggregateData} externalData={item.ExternalDataSetProfiles} /> }
	]);

	const [learningOpportunityCompareData, setLearningOpportunityCompareData] = useState([
		{ label: "", value: (item: LearningOpportunity) => <HtmlLink source={{ Label: item.Name, URL: appURL + "/learningopportunity/" + item.Meta_Id }} cssClass="resourceName" /> },
		{ label: "", value: (item: LearningOpportunity) => <HtmlLink source={item.OwnedByLabel} /> },
		{ label: "Description", value: (item: LearningOpportunity) => <HtmlDiv source={item.Description} />, expanded: true },
		{ label: "Audience Types", value: (item: LearningOpportunity) => <LinksAsPlainList source={item.AudienceType} /> },
		{ label: "Audience Levels", value: (item: LearningOpportunity) => <LinksAsPlainList source={item.AudienceLevelType} /> },
		{ label: "Learning Method Types", value: (item: LearningOpportunity) => <LinksAsPlainList source={item.LearningMethodType} /> },
		{ label: "Delivery Types", value: (item: LearningOpportunity) => <LinksAsPlainList source={item.DeliveryType} /> },
		{ label: "Industries", value: (item: LearningOpportunity) => <ReferenceFrameworkList source={item.IndustryType} /> },
		{ label: "Occupations", value: (item: LearningOpportunity) => <ReferenceFrameworkList source={item.OccupationType} /> },
		{ label: "Estimated Costs", value: (item: LearningOpportunity) => <CostProfileList source={item.EstimatedCost} /> },
		{ label: "Estimated Time to Complete", value: (item: LearningOpportunity) => <DurationProfileList source={item.EstimatedDuration} /> },
		{ label: "Financial Assistance", value: (item: LearningOpportunity) => <LinksAsPlainList source={item.FinancialAssistance?.flatMap((m: FinancialAssistanceProfile) => m.FinancialAssistanceType).filter(m => m)} /> },
		{ label: "Location(s)", value: (item: LearningOpportunity) => <PlaceList source={item.AvailableAt} /> },
		{ label: "Entry Requirements Summary", value: (item: LearningOpportunity) => <ConditionProfileList source={item.EntryCondition} /> },
		{ label: "Completion Requirements Summary", value: (item: LearningOpportunity) => <ConditionProfileList source={item.Requires} /> },
		{ label: "Required for Credential(s)", value: (item: LearningOpportunity) => <OutlinesAsLinkList source={item.IsRequiredFor?.flatMap((m: ConditionProfile) => m.TargetCredential?.Values).filter(m => m)} showType={true} /> },
		{ label: "Outcomes Summary", value: (item: Credential) => <OutcomeSummaryList aggregateData={item.AggregateData} externalData={item.ExternalDataSetProfiles} /> }
	]);

	const [transferValueCompareData, setTransferValueCompareData] = useState([
		{ label: "", value: (item: TransferValue) => <HtmlLink source={{ Label: item.Name, URL: appURL + "/transfervalue/" + item.Meta_Id }} cssClass="resourceName" /> },
		{ label: "", value: (item: TransferValue) => <HtmlLink source={item.OwnedByLabel} /> },
		{ label: "Description", value: (item: TransferValue) => <HtmlDiv source={item.Description || "Coming soon"} />, expanded: true }
	]);

	const [pathwayCompareData, setPathwayCompareData] = useState([
		{ label: "", value: (item: TransferValue) => <HtmlLink source={{ Label: item.Name, URL: appURL + "/pathway/" + item.Meta_Id }} cssClass="resourceName" /> },
		{ label: "", value: (item: TransferValue) => <HtmlLink source={item.OwnedByLabel} /> },
		{ label: "Description", value: (item: TransferValue) => <HtmlDiv source={item.Description || "Coming soon"} />, expanded: true }
	]);

	const [competencyFrameworkCompareData, setCompetencyFrameworkCompareData] = useState([
		{ label: "", value: (item: TransferValue) => <HtmlLink source={{ Label: item.Name, URL: appURL + "/competencyframework/" + item.Meta_Id }} cssClass="resourceName" /> },
		{ label: "", value: (item: TransferValue) => <HtmlLink source={item.OwnedByLabel} /> },
		{ label: "Description", value: (item: TransferValue) => <HtmlDiv source={item.Description || "Coming soon"} />, expanded: true }
	]);

	const [collectionCompareData, setCollectionCompareData] = useState([
		{ label: "", value: (item: TransferValue) => <HtmlLink source={{ Label: item.Name, URL: appURL + "/collection/" + item.Meta_Id }} cssClass="resourceName" /> },
		{ label: "", value: (item: TransferValue) => <HtmlLink source={item.OwnedByLabel} /> },
		{ label: "Description", value: (item: TransferValue) => <HtmlDiv source={item.Description || "Coming soon"} />, expanded: true }
	]);

	const [conceptSchemeCompareData, setConceptSchemeCompareData] = useState([
		{ label: "", value: (item: TransferValue) => <HtmlLink source={{ Label: item.Name, URL: appURL + "/conceptscheme/" + item.Meta_Id }} cssClass="resourceName" /> },
		{ label: "", value: (item: TransferValue) => <HtmlLink source={item.OwnedByLabel} /> },
		{ label: "Description", value: (item: TransferValue) => <HtmlDiv source={item.Description || "Coming soon"} />, expanded: true }
	]);

	const [competencyCompareData, setCompetencyCompareData] = useState([
		{ label: "", value: (item: Competency) => <HtmlLink source={{ Label: item.Name, URL: appURL + "/competency/" + item.CTID }} cssClass="resourceName" /> },
		{ label: "Description", value: (item: Competency) => <HtmlDiv source={item.CompetencyText || "Coming soon"} />, expanded: true }
	]);

	const [supportServiceCompareData, setSupportServiceCompareData] = useState([
		{ label: "", value: (item: Competency) => <HtmlLink source={{ Label: item.Name, URL: appURL + "/supportservice/" + item.CTID }} cssClass="resourceName" /> },
		{ label: "Description", value: (item: Competency) => <HtmlDiv source={item.CompetencyText || "Coming soon"} />, expanded: true }
	]);

	//Used to trick react later
	const [loaded, setLoaded] = useState(false);
	function onItemLoad() {
		setLoaded(!loaded);
	}

	//Used to remove items
	function removeItem(item: any) {
		removeCompareItem(item);
	}
	 
	var filteredSearchTypeOptions = mainSearchTypeOptions.filter(m => !doesWidgetHideThisSearchType(m.key)).filter(m => (m as any).key != "supportservice"
		&& (m as any).key != "outcomedata"
		&& (m as any).key != "job"
		&& (m as any).key != "occupation"
		&& (m as any).key != "task"
		&& (m as any).key != "workrole"
		&& (m as any).key != "conceptscheme"
/*		&& (m as any).key != "transfervalue"*/
		&& (m as any).key != "progressionmodel"
		&& (m as any).key != "competency");
	function setCurrentTabViaDropDown(option: any) {
		setCurrentTab(option.key);
	}

	//TODO: Fix the drop-down not reflecting the current tab
	console.log("current", { currentTab: currentTab, options: mainSearchTypeOptions });

	//Render the page structure
	return (
		<Wrapper>
			<Header>
				<Title>Compare</Title>
				<DropdownWrapper>
					<Dropdown
						ariaLabel="Compare Type"
						defaultOptionIndex={filteredSearchTypeOptions.indexOf(filteredSearchTypeOptions.find(m => m.key == currentTab) || filteredSearchTypeOptions[0])}
						options={filteredSearchTypeOptions}
						getOptionLabel={(option: MainSearchOption) => getCompareItemsByType(option.key).length + " " + widgetGetPluralLabelForText(option.value) }
						onChange={setCurrentTabViaDropDown}
					/>
				</DropdownWrapper>
				{/*<CompareTabButtons>
					<CompareTabButton onClick={setCurrentTab} currentTab={currentTab} broadType="credential" label="Credentials" icon={credentials_icon} source={rawCompareData.credential} />
					<CompareTabButton onClick={setCurrentTab} currentTab={currentTab} broadType="organization" label="Organizations" icon={organizations_icon} source={rawCompareData.organization} />
					<CompareTabButton onClick={setCurrentTab} currentTab={currentTab} broadType="assessment" label="Assessments" icon={assessments_icon} source={rawCompareData.assessment} />
					<CompareTabButton onClick={setCurrentTab} currentTab={currentTab} broadType="learningopportunity" label="Learning Opportunities" icon={learning_opportunities_icon} source={rawCompareData.learningopportunity} />
					<CompareTabButton onClick={setCurrentTab} currentTab={currentTab} broadType="transfervalue" label="Transfer Values" icon={transfer_values_icon} source={rawCompareData.transfervalue} />
					<CompareTabButton onClick={setCurrentTab} currentTab={currentTab} broadType="pathway" label="Pathways" icon={pathways_icon} source={rawCompareData.pathway} />
					<CompareTabButton onClick={setCurrentTab} currentTab={currentTab} broadType="competencyframework" label="Competency Frameworks" icon={competency_frameworks_icon} source={rawCompareData.competencyframework} />
					<CompareTabButton onClick={setCurrentTab} currentTab={currentTab} broadType="collection" label="Collections" icon={competency_frameworks_icon} source={rawCompareData.collection} />
					<CompareTabButton onClick={setCurrentTab} currentTab={currentTab} broadType="conceptscheme" label="Concept Schemes" icon={concept_schemes_icon} source={rawCompareData.conceptscheme} />
				</CompareTabButtons>*/}
				<CompareOptionButtons>
					<CompareOptionButton onClick={() => enterPrintMode()}>Print</CompareOptionButton>
					<CompareOptionButton onClick={() => downloadData()}>Download</CompareOptionButton>
					<CompareOptionButton onClick={() => getShareLink()}>Share</CompareOptionButton>
				</CompareOptionButtons>
			</Header>
			<Body>
				<CompareTabContent currentTab={currentTab} broadType="credential" properties={credentialCompareData as CompareDataProperty[]} update={() => setCredentialCompareData(credentialCompareData)} onItemLoad={onItemLoad} removeItem={removeItem} />
				<CompareTabContent currentTab={currentTab} broadType="organization" properties={organizationCompareData as CompareDataProperty[]} update={() => setOrganizationCompareData(organizationCompareData)} onItemLoad={onItemLoad} removeItem={removeItem} />
				<CompareTabContent currentTab={currentTab} broadType="assessment" properties={assessmentCompareData as CompareDataProperty[]} update={() => setAssessmentCompareData(assessmentCompareData)} onItemLoad={onItemLoad} removeItem={removeItem} />
				<CompareTabContent currentTab={currentTab} broadType="learningopportunity" properties={learningOpportunityCompareData as CompareDataProperty[]} update={() => setLearningOpportunityCompareData(learningOpportunityCompareData)} onItemLoad={onItemLoad} removeItem={removeItem} />
				<CompareTabContent currentTab={currentTab} broadType="transfervalue" properties={transferValueCompareData as CompareDataProperty[]} update={() => setTransferValueCompareData(transferValueCompareData)} onItemLoad={onItemLoad} removeItem={removeItem} />
				<CompareTabContent currentTab={currentTab} broadType="pathway" properties={pathwayCompareData as CompareDataProperty[]} update={() => setPathwayCompareData(pathwayCompareData)} onItemLoad={onItemLoad} removeItem={removeItem} />
				<CompareTabContent currentTab={currentTab} broadType="competencyframework" properties={competencyFrameworkCompareData as CompareDataProperty[]} update={() => setCompetencyFrameworkCompareData(competencyFrameworkCompareData)} onItemLoad={onItemLoad} removeItem={removeItem} />
				<CompareTabContent currentTab={currentTab} broadType="collection" properties={collectionCompareData as CompareDataProperty[]} update={() => setCollectionCompareData(collectionCompareData)} onItemLoad={onItemLoad} removeItem={removeItem} />
				<CompareTabContent currentTab={currentTab} broadType="conceptscheme" properties={conceptSchemeCompareData as CompareDataProperty[]} update={() => setConceptSchemeCompareData(conceptSchemeCompareData)} onItemLoad={onItemLoad} removeItem={removeItem} />
				<CompareTabContent currentTab={currentTab} broadType="competency" properties={competencyCompareData as CompareDataProperty[]} update={() => setCompetencyCompareData(competencyCompareData)} onItemLoad={onItemLoad} removeItem={removeItem} />
				<CompareTabContent currentTab={currentTab} broadType="supportservice" properties={supportServiceCompareData as CompareDataProperty[]} update={() => setSupportServiceCompareData(supportServiceCompareData)} onItemLoad={onItemLoad} removeItem={removeItem} />
				{/*<CompareTabContent currentTab={currentTab} broadType="competency" properties={competencyCompareData as CompareDataProperty[]} update={() => setCompetencyCompareData(competencyCompareData)} onItemLoad={onItemLoad} removeItem={removeItem} />*/}
				<PrintContent label="Credentials" broadType="credential" properties={credentialCompareData as CompareDataProperty[]} />
				<PrintContent label="Organizations" broadType="organization" properties={organizationCompareData as CompareDataProperty[]} />
				<PrintContent label="Assessments" broadType="assessment" properties={assessmentCompareData as CompareDataProperty[]} />
				<PrintContent label="Learning Opportunities" broadType="learningopportunity" properties={learningOpportunityCompareData as CompareDataProperty[]} />
				<PrintContent label="Transfer Values" broadType="transfervalue" properties={transferValueCompareData as CompareDataProperty[]} />
				<PrintContent label="Pathways" broadType="pathway" properties={pathwayCompareData as CompareDataProperty[]} />
				<PrintContent label="Competency Frameworks" broadType="competencyframework" properties={competencyFrameworkCompareData as CompareDataProperty[]} />
				<PrintContent label="Collections" broadType="collection" properties={collectionCompareData as CompareDataProperty[]} />
				<PrintContent label="Concept Schemes" broadType="conceptscheme" properties={conceptSchemeCompareData as CompareDataProperty[]} />
				<PrintContent label="Competencies" broadType="competency" properties={competencyCompareData as CompareDataProperty[]} />
				<PrintContent label="Support Services" broadType="supportservice" properties={supportServiceCompareData as CompareDataProperty[]} />
				{/*<PrintContent label="Competencies" broadType="competency" properties={competencyCompareData as CompareDataProperty[]} />*/}
			</Body>
		</Wrapper>
	)
}

function CompareTabButton(props: { broadType: string, currentTab: string, label: string, icon: string, source: any[], onClick: Dispatch<SetStateAction<string>> }) {
	var isHidden = (window as any).Widget?.Config?.HideGlobalFilters?.filter((m: any) => m.toLowerCase().includes(props.broadType.toLowerCase()))[0] != null;
	return isHidden ? null : (
		<StyledCompareTabButton onClick={() => props.onClick(props.broadType)} className={props.currentTab == props.broadType ? "currentTab" : ""}>
			<img alt="" src={props.icon} />
			<span>{props.label}</span>
		</StyledCompareTabButton>
	)
}

function CompareTabContent(props: { broadType: string, currentTab: string, properties: CompareDataProperty[], update: (data: CompareDataProperty[]) => void, onItemLoad: () => void, removeItem: (item: any) => void }) {
	const [expanded, setExpanded] = useState(false);
	const [sourceData, setSourceData] = useState(getCompareItemsByType(props.broadType) as Array<any>);

	useEffect(() => {
		//updateStateOnCompareChange(() => { setSourceData(getCompareItemsByType(props.broadType)); }); //Don't uncomment this, it causes the page to infinite loop itself to death
		setSourceData(getCompareItemsByType(props.broadType));
	}, []);

	function toggleCollapse(property: CompareDataProperty) {
		//Toggle whether this is expanded
		property.expanded = !property.expanded;
		//Update it in the state
		props.update(props.properties);
		//Trick react into re-rendering the component
		setExpanded(!expanded);
	}

	return (
		<CompareTableWrapper className={props.currentTab == props.broadType ? "currentTab" : ""}>
			{
				(!sourceData || sourceData.length == 0) &&
				<NoCompareData>Nothing selected to compare</NoCompareData>
			}
			<CompareTable>
				<tbody>
					{props.properties.map((property, propertyIndex) => (
						<tr key={"compare-tr-" + propertyIndex}>
							{sourceData.map((item, sourceIndex) => (
								<>
									{
										//If it's not loaded yet and this is the first row, use a single table cell with an appropriate rowspan to contain the loader
										propertyIndex == 0 &&
										!item.Meta_IsLoaded &&
										<td key={"compare-td-" + sourceIndex} className="loading" rowSpan={props.properties.length}>
											<LoadCompareItem source={item} onItemLoad={() => { setSourceData(getCompareItemsByType(props.broadType)); }} />
										</td>
									}
									{
										//Otherwise render as normal
										item.Meta_IsLoaded &&
										<td key={"compare-td-" + sourceIndex} className={(property.expanded || !property.label) ? "expanded" : ""}>
											{
												property.label &&
												<CollapseButton
													onClick={() => { toggleCollapse(property); }}
												>
													<span>{property.label}</span>
													<FontAwesomeIcon icon={property.expanded ? faAngleDown : faAngleRight} />
												</CollapseButton>
											}
											<CollapseContent className={(propertyIndex == 0 ? "withCloseButton " : "") + ((property.expanded || !property.label) ? "expanded" : "")}>
												{property.value(item)}
												{propertyIndex == 0 && <RemoveItemButton onClick={() => { removeCompareItem(item); setSourceData(getCompareItemsByType(props.broadType)); }} aria-label="Remove this item"><FontAwesomeIcon icon={faTimes} /></RemoveItemButton>}
											</CollapseContent>
										</td>
									}
								</>
							))}
						</tr>
					))}
				</tbody>
			</CompareTable>
		</CompareTableWrapper>
	) || null
}

function PrintContent(props: { label: string; broadType: string, properties: CompareDataProperty[] }) {
	var sourceData = getCompareItemsByType(props.broadType) as Array<any>;

	return (
		sourceData.length > 0 &&
		<PrintList>
			<PrintHeader>{props.label}</PrintHeader>
			{sourceData.map((item, sourceIndex) => (
				<PrintListItem key={"print-item-" + sourceIndex}>
					{props.properties.map((property, propertyIndex) => (
						<div key={"print-item-property-" + propertyIndex}>
							{property.label && <div><b>{property.label}</b></div>}
							{property.value(item)}
						</div>
					))}
				</PrintListItem>
			))}
		</PrintList>
	) || null
}

function CostProfileData(props: { source: CostProfile }) {
	return (
		<CostProfileWrapper>
			<div>
				{props.source.Name && <CostProfileName>{props.source.Name}:</CostProfileName>}
				{props.source.Description && <div>{props.source.Description}</div>}
			</div>
			<div>
				{props.source.CostItems?.map((item) => (
					<CostProfileItem>
						<CostProfileItemDetails>
							<div>{item.DirectCostType?.Label}</div>
							<div>{(item.AudienceType || []).concat(item.ResidencyType || []).map((type) => { return type.Label }).join(", ")}</div>
							<div>{item.PaymentPattern}</div>
						</CostProfileItemDetails>
						<CostProfileItemPrice>{formatPrice(item.Price || 0, props.source.CurrencySymbol || "")}</CostProfileItemPrice>
					</CostProfileItem>
				))}
			</div>
		</CostProfileWrapper>
	)
}

function PlaceData(props: { source: Place }) {
	return (
		<PlaceWrapper>
			{(props.source.Latitude != 0 && props.source.Longitude != 0) && <a className="placeName" href={"https://www.google.com/maps/search/?api=1&query=" + props.source.Latitude + "," + props.source.Longitude} target="_blank">{props.source.Name || "Unnamed Location"}</a>}
			{(props.source.Latitude == 0 || props.source.Longitude == 0) && <div className="placeName">{props.source.Name || "Unnamed Location"}</div>}
			<div className="streetAddress">{props.source.StreetAddress || "Unknown Street Address"}</div>
			<div className="cityState">{props.source.AddressLocality || "Unknown City"}, {props.source.AddressRegion || "Unknown State/Province/Region"} {props.source.PostalCode || "Unknown Postal Code"}</div>
			<div className="contactInfoList">
				{props.source.TargetContactPoint?.map((contact: ContactPoint) => {
					<div className="contactInfoItem">
						<div className="contactInfoTitle">{contact.Name || contact.ContactType || "Unknown Contact Name/Type"}</div>
						{contact.Telephone?.length > 0 && <div className="contactInfoPhoneList">{contact.Telephone.map((phone: string) => { <div className="contactInfoPhoneListItem">{phone}</div> })}</div>}
						{contact.SocialMedia?.length > 0 && <div className="contactSocialMediaList">{contact.SocialMedia.map((social: string) => { <a className="contactInfoSocialMediaListItem" href={social} target="_blank">{social}</a> })}</div>}
					</div>
				})}
			</div>
		</PlaceWrapper>
	)
}

function ReferenceFrameworkItem(props: { source: ReferenceFramework }) {
	return (
		<ReferenceFrameworkWrapper>
			<div>{props.source.Label || "Unnamed Reference"}</div>
			{props.source.URL && <PillLink href={props.source.URL} title={"Search for similar resources in the registry with this code"} label="" rightIcon={<FontAwesomeIcon icon={faSearch} size="sm" />} />}
			{props.source.TargetNode && <PillLink href={props.source.TargetNode} title="Learn more about this code (External Link)" label="" rightIcon={<FontAwesomeIcon icon={faExternalLinkAlt} size="sm" />} target="_blank" />}
		</ReferenceFrameworkWrapper>
	)
}

function ConditionProfileData(props: { source: ConditionProfile }) {
	return (
		<ConditionProfileWrapper>
			{props.source.Name && <div className="conditionProfileName">{props.source.Name}</div>}
			{props.source.Description && <div className="conditionProfileDescription">{props.source.Description}</div>}
			{props.source.Condition && <ul className="conditionProfileCondition">{props.source.Condition.map(m => <li>{m}</li>)}</ul>}
		</ConditionProfileWrapper>
	)
}

function OutcomeSummaryListItem(props: { source: AggregateDataProfile }) {
	var adpQValues = (props.source.JobsObtainedList || []);
	appendQValue(adpQValues, props.source.LowEarnings, "Lower End Earnings", props.source.CurrencySymbol);
	appendQValue(adpQValues, props.source.MedianEarnings, "Median Earnings", props.source.CurrencySymbol);
	appendQValue(adpQValues, props.source.HighEarnings, "Higher End Earnings", props.source.CurrencySymbol);
	var dataProfile = ({
		Description: "Summary Information",
		StartDate: props.source.DateEffective,
		EndDate: props.source.ExpirationDate,
		DataAvailable: adpQValues
	} as any) as DataProfile;

	return (
		<OutcomeSummaryItemWrapper>
			{props.source.Name && <div className="aggregateDataProfileName">{props.source.Name}</div>}
			{props.source.Description && <div className="aggregateDataProfileDescription">{props.source.Description}</div>}
			{props.source.DateEffective && props.source.ExpirationDate && <div className="dateRange">{props.source.DateEffective} - {props.source.ExpirationDate}</div>}
			{props.source.DateEffective && !props.source.ExpirationDate && <div className="dateRange">Beginning {props.source.DateEffective}</div>}
			{!props.source.DateEffective && props.source.ExpirationDate && <div className="dateRange">Ending {props.source.ExpirationDate}</div>}
			{props.source.PostReceiptMonths && <div className="postReceiptMonths">Value(s) {props.source.PostReceiptMonths} Months after earning the Credential:</div>}
			<QuantitativeValueList source={adpQValues} cssClass="quantitativeValueList" />
			{props.source.RelevantDataSet && <div className="dataSetProfileList">{props.source.RelevantDataSet.map((dsp: DataSetProfile) => (
				<div className="dataSetProfile">
					{dsp.Name && <div className="dspName">{dsp.Name}</div>}
					{dsp.Description && <div className="dspDescription">{dsp.Description}</div>}
					{dsp.DataSetTimePeriod?.length > 0 && <div className="dataSetTimePeriod">{dsp.DataSetTimePeriod.map((dstf: DataSetTimeFrame) => (
						<div className="dataSetTimeFrame">
							{dstf.StartDate && dstf.EndDate && <div className="dateRange">{dstf.StartDate} - {dstf.EndDate}</div>}
							{dstf.StartDate && !dstf.EndDate && <div className="dateRange">Beginning {dstf.StartDate}</div>}
							{!dstf.StartDate && dstf.EndDate && <div className="dateRange">Ending {dstf.EndDate}</div>}
							{dstf.DataAttributes?.length > 0 && <div className="dataAttributes">{dstf.DataAttributes.map((dp: DataProfile) => (
								<div className="dataProfile">
									{dp.Description && <div className="dpDescription">{dp.Description}</div>}
									{Object.keys(dp).filter(m => Array.isArray((dp as any)[m]) && ((dp as any)[m] as any).length > 0).map((property: string) => (
										<QuantitativeValueList source={(dp as any)[property] as Array<QuantitativeValue>} cssClass="quantitativeValueList" />
									))}
								</div>
							))}</div>}
						</div>
					))}</div>}
				</div>
			))}</div>}
		</OutcomeSummaryItemWrapper>
	)

	//Helper function
	function appendQValue(list: Array<QuantitativeValue>, value: number, description: string, currencySymbol: string) {
		if (value) {
			list.push({
				Description: description,
				CurrencySymbol: currencySymbol,
				Value: value
			} as QuantitativeValue);
		}
	}
}

function QuantitativeValueList(props: { source: QuantitativeValue[], cssClass: string }) {
	return props.source?.length > 0 && (
		<div className={props.cssClass}>
			{props.source.map((qvalue: QuantitativeValue) => (
				<div className="quantitativeValue">
					<div className="quantitativeValueDescription">{qvalue.Description || "Unnamed Value"}</div>
					<div className="quantitativeValueValue">{qvalue.CurrencySymbol}{qvalue.Value ? qvalue.Value : (qvalue.MinValue && qvalue.MaxValue) ? (qvalue.MinValue + " - " + qvalue.MaxValue) : qvalue.MinValue ? ("At least " + qvalue.MinValue) : qvalue.MaxValue ? ("Up to " + qvalue.MaxValue) : qvalue.Percentage ? (qvalue.Percentage + "%") : "Unknown"}</div>
				</div>
			))}
		</div>
	) || null
}

function LoadCompareItem(props: { source: any, onItemLoad: () => void }) {
	//Get a matching item so we can ensure it exists, and also manipulate it
	var item = { ...props.source };

	//Prevent infinite loop crash if an item fails to load
	//Need to overhaul compare page to negate the need to do this
	(window as any).CompareAntiCrash = (window as any).CompareAntiCrash || [];
	var stringItem = JSON.stringify(item);
	var testItem = (window as any).CompareAntiCrash.find((m: any) => m.Item == stringItem);
	if (testItem) {
		testItem.Count++;
		if (testItem.Count > 10) {
			console.log("Unknown infinite loop error. Gave up trying to load item:", item);
			item.Meta_IsError = true;
			item.Meta_IsLoading = false;
		}
	}
	else {
		(window as any).CompareAntiCrash.push({ Item: stringItem, Count: 0 });
	}

	//If loaded or error, return
	if (item.Meta_IsLoaded || item.Meta_IsError) {
		return <div>Error loading data.</div>
	}

	//Fetch the item
	item.Meta_IsLoading = true;
	addOrUpdateCompareItem(item);
	getResourceByURLWithoutState(apiUrl + "/compare/" + props.source.BroadType + "/" + props.source.Meta_Id, null, (response: any) => {
		console.log("Response", response);
		if (response.Successful) {
			response.Result.Meta_IsLoaded = true;
			addOrUpdateCompareItem(response.Result);
			props.onItemLoad();
		}
		else {
			item.Meta_IsError = true;
			addOrUpdateCompareItem(item);
		}
	}, (error: any) => {
		item.Meta_IsError = true;
		addOrUpdateCompareItem(item);
		console.log("Error getting resource", { source: props.source, error: error });
	});

	//Loading
	return <ActivityIndicator />
}

function getRawCompareData() {
	var rawCompareData = {} as any;
	rawCompareData.credential = getCompareItemsByType("credential");
	rawCompareData.organization = getCompareItemsByType("organization");
	rawCompareData.assessment = getCompareItemsByType("assessment");
	rawCompareData.learningopportunity = getCompareItemsByType("learningopportunity");
	rawCompareData.transfervalue = getCompareItemsByType("transfervalue");
	rawCompareData.pathway = getCompareItemsByType("pathway");
	rawCompareData.competencyframework = getCompareItemsByType("competencyframework");
	rawCompareData.collection = getCompareItemsByType("collection");
	rawCompareData.conceptscheme = getCompareItemsByType("conceptscheme");

	console.log("Loaded raw compare data", rawCompareData);
	return rawCompareData;
}


function setRawCompareData(rawCompareData: any, flag: string) {
	console.log("Setting raw compare data via " + flag, rawCompareData);
	window.localStorage.setItem(BUCKET_STORAGE, JSON.stringify(rawCompareData));
}

function enterPrintMode() {
	window.print();
}

function downloadData() {
	//Setup the container
	var data = getRawCompareData();
	var header = ["Type", "CTID", "URL", "Name", "CTDL Type", "Description", "Audience Types", "Audience Levels", "Industries", "Occupations", "Estimated Costs", "Estimated Time to Earn/Complete", "Financial Assistance Types", "Locations", "Entry Requirements", "Completion Requirements", "Delivery Types", "Assessment Method Types", "Learning Method Types", "Required for Credentials", "Organization Types", "Organization Sectors", "Service Types", "Outcomes Summary"];
	var csv = [header];

	//Read the data
	readItems("Credential", "/credential/", data.credential);
	readItems("Organization", "/organization/", data.organization);
	readItems("Assessment", "/assessment/", data.assessment);
	readItems("Learning Opportunity", "/learningopportunity/", data.learningopportunity);
	readItems("Transfer Value", "/transfervalue/", data.transfervalue);
	readItems("Pathway", "/pathway/", data.pathway);
	readItems("PathwaySet", "/pathwayset/", data.pathwayset);
	readItems("Competency Framework", "/competencyframework/", data.competencyframework);
	readItems("Collection", "/collection/", data.collection);
	readItems("Concept Scheme", "/conceptscheme/", data.conceptscheme);

	//Convert to CSV file
	var csvText = csv.map((row) => {
		var rowText = JSON.stringify(row);
		return rowText.substring(1, rowText.length - 1);
	}).join("\n");

	//Return the file
	var blob = new Blob([csvText], { type: "text/csv;charset=utf-8" });
	var blobURL = URL.createObjectURL(blob);
	var link = document.createElement("a");
	link.href = blobURL;
	link.download = "Comparison Data.csv";
	link.target = "_blank";
	document.body.appendChild(link);
	link.click();

	function readItems(label: string, urlPart: string, items: any[]) {
		(items || []).filter(m => m).forEach((item: any) => {
			var rowData = [
				label,
				item.CTID || "No CTID available for reference entities",
				appURL + urlPart + item.Meta_Id,
				item.Name,
				item.CTDLTypeLabel,
				item.Description,
				flattenLinks(item.AudienceType),
				flattenLinks(item.AudienceLevelType),
				flattenLinks(item.IndustryType),
				flattenLinks(item.OccupationType),
				flattenCosts(item.EstimatedCost),
				(item.EstimatedDuration || []).map((duration: any) => { return duration.DurationSummary }).join(" | "),
				flattenLinks((item.FinancialAssistance || [] as Array<any>).flatMap((financial: any) => { return financial.FinancialAssistanceType }).filter((m: any) => m)),
				flattenPlaces(item.AvailableAt || item.Address),
				flattenConditionProfiles(item.EntryCondition, true, true, false),
				flattenConditionProfiles(item.Requires, true, true, false),
				flattenLinks(item.DeliveryType),
				flattenLinks(item.AssessmentMethodType),
				flattenLinks(item.LearningMethodType),
				flattenConditionProfiles(item.IsRequiredFor, false, false, true),
				flattenLinks(item.AgentType),
				flattenLinks(item.AgentSectorType),
				flattenLinks(item.ServiceType),
				flattenOutcomes(item.AggregateData, item.ExternalDataSetProfiles)
			];
			rowData.forEach((item: any) => {
				item = (item || "").replace(/"/g, '""');
				item = (item.indexOf(",") > -1 || item.indexOf("\r\n") > -1 || item.indexOf("\n")) ? '"' + item + '"' : item;
			});
			csv.push(rowData);
		});
	}

	function flattenLinks(links: any[]) {
		return (links || []).map((link) => { return link.Label }).join(" | ");
	}

	function flattenCosts(costs: any[]) {
		return (costs || []).map((costProfile: any) => {
			return costProfile.CostItems?.map((costItem: any) => {
				return costItem.DirectCostType?.Label + ": " + formatPrice(costItem.Price || 0, costProfile.CurrencySymbol || "")
			}).join(" / ");
		}).join(" | ");
	}

	function flattenPlaces(places: any[]) {
		return (places || []).map((place: any) => {
			return [place.StreetAddress, place.AddressLocality, place.AddressRegion, place.PostalCode].filter(m => m).join(", ")
		}).join(" | ");
	}

	function flattenConditionProfiles(conditionProfiles: any[], includeDescription: boolean, includeCondition: boolean, includeTargetCredentials: boolean) {
		return (conditionProfiles || []).flatMap((conditionProfile: any) => {
			return [
				(includeDescription && conditionProfile.Description),
				(includeCondition && conditionProfile.Condition),
				(
					(includeTargetCredentials && conditionProfile.TargetCredential) &&
					((Array.isArray(conditionProfile.TargetCredential) ?
						conditionProfile.TargetCredential :
						[conditionProfile.TargetCredential]) as Array<any>)
					.flatMap((item: any) => item.Name || item.Label)
				)
			].flatMap(m => m).filter(m => m).join("; ");
		}).join(" | ");
	}

	function flattenOutcomes(aggregateData: any[], externalData: []) {
		var combined = combineOutcomesData(aggregateData as AggregateDataProfile[], externalData as DataSetProfile[]);
		var container = [] as Array<any>;
		(combined || []).map((adp: any) => {
			adp.Description && container.push(adp.Description);
			(adp.RelevantDataSet || []).forEach((dsp: any) => {
				dsp.Description && container.push(dsp.Description);
				(dsp.DataSetTimePeriod || []).forEach((dstf: any) => {
					(dstf.DataAttributes || []).forEach((dp: any) => {
						Object.keys(dp).filter(m => Array.isArray((dp as any)[m]) && ((dp as any)[m] as any).length > 0).map((property: string) => {
							dp[property].forEach((qvalue: any) => {
								var label = qvalue.Description || "Unnamed Value";
								var value = "";
								var currency = qvalue.CurrencySymbol || "";
								(qvalue.MinValue && qvalue.MaxValue) && (value += currency + qvalue.MinValue + " - " + currency + qvalue.MaxValue);
								(qvalue.MinValue && !qvalue.MaxValue) && (value += "At least " + currency + qvalue.MinValue);
								(!qvalue.MinValue && qvalue.MaxValue) && (value += "Up to " + currency + qvalue.MaxValue);
								(qvalue.Value && !value) && (value += currency + qvalue.Value);
								qvalue.Percentage && (value += qvalue.Percentage + "%");
								if (value) {
									container.push(label + ": " + value);
								}
							});
						});
					});
				});
			});
		})
		return container.filter(m => m).join("; ");
	}
}

function getShareLink() {
	var params = [] as Array<string>;
	["credential", "organization", "assessment", "learningopportunity", "transfervalue", "pathway", "competencyframework", "collection", "conceptscheme"].forEach(type => {
		var items = getCompareItemsByType(type);
		if (items.length > 0) {
			params.push("share_" + type + "=" + items.map((item: any) => item.Meta_Id).join(","));
		}
	});
	var link = window.location.href + (window.location.search.indexOf("?") > -1 ? "&" : "?") + params.join("&");
	prompt("Copy and paste this URL", link);
	return;

	function getIDs(param: string, items: any[]) {
		var ids = (items || []).map((item: any) => { return item.Meta_Id }).filter((item: any) => { return item });
		return ids.length > 0 ? param + "=" + ids.join(",") : null;
	}
}
//

function getCompareDataFromShareLink() {
	var params = new URLSearchParams(window.location.search);
	["credential", "organization", "assessment", "learningopportunity", "transfervalue", "pathway", "competencyframework", "collection", "conceptscheme"].forEach(type => {
		var key = Array.from(params.keys()).find((key: string) => key.toLowerCase() == "share_" + type);
		if (key) {
			(params.get(key) || "").split(",").forEach(id => {
				var item = { BroadType: type, Meta_Id: id, CTID: "temp-" + getUUID(), Name: "Loading..." } as any;
				//addCompareItem(item); //Don't do this, it causes the page to self-destruct

				getResourceByURLWithoutState(apiUrl + "/compare/" + type + "/" + id, null, (response: any) => {
					console.log("Response", response);
					if (response.Successful) {
						response.Result.Meta_IsLoaded = true;
						addCompareItem(response.Result);
					}
					else {
						item.Meta_IsError = true;
						addCompareItem(item);
					}
				}, (error: any) => {
					item.Meta_IsError = true;
					addCompareItem(item);
					console.log("Error getting resource from share link", { source: item, error: error });
				});

			});
		}
	});
}
//

//Comparison Components
function HtmlLink(props: { source: Partial<Link>, cssClass?: string }) {
	return (props.source && <a className={props.cssClass} href={props.source.URL} target="_blank">{props.source.Label}</a>) || NoItemData()
}

function HtmlDiv(props: { source: string, cssClass?: string }) {
	return (props.source && <div className={props.cssClass}>{props.source}</div>) || null
}

function LinksAsPlainList(props: { source: Link[], cssClass?: string }) {
	return (props.source?.length > 0 && <ul className={props.cssClass}>{props.source.map((item, index) => <li key={"listkey-" + index}>{item.Label}</li>)}</ul>) || NoItemData()
}

function OutlinesAsLinkList(props: { source: Outline[], cssClass?: string, showType?: boolean }) {
	return (props.source?.length > 0 && <ul className={props.cssClass}>{props.source.map((item, index) => <li key={"listkey-" + index}><a href={item.URL} target="_blank">{item.Label}</a> {props.showType && "(" + item.Tags?.[0]?.Label + ")"}</li>)}</ul>) || NoItemData()
}

function CostProfileList(props: { source: CostProfile[], cssClass?: string }) {
	return (props.source?.length > 0 && <div className={props.cssClass}>{props.source.map((profile, index) => <CostProfileData source={profile} key={"costprofile-" + index} />)}</div>) || NoItemData()
}

function DurationProfileList(props: { source: DurationProfile[], cssClass?: string }) {
	return (props.source?.length > 0 && <div className={props.cssClass}>{props.source.map((profile, index) => <DurationProfileWrapper key={"durationprofile-" + index}>{profile.DurationSummary}</DurationProfileWrapper>)}</div>) || NoItemData()
}

function PlaceList(props: { source: Place[], cssClass?: string }) {
	return (props.source?.length > 0 && <div className={props.cssClass}>{props.source.map((profile, index) => <PlaceData key={"place-" + index} source={profile} />)}</div>) || NoItemData()
}

function ConditionProfileList(props: { source: ConditionProfile[], cssClass?: string }) {
	return (props.source?.length > 0 && <div className={props.cssClass}>{props.source.map((profile, index) => <ConditionProfileData key={"conditionprofile-" + index} source={profile} />)}</div>) || NoItemData()
}

function ReferenceFrameworkList(props: { source: ReferenceFramework[], cssClass?: string }) {
	if (!props.source || props.source.length == 0) {
		return NoItemData();
	}

	var groups = [] as Array<any>;
	props.source.forEach((item: ReferenceFramework) => {
		var match = groups.find((m: any) => m.Framework == item.Framework || m.FrameworkName == item.FrameworkName);
		if (!match) {
			match = { Framework: item.Framework, FrameworkName: item.FrameworkName || "Unnamed Framework", Items: [item] };
			groups.push(match);
		}
		else {
			match.Items.push(item);
		}
	});

	return (
		<div className={props.cssClass}>
			{groups.map((group, index) => (
				<div className="referenceFrameworkSet">
					<div className="referenceFrameworkLabel">{group.Framework ? <a href={group.Framework} target="_blank">{group.FrameworkName}</a> : <div>{group.FrameworkName}</div>}</div>
					<div className="referenceFrameworkItemList">
						{group.Items.map((item: ReferenceFramework, index: number) => <ReferenceFrameworkItem source={item} />)}
					</div>
				</div>
			))}
		</div>
	)
}

function OutcomeSummaryList(props: { aggregateData: AggregateDataProfile[], externalData: DataSetProfile[], cssClass?: string }) {
	if ((props.aggregateData || []).length == 0 && (props.externalData || []).length == 0) {
		return NoItemData();
	}

	var combined = combineOutcomesData(props.aggregateData, props.externalData);

	return (combined.length > 0 && <div className={props.cssClass}>{combined.map((profile, index) => <OutcomeSummaryListItem key={"outcome-" + index} source={profile as AggregateDataProfile} />)}</div>) || NoItemData();
}

function combineOutcomesData( aggregateData: AggregateDataProfile[], externalData: DataSetProfile[]) : Array<any> {
	var combined = (aggregateData || []) as Array<any>;
	var fake = { RelevantDataSet: [] } as any;
	externalData?.forEach((dsp: DataSetProfile) => {
		if (!combined.find(m => m.RelevantDataSet?.find((n: DataSetProfile) => n.CTID))) {
			fake.RelevantDataSet.push(dsp);
		}
	});
	if (fake.RelevantDataSet.length > 0) {
		combined.push(fake);
	}

	return combined;
}

function NoItemData() {
	return <NoData>No Data</NoData>
}

//Styled Components
const Wrapper = styled.div`
	min-height: 50vh;
`;

const Header = styled.div`
	display: flex;
	align-items: stretch;
	color: ${(props) => widgetGetColor("SiteHeader", true, props.theme.color.aquaDark)};
    background-color: ${(props) => widgetGetColor("SiteHeader", false, props.theme.color.blueDark)};

	@media(max-width: 1000px) {
		flex-wrap: wrap;
	}

	@media print {
		display: none;
	}
`;

const Body = styled.div`
	& ul {
		margin: 0;
	}
`;

const Title = styled.div`
	font-weight: bold;
	font-size: 24px;
	padding: 20px;

	@media(max-width: 1000px) {
		order: 1;
	}
`;

const CompareTabButtons = styled.div`
	display: flex;
	align-items: stretch;

	@media(max-width: 1000px) {
		order: 3;
	}

	@media(max-width: 675px){
		display: block;
		margin: 0;
		width: 100%;
	}
`;

const StyledCompareTabButton = styled.button`
	flex: 1 1 10%;
	display: flex;
	flex-direction: column;
	padding: 10px 20px;
	background-color: transparent;
	border: none;
	color: inherit;
	border-bottom: 2px solid transparent;

	&:hover, &:focus {
		background-color: rgba(255, 255, 255, 0.2);
	}

	&.currentTab {
		opacity: 1;
		font-weight: bold;
		border-color: ${(props) => widgetGetColor("SiteHeader", true, props.theme.color.aquaDark)};
	}

	& img {
		display: block;
		width: 30px;
		margin: 0 auto 5px auto;
	}

	& > span {
		margin: 0 auto;
		font-size: 14px;
		line-height: 1.3em;
	}

	@media(max-width: 675px){
		width: 100%;
		text-align: left;
		border-bottom: none;
		border-left: 5px solid transparent;
	}
`;

const CompareOptionButtons = styled.div`
	display: flex;
	align-items: stretch;
	padding: 5px;
	margin-left: auto;

	@media(max-width: 1000px) {
		order: 2;
	}
`;

const CompareOptionButton = styled.button`
	display: flex;
	align-items: center;
	padding: 10px;
	background-color: transparent;
	border: none;
	color: #FFF;

	&:hover, &:focus {
		background-color: rgba(255, 255, 255, 0.2);
	}
`;

const CompareTableWrapper = styled.div`
	overflow-x: auto;

	&:not(.currentTab) {
		display: none;
	}

	@media print {
		display: none;
	}
`;

const CompareTable = styled.table`
	border-collapse: collapse;

	& tr {
		
	}

	& td {
		min-width: 400px;
		padding: 0;
		vertical-align: top;
		border: 1px solid ${(props) => widgetGetColor("SiteHeader", false, props.theme.color.aquaDark)};
		border-width: 1px 3px;
		width: 10%;
	}

	& td.loading {
		text-align: center;
		padding: 20px;
	}

	& .resourceName {
		text-align: center;
		font-weight: bold;
	}
`;

const CollapseButton = styled.button`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 5px 10px;
	font-weight: bold;
	background-color: transparent;
	border: none;

	& svg {
		margin-left: auto;
	}

	&:hover, &:focus {
		color: ${(props) => widgetGetColor("SearchResultButton", true, "inherit")};
		background-color: ${(props) => widgetGetColor("SearchResultButton", false, props.theme.color.aquaLight)};
	}
`;

const CollapseContent = styled.div`
	padding: 5px 10px;
	overflow: auto;
	max-height: 30vh;
	font-size: 85%;

	&:empty {
		display: none;
	}

	&:not(.expanded) {
		display: none;
	}

	&.withCloseButton {
		display: flex;
		font-size: 16px;
	}
`;

const CostProfileWrapper = styled.div`
	padding: 5px 0;

	&:not(:last-child) {
		border-bottom: 1px solid #CCC;
	}
`;

const CostProfileName = styled.div`
	font-weight: bold;
	margin-right: 10px;
`;

const CostProfileItem = styled.div`
	display: flex;
	align-items: baseline;
	padding-left: 10px;
	border-top: 1px dashed #CCC;
`;

const CostProfileItemDetails = styled.div`
	margin-right: 10px;
`;

const CostProfileItemPrice = styled.div`
	margin-left: auto;
	font-weight: bold;
`;

const DurationProfileWrapper = styled.div`

`;

const PlaceWrapper = styled.div`

`;

const OutcomeSummaryItemWrapper = styled.div`
	margin-bottom: 10px;
	& .aggregateDataProfileName { font-weight: bold; }
	& .dateRange { font-weight: bold; } 
	& .dataSetProfileList .dspName { font-weight: bold; }
	& .dataSetProfileList .dataSetTimeFrame { margin-bottom: 10px; }
	& .quantitativeValue { display: flex; align-items: baseline; gap: 10px; }
	& .quantitativeValueList:not(:first-child) { border-top: 1px dashed #CCC; }
	& .quantitativeValue:not(:first-child) { border-top: 1px dashed #CCC; }
	& .quantitativeValueValue { margin-left: auto; min-width: 50px; text-align: right; }
`;

const ReferenceFrameworkWrapper = styled.div`
	display: flex;
	align-items: baseline;
	gap: 5px;
	padding: 0 10px;

`;

const ConditionProfileWrapper = styled.div`
	margin-bottom: 10px;

	& .conditionProfileName {
		font-weight: bold;
	}
`;

const NoCompareData = styled.div`
	padding: 50px;
	text-align: center;
	font-weight: bold;
	font-size: 24px;
`;

const NoData = styled.div`
	text-align: center;
	font-style: italic;
	margin-bottom: 10px;
`;

const PrintList = styled.div`
	&:not(:last-child) {
		border-bottom: 1px solid #AAA;
	}
	
	@media not print {
		display: none;
	}
`;

const PrintListItem = styled.div`
	font-size: 85%;
	padding: 25px 0;

	& .resourceName {
		font-size: 16px;
		font-weight: bold;
	}

	&:not(:last-child) {
		border-bottom: 1px solid #CCC;
	}

	& div {
		page-break-inside: avoid;
	}
`;

const PrintHeader = styled.div`
	font-size: 18px;
	font-weight: bold;
	padding: 5px 0;
`;

const RemoveItemButton = styled.button`
	flex: 0 0 30px;
	background-color: transparent;
	border: none;
	margin-left: auto;
	font-size: 18px;
	height: 30px;

	&:hover, &:focus {
		background-color: ${(props) => props.theme.color.aquaLight};
	}
`;

const DropdownWrapper = styled.div`
	width: 300px;
	margin: 18px 0;
`;