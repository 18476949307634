import React from 'react';
import { ModalType } from '../../types/internal';
import { Organization, Credential, Assessment, LearningOpportunity } from '../../types/external';
import PageSection from '../PageSection';
import PageSectionItem from '../PageSectionItem';
import qaIcon from '../../assets/images/icons/icon-quality-assurance-blue-green-01.svg';
import { Section } from './styles';
import { ModalButtonAndWindow } from '../Modal';
import OutlineList from './Outline';
import HasAnyFilled from '../HasAnyFilled';
import JurisdictionAssertions from './JurisdictionAssertions';
import SearchPills from '../SearchPills';
import { isOrganization } from '../../utils';
import { isFilled } from '../../validation';
import { detailSectionProperties } from '../../utils';
import { Label } from './styles';

interface Props {
	item: Credential | Assessment | LearningOpportunity | Organization;
    openedModal?: ModalType;
    onOpenModalClick: (modalType: ModalType) => () => void;
	onCloseModalClick: () => void;
	pageSectionDescription?: string;
}

export default function QualityAssurancePageSection(props: Props) {
	var mergedJurisdictionAssertions = [
		props.item.AccreditedIn,
		props.item.ApprovedIn,
		props.item.RecognizedIn,
		props.item.RegulatedIn,
		props.item.OfferedIn
	].filter((item) => { return item ? true : false }).flatMap((item) => { return item });

    return (
        <HasAnyFilled
            data={props.item}
            keys={[
                ...detailSectionProperties.qa,
                ...detailSectionProperties.jurisdictionAssertions,
			]}>
			<PageSection icon={qaIcon} title="Quality Assurance" description={props.pageSectionDescription}>
				{(props.item.QAReceived?.length > 0 || props.item.OwnerQAReceived?.length > 0 || mergedJurisdictionAssertions?.length > 0) &&
					<PageSectionItem>
						<Label>Quality Assurance Received</Label>
						<ModalButtonAndWindow
							buttonLabel="Quality Assurance"
							resourceTitle={props.item.Name}
							items={props.item.QAReceived}
							Wrapper={Section}
							Content={() => <OutlineList items={props.item.QAReceived} />}
						/>
						<ModalButtonAndWindow
							buttonLabel="Owner's Quality Assurance"
							resourceTitle={props.item.Name}
							items={props.item.OwnerQAReceived}
							Wrapper={Section}
							Content={() => <OutlineList items={props.item.OwnerQAReceived} />}
						/>
						
						<HasAnyFilled
							data={props.item}
							keys={detailSectionProperties.jurisdictionAssertions}>
							<ModalButtonAndWindow
								buttonLabel={"Jurisdiction Assertions (" + mergedJurisdictionAssertions.length + ")"}
								resourceTitle={props.item.Name}
								items={mergedJurisdictionAssertions}
								Wrapper={Section}
								Content={() => <JurisdictionAssertions item={props.item} />}
								hideCount={true}
							/>
						</HasAnyFilled>
					</PageSectionItem>
				}
                {isOrganization(props.item) && isFilled(props.item.QAPerformed) && (
					<PageSectionItem>
						<Label>Quality Assurance Performed</Label>
						<SearchPills links={props.item.QAPerformed} />
					</PageSectionItem>
                )}
            </PageSection>
        </HasAnyFilled>
    );
}
