import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import LoadingOrError from '../../components/LoadingOrError';
import PageSection from '../../components/PageSection';
import PageSectionItem from '../../components/PageSectionItem';
import {
    MobileAboutDetail,
    Description,
    Label,
    Wrapper,
    Inline,
    InlineLabel,
    Section,
} from '../../components/DetailPage/styles';
import { apiUrl } from '../../shared/globals';
import { theme } from '../../shared/theme';
import assessmentIcon from '../../assets/images/icons/icon-assessments-white-green-01.svg';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';

import scoringIcon from '../../assets/images/icons/icon-scoring-method-blue-green-01.svg';
import exampleIcon from '../../assets/images/icons/icon-example-blue-green-01.svg';
import processIcon from '../../assets/images/icons/icon-process-blue-green-01.svg';
import { VStack } from '../../components/Stack';
import { useMediaQuery, useGet } from '../../hooks';
import { ModalType } from '../../types/internal';
import SearchPills from '../../components/SearchPills';
import { Assessment, ProcessProfile } from '../../types/external';
import { isFilled } from '../../validation';
import AboutDetail from './AboutDetail';
import OccupationsAndIndustriesSection from '../../components/DetailPage/OccupationsAndIndustriesSection';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import SubjectsAndKeywordsPageSection from '../../components/DetailPage/SubjectsAndKeywordsPageSection';
import QualityAssurancePageSection from '../../components/DetailPage/QualityAssurancePageSection';
import LocationPageSection from '../../components/DetailPage/LocationPageSection';
import PageBanner from '../../components/DetailPage/PageBanner';
import LinkObject from '../../components/DetailPage/LinkObject';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import CostsAndTimePageSection from '../../components/DetailPage/CostsAndTimePageSection';
import ExpandableText from '../../components/ExpandableText';
import HasAnyFilled from '../../components/HasAnyFilled';
import { detailSectionProperties } from '../../utils';
import ConnectionsSection from '../../components/DetailPage/ConnectionsSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { ModalButtonAndWindow } from '../../components/Modal';
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import ProcessProfileList from '../../components/DetailPage/ProcessProfile';
import { OutcomesDataPageSectionItem } from '../../components/DetailPage/OutcomesDataPageSection';
import { renderJSONLDMetaTags } from '../../utils/JSONLDMetaHelper';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";

interface Props {
    id: string;
}

export default function AssessmentPage(props: RouteComponentProps<Props>) {
    const id = props.match.params.id;
    const [
        assessmentDataIsLoading,
        assessmentDataHasError,
        getAssessmentData,
    ] = useGet();
    const [pageModel, setAssessment] = useState({} as Assessment);
    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });
    const [openedModal, setOpenedModal] = useState<ModalType>();

    function onOpenModalClick(modalType: ModalType) {
        return () => setOpenedModal(modalType);
    }

    function onCloseModalClick() {
        setOpenedModal(undefined);
    }

    useEffect(() => {
        getAssessmentData(`${apiUrl}/assessment/${id}`, (data: Assessment) => {
			setAssessment(data);
			renderJSONLDMetaTags(data, "Assessment");
        });
    }, [getAssessmentData, id]);

	var typeLabel = widgetGetSingleLabelForText(pageModel.CTDLTypeLabel, "Assessment");

    return (
        <LoadingOrError
            isLoading={assessmentDataIsLoading}
            hasError={assessmentDataHasError}>
            <PageBanner
                item={pageModel}
                bannerIcon={assessmentIcon}
            />
            <Wrapper>
                <VStack spacing={isMobile ? '0px' : '20px'}>
                    <HasAnyFilled
                        data={pageModel}
						keys={detailSectionProperties.assessmentAbout}>
                        <PageSection
							icon={aboutIcon}
							title={"About this " + typeLabel}
							variant="Highlight"
							description={"Basic information about the " + typeLabel}>
                            <PageSectionItem>
                                {pageModel.Description && (
                                    <Description>
                                        {pageModel.Description}
                                    </Description>
                                )}
                                <VStack spacing="10px">
									{pageModel.AvailableOnlineAt?.length > 0 &&
										pageModel.AvailableOnlineAt.map((url) => {
											<LinkObject item={{ URL: url, Label: "Available Online" }} />
										})
									}
                                    {pageModel.SubjectWebpage &&
										<LinkObject item={{URL: pageModel.SubjectWebpage, Label: "View this " + typeLabel}} />
                                    }
                                    {pageModel.InCatalog &&
                                        <LinkObject item={{
                                            URL: pageModel.InCatalog, Label: "View this " + typeLabel + "in the Course Catalog "
                                        }} />
                                    }
									<OutcomesDataPageSectionItem aggregateDataProfiles={pageModel.AggregateData} dataSetProfiles={pageModel.ExternalDataSetProfiles} resourceTitle={pageModel.Name} />
                                </VStack>
                            </PageSectionItem>
                            <AboutDetail
                                item={pageModel}
                                onOpenModalClick={onOpenModalClick}
                                onCloseModalClick={onCloseModalClick}
                                openedModal={openedModal}
                            />
                        </PageSection>
                        {isMobile && (
                            <MobileAboutDetail>
                                <AboutDetail
                                    item={pageModel}
                                    onOpenModalClick={onOpenModalClick}
                                    onCloseModalClick={onCloseModalClick}
                                    openedModal={openedModal}
                                />
                            </MobileAboutDetail>
                        )}
                    </HasAnyFilled>
                    <CostsAndTimePageSection
						item={pageModel}
						onOpenModalClick={onOpenModalClick}
						onCloseModalClick={onCloseModalClick}
						openedModal={openedModal}
						pageSectionDescription={"How to pursue and complete the " + typeLabel}
                    />
					<SubjectsAndKeywordsPageSection item={pageModel} pageSectionDescription={"Type of material covered by the " + typeLabel} />
					<AdditionalInformationPageSection item={pageModel} pageSectionDescription={"Identifiers and other information for the " + typeLabel} />
                </VStack>
                <VStack spacing={isMobile ? '0px' : '20px'}>
                    {/* TODO: connections can be other than connection profiles, need to change to a connections wrapper (like CostsAndTimePageSection), and then separate sections */}
					<RelatedOrganizationPageSection item={pageModel} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " that own, offer, or perform other services related to the " + typeLabel} />
					<HasAnyFilled
                        data={pageModel}
                        keys={[
                            ...detailSectionProperties.entityConnections,
                            ...detailSectionProperties.assessmentConnections,
                        ]}>
						<PageSection
							icon={connectionsIcon}
							title="Connections"
							description="Related Resources"
						>
                            <ConnectionsSection
                                item={pageModel}
                                openedModal={openedModal}
                                onOpenModalClick={onOpenModalClick}
                                onCloseModalClick={onCloseModalClick}
                            />
					     </PageSection>
                    </HasAnyFilled>
                    <QualityAssurancePageSection
                        item={pageModel}
                        openedModal={openedModal}
                        onCloseModalClick={onCloseModalClick}
						onOpenModalClick={onOpenModalClick}
						pageSectionDescription="Quality Assurance for the Assessment"
                    />
					<OccupationsAndIndustriesSection item={pageModel} pageSectionDescription={"Career Field information about the " + typeLabel} itemTypeLabel={widgetGetPluralLabelForText("Assessments")} />
					<LocationPageSection item={pageModel} pageSectionDescription={"Geographic information about the " + typeLabel} />
                    <HasAnyFilled
                        data={pageModel}
                        keys={detailSectionProperties.assessmentScoringMethod}>
						<PageSection icon={scoringIcon} title="Scoring Method" description={"How the " + typeLabel + " is scored, graded, or evaluated"}>
                            <PageSectionItem>
                                <Label>Scoring Method:</Label>
                                {pageModel.ScoringMethodDescription && (
                                    <ExpandableText text={pageModel.ScoringMethodDescription} />
                                )}
                                {pageModel.ScoringMethodType && (
                                    <Section>
                                        <SearchPills links={pageModel.ScoringMethodType} />
                                    </Section>
                                )}
                                {pageModel.ScoringMethodExample && (
                                    <LinkObject item={pageModel.ScoringMethodExample} />
                                )}
                                {pageModel.IsNonCredit !== undefined && pageModel.IsNonCredit == true && (
                                    <Inline>
                                        <InlineLabel>Is Non-Credit :</InlineLabel>
                                        <div>
                                            {pageModel.IsNonCredit
                                                ? 'true'
                                                : 'false'}
                                        </div>
                                    </Inline>
                                )}
                                {pageModel.IsProctored !== undefined && (
                                    <Inline>
                                        <InlineLabel>Proctored :</InlineLabel>
                                        <div>
                                            {pageModel.IsProctored
                                                ? 'true'
                                                : 'false'}
                                        </div>
                                    </Inline>
                                )}
                                {pageModel.HasGroupEvaluation !==
                                    undefined && (
                                    <Inline>
                                        <InlineLabel>
                                            Group Evaluation :
                                        </InlineLabel>
                                        <div>
                                            {pageModel.HasGroupEvaluation
                                                ? 'true'
                                                : 'false'}
                                        </div>
                                    </Inline>
                                )}
                                {pageModel.HasGroupParticipation !==
                                    undefined && (
                                    <Inline>
                                        <InlineLabel>
                                            Group Participation :
                                        </InlineLabel>
                                        <div>
                                            {pageModel.HasGroupParticipation
                                                ? 'true'
                                                : 'false'}
                                        </div>
                                    </Inline>
                                )}
                            </PageSectionItem>
                        </PageSection>
                    </HasAnyFilled>
                    <HasAnyFilled
                        data={pageModel}
                        keys={detailSectionProperties.assessmentExample}>
						<PageSection icon={exampleIcon} title="Examples" description={"Available example information for the " + typeLabel}>
                        {pageModel.AssessmentOutput && (
                                <Section>
                                   {/* <Label>Assessment Output:</Label> */}
                                   <ExpandableText text={ pageModel.AssessmentOutput} />
                                </Section>
                            )}
                            {pageModel.AssessmentExample && (
                                <PageSectionItem noBorder>
                                    <LinkObject item={pageModel.AssessmentExample} />
                                </PageSectionItem>
                            )}                        
                        </PageSection>
                    </HasAnyFilled>
                    <HasAnyFilled
                        data={pageModel}
                        keys={detailSectionProperties.assessmentProcess}>
						<PageSection icon={processIcon} title="Processes" description={"Process information related to the " + typeLabel}>
                            <PageSectionItem>
                                {pageModel.ExternalResearch && (
                                    <Section>
										<LinkObject item={{ URL: pageModel.ExternalResearch, Label: "External Research" }} />
                                    </Section>
                                )}
                                {pageModel.ProcessStandards && (
                                    <Section>
                                        <LinkObject item={pageModel.ProcessStandards} />
                                    </Section>
                                )}
                            </PageSectionItem>
                            
                        <HasAnyFilled
                            data={pageModel}
                            keys={detailSectionProperties.assessmentProcessProfiles}>
                                <PageSectionItem> 
									<ModalButtonAndWindow
										buttonLabel="Administration Process"
										getDataOnClickFromURL={pageModel.AdministrationProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Administration Process"
										resourceTitle={pageModel.Name}
										items={[]}
										overrideCount={pageModel.AdministrationProcess?.Total}
									/>
									<ModalButtonAndWindow
										buttonLabel="Development Process"
										getDataOnClickFromURL={pageModel.DevelopmentProcess?.URL}
										Content={(props: { loadedData: any }) => (<ProcessProfileList items={props.loadedData as Array<ProcessProfile>} />)}
										modalTitle="Development Process"
										resourceTitle={pageModel.Name}
										items={[]}
										overrideCount={pageModel.DevelopmentProcess?.Total}
									/>
                            </PageSectionItem> 
                        </HasAnyFilled>
                        
                        </PageSection>
					</HasAnyFilled>
                </VStack>
            </Wrapper>
        </LoadingOrError>
    );
    function TextItem(props: { label: string, item: string }) {
        return (props.item &&
            <Inline>
                <InlineLabel>{props.label}</InlineLabel>
                <div>{props.item}</div>
            </Inline>
        ) || null
    }
}
