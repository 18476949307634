import {
    MainSearchType,
} from '../types/internal';
import {
	Assessment,
	Credential,
	LearningOpportunity,
    Organization,
    Pathway,
    PathwaySet,
    DataSetProfile, 
    ProgressionModel,
    ScheduledOffering,
    SupportService,
    Occupation,
    Job,
    WorkRole,
    Task,
	TransferValue,
	TransferIntermediary,
	FilterItem,
    CredentialingAction,
    Rubric,
    Metric,
    Industry
} from '../types/external';
import { decode } from 'html-entities';

export function isCheckboxFilter(item: any) {
    return (
        item.InterfaceType === 'filterType:CheckBox' ||
        item.InterfaceType === 'interfaceType:CheckBox'
    );
}

export function isAutocompleteFilter(item: any) {
    return item.InterfaceType === 'interfaceType:Autocomplete';
}

export function isTextboxFilter(item: any) {
    return item.InterfaceType == 'interfaceType:Text';
}

export function isOrganization(item: any): item is Organization {
    return item.BroadType == 'Organization';
}

export function isCredential(item: any): item is Credential {
    return item.BroadType == 'Credential';
}

export function isLearningOpportunity(item: any): item is LearningOpportunity {
    return item.BroadType == 'LearningOpportunity';
}

export function isAssessment(item: any): item is Assessment {
    return item.BroadType == 'Assessment';
}
export function isPathway(item: any): item is Pathway {
    return item.BroadType == 'Pathway';
}
export function isPathwaySet(item: any): item is PathwaySet {
    return item.BroadType == 'PathwaySet';
}
export function isTransferValue(item: any): item is TransferValue {
    return item.BroadType == 'TransferValue';
}
export function isTransferIntermediary(item: any): item is TransferIntermediary {
    return item.BroadType == 'TransferIntermediary';
}
export function isDataSetProfile(item: any): item is DataSetProfile {
    return item.BroadType == 'DataSetProfile';
}
export function isProgressionModel(item: any): item is ProgressionModel {
    return item.BroadType == 'ProgressionModel';
}
export function isScheduledOffering(item: any): item is ScheduledOffering {
    return item.BroadType == 'ScheduledOffering';
}
export function isSupportService(item: any): item is SupportService {
    return item.BroadType == 'SupportService';
}
export function isOccupation(item: any): item is Occupation {
    return item.BroadType == 'Occupation';
}
export function isJob(item: any): item is Job {
    return item.BroadType == 'Job';
}
export function isTask(item: any): item is Task {
    return item.BroadType == 'Task';
}
export function isWorkRole(item: any): item is WorkRole {
    return item.BroadType == 'WorkRole';
}
export function isCredentialingAction(item: any): item is CredentialingAction {
    return item.BroadType == 'CredentialingAction';
}
export function isRubric(item: any): item is Rubric {
    return item.BroadType == 'Rubric';
}
export function isMetric(item: any): item is Metric {
    return item.BroadType == 'Metric';
}
export function isIndustry(item: any): item is Industry {
    return item.BroadType == 'Industry';
}
export function getContextForAutoCompleteSearch(filterURI: string) {
    switch (filterURI) {
        case 'filter:OccupationType':
            return 'occupations';
        case 'filter:IndustryType':
            return 'industries';
        case 'filter:InstructionalProgramType':
            return 'instructionalprogramtypes';
        case 'filter:Organization':
            return 'mainsearch';
        default:
            return '';
    }
}

export function getResultsLocationField(searchType: string) {
    switch (searchType) {
        case 'credential':
        case 'assessment':
        case 'learningopportunity':
        case 'supportservice':
            return 'AvailableAt';
        case 'organization':
            return 'Address';
        default:
            return '';
    }
}

export function getMapInfoWindowFromSearchResultsLocations(
    searchType: string,
    resultItem: any,
) {
    switch (searchType) {
        case 'credential':
            return `
                <a href=/${searchType}/${resultItem.Meta_Id}>${resultItem.Name}</>
            `;
        case 'organization':
            return `
            <a href=/${searchType}/${resultItem.Meta_Id}>${resultItem.Name}</>
        `;
        default:
            return '';
    }
}

export function delayCallbackExecution(timer: any, fn: any, ms: number) {
    clearTimeout(timer);
    return setTimeout(fn, ms);
}

export type FilterField = 'identifier' | 'Text' | 'Label';

export function formatPrice(number: number, currencyHTMLEntity?: string) {
    // Add commas see: https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript?page=1&tab=votes#tab-top
    const numberWithCommas = number
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // Decode HTML entity
    if (currencyHTMLEntity) {
        const decodedCurrency = decode(currencyHTMLEntity);
        return `${decodedCurrency}${numberWithCommas}`;
    }
    return numberWithCommas;
}

export function getLinkType(url?: string) {
    return url ? (url[0] !== '/' ? 'external' : 'internal') : 'none';
}

export const detailSectionProperties = {
    organizationAbout: [
        'Description',
        'SubjectWebpage',
        'MissionAndGoalsStatement',
        'MissionAndGoalsStatementDescription',
        'AgentPurpose',
        'AgentPurposeDescription',
        'AgentType',
        'AlternateName',
        'Connections',
    ],
    organizationSectors: [
        'AgentSectorType',
        'IndustryType',
        'ServiceType',
        'Keyword',
    ],
    organizationProcess: [
        'TransferValueStatement',
		'TransferValueStatementDescription',
		'SupportServiceStatement',
        'AdministrationProcess',
        'AppealProcess',
        'ComplaintProcess',
        'DevelopmentProcess',
        'MaintenanceProcess',
        'ReviewProcess',
        'RevocationProcess',
        'HasConditionManifest','HasCostManifest',
        'HasVerificationService'
    ],
    organizationProcessProfiles: [
        'AdministrationProcess',
        'AppealProcess',
        'ComplaintProcess',
        'DevelopmentProcess',
        'MaintenanceProcess',
        'ReviewProcess',
        'RevocationProcess',
    ],
    organizationManifests: [
        'HasConditionManifest','HasCostManifest'
    ],
    credentialAbout: [
        'Description',
        'SubjectWebpage',
        'AvailableOnlineAt',
        'CredentialType ',
        'CredentialStatusType',
        'AudienceType',
        'AudienceLevelType',
        'LearningDeliveryType',
        'AssessmentDeliveryType',
    ],
    credentialAboutConnections: [
        'HasPart',
        'IsPartOf',
        'DegreeConcentration',
        'DegreeMajor',
        'DegreeMinor',
        'AdvancedStandingFrom',
        'IsAdvancedStandingFor',
        'IsPreparationFor',
        'IsRecommendedFor',
        'IsRequiredFor',
        'PreparationFrom',
        'AssessesCompetencies',
        'TeachesCompetencies',
        'RequiresCompetencies',
        'HasTransferValue',
        'ProvidesTransferValueFor',
        'ReceivesTransferValueFrom'
    ],
    credentialCostAndTime: [
        'EstimatedCost',
        'AssessmentCost',
        'LearningOpportunityCost',
        'FinancialAssistance',
        'EstimatedDuration',
        'RenewalFrequency',
        'Requires',
        'Recommends',
        'Corequisite',
        'CoPrerequisite',
    ],
    credentialCostAndTimeRenewal: [
        'Renewal',
        //'RenewedBy',
        'RenewedIn',
        //'RevokedBy',
        'RevokedIn',
        'Revocation',
    ],
    credentialRequirements: [
        'Requires',
        'Recommends',
        'Corequisite',
        'CoPrerequisite',
        'EntryCondition','CommonConditions'
    ],
    entityConnections: [
        'AdvancedStandingFrom',
        'IsAdvancedStandingFor',
        'IsPreparationFor',
        'IsRecommendedFor',
        'IsRequiredFor',
        'PreparationFrom',
        'Collections',
        'TargetPathway',
        'HasTransferValue',
        'HasPart',
        'Prerequisite',
        'SupportServiceCondition',
        'HasWorkRole',
        'HasTask',
        'HasOccupation',
        'HasSupportService',
        'HasJob',
        'HasSpecialization',
        'AbilityEmbodied',
        'SkillEmbodied',
        'KnowledgeEmbodied',
        'PhysicalCapabilityType',
        'PerformanceLevelType',
        'EnvironmentalHazardType',
        'SensoryCapabilityType',
        'Classification',
        'Instrument',
        'Object',
        'TargetCompetency',
        'ProvidesTransferValueFor',
        'ReceivesTransferValueFrom',
        'ObjectOfAction',
        "RelatedAssessment",
        "RelatedOccupation",
        "RelatedCredential",
        "RelatedJob",
        "RelatedLearningOpp",
        "HasCriterionCategorySet",
        "TargetOccupation",
        "HasRubric",
        "RelatedTask",
        "RelatedWorkRole",
        "RelatedActions"
    ],
    entityCompetencies: [
        'AssessesCompetencies',
        'RequiresCompetencies',
        'TeachesCompetencies',
    ],
    credentialConnections: [
        'AssessesCompetencies',
        'RequiresCompetencies'
    ],
    assessmentConnections: [
        'AssessesCompetencies',
        'RequiresCompetencies',
        'ProvidesTransferValueFor',
        'ReceivesTransferValueFrom'
    ],
    loppConnections: [
        'TargetAssessment',
        'TargetLearningOpportunity',
        'TeachesCompetencies',
        'RequiresCompetencies',
        'IsPartOf',
        'ProvidesTransferValueFor',
        'ReceivesTransferValueFrom',
        'ObjectOfAction'
    ],
    credentialProcess: [
        'ProcessStandards', 
        'ProcessStandardsDescription', 
        'AdministrationProcess',
        'AppealProcess',
        'ComplaintProcess',
        'DevelopmentProcess',
        'MaintenanceProcess',
        'ReviewProcess',
        'RevocationProcess'
    ],
    credentialProcessProfiles: [
        'AdministrationProcess',
        'AppealProcess',
        'ComplaintProcess',
        'DevelopmentProcess',
        'MaintenanceProcess',
        'ReviewProcess',
        'RevocationProcess'
    ],
    assessmentAbout: [
        'Description',
        'AvailableOnlineAt',
        'SubjectWebpage',
        'AudienceType',
        'AudienceLevelType',
        'AssessmentMethodType',
        'DeliveryType',
        'DeliveryTypeDescription',
        'AssessmentUseType',
		'CreditValue',
        'CreditUnitTypeDescription',
		'AssessesCompetencies'
    ],
    assessmentScoringMethod: [
        'ScoringMethodDescription',
        'ScoringMethodExample',
        'ScoringMethodType',
        'IsProctored',
        'HasGroupEvaluation',
        'HasGroupParticipation',
    ],
    assessmentExample: [
        'AssessmentExampleDescription',
        'AssessmentOutput',
        'AssessmentExample',
    ],
    assessmentMethod: [
        'AssessmentMethodDescription',
        'AssessmentMethodType',
    ],
    assessmentProcess: [
        'ExternalResearch',
        'ProcessStandards',
        'AdministrationProcess',
        'DevelopmentProcess',
        'MaintenanceProcess',
    ],
    assessmentProcessProfiles: [
        'AdministrationProcess',
        'DevelopmentProcess',
        'MaintenanceProcess',
    ],
    creditValue: [
		'CreditValue',
        'CreditUnitTypeDescription',
    ],
    deliveryType: [
        'DeliveryType',
        'DeliveryTypeDescription',
    ],
    loppAbout: [
        'Description',
        'AvailableOnlineAt',
        'SubjectWebpage',
        'AudienceType',
        'AudienceLevelType',
        'AssessmentMethodType',
        'DeliveryType',
        'DeliveryTypeDescription',
        'LearningMethodType',
        'LearningMethodDescription',
		'CreditValue',
        'CreditUnitTypeDescription',
		'TeachesCompetencies'
    ],
    learningMethodType: [
        'LearningMethodType',
        'LearningMethodDescription',
    ],
    collectionAbout: [
        'Description',
        'SubjectWebpage',
        'Connections',
    ],
    transferValueConnections: [
        'TransferValueFor',
        'TransferValueFrom',
    ],
    transferValueProcess: [
        'DevelopmentProcess',
    ],
    transferIntermediaryAbout: [
        'Description',
        'SubjectWebpage',
        'IntermediaryFor',
        'CreditValue',
        'CreditUnitTypeDescription',
        'Requires'
    ],
    dataSetProfileProcess: [
        'MaintenanceProcess',
        'AdministrationProcess',
        'DevelopmentProcess',
        'ReviewProcess'
    ],
    // Common sections
    contact: ['Email', 'SocialMedia'],
    additionalInformation: [
        'Meta_LastUpdated',
        'CTID',
        'CredentialId',
        'DUNS',
        'FEIN',
        'OPEID',
        'IPEDSID',
        'ISICV4',
        'LEICode',
        'Identifier',
        'VersionIdentifier',
        'AlternateName',
        'InLanguage',
        'FoundingDate',
        'DateEffective',
        'ExpirationDate',
    ],
    locations: [
        'AvailabilityListing',
        'Address',
        'AvailableAt',
        'SameAs'
    ],
    jurisdiction: [
        'Jurisdiction',
        'AccreditedIn',
        'ApprovedIn',
        'OfferedIn',
        'RecognizedIn',
        'RegulatedIn'
    ],
    qa: [
        'QAReceived',
        'QAPerformed'
    ],
    jurisdictionAssertions: [
        'AccreditedIn',
        'ApprovedIn',
        'OfferedIn',
        'RecognizedIn',
        'RegulatedIn',
        'RenewedIn',
        'RevokedIn',
    ],
    subjectsAndKeywords: [
        'Subject',
        'InstructionalProgramType',
        'Keyword',
        'LocalSubject',
        'ConceptTerm',
        'ConceptKeyword'
    ],
    relatedOrganizations: [
        'OwnedBy',
        'OwnedOfferedBy',
        'OfferedBy',
        'RenewedBy',
        'CopyrightHolder',
        'Creator',
        'Publisher',
        'RightsHolder',
        'DataProviderMain',
        "AssertedBy",
        "ActingAgent",
        "Particpant"
    ],
    occupationsAndIndustries: [
        'OccupationType',
        'IndustryType'
    ],
    costAndTime: [
        'EstimatedCost',
        'FinancialAssistance',
        'EstimatedDuration'
    ],
    dataSetProfileAbout: [
        'Description',
        'Source'
    ],
    scheduledOfferingAbout: [
        'Description',
        'SubjectWebpage',
        'AlternateName',
    ],
    supportServiceAbout: [
        'Description',
        'SubjectWebpage',
        'AlternateName',
    ],
    occupationAbout: [
        'Description',
        'SubjectWebpage',
        'AlternateName',
    ],
    pathwayComponentAbout: [
        'Description',
        'SubjectWebpage',
        'AlternateName',
    ],
    workClassRequirements: [
        'Requires'    ],
    MetricAbout: [
        'Description',
        'SubjectWebpage',
        'AlternateName',
        'IncomeDeterminationType',
        'EmploymentDefinition',
        'EarningsDefinition',
        'EarningsThreshold',
        'Adjustment'
    ],
        
};
