import React, { ReactNode } from 'react';
import { isFilled } from '../../validation';

interface Props {
    data: any;
    keys: Array<string>;
    children: ReactNode;
}

export default function HasAnyFilled({ data, keys, children }: Props) {
    const hasAnyFilled = keys.some((key) => isFilled(data[key]));
    return hasAnyFilled ? <>{children}</> : null;
}
