import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { apiUrl } from '../../shared/globals';
import { RouteComponentProps } from 'react-router-dom';
import LoadingOrError from '../../components/LoadingOrError';
import { appURL } from '../../shared/globals';
import {
	Pathway as PathwayType,
	PathwayComponent as PathwayComponentType,
	ComponentCondition as ComponentConditionType,
	IdentifierValue as IdentifierValueType,
	ValueProfile as ValueProfileType,
	ConceptScheme,
	Concept,
	Link,
	Outline,
	AJAXSettingsOutline
} from '../../types/external';
import { ModalType } from '../../types/internal';
import pathwaysBannerIcon from '../../assets/images/icons/icon-pathways-white-green-01.svg';
import pathwaysIcon from '../../assets/images/icons/icon-pathways-green-blue-01.svg';
import PageBanner from '../../components/DetailPage/PageBanner';
import {
	Wrapper,
	FullWidthWrapper,
	InnerWrapper,
	Description,
	Label
} from '../../components/DetailPage/styles';
import { VStack } from '../../components/Stack';
import PageSection from '../../components/PageSection';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import { getResourceByURI, englishOrNull, createKeywordSearchLink } from '../../components/GetResourceByURI';
import ActivityIndicator from '../../components/ActivityIndicator';
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import LinkObject from '../../components/DetailPage/LinkObject';
import PageSectionItem from '../../components/PageSectionItem';
import SearchPills from '../../components/SearchPills';
import SubjectsAndKeywordsPageSection from '../../components/DetailPage/SubjectsAndKeywordsPageSection';
import OccupationsAndIndustriesSection from '../../components/DetailPage/OccupationsAndIndustriesSection';
import { renderJSONLDMetaTags } from '../../utils/JSONLDMetaHelper';


interface Props {
	id: string;
	targetCTID?: string;
}

export default function PathwayPageV2(props: RouteComponentProps<Props>) {
	const id = props.match.params.id;
	const [dataIsLoading, setDataIsLoading] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [pathway, setPathway] = useState({} as PathwayType);
	const [components, setComponents] = useState([] as Array<PathwayComponentType>);
	const [progressionModel, setProgressionModel] = useState([] as Array<ConceptScheme>);
	const [progressionLevels, setProgressionLevels] = useState([] as Array<Concept>);
	const [localAJAXCache, setLocalAJAXCache] = useState([] as Array<any>); //Used to trick react into re-rendering the data

	useEffect(() => {
		getResourceByURI(`${apiUrl}/dsp_pathway/${id}`, setLocalAJAXCache, null, (loaded: any) => {
			var data = loaded.Result;
			setDataIsLoading(false);
			setPathway(convertDataToPathway(data.Resource || { "ceterms:name": "Missing Pathway", "ceterms:description": "Unable to find data for identifier: " + id }, data.Metadata));
			renderJSONLDMetaTags(data.Resource, "Pathway");
			console.log("Pathway", pathway);
			setComponents(data.RelatedItems.filter((m: any) => { return m["ceasn:isPartOf"] == data.Resource["@id"] }).map((m: PathwayComponentType) => { return convertDataToPathwayComponent(m) }));
		},
		(error: any) => {
			setHasError(true);
			console.log("Error loading data: ", error);
		});
	}, [id, localAJAXCache]);

	function convertDataToPathway(resource: any, metadata: any): PathwayType {
		console.log("Converting resource to pathway", resource);
		return {
			BroadType: "Pathway",
			CTDLType: "ceterms:Pathway",
			CTDLTypeLabel: "Pathway",
			Meta_Id: id,
			Meta_Language: resource["ceasn:inLanguage"]?.[0] || "en",
			Meta_LastUpdated: metadata?.LastUpdated || "Unknown",
			Meta_LastUpdatedHeader: metadata?.LastUpdated || "Unknown",
			CTID: resource["ceterms:ctid"],
			Name: englishOrNull(resource["ceterms:name"]),
			Description: englishOrNull(resource["ceterms:description"]),
			SubjectWebpage: resource["ceterms:subjectWebpage"],
			Subject: resource["ceterms:subject"]?.map((m: string) => { return createKeywordSearchLink("pathway", englishOrNull(m)) }),
			Keyword: resource["ceterms:keyword"]?.map((m: string) => { return createKeywordSearchLink("pathway", englishOrNull(m)) }),
			OwnedBy: resource["ceterms:ownedBy"] && { Values: resource["ceterms:ownedBy"]?.map((m: string) => { return getOrganizationOutlineByURI(m) }) } as AJAXSettingsOutline,
			OfferedBy: resource["ceterms:offeredBy"] && { Values: resource["ceterms:offeredBy"]?.map((m: string) => { return getOrganizationOutlineByURI(m) }) } as AJAXSettingsOutline,
			HasProgressionModel: resource["asn:hasProgressionModel"], //Should use the same data conversion process as concept schemes
			CredentialRegistryURL: resource["@id"],
			HasDestinationComponent: resource["ceterms:hasDestinationComponent"],
			RegistryData: { CTID: resource["ceterms:ctid"], Resource: { URL: resource["@id"], Label: "View Resource" }, Envelope: { URL: resource["@id"]?.replace("/resources/", "/envelopes/"), Label: "View Envelope" } },
			Meta_JSON: resource
		} as PathwayType;
	}

	function convertDataToPathwayComponent(resource: any): PathwayComponentType {
		return {
			BroadType: "PathwayComponent",
			CTDLType: resource["@type"],
			CTDLTypeLabel: englishOrNull(lookupFromSchema(resource["@type"])?.["rdfs:label"]),
			CredentialRegistryURL: resource["@id"],
			CTID: resource["ceterms:ctid"],
			Name: englishOrNull(resource["ceterms:name"]),
			Description: englishOrNull(resource["ceterms:description"]),
			HasProgressionLevel: resource["ceterms:hasProgressionLevel"],
			IsDestinationComponentOf: resource["ceterms:isDestinationComponentOf"],
			ComponentCategory: englishOrNull(resource["ceterms:componentCategory"]),
			ComponentDesignation: englishOrNull(resource["ceterms:componentDesignation"]),
			Identifier: resource["ceterms:identifier"]?.map((m: any) => { return convertDataToIdentifierValue(m) }),
			HasCondition: resource["ceterms:hasCondition"]?.map((m: any) => { return convertDataToComponentCondition(m) }),
			PointValue: resource["ceterms:pointValue"],
			Precedes: resource["ceterms:precedes"],
			Prerequisite: resource["ceterms:prerequisite"],
			SourceData: resource["ceterms:sourceData"],
			SubjectWebpage: resource["ceterms:subjectWebpage"],
			ProgramTerm: englishOrNull(resource["ceterms:programTerm"]),
			CreditValue: resource["ceterms:creditValue"]?.map((m: any) => { return convertDataToValueProfile(m) }),
			Meta_JSON: resource
		} as any;
	}

	function convertDataToComponentCondition(resource: any): ComponentConditionType | null {
		return !resource ? null : {
			Name: englishOrNull(resource["ceterms:name"]),
			Description: englishOrNull(resource["ceterms:description"]),
			RequiredNumber: resource["ceterms:requiredNumber"],
			TargetComponent: resource["ceterms:targetComponent"],
			Meta_JSON: resource
		} as ComponentConditionType;
	}

	function convertDataToIdentifierValue(resource: any): IdentifierValueType | null {
		return !resource ? null : {
			IdentifierType: resource["ceterms:identifierType"],
			IdentifierTypeName: englishOrNull(resource["ceterms:identifierTypeName"]),
			IdentifierValueCode: resource["ceterms:identifierValueCode"]
		} as IdentifierValueType;
	}

	function convertDataToValueProfile(resource: any): ValueProfileType | null {
		return !resource ? null : {
			Description: englishOrNull(resource["schema:description"]),
			MaxValue: resource["schema:maxValue"],
			MinValue: resource["schema:minValue"],
			Value: resource["schema:value"],
			Percentage: resource["qdata:percentage"],
			Subject: resource["ceterms:subject"]?.map((m: any) => { return convertDataToLink(m) }),
			CreditLevelType: resource["ceterms:subject"]?.map((m: any) => { return convertDataToLink(m) }),
			CreditUnitType: resource["ceterms:subject"]?.map((m: any) => { return convertDataToLink(m) }),
		} as ValueProfileType;
	}

	function convertDataToLink(resource: any): Link | null {
		return !resource ? null : {
			Label: englishOrNull(resource["ceterms:targetNodeName"] || resource["skos:prefLabel"] || resource["ceterms:name"] || resource["ceasn:name"])
		} as Link;
	}

	function lookupFromSchema(url: string) {
		//Temporary
		return {
			"rdfs:label": { "en": url }
		};
	}

	function getOrganizationOutlineByURI(uri: string): Outline {
		var data = getResourceByURI(uri, setLocalAJAXCache).Data;
		return {
			Label: englishOrNull(data?.["ceterms:name"]) || <ActivityIndicator size='sm' color='inherit' />,
			URL: appURL + "/organization/" + uri.split("/resources/")[1],
			Image: data?.["ceterms:image"]
		} as Outline;
	}

	var destinationComponent = (pathway.HasDestinationComponent ?
		components.filter((m) => { return m.CredentialRegistryURL == pathway.HasDestinationComponent })[0] :
		components.filter((m: any) => { return m.IsDestinationComponentOf == pathway.CredentialRegistryURL })[0]) ||
		{ Name: "No Destination", Description: "No destination component was indicated for this pathway." } as PathwayComponentType;

	return (
		<LoadingOrError isLoading={dataIsLoading} hasError={hasError}>
			<PageBanner
				item={pathway}
				bannerIcon={pathwaysBannerIcon}
				fallbackIcon={pathwaysBannerIcon}
			/>
			<Wrapper>
				<VStack>
					<PageSection icon={aboutIcon} title="About this Pathway" variant="Highlight" description="Basic information about the Pathway">
						<Description>{pathway.Description || "No description available"}</Description>
						{pathway.SubjectWebpage && (
							<PageSectionItem>
								<LinkObject item={{ URL: pathway.SubjectWebpage, Label: "View this Pathway" }} />
							</PageSectionItem>
						)}
					</PageSection>
				</VStack>
				<VStack>
					<RelatedOrganizationPageSection item={pathway} pageSectionDescription="Organizations related to the Pathway" />
				</VStack>
			</Wrapper>
			<FullWidthWrapper>
				<TabSetOrSingle
					activeIndex={0}
					items={[
						{
							Label: "Components",
							Content: (
								<PageSection icon={pathwaysIcon} title="Components" description="Components in the Pathway">
									{destinationComponent && <PathwayComponent pathwayComponent={destinationComponent} allPathwayComponents={components} /> }
								</PageSection>
							)
						},
						{
							Label: "Details",
							Content: (
								<InnerWrapper>
									<VStack>
										<AdditionalInformationPageSection item={pathway} pageSectionDescription="Additional information about the Pathway" />
									</VStack>
									<VStack>
										<SubjectsAndKeywordsPageSection item={pathway} pageSectionDescription="Subjects and Keywords related to the Pathway" />
										<OccupationsAndIndustriesSection item={pathway} pageSectionDescription="Career Field information about the Pathway" />
									</VStack>
								</InnerWrapper>
							)
						}
					].filter(m => m.Content != null)}
				/>
			</FullWidthWrapper>
		</LoadingOrError>
	)
}

function PathwayComponent(props: { pathwayComponent: PathwayComponentType, allPathwayComponents: Array<PathwayComponentType> }) {
	return props.pathwayComponent && (
		<PathwayComponentWrapper>
			{props.pathwayComponent.Name && <PathwayComponentName>{props.pathwayComponent.Name}</PathwayComponentName>}
			<PathwayComponentContent>
				{props.pathwayComponent.Description && <div>{props.pathwayComponent.Description}</div>}
				{props.pathwayComponent.HasCondition?.length > 0 &&
					<ComponentConditionList>
						{props.pathwayComponent.HasCondition.map((componentCondition, index) => (
							<ComponentCondition componentCondition={componentCondition} allPathwayComponents={props.allPathwayComponents} key={"pathwaycomponent-" + index} />
						))}
					</ComponentConditionList>
				}
			</PathwayComponentContent>
		</PathwayComponentWrapper>
	) || null;
}

function ComponentCondition(props: { componentCondition: ComponentConditionType, allPathwayComponents: Array<PathwayComponentType> }) {
	return props.componentCondition && (
		<ComponentConditionWrapper>
			{props.componentCondition.Name && <div>{props.componentCondition.Name}</div>}
			{props.componentCondition.Description && <div>{props.componentCondition.Description}</div>}
		</ComponentConditionWrapper>
	) || null;
}

const PathwayComponentWrapper = styled.div`
	padding: 5px;

	&:not(:last-child) {
		border-bottom: 1px solid #CCC;
	}
`;

const PathwayComponentName = styled.div`
	font-weight: bold;
`;

const PathwayComponentContent = styled.div`
	padding-left: 10px;
`;

const ComponentConditionList = styled.div`
	padding-left: 25px;
`;

const ComponentConditionWrapper = styled.div`
	
`;